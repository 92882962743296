import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import { connect } from 'react-redux';
import DataTable from 'components/DataTable/DataTable';
import _ from 'lodash';
import { DarkBackground, Spinner } from 'elements/Spinner/Spinner.styled';
import { setCurrentView } from '../Home/Home.actions';
import SavedTRFPrintScreenFilter from './SavedTRFPrintScreenFilter';
import { getPrintImage } from '../Forms/TRFImaging.actions';
import { formatTableData } from './SavedTRFPrintScreenHelper';
import { updatedTableDefinition } from './SavedTRFPrintScreen.constants';
import { setMONumberSortOrder, setTagFunctionalLocationSortOrder, setTagDateTestedSortOrder } from './SavedTRFPrintScreen.actions';

const SavedTRFPrintScreenPage = (props) => {
  const { isTableLoading, isMRATOnline, totalCount, fetchTRFImages, selectedBarrierType, searchedTRFData,
  sortMONumberData, sortTagFirstDateTestedData, sortTagFunctionalLocationData } = props;
  const [printButtonVisible, setPrintButtonVisible] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  let checkedFormsData = [];
  const handlePrintButtonVisible = (visibleState, checkedRecords) => {
    setPrintButtonVisible(visibleState);
    checkedFormsData = [...checkedRecords];
  };
  const sortButtonHandler = (columnName) => {
    if (columnName === 'HdWorkOrder') {
      sortMONumberData('MoNumber');
    } else if (columnName === 'HdLocation') {
      sortTagFunctionalLocationData('TagFunctionalLocation');
    } else {
      sortTagFirstDateTestedData('TagFirstDateTested');
    }
  };
  const handlePrintButton = async () => {
    setSpinnerVisible(true);
    const trfImages = await fetchTRFImages(checkedFormsData);
    const imgData = [];
    _.forEach(trfImages, (ele) => {
      imgData.push(`data:image/png;base64, ${ele.Trfimage1}`);
      ele.Trfimage2 && imgData.push(`data:image/png;base64, ${ele.Trfimage2}`);
      ele.Trfimage3 && imgData.push(`data:image/png;base64, ${ele.Trfimage3}`);
    });
    const iframe = document.createElement('iframe');
    iframe.style.height = 0;
    iframe.style.visibility = 'hidden';
    iframe.style.width = 0;
    iframe.style.overflowX = 'visible';
    iframe.setAttribute('srcdoc', '<html><body></body></html>');
    document.body.appendChild(iframe);

    iframe.addEventListener('load', () => {
      const { body } = iframe.contentDocument;
      const div = document.createElement('div');
      div.style.position = 'absolute';
      div.style.display = 'block';
      div.style.left = 0;
      div.style.top = 0;
      div.style.bottom = 0;
      div.style.right = 0;
      div.style.padding = 0;
      body.appendChild(div);
      let count = 1;
      _.forEach(imgData, (img) => {
        const image = document.createElement('img');
        image.src = img;
        image.setAttribute('id', `img${count}`);
        image.style.width = '100%';
        image.style.maxHeight = '958px';
        div.insertAdjacentElement('beforeend', image);
        count += 1;
      });
    });

    setSpinnerVisible(false);
    iframe.contentWindow.print();
    iframe.contentWindow.addEventListener('beforeprint', () => {
      const images = iframe.contentWindow.document.querySelectorAll('img');
      _.forEach(images, (img) => {
        const ratio = img.naturalHeight / img.naturalWidth;
        if (ratio < 1.29) {
          const margin = Math.abs(958 - (ratio / 1.29 * 958));
          iframe.contentWindow.document.getElementById(img.id).style.marginBottom = `${margin}px`;
        }
      });
    });
    iframe.contentWindow.addEventListener('afterprint', () => {
      iframe.parentNode.removeChild(iframe);
    });
  };

  return (
    <SiteWrapper
      headerText={
        (
          <div style={{ textAlign: 'center' }}>
            <span>
              Saved TRF Result Printing
              <br />
            </span>
          </div>
        )
      }
      showTotalCount
      totalCount={totalCount}
      goBack="home"
      TagSelectionPrintButtonVisible={printButtonVisible && isMRATOnline}
      PrintButtonHandler={handlePrintButton}
    >
      <div className="container">
        <SavedTRFPrintScreenFilter id="TRFPrintScreenFilter" />
        <DarkBackground disappear={!spinnerVisible}>
          <Spinner />
        </DarkBackground>
        <div className="row">
          <div className="col-xs-12">
            <DataTable
              spaceBreakNeeded
              id="SavedTRFPrintScreenTable"
              tableDefinition={updatedTableDefinition}
              tableData={formatTableData(searchedTRFData)}
              isLoading={isTableLoading}
              showArrowButton={false}
              sortButtonHandler={sortButtonHandler}
              printButtonVisible={handlePrintButtonVisible}
              isMRATOnline={isMRATOnline}
              showCheckBox
              historicalPrintScreen
              count={totalCount}
              height={selectedBarrierType === 'm6 f&g' ? 'calc(100vh - 340px)' : 'calc(100vh - 282px)'}
            />
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};

const mapStateToProps = ({ Home, MOSelection, SavedTRF, AppData }) => ({
  clearFilters: SavedTRF.clearFilters,
  isTableLoading: SavedTRF.tableLoading,
  isMRATOnline: AppData.isMRATOnline,
  moDataAll: MOSelection.moDataAll,
  tags: Home.tags,
  totalCount: SavedTRF.totalCount,
  trfTableData: SavedTRF.trfTableData,
  savedTRFDataAll: SavedTRF.savedTRFDataAll,
  searchedTRFData: SavedTRF.searchedTRFData,
  selectedAssetName: Home.selectedAssetName,
  selectedBarrierType: Home.selectedBarrierType
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setCurrentAppView: (view) => dispatch(setCurrentView(view)),
  fetchTRFImages: (checkedForm) => dispatch(getPrintImage(checkedForm)),
  sortMONumberData: (columnName) => dispatch(setMONumberSortOrder(columnName)),
  sortTagFunctionalLocationData: (columnName) => dispatch(setTagFunctionalLocationSortOrder(columnName)),
  sortTagFirstDateTestedData: (columnName) => dispatch(setTagDateTestedSortOrder(columnName))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedTRFPrintScreenPage);

SavedTRFPrintScreenPage.propTypes = {
  fetchTRFImages: PropTypes.func,
  isMRATOnline: PropTypes.bool,
  isTableLoading: PropTypes.bool,
  searchedTRFData: PropTypes.array,
  selectedBarrierType: PropTypes.string,
  sortMONumberData: PropTypes.func,
  sortTagFirstDateTestedData: PropTypes.func,
  sortTagFunctionalLocationData: PropTypes.func,
  totalCount: PropTypes.number,
};
