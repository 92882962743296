import styled from 'styled-components';

export const DisplayImagesWrapper = styled.div`
width:50%;
height: 74vh;
padding: 0 0.5rem;
overflow: auto;
border: 1px solid rgb(166, 166, 166);
margin-left: 50%;
margin-right: 0.1rem;
font-size: 10pt;
`;

export const DisplayWrapper = styled.div`
display: flex;
`;

export const ImageWrapper = styled.div`
height:100%;
width: 35%;
margin: 5% 1%;
`;
export const ImageNoteWrapper = styled.div`
height:100%;
width: 71%;
margin: 0.4rem 0 0 1rem;
`;

export const InfoWrapper = styled.div`
width: 55%;
`;

export const DeleteButton = styled.div`

`;

export const EditButton = styled.div`
left: 18rem;
`;

export const Border = styled.div`
border-bottom: 1px solid green;
`;
