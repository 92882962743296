import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const LeftResultAsLeftResultFormulaM68 = (formData) => {
    if (FieldHasValue(formData.F390AltMeasureDetectorMaVOutputWithMultimeter)
        && FieldHasValue(formData.F391AltRecordConfirmedFireMaVOutputLevel)) {
        if (FieldHasValue(formData.F389AltConfirmThatExternalHeatAppliedWorkshop === 'YES')
            && Math.max.apply(Math, [ValidateNumberField(formData.F390AltMeasureDetectorMaVOutputWithMultimeter)])
            >= ValidateNumberField(formData.F391AltRecordConfirmedFireMaVOutputLevel)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
