import { FieldHasValue } from 'helpers/Validation';
import { FunctionTestType } from 'journeys/portal/Forms/Sections.constants';

export const Test1Result = (formData) => {
    if (formData.F663FunctionTestType === FunctionTestType.DRY_TEST
        && FieldHasValue(formData.F666AftTst1ActivatedFireSuppressionSystem)
        && FieldHasValue(formData.F668AftTst1ConfirmDischargeValveOperatesCorrectly)) {
        if (formData.F666AftTst1ActivatedFireSuppressionSystem === 'YES'
            && formData.F668AftTst1ConfirmDischargeValveOperatesCorrectly === 'YES') {
            return 'PASS';
        }
        return 'FAIL';
    }
    if (FieldHasValue(formData.F666AftTst1ActivatedFireSuppressionSystem)
        && FieldHasValue(formData.F668AftTst1ConfirmDischargeValveOperatesCorrectly)
        && FieldHasValue(formData.F670AftTst1ConfirmExtinguishantReleasedProtectedArea)) {
        if (formData.F666AftTst1ActivatedFireSuppressionSystem === 'YES'
            && formData.F668AftTst1ConfirmDischargeValveOperatesCorrectly === 'YES'
            && formData.F670AftTst1ConfirmExtinguishantReleasedProtectedArea === 'YES') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const Test2Result = (formData) => {
    if (formData.F663FunctionTestType === FunctionTestType.DRY_TEST && FieldHasValue(formData.F667AftTst2ActivatedFireSuppressionSystem)
        && FieldHasValue(formData.F669AftTst2ConfirmDischargeValveOperatesCorrectly)) {
        if (formData.F667AftTst2ActivatedFireSuppressionSystem === 'YES'
            && formData.F669AftTst2ConfirmDischargeValveOperatesCorrectly === 'YES') {
            return 'PASS';
        }
        return 'FAIL';
    }

    if (FieldHasValue(formData.F667AftTst2ActivatedFireSuppressionSystem)
        && FieldHasValue(formData.F669AftTst2ConfirmDischargeValveOperatesCorrectly)
        && FieldHasValue(formData.F671AftTst2ConfirmExtinguishantReleasedProtectedArea)) {
        if (formData.F667AftTst2ActivatedFireSuppressionSystem === 'YES'
            && formData.F669AftTst2ConfirmDischargeValveOperatesCorrectly === 'YES'
            && formData.F671AftTst2ConfirmExtinguishantReleasedProtectedArea === 'YES') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const calcHeight = (discardCheck, dryTestCheck) => {
    if (discardCheck) {
        if (dryTestCheck) {
            return '1.17rem';
        }
        return '1.24rem';
    }
    return '1.5rem';
};
