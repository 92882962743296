import { FieldHasValue } from 'helpers/Validation';
import { commonConstants } from 'journeys/portal/Forms/Sections.constants';

export const AsFoundResultP625Formula = (formData) => {
    if (FieldHasValue(formData.F1020AftHmiindicationOff) && FieldHasValue(formData.F1021AftHmiindicationOn)) {
        if (formData.F1020AftHmiindicationOff === commonConstants.ON || formData.F1021AftHmiindicationOn === commonConstants.OFF) {
            return 'FAIL';
        }
        return 'PASS';
    }
    return '';
};
