import { apiAuthParameters } from 'authConfig';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { entries } from 'idb-keyval';
import { msalInstance } from './MsalAuthWrapper/MsalAuthWrapper';
import offlineStore from './OfflineDB';

export function Auth() {
    return getToken();
}

const getToken = async () => {
    const account = getCurrentUser();
    const resp = await msalInstance.acquireTokenSilent({
        scopes: apiAuthParameters.scopes,
        account
    });
    return resp.accessToken;
};

const getCurrentUser = () => {
    const accounts = msalInstance.getAllAccounts();
    return accounts[0];
};

const logoutRequest = {
    account: getCurrentUser()
};

// eslint-disable-next-line no-unused-vars
const addAuthTokenToRequest = async (req) => Auth()
    .then((accessToken) => ({
        ...req,
        headers: {
            ...req.headers,
            Authorization: `Bearer ${accessToken}`,
            'x-correlation_id': uuidv4()
        }
    }))
    .catch(async err => {
        if (navigator.onLine) {
            window.appInsights?.trackException({
                error: err.message,
                exception: err,
                severityLevel: SeverityLevel.Error,
                properties: { ...err }
            });
            const storesOfflineValues = await entries(offlineStore);
            if (storesOfflineValues.length > 0) {
                msalInstance.loginRedirect();
            } else {
                if (logoutRequest?.account?.username?.toLowerCase()?.includes('bp')) {
                    msalInstance.logoutRedirect();
                }
                msalInstance.logoutRedirect(logoutRequest);
            }
        }
    });

// eslint-disable-next-line arrow-body-style
const axiosInterceptor = axios.interceptors.request.use((interceptedReq) => {
    // Do something before request is sent
    // console.info('✉️ ', interceptedReq);
    if (navigator.onLine) {
        return addAuthTokenToRequest(interceptedReq);
    }
    return interceptedReq;
},
    (err) => {
        Promise.reject(err);
        navigator.onLine && window.appInsights?.trackException({
            error: err.message,
            exception: err,
            severityLevel: SeverityLevel.Error,
            properties: { ...err }
        });
    }
);

export default axiosInterceptor;
