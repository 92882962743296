import { Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { formatDate } from 'helpers/DateFormatter';
import { APIEndpoints } from 'helpers/APILists';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import { customSort, trackErrors } from '../helper';
import DropdownInput from '../../elements/DropdownInput';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import ModalFooterActions from '../../elements/ModalFooterActions';

const BpRegionForm = ({ isOpen, title, onClose, rowData, onRecordSubmit, bpRegionOptions }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [cancelAlert, setCancelAlert] = useState(false);
    const [regionList, setRegionList] = useState([]);
    const [regionExistError, setRegionExistError] = useState('');
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const submitButtonVisible = !_.isEqual(rowData, createRowData)
        && regionExistError === ''
        && createRowData.BpRegion1
        && ((createRowData.DraftStatus === 'Active' && createRowData.TimeInDraftAllowed) || createRowData.DraftStatus === 'Inactive')
        && ((createRowData.DraftStatus === 'Active' && createRowData.DraftNotice) || createRowData.DraftStatus === 'Inactive');
        const gridStyle = {
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '0 10px'
          };

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetBpRegionDetails)
            .then((res) => {
                setRegionList(res.data.map(x => ({ RegionId: x.RegionId, BpRegion1: x.BpRegion1 })));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    useEffect(() => {
        setRegionExistError('');
        if (regionList.find(x => x.BpRegion1 === createRowData.BpRegion1 && x.RegionId !== createRowData.RegionId)) {
            setRegionExistError(' *(Region already exists)');
        }
        const regionAlias = bpRegionOptions.find(x => createRowData.BpRegion1 === x.BpRegion)?.BpRegionAlias;
        setCreateRowData({ ...createRowData, BpRegionAlias: regionAlias });
        // eslint-disable-next-line
    }, [createRowData.BpRegion1]);

    const handleInputChange = ((e) => {
        if (e.target.name === 'TimeInDraftAllowed' && (e.target.value < 1 || e.target.value > 720) && e.target.value !== '') {
            return;
        }
        setCreateRowData((prev) => (
            {
                ...prev,
                [e.target.name]: e.target.value
            }
        ));
    });

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const resetButtonHandler = () => {
        setRegionExistError('');
        setCreateRowData(rowData);
    };

    const submitButtonHandler = () => {
        createRowData.Active = createRowData.Active === 'Active';
        createRowData.MratDpsActive = createRowData.MratDpsActive === 'Active';
        createRowData.DraftStatus = createRowData.DraftStatus === 'Active';
        createRowData.ModifiedBy = userName?.name;
        createRowData.LastModifiedDate = formatDate(new Date());
        if (createRowData.RegionId) {
            updateRecord(createRowData);
        } else {
            createRowData.RegionId = 0;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            submitRecord(createRowData);
        }
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_AddBpRegionDetails, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.RegionId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateBpRegionDetails, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.RegionId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}
            <DialogContent>
                <div style={gridStyle}>
                    <DropdownInput
                        name="BpRegion1"
                        title="BP Region (SAP PM)"
                        errorMsg={regionExistError}
                        value={createRowData.BpRegion1}
                        required
                        handleInputChange={handleInputChange}
                        options={customSort(_.uniq(bpRegionOptions.map(x => x.BpRegion)))}
                    />
                    <DropdownInput
                        name="Active"
                        title="SAP Pipeline Run Status"
                        value={createRowData.Active}
                        handleInputChange={handleInputChange}
                        options={[{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }]}
                        required
                    />
                    <DropdownInput
                        name="MratDpsActive"
                        title="DPS Pipeline Run Status"
                        value={createRowData.MratDpsActive}
                        handleInputChange={handleInputChange}
                        options={[{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }]}
                        required
                    />
                    <TextInput
                        name="BpRegionAlias"
                        title="BP Region Alias"
                        value={createRowData?.BpRegionAlias}
                        disabled
                        handleInputChange={handleInputChange}
                        type="string"
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '1px', marginBottom: '10px' }}>
                    <TextInput
                        name="Disclaimer"
                        title="Disclaimer"
                        value={createRowData.Disclaimer}
                        handleInputChange={handleInputChange}
                        isMultiline
                        maxLength="255"
                        width="1080px"
                    />
                </div>
                <div style={gridStyle}>
                    <TextInput
                        name="TimeInDraftAllowed"
                        title="Time in Draft allowed (in minutes, max:720)"
                        value={createRowData?.TimeInDraftAllowed}
                        handleInputChange={handleInputChange}
                        required={createRowData?.DraftStatus === 'Active'}
                        type="number"
                    />
                    <DropdownInput
                        name="DraftStatus"
                        title="Draft Status"
                        value={createRowData.DraftStatus}
                        handleInputChange={handleInputChange}
                        options={[{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }]}
                        required
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '1px', marginBottom: '10px' }}>
                    <TextInput
                        name="DraftNotice"
                        title="Draft Notice"
                        value={createRowData.DraftNotice}
                        handleInputChange={handleInputChange}
                        isMultiline
                        required={createRowData?.DraftStatus === 'Active'}
                        maxLength="255"
                        width="1080px"
                    />
                </div>
                <div style={gridStyle}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData?.RegionId ? createRowData.CreatedBy : userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData?.RegionId ? formatDate(new Date(createRowData.CreatedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData?.RegionId ? formatDate(new Date(createRowData.LastModifiedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '12px' }}>
                    <TextInput
                        name="Comments"
                        title="Comments"
                        value={createRowData.Comments}
                        handleInputChange={handleInputChange}
                        isMultiline
                        width="1080px"
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px', justifyContent: 'space-between' }}>
                <div>
                    <InputLabel className={classes.inputLabel}>
                        <i> Note: If BP Region is not available in the list please add a new record in Group Mapping table. </i>
                    </InputLabel>
                </div>
                <ModalFooterActions
                    submitButtonVisible={submitButtonVisible}
                    submitButtonHandler={submitButtonHandler}
                    resetButtonHandler={resetButtonHandler}
                    cancelButtonHandler={cancelButtonHandler}
                />
            </DialogActions>
        </Dialog>

    );
};

export default BpRegionForm;

BpRegionForm.propTypes = {
    bpRegionOptions: PropTypes.array,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string
};
