import React from 'react';
import { GridToolbarColumnsButton } from '@mui/x-data-grid';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import './style.css';
import { useStyles } from './DataGridToolbar.styled';
import SearchBar from './SearchBar';
import { handleExport } from '../helpers/HandleExport';

const DataGridToolbar = ({ onAddClick, tableLoading, handleColumnSearch, searchFields, isAddVisible, downloadResult }) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.div}>
                <div>
                    <GridToolbarColumnsButton className={classes.columnsButton} />
                    {isAddVisible
                        && (
                            <Button
                                disabled={tableLoading}
                                onClick={onAddClick}
                                className={classes.button}
                                startIcon={<AddIcon />}
                            >
                                Add
                            </Button>
                        )}
                </div>
                <Button
                    disabled={tableLoading}
                    onClick={() => handleExport(downloadResult)}
                    className={classes.downloadButton}
                    startIcon={<GetAppIcon />}
                >
                    Download
                </Button>
            </div>
            <div>
                <SearchBar
                    handleColumnSearch={handleColumnSearch}
                    searchFields={searchFields}
                    loading={tableLoading}
                />
            </div>
        </>

    );
};

export default DataGridToolbar;

DataGridToolbar.propTypes = {
    downloadResult: PropTypes.array,
    handleColumnSearch: PropTypes.func,
    isAddVisible: PropTypes.bool,
    onAddClick: PropTypes.func,
    searchFields: PropTypes.array,
    tableLoading: PropTypes.bool
};
