import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { StyledLabel } from 'elements/Label/Label';
import { attentionLabelDisplay } from './AttentionLabel.data';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

const AttentionLabel = (props) => {
    const { formName } = props;
    const classes = useStyles();
    return (
        <Grid container spacing={1} className={classes.root}>
            {
                attentionLabelDisplay(formName).rows.map(row => (
                    <Grid item container xs={12} key={row.key}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                <StyledLabel
                                    id={field.key}
                                    fontWeight={field.fontWeight}
                                    fontSize={field.fontSize}
                                    textAlign={field.textAlign}
                                    labelWidth={field.labelWidth}
                                    padding={field.padding}
                                    direction={field.direction}
                                    color={field.color}
                                >
                                    {field.label}
                                </StyledLabel>
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    dpsData: Forms.dpsData,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttentionLabel);

AttentionLabel.propTypes = {
    formName: PropTypes.string,
};
