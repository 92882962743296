import { checkURVGreaterThanLRV } from 'helpers/Validation';
import { HMISectionCulatedRangeFormula } from '../P78/P78.formulas';
import { EngineeringUnit } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../C11/C11.data';
import { labelWidth } from '../P710/P710.data';

export const HMISectionDataP74 = (selectedTag, formName, formData) => {
    let processTransmitterReadingLabel;
    if (formName === 'P7-5' || formName === 'P6-3' || formName === 'P6-3.2') {
        processTransmitterReadingLabel = 'Process Transmitter Reading';
    } else {
        processTransmitterReadingLabel = 'Process Transmitter Reading Prior to Test';
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F19HmiReadingPtLabel',
                    label: 'HMI Reading Prior to Test',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F19HmiReadingPt',
                    value: formData.F19HmiReadingPt,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    height: '1.2rem',
                    borderBottom: '0',
                    textAlign: 'center',
                },
                {
                    key: 'F20ProcessTransmitterReadingLabel',
                    label: processTransmitterReadingLabel,
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel
                },
                {
                    key: 'F20ProcessTransmitterReading',
                    value: formData.F20ProcessTransmitterReading,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    borderBottom: '0',
                    textAlign: 'center'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F25InstrumentLrvLabel',
                        label: 'Instrument LRV',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F25InstrumentLrv',
                        value: selectedTag.DateTested ? formData?.F25InstrumentLrv : formData?.F25InstrumentLrv ?? selectedTag?.TsprInstrumentCalibratedLrv,
                        isRequired: true,
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        xs: 2,
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        borderBottom: '0',
                        hasInstError: !checkURVGreaterThanLRV(formData.F25InstrumentLrv, formData.F26InstrumentUrv)
                    },
                    {
                        key: 'F26InstrumentUrvLabel',
                        label: 'Instrument URV',
                        xs: 4,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel
                    },
                    {
                        key: 'F26InstrumentUrv',
                        value: selectedTag.DateTested ? formData?.F26InstrumentUrv : formData?.F26InstrumentUrv ?? selectedTag?.InstrumentCalibratedUrv,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        isRequired: true,
                        width: '100%',
                        labelWidth: '0%',
                        textAlignLabel: 'end',
                        height: '1.2rem',
                        textAlign: 'center',
                        borderBottom: '0',
                        hasInstError: !checkURVGreaterThanLRV(formData.F25InstrumentLrv, formData.F26InstrumentUrv)
                    }]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F27InstrumentCalibratedRangeLabel',
                        label: 'Instrument Calibrated Range',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F27InstrumentCalibratedRange',
                        value: HMISectionCulatedRangeFormula(formData.F25InstrumentLrv, formData.F26InstrumentUrv, formData.F28InstrumentEngineeringUnit),
                        xs: 2,
                        inputWidth: '100%',
                        labelWidth: '0%',
                        readOnly: true,
                        isCalculatedNew: true,
                        borderTop: '0',
                        textAlign: 'center',
                        height: '2rem',
                        marginBottom: '15px',
                        isAutoGrow: true,
                        marginRight: '0',
                    },
                    {
                        key: 'F28InstrumentEngineeringUnitLabel',
                        label: EngineeringUnit.INSTRUMENT_ENGINEERING_UNIT_EU,
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        labelWidth,
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel
                    },
                    {
                        key: 'F28InstrumentEngineeringUnit',
                        value: selectedTag.DateTested ? formData?.F28InstrumentEngineeringUnit : formData?.F28InstrumentEngineeringUnit ?? selectedTag?.TsprInstrumentEngineeringUnits,
                        xs: 2,
                        inputWidth: '100%',
                        labelWidth: '0%',
                        textAlignLabel: 'end',
                        marginRight: '0',
                        height: '1.2rem',
                        borderTop: '0',
                        textAlign: 'center',
                        marginBottom: '15px',
                        isRequired: true,
                        maxLength: '17',
                        direction: 'rtl',
                        isAutoGrow: true,
                    }]
            }
        ]
    };
};
