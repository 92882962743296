import axios from 'axios';
import DataGridComponent from 'components/DataGrid/DataGridComponent';
import { APIEndpoints } from 'helpers/APILists';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { trackErrors } from '../helper';
import { dpsHeaderData, dpsMappingApiEndpoint, dpsMappingInitialState, dpsMappingSearchState } from './DpsMapping.data';
import DpsMappingForm from './DpsMappingForm';

const addTitle = 'Add New Record for DPS Mapping';
const editTitle = 'Edit Record for DPS Mapping';

const DpsMapping = () => {
    const apiEndpoints = dpsMappingApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(dpsMappingInitialState);
    const [modalTitle, setModalTitile] = useState(addTitle);
    // the below object is being used in datagrid to refresh the table on add/update
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    // to highlight the updated or newly added row
    const [rowDetails, setRowDetails] = useState({
        columnName: 'DpsMappingDetailId',
        rowNumber: ''
    });
    const [dropdownsData, setDropdownsData] = useState({
        formNameOptions: [],
        formHeadingOptions: [],
        dpsFieldOptions: [],
        vwFieldNameOptions: [],
        inputFieldTypeIdOptions: [],
        userInputTypeIdOptions: [],
        mratTrfTableNameOptions: []
    });
    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetTrfMasterLists)
            .then((res) => {
                setDropdownsData(prev => ({
                    ...prev,
                    formNameOptions: res.data.filter(y => y.Status === 'Active').sort((a, b) => a.TrfId.localeCompare(b.TrfId, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })).map(x => x.TrfId),
                    mratTrfTableNameOptions: res.data.map(x => ({ TrfId: x.TrfId, TableName: x.MratTrfTableName }))
                }));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetDpsMappingDetails)
            .then((res) => {
                setDropdownsData(prev => ({
                    ...prev,
                    formHeadingOptions: _.compact(_.uniq(res.data.map(x => x.FormHeading))).sort()
                }));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, [refreshDatagrid]);

    useEffect(() => {
        axios.get(`${APIEndpoints.ADMIN_GetColumnNames}/DEVICE_PERFORMANCE_STANDARDS`)
            .then((res) => {
                setDropdownsData(prev => ({
                    ...prev,
                    dpsFieldOptions: res.data.sort()
                }));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    useEffect(() => {
        axios.get(`${APIEndpoints.ADMIN_GetColumnNames}/vwSearchTag`)
            .then((res) => {
                setDropdownsData(prev => ({
                    ...prev,
                    vwFieldNameOptions: res.data.sort()
                }));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    const parseData = (data) => new Promise((resolve, reject) => {
        (async () => {
            try {
                const inputFieldResponse = await axios.get(APIEndpoints.ADMIN_GetInputFieldTypes);
                const userInputResponse = await axios.get(APIEndpoints.ADMIN_GetUserInputTypes);
                const result = data.map((res) => ({
                    ...res,
                    InputFieldType: inputFieldResponse.data.find(x => x.InputFieldTypeId === res.InputFieldTypeId)?.Type,
                    UserInputType: userInputResponse.data.find(y => y.UserInputTypeId === res.UserInputTypeId)?.Type
                }));
                setDropdownsData(prev => ({
                    ...prev,
                    inputFieldTypeIdOptions: _.sortBy(inputFieldResponse.data.filter(y => y.Status === 'Active').map(x => ({
                        label: x.Type,
                        value: x.InputFieldTypeId
                    })), 'label'),
                    userInputTypeIdOptions: _.sortBy(userInputResponse.data.filter(y => y.Status === 'Active').map(x => ({
                        label: x.Type,
                        value: x.UserInputTypeId,
                        InputFieldTypeId: x.InputFieldTypeId
                    })), 'label')
                }));
                resolve(result);
            } catch (err) {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
                reject();
            }
        })();
    });

    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitile(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };

    const handleAddRecord = () => {
        setEditRowData(dpsMappingInitialState);
        setModalTitile(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
        setRefreshDatagrid(prev => !prev);
    };

    return (
        <>
            <DataGridComponent
                headerData={dpsHeaderData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                initialData={dpsMappingSearchState}
                rowDetails={rowDetails}
                dataParser={parseData}
                refreshDatagrid={refreshDatagrid}
            />

            {showModal
                && (
                    <DpsMappingForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        dropdownsData={dropdownsData}
                        onRecordSubmit={handleRecordSubmit}
                    />
                )}
        </>
    );
};

export default DpsMapping;
