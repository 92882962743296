import { ValidateNumberField, FieldHasValue } from 'helpers/Validation';

export const AsLeftTableF81AsLeftResultFormula = (formData) => {
    if ((formData.F338PhysicalValvePositionPriortotest === 'Closed'
        && formData.F350ValveFullStrokeLocalPosition2 === 'Open')
        || (formData.F338PhysicalValvePositionPriortotest === 'Open'
            && formData.F350ValveFullStrokeLocalPosition2 === 'Closed')) {
        if (FieldHasValue(formData.F344ValveTravelTimeRequirement)
            && FieldHasValue(formData.F351ValveFullStrokeTravelTime2)) {
            if (ValidateNumberField(formData.F351ValveFullStrokeTravelTime2) <= ValidateNumberField(formData.F344ValveTravelTimeRequirement)) {
                return 'PASS';
            }
            return 'FAIL';
        }
    } else if ((FieldHasValue(formData.F338PhysicalValvePositionPriortotest)
        && FieldHasValue(formData.F350ValveFullStrokeLocalPosition2))
        || FieldHasValue(formData.F338PhysicalValvePositionPriortotest) === 'Intermediate') {
        return 'FAIL';
    }
    return '';
};

export const disableALTValveFullStrokeTravelTime = (formData) => {
    if (formData.F61AsFoundResult !== 'PASS') {
        if ((formData.F338PhysicalValvePositionPriortotest === 'Closed'
            && formData.F350ValveFullStrokeLocalPosition2 === 'Open')
            || (formData.F338PhysicalValvePositionPriortotest === 'Open'
                && formData.F350ValveFullStrokeLocalPosition2 === 'Closed')) {
            return false;
        }
        return (
            (FieldHasValue(formData.F338PhysicalValvePositionPriortotest) === 'Intermediate')
            || (FieldHasValue(formData.F338PhysicalValvePositionPriortotest)
            && FieldHasValue(formData.F350ValveFullStrokeLocalPosition2))
        );
    }
    return true;
};
