export const userInputTypeData = [
    {
        field: 'UserInputTypeId',
        headerName: 'User Input Type ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Type',
        headerName: 'User Input Type',
        width: '250',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'Description',
        headerName: 'Description',
        width: '220',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'InputFieldType',
        headerName: 'Input Field Type',
        width: '210',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '100',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '150',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const userInputTypeInitialState = {
    UserInputTypeId: '',
    Type: '',
    Description: '',
    InputFieldTypeId: '',
    Status: 'Inactive',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: '',
};

export const searchDataInitialState = {
    UserInputTypeId: '',
    Type: '',
    Description: '',
    InputFieldType: '',
    Status: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: '',
};

export const userInputTypeApiEndpoint = {
    read: 'ADMIN_GetUserInputTypes',
    create: 'ADMIN_SubmitUserInputTypeRecord',
    update: 'ADMIN_UpdateUserInputTypeRecord'
};
