export const DamperTravelSectionDataM613 = (selectedTag, dpsData, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F363DamperTravelTimeRequirement',
                    label: 'Damper Travel Time Requirement (sec)',
                    value: selectedTag.DateTested ? formData?.F363DamperTravelTimeRequirement : formData?.F363DamperTravelTimeRequirement ?? selectedTag?.DesignElementResponseTime,
                    xs: 12,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    marginRight: '0.5rem',
                    width: '28.6%',
                    direction: 'rtl',
                    labelWidth: '69%',
                    textAlign: 'center',
                },
            ]
        }
    ]

});

export const selectValveTravelSectionData = (selectedTag, dpsData, formName, formData) => {
    let filterViewData = { rows: [] };
    if (formName === 'M6-13') {
        filterViewData = DamperTravelSectionDataM613(selectedTag, dpsData, formData);
    }
    return filterViewData;
};
