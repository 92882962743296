import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';
import { commonConstants } from 'journeys/portal/Forms/Sections.constants';

const passFailFormula = (formData) => (ValidateNumberField(formData.F195ActualFailHigh) >= ValidateNumberField(formData.F33FailureModeHc * 0.99) ? commonConstants.PASS_Result : commonConstants.FAIL_Result);
const passFailFormula2 = (formData) => (ValidateNumberField(formData.F196ActualFailLow) <= ValidateNumberField(formData.F36FailureModeLc * 1.01) ? commonConstants.PASS_Result : commonConstants.FAIL_Result);

export const PassFailFormula1 = (formData) => (FieldHasValue(formData.F195ActualFailHigh)
    && FieldHasValue(formData.F33FailureModeHc) ? passFailFormula(formData) : '');

export const PassFailFormula2 = (formData) => (FieldHasValue(formData.F196ActualFailLow)
    && FieldHasValue(formData.F36FailureModeLc) ? passFailFormula2(formData) : '');

export const isFailModeHighCurrentDisabled = (formData) => ((FieldHasValue(formData?.F36FailureModeLc)
    || FieldHasValue(formData?.F196ActualFailLow)) && !(FieldHasValue(formData?.F33FailureModeHc)
        || FieldHasValue(formData?.F195ActualFailHigh)));

export const isActualFailHighRequired = (selectedTag, formData) => selectedTag?.FailureModeHighCurrentMA
    || (!selectedTag?.FailureModeHighCurrentMA && !selectedTag?.F36FailureModeLc)
    || !(FieldHasValue(formData?.F36FailureModeLc) && FieldHasValue(formData?.F196ActualFailLow));

export const isActualFailLowRequired = (selectedTag, formData) => selectedTag?.FailureModeLowCurrentMA
    || (!selectedTag?.FailureModeHighCurrentMA && !selectedTag?.F36FailureModeLc)
    || !(FieldHasValue(formData?.F33FailureModeHc) && FieldHasValue(formData?.F195ActualFailHigh));

export const isFailModeLowCurrentDisabled = (formData) => ((FieldHasValue(formData?.F33FailureModeHc)
    || FieldHasValue(formData?.F195ActualFailHigh)) && !(FieldHasValue(formData?.F36FailureModeLc)
        || FieldHasValue(formData?.F196ActualFailLow)));

export const isFailureModeHighCurrentRequired = (selectedTag, formData) => (FieldHasValue(formData?.F33FailureModeHc)
    && FieldHasValue(formData?.F36FailureModeLc)) || !(FieldHasValue(formData?.F36FailureModeLc)
        && (!selectedTag?.FailureModeLowCurrentMA && !selectedTag?.FailureModeHighCurrentMA))
    || (selectedTag?.FailureModeLowCurrentMA && selectedTag?.FailureModeHighCurrentMA) || (selectedTag?.FailureModeHighCurrentMA);

export const isFailureModeLowCurrentRequired = (selectedTag, formData) => (FieldHasValue(formData?.F33FailureModeHc)
    && FieldHasValue(formData?.F36FailureModeLc)) || !(FieldHasValue(formData?.F33FailureModeHc)
        && (!selectedTag?.FailureModeLowCurrentMA && !selectedTag?.FailureModeHighCurrentMA)) || (selectedTag?.FailureModeLowCurrentMA
            && selectedTag?.FailureModeHighCurrentMA) || (selectedTag?.FailureModeLowCurrentMA);
