export const VisualInspectSectionDataP727 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F119VisualInspection',
                label: `Visually inspect instrument tagging, instrument wiring connection/terminations, 
                        instrument condition, pressure regulator setting and condition, 
                        quarter turn indication position correct if applicable, 
                        bug screen orientation with no blockage, general deterioration and no leak sign. 
                        Record pressure of pneumatic/hydraulic supply to valve actuator.`,
                value: formData.F119VisualInspection,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit',
            }]
        }
    ]

});
