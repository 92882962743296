/* eslint-disable import/no-anonymous-default-export */
import Home from './Home/Home.reducer';
import DPS from './SubSections/DPS/DPS.reducer';
import TagSelectionMOScreen from './TagSelectionMOScreen/TagSelectionMOScreen.reducer';
import MOSelection from './MOSelection/MOSelection.reducer';
import Forms from './Forms/Forms.reducer';
import TestEquipment from './TestEquipment/TestEquipment.reducer';
import SavedTRF from './SavedTRFPrintScreen/SavedTRFPrintScreen.reducers';

export default {
    Home,
    DPS,
    TagSelectionMOScreen,
    MOSelection,
    Forms,
    TestEquipment,
    SavedTRF
};
