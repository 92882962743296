import { AsFoundResultFormulaM63andM64 } from './M63.formulas';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';

export const AsFoundTestTableSectionDataM63 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F411AftConfirmTestTorch',
                    label: 'Confirm that test torch has been shined into the detector',
                    value: formData.F411AftConfirmTestTorch,
                    xs: 6,
                    isRequired: true,
                    labelWidth: '57.5%',
                    width: '42.5%',
                    isDropdown: true,
                    placeholder: dropdownPlaceholder,
                    options: [{ name: dropdownPlaceholder, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    marginLeft: '8px',
                    marginRight: '0.5rem',
                    inputTextAlign: 'center',
                    height: '1.96rem',
                    borderBottom: '0',
                    paddingPlaceholder: '4px',
                    arrowMarginTop: '4px'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F372AftHmistatus1',
                    label: 'HMI Status',
                    value: formData.F372AftHmistatus1,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'Select HMI Status',
                    options: [{ name: 'Select HMI Status', value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'FAULT', value: 'FAULT' }],
                    labelWidth: '57.5%',
                    width: '42.5%',
                    marginLeft: '8px',
                    marginRight: '0.5rem',
                    inputTextAlign: 'center',
                    xs: 6,
                    borderBottom: '0'
                },
                {
                    key: 'nodata',
                    isLabel: true,
                    xs: 1
                },
                {
                    key: 'F102AftResult',
                    label: 'As-Found Result',
                    value: AsFoundResultFormulaM63andM64(formData),
                    readOnly: true,
                    xs: 5,
                    width: '55%',
                    labelWidth: '45%',
                    isCalculatedNew: true,
                    textAlign: 'center',
                    height: '1.1rem',
                    borderRight: '0',
                    marginRight: '8px',
                    direction: 'rtl',
                    inputMarginRight: '4px',
                    isLabelBold: true,
                    fontWeight: '700'
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F412AftConfirmCctvvideoOnHmi',
                    label: 'Confirm that CCTV video image appeared on HMI (or dedicated screen)',
                    value: formData.F412AftConfirmCctvvideoOnHmi,
                    xs: 6,
                    labelWidth: '57.5%',
                    width: '42.5%',
                    marginLeft: '8px',
                    marginRight: '0.5rem',
                    isDropdown: true,
                    placeholder: dropdownPlaceholder,
                    options: [{ name: dropdownPlaceholder, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    inputTextAlign: 'center',
                    marginBottom: '15px',
                    height: '2rem',
                    paddingPlaceholder: '5px',
                    arrowMarginTop: '5px'
                }]
        },
        {
            key: 5,
            fields: [
                {
                    key: 'F376CleanDetectorLensesIfVisiblyDirty',
                    label: 'Clean detector lenses if visibly dirty',
                    value: formData.F376CleanDetectorLensesIfVisiblyDirty,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'Select Clean detector lenses',
                    options: [{ name: 'Select Clean detector lenses', value: '' },
                    { name: 'CLEANED', value: 'CLEANED' },
                    { name: 'NOT REQUIRED', value: 'NOT REQUIRED' }],
                    labelWidth: '57.5%',
                    width: '42.5%',
                    marginLeft: '8px',
                    marginRight: '0.5rem',
                    inputTextAlign: 'center',
                    xs: 6,
                    fontSize: '8.9pt',
                    borderBottom: '0',
                    marginBottom: '4px'
                },
                {
                    key: 'detectorLenses',
                    label: 'If detector lenses is cleaned then repeat the test',
                    isLabel: true,
                    padding: '5px 0 5px 4px',
                    xs: 6,
                    color: '#ea7c34',
                    fontSize: '8.5pt',
                    fontStyle: 'italic',
                    fontWeight: '600'
                },
            ]
        }
    ]
});
