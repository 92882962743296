import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormInputViewOnly } from 'elements/Inputs/FormInput/FormInputViewOnly';
import { StyledLabel } from 'elements/Label/Label';
import { FormDropdown } from 'elements/Inputs/FormInput/Dropdown/Dropdown';
import { FormInputMultiline, FormInput, FormInputBlur } from 'elements/Inputs/FormInput';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { DeleteButton, StyleDropdown, StyleInput } from '../AsFoundTestTableSection/AsFoundTestSection.styled';
import { selectFlowAlarmTestData } from './FlowAlarmTest.data';
import { keyName } from './FlowAlarmTest.constants';
import { errorMessageMap } from '../../Sections.constants';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

const FlowAlarmTestSection = (props) => {
    const { changeHandler, formName, isFormReadOnly, userRole, formData, updateFormData, flowAlarmTestPoint } = props;
    const keyData = keyName?.filter((key) => Object.keys(key)?.includes(formName));
    const classes = useStyles();
    const formDeleteButton = (field) => field.isDelete
        ? (
            <DeleteButton>
                <IconButton style={{ padding: '0' }} aria-label="delete" onClick={() => { updateFormData(field.key, keyData[0][formName], 'flowAlarm'); }}>
                    <DeleteIcon style={{ color: '#498205', fontSize: '1rem' }} id={`delete-${field.key}`} />
                </IconButton>
            </DeleteButton>
        ) : (
            <StyleInput
                formName={formName}
            >
                <FormInput
                    id={field.key}
                    name={field.key}
                    label={field.label}
                    value={field.value}
                    isCalculated={field.isCalculated}
                    isCalculatedNew={field.isCalculatedNew}
                    direction={field.direction}
                    isRequired={field.isRequired}
                    handleChange={changeHandler}
                    type={field.type}
                    width={field.width}
                    padding={field.padding}
                    labelWidth={field.labelWidth}
                    marginLeft={field.marginLeft}
                    textAlign={field.textAlign}
                    readOnly={field.readOnly || isFormReadOnly}
                    maxLength={field.maxLength}
                    marginRight={field.marginRight}
                    borderRight={field.borderRight}
                    height={field.height}
                    marginBottom={field.marginBottom}
                    borderBottom={field.borderBottom}
                    borderTop={field.borderTop}
                    borderLeft={field.borderLeft}
                    inputMarginRight={field.inputMarginRight}
                    marginTop={field.marginTop}
                    userRole={userRole}
                    isLabelNew={field.isLabelNew}
                    isLabelBold={field.isLabelBold}
                    fontWeight={field.fontWeight}
                    isDisabled={field.isDisabled}
                    hasDPSError={field.hasDPSError}
                    textAlignLabel={field.textAlignLabel}
                    inputMarginLeft={field.inputMarginLeft}
                    fontColor={field.fontColor}
                    isDeleteLabel={field.isDeleteLabel}
                    color={field.color}
                    fontSize={field.fontSize}
                    hasAftTestGasConcentrationError={field.hasAftTestGasConcentrationError}
                    testGasConcentrationMessage={field.testGasConcentrationMessage}
                />
            </StyleInput>
        );
    const formInputBlur = (field) => field.isBlur
        ? (
            <FormInputBlur
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                isCalculated={field.isCalculated}
                isCalculatedNew={field.isCalculatedNew}
                direction={field.direction}
                isRequired={field.isRequired}
                handleChange={changeHandler}
                type={field.type}
                width={field.width}
                padding={field.padding}
                labelWidth={field.labelWidth}
                marginLeft={field.marginLeft}
                textAlign={field.textAlign}
                readOnly={field.readOnly || isFormReadOnly}
                maxLength={field.maxLength}
                marginRight={field.marginRight}
                borderRight={field.borderRight}
                height={field.height}
                marginBottom={field.marginBottom}
                borderBottom={field.borderBottom}
                borderTop={field.borderTop}
                borderLeft={field.borderLeft}
                inputMarginRight={field.inputMarginRight}
                marginTop={field.marginTop}
                userRole={userRole}
                isLabelNew={field.isLabelNew}
                isLabelBold={field.isLabelBold}
                fontWeight={field.fontWeight}
                isDisabled={field.isDisabled}
                hasDPSError={field.hasDPSError}
                textAlignLabel={field.textAlignLabel}
                inputMarginLeft={field.inputMarginLeft}
                displayPopUp={field.displayPopUp}
                hasTestPointError={field.hasTestPointError}
                testPointErrorMessage={errorMessageMap[formName] || errorMessageMap.default}
            />
        ) : formDeleteButton(field);
    const formMultilineInput = (field) => field.isMultiLine
        ? (
            <FormInputMultiline
                id={field.key}
                name={field.key}
                value={field.value}
                maxLength={field.maxLength}
                handleChange={changeHandler}
                type={field.type}
                inputWidth={field.inputWidth}
                userRole={userRole}
                width={field.labelWidth}
                marginRight="0"
                borderBottom={field.borderBottom}
                borderTop={field.borderTop}
                borderLeft={field.borderLeft}
                borderRight={field.borderRight}
                inputMarginLeft={field.inputMarginLeft}
                inputMarginRight={field.inputMarginRight}
                readOnly={field.readOnly || isFormReadOnly}
                isDisabled={field.isDisabled}
            />
        )
        : formInputBlur(field);
    const formDropdownInput = (field) => field.isDropdown ? (
        <StyleDropdown
            formName={formName}
        >
            <FormDropdown
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                placeholder={field.placeholder}
                handleChange={changeHandler}
                options={field.options}
                isRequired={field.isRequired}
                width={field.width}
                inputTextAlign={field.inputTextAlign}
                labelWidth={field.labelWidth}
                direction={field.direction}
                readOnly={field.readOnly || isFormReadOnly}
                isDisabled={field.isDisabled}
                marginBottom={field.marginBottom}
                marginLeft={field.marginLeft}
                marginRight={field.marginRight}
                borderBottom={field.borderBottom}
                height={field.height}
                marginTop={field.marginTop}
                borderTop={field.borderTop}
                paddingPlaceholder={field.paddingPlaceholder}
                arrowMarginTop={field.arrowMarginTop}
                fontSize={field.fontSize}
                borderRight={field.borderRight}
                rootMarginRight={field.rootMarginRight}
                noDefaultOption={field.noDefaultOption}
                inputMarginRight={field.inputMarginRight}
                borderLeft={field.borderLeft}
                subMenu={field.subMenu}
            />
        </StyleDropdown>
    )
        : formMultilineInput(field);

    const formInputView = (field) => field.isInfo ? (
        <FormInputViewOnly
            id={field.key}
            name={field.key}
            label={field.label}
            value={field.value}
            fontStyle={field.fontStyle}
            labelFontStyle={field.labelFontStyle}
            width={field.width}
            height={field.height}
            wordBreak={field.wordBreak}
            labelWidth={field.labelWidth}
            noWrap={field.noWrap}
            field={field.padding}
            padding={field.padding}
            marginBottom={field.marginBottom}
            marginTop={field.marginTop}
            valueMarginTop={field.valueMarginTop}
            textAlign={field.textAlign}
            labelTextAlign={field.labelTextAlign}
        />
    )
        : formDropdownInput(field);
    return (
        <Grid container spacing={1} className={classes.root}>
            {
                selectFlowAlarmTestData(formName, formData, flowAlarmTestPoint).rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ padding: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isLabel
                                    ? (
                                        <StyledLabel
                                            id={field.key}
                                            label={field.label}
                                            color={field.color}
                                            fontWeight={field.fontWeight}
                                            fontSize={field.fontSize}
                                            textAlign={field.textAlign}
                                            fontStyle={field.fontStyle}
                                            labelWidth={field.labelWidth}
                                            marginLeft={field.marginLeft}
                                            padding={field.padding}
                                        >
                                            {field.label}
                                        </StyledLabel>
                                    )
                                    : formInputView(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FlowAlarmTestSection);

FlowAlarmTestSection.propTypes = {
    changeHandler: PropTypes.func,
    flowAlarmTestPoint: PropTypes.number,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    updateFormData: PropTypes.func,
    userRole: PropTypes.string,
};
