import { dropdownOptions } from '../S71/S71.data';

export const FlowLevelTestSectionDataS74 = (formName, formData, multiTRFDetails) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F956TrfTypeSelectionLabel',
            label: 'Flow or Level Test?',
            xs: 4,
            isLabel: true,
            fontWeight: '600',
            fontSize: '9pt',
            padding: '14px 0 15px 0',
        },
        {
            key: 'F956TrfTypeSelection',
            value: formData.F956TrfTypeSelection,
            isRequired: true,
            isDropdown: true,
            placeholder: 'Select',
            options: [{ name: 'Select', value: '' }, ...dropdownOptions(formName, multiTRFDetails)],
            xs: 2,
            width: '100%',
            labelWidth: '0%',
            marginTop: '15px',
            marginBottom: '15px'
        }]
    }
    ]
});
