import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputWrapper, StyledTextArea, StyledMultilineLabel } from './Inputs.styled';

export const FormInputMultiline = (props) => {
    const { isDisabled, id, isRequired, label, type, name, value, handleChange, rows,
        readOnly, width, marginLeft, borderRight, padding, marginRight, marginTop, direction,
        inputWidth, alignItems, borderBottom, borderLeft, borderTop, inputMarginLeft,
        inputMarginRight, maxLength } = props;
    const [inputValue, handleValue] = useState(value || '');
    const isFirstRun = useRef(true);
    useEffect(() => {
        let timeOutId;
        if (isFirstRun.current) {
            isFirstRun.current = false;
        } else {
            timeOutId = setTimeout(() => handleChange({ [name]: inputValue }), 500);
        }
        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);
    useEffect(() => {
        handleValue(value);
    }, [value]);
    const handleTextValue = (e) => {
        const newTextValue = e.target.value?.replace(/^\s+/, '');
        if (newTextValue.length <= (maxLength ?? 255)) {
            handleValue(newTextValue);
        }
    };
    return (
        <InputWrapper alignItems={alignItems} marginTop={marginTop}>
            <StyledMultilineLabel
                width={width}
                marginLeft={marginLeft}
                marginRight={marginRight}
                padding={padding}
                direction={direction}
            >
                {label}
            </StyledMultilineLabel>
            <StyledTextArea
                id={id}
                rows={rows || 2}
                type={type || 'text'}
                value={typeof inputValue === 'number' ? inputValue ?? '' : inputValue?.replace(/^\s+/, '') ?? ''}
                onChange={(e) => handleTextValue(e)}
                isRequired={isRequired}
                readOnly={readOnly}
                inputWidth={inputWidth}
                isDisabled={isDisabled}
                borderRight={borderRight}
                borderLeft={borderLeft}
                autoComplete="off"
                borderTop={borderTop}
                borderBottom={borderBottom}
                marginLeft={inputMarginLeft}
                marginRight={inputMarginRight}
            />
        </InputWrapper>
    );
};

FormInputMultiline.propTypes = {
    alignItems: PropTypes.string,
    borderBottom: PropTypes.string,
    borderLeft: PropTypes.string,
    borderRight: PropTypes.string,
    borderTop: PropTypes.string,
    direction: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    inputMarginLeft: PropTypes.string,
    inputMarginRight: PropTypes.string,
    inputWidth: PropTypes.string,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    label: PropTypes.string.isRequired,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    marginTop: PropTypes.string,
    maxLength: PropTypes.string,
    name: PropTypes.string.isRequired,
    padding: PropTypes.string,
    readOnly: PropTypes.bool,
    rows: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.any,
    width: PropTypes.string
};
