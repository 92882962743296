import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { MESSAGE_TYPE } from '../../../../common/common.constants';
import { APIEndpoints } from '../../../../../helpers/APILists';
import { trackErrors } from '../helper';
import { useMsal } from '@azure/msal-react';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { formatDate } from 'helpers/DateFormatter';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import AutocompleteInput from '../../elements/AutocompleteInput';
import DropdownInput from '../../elements/DropdownInput';
import ModalFooterActions from '../../elements/ModalFooterActions';

const CorrectiveDPSMappingForm = ({ isOpen, title, onClose, rowData, onRecordSubmit, dropdownsData, trfDetails }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [cancelAlert, setCancelAlert] = useState(false);
    const [correctiveDPSMappingList, setCorrectiveDPSMappingList] = useState([]);
    const [uniqueRecordError, setUniqueRecordError] = useState('');
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const trfID = trfDetails.find(x => x?.TrfId === createRowData?.Trf)?.RowId;
    const submitButtonVisible = !_.isEqual(rowData, createRowData) && uniqueRecordError === ''
        && createRowData.Trf
        && createRowData.CorrectiveTrf
        && createRowData.Status;

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetCorrectiveDPSMapping)
            .then((res) => {
                setCorrectiveDPSMappingList(res.data);
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    const handleInputChange = ((e) => {
        setCreateRowData((prev) => (
            {
                ...prev,
                [e.target.name]: e.target.value
            }
        ));
    });

    const handleAutocompleteChange = ((e, val) => {
        const value = val || null;
        const name = e.target.id.split('-')[0];
        setCreateRowData((prev) => (
            {
                ...prev,
                [name]: value
            }
        ));
    });

    const handleBlur = () => {
        setUniqueRecordError('');
        if (correctiveDPSMappingList
            .filter(x => x.Trfid === trfID && x.CorrectiveDpsmappingId !== createRowData.CorrectiveDpsmappingId)
            .length >= 1) {
            setUniqueRecordError('(*TRF ID exists)');
        }
    };

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const resetButtonHandler = () => {
        setUniqueRecordError('');
        setCreateRowData(rowData);
    };

    const submitButtonHandler = () => {
        createRowData.Trfid = trfID;
        createRowData.CorrectiveTrfid = trfDetails.find(x => x?.TrfId === createRowData?.CorrectiveTrf)?.RowId;
        createRowData.Status = createRowData.Status === 'Active';
        createRowData.ModifiedBy = userName?.name;
        createRowData.LastModifiedDate = formatDate(new Date());
        createRowData.Trf = null;
        createRowData.CorrectiveTrf = null;
        if (createRowData.CorrectiveDpsmappingId) {
            updateRecord(createRowData);
        } else {
            createRowData.CorrectiveDpsmappingId = 0;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            submitRecord(createRowData);
        }
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_AddCorrectiveDPSMapping, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.CorrectiveDpsmappingId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateCorrectiveDPSMapping, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.CorrectiveDpsmappingId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };
    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <AutocompleteInput
                        name="Trf"
                        title="TRF ID"
                        value={createRowData.Trf}
                        required
                        handleInputChange={handleAutocompleteChange}
                        options={dropdownsData.trfIdOptions}
                        errorMsg={uniqueRecordError}
                        handleBlur={handleBlur}
                    />
                    <AutocompleteInput
                        name="CorrectiveTrf"
                        title="Corrective TRF ID"
                        value={createRowData.CorrectiveTrf}
                        required
                        handleInputChange={handleAutocompleteChange}
                        options={dropdownsData.correctiveTrfIdOptions}
                    />
                    <DropdownInput
                        name="Status"
                        title="Status"
                        value={createRowData.Status}
                        options={[{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }]}
                        handleInputChange={handleInputChange}
                        required
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '1px', marginBottom: '10px' }}>
                    <TextInput
                        name="BusinessRule"
                        title="Business Rule"
                        value={createRowData?.BusinessRule}
                        isMultiline
                        handleInputChange={handleInputChange}
                        width="1080px"
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData?.CorrectiveDpsmappingId ? createRowData.CreatedBy : userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData?.CorrectiveDpsmappingId ? formatDate(new Date(createRowData.CreatedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData?.CorrectiveDpsmappingId ? formatDate(new Date(createRowData.LastModifiedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '12px' }}>
                    <TextInput
                        name="Comments"
                        title="Comments"
                        value={createRowData.Comments}
                        handleInputChange={handleInputChange}
                        isMultiline
                        width="1080px"
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px', justifyContent: 'space-between' }}>
                <div>
                    <InputLabel className={classes.inputLabel}>
                        <i> Note: If TRF ID or Corrective TRF ID is not available in the list please add a new record in TRF Master List table.</i>
                    </InputLabel>
                </div>
                <ModalFooterActions
                    submitButtonVisible={submitButtonVisible}
                    submitButtonHandler={submitButtonHandler}
                    resetButtonHandler={resetButtonHandler}
                    cancelButtonHandler={cancelButtonHandler}
                />
            </DialogActions>
        </Dialog>
    );
};

export default CorrectiveDPSMappingForm;

CorrectiveDPSMappingForm.propTypes = {
    dropdownsData: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string,
    trfDetails: PropTypes.array
};
