import { AFTF54DeviationEu1Formula, AsFoundAftResultFormula } from './P69.formulas';

export const AsFoundTestTableSectionDataP861P69 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AsFoundTest',
                label: 'As-Found Test',
                isLabel: true,
                fontWeight: '700',
                padding: '8px 0 0 6px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: '%-HMI-InstLabel',
                label: '% - HMI - Inst.',
                xs: 3,
                fontWeight: '700',
                fontSize: '9pt',
                padding: '5px 0 0 6px',
                labelWidth: '100%',
                isLabel: true
            },
            {
                key: 'AftAnalyzerReadingLabel',
                label: 'Analyzer Reading',
                xs: 2,
                fontWeight: '700',
                textAlign: 'center',
                fontSize: '9pt',
                padding: '5px 0 0 6px',
                labelWidth: '100%',
                isLabel: true
            },
            {
                key: 'NodataP610',
                isLabel: true,
                xs: 2
            },
            {
                key: 'HmiReading',
                label: 'HMI Reading',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                padding: '5px 5px 0 0',
                labelWidth: '100%',
                textAlign: 'center',
                fontSize: '9pt'
            },
            {
                key: 'Deviation',
                label: 'Deviation (EU)',
                isLabel: true,
                xs: 3,
                fontWeight: '700',
                padding: '5px 5px 0 0',
                labelWidth: '100%',
                textAlign: 'center',
                fontSize: '9pt'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'ReferenceCalibrationGasLabel',
                value: 'Reference Calibration Gas (EU)',
                xs: 3,
                height: '1.2rem',
                readOnly: true,
                marginLeft: '4px',
                labelWidth: '0',
                width: '100%',
                isLabelNew: true,
                borderLeft: '0',
                borderRight: '0'
            },
            {
                key: 'F356AftAnalyzerReading',
                value: formData.F356AftAnalyzerReading,
                isRequired: true,
                maxLength: '10',
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                xs: 2,
                textAlign: 'center'
            },
            {
                key: 'NodataP610',
                xs: 2,
                isLabel: true
            },
            {
                key: 'F48HmiReading1',
                value: formData.F48HmiReading1,
                xs: 2,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                textAlign: 'center'
            },
            {
                key: 'F54DeviationEu1',
                value: AFTF54DeviationEu1Formula(formData),
                xs: 3,
                type: 'number',
                readOnly: true,
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                borderLeft: '0',
                borderRight: '0',
                height: '1.2rem',
                inputMarginRight: '4px',
                textAlign: 'center'
            }]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'ToleranceEULabel',
                    value: 'Tolerance (EU)',
                    xs: 3,
                    height: '1.2rem',
                    readOnly: true,
                    marginLeft: '4px',
                    labelWidth: '0',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderRight: '0',
                    borderTop: '0',
                    isLabelNew: true,
                    marginBottom: '4px',
                    width: '100%'
                },
                {
                    key: 'F101AftTolerance',
                    value: selectedTag.DateTested ? formData?.F101AftTolerance : formData?.F101AftTolerance ?? selectedTag?.ToleranceInInstrumentEu,
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    height: '1.2rem',
                    width: '100%',
                    labelWidth: '0',
                    borderTop: '0',
                    borderBottom: '0',
                    marginBottom: '4px',
                    textAlign: 'center'
                },
                {
                    key: 'F102AftResultLabel',
                    label: 'As-Found Result',
                    isLabel: true,
                    xs: 2,
                    padding: '5px 0 0 0',
                    fontWeight: '700',
                    fontSize: '9pt',
                    labelWidth: '97%',
                    textAlign: 'end'
                },
                {
                    key: 'F102AftResult',
                    value: AsFoundAftResultFormula(formData),
                    xs: 2,
                    readOnly: true,
                    isCalculatedNew: true,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    marginBottom: '4px',
                    borderBottom: '0',
                    borderTop: '0'
                }
            ]
        }
    ]

});
