import axios from 'axios';
import { APIEndpoints } from 'helpers/APILists';
import { COMMON_CONSTANTS } from './common.constants';

export const updateApplicationOnlineStatus = (MRATStatus) => dispatch => {
    dispatch({
        type: COMMON_CONSTANTS.MRAT_ONLINEOFFLINESTATUSUPDATE,
        payload: {
            isMRATOnline: MRATStatus
        }
    });
};

export const setLastOnlineTime = (lastOnlineTime) => dispatch => {
    dispatch({
        type: COMMON_CONSTANTS.MRAT_SETLASTONLINETIME,
        payload: {
            lastOnlineTime
        }
    });
};

export const getMratSecurityGroupInfo = async () => {
    const securityGroupsResponse = await axios.get(APIEndpoints.GetMratSecurityGroupInfo);
    return securityGroupsResponse.data;
};
