import styled from 'styled-components';

export const AssetImageBox = styled.div`
width: 200px;
height: 200px;
border: 2px solid #498205;
margin-right: 1rem;
&:hover {
    border: transparent;
}
`;
