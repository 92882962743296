import { FieldHasValue } from 'helpers/Validation';

export const AsFoundResultFormulaM63andM64 = (formData) => {
    if (FieldHasValue(formData.F411AftConfirmTestTorch) && FieldHasValue(formData.F372AftHmistatus1)) {
        if (formData.F411AftConfirmTestTorch === 'YES' && formData.F372AftHmistatus1 === 'ALARM') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
