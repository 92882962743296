import React from 'react';
import { connect } from 'react-redux';
import Form from '../../FormsTemplate';
import { FormNames } from '../../Forms.constants';
import PropTypes from 'prop-types';

import UserInfoSection from '../../Sections/UserInfoSection/UserInfoSection';
import ManufacturerModelSection from '../../Sections/ManufacturerModelSection/ManufacturerModelSection';
import TestResultSection from '../../Sections/TestResultSection/TestResultSection';
import NoteSection from '../../Sections/NoteSection/NoteSection';
import BorderedBox from '../../BorderedBox';
import AreaClassificationSection from '../../Sections/AreaClassification/AreaClassification';
import DeviceNameplate from '../../Sections/DeviceNameplate/DeviceNameplate';
import MotorNameplate from '../../Sections/MotorNameplate/MotorNameplate';
import InspectionChecklist from '../../Sections/InspectionChecklist/InspectionChecklist';
import AttentionLabel from '../../Sections/AttentionLabel/AttentionLabel';
import FailureModeSection from '../../Sections/FailureModeSection/FailureModeSection';
import AdditionalInspectionChecklist from '../../Sections/AdditionalInspectionChecklist/AdditionalInspectionChecklist';
import { FieldHasValue } from 'helpers/Validation';

const M42Form = ({ formData }) => {
    const bordereCondition = (FieldHasValue(formData?.F957SelectExProtectionType));

    return (
        <Form
            formName={FormNames.M42}
        >
            <UserInfoSection />
            <ManufacturerModelSection />
            <BorderedBox>
                <AreaClassificationSection />
            </BorderedBox>
            <BorderedBox>
                <DeviceNameplate />
            </BorderedBox>
            <BorderedBox>
                <MotorNameplate />
            </BorderedBox>
            <BorderedBox>
                <InspectionChecklist />
            </BorderedBox>
            <FailureModeSection />
            {bordereCondition && (
            <BorderedBox>
                <AdditionalInspectionChecklist />
            </BorderedBox>
            )}
            <TestResultSection />
            <NoteSection />
            <AttentionLabel />
        </Form>
    );
};

const mapStateToProps = ({ Forms }) => ({
    formData: Forms.updatedFormDataObj,
    selectedTag: Forms.selectedTag,
});

const mapDispatchToProps = dispatch => ({});

M42Form.propTypes = {
    formData: PropTypes.object
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(M42Form);
