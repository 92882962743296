import { Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { formatDate } from 'helpers/DateFormatter';
import { APIEndpoints } from 'helpers/APILists';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import ImageUploader from 'components/DataGrid/helpers/ImageUploader';
import ImageViewer from 'components/DataGrid/helpers/ImageViewer';
import DropdownInput from '../../elements/DropdownInput';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import { trackErrors } from '../helper';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import ModalFooterActions from '../../elements/ModalFooterActions';

const GroupMappingForm = ({ isOpen, title, onClose, rowData, onRecordSubmit }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [cancelAlert, setCancelAlert] = useState(false);
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const submitButtonVisible = !_.isEqual(rowData, createRowData)
        && createRowData.BpRegion && createRowData.BpRegionNameAlias
        && createRowData.BpCompanyCodeName && createRowData.MratGroup
        && createRowData.HdSite
        && createRowData.BpSiteName
        && createRowData.BpSiteAliasName
        && ((createRowData.ImageStatus === 'Active' && createRowData.AssetImg) || createRowData.ImageStatus === 'Inactive');

    const handleInputChange = ((e) => setCreateRowData((prev) => (
        {
            ...prev,
            [e.target.name]: e.target.value
        }
    )));

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const resetButtonHandler = () => {
        setCreateRowData(rowData);
    };

    const handleImageUpload = (img) => {
        setCreateRowData((prev) => (
            {
                ...prev,
                AssetImg: img?.split(',')[1]
            }
        ));
    };

    const submitButtonHandler = () => {
        if (createRowData.RowId) {
            createRowData.ModifiedBy = userName?.name;
            createRowData.LastModifiedDate = formatDate(new Date());
            updateRecord(createRowData);
        } else {
            createRowData.RowId = 0;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            createRowData.ModifiedBy = userName?.name;
            createRowData.LastModifiedDate = createRowData.CreatedDate;
            submitRecord(createRowData);
        }
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_AddGroupMappingRecord, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.RowId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateGroupMappingRecord, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.RowId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="BpCompanyCodeName"
                        title="BP Company Code Name (SAP PM)"
                        value={createRowData.BpCompanyCodeName}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="30"
                    />
                    <TextInput
                        name="BpRegion"
                        title="BP Region (SAP PM)"
                        value={createRowData.BpRegion}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="30"
                    />
                    <TextInput
                        name="HdSite"
                        title="HD Site (SAP PM)"
                        value={createRowData.HdSite}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="4"
                    />
                    <TextInput
                        name="BpSiteName"
                        title="BP Site Name (SAP PM)"
                        value={createRowData.BpSiteName}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="30"
                    />
                    <TextInput
                        name="BpRegionNameAlias"
                        title="BP Region Alias"
                        value={createRowData.BpRegionNameAlias}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="30"
                        isMultiline
                    />
                    <TextInput
                        name="MratGroup"
                        title="MRAT Group"
                        value={createRowData.MratGroup}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="30"
                    />
                    <TextInput
                        name="BpSiteAliasName"
                        title="BP Site Name Alias"
                        value={createRowData.BpSiteAliasName}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="30"
                    />
                    <DropdownInput
                        name="Status"
                        title="Status"
                        value={createRowData.Status}
                        options={['LIVE', 'NOT LIVE']}
                        handleInputChange={handleInputChange}
                        required
                    />
                    <div />
                    <div />
                    <div>
                        <InputLabel style={{ fontSize: '12px', color: 'black', paddingLeft: '3px', paddingBottom: '4px', fontWeight: '600' }}>
                            Asset Image
                        </InputLabel>
                        {createRowData.AssetImg && <ImageViewer imgData={createRowData.AssetImg} />}
                        <ImageUploader onSubmit={handleImageUpload} required={createRowData.ImageStatus === 'Active'} value={createRowData.AssetImg ? true : ''} />
                    </div>
                    <DropdownInput
                        name="ImageStatus"
                        title="Image Status"
                        value={createRowData.ImageStatus}
                        options={['Active', 'Inactive']}
                        handleInputChange={handleInputChange}
                        required
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData?.RowId ? createRowData.CreatedBy : userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData?.RowId ? formatDate(new Date(createRowData.CreatedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData.LastModifiedDate || formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '12px' }}>
                    <TextInput
                        name="Comments"
                        title="Comments"
                        value={createRowData.Comments}
                        handleInputChange={handleInputChange}
                        isMultiline
                        width="1080px"
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px', justifyContent: 'space-between' }}>
                <div>
                    <InputLabel className={classes.inputLabel}>
                        <i>  Note: Values for the first 4 fields are to be fetched from SAP PM.</i>
                    </InputLabel>
                </div>
                <ModalFooterActions
                    submitButtonVisible={submitButtonVisible}
                    submitButtonHandler={submitButtonHandler}
                    resetButtonHandler={resetButtonHandler}
                    cancelButtonHandler={cancelButtonHandler}
                />
            </DialogActions>
        </Dialog>

    );
};

export default GroupMappingForm;

GroupMappingForm.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string
};
