import {
    AsFoundTestTableF39HmiInst1Formula,
    AsFoundTestTableF40HmiInst2Formula,
    AsFoundTestTableF41HmiInst3Formula
} from './P72.formulas';
import {
    AsFoundTestTableF56DeviationEu3Formula,
    AsFoundTestTableF55DeviationEu2Formula,
    AsFoundTestTableF54DeviationEu1Formula
} from '../Formulas';
import { paddingAF } from '../../../AsLeftTestTableSection/Data/M71/M71.data';

export const AsFoundTestTableSectionDataP72 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AsFoundTestLabel',
                label: 'As Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: 700,
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'remark',
                label: `Remark: Below test point indications assume that instrument range is defined in differential pressure units and HMI range is defined in flow rate units.
                In typical applications, differential pressure to flow rate conversions are performed in control/safety systems.`,
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '100%',
                padding: '5px 1px 1px 8px',
                fontSize: '8.5pt',
                color: '#ea7c34',
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'remark1',
                label: `Indicated HMI values at 0%, 50%, 100% test points may not be accurate if density correction is taking place during conversion
                 or if the HMI display range does not match the actual calculated flow range obtained from conversion. Consult with engineering team in such cases.`,
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '100%',
                padding: '0px 1px 1px 8px',
                fontSize: '8.5pt',
                color: '#ea7c34',
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'AsFoundTestHMIINST',
                label: '% - HMI - Inst.',
                isLabel: true,
                fontWeight: '700',
                labelWidth: '100%',
                xs: 2,
                padding: '5px 0 5px 8px',
                fontSize: '9pt'
            },
            {
                key: 'TestModuleReading',
                label: 'Test Module Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                labelWidth: '100%',
                textAlign: 'center',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'TXReading',
                label: 'TX Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                labelWidth: '100%',
                textAlign: 'center',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'HMIReading',
                label: 'HMI Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                labelWidth: '100%',
                textAlign: 'center',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'mAReading',
                label: 'mA Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                labelWidth: '100%',
                textAlign: 'center',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Deviation',
                label: 'Deviation(EU)',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                labelWidth: '100%',
                textAlign: 'center',
                padding: paddingAF,
                fontSize: '9pt'
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F39HmiInst1',
                xs: 2,
                value: AsFoundTestTableF39HmiInst1Formula(formData).length > 255
                    ? AsFoundTestTableF39HmiInst1Formula(formData).slice(0, 255) : AsFoundTestTableF39HmiInst1Formula(formData),
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F42TestModuleReading1',
                value: formData.F42TestModuleReading1,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F45TxReading1',
                value: formData.F45TxReading1,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F48HmiReading1',
                value: formData.F48HmiReading1,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F51MAReading1',
                value: formData.F51MAReading1,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F54DeviationEu1',
                value: AsFoundTestTableF54DeviationEu1Formula(formData),
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
                xs: 2,
                textAlign: 'center'
            }]
        },
        {
            key: 6,
            fields: [{
                key: 'F40HmiInst2',
                xs: 2,
                value: AsFoundTestTableF40HmiInst2Formula(formData).length > 255
                    ? AsFoundTestTableF40HmiInst2Formula(formData).slice(0, 255) : AsFoundTestTableF40HmiInst2Formula(formData),
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F43TestModuleReading2',
                value: formData.F43TestModuleReading2,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F46TxReading2',
                value: formData.F46TxReading2,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F49HmiReading2',
                value: formData.F49HmiReading2,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F52MAReading2',
                value: formData.F52MAReading2,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F55DeviationEu2',
                value: AsFoundTestTableF55DeviationEu2Formula(formData),
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
                xs: 2,
                textAlign: 'center'
            }]
        },
        {
            key: 7,
            fields: [{
                key: 'F41HmiInst3',
                xs: 2,
                value: AsFoundTestTableF41HmiInst3Formula(formData).length > 255
                    ? AsFoundTestTableF41HmiInst3Formula(formData).slice(0, 255) : AsFoundTestTableF41HmiInst3Formula(formData),
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                marginLeft: '4px'
            },
            {
                key: 'F44TestModuleReading3',
                value: formData.F44TestModuleReading3,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F47TxReading3',
                value: formData.F47TxReading3,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F50HmiReading3',
                value: formData.F50HmiReading3,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F53MAReading3',
                value: formData.F53MAReading3,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F56DeviationEu3',
                value: AsFoundTestTableF56DeviationEu3Formula(formData),
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
                xs: 2,
                textAlign: 'center'
            }]
        }
    ]
});
