import styled from 'styled-components';

export const StyleLabelFlowChangeCheck = styled.div`
#F813TransmitterTxreading1Label{
    @media only screen and (max-width: 1203px) {
        margin-top: ${props => props.marginTop ? props.marginTop : ''};
    }
}
 
#F302HmireadingLabel{
    @media only screen and (max-width: 1203px) {
        margin-top: ${props => props.marginTop ? props.marginTop : ''};
    }
}
`;
