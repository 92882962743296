import React from 'react';
import PropTypes from 'prop-types';
import { RefreshSVGButton } from './Refresh.styled';

const RefreshIcon = (props) => (
  <div title="Reset Form">
    <RefreshSVGButton id="RefreshIcon" xmlns="http://www.w3.org/2000/svg" width="40" height="37" viewBox="0 0 24.156 24.159" onClick={props.buttonHandler}>
      <g id="Group_19740" data-name="Group 19740" transform="translate(-6541.921 -8595.926)" title="Reset Form">
        <rect
          id="Rectangle_5098"
          data-name="Rectangle 5098"
          width="24"
          height="24"
          transform="translate(6542 8596)"
          fill="none"
        />
        <g id="_609468" data-name="609468" transform="translate(6547.314 8596.41) rotate(15.009)">
          <path
            id="Path_18104"
            data-name="Path 18104"
            d="M9.246.065A.388.388,0,0,0,9.1.554c.093.2.174.226.725.259a7.66,7.66,0,0,1,1.336.166,8.659,8.659,0,0,1,5.471,13.34,8.781,
            8.781,0,0,1-3.775,3.12A8.664,8.664,0,0,1,2.28,14.319,8.531,8.531,0,0,1,.807,9.174a8.222,8.222,0,0,1,.881-3.52,8.646,8.646,0,0,1,
            2.25-2.861c.544-.452.485-.559.485.881a8.467,8.467,0,0,0,.044,1.332.393.393,0,0,0,.722-.055c.056-.159.067-3.428.011-3.627A.647.647,0,
            0,0,5.068,1.1l-.1-.089L3.209,1A17.88,17.88,0,0,0,1.3,1.03a.388.388,0,0,0-.178.637l.107.122,1.314.018,1.318.018L3.509,2.1A10.464,
            10.464,0,0,0,1.377,4.529,9.928,9.928,0,0,0,.067,8.193a11.623,11.623,0,0,0,.022,2.683,9.262,9.262,0,0,0,.874,2.758,8.785,8.785,0,0,0,
            1.81,2.521,8.786,8.786,0,0,0,2.521,1.81,9.342,9.342,0,0,0,2.758.877,11.539,11.539,0,0,0,2.813,0,9.342,9.342,0,0,0,2.758-.877,8.786,8.786,
            0,0,0,2.521-1.81,8.785,8.785,0,0,0,1.81-2.521,9.342,9.342,0,0,0,.877-2.758,11.537,11.537,0,0,0,0-2.813,11.505,11.505,0,0,0-.474-1.847A12.909,
            12.909,0,0,0,17.5,4.473a10.24,10.24,0,0,0-3.05-3.05A12.909,12.909,0,0,0,12.711.572,9.867,9.867,0,0,0,9.883.009C9.406-.009,9.357,0,9.246.065Z"
            fill="#fff"
            stroke="#fff"
            strokeWidth="0.8"
          />
        </g>
      </g>
    </RefreshSVGButton>
  </div>
);

export default RefreshIcon;

RefreshIcon.propTypes = {
  buttonHandler: PropTypes.func
};
