import { labelWidth } from '../HMISection/Data/P710/P710.data';
import { paddingVal } from '../AsFoundTestTableSection/Data/P66/P66.data';
import { paddingLabel } from '../HMISection/Data/C11/C11.data';

const selectActivationStatus = 'Select Status';
export const SandAlarmTestSectionData = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'SandAlarmTest',
                    label: 'Sand Alarm Test',
                    isLabel: true,
                    xs: 4,
                    padding: '5px 0 5px 8px',
                    fontWeight: '700',
                }
            ]
        },
        {
            key: 2,
            fields: [{
                key: 'remark1',
                label: 'Remark: Alarms may or may not be activated on HMI depending on configured limits and simulated acoustic signal (i.e., spike peak and duration).',
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '99%',
                padding: '0px 1px 0px 8px',
                fontSize: '8.5pt',
                color: '#ea7c34'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'remark2',
                label: 'Test Result may still be "PASS" if alarms are not activated on HMI due to low peak or short duration of spikes. Judgement should be applied.',
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '99%',
                padding: '0px 1px 10px 8px',
                fontSize: '8.5pt',
                color: '#ea7c34'
            }]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F950HighSandAlarmLimitLabel',
                    label: 'High Sand Alarm Limit (gram/sec)',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingVal,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F950HighSandAlarmLimit',
                    value: formData.F950HighSandAlarmLimit,
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    textAlign: 'center',
                    fontWeight: '700',
                    type: 'number',
                    maxLength: '10',
                    height: '1.27rem'
                },
                {
                    key: 'F951HighSandAlarmActivatedLabel',
                    label: 'High Sand Alarm Activated',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel
                },
                {
                    key: 'F951HighSandAlarmActivated',
                    value: formData.F951HighSandAlarmActivated,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: selectActivationStatus,
                    options: [{ name: selectActivationStatus, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' },
                    { name: 'DISABLED', value: 'DISABLED' }],
                    labelWidth: '0%',
                    width: '98%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderRight: '0',
                    height: '1.63rem'
                }]
        },
        {
            key: 5,
            fields: [
                {
                    key: 'F952HighHighSandAlarmLimitLabel',
                    label: 'High High Sand Alarm Limit (gram/sec)',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingVal,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F952HighHighSandAlarmLimit',
                    value: formData.F952HighHighSandAlarmLimit,
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    textAlign: 'center',
                    fontWeight: '700',
                    type: 'number',
                    maxLength: '10',
                    borderTop: '0',
                    borderBottom: '0',
                    marginBottom: '4px',
                    height: '1.37rem'
                },
                {
                    key: 'F953HighHighSandAlarmActivatedLabel',
                    label: 'High High Sand Alarm Activated',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel
                },
                {
                    key: 'F953HighHighSandAlarmActivated',
                    value: formData.F953HighHighSandAlarmActivated,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: selectActivationStatus,
                    options: [{ name: selectActivationStatus, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' },
                    { name: 'DISABLED', value: 'DISABLED' }],
                    labelWidth: '0%',
                    width: '98%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderTop: '0',
                    borderBottom: '0',
                    borderRight: '0',
                    marginBottom: '4px',
                    height: '1.5rem'
                }]
        },
    ]
});
export const selectSandAlarmTestSectionData = (selectedTag, formName, formData) => {
    let filterViewData = { rows: [] };
    if (['P6-6', 'P8-17.1'].includes(formName)) {
        filterViewData = SandAlarmTestSectionData(selectedTag, formName, formData);
    }
    return filterViewData;
};
