import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { MatchNumber } from 'helpers/Validation';
import { HandleTextValue as handleTextValue, handleBlur } from 'helpers/HandleTextValue';
import { InputWrapper, StyledInput, StyledLabel } from './Inputs.styled';

export const FormInputBlur = (props) => {
    const { id, label, type, inputMaxWidth, name, value, handleChange,
        isCalculated, isCalculatedNew, isDisabled, isRequired, readOnly,
        marginLeft, width, minWidth, labelWidth, marginRight, marginBottom,
        marginTop, direction, padding, textAlign, maxLength, borderTop, height,
        borderBottom, borderRight, borderLeft, inputMarginRight, isLabelNew,
        isLabelBold, textAlignLabel, userRole, fontWeight, hasTestPointError,
        color, fontSize, inputMarginLeft, inputFontSize, testPointErrorMessage } = props;
    const [inputValue, handleValue] = useState(value || '');

    useEffect(() => {
        handleValue(value);
    }, [value]);

    return (
        <InputWrapper
            marginBottom={marginBottom}
            marginTop={marginTop}
        >
            <StyledLabel
                textAlignLabel={textAlignLabel}
                marginLeft={marginLeft}
                labelWidth={labelWidth}
                direction={direction}
                marginRight={marginRight}
                padding={padding}
                fontWeight={fontWeight}
                color={color}
                fontSize={fontSize}
            >
                {label}
            </StyledLabel>
            <>
                <StyledInput
                    id={id}
                    type={type === 'number' ? 'tel' : 'text'}
                    onWheel={type === 'number' ? (e) => e.target.blur() : null}
                    onPaste={type === 'number' ? (e) => (e.clipboardData.getData('Text').includes('e')
                        || e.clipboardData.getData('Text').includes('E') || !MatchNumber(e.clipboardData.getData('Text')))
                        && e.preventDefault() : null}
                    onBlur={type === 'number' ? (e) => handleBlur(e, handleChange, name) : null}
                    onChange={(e) => handleTextValue(e, type, handleValue, maxLength)}
                    value={typeof inputValue === 'number' ? inputValue : inputValue?.trim() ?? ''}
                    isRequired={isRequired}
                    isCalculated={isCalculated}
                    isCalculatedNew={isCalculatedNew}
                    isLabelNew={isLabelNew}
                    isLabelBold={isLabelBold}
                    isDisabled={isDisabled}
                    readOnly={readOnly}
                    inputMaxWidth={inputMaxWidth}
                    width={width}
                    minWidth={minWidth}
                    autoComplete="off"
                    textAlign={textAlign}
                    borderTop={borderTop}
                    borderBottom={borderBottom}
                    borderRight={borderRight}
                    height={height}
                    borderLeft={borderLeft}
                    inputMarginRight={inputMarginRight}
                    userRole={userRole}
                    hasTestPointError={hasTestPointError}
                    data-tip={hasTestPointError}
                    data-for="registerTip"
                    inputMarginLeft={inputMarginLeft}
                    inputFontSize={inputFontSize}
                />
                {hasTestPointError
                    && (
                        <ReactTooltip id="registerTip" place="top" effect="solid">
                            {testPointErrorMessage}
                        </ReactTooltip>
                    )}
            </>
        </InputWrapper>
    );
};

FormInputBlur.propTypes = {
    borderBottom: PropTypes.string,
    borderLeft: PropTypes.string,
    borderRight: PropTypes.string,
    borderTop: PropTypes.string,
    color: PropTypes.string,
    direction: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    hasTestPointError: PropTypes.bool,
    height: PropTypes.string,
    id: PropTypes.string,
    inputFontSize: PropTypes.string,
    inputMarginLeft: PropTypes.string,
    inputMarginRight: PropTypes.string,
    inputMaxWidth: PropTypes.string,
    isCalculated: PropTypes.bool,
    isCalculatedNew: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLabelBold: PropTypes.bool,
    isLabelNew: PropTypes.bool,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    labelWidth: PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    marginTop: PropTypes.string,
    maxLength: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    minWidth: PropTypes.string,
    name: PropTypes.string.isRequired,
    padding: PropTypes.string,
    readOnly: PropTypes.any,
    testPointErrorMessage: PropTypes.string,
    textAlign: PropTypes.string,
    textAlignLabel: PropTypes.string,
    type: PropTypes.string,
    userRole: PropTypes.string,
    value: PropTypes.any,
    width: PropTypes.string
};
