import { FieldHasValue, ValidateField } from 'helpers/Validation';

export const FailureModeLowPassFailResult = (FormData) => {
    if (FieldHasValue(FormData.F315TxshowBadPvorFaultOnHmiLc)) {
        if (ValidateField(FormData.F315TxshowBadPvorFaultOnHmiLc) === 'Yes') {
            return 'Pass';
        }
        return 'Fail';
    }
    return '';
};
export const FailureModeHighPassFailResult = (FormData) => {
    if (FieldHasValue(FormData.F316TxshowBadPvorFaultOnHmiHc)) {
        if (ValidateField(FormData.F316TxshowBadPvorFaultOnHmiHc) === 'Yes') {
            return 'Pass';
        }
        return 'Fail';
    }
    return '';
};

export const isFailModeHighCurrentDisabled = (formData) => ((FieldHasValue(formData?.F36FailureModeLc)
    || FieldHasValue(formData?.F315TxshowBadPvorFaultOnHmiLc)) && !(FieldHasValue(formData?.F33FailureModeHc)
        || FieldHasValue(formData?.F316TxshowBadPvorFaultOnHmiHc)));

export const isBadPvorFaultOnHmiHcRequired = (selectedTag, formData) => selectedTag?.FailureModeHighCurrentMA || (!selectedTag?.FailureModeHighCurrentMA
    && !selectedTag?.F36FailureModeLc) || !(FieldHasValue(formData?.F36FailureModeLc) && FieldHasValue(formData?.F315TxshowBadPvorFaultOnHmiLc));

export const isBadPvorFaultOnHmiLcRequired = (selectedTag, formData) => selectedTag?.FailureModeLowCurrentMA || (!selectedTag?.FailureModeHighCurrentMA
    && !selectedTag?.F36FailureModeLc) || !(FieldHasValue(formData?.F33FailureModeHc) && FieldHasValue(formData?.F316TxshowBadPvorFaultOnHmiHc));

export const isFailModeLowCurrentDisabled = (formData) => ((FieldHasValue(formData?.F33FailureModeHc)
    || FieldHasValue(formData?.F316TxshowBadPvorFaultOnHmiHc)) && !(FieldHasValue(formData?.F36FailureModeLc)
        || FieldHasValue(formData?.F315TxshowBadPvorFaultOnHmiLc)));

export const isFailureModeHighCurrentRequired = (selectedTag, formData) => (FieldHasValue(formData?.F33FailureModeHc)
    && FieldHasValue(formData?.F36FailureModeLc)) || !(FieldHasValue(formData?.F36FailureModeLc)
        && (!selectedTag?.FailureModeLowCurrentMA && !selectedTag?.FailureModeHighCurrentMA))
    || (selectedTag?.FailureModeLowCurrentMA && selectedTag?.FailureModeHighCurrentMA) || (selectedTag?.FailureModeHighCurrentMA);

export const isFailureModeLowCurrentRequired = (selectedTag, formData) => (FieldHasValue(formData?.F33FailureModeHc)
    && FieldHasValue(formData?.F36FailureModeLc)) || !(FieldHasValue(formData?.F33FailureModeHc)
        && (!selectedTag?.FailureModeLowCurrentMA && !selectedTag?.FailureModeHighCurrentMA))
    || (selectedTag?.FailureModeLowCurrentMA && selectedTag?.FailureModeHighCurrentMA) || (selectedTag?.FailureModeLowCurrentMA);
