export const inputFieldTypesHeaderData = [
    {
        field: 'InputFieldTypeId',
        headerName: 'Input Field Type ID',
        width: '80',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Type',
        headerName: 'Type',
        width: '250',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'Description',
        headerName: 'Description',
        width: '200',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '85',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '110',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const inputFieldTypesInitialState = {
    InputFieldTypeId: '',
    Type: '',
    Description: '',
    Status: 'Inactive',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const searchDataInitialState = {
    InputFieldTypeId: '',
    Type: '',
    Description: '',
    Status: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const inputFieldTypesApiEndpoint = {
    read: 'ADMIN_GetInputFieldTypes',
    create: 'ADMIN_AddInputFieldTypeDetails',
    update: 'ADMIN_UpdateInputFieldTypeDetails'
};
