import {
    Placeholder,
    classOptions,
    zoneOptions,
    divisionOptions,
    equipmentProtectionLevelOptions,
    gasGroupOptions,
    temperaturClassOptions
} from '../../Sections.constants';
import { paddingValue, paddingLabels } from '../AreaClassification/AreaClassification.data';
import { paddingValAFWS } from '../AsLeftTestWorkshop/AsLeftTestWorkshop.data';
import { labelWidth } from '../HMISection/Data/P710/P710.data';

export const deviceNameplate = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'DeviceNameplate',
                label: 'Device Nameplate',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: paddingValAFWS
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F1005DeviceClassLabel',
                    label: 'Class',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabels
                },
                {
                    key: 'F1005DeviceClass',
                    value: formData.F1005DeviceClass,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: Placeholder.SELECT,
                    options: classOptions,
                    inputTextAlign: 'center',
                    xs: 4,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    marginLeft: '16px'
                },
                {
                    key: 'F1008DeviceZoneLabel',
                    label: 'Zone',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingValue
                },
                {
                    key: 'F1008DeviceZone',
                    value: formData.F1008DeviceZone,
                    xs: 4,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    borderBottom: '0',
                    marginLeft: '13px',
                    isDropdown: true,
                    placeholder: Placeholder.SELECT,
                    options: zoneOptions,
                    rootMarginRight: '4px',
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F1006DeviceDivisionLabel',
                    label: 'Division',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabels
                },
                {
                    key: 'F1006DeviceDivision',
                    value: formData.F1006DeviceDivision,
                    xs: 4,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    isRequired: true,
                    isDropdown: true,
                    placeholder: Placeholder.SELECT,
                    options: divisionOptions,
                    marginLeft: '16px'
                },
                {
                    key: 'F1009DeviceEplLabel',
                    label: 'EPL',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingValue
                },
                {
                    key: 'F1009DeviceEpl',
                    value: formData.F1009DeviceEpl,
                    xs: 4,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderRight: '0',
                    borderBottom: '0',
                    inputMarginRight: '4px',
                    isDropdown: true,
                    placeholder: Placeholder.SELECT,
                    options: equipmentProtectionLevelOptions,
                    rootMarginRight: '4px',
                    marginLeft: '13px'
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F1007DeviceGasGroupLabel',
                    label: 'Gas Group',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabels
                },
                {
                    key: 'F1007DeviceGasGroup',
                    value: formData.F1007DeviceGasGroup,
                    xs: 4,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    isRequired: true,
                    borderBottom: '0',
                    marginBottom: '4px',
                    isDropdown: true,
                    placeholder: Placeholder.SELECT,
                    options: gasGroupOptions,
                    marginLeft: '16px'
                },
                {
                    key: 'F1010DeviceTempClassLabel',
                    label: 'Temp Class',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingValue
                },
                {
                    key: 'F1010DeviceTempClass',
                    value: formData.F1010DeviceTempClass,
                    xs: 4,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderRight: '0',
                    isRequired: true,
                    borderBottom: '0',
                    marginBottom: '4px',
                    inputMarginRight: '4px',
                    isDropdown: true,
                    placeholder: Placeholder.SELECT,
                    options: temperaturClassOptions,
                    rootMarginRight: '4px',
                    marginLeft: '13px'
                }
            ]
        }
    ]
});

export const selectDeviceNameplateData = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'M4-2') {
        filterViewData = deviceNameplate(formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
