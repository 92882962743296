import {
    AsFoundTestTableF39HmiInst1Formula,
    AsFoundTestTableF40HmiInst2Formula,
    AsFoundTestTableF41HmiInst3Formula,
} from '../P711/P711.formulas';
import {
    AsFoundTestTableF54DeviationEu1Formula,
    AsFoundTestTableF55DeviationEu2Formula,
    AsFoundTestTableF56DeviationEu3Formula
} from './P6022.formulas';
import { paddingAF } from '../../../AsLeftTestTableSection/Data/M71/M71.data';
import { signalToTriggerIsErrorCheck } from '../../../SignalToTrigger/SignalToTrigger.data';

export const AsFoundTestTableSectionDataP6022 = (selectedTag, formName, formData) => {
    const signalToTriggerErrorCheckValue = (formDataValue) => signalToTriggerIsErrorCheck(formName, formData) ? '' : formDataValue;
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'As-FoundTest',
                    label: 'As-Found Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: 700,
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: '%-HMI-Inst.',
                    label: '% - HMI - Inst.',
                    isLabel: true,
                    xs: 2,
                    fontWeight: 700,
                    labelWidth: '100%',
                    padding: '5px 0 5px 8px',
                    fontSize: '9pt'
                },
                {
                    key: 'TestDeviceReading',
                    label: 'Test Device Reading (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: 700,
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'TXReading',
                    label: 'TX Reading (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: 700,
                    textAlign: 'center',
                    labelWidth: '100%',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'HMIReading',
                    label: 'HMI Reading (HMI EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: 700,
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'mAReading',
                    label: 'mA Reading',
                    isLabel: true,
                    xs: 2,
                    fontWeight: 700,
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'Deviation',
                    label: 'Deviation (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: 700,
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'F39HmiInst1',
                    value: signalToTriggerErrorCheckValue(AsFoundTestTableF39HmiInst1Formula(formData)),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F800TestDeviceReading',
                    value: signalToTriggerErrorCheckValue(formData.F800TestDeviceReading),
                    isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0'
                },
                {
                    key: 'F45TxReading1',
                    value: signalToTriggerErrorCheckValue(formData.F45TxReading1),
                    isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    borderLeft: '0'
                },
                {
                    key: 'F48HmiReading1',
                    value: signalToTriggerErrorCheckValue(formData.F48HmiReading1),
                    isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0'
                },
                {
                    key: 'F51MAReading1',
                    value: signalToTriggerErrorCheckValue(formData.F51MAReading1),
                    isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    borderLeft: '0',
                },
                {
                    key: 'F54DeviationEu1',
                    value: signalToTriggerErrorCheckValue(AsFoundTestTableF54DeviationEu1Formula(formData)),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    readOnly: true,
                }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F40HmiInst2',
                    value: signalToTriggerErrorCheckValue(AsFoundTestTableF40HmiInst2Formula(formData)),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F801TestDeviceReading',
                    value: signalToTriggerErrorCheckValue(formData.F801TestDeviceReading),
                    isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    maxLength: '10',
                    xs: 2,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0'
                },
                {
                    key: 'F46TxReading2',
                    value: signalToTriggerErrorCheckValue(formData.F46TxReading2),
                    isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    borderLeft: '0'
                },
                {
                    key: 'F49HmiReading2',
                    value: signalToTriggerErrorCheckValue(formData.F49HmiReading2),
                    isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0'
                },
                {
                    key: 'F52MAReading2',
                    value: signalToTriggerErrorCheckValue(formData.F52MAReading2),
                    isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    borderLeft: '0',
                },
                {
                    key: 'F55DeviationEu2',
                    value: signalToTriggerErrorCheckValue(AsFoundTestTableF55DeviationEu2Formula(formData)),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    readOnly: true,
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F41HmiInst3',
                    value: signalToTriggerErrorCheckValue(AsFoundTestTableF41HmiInst3Formula(formData)),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.1rem',
                    borderRight: '0',
                    borderLeft: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F802TestDeviceReading',
                    value: signalToTriggerErrorCheckValue(formData.F802TestDeviceReading),
                    isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.1rem',
                },
                {
                    key: 'F47TxReading3',
                    value: signalToTriggerErrorCheckValue(formData.F47TxReading3),
                    isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.1rem',
                    borderRight: '0',
                    borderLeft: '0'
                },
                {
                    key: 'F50HmiReading3',
                    value: signalToTriggerErrorCheckValue(formData.F50HmiReading3),
                    isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                },
                {
                    key: 'F53MAReading3',
                    value: signalToTriggerErrorCheckValue(formData.F53MAReading3),
                    isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.1rem',
                    borderRight: '0',
                    borderLeft: '0',
                },
                {
                    key: 'F56DeviationEu3',
                    value: signalToTriggerErrorCheckValue(AsFoundTestTableF56DeviationEu3Formula(formData)),
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.1rem',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    readOnly: true,
                }]
            }
        ]
    };
};
