import { FieldHasValue, ValidateField, ValidateNumberField, ExponentialToDecimal } from 'helpers/Validation';
import { isF118InstEUHasValue } from '../P61/P61.formulas';
import { isF115HmiEUHasValue } from '../P711/P711.formulas';

export const AFTCalibration2Points1Formula = (formData) => {
    let CalibrationPoint1;
    if (FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F117InstrumentCalibratedUrv)) {
        CalibrationPoint1 = '100% - '.concat(ValidateField(formData.F22HmiUrv))
            .concat(isF115HmiEUHasValue(formData))
            .concat(ValidateField(formData.F115HmiengineeringUnits))
            .concat(' - ')
            .concat(ValidateField(formData.F117InstrumentCalibratedUrv))
            .concat(isF118InstEUHasValue(formData))
            .concat(ValidateField(formData.F118InstrumentEngineeringUnits));
        if (CalibrationPoint1.length > 255) {
            return CalibrationPoint1.slice(0, 255);
        }
        return CalibrationPoint1;
    }
    return '100%';
};

export const AFTCalibration2Points2Formula = (formData) => {
    let Hmi2Inst2;
    if (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F116InstrumentCalibratedLrv) && FieldHasValue(formData.F117InstrumentCalibratedUrv)) {
        Hmi2Inst2 = '50% - '.concat(ExponentialToDecimal(ValidateNumberField(formData.F21HmiLrv)
            + (ValidateNumberField(formData.F22HmiUrv) - ValidateNumberField(formData.F21HmiLrv)) * (0.5)))
            .concat(isF115HmiEUHasValue(formData))
            .concat(ValidateField(formData.F115HmiengineeringUnits))
            .concat(' - ')
            .concat(ExponentialToDecimal(ValidateNumberField(formData.F116InstrumentCalibratedLrv)
                + (ValidateNumberField(formData.F117InstrumentCalibratedUrv) - ValidateNumberField(formData.F116InstrumentCalibratedLrv)) * (0.5)))
            .concat(isF118InstEUHasValue(formData))
            .concat(ValidateField(formData.F118InstrumentEngineeringUnits));
        return Hmi2Inst2.length > 255 ? Hmi2Inst2.slice(0, 255) : Hmi2Inst2;
    }
    return '50%';
};

export const AFTCalibration2Points3Formula = (formData) => {
    let CalibrationPoint3;
    if (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F116InstrumentCalibratedLrv)) {
        CalibrationPoint3 = '0% - '.concat(ValidateField(formData.F21HmiLrv))
            .concat(isF115HmiEUHasValue(formData))
            .concat(ValidateField(formData.F115HmiengineeringUnits))
            .concat(' - ')
            .concat(ValidateField(formData.F116InstrumentCalibratedLrv))
            .concat(isF118InstEUHasValue(formData))
            .concat(ValidateField(formData.F118InstrumentEngineeringUnits));
        if (CalibrationPoint3.length > 255) {
            return CalibrationPoint3.slice(0, 255);
        }
        return CalibrationPoint3;
    }
    return '0%';
};

export const AsFoundTestTableF54DeviationEu1Formula = (formData) => FieldHasValue(formData.F124AftGaugeReading1) && FieldHasValue(formData.F147AftTransmitterReading1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F124AftGaugeReading1) - ValidateNumberField(formData.F147AftTransmitterReading1))) : '';
export const AsFoundTestTableF55DeviationEu2Formula = (formData) => FieldHasValue(formData.F125AftGaugeReading2) && FieldHasValue(formData.F148AftTransmitterReading2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F125AftGaugeReading2) - ValidateNumberField(formData.F148AftTransmitterReading2))) : '';
export const AsFoundTestTableF56DeviationEu3Formula = (formData) => FieldHasValue(formData.F126AftGaugeReading3) && FieldHasValue(formData.F149AftTransmitterReading3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F126AftGaugeReading3) - ValidateNumberField(formData.F149AftTransmitterReading3))) : '';
