export const ALTDensitySectionDataP65P831 = (selectedTag, formName, formData) => {
    const disableAsLeftSection = ((formData.F102AftResult === 'PASS' && formData.F218AftIsRecalibrationNeeded === 'NO') || formData.F619AsLeftTestRequired === 'NO');
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsLeftTestHMIINST',
                    label: 'As-Left Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 5px 3px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'F219AltWaterDensityUsed',
                    label: 'Water Density Used',
                    value: !disableAsLeftSection ? formData.F219AltWaterDensityUsed : '',
                    isDisabled: disableAsLeftSection,
                    readOnly: disableAsLeftSection,
                    xs: 6,
                    maxLength: '23',
                    width: '32.4%',
                    textAlign: 'center',
                    labelWidth: '32.8%',
                    marginLeft: '2px',
                    borderBottom: '0',
                },
                {
                    key: 'Nodata',
                    isLabel: true,
                    xs: 2,
                },
                {
                    key: 'F222AltBackgroundCountLabel',
                    label: 'Background Count',
                    xs: 2,
                    isLabel: 'true',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '4px 0 0 0'
                },
                {
                    key: 'F222AltBackgroundCount',
                    value: !disableAsLeftSection ? formData.F222AltBackgroundCount : '',
                    isDisabled: disableAsLeftSection,
                    readOnly: disableAsLeftSection,
                    xs: 2,
                    maxLength: '23',
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderRight: '0',
                    marginLeft: '2px'
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'F220AltOilDensityUsed',
                    label: 'Oil Density Used',
                    value: !disableAsLeftSection ? formData.F220AltOilDensityUsed : '',
                    isDisabled: disableAsLeftSection,
                    readOnly: disableAsLeftSection,
                    maxLength: '23',
                    xs: 6,
                    textAlign: 'center',
                    width: '32.4%',
                    labelWidth: '32.8%',
                    marginLeft: '2px',
                    borderBottom: '0',
                },
                {
                    key: 'Nodata',
                    isLabel: true,
                    xs: 2,
                },
                {
                    key: 'F223AltVesselEmptyCountLabel',
                    label: 'Vessel Empty Count',
                    xs: 2,
                    isLabel: 'true',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '4px 0 0 0'
                },
                {
                    key: 'F223AltVesselEmptyCount',
                    value: !disableAsLeftSection ? formData.F223AltVesselEmptyCount : '',
                    isDisabled: disableAsLeftSection,
                    readOnly: disableAsLeftSection,
                    maxLength: '23',
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderRight: '0',
                    marginLeft: '2px'
                }]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F221AltGasDensityUsed',
                        label: 'Gas Density Used',
                        value: !disableAsLeftSection ? formData.F221AltGasDensityUsed : '',
                        isDisabled: disableAsLeftSection,
                        readOnly: disableAsLeftSection,
                        maxLength: '23',
                        xs: 6,
                        width: '32.4%',
                        textAlign: 'center',
                        labelWidth: '32.8%',
                        marginLeft: '2px'
                    },
                    {
                        key: 'Nodata',
                        isLabel: true,
                        xs: 2,
                    },
                    {
                        key: 'F224AltVesselFullCountLabel',
                        label: 'Vessel Full Count',
                        xs: 2,
                        isLabel: 'true',
                        labelWidth: '96%',
                        textAlign: 'end',
                        fontSize: '9pt',
                        fontWeight: '600',
                        padding: '4px 0 0 0'
                    },
                    {
                        key: 'F224AltVesselFullCount',
                        value: !disableAsLeftSection ? formData.F224AltVesselFullCount : '',
                        isDisabled: disableAsLeftSection,
                        readOnly: disableAsLeftSection,
                        maxLength: '23',
                        xs: 2,
                        labelWidth: '0',
                        width: '100%',
                        textAlign: 'center',
                        borderRight: '0',
                        marginLeft: '2px'
                    }]
            }
        ]
    };
};
