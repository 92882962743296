import { checkURVGreaterThanLRV, ValidateField } from 'helpers/Validation';
import { HMISectionCulatedRangeFormula } from '../P78/P78.formulas';
import { EngineeringUnit } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../C11/C11.data';
import { labelWidth } from '../P710/P710.data';

export const HMISectionDataP720 = (selectedTag, formData) => {
    const sensorTypeVal = formData.F774SensorType === 'Velometer' || formData.F774SensorType === 'Accelerometer';
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F774SensorTypeLabel',
                    label: 'Sensor Type',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F774SensorType',
                    value: formData.F774SensorType,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'Select Sensor Type',
                    options: [
                        { name: 'Select Sensor Type', value: '' },
                        { name: 'Proximeter', value: 'Proximeter' },
                        { name: 'Velometer', value: 'Velometer' },
                        { name: 'Accelerometer', value: 'Accelerometer' }],
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.5rem',
                    textAlign: 'center',
                },
                {
                    key: 'F661ProximityProbeLengthLabel',
                    label: 'Proximity Probe Length (m)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F661ProximityProbeLength',
                    value: (sensorTypeVal) ? '' : ValidateField(formData.F661ProximityProbeLength),
                    xs: 2,
                    borderBottom: '0',
                    type: 'number',
                    maxLength: '10',
                    height: '1.25rem',
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    textAlign: 'center',
                    isDisabled: (sensorTypeVal),
                    readOnly: (sensorTypeVal),

                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'F775ScaleFactorSensitivityLabel',
                    label: 'Scale Factor / Sensitivity',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F775ScaleFactorSensitivity',
                    value: formData.F775ScaleFactorSensitivity,
                    isRequired: true,
                    height: '1.2rem',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    maxLength: '20'
                },
                {
                    key: 'F662ExtensionCableLengthLabel',
                    label: 'Extension Cable Length (m)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    labelWidth,
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F662ExtensionCableLength',
                    value: (sensorTypeVal) ? '' : formData.F662ExtensionCableLength,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    textAlign: 'center',
                    isDisabled: (sensorTypeVal),
                    readOnly: (sensorTypeVal),
                }]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F663HmiReadingPriorToTestLabel',
                        label: 'HMI Vibration Reading Prior to Test (HMI EU)',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F663HmiReadingPriorToTest',
                        value: formData.F663HmiReadingPriorToTest,
                        xs: 2,
                        width: '100%',
                        labelWidth: '0%',
                        borderTop: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                        isRequired: true,
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                    },
                    {
                        key: 'F664GapVoltagePriorToTestLabel',
                        label: 'Gap Voltage Prior to Test (V DC)',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        labelWidth,
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F664GapVoltagePriorToTest',
                        value: (sensorTypeVal) ? '' : formData.F664GapVoltagePriorToTest,
                        xs: 2,
                        width: '100%',
                        labelWidth: '0%',
                        textAlignLabel: 'end',
                        borderTop: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                        isRequired: !(sensorTypeVal) ? true : '',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        isDisabled: (sensorTypeVal),
                        readOnly: (sensorTypeVal),
                    }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F21HmiLrvLabel',
                    label: 'HMI LRV',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F21HmiLrv',
                    value: selectedTag.DateTested ? formData?.F21HmiLrv : formData.F21HmiLrv ?? selectedTag?.TsprHmiLrv,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    height: '1.2rem',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    hasHmiError: !checkURVGreaterThanLRV(formData.F21HmiLrv, formData.F22HmiUrv)
                },
                {
                    key: 'F22HmiUrvLabel',
                    label: 'HMI URV',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F22HmiUrv',
                    value: selectedTag.DateTested ? formData?.F22HmiUrv : formData.F22HmiUrv ?? selectedTag?.TsprHmiUrv,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderBottom: '0',
                    hasHmiError: !checkURVGreaterThanLRV(formData.F21HmiLrv, formData.F22HmiUrv),
                }]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F105HMICalculatedRangeLabel',
                        label: 'HMI Configured Range',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F105HMICalculatedRange',
                        value: HMISectionCulatedRangeFormula(formData.F21HmiLrv, formData.F22HmiUrv, formData.F24HmiEngineeringUnit),
                        xs: 2,
                        isCalculatedNew: true,
                        readOnly: true,
                        inputWidth: '100%',
                        labelWidth: '0%',
                        borderTop: '0',
                        marginBottom: '15px',
                        textAlign: 'center',
                        isAutoGrow: true,
                        marginRight: '0',
                    },
                    {
                        key: 'F24HmiEngineeringUnitLabel',
                        label: EngineeringUnit.HMI_ENGINEERING_UNIT_EU,
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        labelWidth,
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F24HmiEngineeringUnit',
                        value: selectedTag.DateTested ? formData?.F24HmiEngineeringUnit : formData?.F24HmiEngineeringUnit ?? selectedTag?.TsprHmiEngineeringUnits,
                        xs: 2,
                        direction: 'rtl',
                        isRequired: true,
                        inputWidth: '100%',
                        labelWidth: '0%',
                        marginRight: '0',
                        textAlign: 'center',
                        borderTop: '0',
                        marginBottom: '15px',
                        maxLength: '14',
                        isAutoGrow: true,
                        marginTop: '0',
                    }
                ]
            }
        ]
    };
};
