import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { formatDate } from 'helpers/DateFormatter';
import { APIEndpoints } from 'helpers/APILists';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import { trackErrors } from '../helper';
import DropdownInput from '../../elements/DropdownInput';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import { strEquals } from '../../AdminScreen.helper';
import ModalFooterActions from '../../elements/ModalFooterActions';

const AdfPipelineParameterForm = ({ isOpen, title, onClose, rowData, dropdownsData, onRecordSubmit }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [cancelAlert, setCancelAlert] = useState(false);
    const [nameError, setNameError] = useState('');
    const [valueError, setValueError] = useState('');
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const submitButtonVisible = !_.isEqual(rowData, createRowData) && nameError === '' && valueError === ''
        && createRowData.ParamDataType
        && createRowData.ParamName
        && createRowData.ParamValue
        && createRowData.Status;
    const [paramNames, setParamNames] = useState([]);

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetAdfPipelineParameter)
            .then((res) => {
                setParamNames(res.data.map((x) => ({
                    AdfPipelineParameterId: x.AdfPipelineParameterId,
                    ParamName: x.ParamName
                })));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    const handleInputChange = ((e) => setCreateRowData((prev) => (
        {
            ...prev,
            [e.target.name]: e.target.value
        }
    )));

    const handleBlurName = () => {
        setNameError('');
        const paramName = createRowData.ParamName;
        const onlyNumbersPattern = /^[0-9]+$/;
        if (onlyNumbersPattern.test(paramName)) {
            setNameError(' *(Param Name is Invalid)');
        }
        if (paramNames?.find(x => strEquals(x?.ParamName, paramName)
            && x?.AdfPipelineParameterId !== createRowData?.AdfPipelineParameterId
        )) {
            setNameError(' *(Param Name already exists)');
        }
    };

    const handleBlurValue = () => {
        setValueError('');
        const paramValue = createRowData.ParamValue;
        const paramDataType = createRowData.ParamDataType;

        if (paramDataType === 'comma delimited str') {
            const commaDelimitedPattern = /,(?!\S)/;
            if (commaDelimitedPattern.test(paramValue)) {
                setValueError(' *(Param Value is Invalid)');
            }
        } else if (paramDataType === 'str') {
            const commaPattern = /,/;
            if (commaPattern.test(paramValue)) {
                setValueError(' *(Param Value is Invalid)');
            }
        }
    };

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const resetButtonHandler = () => {
        setNameError('');
        setValueError('');
        setCreateRowData(rowData);
    };

    const submitButtonHandler = () => {
        createRowData.ModifiedBy = userName?.name;
        createRowData.LastModifiedDate = formatDate(new Date());
        if (createRowData.AdfPipelineParameterId) {
            updateRecord(createRowData);
        } else {
            createRowData.AdfPipelineParameterId = 0;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            submitRecord(createRowData);
        }
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_AddAdfPipelineParameter, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.AdfPipelineParameterId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateAdfPipelineParameter, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.AdfPipelineParameterId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="ParamName"
                        title="Param Name"
                        value={createRowData.ParamName}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="50"
                        handleBlur={handleBlurName}
                        errorMsg={nameError}
                        isMultiline
                    />
                    <DropdownInput
                        name="ParamDataType"
                        title="Param Data Type"
                        value={createRowData.ParamDataType}
                        options={dropdownsData.paramDataTypeOptions}
                        handleInputChange={handleInputChange}
                        required
                        handleBlur={handleBlurValue}
                    />
                    <TextInput
                        name="ParamValue"
                        title="Param Value"
                        value={createRowData.ParamValue}
                        required
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlurValue}
                        type="string"
                        maxLength="100"
                        isMultiline
                        errorMsg={valueError}
                    />
                    <DropdownInput
                        name="Status"
                        title="Status"
                        value={createRowData.Status}
                        options={['Active', 'Inactive']}
                        handleInputChange={handleInputChange}
                        required
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData?.AdfPipelineParameterId ? createRowData.CreatedBy : userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData?.AdfPipelineParameterId ? formatDate(new Date(createRowData.CreatedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData?.AdfPipelineParameterId ? formatDate(new Date(createRowData.LastModifiedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '12px' }}>
                    <TextInput
                        name="Comment"
                        title="Comments"
                        value={createRowData.Comment}
                        handleInputChange={handleInputChange}
                        isMultiline
                        width="1080px"
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px', justifyContent: 'space-between' }}>
                <div />
                <ModalFooterActions
                    submitButtonVisible={submitButtonVisible}
                    submitButtonHandler={submitButtonHandler}
                    resetButtonHandler={resetButtonHandler}
                    cancelButtonHandler={cancelButtonHandler}
                />
            </DialogActions>
        </Dialog>

    );
};

export default AdfPipelineParameterForm;

AdfPipelineParameterForm.propTypes = {
    dropdownsData: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string
};
