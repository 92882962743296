export const ValidateField = (value) => FieldHasValue(value) ? value : '';
export const ValidateNumberField = (value) => value ? parseFloat(value) : 0;
export const FieldHasValue = (value) => value || value === 0;
export const ShowIfDifferentThanZero = (value) => value !== 0 ? value : '';
export const MatchWholeNumber = (value) => value?.toString().match(/^\d+$/);

export const IsNonText = (value) => value?.toString().match(/^[+-]?\d+\.?\d*$/);
export const IsANumber = (value) => !isNaN(value);
export const Trim = (value) => value && value.trim ? value.trim() : (value ?? '');
export const CountIf = (value) => value === 'Fail' ? 1 : 0;
export const ExponentialToDecimal = (value) => {
    if (value.toString().includes('e') || value.toString().includes('.')) {
        return value.toFixed(10).slice(0, 10).replace(/(\.\d*[1-9])0+$|\.0*$/, '$1');
    }
    return value;
};

export const MatchNumber = (value) => value?.match(/^[+-]?(\d*\.?\d*|\.\d+)$/);

export const MatchSRA = (value) => value?.match(/^P8-\d{1,2}.1$|P7-4.1/);

export const MatchOldTRFs = (value) => value?.match(/^P7-[1-6]$/) || value === 'M6-1';

export const checkURVGreaterThanLRV = (lrv, urv) => ((ValidateField(lrv) === '' || ValidateField(urv) === '')
    || (Number(ValidateField(lrv)) < Number(ValidateField(urv))));

export const checkTGCGreaterThanSetpointHH = (setpointHH, appliedTGC) => ((ValidateField(setpointHH) === '' || ValidateField(appliedTGC) === '')
    || (Number(ValidateField(setpointHH)) <= Number(ValidateField(appliedTGC))));

export const checkTGCLessThanSetpointLL = (setpointLL, appliedTGC) => ((ValidateField(setpointLL) === '' || ValidateField(appliedTGC) === '')
    || (Number(ValidateField(appliedTGC)) <= Number(ValidateField(setpointLL))));

export const checkDPSError = (dps) => (dps || dps === 0) && IsANumber(dps);

export const checkDPSUnitError = (dps) => (FieldHasValue(dps));

export const leadingZero = (num) => {
    if (FieldHasValue(num) === '-.') {
        return '-0.';
    }
    if (FieldHasValue(num) === '.' || FieldHasValue(num) === '+.') {
        return '0.';
    }
    if (FieldHasValue(num)) {
        return ExponentialToDecimal(parseFloat(num));
    }
    return num;
};

export const TestPointRange = (value, noOfRows) => Number(MatchWholeNumber(value)) >= 1 && (Number(MatchWholeNumber(value)) <= noOfRows);

export const displayPopUpMessage = (TestPoint, PrevVal, noOfRows) => Number(PrevVal) && TestPointRange(TestPoint, noOfRows) && Number(TestPoint) < Number(PrevVal);

export const conditionalValueSelector = (condition, valueIfTrue, valueIfFalse) => condition ? valueIfTrue : valueIfFalse;

export const CustomRound = number => Math.round(number * 10) / 10;
export const icEngApprovedValueYes = (icEngApprovedvalue) => (ValidateField(icEngApprovedvalue) === 'YES' || ValidateField(icEngApprovedvalue) === '');
