import { checkURVGreaterThanLRV, ValidateField } from 'helpers/Validation';
import { HMISectionCulatedRangeFormula } from '../P78/P78.formulas';
import { EngineeringUnit } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../C11/C11.data';
import { labelWidth } from '../P710/P710.data';

export const HMISectionDataP721 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F661ProximityProbeLengthLabel',
                label: 'Proximity Probe Length (m)',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F661ProximityProbeLength',
                value: ValidateField(formData.F661ProximityProbeLength),
                xs: 2,
                borderBottom: '0',
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0%',
                height: '1.2rem',
                textAlign: 'center'
            },
            {
                key: 'F662ExtensionCableLengthLabel',
                label: 'Extension Cable Length (m)',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F662ExtensionCableLength',
                value: ValidateField(formData.F662ExtensionCableLength),
                xs: 2,
                borderBottom: '0',
                type: 'number',
                maxLength: '10',
                height: '1.2rem',
                width: '100%',
                labelWidth: '0%',
                textAlignLabel: 'end',
                textAlign: 'center'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F663HmiReadingPriorToTestLabel',
                label: 'HMI Displacement Reading Prior to Test (HMI EU)',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F663HmiReadingPriorToTest',
                value: ValidateField(formData.F663HmiReadingPriorToTest),
                isRequired: true,
                xs: 2,
                borderBottom: '0',
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0%',
                height: '1.2rem',
                textAlign: 'center'
            },
            {
                key: 'F664GapVoltagePriorToTestLabel',
                label: 'Gap Voltage Prior to Test (V)',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F664GapVoltagePriorToTest',
                value: ValidateField(formData.F664GapVoltagePriorToTest),
                isRequired: true,
                xs: 2,
                borderBottom: '0',
                type: 'number',
                maxLength: '10',
                height: '1.2rem',
                width: '100%',
                labelWidth: '0%',
                textAlignLabel: 'end',
                textAlign: 'center'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F21HmiLrvLabel',
                label: 'HMI LRV',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F21HmiLrv',
                value: selectedTag.DateTested ? formData?.F21HmiLrv : formData.F21HmiLrv ?? selectedTag?.TsprHmiLrv,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                height: '1.2rem',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlign: 'center',
                borderBottom: '0',
                hasHmiError: !checkURVGreaterThanLRV(formData.F21HmiLrv, formData.F22HmiUrv)
            },
            {
                key: 'F22HmiUrvLabel',
                label: 'HMI URV',
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F22HmiUrv',
                value: selectedTag.DateTested ? formData?.F22HmiUrv : formData.F22HmiUrv ?? selectedTag?.TsprHmiUrv,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlignLabel: 'end',
                height: '1.2rem',
                textAlign: 'center',
                borderBottom: '0',
                hasHmiError: !checkURVGreaterThanLRV(formData.F21HmiLrv, formData.F22HmiUrv),
            }]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F105HmicalculatedRangeLabel',
                    label: 'HMI Configured Range',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F105HmicalculatedRange',
                    value: HMISectionCulatedRangeFormula(formData.F21HmiLrv, formData.F22HmiUrv, formData.F24HmiEngineeringUnit),
                    xs: 2,
                    isCalculatedNew: true,
                    readOnly: true,
                    inputWidth: '100%',
                    labelWidth: '0%',
                    borderTop: '0',
                    borderBottom: '0',
                    textAlign: 'center',
                    isAutoGrow: true,
                    marginTop: '0',
                    marginRight: '0',
                    fontColor: 'black'
                },
                {
                    key: 'F24HmiEngineeringUnitLabel',
                    label: EngineeringUnit.HMI_ENGINEERING_UNIT_EU,
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F24HmiEngineeringUnit',
                    value: selectedTag.DateTested ? formData?.F24HmiEngineeringUnit : formData?.F24HmiEngineeringUnit ?? selectedTag?.TsprHmiEngineeringUnits,
                    isRequired: true,
                    xs: 2,
                    direction: 'rtl',
                    inputWidth: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    borderTop: '0',
                    borderBottom: '0',
                    marginRight: '0',
                    textAlign: 'center',
                    maxLength: '14',
                    isAutoGrow: true,
                    marginTop: '0',
                }]
        },
        {
            key: 5,
            fields: [
                {
                    key: 'F25InstrumentLrvLabel',
                    label: 'Instrument LRV',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F25InstrumentLrv',
                    value: selectedTag.DateTested ? formData?.F25InstrumentLrv : formData?.F25InstrumentLrv ?? selectedTag?.TsprInstrumentCalibratedLrv,
                    xs: 2,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.3rem',
                    textAlign: 'center',
                    borderTop: '0',
                    borderBottom: '0',
                    hasInstError: !checkURVGreaterThanLRV(formData.F25InstrumentLrv, formData.F26InstrumentUrv)
                },
                {
                    key: 'F26InstrumentUrvLabel',
                    label: 'Instrument URV',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F26InstrumentUrv',
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    value: selectedTag.DateTested ? formData?.F26InstrumentUrv : formData?.F26InstrumentUrv ?? selectedTag?.InstrumentCalibratedUrv,
                    isRequired: true,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderBottom: '0',
                    hasInstError: !checkURVGreaterThanLRV(formData.F25InstrumentLrv, formData.F26InstrumentUrv)
                }]
        },
        {
            key: 6,
            fields: [
                {
                    key: 'F27InstrumentCalibratedRangeLabel',
                    label: 'Probe Linear Range',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F27InstrumentCalibratedRange',
                    value: HMISectionCulatedRangeFormula(formData.F25InstrumentLrv, formData.F26InstrumentUrv, formData.F118InstrumentEngineeringUnits),
                    xs: 2,
                    isCalculatedNew: true,
                    readOnly: true,
                    inputWidth: '100%',
                    labelWidth: '0%',
                    borderTop: '0',
                    marginBottom: '15px',
                    textAlign: 'center',
                    isAutoGrow: true,
                    marginRight: '0',
                },
                {
                    key: 'F118InstrumentEngineeringUnitsLabel',
                    label: EngineeringUnit.INSTRUMENT_ENGINEERING_UNIT_EU,
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F118InstrumentEngineeringUnits',
                    value: selectedTag.DateTested
                        ? formData?.F118InstrumentEngineeringUnits : formData?.F118InstrumentEngineeringUnits ?? selectedTag?.TsprInstrumentEngineeringUnits,
                    xs: 2,
                    textAlignLabel: 'end',
                    isRequired: true,
                    inputWidth: '100%',
                    labelWidth: '0%',
                    marginRight: '0',
                    textAlign: 'center',
                    borderTop: '0',
                    marginBottom: '15px',
                    maxLength: '17',
                    direction: 'rtl',
                    isAutoGrow: true,
                    marginTop: '0',
                }
            ]
        }
    ]
});
