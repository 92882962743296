export const VisualInspectSectionDataP716 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F119VisualInspection',
                label: `Visually inspect valve tagging, all control panel ancillaries, electrical cables, small-bore tubing and fittings,
                 equipment condition, pressure regulator setting, bug screen orientation with no blockage, general deterioration and no
                  leak sign. Record pressure of pneumatic/hydraulic supply to valve actuator.`,
                value: formData.F119VisualInspection,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]

});
