import styled from 'styled-components';

export const TagSelectionFilterWrapper = styled.div`
.MuiGrid-grid-xs-2 {
    max-width: 14%;
}
.MuiFormControlLabel-label{
    font-size: 10pt;
    text-align: center;
}

.Dropdown-placeholder.is-selected{
    width: 74%;
}

.Dropdown-control {
    border: 2px solid #498205;
    font-size: 9pt;
    padding: 5px;
    height: 32px;
    white-space:nowrap;
}
.Dropdown-disabled {
    border: 2px solid black;
    background-color: #A6A6A6;
    opacity: 0.4;
    font-size: 9pt;
    padding: 5px;
    height: 32px;
}
.Dropdown-menu {
    border: 1px solid #498205;
}

.Dropdown-option {
    font-size: 9pt;
    padding: 5px;
    min-height: 22px;
    white-space:nowrap;
    overflow-x: hidden;
 }
 .Dropdown-arrow {
    top: 5px;
    padding: 4px;
    border: solid #498205;
    border-width: 0 3px 3px 0;
    padding: 6px;
 }
 .Dropdown-noresults {
     font-size: 8pt
 }
 .Dropdown-menu {
    margin-left: 0;
 }
`;

export const FilterToolTip = styled.div`
& {
	position:relative;

}

&:before {
	display:none;
	border-bottom: 5px solid #1a1a1a;
	z-index:8;
	font-size:0;
	line-height:0;
	width:0;
	height:0;
}
&:after {
	display: none;
	${props => props['data-tip'] !== '' && 'content:attr(data-tip)'};
	position: absolute;
	bottom: 30px;
	left: 110px;
	padding: 5px 8px;
	border: 1px solid;
	background: #fff;
	color: #1a1a1a;
	z-index: 9;
	font-size: 8pt;
    transform: ${props => props.transform ? 'translate(-79.5%)' : 'translate(0%)'};
    width: 7rem;
	line-height: 18px;
	border-radius: 3px;
    word-wrap: break-word;
}
&:hover:before,
&:hover:after {
	display:block;
}
`;
