import { AsLeftResultFormulaP611 } from '../P611/P611.formulas';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';
import { disableAsLeft } from '../../AsLeftTestTableSection.helpers';
import { conditionalValueSelector } from 'helpers/Validation';

export const AsLeftTestTableSectionDataP733P620 = (selectedTag, formData) => {
    const altDisabled = disableAsLeft(formData.F102AftResult);
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'As-LeftTest',
                label: 'As-Left Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 5px 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'NoData',
                isLabel: true,
                fontWeight: '700',
                xs: 6,
                fontSize: '9pt'
            },
            {
                key: 'Alt_HeaterPackage',
                label: 'Heater Package with Temperature Trip Amplifier',
                isLabel: true,
                xs: 6,
                textAlign: 'center',
                fontSize: '9pt',
                fontWeight: '700',
                padding: '0 0 8px 8px',
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F443AltForcedOutputsignal',
                label: 'Forced Trip Signal',
                value: conditionalValueSelector(altDisabled,
                    '', formData.F443AltForcedOutputsignal),
                isRequired: !altDisabled,
                isDisabled: altDisabled,
                readOnly: altDisabled,
                xs: 6,
                labelWidth: '51%',
                width: conditionalValueSelector(altDisabled, '47.5%', '49%'),
                isDropdown: !altDisabled,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                inputTextAlign: 'center',
                borderBottom: '0',
                height: conditionalValueSelector(altDisabled, '1.2rem', '1.5rem'),
            },
            {
                key: 'F651AltTemperatureTripSetpoint',
                label: 'Temperature Trip Setpoint',
                value: conditionalValueSelector(altDisabled, '',
                    formData.F651AltTemperatureTripSetpoint),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                xs: 6,
                type: 'number',
                maxLength: '10',
                labelWidth: '40%',
                width: '60%',
                marginRight: '0.3rem',
                textAlign: 'center',
                direction: 'rtl',
                borderRight: '0',
                inputMarginRight: '4px',
                borderBottom: '0',
                height: conditionalValueSelector(altDisabled, '1.2rem', '1.24rem')
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F444AltConfirmContactorOperates',
                label: 'Confirm Contactor Operates Correctly',
                value: conditionalValueSelector(altDisabled, '',
                    formData.F444AltConfirmContactorOperates),
                isRequired: !altDisabled,
                isDisabled: altDisabled,
                readOnly: altDisabled,
                isDropdown: !altDisabled,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                labelWidth: '51%',
                width: conditionalValueSelector(altDisabled, '47.5%', '49%'),
                height: conditionalValueSelector(altDisabled, '1.2rem', '1.5rem'),
                marginLeft: '8px',
                inputTextAlign: 'center',
                xs: 6,
                borderBottom: '0'
            },
            {
                key: 'F652AsLeftTripPoint',
                label: 'As-Left Trip Point',
                value: conditionalValueSelector(altDisabled, '', formData.F652AsLeftTripPoint),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                maxLength: '10',
                xs: 6,
                labelWidth: '40%',
                width: '60%',
                marginRight: '0.3rem',
                textAlign: 'center',
                direction: 'rtl',
                borderRight: '0',
                inputMarginRight: '4px',
                height: conditionalValueSelector(altDisabled, '1rem', '1.12rem'),
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F579AltConfirmLampsSwitchCorrectly',
                label: 'Confirm Lamps Switch Correctly',
                value: conditionalValueSelector(altDisabled, '', formData.F579AltConfirmLampsSwitchCorrectly),
                isRequired: !altDisabled,
                isDisabled: altDisabled,
                readOnly: altDisabled,
                isDropdown: !altDisabled,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                labelWidth: '51%',
                width: conditionalValueSelector(altDisabled, '47.5%', '49%'),
                height: conditionalValueSelector(altDisabled, '1.2rem', '1.5rem'),
                marginLeft: '8px',
                inputTextAlign: 'center',
                xs: 6,
                borderBottom: '0'
            }]
        },
        {
            key: 6,
            fields: [{
                key: 'F445AltConfirmcorrectHmiindication',
                label: 'Confirm Correct HMI Indication ',
                value: conditionalValueSelector(altDisabled, '',
                    formData.F445AltConfirmcorrectHmiindication),
                isDisabled: altDisabled,
                isDropdown: !altDisabled,
                isRequired: !altDisabled,
                readOnly: altDisabled,
                xs: 6,
                labelWidth: '51%',
                width: conditionalValueSelector(altDisabled, '47.5%', '49%'),
                height: conditionalValueSelector(altDisabled, '1.4rem', ''),
                marginLeft: '8px',
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                inputTextAlign: 'center',
                marginBottom: '4px',
                borderBottom: '0',
            },
            {
                key: 'F103AltResult',
                label: 'As-Left Result',
                value: conditionalValueSelector(altDisabled, '',
                    AsLeftResultFormulaP611(formData)),
                isDisabled: altDisabled,
                readOnly: true,
                isCalculatedNew: true,
                textAlign: 'center',
                height: '1.26rem',
                borderRight: '0',
                marginRight: '8px',
                direction: 'rtl',
                inputMarginRight: '4px',
                isLabelBold: true,
                fontWeight: '700',
                xs: 6,
                labelWidth: '39.4%',
                width: '60%',
                borderBottom: '0',
            }]
        }]
    };
};
