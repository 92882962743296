import { formatDate } from 'helpers/DateFormatter';

export const UserInfoSectionData = (selectedTag, userName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F2SapFloc',
                label: 'Tag Number',
                value: formData.F2SapFloc ?? selectedTag.HdLocation,
                labelWidth: '26%',
                xs: 4
            },
            {
                key: 'F4CompletedBy',
                label: 'Created By',
                value: formData?.F4CompletedBy ?? userName?.name,
                labelWidth: '26%',
                xs: 4
            },
            {
                key: 'F5DateTested',
                label: 'Created Date',
                value: formData.F5DateTested ?? selectedTag.DateTested
                    ? formatDate(new Date(formData.F5DateTested ?? selectedTag.DateTimeTested))
                    : formatDate(selectedTag.currentDateTime),
                labelWidth: '29%',
                xs: 4
            },
            {
                key: 'Nodata',
                label: '',
                value: '',
                xs: 4
            },
            {
                key: 'F4ModifiedBy',
                label: 'Modified By',
                value: formData.F4ModifiedBy,
                labelWidth: '26%',
                xs: 4
            },
            {
                key: 'F51DateLastModified',
                label: 'Last Modified Date',
                value: formData.F51DateLastModified ? formatDate(new Date(formData.F51DateLastModified)) : '', // dd-mmm-yyyy HH:mm AM/PM, // formatDate(new Date())
                labelWidth: '29%',
                xs: 4
            },
            {
                key: 'F731Doc1Url',
                label: 'Document 1 URL',
                value: selectedTag.DateTested ? formData?.F731Doc1Url : formData?.F731Doc1Url ?? selectedTag?.Doc1Url,
                width: '82%',
                labelWidth: '13%',
                textalign: 'left',
                xs: 8,
                wordBreak: true
            },
            {
                key: 'F729Doc1Number',
                label: 'Document 1 Number',
                value: selectedTag.DateTested ? formData?.F729Doc1Number : formData?.F729Doc1Number ?? selectedTag?.Doc1Number,
                width: '65%',
                labelWidth: '29%',
                xs: 4,
                borderTop: '0',
                marginBottom: '15px',
                wordBreak: true
            },
            {
                key: 'F732Doc2Url',
                label: 'Document 2 URL',
                value: selectedTag.DateTested ? formData?.F732Doc2Url : formData?.F732Doc2Url ?? selectedTag?.Doc2Url,
                width: '82%',
                labelWidth: '13%',
                marginBottom: '15px',
                textalign: 'left',
                xs: 8,
                wordBreak: true
            },
            {
                key: 'F730Doc2Number',
                label: 'Document 2 Number',
                value: selectedTag.DateTested ? formData?.F730Doc2Number : formData?.F730Doc2Number ?? selectedTag?.Doc2Number,
                marginLeft: '2rem',
                width: '65%',
                labelWidth: '29%',
                xs: 4,
                borderTop: '0',
                marginBottom: '15px',
                wordBreak: true
            },
            ]
        }
    ]

});
