import { FieldHasValue } from 'helpers/Validation';

export const AsFoundResultFormula = (formData) => {
    if (
        FieldHasValue(formData.F427AftSmokeDetectorHousing)
        && FieldHasValue(formData.F372AftHmistatus1)) {
        if (formData.F427AftSmokeDetectorHousing === 'YES' && formData.F372AftHmistatus1 === 'ALARM') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
