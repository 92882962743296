export const VisualInspectSectionDataP8141 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect instrument for general condition, security of mounting,
                 mechanical damage, signs of corrosion, field cable/gland condition, correct tagging.`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }]

});
