import { labelWidth } from '../P710/P710.data';

export const HMISectionDataP732 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [
            {
                key: 'F589RelayCoilStatusPriorToTestLabel',
                label: 'Relay Coil Status Prior to Test',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '18px 0 0 0'
            },
            {
            key: 'F589RelayCoilStatusPriorToTest',
            value: formData.F589RelayCoilStatusPriorToTest,
            isDropdown: true,
            placeholder: 'Select Relay Status',
            options: [
                { name: 'Select Relay Status', value: '' },
                { name: 'Energized', value: 'Energized' },
                { name: 'De-energized', value: 'De-energized' }],
            width: '100%',
            labelWidth: '0%',
            xs: 2,
            inputTextAlign: 'center',
            marginTop: '15px'
        },
        {
            key: 'F590RelayContactPositionPriorToTestLabel',
            label: 'Relay Contact Position Prior to Test',
            xs: 4,
            isLabel: true,
            labelWidth,
            fontWeight: '600',
            textAlign: 'right',
            fontSize: '9pt',
            padding: '18px 5px 0 0'
        },
        {
            key: 'F590RelayContactPositionPriorToTest',
            value: formData.F590RelayContactPositionPriorToTest,
            isDropdown: true,
            placeholder: 'Select Position',
            options: [{ name: 'Select Position', value: '' },
            { name: 'Open', value: 'Open' },
            { name: 'Closed', value: 'Closed' }],
            xs: 2,
            labelWidth: '0%',
            width: '100%',
            inputTextAlign: 'center',
            marginTop: '15px',
        }]
    }]
});
