export const VisualInspectSectionDataP618 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F179VisuallyInspection',
                label: `Visually inspect physical device for signs of damage, ID tags present, 
                        corrosion, moisture ingress, inspect wiring and conduit. 
                        Check thermowell for general deterioration and no leak sign.`,
                value: formData.F179VisuallyInspection,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit',
                marginTop: '15px'
            }]
        }
    ]

});
