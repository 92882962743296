export const informationHeaderData = [
    {
        field: 'Id',
        headerName: 'ID',
        width: '60',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Pdfname',
        headerName: 'PDF Name',
        width: '200',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'Pdffile',
        headerName: 'PDF File',
        width: '100',
        hideInTable: false,
        isDisabled: true,
        dataType: 'pdf'
    },
    {
        field: 'PdfdisplayOnScreen',
        headerName: 'PDF Display On Screen',
        width: '120',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Status',
        headerName: 'PDF Status',
        width: '100',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '110',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const informationInitialState = {
    Id: null,
    Pdfname: null,
    Pdffile: null,
    PdfdisplayOnScreen: null,
    Pdfstatus: null,
    Status: 'Inactive',
    CreatedBy: null,
    CreatedDate: null,
    ModifiedBy: null,
    LastModifiedDate: null,
    Comments: null
};

export const informationSearchState = {
    Id: '',
    Pdfname: '',
    Pdffile: '',
    PdfdisplayOnScreen: '',
    Status: '',
    Pdfstatus: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const informationApiEndpoint = {
    read: 'InfoDocuments'
};
