import { TxAsFoundLabel } from '../../Sections.constants';
import { labelWidth } from '../HMISection/Data/P710/P710.data';
import { IsLevelTrf } from '../UnitsUsedSection/Data/TxAsFoundS74/TxAsFoundS74.data';

const paddingLabel = '20px 5px 0 0';
const txAsLeftSectionData = (formName, formData) => {
    const isLevelS74Trf = (['S7-4'].includes(formName) && IsLevelTrf(formData));
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'F981TxAsLeftLabel',
                label: 'Tx As Left',
                xs: isLevelS74Trf ? 2 : 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F981TxAsLeft',
                value: formData.F981TxAsLeft,
                type: 'number',
                maxLength: '10',
                height: '1.2rem',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlign: 'center',
                marginTop: '15px',
                isRequired: formName === 'S7-3'
            },
            {
                key: 'F982AltAlternativeTxLabel',
                label: TxAsFoundLabel.Alternative_Tx,
                xs: isLevelS74Trf ? 2 : 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel
            },
            {
                key: 'F982AltAlternativeTx',
                value: formData.F982AltAlternativeTx,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlignLabel: 'end',
                height: '1.2rem',
                textAlign: 'center',
                marginTop: '15px'
            },
            ...(isLevelS74Trf
                ? [
                    {
                        key: 'F997AltSightGlassLabel',
                        label: TxAsFoundLabel.Sight_Glass,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F997AltSightGlass',
                        value: formData.F997AltSightGlass,
                        type: 'number',
                        maxLength: '10',
                        xs: 2,
                        width: '100%',
                        labelWidth: '0%',
                        textAlignLabel: 'end',
                        height: '1.2rem',
                        textAlign: 'center',
                        marginTop: '15px',
                    }
                ] : []),
            ]
        }]
    };
};
export const selectTxAsLeftSectionData = (formName, formData) => {
    const formNameToFunctionMap = {
        'S7-2': () => txAsLeftSectionData(formName, formData),
        'S7-3': () => txAsLeftSectionData(formName, formData),
        'S7-4': () => txAsLeftSectionData(formName, formData)
    };
    let filterViewData = { rows: [] };
    const functionToExecute = formNameToFunctionMap[formName];
    if (functionToExecute) {
        filterViewData = functionToExecute();
    }
    return filterViewData;
};
