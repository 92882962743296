export const TYPE = {
    NAME: 'NAME'
};

export const HOME_ACTIONS = {
    HOME_ACTIONS_GET_ALL_BP_REGION_DATA_SUCCESS: 'HOME_ACTIONS_GET_ALL_BP_REGION_DATA_SUCCESS',
    HOME_ACTIONS_GET_ALL_BP_REGION_DATA_PENDING: 'HOME_ACTIONS_GET_ALL_BP_REGION_DATA_PENDING',
    HOME_ACTIONS_GET_ALL_BP_REGION_DATA_FAILURE: 'HOME_ACTIONS_GET_ALL_BP_REGION_DATA_FAILURE',
    HOME_ACTIONS_GET_ALL_BARRIER_TYPE_DATA_SUCCESS: 'HOME_ACTIONS_GET_ALL_BARRIER_TYPE_DATA_SUCCESS',
    HOME_ACTIONS_GET_ALL_WORK_TYPE_DATA_SUCCESS: 'HOME_ACTIONS_GET_ALL_WORK_TYPE_DATA_SUCCESS',
    HOME_SET_ASSET_NAME: 'HOME_SET_ASSET_NAME',
    HOME_SET_GROUP_NAME: 'HOME_SET_GROUP_NAME',
    HOME_ACTIONS_GET_ASSET_DATA_SUCCESS: 'HOME_ACTIONS_GET_ASSET_DATA_SUCCESS',
    HOME_ACTIONS_GET_ASSET_DATA_PENDING: 'HOME_ACTIONS_GET_ASSET_DATA_PENDING',
    HOME_ACTIONS_GET_ASSET_DATA__FAILURE: 'HOME_ACTIONS_GET_ASSET_DATA__FAILURE',
    HOME_SET_WORK_TYPE_NAME: 'HOME_SET_WORK_TYPE_NAME',
    HOME_SET_BARRIER_TYPE_NAME: 'HOME_SET_BARRIER_TYPE_NAME',
    HOME_ACTIONS_GET_TEST_MAKE_MODEL_DATA_SUCCESS: 'HOME_ACTIONS_GET_TEST_MAKE_MODEL_DATA_SUCCESS',
    HOME_ACTIONS_GET_TAG_RECORDS_DATA_SUCCESS: 'HOME_ACTIONS_GET_TAG_RECORDS_DATA_SUCCESS',
    HOME_ACTIONS_GET_TAG_BI_RECORDS_DATA_SUCCESS: 'HOME_ACTIONS_GET_TAG_BI_RECORDS_DATA_SUCCESS',
    HOME_ACTIONS_GET_TRF_MASTER_LISTS_DATA_SUCCESS: 'HOME_ACTIONS_GET_TRF_MASTER_LISTS_DATA_SUCCESS',
    HOME_SET_USERSROLEANDREGION: 'HOME_SET_USERSROLEANDREGION',
    HOME_PREFETCHING_OFFLINEDATA_PENDING: 'HOME_PREFETCHING_OFFLINEDATA_PENDING',
    HOME_PREFETCHING_OFFLINEDATA_SUCCESS: 'HOME_PREFETCHING_OFFLINEDATA_SUCCESS',
    HOME_PREFETCHING_OFFLINEDATA_FAILURE: 'HOME_PREFETCHING_OFFLINEDATA_FAILURE',
    HOME_CLEANUP_NONESSENTAIL_STATES: 'HOME_CLEANUP_NONESSENTAIL_STATES',
    HOME_ACTIONS_GET_DETAILS_BY_REGION: 'HOME_ACTIONS_GET_DETAILS_BY_REGION',
    INFOPAGE_GETDOCUMENTS_PENDING: 'INFOPAGE_GETDOCUMENTS_PENDING',
    INFOPAGE_GETDOCUMENTS_SUCCESS: 'INFOPAGE_GETDOCUMENTS_SUCCESS',
    INFOPAGE_GETDOCUMENTS_FAILURE: 'INFOPAGE_GETDOCUMENTS_FAILURE',
    SET_CURRENT_VIEW: 'SET_CURRENT_VIEW',
    UPDATE_INVALIDATION_STATUS: 'UPDATE_INVALIDATION_STATUS',
    CHECK_OFFLINE_DATA_PRESENT: 'CHECK_OFFLINE_DATA_PRESENT',
    UPDATE_SYNC_BUTTON_STATUS: 'UPDATE_SYNC_BUTTON_STATUS',
    SET_DATA_LOADED: 'SET_DATA_LOADED',
    SET_PREVIOUS_SCREEN: 'SET_PREVIOUS_SCREEN'
};

export const DEFAULT_WORK_TYPE = 'Preventative';

export const MRAT_USER_ROLE = {
    View: 'Viewer',
    Technician: 'Technician',
    Engineer: 'Engineer'
};

export const INFOPAGE_ACTIONS = {
    INFOPAGE_GETDOCUMENTS_PENDING: 'INFOPAGE_GETDOCUMENTS_PENDING',
    INFOPAGE_GETDOCUMENTS_SUCCESS: 'INFOPAGE_GETDOCUMENTS_SUCCESS',
    INFOPAGE_GETDOCUMENTS_FAILURE: 'INFOPAGE_GETDOCUMENTS_FAILURE',
};

export const INFO_PAGE = {
    USER: 'USER',
    VIEW: 'infoAdmin'
};

export const homePageLabels = {
    Group: 'Group',
    Asset: 'Asset',
    BarrierType: 'Barrier Type'
};
