import html2canvas from 'html2canvas';

export const parseFormDataObj = (sectionData) => {
    const initialFormData = {};
    sectionData.rows.forEach((row) => {
        row.fields.forEach((field) => {
            initialFormData[field.key] = field.value;
        });
    });

    return initialFormData;
};

export const parseMandatoryFormDataObj = (sectionData) => {
    let allSectionFields = [];
    sectionData.rows.forEach((row) => {
        allSectionFields = [...allSectionFields, ...row.fields];
    });

    return allSectionFields;
};

function replaceDatePicker(datePicker) {
    if (!datePicker) return;
    const newDatePicker = document.createElement('p');
    newDatePicker.id = 'date-picker-inline';
    newDatePicker.textContent = datePicker?.value ?? datePicker?.textContent;
    datePicker.parentNode?.replaceChild(newDatePicker, datePicker);
}
const isTagOrDescOrVal = (currentId) => (currentId.includes('TagNumber') || currentId.includes('Desc') || currentId.includes('Val'))
    && !currentId.includes('AutoGrow');

const commentStrings = [
    'InspectionCommentsG',
    'FlameproofCommentsD',
    'IncreasedSafetyCommentsE',
    'IntrinsicallySafeCommentsI',
    'EncapsulationCommentsM',
    'NonSparkingCommentsN',
    'PurgePressurizedCommentsP'
];
export const captureTRFImageHandler = (username, date) => {
    const element = document.getElementById('trfimage');
    const bodywrapper = document.getElementById('bodywrapper');
    // Capture original width of body before restricting to 1080px.
    // Reset it back before print. This will help to calculate image height as per 1080 px width.
    const bodyWrapperMaxWidth = bodywrapper.style.maxWidth;
    bodywrapper.style.maxWidth = '1080px';
    const modifiedBy = document.getElementById('F4ModifiedBy');
    const dateLastModified = document.getElementById('F51DateLastModified');
    const datePicker = document.getElementById('date-picker-inline');
    const textAreaNodes = document.querySelectorAll('textArea');
    const { border, padding, fontSize,
        borderColor, textAlign, minHeight, paddingLeft } = {
        border: '2px solid',
        padding: '8px',
        fontSize: '9pt',
        borderColor: 'rgb(166,166,166)',
        textAlign: 'left',
        minHeight: '2rem',
        paddingLeft: '5px'
    };
    let width = '60%';
    textAreaNodes.forEach(node => {
        const currentId = node.id;
        const pid = `p${currentId}`;
        const para = document.createElement('p');
        para.setAttribute('id', pid);
        if (currentId === 'F15Note' || currentId === 'F84Note') {
            width = '85%';
        } else if (isTagOrDescOrVal(currentId)) {
            width = '100%';
        } else if (currentId.includes('AutoGrow')) {
            width = '80%';
        } else if (commentStrings.some(str => currentId.includes(str))) {
            width = '99%';
        }
        const textValue = document.getElementById(currentId)?.value;
        para.textContent = textValue;
        const { style } = para;
        style.fontSize = fontSize;
        style.borderColor = borderColor;
        style.overflowWrap = 'break-word';
        style.textAlign = textAlign;
        style.width = width;
        style.hyphens = 'auto';
        if (isTagOrDescOrVal(currentId)) {
            style.minHeight = minHeight;
            style.paddingLeft = paddingLeft;
            style.border = border;
            style.borderLeft = currentId.includes('Tst1TagNumber') ? '0' : border;
            style.borderRight = '0';
            document.getElementById(currentId)?.replaceWith(para);
        } else if (currentId.includes('AutoGrow')) {
            const currentElement = document.getElementById(currentId);
            currentElement.style.textAlign = 'left';
            currentElement.style.paddingBottom = '4px';
            const parentElement = currentElement.parentNode;
            parentElement.style.marginTop = '5px';
            parentElement.style.paddingTop = '0';
            style.margin = '1px';
            textValue.length > 24 && document.getElementById(currentId)?.replaceWith(para);
        } else if (commentStrings.some(str => currentId.includes(str))) {
            style.margin = '0.2px';
            style.border = border;
            style.borderRight = '0';
            style.borderLeft = '0';
            style.borderBottom = '0';
            style.borderColor = 'rgb(120,120,120)';
            style.paddingLeft = '2px';
            textValue.length > 40 && document.getElementById(currentId)?.replaceWith(para);
        } else {
            style.border = border;
            style.padding = padding;
            textValue?.length > 90 && document.getElementById(currentId)?.replaceWith(para);
        }
    });
    replaceDatePicker(datePicker);
    modifiedBy.innerHTML = username;
    dateLastModified.innerHTML = date;
    const header = document.getElementById('header');
    bodywrapper.scrollTo(0, 0);
    const printHeight = bodywrapper.scrollHeight;
    bodywrapper.style.maxWidth = bodyWrapperMaxWidth;
    return html2canvas(element, {
        allowTaint: true,
        useCORS: true,
        logging: false,
        // 1080 is for 8inch i-safe tablet resolution
        // Letter size width (816px) was degrading the image quality
        windowWidth: 1080,
        height: printHeight + header.scrollHeight,
        windowHeight: printHeight + (2 * header.scrollHeight),
    });
};
