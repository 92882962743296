import { labelWidth } from '../P710/P710.data';
import { valveSizeValue } from '../P725/P725.data';

export const ValveSectionDataS71 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F340ValveSizeLabel',
                    label: 'Valve Size',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '18px 0 0 0'
                },
                {
                    key: 'F340ValveSize',
                    value: valveSizeValue(selectedTag, formData),
                    isRequired: true,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    type: 'number',
                    maxLength: '10',
                    marginTop: '15px',
                    borderBottom: '0',
                },
                {
                    key: 'F954RegulatorPressureLabel',
                    label: 'Regulator Pressure As-Found (bar)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '18px 0 0 0'
                },
                {
                    key: 'F954RegulatorPressure',
                    value: formData.F954RegulatorPressure,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '0%',
                    width: '98%',
                    height: '1.2rem',
                    textAlignLabel: 'end',
                    textAlign: 'center',
                    isRequired: true,
                    marginTop: '15px',
                }
            ]
        }]
});
