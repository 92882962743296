import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_AZURE_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true
    }
});
ai.loadAppInsights();
ai.setAuthenticatedUserContext(sessionStorage.getItem('userName'));
// could find better way to access app insight opject in any Javascript code.
window.appInsights = ai;
