import { InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { AutocompleteWrapper } from './AutocompleteInput.styled';
import { backgroundColor } from '../AdminScreen.helper';

const AutocompleteInput = ({ name, title, value, options = [], handleInputChange, handleBlur, required, addNewOption, noOptionText, errorMsg }) => {
    const bgColor = backgroundColor(value, required);
    const filter = createFilterOptions();

    return (
        <AutocompleteWrapper>
            <div style={{ display: 'flex' }}>
                <InputLabel style={{ fontSize: '12px', color: 'black', paddingLeft: '3px', paddingBottom: '0', fontWeight: '600' }}>
                    {title}
                </InputLabel>
                {errorMsg && <span style={{ fontSize: '9px', color: 'rgb(255, 0, 0)', fontWeight: 'bold', right: '10%' }}>{errorMsg}</span>}
            </div>
            <Autocomplete
                disablePortal
                disableClearable
                noOptionsText={noOptionText || 'No Options..'}
                id={name}
                style={{ width: '250px', marginTop: '4px', backgroundColor: bgColor }}
                value={value}
                name={name}
                options={options}
                onChange={handleInputChange}
                filterOptions={(option, params) => {
                    const filtered = filter(option, params);
                    if (params.inputValue !== '' && filtered.length === 0 && addNewOption) {
                        filtered.push(`Add "${params.inputValue}"`);
                    }
                    return filtered;
                }}
                renderInput={(params) => (
                    <TextField
                        /* eslint-disable react/jsx-props-no-spreading */
                        {...params}
                        variant="outlined"
                        type="search"
                        onChange={(e => e.target.value === '' && handleInputChange(e))}
                        onBlur={handleBlur}
                    />
                )}
            />
        </AutocompleteWrapper>
    );
};

export default AutocompleteInput;

AutocompleteInput.propTypes = {
    addNewOption: PropTypes.bool,
    errorMsg: PropTypes.string,
    handleBlur: PropTypes.func,
    handleInputChange: PropTypes.func,
    name: PropTypes.string,
    noOptionText: PropTypes.string,
    options: PropTypes.array,
    required: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.string
};
