import React from 'react';
import PropTypes from 'prop-types';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import Grid from '@material-ui/core/Grid';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ErrorText } from './index.styled';

const ErrorPage = (props) => {
    const appInsights = useAppInsightsContext();
    const renderMessage = () => {
        const message = props.message ? props.message : 'Something went wrong. Please contact system administrator.';
        navigator.onLine && appInsights.trackException({
            error: message,
            exception: message,
            severityLevel: SeverityLevel.Error
        });

        return message;
    };

    return (
        <SiteWrapper
            headerText={
                (
                    <div style={{ textAlign: 'center' }}>
                        Error
                    </div>
                )
            }
        >
            <div className="container">
                <Grid container>
                    <Grid container item xs={12}>
                        <Grid item xs={12} style={{ display: 'flex' }}>
                            <ErrorText>
                                { renderMessage() }
                            </ErrorText>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </SiteWrapper>
    );
};

export default ErrorPage;

ErrorPage.propTypes = {
    message: PropTypes.string
};
