import { Placeholder } from '../../Sections.constants';
import { dropdownPlaceholder } from '../AFT_FireTestSection/Data/M71/M71.data';

export const Detectorreinstatement = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F394DetectorMaVOutputReturnedBackToNormalLabel',
                    label: 'Detector mA/V output returned back to normal level (workshop test)',
                    xs: 4,
                    labelWidth: '98%',
                    padding: '5px 0 0 8px',
                    isLabel: true,
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F394DetectorMaVOutputReturnedBackToNormal',
                    value: (formData.F383IsThereHazardousAreaRestriction !== 'NO') ? formData.F394DetectorMaVOutputReturnedBackToNormal : '',
                    isRequired: (formData.F383IsThereHazardousAreaRestriction !== 'NO'),
                    isDisabled: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    readOnly: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    xs: 2,
                    borderTop: '0',
                    width: '100%',
                    labelWidth: '0',
                    isDropDown: (formData.F383IsThereHazardousAreaRestriction !== 'NO'),
                    height: '2.24rem',
                    inputTextAlign: 'center',
                    placeholder: dropdownPlaceholder,
                    options: [{ name: dropdownPlaceholder, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    marginTop: '4px',
                    paddingPlaceholder: '6px',
                    arrowMarginTop: '6px'
                },
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F395ConfirmThatDetectorHasBeenReinstatedBackToFieldLabel',
                    label: 'Confirm that detector has been reinstated back to field location (workshop test)',
                    xs: 4,
                    labelWidth: '98%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F395ConfirmThatDetectorHasBeenReinstatedBackToField',
                    value: (formData.F383IsThereHazardousAreaRestriction !== 'NO') ? formData.F395ConfirmThatDetectorHasBeenReinstatedBackToField : '',
                    isRequired: (formData.F383IsThereHazardousAreaRestriction !== 'NO'),
                    isDisabled: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    readOnly: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    xs: 2,
                    borderTop: '0',
                    width: '100%',
                    labelWidth: '0',
                    isDropDown: (formData.F383IsThereHazardousAreaRestriction !== 'NO'),
                    height: '2.24rem',
                    inputTextAlign: 'center',
                    placeholder: dropdownPlaceholder,
                    options: [{ name: dropdownPlaceholder, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    paddingPlaceholder: '6px',
                    arrowMarginTop: '6px'
                },
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F382HmistatusAfterTestLabel',
                    label: 'HMI Status after detector reinstatement to the field (after workshop test)',
                    xs: 4,
                    labelWidth: '100%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F382HmistatusAfterTest',
                    value: (formData.F383IsThereHazardousAreaRestriction !== 'NO') ? formData.F382HmistatusAfterTest : '',
                    isRequired: (formData.F383IsThereHazardousAreaRestriction !== 'NO'),
                    isDisabled: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    readOnly: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    xs: 2,
                    borderTop: '0',
                    width: '100%',
                    labelWidth: '0',
                    isDropDown: (formData.F383IsThereHazardousAreaRestriction !== 'NO'),
                    height: '2.24rem',
                    inputTextAlign: 'center',
                    placeholder: Placeholder.HMI_STATUS_PLACEHOLDER,
                    options: [{ name: Placeholder.HMI_STATUS_PLACEHOLDER, value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'LOOP FAULT', value: 'LOOP FAULT' }],
                    marginBottom: '4px',
                    borderBottom: '0',
                    paddingPlaceholder: '8px',
                    arrowMarginTop: '8px'
                },
            ]
        }
    ]
});
export const selectDetectorreinstatementSectionData = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'M6-8') {
        filterViewData = Detectorreinstatement(selectedTag, formName, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
