export const VisualInspectSectionDataP77 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect source and detector installations (e.g. corrosion on source or detector, cable gland 
                    condition, blind plugs in place), confirm that source mechanism is in good condition and source can be 
                    withdrawn into safe (padlocked) position.`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        },
        {
            key: 2,
            fields: [
                {
                key: 'F194DiagnosticMessages',
                label: 'Retrieve any diagnostic messages',
                value: formData.F194DiagnosticMessages,
                isMultiline: true,
                xs: 12,
                marginTop: '15px',
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit',
            }]
        }
    ]
});
