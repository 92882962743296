import { ValidateField, FieldHasValue } from 'helpers/Validation';

export const SraPassFailFormula = (formData) => {
    if (FieldHasValue(formData.F326istheSraontheIcssalarmAndEventLogChronicle)
        && FieldHasValue(formData.F327DoesTheIcssAlarmTagOrDescriptioncontainTheTextSra)
        && FieldHasValue(formData.F328IsTheIcssAlarmPriorityAsPerAlarmResponseManual)
        && FieldHasValue(formData.F329IsTheSraClearlyIdentifiedonTheHmi)
        && FieldHasValue(formData.F330DoesTheOperatorHaveKnowledgeOfTheResponseRequired)
        && FieldHasValue(formData.F331IsTheArmReadilyAvialableToTheOperator)
        && FieldHasValue(formData.F332IsTheResponseInTheArmAdequate)
        && FieldHasValue(formData.F333IsTimeToRespondLessThan10minutes)) {
        if (ValidateField(formData.F326istheSraontheIcssalarmAndEventLogChronicle) === 'Yes'
            && ValidateField(formData.F327DoesTheIcssAlarmTagOrDescriptioncontainTheTextSra) === 'Yes'
            && ValidateField(formData.F328IsTheIcssAlarmPriorityAsPerAlarmResponseManual) === 'Yes'
            && ValidateField(formData.F329IsTheSraClearlyIdentifiedonTheHmi) === 'Yes'
            && ValidateField(formData.F330DoesTheOperatorHaveKnowledgeOfTheResponseRequired) === 'Yes'
            && ValidateField(formData.F331IsTheArmReadilyAvialableToTheOperator) === 'Yes'
            && ValidateField(formData.F332IsTheResponseInTheArmAdequate) === 'Yes'
            && ValidateField(formData.F333IsTimeToRespondLessThan10minutes) === 'Yes') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
