import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { conditionalValueSelector } from 'helpers/Validation';

export const asFoundPstMthod = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F494PstsettingLabel',
                    label: 'As-Found PST Method?',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '18px 0 0 0',
                },
                {
                    key: 'F494Pstsetting',
                    value: conditionalValueSelector(formData.F494Pstsetting, formData.F494Pstsetting, 'Both'),
                    isDropdown: true,
                    isRequired: true,
                    options: [{ name: 'Both', value: 'Both' },
                    { name: 'Solenoid Valve', value: 'Solenoid Valve' },
                    { name: 'Smart Positioner', value: 'Smart Positioner' }],
                    xs: 2,
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    marginTop: '15px',
                    noDefaultOption: true,
                }
            ]
        },
    ]
});
