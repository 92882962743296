import { FieldHasValue, ValidateField, ValidateNumberField, ExponentialToDecimal } from 'helpers/Validation';

export const AsLeftTableF63Hmi2Inst1Formula = (formData) => {
    let HmiInst1;
    if (FieldHasValue(formData.F21HmiLrv)) {
        HmiInst1 = '0% - '.concat(ValidateField(formData.F21HmiLrv))
            .concat(FieldHasValue(formData.F24HmiEngineeringUnit) ? ' ' : '')
            .concat(ValidateField(formData.F24HmiEngineeringUnit))
            .concat(' - ')
            .concat(ValidateField(formData.F25InstrumentLrv))
            .concat(FieldHasValue(formData.F118InstrumentEngineeringUnits) ? ' ' : '')
            .concat(ValidateField(formData.F118InstrumentEngineeringUnits));
        if (HmiInst1.length > 255) {
            return HmiInst1.slice(0, 255);
        }
        return HmiInst1;
    }
    return '0%';
};

export const AsLeftTableF65Hmi2Inst3Formula = (formData) => {
    let HmiInst3;
    if (FieldHasValue(formData.F22HmiUrv)) {
        HmiInst3 = '100% - '.concat(ValidateField(formData.F22HmiUrv))
            .concat((FieldHasValue(formData.F24HmiEngineeringUnit)) ? ' ' : '')
            .concat(ValidateField(formData.F24HmiEngineeringUnit))
            .concat(' - ')
            .concat(ValidateField(formData.F26InstrumentUrv))
            .concat((FieldHasValue(formData.F118InstrumentEngineeringUnits)) ? ' ' : '')
            .concat(ValidateField(formData.F118InstrumentEngineeringUnits));
        if (HmiInst3.length > 255) {
            return HmiInst3.slice(0, 255);
        }
        return HmiInst3;
    }
    return '100%';
};

export const AsFoundTestTableF78Deviation2Eu1Formula = (formData) => FieldHasValue(formData.F69Tx2Reading1) && FieldHasValue(formData.F66TestModule2Reading1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F69Tx2Reading1) - ValidateNumberField(formData.F66TestModule2Reading1))) : '';
export const AsFoundTestTableF79Deviation2Eu2Formula = (formData) => FieldHasValue(formData.F70Tx2Reading2) && FieldHasValue(formData.F67TestModule2Reading2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F70Tx2Reading2) - ValidateNumberField(formData.F67TestModule2Reading2))) : '';
export const AsFoundTestTableF80Deviation2Eu3Formula = (formData) => FieldHasValue(formData.F71Tx2Reading3) && FieldHasValue(formData.F68TestModule2Reading3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F71Tx2Reading3) - ValidateNumberField(formData.F68TestModule2Reading3))) : '';
