import { valveToLouverDamper, Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../C11/C11.data';
import { labelWidth } from '../P710/P710.data';

export const HMISectionDataP610P76P716P613 = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 2,
            fields: [{
                key: 'F338PhysicalValvePositionPriortotestLabel',
                label: `Physical ${valveToLouverDamper(formName)} Position Prior to Test`,
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F338PhysicalValvePositionPriortotest',
                value: formData.F338PhysicalValvePositionPriortotest,
                isDropdown: true,
                placeholder: Placeholder.SELECT_PHYSICAL_POSITION,
                options: [
                    { name: Placeholder.SELECT_PHYSICAL_POSITION, value: '' },
                    { name: 'Open', value: 'Open' },
                    { name: 'Closed', value: 'Closed' },
                    { name: 'Intermediate', value: 'Intermediate' }],
                isRequired: true,
                width: '100%',
                labelWidth: '0%',
                marginBottom: '15px',
                xs: 2,
                inputTextAlign: 'center'
            },
            {
                key: 'F339HmivalvePositionPriortoTestLabel',
                label: `HMI ${valveToLouverDamper(formName)} Position Prior to Test`,
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F339HmivalvePositionPriortoTest',
                value: formData.F339HmivalvePositionPriortoTest,
                isDropdown: true,
                placeholder: Placeholder.SELECT_HMI_POSITION,
                options: [
                    { name: Placeholder.SELECT_HMI_POSITION, value: '' },
                    { name: 'Open', value: 'Open' },
                    { name: 'Closed', value: 'Closed' },
                    { name: 'Intermediate', value: 'Intermediate' }],
                width: '100%',
                labelWidth: '0%',
                marginBottom: '15px',
                xs: 2,
                inputTextAlign: 'center',
                textAlignLabel: 'end',
            }]
        }
    ]
});
