export const VisualInspectSectionDataP615 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F193VisuallyInspect',
            label: `Visually inspect instrument tagging, instrument wiring connection/terminations, 
                    instrument condition, vent port/line is not blocked, bug screen is in place if required, 
                    general deterioration and no leak sign.`,
            value: formData.F193VisuallyInspect,
            isRequired: true,
            isMultiline: true,
            xs: 12,
            width: '50%',
            inputWidth: '50%',
            alignItems: 'inherit',
            marginTop: '15px',
        }]
    }]
});
