import { FieldHasValue, ValidateNumberField, IsANumber } from 'helpers/Validation';

export const FoundResultAsFoundResultFormula = (formData) => {
    if (FieldHasValue(formData.F320AftRtdSimulatorReading1)
        && FieldHasValue(formData.F321AftRtdSimulatorReading2)
        && FieldHasValue(formData.F322AftRtdSimulatorReading3)
        && FieldHasValue(formData.F209AftTxreading1)
        && FieldHasValue(formData.F210AftTxreading2)
        && FieldHasValue(formData.F211AftTxreading3)
        && FieldHasValue(formData.F106AftHmireading1)
        && FieldHasValue(formData.F107AftHmireading2)
        && FieldHasValue(formData.F108AftHmireading3)
        && FieldHasValue(formData.F26InstrumentUrv) && IsANumber(formData.F26InstrumentUrv) && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F215AftDeviation1), ValidateNumberField(formData.F216AftDeviation2),
        ValidateNumberField(formData.F217AftDeviation3)]) <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const FoundResultF62SensorTrimNeedFormula = (formData) => {
    if (formData.F102AftResult && FieldHasValue(formData.F26InstrumentUrv)) {
        if (ValidateNumberField(formData.F26InstrumentUrv) > ValidateNumberField(formData.F25InstrumentLrv)) {
            if (((Math.max.apply(Math, [ValidateNumberField(formData.F215AftDeviation1), ValidateNumberField(formData.F216AftDeviation2),
            ValidateNumberField(formData.F217AftDeviation3)])) / (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv))) <= 0.02) {
                return 'NO';
            }
            return 'YES';
        }
        return '';
    }
    return '';
};
