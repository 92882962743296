import { dpsInitialState } from './DPS.data';
import { DPSACTIONS } from './DPS.constants';

export default function DPSReducer(state = dpsInitialState, action) {
    switch (action.type) {
        case DPSACTIONS.GET_DPSRECORDS_PENDING:
        case DPSACTIONS.GET_DPSRECORDS_FAILURE:
        case DPSACTIONS.GET_DPSRECORDS_SUCCESS:
        case DPSACTIONS.GET_SEARCHDPSRECORDS_SUCCESS:
        case DPSACTIONS.GET_SEARCHDPSRECORDS_FAILURE:
        case DPSACTIONS.GET_SEARCHDPSRECORDS_PENDING:
        case DPSACTIONS.GET_SORTDPSRECORDS_FAILURE:
        case DPSACTIONS.GET_SORTDPSRECORDS_PENDING:
        case DPSACTIONS.GET_SORTDPSRECORDS_SUCCESS:
        case DPSACTIONS.RESET_DPS_TOTAL_COUNT:
        case DPSACTIONS.ADDDPS_SET_INITIALDATA:
        case DPSACTIONS.ADDDPS_RESET_DATA:
        case DPSACTIONS.EDITDPS_SET_EXISTINGDATA:
        case DPSACTIONS.EDITDPS_RESET_DATA:
        case DPSACTIONS.EDIT_DPSRECORD_PENDING:
        case DPSACTIONS.ADD_DPSRECORD_PENDING:
        case DPSACTIONS.ADD_DPSRECORD_SUCCESS:
        case DPSACTIONS.ADD_DPSRECORD_FAILURE:
        case DPSACTIONS.EDIT_DPSRECORD_SUCCESS:
        case DPSACTIONS.EDIT_DPSRECORD_FAILURE:
        case DPSACTIONS.UPDATE_DPS_UNSAVED_CHANGES_LABEL:
        case DPSACTIONS.DISPLAY_STATUS_SORTORDER:
        case DPSACTIONS.FUNCTIONAL_LOCATION_SORTORDER:
        case DPSACTIONS.GET_DPSRECORDS_TOTALCOUNT_SUCCESS:
        case DPSACTIONS.DPS_LOCKED_FIELDS:
        case DPSACTIONS.GET_DPSFIELD_MAXLENGTH:
        case DPSACTIONS.CORRECTIVE_DPS_MAPPING:
        case DPSACTIONS.DPSRECORD_FLOC_EXIST:
            return { ...state, ...action.payload };
        case DPSACTIONS.ADDDPS_UPDATE_DATA:
            return {
                ...state,
                addDPSData: {
                    ...state.addDPSData,
                    ...action.payload
                }
            };
        case DPSACTIONS.EDITDPS_UPDATE_DATA:
            return {
                ...state,
                editDPSData: {
                    ...state.editDPSData,
                    ...action.payload
                }
            };
        case DPSACTIONS.ADD_DPSRECORD_LOCAL_STORAGE:
            return {
                ...state,
                dpsDataAll: [...state.dpsDataAll, action.payload.addDPSData],
                totalCount: [...state.dpsDataAll, action.payload.addDPSData].length
            };
        case DPSACTIONS.EDIT_DPSRECORD_LOCAL_STORAGE:
            return {
                ...state,
                dpsDataAll: state.dpsDataAll.map(
                        (content, i) => content.RowId === action.payload.editDPSData.RowId ? { ...action.payload.editDPSData }
                                                : content
                    )
            };
        case DPSACTIONS.REMOVE_DPSRECORD_LOCAL_STORAGE:
            return {
                ...state,
                dpsDataAll: state.dpsDataAll.filter(content => content.RowId !== action.payload.RowId)
            };
        default:
            return state;
    }
}
