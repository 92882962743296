export const getAttentionLabel = (formName) => {
    switch (formName) {
        case 'P6-10':
            return `Attention: This TRF is for full stroke testing. If you need to do partial stroke testing
             (P6-13), please switch to the respective TRF.`;
        case 'P6-13':
            return `Attention: This TRF is for partial stroke testing. If you need to do full stroke testing (P6-10),
             please switch to the respective TRF.`;
        case 'P7-6':
            return `Attention: This TRF is for full stroke testing. If you need to do partial stroke testing (P7-16)
             or leak testing (P7-25 or P7-26), please switch to the respective TRF.`;
        case 'P7-16':
            return `Attention: This TRF is for partial stroke testing. If you need to do full stroke testing (P7-6)
             or leak testing (P7-25 or P7-26), please switch to the respective TRF.`;
        case 'P7-25':
        case 'P7-26':
            return `Attention: This TRF is for leak testing. If you need to do full stroke testing (P7-6)
             or partial stroke testing (P7-16), please switch to the respective TRF.`;
        case 'M4-2':
            return 'Please use camera below to capture photo/s of the equipment during inspection.';
        default:
            return '';
    }
};
