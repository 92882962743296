import React from 'react';
import PropTypes from 'prop-types';
import { FormInput, FormInputMultiline, InputViewOnly } from 'elements/Inputs';
import { FormDropdown } from 'elements/Dropdown';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from 'elements/';
import { parseDate } from 'helpers/DateFormatter';
import UserInformationSectionEditTEI from 'journeys/portal/Forms/Sections/UserInformationSection/UserInformationSectionEditTEI';
import UserInformationSectionAddTEI from 'journeys/portal/Forms/Sections/UserInformationSection/UserInformationSectionAddTEI';

export const TestEquipmentForm = ({ formId, formHandler, formValues, isEditTestEquipment, readOnly,
    bpRegionNameAlias, selectedAssetAliasName }) => {
    const calDueDate = formValues?.CalDueDate ? formValues?.CalDueDate : null;
    const calDueDateReadOnly = formValues?.CalDueDate ? parseDate(formValues?.CalDueDate) : null;
    return (
        <form id={formId}>
            {
                isEditTestEquipment
                    ? <UserInformationSectionEditTEI />
                    : <UserInformationSectionAddTEI />
            }
            <Grid container spacing={3} style={{ paddingTop: '20px' }}>
                <Grid container item>
                    <Grid item xs={4}>
                        <FormDropdown
                            formName={formId}
                            id="Region"
                            name="Region"
                            label="Region"
                            isRequired
                            value={formValues.Region}
                            width="86%"
                            handleChange={formHandler}
                            options={[{ name: bpRegionNameAlias.toUpperCase(), value: bpRegionNameAlias.toUpperCase() }]}
                            readOnly={readOnly}
                            noDefaultOption
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormDropdown
                            formName={formId}
                            id="Asset"
                            name="Asset"
                            label="Asset"
                            isRequired
                            value={(formValues?.Asset)}
                            width="86%"
                            handleChange={formHandler}
                            options={[{ name: selectedAssetAliasName.toUpperCase(), value: selectedAssetAliasName.toUpperCase() }]}
                            readOnly={readOnly}
                            noDefaultOption
                        />

                    </Grid>
                    <Grid item xs={4}>
                        {isEditTestEquipment
                            ? (
                                <InputViewOnly
                                    formName={formId}
                                    name="DeviceType"
                                    id="DeviceType"
                                    label="Device Type"
                                    value={formValues.DeviceType}
                                    readOnly={readOnly}
                                />
                            )

                            : (
                                <FormInput
                                    formName={formId}
                                    name="DeviceType"
                                    id="DeviceType"
                                    label="Device Type"
                                    isRequired
                                    value={formValues.DeviceType}
                                    handleChange={formHandler}
                                    maxLength="50"
                                    readOnly={readOnly}
                                />
                            )}
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={4}>
                        <FormInput
                            formName={formId}
                            name="TestEqManufacturer"
                            id="EquipmentManufacturer"
                            label="Equipment Manufacturer"
                            isRequired
                            value={formValues.TestEqManufacturer}
                            handleChange={formHandler}
                            maxLength="50"
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormInput
                            formName={formId}
                            name="TestEqModel"
                            id="EquipmentModel"
                            label="Equipment Model"
                            isRequired
                            value={formValues.TestEqModel}
                            handleChange={formHandler}
                            maxLength="50"
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormInput
                            formName={formId}
                            name="TestEqSn"
                            id="EquipmentSerialNumber"
                            label="Equipment Serial Number"
                            isRequired
                            value={formValues.TestEqSn}
                            handleChange={formHandler}
                            maxLength="50"
                            readOnly={readOnly}
                        />
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={4}>
                        {!readOnly
                            ? (
                                <DatePicker
                                    formName={formId}
                                    id="CalDueDateOnline"
                                    name="CalDueDateOnline"
                                    label="Cal.Due Date"
                                    value={calDueDate}
                                    onChange={(event) => formHandler({ CalDueDate: event })}
                                />
                            )
                            : (
                                <FormInput
                                    formName={formId}
                                    name="CalDueDateOffline"
                                    id="CalDueDateOffline"
                                    label="Cal.Due Date"
                                    value={calDueDateReadOnly}
                                    onChange={(event) => formHandler({ CalDueDate: event })}
                                    isRequired
                                    readOnly={readOnly}
                                />
                            )}
                    </Grid>
                    <Grid item xs={4}>
                        {isEditTestEquipment
                            ? (
                                <FormDropdown
                                    name="Status"
                                    id="Status"
                                    label="Status"
                                    value={formValues.Status}
                                    handleChange={formHandler}
                                    options={[{ name: 'Active', value: 'Active' }, { name: 'Inactive', value: 'Inactive' }]}
                                    width="86%"
                                    noDefaultOption
                                    readOnly={readOnly}
                                    formName={formId}
                                />
                            )
                            : (
                                <InputViewOnly
                                    formName={formId}
                                    name="Status"
                                    id="Status"
                                    label="Status"
                                    handleChange={formHandler}
                                    value={formValues.Status}
                                />
                            )}
                    </Grid>
                </Grid>

                <Grid container item>
                    <Grid item xs={12}>
                        <FormInputMultiline
                            formName={formId}
                            rows={3}
                            name="Notes"
                            id="Notes"
                            label="Notes"
                            value={formValues.Notes ?? ''}
                            handleChange={formHandler}
                            readOnly={readOnly}
                        />
                    </Grid>
                </Grid>

            </Grid>
        </form>
    );
};
TestEquipmentForm.propTypes = {
    bpRegionNameAlias: PropTypes.string,
    formHandler: PropTypes.func,
    formId: PropTypes.string,
    formValues: PropTypes.object.isRequired,
    isEditTestEquipment: PropTypes.bool,
    readOnly: PropTypes.bool,
    selectedAssetAliasName: PropTypes.string
};
