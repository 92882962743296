import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';
import { failedComponentsOptions } from '../P716/P716.data';
import { FailedComponent } from '../../../../Sections.constants';

export const ValveSizeSectionDataP727 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F343PhysicalValvePositionAfterTest',
                    label: 'Physical Valve Position After Test',
                    value: formData.F343PhysicalValvePositionAfterTest,
                    isDropDown: true,
                    placeholder: 'Select Physical Position',
                    options: [
                        { name: 'Select Local Position', value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                    xs: 5,
                    isRequired: true,
                    width: '41.3%',
                    labelWidth: '51%',
                    marginTop: '20px',
                    inputTextAlign: 'center'
                },
                {
                    key: 'F347HmivalvePositionAfterTest',
                    label: 'HMI Valve Position After Test',
                    xs: 3,
                    fontWeight: '600',
                    isLabel: true,
                    padding: '23px 0 0 0',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt'
                },
                {
                    key: 'F347HmivalvePositionAfterTest',
                    value: formData.F347HmivalvePositionAfterTest,
                    isDropDown: true,
                    placeholder: 'Select HMI Position',
                    options: [
                        { name: 'Select HMI Position', value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                    xs: 4,
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '20px'
                },
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'Nodata6',
                    label: '',
                    xs: 6,
                    isLabel: true
                },
                {
                    key: 'F85TestResultLabel',
                    label: 'Test Result',
                    xs: 2,
                    isLabel: true,
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '5px 0 0 0'
                },
                {
                    key: 'F85TestResult',
                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    xs: 4,
                    labelWidth: '0',
                    width: '100%',
                    borderTop: '0',
                    borderBottom: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification') ? '0' : ''
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'Nodata7',
                    label: '',
                    xs: 6,
                    isLabel: true
                },
                {
                    key: 'FailedComponents',
                    label: 'Failed Components',
                    xs: 2,
                    fontWeight: '600',
                    isLabel: true,
                    padding: '5px 0 0 0',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                },
                {
                    key: 'F348FailedComponents',
                    label: '',
                    value: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification') ? formData.F348FailedComponents : '',
                    placeholder: FailedComponent.SEARCH_FAILED_COMPONENTS,
                    isRequired: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification'),
                    isDisabled: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    readOnly: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    isFailedDropDown: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification'),
                    options: failedComponentsOptions,
                    xs: 2,
                    fontSize: '8.4pt',
                    labelWidth: '0',
                    width: '100%',
                    borderTop: '0',
                    paddingLeft: '1px'
                },
                {
                    key: 'F349FailedComponents1',
                    value: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification') ? formData.F349FailedComponents1 : '',
                    placeholder: FailedComponent.SEARCH_FAILED_COMPONENTS,
                    isDisabled: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    readOnly: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    isFailedDropDown: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification'),
                    options: failedComponentsOptions,
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    fontSize: '8.4pt',
                    borderTop: '0',
                    borderLeft: '0',
                    paddingLeft: '1px'
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'Nodata7',
                    label: '',
                    xs: 8,
                    isLabel: true
                },
                {
                key: 'remark',
                label: 'Remark: Record manufacturer and model of failed components on the TRF.',
                isLabel: true,
                xs: 4,
                fontWeight: '600',
                labelWidth: '100%',
                fontSize: '8.5pt',
                color: '#ea7c34'
            }]
        },
    ]

});
