export const VisualInspectSectionDataP61andP612 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect instrument tagging, instrument mounting/manifold conditions, the state of instrument wiring 
                connection/terminations, tubing/fitting integrity, the general instrument condition, for general signs deterioration 
                as well as for signs of process fluid weeping/leaking`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]
});
