import { AsFoundTestTableDeviationEu1Formula } from './P67.formulas';
import { FoundResultAsFoundResultFormula } from '../../../FoundResultSection/Data/P67/P67.formulas';

export const AsFoundTestTableSectionDataP67P841P8101 = (selectedTag, formName, formData) => {
    const switchSetpointCommentCondition = formData?.F798SwitchSetpointComment ?? selectedTag?.SwitchSetpointComment;
    let aftIndicationLabel;
    if (formName === 'P6-18') {
        aftIndicationLabel = 'Switch Output';
    } else {
        aftIndicationLabel = 'HMI Indication';
    }
    return {
        rows: [
            {
                key: 1,
                fields: [
                    {
                        key: 'ASFoundTest',
                        label: 'As-Found Test',
                        isLabel: true,
                        xs: 4,
                        fontWeight: '700',
                        labelWidth: '100%',
                        padding: '7px 0 5px 6px',
                        fontSize: '9pt'
                    },
                    {
                        key: 'Nodata',
                        isLabel: true,
                        xs: 1
                    },
                    {
                        key: 'F181AftTestEquipmentIndicationLabel',
                        label: 'Test Equipment Reading',
                        isLabel: true,
                        xs: 2,
                        fontWeight: '600',
                        textAlign: 'center',
                        labelWidth: '100%',
                        padding: '7px 0 5px 3px',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F182AftHmireadingLabel',
                        label: aftIndicationLabel,
                        isLabel: true,
                        xs: 3,
                        fontWeight: '600',
                        labelWidth: '100%',
                        textAlign: 'center',
                        padding: '7px 0 5px 0',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F183AftDeviationLabel',
                        label: 'Deviation (EU)',
                        isLabel: true,
                        xs: 2,
                        fontWeight: '600',
                        labelWidth: '100%',
                        textAlign: 'center',
                        padding: '7px 0 5px 0px',
                        fontSize: '9pt'
                    }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F300SwitchSetpoint',
                        label: 'Switch Setpoint',
                        value: selectedTag.DateTested ? formData?.F300SwitchSetpoint : formData?.F300SwitchSetpoint ?? selectedTag?.SwitchSetpoint,
                        isRequired: true,
                        xs: 4,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '52.7%',
                        width: '30%',
                        height: '1.13rem',
                        marginLeft: '6px',
                        textAlign: 'center',
                    },
                    {
                        key: 'Nodata',
                        isLabel: true,
                        xs: 1
                    },
                    {
                        key: 'F181AftTestEquipmentIndication',
                        value: formData.F181AftTestEquipmentIndication,
                        isRequired: true,
                        type: 'number',
                        xs: 2,
                        width: '100%',
                        maxLength: '10',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.13rem',
                        borderRight: '0',
                    },
                    {
                        key: 'F182AftHmireading',
                        value: formData.F182AftHmireading,
                        isRequired: true,
                        isDropdown: true,
                        placeholder: 'Select ALARM or NORMAL',
                        options: [{ name: 'Select ALARM or NORMAL', value: '' },
                        { name: 'ALARM', value: 'ALARM' },
                        { name: 'NORMAL', value: 'NORMAL' }],
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F183AftDeviation',
                        value: AsFoundTestTableDeviationEu1Formula(formData),
                        xs: 2,
                        labelWidth: '0',
                        width: '96.2%',
                        textAlign: 'center',
                        height: '1.13rem',
                        borderLeft: '0',
                        borderRight: '0',
                        readOnly: true,
                        inputMarginRight: '4px'
                    }]
            },
            switchSetpointCommentCondition ? {
                key: 3,
                fields: [
                    {
                        key: 'F798SwitchSetpointCommentLabel',
                        label: 'Switch Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        fontStyle: 'italic',
                        padding: '5px 0 0 6px',
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F798SwitchSetpointComment',
                        value: selectedTag?.DateTested ? formData?.F798SwitchSetpointComment : switchSetpointCommentCondition,
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.2rem',
                        padding: '5px 0 0 19px',
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 3,
                fields: []
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F101AftTolerance',
                        label: 'Tolerance (EU)',
                        value: selectedTag.DateTested ? formData?.F101AftTolerance : formData?.F101AftTolerance ?? selectedTag?.ToleranceInInstrumentEu,
                        isRequired: true,
                        xs: 4,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '52.7%',
                        width: '30%',
                        height: '1.17rem',
                        marginLeft: '6px',
                        textAlign: 'center',
                        borderBottom: '0',
                        borderTop: (switchSetpointCommentCondition) ? '' : 0,
                        marginBottom: !(switchSetpointCommentCondition) ? '4px' : 0,
                    },
                    {
                        key: 'Nodata',
                        isLabel: true,
                        xs: 3
                    },
                    {
                        key: 'F102AftResult',
                        label: 'As-Found Result',
                        value: FoundResultAsFoundResultFormula(formData),
                        readOnly: true,
                        isCalculatedNew: true,
                        xs: 5,
                        labelWidth: '58.3%',
                        fontWeight: '700',
                        direction: 'rtl',
                        height: '1.17rem',
                        width: '38.4%',
                        marginRight: '0.5rem',
                        inputMarginRight: '4px',
                        borderBottom: '0',
                        borderRight: '0',
                        textAlign: 'center',
                        marginBottom: '4px',
                        borderTop: (switchSetpointCommentCondition) ? '' : 0,
                    }
                ]
            }
        ]
    };
};
