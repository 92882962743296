import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NetworkIcon from '../../../icons/Online';
import { updateApplicationOnlineStatus, setLastOnlineTime } from '../common.actions';

export const formatDate = (date) => date ? new Intl.DateTimeFormat('en-GB', {
    month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: 'numeric', second: 'numeric', hourCycle: 'h12'
}).format(date).replace(' ', '-').replace(' ', '-')
    .replace(',', '')
    .replace('am', 'AM')
    .replace('pm', 'PM')
    .replace('Sept', 'Sep') : '';

const OnlineOfflineIndicator = (props) => {
    const { updateStatusGlobally, isMRATOnline, lastOnlineTime, setLastOnlineTimeGlobally } = props;
    const onlineOfflineCallback = (e) => {
        if (e.type === 'offline') {
            updateStatusGlobally(false);
            setLastOnlineTimeGlobally(`Data Last Downloaded ${formatDate(new Date()).replaceAll('-', '/')}`);
        } else {
            updateStatusGlobally(true);
        }
    };
    useEffect(() => {
        window.addEventListener('online', onlineOfflineCallback);
        window.addEventListener('offline', onlineOfflineCallback);
        return () => {
            window.removeEventListener('online', onlineOfflineCallback);
            window.removeEventListener('offline', onlineOfflineCallback);
        };
    });

    return (
        <span role="button" tabIndex={0}>
            <span id="lastOnlineTime">
                {isMRATOnline ? '' : lastOnlineTime}
            </span>
            &emsp;
            <NetworkIcon style={{ stroke: isMRATOnline ? '#498205' : 'red', marginRight: '2px' }} />
            {' '}
            Version -
            {' '}
            {process.env.REACT_APP_APP_VERSION}
        </span>
    );
};

const mapStateToProps = ({ AppData }) => ({
    isMRATOnline: AppData.isMRATOnline,
    lastOnlineTime: AppData.lastOnlineTime
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    setLastOnlineTimeGlobally: (lastOnlineTime) => dispatch(setLastOnlineTime(lastOnlineTime)),
    updateStatusGlobally: (currentStatus) => dispatch(updateApplicationOnlineStatus(currentStatus))
});
export default connect(mapStateToProps, mapDispatchToProps)(OnlineOfflineIndicator);

OnlineOfflineIndicator.propTypes = {
    isMRATOnline: PropTypes.bool,
    lastOnlineTime: PropTypes.string,
    setLastOnlineTimeGlobally: PropTypes.func,
    updateStatusGlobally: PropTypes.func.isRequired
};
