export const VisualInspectSectionDataM616 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F119VisualInspection',
                label: `Visually inspect audible (speaker, horn) and/or visual (strobe, beacon) notification appliances for general condition,
                 security of mounting, mechanical damage, signs of corrosion,
                 field cable/gland condition, correct tagging. Lamps should be free from any obstruction and clearly visible when activated.`,
                value: formData.F119VisualInspection,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }]

});
