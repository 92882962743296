import { paddingValAFWS } from '../AsLeftTestWorkshop/AsLeftTestWorkshop.data';
import { labelWidth } from '../HMISection/Data/P710/P710.data';

export const paddingLabels = '4px 0 0 8px';
export const paddingValue = '4px 0 0 32px';
export const maxLength = '20';
export const marginLeft = '16px';
export const areaClassification = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AreaClassification',
                label: 'Area Classification',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: paddingValAFWS
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F999AreaClassLabel',
                    label: 'Class',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabels
                },
                {
                    key: 'F999AreaClass',
                    value: selectedTag.DateTested ? formData?.F999AreaClass : formData?.F999AreaClass ?? selectedTag?.Class,
                    xs: 4,
                    maxLength,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    height: '1.2rem',
                    marginLeft
                },
                {
                    key: 'F1002AreaZoneLabel',
                    label: 'Zone',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    padding: paddingValue
                },
                {
                    key: 'F1002AreaZone',
                    value: selectedTag.DateTested ? formData?.F1002AreaZone : formData?.F1002AreaZone ?? selectedTag?.Zone,
                    xs: 4,
                    maxLength,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    borderBottom: '0',
                    height: '1.2rem',
                    marginLeft: '13px'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F1000AreaDivisionLabel',
                    label: 'Division',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabels
                },
                {
                    key: 'F1000AreaDivision',
                    value: selectedTag.DateTested ? formData?.F1000AreaDivision : formData?.F1000AreaDivision ?? selectedTag?.Division,
                    xs: 4,
                    maxLength,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    height: '1.2rem',
                    marginLeft
                },
                {
                    key: 'F1003AreaEplLabel',
                    label: 'EPL',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingValue
                },
                {
                    key: 'F1003AreaEpl',
                    value: selectedTag.DateTested ? formData?.F1003AreaEpl : formData?.F1003AreaEpl ?? selectedTag?.EquipmentProtectionLevel,
                    xs: 4,
                    maxLength,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderRight: '0',
                    borderBottom: '0',
                    inputMarginRight: '4px',
                    height: '1.2rem',
                    marginLeft: '13px'
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F1001AreaGasGroupLabel',
                    label: 'Gas Group',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabels
                },
                {
                    key: 'F1001AreaGasGroup',
                    value: selectedTag.DateTested ? formData?.F1001AreaGasGroup : formData?.F1001AreaGasGroup ?? selectedTag?.GasGroup,
                    xs: 4,
                    maxLength,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    marginBottom: '4px',
                    height: '1.2rem',
                    marginLeft
                },
                {
                    key: 'F1004AreaTempClassLabel',
                    label: 'Temp Class',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingValue
                },
                {
                    key: 'F1004AreaTempClass',
                    value: selectedTag.DateTested ? formData?.F1004AreaTempClass : formData?.F1004AreaTempClass ?? selectedTag?.TemperatureClass,
                    xs: 4,
                    maxLength,
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    borderRight: '0',
                    borderBottom: '0',
                    marginBottom: '4px',
                    inputMarginRight: '4px',
                    height: '1.2rem',
                    marginLeft: '13px'
                }
            ]
        }
    ]
});

export const selectAreaClassificationData = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'M4-2') {
        filterViewData = areaClassification(selectedTag, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
