import { SafetyFunctionDescriptionData, safetyFunctionDescriptionDataP6232, commonConstants } from 'journeys/portal/Forms/Sections.constants';

export const SafetyFunctionDescription = (formData, key) => {
    if (formData[key] === commonConstants.No) {
        return `Safety function does not require ${SafetyFunctionDescriptionData[key]}`;
    }
    if (formData[key] === commonConstants.Yes) {
        return `Safety function requires ${SafetyFunctionDescriptionData[key]}`;
    }
    return '';
};

export const SafetyFunctionDescriptionP623 = (formData, key) => {
    if (formData[key] === commonConstants.No) {
        return `Safety function does not require ${safetyFunctionDescriptionDataP6232[key]}`;
    }
    if (formData[key] === commonConstants.Yes) {
        return `Safety function requires ${safetyFunctionDescriptionDataP6232[key]}`;
    }
    return '';
};
