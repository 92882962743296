import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { MESSAGE_TYPE } from '../../../../common/common.constants';
import { APIEndpoints } from '../../../../../helpers/APILists';
import { trackErrors } from '../helper';
import { useMsal } from '@azure/msal-react';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { formatDate } from 'helpers/DateFormatter';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import DropdownInput from '../../elements/DropdownInput';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import { strEquals } from '../../AdminScreen.helper';

const DpsFieldForm = ({ isOpen, title, onClose, rowData, onRecordSubmit }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [cancelAlert, setCancelAlert] = useState(false);
    const [dpsFieldList, setDPSFieldList] = useState([]);
    const [nameError, setNameError] = useState('');
    const [dpsDisplayError, setDpsDisplayError] = useState('');
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const submitButtonVisible = !_.isEqual(rowData, createRowData) && nameError === '' && dpsDisplayError === ''
        && createRowData.DpsfieldColumnName && createRowData.DpsfieldDisplayName
        && createRowData.DpsfieldDatatype && createRowData.DpsfieldMaxlength && createRowData.Status;
    const [dpsFieldDropdownData, setDpsFieldDropdownData] = useState({
        dpsfieldDatatype: []
    });

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetDpsFields)
            .then((res) => {
                setDPSFieldList(res.data.map(x => ({ DpsfieldId: x.DpsfieldId, DpsfieldColumnName: x.DpsfieldColumnName, DpsfieldDisplayName: x.DpsfieldDisplayName })));
                const maxDpsFieldId = _.maxBy(res.data, 'DpsfieldId')?.DpsfieldId || 0;
                setDpsFieldDropdownData(prev => ({
                    ...prev,
                    dpsfieldDatatype: _.compact(_.uniq(res.data.map(x => x.DpsfieldDatatype))).sort(),
                    DpsfieldId: maxDpsFieldId + 1,
                }));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    useEffect(() => {
        if (!_.isEqual(rowData, createRowData) && createRowData?.DpsfieldDatatype === 'float') {
            setCreateRowData(prev => ({
                ...prev,
                DpsfieldMaxlength: 10
            }));
        } else {
            !_.isEqual(rowData, createRowData) && setCreateRowData(prev => ({
                ...prev,
                DpsfieldMaxlength: ''
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createRowData.DpsfieldDatatype]);

    const handleInputChange = ((e) => {
        if (e.target.name === 'DpsfieldMaxlength' && (e.target.value < 1 || e.target.value > 255) && e.target.value !== '') {
            return;
        }
        setCreateRowData((prev) => (
            {
                ...prev,
                [e.target.name]: e.target.value
            }
        ));
    });

    const handleBlur = () => {
        setNameError('');
        if (dpsFieldList.find(x => strEquals(x.DpsfieldColumnName, createRowData.DpsfieldColumnName) && x.DpsfieldId !== createRowData.DpsfieldId)) {
            setNameError(' *(Name already exists)');
        }
    };

    const handleBlurDisplayName = () => {
        setDpsDisplayError('');
        if (dpsFieldList.find(x => strEquals(x.DpsfieldDisplayName, createRowData.DpsfieldDisplayName) && x.DpsfieldId !== createRowData.DpsfieldId)) {
            setDpsDisplayError(' *(Name already exists)');
        }
    };

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const resetButtonHandler = () => {
        setNameError('');
        setDpsDisplayError('');
        setCreateRowData(rowData);
    };

    const submitButtonHandler = () => {
        createRowData.Status = createRowData.Status === 'Active';
        createRowData.ModifiedBy = userName?.name;
        createRowData.LastModifiedDate = formatDate(new Date());
        if (createRowData.DpsfieldId) {
            updateRecord(createRowData);
        } else {
            createRowData.DpsfieldId = dpsFieldDropdownData.DpsfieldId;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            submitRecord(createRowData);
        }
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_AddDpsfield, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.DpsfieldId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateDpsfield, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.DpsfieldId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };
    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="DpsfieldColumnName"
                        title="DPS Field Column Name"
                        value={createRowData.DpsfieldColumnName}
                        errorMsg={nameError}
                        required
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlur}
                        type="string"
                        maxLength="40"
                    />
                    <TextInput
                        name="DpsfieldDisplayName"
                        title="DPS Field Display Name"
                        value={createRowData.DpsfieldDisplayName}
                        required
                        handleInputChange={handleInputChange}
                        errorMsg={dpsDisplayError}
                        handleBlur={handleBlurDisplayName}
                        type="string"
                        maxLength="40"
                    />
                    <DropdownInput
                        name="DpsfieldDatatype"
                        title="DPS Field Datatype"
                        value={createRowData.DpsfieldDatatype}
                        required
                        handleInputChange={handleInputChange}
                        options={dpsFieldDropdownData.dpsfieldDatatype}
                    />
                    <TextInput
                        name="DpsfieldMaxlength"
                        title="DPS Field Max Length"
                        value={createRowData.DpsfieldMaxlength}
                        required
                        handleInputChange={handleInputChange}
                        type="number"
                    />
                    <DropdownInput
                        name="Status"
                        title="Status"
                        value={createRowData.Status}
                        options={[{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }]}
                        handleInputChange={handleInputChange}
                        required
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData?.DpsfieldId ? createRowData.CreatedBy : userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData?.DpsfieldId ? formatDate(new Date(createRowData.CreatedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData?.DpsfieldId ? formatDate(new Date(createRowData.LastModifiedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '12px' }}>
                    <TextInput
                        name="Comment"
                        title="Comments"
                        value={createRowData.Comment}
                        handleInputChange={handleInputChange}
                        isMultiline
                        width="1080px"
                    />
                </div>
            </DialogContent>
            <DialogActions classes={{ root: classes.buttonContainer }}>
                {submitButtonVisible
                    && (
                        <Button
                            classes={{ root: classes.button }}
                            onClick={submitButtonHandler}
                            variant="outlined"
                        >
                            Submit
                        </Button>
                    )}
                <Button
                    classes={{ root: classes.button }}
                    onClick={resetButtonHandler}
                    variant="outlined"
                >
                    Reset
                </Button>
                <Button
                    classes={{ root: classes.button }}
                    onClick={cancelButtonHandler}
                    variant="outlined"
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DpsFieldForm;

DpsFieldForm.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string
};
