import { FieldHasValue } from 'helpers/Validation';

export const AsFoundResultFormulaP732 = (formData) => {
    if (FieldHasValue(formData.F591AftForcedTripSignal) && FieldHasValue(formData.F592AftConfirmRelayOperatesCorrectly)) {
        if (formData.F591AftForcedTripSignal === 'YES' && formData.F592AftConfirmRelayOperatesCorrectly === 'YES') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
