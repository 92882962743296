import { signalToTriggerIsErrorCheck } from '../../SignalToTrigger/SignalToTrigger.data';
import { paddingValTPFR } from '../../ZeroFlowConditions/Data/P6022/P6022.data';

export const FlowChangeCheckSectionP6022 = (formData, formName) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'FlowChangeCheckLabel',
                label: 'Flow Change Check',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                labelWidth: '100%',
                padding: '5px 0px 0px 4px',
                marginTop: '0',
            }]
        },
        {
            key: 1,
            fields: [{
                key: 'remark',
                label: `Remark: Increase or decrease flow rate by 5-10% if operational conditions permit.
                 Observe transmitter's response and compare with an independent flow reading if possible.`,
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '99%',
                padding: '5px 0px 0px 4px',
                fontSize: '8.5pt',
                color: '#ea7c34',
                marginTop: '0'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'nodata',
                    xs: 2,
                    isLabel: true
                },
                {
                    key: 'F813TransmitterTxreading1Label',
                    label: 'TX Reading (EU)',
                    isLabel: true,
                    fontWeight: '700',
                    textAlign: 'center',
                    labelWidth: '100%',
                    padding: paddingValTPFR,
                    xs: 2,
                    fontSize: '9pt',
                    marginTop: '1rem'
                },
                {
                    key: 'F302HmireadingLabel',
                    label: 'HMI Reading (HMI EU)',
                    isLabel: true,
                    fontWeight: '700',
                    textAlign: 'center',
                    labelWidth: '100%',
                    padding: paddingValTPFR,
                    xs: 2,
                    fontSize: '9pt',
                    marginTop: '1rem'
                },
                {
                    key: 'F469IndependentFlowTxReadingLabel',
                    label: 'Independent Flow Tx Reading (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    padding: paddingValTPFR,
                    fontSize: '9pt'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'nodata',
                    xs: 2,
                    isLabel: true
                },
                {
                    key: 'F813TransmitterTxreading1',
                    value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F813TransmitterTxreading1 : '',
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    marginBottom: '0',
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                },
                {
                    key: 'F302Hmireading',
                    value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F302Hmireading : '',
                    xs: 2,
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    marginBottom: '0'
                },
                {
                    key: 'F469IndependentFlowTxReading',
                    value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F469IndependentFlowTxReading : '',
                    isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    marginBottom: '0'
                }
            ]
        }
    ]
});
