import { commonConstants, Result, Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';

export const FailureModeSectionDataS72 = (formData) => {
    const asFoundResult = formData.F102AftResult === Result.RESULT_PASS;
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'F619AsLeftTestRequiredLabel',
                label: 'As-Left Test Required?',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
                marginTop: '15px'
            },
            {
                key: 'F619AsLeftTestRequired',
                value: asFoundResult ? '' : formData.F619AsLeftTestRequired,
                isDisabled: asFoundResult,
                isDropdown: !(asFoundResult),
                readOnly: asFoundResult,
                placeholder: Placeholder.SELECT_YES_OR_NO,
                options: [{ name: Placeholder.SELECT_YES_OR_NO, value: '' },
                    { name: commonConstants.YES, value: commonConstants.YES },
                    { name: commonConstants.NO, value: commonConstants.NO }],
                xs: 2,
                inputTextAlign: 'center',
                textAlign: 'center',
                labelWidth: '0%',
                width: '100%',
                marginTop: '15px'
            }]
        }]
    };
};
