import { AsLeftTableM613Formula } from './M613.formulas';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const AsLeftTestTableSectionDataM613 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'ASLEFTTEST',
                label: 'As-Left Test',
                isLabel: true,
                fontWeight: '700',
                width: '28%',
                padding: '5px 0 0 8px',
                xs: 12,
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'attention',
                label: `Attention: The following simple corrections may be performed by technician as part of this PM work order — 
                repeat stroking damper against stiction, cleaning vent lines and ports, flow regulator adjustments, limit switch adjustments.`,
                width: '0',
                marginLeft: '8px',
                borderBottom: '0',
                borderLeft: '0',
                borderTop: '0',
                borderRight: '0',
                labelWidth: '100%',
                inputMarginRight: '6px',
                fontSize: '8.5pt',
                color: '#ea7c34'
            },
            ]
        },
        {
            key: 3,
            fields: [{
                label: `The following complicated corrections should be performed by technician as part of follow-up corrective work order —
                component replacements (e.g. solenoid), damper and actuator overhaul, lubricating and greasing damper or actuator.`,
                width: '0',
                marginLeft: '8px',
                labelWidth: '100%',
                borderBottom: '0',
                borderLeft: '0',
                borderTop: '0',
                borderRight: '0',
                fontSize: '8.5pt',
                inputMarginRight: '6px',
                color: '#ea7c34'
            }]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F366DamperFullStrokePositionIndicationAltLabel',
                    label: 'Damper Full Stroke Position Indication',
                    xs: 4,
                    labelWidth: '100%',
                    padding: '15px 0 0 8px',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F366DamperFullStrokePositionIndicationAlt',
                    value: formData.F61AsFoundResult !== 'PASS' ? formData.F366DamperFullStrokePositionIndicationAlt : '',
                    isRequired: formData.F61AsFoundResult !== 'PASS',
                    isDisabled: formData.F61AsFoundResult === 'PASS',
                    readOnly: formData.F61AsFoundResult === 'PASS',
                    isDropdown: formData.F61AsFoundResult !== 'PASS',
                    placeholder: 'SELECT DAMPER POSITION',
                    options: [{ name: 'SELECT DAMPER POSITION', value: '' },
                    { name: 'OPEN', value: 'OPEN' },
                    { name: 'CLOSE', value: 'CLOSE' },
                    { name: 'INTERMEDIATE', value: 'INTERMEDIATE' }],
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: formData.F366DamperFullStrokePositionIndicationAlt === 'OPEN' || formData.F366DamperFullStrokePositionIndicationAlt === 'INTERMEDIATE' ? '' : '0',
                    marginTop: '15px',
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                }]
        },
        {
            key: 5,
            fields: [
                {
                    key: 'F367DamperFullStrokeTravelTimeAltLabel',
                    label: 'Damper Full Stroke Travel Time (sec)',
                    xs: 4,
                    labelWidth: '100%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F367DamperFullStrokeTravelTimeAlt',
                    value: !(formData.F61AsFoundResult === 'PASS' || formData.F366DamperFullStrokePositionIndicationAlt === 'OPEN'
                        || formData.F366DamperFullStrokePositionIndicationAlt === 'INTERMEDIATE')
                        ? formData.F367DamperFullStrokeTravelTimeAlt : '',
                    isRequired: !(formData.F61AsFoundResult === 'PASS' || formData.F366DamperFullStrokePositionIndicationAlt === 'OPEN'
                        || formData.F366DamperFullStrokePositionIndicationAlt === 'INTERMEDIATE'),
                    isDisabled: (formData.F61AsFoundResult === 'PASS' || formData.F366DamperFullStrokePositionIndicationAlt === 'OPEN'
                        || formData.F366DamperFullStrokePositionIndicationAlt === 'INTERMEDIATE'),
                    readOnly: (formData.F61AsFoundResult === 'PASS' || formData.F366DamperFullStrokePositionIndicationAlt === 'OPEN'
                        || formData.F366DamperFullStrokePositionIndicationAlt === 'INTERMEDIATE'),
                    xs: 2,
                    type: 'number',
                    borderBottom: '0',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    marginBottom: '4px'
                },
                {
                    key: 'F81AsLeftResultLabel',
                    label: 'As-Left Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F81AsLeftResult',
                    value: formData.F61AsFoundResult !== 'PASS' ? AsLeftTableM613Formula(formData) : '',
                    isDisabled: formData.F61AsFoundResult === 'PASS',
                    isCalculatedNew: true,
                    xs: 2,
                    fontWeight: '700',
                    readOnly: true,
                    borderRight: '0',
                    borderBottom: '0',
                    inputMarginRight: '4px',
                    marginBottom: '4px',
                    width: '100%',
                    labelWidth: '0',
                    direction: 'rtl',
                    textAlign: 'center',
                }]
        }]
});
