import { strEquals } from '../../../Admin/AdminScreen.helper';

const checkAsfoundResult = (formData) => strEquals(formData.F102AftResult, 'FAIL') || strEquals(formData.F61AsFoundResult, 'FAIL') || strEquals(formData.F334SraPassFail, 'FAIL');
const dropdownOptions = [{ name: 'Select Test Result', value: '' }, { name: 'Pass', value: 'Pass' },
{ name: 'Pass with corrective notification', value: 'PassWithCorrectiveNotification' }, { name: 'Fail', value: 'Fail' },
{ name: 'Fail with corrective notification', value: 'FailWithCorrectiveNotification' }, { name: 'Unknown', value: 'Unknown' }];
const dropdownFailOptions = [{ name: 'Select Test Result', value: '' }, { name: 'Fail', value: 'Fail' },
{ name: 'Fail with corrective notification', value: 'FailWithCorrectiveNotification' }, { name: 'Unknown', value: 'Unknown' }];

export const TestResultDropdownOptions = (formData) => {
    if (checkAsfoundResult(formData)) {
        return dropdownFailOptions;
    }
    return dropdownOptions;
};

export const TestAsFoundResultValue = (formData) => {
    const valueArr = dropdownFailOptions.map(opt => opt.value);
    return checkAsfoundResult(formData) && !valueArr.includes(formData.F85TestResult);
};
