import styled from 'styled-components';

export const SearchBoxWrapper = styled.div`
.MuiOutlinedInput-root {
    border-radius: 0;
    margin-left:  ${props => props.marginLeft ? props.marginLeft : '0'};
    height: ${props => props.height ? props.height : '40px'};
    width:  ${props => props.width ? props.width : 'inherit'};
    margin: ${props => props.margin ? props.margin : ''};
}
.MuiOutlinedInput-notchedOutline {
    border-color:  ${props => props.borderColor ? props.borderColor : '#A6A6A6'};
    border-width: 2px;
}
.MuiOutlinedInput-root:hover fieldset {
    border-color: #498205;
}
.MuiOutlinedInput-root:focus fieldset {
    border-color: #498205;
}
.MuiOutlinedInput-root:focus-within fieldset {
    border-color: #498205;
}
.MuiOutlinedInput-input {
    padding: ${props => props.padding ? props.padding : '0px 12px'};
}
.MuiInputBase-input {
    height: 1.1876em;
    font-size: ${props => props.fontSize ? props.fontSize : 'inherit'};
}
`;
