import { FieldHasValue } from 'helpers/Validation';
import { AutomaticActivationFireSuppression } from 'journeys/portal/Forms/Sections.constants';

export const AsLeftResultFormulaM71 = (formData) => {
    if (formData.F696AltTst1AutomaticActivationFireSuppression === AutomaticActivationFireSuppression.DISCARD
        && FieldHasValue(formData.F709AltTst2Result)) {
        if (formData.F709AltTst2Result === 'PASS') {
            return 'PASS';
        }
        return 'FAIL';
    }

    if (formData.F697AltTst2ManualActivationFireSuppression === AutomaticActivationFireSuppression.DISCARD
        && FieldHasValue(formData.F708AltTst1Result)) {
        if (formData.F708AltTst1Result === 'PASS') {
            return 'PASS';
        }
        return 'FAIL';
    }

    if (FieldHasValue(formData.F708AltTst1Result) && FieldHasValue(formData.F709AltTst2Result)) {
        if (formData.F708AltTst1Result === 'PASS' && formData.F709AltTst2Result === 'PASS') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
