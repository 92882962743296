import {
    AsFoundTestTableHmiInst1Formula,
    AsFoundTestTableHmiInst2Formula,
    AsFoundTestTableHmiInst3Formula,
    AsFoundTestTableDeviationEu1Formula,
    AsFoundTestTableDeviationEu2Formula,
    AsFoundTestTableDeviationEu3Formula
} from './P77.formulas';
import { paddingAF, selectConditionsLabel } from '../../../AsLeftTestTableSection/Data/P77/P77.data';

export const AsFoundTestTableSectionDataP77 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'HMI-Inst',
                label: '% - HMI - Inst.',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                padding: '15px 0 5px 6px',
                fontSize: '9pt'
            },
            {
                key: 'LevelConditions',
                label: 'Level Conditions',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Independent Reading',
                label: 'Independent/Simulator Reading (%)',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'TXReading',
                label: 'TX Reading (%)',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                textAlign: 'center',
                labelWidth: '100%',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'HMIReading',
                label: 'HMI Reading (%)',
                isLabel: true,
                xs: 1,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'mAReading',
                label: 'mA Reading',
                isLabel: true,
                xs: 1,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Deviation',
                label: 'Deviation (%)',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F203AftHmiInst1',
                value: AsFoundTestTableHmiInst1Formula(formData).length > 255
                    ? AsFoundTestTableHmiInst1Formula(formData).slice(0, 255) : AsFoundTestTableHmiInst1Formula(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F789AftLevelConditions1',
                xs: 2,
                value: formData.F789AftLevelConditions1,
                isDropdown: true,
                isRequired: true,
                borderBottom: '0',
                borderRight: '0',
                placeholder: selectConditionsLabel,
                options: [
                    { name: selectConditionsLabel, value: '' },
                    { name: 'Actual', value: 'Actual' },
                    { name: 'Simulated', value: 'Simulated' }],
                inputTextAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.46rem'
            },
            {
                key: 'F206AftIndependentReading1',
                value: formData.F206AftIndependentReading1,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F209AftTxreading1',
                value: formData.F209AftTxreading1,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F106AftHmireading1',
                value: formData.F106AftHmireading1,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 1,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F212AftMAreading1',
                value: formData.F212AftMAreading1,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 1,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
            },
            {
                key: 'F215AftDeviation1',
                value: AsFoundTestTableDeviationEu1Formula(formData),
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F204AftHmiInst2',
                value: AsFoundTestTableHmiInst2Formula(formData).length > 255
                    ? AsFoundTestTableHmiInst2Formula(formData).slice(0, 255) : AsFoundTestTableHmiInst2Formula(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F790AftLevelConditions2',
                xs: 2,
                value: formData.F790AftLevelConditions2,
                isDropdown: true,
                isRequired: true,
                borderBottom: '0',
                borderRight: '0',
                placeholder: selectConditionsLabel,
                options: [
                    { name: selectConditionsLabel, value: '' },
                    { name: 'Actual', value: 'Actual' },
                    { name: 'Simulated', value: 'Simulated' }],
                inputTextAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.44rem'
            },
            {
                key: 'F207AftIndependentReading2',
                value: formData.F207AftIndependentReading2,
                isRequired: true,
                maxLength: '10',
                xs: 2,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F210AftTxreading2',
                value: formData.F210AftTxreading2,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F107AftHmireading2',
                value: formData.F107AftHmireading2,
                isRequired: true,
                xs: 1,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F213AftMAreading2',
                value: formData.F213AftMAreading2,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 1,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
            },
            {
                key: 'F216AftDeviation2',
                value: AsFoundTestTableDeviationEu2Formula(formData),
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F205AftHmiInst3',
                value: AsFoundTestTableHmiInst3Formula(formData).length > 255
                    ? AsFoundTestTableHmiInst3Formula(formData).slice(0, 255) : AsFoundTestTableHmiInst3Formula(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                marginLeft: '4px'
            },
            {
                key: 'F791AftLevelConditions3',
                xs: 2,
                value: formData.F791AftLevelConditions3,
                isDropdown: true,
                isRequired: true,
                borderRight: '0',
                placeholder: selectConditionsLabel,
                options: [
                    { name: selectConditionsLabel, value: '' },
                    { name: 'Actual', value: 'Actual' },
                    { name: 'Simulated', value: 'Simulated' }],
                inputTextAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.46rem'
            },
            {
                key: 'F208AftIndependentReading3',
                value: formData.F208AftIndependentReading3,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F211AftTxreading3',
                value: formData.F211AftTxreading3,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F108AftHmireading3',
                value: formData.F108AftHmireading3,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 1,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F214AftMAreading3',
                value: formData.F214AftMAreading3,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 1,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F217AftDeviation3',
                value: AsFoundTestTableDeviationEu3Formula(formData),
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
            }]
        }
    ]
});
