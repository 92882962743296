import { makeStyles } from '@material-ui/core/styles';

export const headerIconButtonStyles = makeStyles((theme) => ({
    iconContainer: {
        '&:hover $icon': {
            color: '#335B03',
            borderColor: '#335B03'
        },
        padding: 0
    },
    icon: {
        color: 'white',
        transform: props => props.rotate && 'rotate('.concat(props.rotate, 'deg)')
    },
    tooltip: {
        fontSize: '0.8rem',
        backgroundColor: theme.palette.common.white,
        border: '1px solid black',
        color: 'rgba(0, 0, 0, 0.87)',
        margin: 0
    }
})
);
