import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormInput } from 'elements/Inputs/FormInput';
import { StyledLabel } from 'elements/Label/Label';
import { FormDropdown } from 'elements/Inputs/FormInput/Dropdown/Dropdown';
import { selectFireSuppressionData } from './FireSuppressionSection.data';
import { StyleFailureNewDropdown } from '../FailureModeSection/FailureModeSection.styled';
import { InputAutogrow } from 'elements/Inputs/FormInput/InputAutogrow/InputAutogrow';

const FailureModeSection = (props) => {
    const { selectedTag, changeHandler, formData, formName, isFormReadOnly, userRole } = props;
    const formInput = (field) => field.isAutoGrow
        ? (
            <InputAutogrow
                id={field.key}
                name={field.key}
                value={field.value}
                maxLength={field.maxLength}
                handleChange={changeHandler}
                inputWidth={field.inputWidth}
                width={field.labelWidth}
                label={field.label}
                marginBottom={field.marginBottom}
                marginLeft={field.marginLeft}
                direction={field.direction}
                marginRight={field.marginRight}
                padding={field.padding}
                isCalculatedNew={field.isCalculatedNew}
                inputMarginTop={field.inputMarginTop}
                readOnly={isFormReadOnly}
                isDisabled={field.readOnly}
                isRequired={field.isRequired}
                textAlign={field.textAlign}
                marginTop={field.marginTop}
                borderBottom={field.borderBottom}
                borderTop={field.borderTop}
                borderRight={field.borderRight}
                inputTextAlign={field.inputTextAlign}
                borderLeft={field.borderLeft}
            />
        ) : (
            <FormInput
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                isRequired={field.isRequired}
                isCalculated={field.isCalculated}
                isCalculatedNew={field.isCalculatedNew}
                handleChange={changeHandler}
                readOnly={field.readOnly || isFormReadOnly}
                labelWidth={field.labelWidth}
                width={field.width}
                marginLeft={field.marginLeft}
                marginRight={field.marginRight}
                direction={field.direction}
                padding={field.padding}
                type={field.type}
                marginBottom={field.marginBotom}
                maxLength={field.maxLength}
                borderRight={field.borderRight}
                borderTop={field.borderTop}
                borderBottom={field.borderBottom}
                textAlign={field.textAlign}
                userRole={userRole}
                isDisabled={field.isDisabled}
                height={field.height}
                hasDPSError={field.hasDPSError}
                marginTop={field.marginTop}
                borderLeft={field.borderLeft}
                fontWeight={field.fontWeight}
                isLabelNew={field.isLabelNew}
            />
        );
    const dropdownInput = (field) => field.isDropdown ? (
        <StyleFailureNewDropdown
            formName={formName}
        >
            <FormDropdown
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                placeholder={field.placeholder}
                handleChange={changeHandler}
                options={field.options}
                isRequired={field.isRequired}
                labelWidth={field.labelWidth}
                width={field.width}
                marginLeft={field.marginLeft}
                marginRight={field.marginRight}
                readOnly={isFormReadOnly || field.readOnly}
                direction={field.direction}
                textAlign={field.textAlign}
                borderTop={field.borderTop}
                borderRight={field.borderRight}
                inputTextAlign={field.inputTextAlign}
                marginTop={field.marginTop}
                marginBottom={field.marginBottom}
                borderBottom={field.borderBottom}
            />
        </StyleFailureNewDropdown>
    )
        : formInput(field);
    return (
        <div>
            {
                selectFireSuppressionData(selectedTag, formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isLabel
                                    ? (
                                        <StyledLabel
                                            id={field.key}
                                            fontWeight={field.fontWeight}
                                            textAlign={field.textAlign}
                                            labelWidth={field.labelWidth}
                                            padding={field.padding}
                                            color={field.color}
                                            fontSize={field.fontSize}
                                            fontStyle={field.fontStyle}
                                        >
                                            {field.label}
                                        </StyledLabel>
                                    )
                                    : dropdownInput(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </div>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FailureModeSection);

FailureModeSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
