import { AsLeftResultFormula } from './M69.formula';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';

export const AsLeftTestTableSectionDataM69 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AsleftTest',
                label: 'As-Left Test (in field repair attempt)',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F403AltJumperWireAppliedandShortedLabel',
                    label: 'Confirm that jumper wire applied and shorted across the end of line resistor.',
                    xs: 4,
                    labelWidth: '98%',
                    padding: '5px 0 0 8px',
                    isLabel: true,
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F403AltJumperWireAppliedandShorted',
                    value: formData.F102AftResult !== 'PASS' ? formData.F403AltJumperWireAppliedandShorted : '',
                    isRequired: formData.F102AftResult !== 'PASS',
                    isDisabled: formData.F102AftResult === 'PASS',
                    isDropdown: formData.F102AftResult !== 'PASS',
                    readOnly: formData.F102AftResult === 'PASS',
                    placeholder: 'Select YES or NO',
                    options: [{ name: 'Select YES or NO', value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    labelWidth: '0',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    height: '2.5rem',
                    paddingPlaceholder: '10px',
                    arrowMarginTop: '10px'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F378AltHmistatus1Label',
                    label: 'HMI Status',
                    xs: 4,
                    labelWidth: '98%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F378AltHmistatus1',
                    isDropdown: formData.F102AftResult !== 'PASS',
                    value: formData.F102AftResult !== 'PASS' ? formData.F378AltHmistatus1 : '',
                    isRequired: formData.F102AftResult !== 'PASS',
                    isDisabled: formData.F102AftResult === 'PASS',
                    placeholder: 'Select HMI Status',
                    options: [{ name: 'Select HMI Status', value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'LOOP FAULT', value: 'LOOP FAULT' }],
                    labelWidth: '0',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    marginBottom: '0',
                    readOnly: formData.F102AftResult === 'PASS',
                    height: formData.F102AftResult !== 'PASS' ? '1.55rem' : '1.3rem',
                    width: '100%'
                },
                {
                    key: 'F103AltResultLabel',
                    label: 'As-Left Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F103AltResult',
                    value: formData.F102AftResult !== 'PASS' ? AsLeftResultFormula(formData) : '',
                    isDisabled: formData.F102AftResult === 'PASS',
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0',
                    isCalculatedNew: true,
                    xs: 2,
                    textAlign: 'center',
                    height: '1.3rem',
                    borderRight: '0',
                    borderBottom: '0',
                    direction: 'rtl',
                    marginBottom: '4px',
                    inputMarginRight: '4px',
                    isLabelBold: true,
                    fontWeight: '700',
                }
            ]
        }]
});
