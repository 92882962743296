import { typeFlameScanner } from './Data/P625/P625.data';
import { asFoundPstMthod } from './Data/S71/S71.data';
import { actualOrSimulatedLevel } from './Data/S74/S74.data';

export const asFoundPstMethodSectionData = (formName, formData) => {
    let filterViewData = { rows: [] };
    if (['S7-1'].includes(formName) && formData.F956TrfTypeSelection === 'Partial') {
        filterViewData = asFoundPstMthod(formData);
    } else if (['S7-4'].includes(formName)) {
        filterViewData = actualOrSimulatedLevel(formData);
    } else if (['P6-25'].includes(formName)) {
        filterViewData = typeFlameScanner(formData);
    }
    return filterViewData;
};
