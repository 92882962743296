import { FieldHasValue, ValidateNumberField, ValidateField, ExponentialToDecimal, IsANumber } from 'helpers/Validation';

export const HmiInst1FormulaP63 = (formData) => {
    const Hmi2Inst1 = '0% - '.concat(ValidateField(formData.F25InstrumentLrv))
        .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
    return Hmi2Inst1.length > 255 ? Hmi2Inst1.slice(0, 255) : Hmi2Inst1;
};

export const HmiInst2FormulaP63 = (formData) => {
    const Hmi2Inst2 = '50% - '.concat(ExponentialToDecimal((ValidateNumberField(formData.F25InstrumentLrv)
        + (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) * 0.5)))
        .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
    return Hmi2Inst2.length > 255 ? Hmi2Inst2.slice(0, 255) : Hmi2Inst2;
};

export const HmiInst3FormulaP63 = (formData) => {
    const Hmi2Inst3 = '100% - '.concat(ValidateField(formData.F26InstrumentUrv))
        .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
    return Hmi2Inst3.length > 255 ? Hmi2Inst3.slice(0, 255) : Hmi2Inst3;
};

export const AsLeftTestTableDeviationEu1Formula = (formData) => FieldHasValue(formData.F231AltTxreading1) && FieldHasValue(formData.F323AltRtdSimulatorReading1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F231AltTxreading1) - ValidateNumberField(formData.F323AltRtdSimulatorReading1))) : '';
export const AsLeftTestTableDeviationEu2Formula = (formData) => FieldHasValue(formData.F232AltTxreading2) && FieldHasValue(formData.F324AltRtdSimulatorReading2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F232AltTxreading2) - ValidateNumberField(formData.F324AltRtdSimulatorReading2))) : '';
export const AsLeftTestTableDeviationEu3Formula = (formData) => FieldHasValue(formData.F233AltTxreading3) && FieldHasValue(formData.F325AltRtdSimulatorReading3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F233AltTxreading3) - ValidateNumberField(formData.F325AltRtdSimulatorReading3))) : '';

export const F103AltResultFormula = (formData) => {
    if (FieldHasValue(formData.F323AltRtdSimulatorReading1)
        && FieldHasValue(formData.F324AltRtdSimulatorReading2)
        && FieldHasValue(formData.F325AltRtdSimulatorReading3)
        && FieldHasValue(formData.F231AltTxreading1)
        && FieldHasValue(formData.F232AltTxreading2)
        && FieldHasValue(formData.F233AltTxreading3)
        && FieldHasValue(formData.F109AltHmireading1)
        && FieldHasValue(formData.F113AltHmireading2)
        && FieldHasValue(formData.F114AltHmireading3)
        && FieldHasValue(formData.F26InstrumentUrv)
        && IsANumber(formData.F26InstrumentUrv)
        && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F237AltDeviation1),
        ValidateNumberField(formData.F238AltDeviation2), ValidateNumberField(formData.F239AltDeviation3)])
            <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
