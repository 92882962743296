import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { SearchFieldWrapper } from './SearchField.styled';

const SearchField = ({ handleSearch, width, disabled, maxLength, name, readOnly }) => {
    const [inputValue, setInputValue] = useState('');
    const variant = !disabled ? 'outlined' : 'filled';

    const handleChange = (e) => {
        setInputValue(e.target.value);
        handleSearch(e.target.value, name);
    };

    return (
        <SearchFieldWrapper width={width}>
            <TextField
                key={name}
                autoComplete="off"
                name={name}
                type="search"
                placeholder={disabled || readOnly ? '' : 'Search'}
                disabled={disabled}
                variant={variant}
                value={inputValue}
                inputProps={{ maxLength, readOnly }}
                onChange={handleChange}
            />
        </SearchFieldWrapper>
    );
};

export default SearchField;

SearchField.propTypes = {
    disabled: PropTypes.bool,
    handleSearch: PropTypes.func.isRequired,
    maxLength: PropTypes.string,
    name: PropTypes.string,
    readOnly: PropTypes.bool,
    width: PropTypes.string
};
