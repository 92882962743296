export const FoundThresholdSectionData = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F256AftAsFoundThresholdValue100Label',
                label: 'As-Found Threshold Value at 100% (V)',
                xs: 4,
                isLabel: true,
                marginTop: '10px',
                fontWeight: '600',
                fontSize: '9pt',
                padding: '0 0 0 3px',
            },
            {
                key: 'F256AftAsFoundThresholdValue100',
                value: formData.F256AftAsFoundThresholdValue100,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                textAlign: 'center',
                marginTop: '10px'
            },
            {
                key: 'F257AftAsLeftThresholdValue100',
                label: 'As-Left Threshold Value at 100% (V)',
                value: formData.F257AftAsLeftThresholdValue100,
                type: 'number',
                maxLength: '10',
                xs: 6,
                width: '36.5%',
                labelWidth: '75%',
                marginRight: '0.5rem',
                height: '1.2rem',
                textAlign: 'center',
                direction: 'rtl',
                marginTop: '10px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F258AftAsFoundThresholdValue0Label',
                label: 'As-Found Threshold Value at 0% (V)',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '0 0 0 3px',
            },
            {
                key: 'F258AftAsFoundThresholdValue0',
                value: formData.F258AftAsFoundThresholdValue0,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                textAlign: 'center',
                borderTop: '0'
            },
            {
                key: 'F259AftAsLeftThresholdValue0',
                label: 'As-Left Threshold Value at 0% (V)',
                value: formData.F259AftAsLeftThresholdValue0,
                type: 'number',
                maxLength: '10',
                width: '36.5%',
                labelWidth: '75%',
                direction: 'rtl',
                xs: 6,
                borderTop: '0',
                textAlign: 'center',
                height: '1.2rem',
                marginRight: '0.5rem'
            }
            ]
        }
    ]
});
export const selectFoundThresholdForForm = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'P7-8' || formName === 'P6-17') {
        filterViewData = FoundThresholdSectionData(selectedTag, formName, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
