import { FieldHasValue } from 'helpers/Validation';

export const AsLeftResultFormulaP611 = (formData) => {
    if (FieldHasValue(formData.F443AltForcedOutputsignal) && FieldHasValue(formData.F444AltConfirmContactorOperates)) {
        if (formData.F443AltForcedOutputsignal === 'YES' && formData.F444AltConfirmContactorOperates === 'YES') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
