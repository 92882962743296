import { dropdownPlaceholder } from '../AFT_FireTestSection/Data/M71/M71.data';

export const dropdownListPlaceHolder = 'Select from dropdown list';
export const notRequiredVal = 'NOT REQUIRED';
export const DetectorLenseSection = (selectedTag, distinctTestMakeModelData, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F376CleanDetectorLensesIfVisiblyDirty',
                    label: 'Clean detector lenses if visibly dirty',
                    value: formData.F376CleanDetectorLensesIfVisiblyDirty,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: dropdownListPlaceHolder,
                    options: [{ name: dropdownListPlaceHolder, value: '' },
                    { name: 'CLEANED', value: 'CLEANED' },
                    { name: notRequiredVal, value: notRequiredVal }],
                    labelWidth: '51.2%',
                    width: '48.8%',
                    marginLeft: '0.6rem',
                    inputTextAlign: 'center',
                    xs: 5,
                    marginTop: '1rem'
                },
                {
                    key: 'DetectorLense_NOTE',
                    label: 'If detector lenses cleaned or alignment performed, repeat the test',
                    isLabel: true,
                    xs: 7,
                    color: '#ea7c34',
                    fontSize: '8.5pt',
                    fontStyle: 'italic',
                    fontWeight: '600',
                    padding: '20px 25px 0 8px'
                }
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F377AlignmentRequired',
                    label: 'Alignment required',
                    value: formData.F377AlignmentRequired,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: dropdownPlaceholder,
                    options: [{ name: dropdownPlaceholder, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    labelWidth: '51.2%',
                    width: '48.8%',
                    marginLeft: '0.6rem',
                    borderTop: '0',
                    inputTextAlign: 'center',
                    xs: 5
                }
            ]
        }
    ]
});

export const DetectorLenseSectionM610 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F376CleanDetectorLensesIfVisiblyDirty',
                    label: 'Clean detector if visibly dirty',
                    value: formData.F376CleanDetectorLensesIfVisiblyDirty,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: dropdownListPlaceHolder,
                    options: [{ name: dropdownListPlaceHolder, value: '' },
                    { name: 'CLEANED', value: 'CLEANED' },
                    { name: notRequiredVal, value: notRequiredVal }],
                    labelWidth: '100%',
                    width: '100%',
                    marginLeft: '4px',
                    inputTextAlign: 'center',
                    xs: 4,
                    marginTop: '1rem'
                },
            ]
        },
    ]
});

export const DetectorreinstatementM68 = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'DetectorReinstatement',
                label: 'Detector reinstatement',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '15px 0 5px 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F392ConfirmThatExternalHeatHasBeenRemovedLabel',
                    label: 'Confirm that external heat has been removed from detector (in field or workshop)',
                    xs: 4,
                    labelWidth: '98%',
                    padding: '0 0 0 8px',
                    fontWeight: '600',
                    fontSize: '9pt',
                    isLabel: true
                },
                {
                    key: 'F392ConfirmThatExternalHeatHasBeenRemoved',
                    value: formData.F392ConfirmThatExternalHeatHasBeenRemoved,
                    isRequired: true,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    isDropdown: true,
                    height: '2.24rem',
                    inputTextAlign: 'center',
                    placeholder: dropdownPlaceholder,
                    options: [{ name: dropdownPlaceholder, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    paddingPlaceholder: '6px',
                    arrowMarginTop: '6px'
                },
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F393HmistatusLabel',
                    label: 'HMI Status (field test)',
                    xs: 4,
                    labelWidth: '98%',
                    padding: '5px 0 5px 8px',
                    isLabel: true,
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F393Hmistatus',
                    value: (formData.F383IsThereHazardousAreaRestriction !== 'YES') ? formData.F393Hmistatus : '',
                    isRequired: (formData.F383IsThereHazardousAreaRestriction !== 'YES'),
                    isDisabled: (formData.F383IsThereHazardousAreaRestriction === 'YES'),
                    readOnly: (formData.F383IsThereHazardousAreaRestriction === 'YES'),
                    xs: 2,
                    borderTop: '0',
                    width: '100%',
                    labelWidth: '0',
                    isDropdown: (formData.F383IsThereHazardousAreaRestriction !== 'YES'),
                    height: '1.5rem',
                    inputTextAlign: 'center',
                    placeholder: 'Select HMI Status',
                    options: [{ name: 'Select HMI Status', value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'LOOP FAULT', value: 'LOOP FAULT' }],
                    marginBottom: '4px',
                    borderBottom: '0',
                },
            ]
        }
    ]
});

export const SelectDetectorLenseSection = (selectedTag, formName, formData) => {
    let filterViewData = { rows: [] };
    if (formName === 'M6-7') {
        filterViewData = DetectorLenseSection(selectedTag, formName, formData);
    } else if (formName === 'M6-10') {
        filterViewData = DetectorLenseSectionM610(formData);
    } else if (formName === 'M6-8') {
        filterViewData = DetectorreinstatementM68(selectedTag, formName, formData);
    }
    return filterViewData;
};
