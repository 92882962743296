export const menuItems = [
    {
        name: 'TRF Master List',
        url: 'trfMasterList',
        dbName: 'dbo.TRF_MASTER_LIST'
    },
    {
        name: 'DPS Mapping',
        url: 'dpsMapping',
        dbName: 'dbo.DPSMappingDetails'
    },
    {
        name: 'Security Groups',
        url: 'mratSecurityGroups',
        dbName: 'dbo.MRAT_SECURITY_GROUPS'
    },
    {
        name: 'Group Mapping',
        url: 'mratGroupMapping',
        dbName: 'dbo.MRAT_GROUP_MAPPING'
    },
    {
        name: 'Input Field Type LKUP',
        url: 'inputFieldTypes',
        dbName: 'dbo.InputFieldType'
    },
    {
        name: 'Roles LKUP',
        url: 'roles',
        dbName: 'dbo.ROLES'
    },
    {
        name: 'User Input Type LKUP',
        url: 'userInputType',
        dbName: 'dbo.UserInputType'
    },
    {
        name: 'AD Group Type LKUP',
        url: 'adGroupType',
        dbName: 'dbo.adGroupType'
    },
    {
        name: 'BP Region LKUP',
        url: 'bpRegion',
        dbName: 'dbo.BPREGION'
    },
    {
        name: 'DPS Field LKUP',
        url: 'dpsfield',
        dbName: 'dbo.Dpsfield'
    },
    {
        name: 'DPS Data Source LKUP',
        url: 'dpsDataSources',
        dbName: 'dbo.DPSDataSource'
    },
    {
        name: 'DPS Source Mapping',
        url: 'dpsSourceMapping',
        dbName: 'dbo.DPSSourceMapping'
    },
    {
        name: 'Corrective DPS Mapping',
        url: 'correctiveDPSMapping',
        dbName: 'dbo.CorrectiveDPSMapping'
    },
    {
        name: 'Information',
        url: 'information',
        dbName: 'dbo.Information'
    },
    {
        name: 'Multi TRF Details',
        url: 'multiTrfDetails',
        dbName: 'dbo.MultiTrfDetail'
    },
    {
        name: 'DPS Upload Logs',
        url: 'dpsUpload',
        dbName: 'dbo.DpsUploadLogs'
    },
    {
        name: 'ADF Pipeline Parameter',
        url: 'adfPipelineParameter',
        dbName: 'dbo.AdfPipelineParameter'
    },
    {
        name: 'Role Login Exclusion',
        url: 'prodMultiroleLoginExclusion',
        dbName: 'dbo.Prod_Multirole_Login_Exclusion'
    }
];
