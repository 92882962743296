import { createTableDefinitionObject } from '../SubSections/DPS/DPS.helpers';
import { isMobile as isTablet } from 'react-device-detect';

const width = isTablet ? {
    moNumber: '10%',
    moDescription: '31%',
    floc: '19%',
    startDate: '15%',
    finishDate: '15%'
} : {
    moNumber: '12%',
    moDescription: '28%',
    floc: '19%',
    startDate: '15%',
    finishDate: '16%'
};

export const TYPE = {
    NAME: 'NAME'
};

export const tableDefinition = [
    createTableDefinitionObject('HdWorkOrder', 'MO Number', width.moNumber),
    createTableDefinitionObject('HdWorkOrderDescription', 'MO Description', width.moDescription),
    createTableDefinitionObject('HdLocation', 'Functional Location', width.floc),
    createTableDefinitionObject('ScheduleStartDate', 'Schedule Start Date', width.startDate),
    createTableDefinitionObject('ScheduleFinishDate', 'Schedule Finish Date', width.finishDate)
];
export const updatedTabledefination = tableDefinition.map((row) => {
    if (row.columnTitle === 'Schedule Finish Date') {
        return {
            ...row,
            displaySort: true
        };
    }
    return row;
}
);

export const MOACTIONS = {
    GET_MORECORDS_PENDING: 'GET_MORECORDS_PENDING',
    GET_MORECORDS_SUCCESS: 'GET_MORECORDS_SUCCESS',
    GET_MORECORDS_FAILURE: 'GET_MORECORDS_FAILURE',
    SET_MORECORD_ROW: 'SET_MORECORD_ROW',
    GET_SEARCHMORECORDS_PENDING: 'GET_SEARCHMORECORDS_PENDING',
    GET_SEARCHMORECORDS_SUCCESS: 'GET_SEARCHMORECORDS_SUCCESS',
    GET_SEARCHMORECORDS_FAILURE: 'GET_SEARCHMORECORDS_FAILURE',
    GET_SORTMORECORDS_PENDING: 'GET_SORTMORECORDS_PENDING',
    GET_SORTMORECORDS_SUCCESS: 'GET_SORTMORECORDS_SUCCESS',
    GET_SORTMORECORDS_FAILURE: 'GET_SORTMORECORDS_FAILURE',
    SET_DATE_FOR_MO_SCREEN_SUCCESS: 'SET_DATE_FOR_MO_SCREEN_SUCCESS',
    SET_DATE_FOR_MO_SCREEN_FAILURE: 'SET_DATE_FOR_MO_SCREEN_FAILURE',
    SET_SELECTED_DATE: 'SET_SELECTED_DATE',
    FILTER_MO_DATA_ON_DATE_SELECTION: 'FILTER_MO_DATA_ON_DATE_SELECTION',
    RESET_DATE_FOR_MO_SCREEN_SUCCESS: 'RESET_DATE_FOR_MO_SCREEN_SUCCESS'
};
