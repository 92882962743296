import { placeholderName } from '../../../HMISection/Data/P733/P733.data';
import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';

export const TestResultSectionDataM71 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F728DischargeValvePositionAt',
            label: 'Discharge Valve Position After Test',
            value: formData.F728DischargeValvePositionAt,
            isRequired: true,
            isDropDown: true,
            placeholder: placeholderName,
            options: [{ name: placeholderName, value: '' },
            { name: 'Open', value: 'Open' },
            { name: 'Closed', value: 'Closed' }],
            direction: 'rtl',
            xs: 6,
            labelWidth: '60.5%',
            width: '39.35%',
            inputTextAlign: 'center',
        },
        {
            key: 'F85TestResult',
            label: 'Test Result',
            xs: 6,
            value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
            isRequired: true,
            isDropDown: true,
            placeholder: 'Select Test Result',
            options: TestResultDropdownOptions(formData),
            labelWidth: '39%',
            marginRight: '0.5rem',
            width: '61%'
        }]
    }]
});
