import { AsFoundResultFormulaP611 } from '../P611/P611.formulas';
import { paddingValue } from '../P725/P725.data';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';

export const AsFoundTestTableSectionDataP733P620 = (selectedTag, formData) => ({
        rows: [{
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'NoData1',
                isLabel: true,
                fontWeight: '700',
                xs: 6,
                padding: paddingValue,
                fontSize: '9pt'
            },
            {
                key: 'Heater_Package',
                label: 'Heater Package with Temperature Trip Amplifier',
                isLabel: true,
                xs: 6,
                textAlign: 'center',
                fontSize: '9pt',
                fontWeight: '700',
                padding: '0 0 8px 8px',
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F440AftForcedOutputsignal',
                label: 'Forced Trip Signal',
                value: formData.F440AftForcedOutputsignal,
                xs: 6,
                isRequired: true,
                labelWidth: '51%',
                width: '49%',
                isDropdown: true,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                inputTextAlign: 'center',
                borderBottom: '0',
            },
            {
                key: 'F649AftTemperatureTripSetpoint',
                label: 'Temperature Trip Setpoint',
                value: formData.F649AftTemperatureTripSetpoint,
                xs: 6,
                type: 'number',
                maxLength: '10',
                labelWidth: '40%',
                width: '60%',
                marginRight: '0.3rem',
                textAlign: 'center',
                direction: 'rtl',
                borderRight: '0',
                inputMarginRight: '4px',
                borderBottom: '0',
                height: '1.24rem'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F441AftConfirmContactorOperates',
                label: 'Confirm Contactor Operates Correctly',
                value: formData.F441AftConfirmContactorOperates,
                isRequired: true,
                isDropdown: true,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                labelWidth: '51%',
                width: '49%',
                marginLeft: '8px',
                inputTextAlign: 'center',
                xs: 6,
                borderBottom: '0'
            },
            {
                key: 'F650AsFoundTripPoint',
                label: 'As-Found Trip Point',
                value: formData.F650AsFoundTripPoint,
                type: 'number',
                maxLength: '10',
                xs: 6,
                labelWidth: '40%',
                width: '60%',
                marginRight: '0.3rem',
                textAlign: 'center',
                direction: 'rtl',
                borderRight: '0',
                inputMarginRight: '4px',
                height: '1.12rem'
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F578AftConfirmLampsSwitchCorrectly',
                label: 'Confirm Lamps Switch Correctly',
                value: formData.F578AftConfirmLampsSwitchCorrectly,
                isRequired: true,
                isDropdown: true,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                labelWidth: '51%',
                width: '49%',
                marginLeft: '8px',
                inputTextAlign: 'center',
                xs: 6,
                borderBottom: '0'
            }]
        },
        {
            key: 6,
            fields: [{
                key: 'F442AftConfirmcorrectHmiindication',
                label: 'Confirm Correct HMI Indication ',
                value: formData.F442AftConfirmcorrectHmiindication,
                xs: 6,
                marginLeft: '8px',
                isRequired: true,
                isDropdown: true,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                inputTextAlign: 'center',
                labelWidth: '51%',
                width: '49%',
                borderBottom: '0',
                marginBottom: '4px'
            },
            {
                key: 'F102AftResult',
                label: 'As-Found Result',
                value: AsFoundResultFormulaP611(formData),
                readOnly: true,
                xs: 6,
                labelWidth: '39.4%',
                width: '60%',
                isCalculatedNew: true,
                textAlign: 'center',
                height: '1.25rem',
                borderRight: '0',
                marginRight: '8px',
                direction: 'rtl',
                inputMarginRight: '4px',
                isLabelBold: true,
                fontWeight: '700',
                borderBottom: '0',
            }]
        }]
    });
