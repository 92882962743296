import { FoundResultAsFoundResultFormulaM68 } from './M68.formulas';
import { paddingValAFWS } from '../AsLeftTestWorkshop/AsLeftTestWorkshop.data';
import { labelWidth } from '../HMISection/Data/P710/P710.data';

export const AsFoundTestWorkshopData = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'ASFOUNDTEST2',
                label: 'As-Found Test - Workshop',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: paddingValAFWS
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F385AftConfirmThatExternalHeatAppliedWorkshopLabel',
                    label: 'Confirm that external heat has been applied to the detector in the workshop (any heat source such as hot air gun or magnet)',
                    xs: 4,
                    labelWidth: '98%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F385AftConfirmThatExternalHeatAppliedWorkshop',
                    value: (formData.F383IsThereHazardousAreaRestriction !== 'NO') ? formData.F385AftConfirmThatExternalHeatAppliedWorkshop : '',
                    isRequired: (formData.F383IsThereHazardousAreaRestriction !== 'NO'),
                    isDisabled: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    readOnly: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    isDropdown: (formData.F383IsThereHazardousAreaRestriction !== 'NO'),
                    height: '3.24rem',
                    inputTextAlign: 'center',
                    placeholder: 'Select YES or NO',
                    options: [{ name: 'Select YES or NO', value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    paddingPlaceholder: '13px',
                    arrowMarginTop: '13px'
                },
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F386AftMeasureDetectorMaVOutputWithMultimeterLabel',
                    label: 'Measure detector mA/V output with multimeter (m/A)',
                    xs: 4,
                    labelWidth: '98%',
                    padding: paddingValAFWS,
                    isLabel: true,
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F386AftMeasureDetectorMaVOutputWithMultimeter',
                    value: (formData.F383IsThereHazardousAreaRestriction !== 'NO') ? formData.F386AftMeasureDetectorMaVOutputWithMultimeter : '',
                    isRequired: (formData.F383IsThereHazardousAreaRestriction !== 'NO'),
                    isDisabled: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    readOnly: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    xs: 2,
                    borderBottom: '0',
                    borderTop: '0',
                    width: '100%',
                    maxLength: '10',
                    type: 'number',
                    labelWidth: '0',
                    height: '1.65rem',
                    textAlign: 'center',
                },
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F387AftRecordConfirmedFireMaVOutputLevelLabel',
                    label: 'Record "confirmed fire" mA/V output level from detector datasheet or manual (m/A)',
                    xs: 4,
                    labelWidth: '98%',
                    padding: paddingValAFWS,
                    isLabel: true,
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F387AftRecordConfirmedFireMaVOutputLevel',
                    value: (formData.F383IsThereHazardousAreaRestriction !== 'NO') ? formData.F387AftRecordConfirmedFireMaVOutputLevel : '',
                    isRequired: (formData.F383IsThereHazardousAreaRestriction !== 'NO'),
                    isDisabled: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    readOnly: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    xs: 2,
                    borderBottom: '0',
                    width: '100%',
                    maxLength: '10',
                    type: 'number',
                    labelWidth: '0',
                    height: '2.24rem',
                    textAlign: 'center',
                    marginBottom: '4px'
                },
                {
                    key: 'F396AftResult2Label',
                    label: 'As-Found Workshop Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '25px 5px 0 0',
                },
                {
                    key: 'F396AftResult2',
                    value: (formData.F383IsThereHazardousAreaRestriction !== 'NO') ? FoundResultAsFoundResultFormulaM68(formData) : '',
                    isDisabled: (formData.F383IsThereHazardousAreaRestriction === 'NO'),
                    isCalculatedNew: true,
                    xs: 2,
                    readOnly: true,
                    fontWeight: '700',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    textAlign: 'center',
                    width: '100%',
                    height: '1.16rem',
                    marginTop: '1.08rem',
                    labelWidth: '0',
                    direction: 'rtl'
                },
            ]
        }
    ]
});

export const selectAsFoundTestWorkshopSectionData = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'M6-8') {
        filterViewData = AsFoundTestWorkshopData(selectedTag, formName, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
