export const FailureModeSectionDataP77P65P652P831 = (formData) => {
    const asFoundResult = formData.F102AftResult === 'PASS' && formData.F218AftIsRecalibrationNeeded === 'NO';
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'F619AsLeftTestRequired',
                label: 'As-Left Test Required?',
                value: !(asFoundResult) ? formData.F619AsLeftTestRequired : '',
                isDisabled: asFoundResult,
                isRequired: false,
                isDropdown: !(asFoundResult),
                readOnly: asFoundResult,
                placeholder: 'Select YES or NO',
                options: [{ name: 'Select YES or NO', value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                inputTextAlign: 'center',
                labelWidth: '40%',
                width: '40%',
                xs: 5,
                height: asFoundResult ? '1.26rem' : '1.55rem',
                marginTop: '15px',
                textAlign: 'left',
            }]
        }]
    };
};
