import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { MRAT_USER_ROLE } from 'journeys/portal/Home/Home.constants';
import { InputWrapper, StyledMultilineLabel, TextInputWrapper } from './InputAutogrow.styled';

export const InputAutogrow = (props) => {
    const { isDisabled, id, label, name, value, handleChange, width, marginLeft, padding,
        marginRight, marginTop, inputMarginTop, direction, inputWidth, alignItems, maxLength,
        marginBottom, isCalculatedNew, readOnly, isRequired, textAlign } = props;

    const [inputValue, handleValue] = useState(value || '');
    const isFirstRun = useRef(true);
    const { userRole } = useSelector(state => state.Home);
    const allowedRoles = [MRAT_USER_ROLE.Engineer, MRAT_USER_ROLE.View];
    const isAllowed = allowedRoles.includes(userRole);

    useEffect(() => {
        let timeOutId;
        if (isFirstRun.current) {
            isFirstRun.current = false;
        } else {
            timeOutId = setTimeout(() => handleChange({ [name]: inputValue }), 500);
        }
        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    useEffect(() => {
        handleValue(value);
    }, [value]);

    const handleTextValue = (e) => {
        const newTextValue = e.target.value?.replace(/^\s+/, '');
        if (newTextValue.length <= (maxLength ?? 255)) {
            handleValue(newTextValue);
        }
    };

    return (
        <InputWrapper alignItems={alignItems} marginTop={marginTop} marginBottom={marginBottom}>
            <StyledMultilineLabel
                width={width}
                marginLeft={marginLeft}
                marginRight={marginRight}
                padding={padding}
                direction={direction}
            >
                {label}
            </StyledMultilineLabel>

            <TextInputWrapper
                id={id}
                width={inputWidth}
                inputMarginTop={inputMarginTop}
                isCalculatedNew={isCalculatedNew}
                isRequired={isRequired}
                userRole={userRole}
                textAlign={textAlign}
                readOnly={readOnly}
                value={value}
            >
                <TextField
                    autoComplete="off"
                    fullWidth
                    variant="outlined"
                    id={`${id}_AutoGrow`}
                    inputProps={maxLength}
                    value={inputValue?.replace(/^\s+/, '') ?? ''}
                    onChange={(e) => handleTextValue(e)}
                    isRequired={isRequired}
                    multiline
                    disabled={readOnly || isAllowed || isDisabled}
                />
            </TextInputWrapper>
        </InputWrapper>
    );
};

InputAutogrow.propTypes = {
    alignItems: PropTypes.string,
    direction: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    inputMarginTop: PropTypes.string,
    inputWidth: PropTypes.string,
    isCalculatedNew: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    label: PropTypes.string.isRequired,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    marginTop: PropTypes.string,
    maxLength: PropTypes.string,
    name: PropTypes.string.isRequired,
    padding: PropTypes.string,
    readOnly: PropTypes.any,
    textAlign: PropTypes.string,
    value: PropTypes.any,
    width: PropTypes.string
};
