export const rolesHeaderData = [
    {
        field: 'RoleId',
        headerName: 'Role ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'RoleName',
        headerName: 'Role Name',
        width: '150',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '85',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '140',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '140',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const rolesInitialState = {
    RoleId: '',
    RoleName: '',
    Status: 'Inactive',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const rolesSearchState = {
    RoleId: '',
    RoleName: '',
    Status: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const rolesApiEndpoint = {
    read: 'ADMIN_GetRole'
};
