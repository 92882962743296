import { formatDate } from 'helpers/DateFormatter';

export const UserInformationSectionDataDPS = (userName, formValues) => {
    const createdDate = formValues?.CreatedDate ? formatDate(new Date(formValues?.CreatedDate)) : '';
    const fontFamily = '"Open Sans", sans-serif';
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'Region',
                    label: 'Region',
                    value: formValues.Region,
                    labelWidth: '8rem',
                    marginTop: '10px',
                    paddingLeft: '13px',
                    labelfontSize: '10pt',
                    labelfontFamily: fontFamily,
                    fontWeight: '550'
                },
                {
                    key: 'CreatedBy',
                    label: 'Created By',
                    value: formValues?.RowId ? formValues?.CreatedBy : userName?.name,
                    labelWidth: '8rem',
                    marginTop: '10px',
                    paddingLeft: '13px',
                    labelfontSize: '10pt',
                    labelfontFamily: fontFamily,
                    fontWeight: '550'
                },
                {
                    key: 'CreatedDate',
                    label: 'Created Date',
                    value: formValues?.RowId ? createdDate : formatDate(new Date()),
                    marginTop: '10px',
                    paddingLeft: '17px',
                    labelfontSize: '10pt',
                    labelfontFamily: fontFamily,
                    fontWeight: '550',
                    labelWidth: '8rem'
                },
                {
                    key: 'Asset',
                    label: 'Asset',
                    value: formValues.Asset,
                    labelWidth: '8rem',
                    marginTop: '2px',
                    paddingLeft: '13px',
                    labelfontSize: '10pt',
                    labelfontFamily: fontFamily,
                    fontWeight: '550'
                },
                {
                    key: 'ModifiedBy',
                    label: 'Modified By',
                    value: formValues?.ModifiedBy,
                    labelWidth: '8rem',
                    marginTop: '2px',
                    paddingLeft: '13px',
                    labelfontSize: '10pt',
                    labelfontFamily: fontFamily,
                    fontWeight: '550'
                },
                {
                    key: 'LastModifiedDate',
                    label: 'Last Modified Date',
                    value: formValues?.LastModifiedDate ? formatDate(new Date(formValues.LastModifiedDate)) : '',
                    labelWidth: '8rem',
                    marginTop: '2px',
                    paddingLeft: '17px',
                    labelfontSize: '10pt',
                    labelfontFamily: fontFamily,
                    fontWeight: '550'
                }]
            }
        ]
    };
};
