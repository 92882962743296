import { ExponentialToDecimal, ValidateField, FieldHasValue, ValidateNumberField } from 'helpers/Validation';
import { isF28InstEUHasValue } from './P65/P65.formulas';

export const HmiInst1FormulaP63 = (formData) => '0% - '.concat(ValidateField(formData.F25InstrumentLrv))
    .concat(isF28InstEUHasValue(formData))
    .concat(ValidateField(formData.F28InstrumentEngineeringUnit));

export const HmiInst2FormulaP63 = (formData) => '50% - '.concat(ExponentialToDecimal((ValidateNumberField(formData.F25InstrumentLrv)
    + (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) * 0.5)))
    .concat(isF28InstEUHasValue(formData))
    .concat(ValidateField(formData.F28InstrumentEngineeringUnit));

export const HmiInst3FormulaP63 = (formData) => '100% - '.concat(ValidateField(formData.F26InstrumentUrv))
    .concat(isF28InstEUHasValue(formData))
    .concat(ValidateField(formData.F28InstrumentEngineeringUnit));

export const AsFoundTestTableF54DeviationEu1Formula = (formData) => {
    if (FieldHasValue(formData.F42TestModuleReading1)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F45TxReading1) - ValidateNumberField(formData.F42TestModuleReading1)));
    }
    return '';
};

export const AsFoundTestTableF55DeviationEu2Formula = (formData) => {
    if (FieldHasValue(formData.F43TestModuleReading2)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F46TxReading2) - ValidateNumberField(formData.F43TestModuleReading2)));
    }
    return '';
};

export const AsFoundTestTableF56DeviationEu3Formula = (formData) => {
    if (FieldHasValue(formData.F44TestModuleReading3)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F47TxReading3) - ValidateNumberField(formData.F44TestModuleReading3)));
    }
    return '';
};
