import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormInput } from 'elements/Inputs/FormInput/';
import { StyledLabel } from 'elements/Label/Label';

import { selectAreaClassificationData } from './AreaClassification.data';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

const AreaClassificationSection = (props) => {
    const { selectedTag, formData, changeHandler, formName, isFormReadOnly, userRole } = props;
    const classes = useStyles();
    return (
        <Grid container spacing={1} className={classes.root}>
            {
                selectAreaClassificationData(selectedTag, formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ padding: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isLabel
                                    ? (
                                        <StyledLabel
                                            id={field.key}
                                            label={field.label}
                                            fontWeight={field.fontWeight}
                                            textAlign={field.textAlign}
                                            labelWidth={field.labelWidth}
                                            padding={field.padding}
                                            marginLeft={field.marginLeft}
                                            fontSize={field.fontSize}
                                        >
                                            {field.label}
                                        </StyledLabel>
                                    )
                                    : (
                                        <FormInput
                                            id={field.key}
                                            name={field.key}
                                            label={field.label}
                                            value={field.value}
                                            isRequired={field.isRequired}
                                            readOnly={field.readOnly || isFormReadOnly}
                                            handleChange={changeHandler}
                                            direction={field.direction}
                                            textAlign={field.textAlign}
                                            padding={field.padding}
                                            width={field.width}
                                            labelWidth={field.labelWidth}
                                            marginLeft={field.marginLeft}
                                            maxLength={field.maxLength}
                                            marginRight={field.marginRight}
                                            fontSize={field.fontSize}
                                            marginBottom={field.marginBottom}
                                            marginTop={field.marginTop}
                                            borderRight={field.borderRight}
                                            height={field.height}
                                            borderBottom={field.borderBottom}
                                            borderTop={field.borderTop}
                                            borderLeft={field.borderLeft}
                                            inputMarginRight={field.inputMarginRight}
                                            userRole={userRole}
                                            fontWeight={field.fontWeight}
                                        />
                                    )}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AreaClassificationSection);

AreaClassificationSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
