import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Grid } from '@material-ui/core';
import { FormInputMultiline } from 'elements/Inputs';
import { formatDate } from 'helpers/DateFormatter';
import { set } from 'idb-keyval';
import { useMsal } from '@azure/msal-react';
import imageStore from 'utils/ImageDB';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { newImages, setIsEditimage, editedImages } from 'journeys/portal/Forms/Forms.actions';
import { isMobile as isTablet } from 'react-device-detect';
import ImageId from './ImageIdGenerator';
import { CaptureImageWrapper, Camera, DisplayMessageWrapper, DisplayMessage, Info, UpdateNoteButton } from './CaptureImage.styled';
import Base64toBlob from './Base64toBlob';
import { ImageNoteWrapper } from '../ImageGallery/ImageGallery.styled';

const videoConstraintsTablet = {
    width: 500,
    height: 250,
    facingMode: { exact: 'environment' }
};

const videoConstraintsLaptop = {
    width: 500,
    height: 250,
    facingMode: { exact: 'user' }
};

const CaptureImage = (props) => {
    const { selectedTag, getImages, captureNewImage, readOnly, barrierType, assetName, hdWorkType, regionName, editImage, iseditimage, setIsEditImage, editedimages } = props;
    const webCamRef = useRef('');
    const [cameraSetUpError, setCameraSetUpError] = useState(false);
    const [notes, setNotes] = useState('');
    const [editNote, seteditNotes] = useState('');
    const formHandler = (event) => {
        setNotes(event.Notes);
    };
    const formHandlerforEditNote = (event) => {
        seteditNotes(event.editNotes);
    };
    const captureAccess = !readOnly;
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const updatehandler = () => {
        set(editImage.ImageId, {
            RowId: editImage.RowId,
            BpRegion: editImage.BpRegion,
            HdSite: editImage.HdSite,
            HdWorkOrder: editImage.HdWorkOrder,
            Mi: editImage.Mi === null ? '' : editImage.Mi,
            MaintenanceItemPm: editImage.MaintenanceItemPm === null ? '' : editImage.MaintenanceItemPm,
            HdWorkType: editImage.HdWorkType,
            BarrierType: editImage.BarrierType,
            HdLocation: editImage.HdLocation,
            CreationDate: editImage.CreationDate,
            ImageNotes: editNote.trim(),
            CreatedBy: editImage.CreatedBy,
            ImageId: editImage.ImageId,
            base64Image: editImage.base64Image,
            Image: editImage.Image,
            ModifiedBy: userName?.name,
            DateLastModified: formatDate(new Date())
        }, imageStore);
        setIsEditImage(false, {});
        editedimages(editImage.ImageId);
        seteditNotes('');
        getImages();
    };
    const saveImage = () => {
        const imageSrc = webCamRef.current.getScreenshot();
        const today = new Date();
        const id = ImageId(today);
        const creationDate = formatDate(today);
        const blobData = Base64toBlob(imageSrc);
        set(id, {
            RowId: 0,
            BpRegion: regionName,
            HdSite: assetName,
            HdWorkOrder: selectedTag?.HdWorkOrder,
            Mi: selectedTag?.Mi === null ? '' : selectedTag?.Mi,
            MaintenanceItemPm: selectedTag?.MaintenanceItemPm === null ? '' : selectedTag?.MaintenanceItemPm,
            HdWorkType: hdWorkType,
            BarrierType: barrierType,
            HdLocation: selectedTag?.HdLocation,
            CreationDate: creationDate,
            ImageNotes: notes.trim(),
            CreatedBy: userName?.name,
            ImageId: id,
            base64Image: imageSrc,
            Image: blobData,
            ModifiedBy: userName?.name,
            DateLastModified: creationDate
        }, imageStore);
        captureNewImage(id);
        setNotes('');
        getImages();
    };
    const isEditImg = !editImage.RowId
        ? (
            <img
                src={editImage.base64Image}
                width="98%"
                height="100%"
                alt={`capture-${editImage.ImageId}`}
                key={`image-${editImage.ImageId}`}
                id={`image-${editImage.ImageId}`}
            />
        ) : (
            <img
                src={`data:image/png;base64,${editImage.Image}`}
                width="100%"
                height="100%"
                alt={`capture-${editImage.ImageId}`}
                key={`image-${editImage.ImageId}`}
                id={`image-${editImage.ImageId}`}
            />
        );

    const isTabletCheck = isTablet ? videoConstraintsTablet : videoConstraintsLaptop;

    const isCaptureAccess = captureAccess ? (
        <Webcam
            id="web_cam"
            audio={false}
            ref={webCamRef}
            screenshotFormat="image/jpeg"
            width="98%"
            height="100%"
            onClick={() => saveImage()}
            videoConstraints={isTabletCheck}
            screenshotQuality={10}
            onUserMediaError={(MediaStreamError) => {
                setCameraSetUpError(true);
            }}
        />
    ) : (
        <DisplayMessageWrapper>
            <DisplayMessage paddingTop="25%" id="viewer_mode">
                You are viewing this in view only mode.
            </DisplayMessage>
        </DisplayMessageWrapper>
    );
    const isCameraSetUpErr = cameraSetUpError
        ? (
            <DisplayMessageWrapper>
                <DisplayMessage paddingTop="20%" id="media_error">
                    Your camera is not set up properly, is currently in use, or is improperly configured by the app maker.
                </DisplayMessage>
            </DisplayMessageWrapper>
        ) : (
            <Camera id="camera">
                {isCaptureAccess}
            </Camera>
        );
    return (
        <div>
            {
                iseditimage
                    ? (
                        <CaptureImageWrapper>
                            <FormInputMultiline
                                rows="6"
                                id="editNotes"
                                name="editNotes"
                                label="Notes"
                                marginTop="5px"
                                value={editImage.ImageNotes || editNote}
                                handleChange={(obj) => { formHandlerforEditNote(obj); }}
                                type="text"
                                fontSize="11pt"
                            />
                            <ImageNoteWrapper id={`imageWrapper-${editImage.ImageId}`}>
                                <Grid container>
                                    <Grid item container xs={11}>
                                        {isEditImg}
                                    </Grid>
                                    <Grid item container xs={1}>
                                        <UpdateNoteButton>
                                            <button type="button" onClick={() => updatehandler()}>
                                                Update Note
                                            </button>
                                        </UpdateNoteButton>
                                    </Grid>
                                </Grid>
                            </ImageNoteWrapper>
                        </CaptureImageWrapper>
                    ) : (
                        <CaptureImageWrapper id="camera_wrapper">
                            {captureAccess && (
                                <FormInputMultiline
                                    rows="6"
                                    id="Notes"
                                    name="Notes"
                                    label="Notes"
                                    marginTop="5px"
                                    value={notes}
                                    handleChange={(obj) => { formHandler(obj); }}
                                    type="text"
                                    fontSize="11pt"
                                />
                            )}
                            <div style={{ display: 'flex' }}>
                                {isCameraSetUpErr}
                                {captureAccess && <Info id="info">Click Over the camera view to capture the picture</Info>}
                            </div>
                        </CaptureImageWrapper>
                    )
            }
        </div>
    );
};

const mapStateToProps = ({ Home, MOSelection, Forms }) => ({
    assetName: Home.selectedAssetName,
    barrierType: MOSelection.selectedRow.BarrierType,
    editImage: Forms.editImage,
    iseditimage: Forms.iseditimage,
    hdWorkType: Home.selectedWorkType,
    regionName: Home.regionName
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    captureNewImage: (imageId) => dispatch(newImages(imageId)),
    setIsEditImage: (isEdit, img) => dispatch(setIsEditimage(isEdit, img)),
    editedimages: (id) => dispatch(editedImages(id))

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CaptureImage);

CaptureImage.propTypes = {
    assetName: PropTypes.string,
    barrierType: PropTypes.string,
    captureNewImage: PropTypes.func,
    editedimages: PropTypes.func,
    editImage: PropTypes.string,
    getImages: PropTypes.func,
    hdWorkType: PropTypes.string,
    iseditimage: PropTypes.bool,
    readOnly: PropTypes.bool,
    regionName: PropTypes.string,
    selectedTag: PropTypes.object,
    setIsEditImage: PropTypes.func
};
