import { createTableDefinitionObject } from '../SubSections/DPS/DPS.helpers';

export const TYPE = {
    NAME: 'NAME'
};

export const tableDefinition = [
    createTableDefinitionObject('HdLocation', 'Functional Location', '18%'),
    createTableDefinitionObject('DateTested', 'First Date Tested'),
    createTableDefinitionObject('CompletedBy', 'First Test Done By'),
    createTableDefinitionObject('HdLocationDescription', 'Location Description', '20%'),
    createTableDefinitionObject('LocationParent', 'Parent Location', '18%'),
    createTableDefinitionObject('TrfShortDesc', 'Test Record Form ID'),
];

export const updatedTableDefinition = tableDefinition.map((row) => {
    if (row.columnTitle === 'Functional Location') {
        return {
            ...row,
            displaySort: true
        };
    }
    if (row.columnTitle === 'Print Select All') {
        return {
            ...row,
            displayCheckBox: true
        };
    }
    return row;
}
);

export const TAGS_ACTIONS = {
    DESELECT_FILTERS: 'DESELECT_FILTERS',
    GET_TAGRECORDS_PENDING: 'GET_TAGRECORDS_PENDING',
    GET_TAGRECORDS_SUCCESS: 'GET_TAGRECORDS_SUCCESS',
    SET_TAGRECORD_ROW: 'SET_TAGRECORD_ROW',
    GET_SEARCHTAGRECORDS_PENDING: 'GET_SEARCHTAGRECORDS_PENDING',
    GET_SEARCHTAGRECORDS_SUCCESS: 'GET_SEARCHTAGRECORDS_SUCCESS',
    GET_SEARCHTAGRECORDS_FAILURE: 'GET_SEARCHTAGRECORDS_FAILURE',
    GET_SORTTAGRECORDS_PENDING: 'GET_SORTTAGRECORDS_PENDING',
    GET_SORTTAGRECORDS_SUCCESS: 'GET_SORTTAGRECORDS_SUCCESS',
    GET_SORTTAGRECORDS_FAILURE: 'GET_SORTTAGRECORDS_FAILURE',
    GET_TAGRECORDS_TOTALCOUNT: 'GET_TAGRECORDS_TOTALCOUNT',
    LOAD_FILTERS: 'LOAD_FILTERS',
    SET_TESTSTATUS_FILTERS: 'SET_TESTSTATUS_FILTERS',
    SET_FILTERS: 'SET_FILTERS',
    SET_CHECKBOX_FILTERS: 'SET_CHECKBOX_FILTERS',
    GET_SELECTEDROWTAGS: 'GET_SELECTEDROWTAGS',
    GET_TESTSTATUS_SELECTEDROWTAGS: 'GET_TESTSTATUS_SELECTEDROWTAGS',
    HDLOCATION_SORTORDER: 'HDLOCATION_SORTORDER'
};
