import axios from 'axios';
import { set, get, del, keys, values, clear } from 'idb-keyval';
import offlineStore from 'utils/OfflineDB';
import imageStore from 'utils/ImageDB';
import { APIEndpoints } from 'helpers/APILists';
import { ExponentialToDecimal, Trim, MatchSRA } from 'helpers/Validation';
import { formatDate } from 'helpers/DateFormatter';
import { MESSAGE_TYPE } from '../../common/common.constants';
import { FORM_ACTIONS } from './Forms.constants';
import { TAGS_ACTIONS } from '../TagSelectionMOScreen/TagSelectionMOScreen.constants';
import { HOME_ACTIONS } from '../Home/Home.constants';
import { formDataInitialState } from './Forms.data';
import { getAllDPSData } from '../SubSections/DPS/DPS.actions';
import { setCurrentView } from '../Home/Home.actions';
import { getAllTRFFormImage, errorMsg } from './TRFImaging.actions';
import { getTestMakeModelData } from '../TestEquipment/TestEquipment.actions';
import { getTestStatusFilteredData } from '../TagSelectionMOScreen/TagSelectionMOScreen.actions';
import _ from 'lodash';

export const getTotalRecordsSavedOffline = () => async (dispatch) => {
    const keyData = await keys(offlineStore);
    const formsSavedOffline = keyData.filter((data) => data.includes('Forms_'));
    const sraFormsSavedOffline = keyData.filter((data) => data.includes('Forms_') && data.includes('_SRA'));
    dispatch({
        type: FORM_ACTIONS.GET_OFFLINE_FORM_COUNT,
        payload: {
            totalCount: formsSavedOffline.length - sraFormsSavedOffline.length
        }
    });
};

const trimFormData = (formData) => {
    const formDataObject = formData;
    Object.keys(formDataObject).forEach((key) => {
        if (typeof formDataObject[key] === 'string') {
            formDataObject[key] = formDataObject[key]?.trim();
        }
    });
    return formDataObject;
};

export const setDataForFormTagSelected = (selectedTag) => (dispatch) => {
    const payloadValue = (defalutValue) => defalutValue ?? '';
    dispatch({
        type: FORM_ACTIONS.SET_FORMTAG_SELECTED,
        payload: {
            selectedTag: {
                ...selectedTag,
                currentDateTime: new Date(),
                TsprReCalibrationTolerance: ExponentialToDecimal(payloadValue(selectedTag?.TsprReCalibrationTolerance)),
                TsprHmiLrv: ExponentialToDecimal(payloadValue(selectedTag?.TsprHmiLrv)),
                TsprHmiUrv: ExponentialToDecimal(payloadValue(selectedTag?.TsprHmiUrv)),
                FailureModeHighCurrentMA: ExponentialToDecimal(payloadValue(selectedTag?.FailureModeHighCurrentMA)),
                FailureModeLowCurrentMA: ExponentialToDecimal(payloadValue(selectedTag?.FailureModeLowCurrentMA)),
                TsprInstrumentCalibratedLrv: ExponentialToDecimal(payloadValue(selectedTag?.TsprInstrumentCalibratedLrv)),
                InstrumentCalibratedUrv: ExponentialToDecimal(payloadValue(selectedTag?.InstrumentCalibratedUrv)),
                DesignElementResponseTime: ExponentialToDecimal(payloadValue(selectedTag?.DesignElementResponseTime)),
                FailTolerance: ExponentialToDecimal(payloadValue(selectedTag?.FailTolerance)),
                HighHighSetpoint: ExponentialToDecimal(payloadValue(selectedTag?.HighHighSetpoint)),
                LowLowSetpoint: ExponentialToDecimal(payloadValue(selectedTag?.LowLowSetpoint)),
                SetPointHigh: ExponentialToDecimal(payloadValue(selectedTag?.SetPointHigh)),
                LowSetPoint: ExponentialToDecimal(payloadValue(selectedTag?.LowSetPoint)),
                SwitchSetpoint: ExponentialToDecimal(payloadValue(selectedTag?.SwitchSetpoint)),
                ValveSize: ExponentialToDecimal(payloadValue(selectedTag?.ValveSize)),
                ToleranceInInstrumentEu: ExponentialToDecimal(payloadValue(selectedTag?.ToleranceInInstrumentEu)),
                TargetLeakageRate: ExponentialToDecimal(payloadValue(selectedTag?.TargetLeakageRate)),
                ReCalibrationTolerance: ExponentialToDecimal(payloadValue(selectedTag?.ReCalibrationTolerance)),
            }
        }

    });
};

const buildFormData = (img) => {
    const image = deleteProperty(img, 'base64Image');
    const formData = new FormData();
    Object.keys(image).forEach((key) => {
        formData.append(key, image[key]);
    });
    return formData;
};

export const deleteProperty = (formObject, propertyToRemove) => {
    const result = { ...formObject };
    delete result[propertyToRemove];
    return result;
};

export const deleteImagesFromLocalDB = (imageList) => async (dispatch) => {
    const offlineStoreData = await values(offlineStore);
    if (imageList.length !== 0) {
        for await (const imageId of imageList) {
            for await (const offlineData of offlineStoreData) {
                if (offlineData.url.includes('SubmitImages') && offlineData.body.ImageId === imageId) {
                    await del(`Images_${offlineData.body.ImageId}`, offlineStore);
                }
            }
        }
    }
};

export const deleteImagesFromDB = (uniqueImageId, imageList) => (dispatch, getState) => {
    dispatch({
        type: FORM_ACTIONS.FORM_ACTIONS_DELETEIMAGES_PENDING,
    });
    axios.post(`${APIEndpoints.DeleteImages}`, imageList).then(() => {
        // HERE I SHOULD ALSO get the form object from the returned data, as it contains RowId from the database and fetch it here in the redux store
        dispatch({
            type: FORM_ACTIONS.FORM_ACTIONS_DELETEIMAGES_SUCCESS,
            meta: { message: 'images deleted successfully' }
        });
    }).catch((err) => {
        if (err.message === errorMsg) {
            const offlineObject = {
                url: err.config.url,
                body: imageList,
                requestType: 'POST'
            };
            set(`deleteImages_${uniqueImageId}`, offlineObject, offlineStore)
                .then(() => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_DELETEIMAGES_OFFLINE,
                        meta: { err }
                    });
                })
                .catch((offlineErr) => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_DELETEIMAGES_FAILURE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: offlineErr.message, exception: offlineErr }
                    });
                });
        } else {
            dispatch({
                type: FORM_ACTIONS.FORM_ACTIONS_DELETEIMAGES_FAILURE,
                meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
            });
        }
    });
};

const selectedRowTagupdate = (x, formObject) => (x.HdLocation === formObject.F2SapFloc)
    && (x.HdWorkOrder === formObject.F89Mo
        || x.HdWorkOrder === formObject.F20Mo)
    && (x.Mi === formObject.F86Mi
        || x.Mi === formObject.F17Mi);

const updateTagRecord = (tag, formObject, newTrfId, trfMasterList) => {
    if (selectedRowTagupdate(tag, formObject)) {
        return {
            ...tag,
            DateTested: formObject.F5DateTested,
            CompletedBy: formObject.F4CompletedBy,
            InDraftState: formObject.InDraftState,
            TrfId: newTrfId ?? tag.TrfId,
            TrfShortDesc: newTrfId ? trfMasterList.filter(trfItem => trfItem.TrfId === newTrfId)[0].TrfShortDesc : tag.TrfShortDesc,
        };
    }
    return tag;
};

export const updateSelectedRowTags = (formObject, newTrfId) => async (dispatch, getState) => {
    const { Forms: { selectedTrfId, selectedTag }, TagSelectionMOScreen: { selectedRowTags, selectedRowTagsTableData }, Home: { trfMasterList } } = getState();
    const trfId = selectedTrfId ?? Trim(selectedTag.TrfId);
    if (!(newTrfId === 'P8-1' && MatchSRA(Trim(trfId)))) {
        dispatch({
            type: TAGS_ACTIONS.GET_TAGRECORDS_SUCCESS,
            payload: {
                selectedRowTags: selectedRowTags.map(tag => updateTagRecord(tag, formObject, newTrfId, trfMasterList)),
                selectedRowTagsTableData: selectedRowTagsTableData.map(tag => updateTagRecord(tag, formObject, newTrfId, trfMasterList))
            }
        });
    }
};

export const updateTags = (formObject, newTrfId) => async (dispatch, getState) => {
    const { Home: { trfMasterList, tags }, Forms: { selectedTrfId, selectedTag } } = getState();
    const trfId = selectedTrfId ?? Trim(selectedTag.TrfId);
    if (!(newTrfId === 'P8-1' && MatchSRA(Trim(trfId)))) {
        dispatch({
            type: HOME_ACTIONS.HOME_ACTIONS_GET_TAG_RECORDS_DATA_SUCCESS,
            payload: {
                tags: tags.map(x => selectedRowTagupdate(x, formObject)
                    ? {
                        ...x,
                        DateTested: formObject.F5DateTested,
                        CompletedBy: formObject.F4CompletedBy,
                        InDraftState: formObject.InDraftState,
                        TrfId: newTrfId ?? x.TrfId,
                        TrfShortDesc: newTrfId ? trfMasterList.filter(trfItem => trfItem.TrfId === newTrfId)[0].TrfShortDesc : x.TrfShortDesc,
                    }
                    : x)
            }
        });
    }
};

const formSubmitSucceeded = (formObject, newTrfId) => async (dispatch) => {
    dispatch(updateSelectedRowTags(formObject, newTrfId));
    dispatch(updateTags(formObject, newTrfId));
    dispatch(setCurrentView('tagselectionmoscreen'));
};

export const submitImagesOnlineOrOffline = () => async (dispatch, getState) => {
    const { Forms: { selectedTag } } = getState();
    const capturedImages = await values(imageStore);
    const images = capturedImages?.filter((img) => img.HdLocation === selectedTag.HdLocation && !img.RowId);
    images.forEach(async (image) => {
        dispatch({
            type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWIMAGEDATA_PENDING,
        });
        await axios.post(APIEndpoints.SubmitImages, buildFormData(image)).then((res) => {
            dispatch({
                type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWIMAGEMDATA_SUCCESS,
                meta: { message: 'Image saved successfully' }
            });
            del(image.ImageId, imageStore).then(() => set(res.data.ImageId, res.data, imageStore));
        }).catch((err) => {
            if (err.message === errorMsg) {
                const offlineObject = {
                    url: err.config.url,
                    body: image,
                    requestType: 'POST'
                };
                set(`Images_${image.ImageId}`, offlineObject, offlineStore)
                    .then(() => {
                        dispatch({
                            type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWIMAGEDATA_OFFLINE,
                            meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                        });
                    })
                    .catch((offlineErr) => {
                        dispatch({
                            type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWIMAGEDATA_FAILURE,
                            meta: { messageType: MESSAGE_TYPE.ERROR, message: offlineErr.message, exception: offlineErr }
                        });
                        del(image.ImageId, imageStore);
                    });
            } else {
                dispatch({
                    type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWIMAGEDATA_FAILURE,
                    meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                });
                del(image.ImageId, imageStore);
            }
        });
    });
};
export const setIsEditimage = (isEdit, img) => ({

    type: FORM_ACTIONS.SET_IS_EDIT_IMAGE,
    payload: {
        iseditimage: isEdit,
        editImage: img
    }
});
export const editedImages = (ImageId) => (dispatch, getState) => {
    let { Forms: { editedImageList } } = getState();
    editedImageList = [...editedImageList, ImageId];
    dispatch({
        type: FORM_ACTIONS.EDIT_IMAGE_NOTE,
        payload: {
            editedImageList
        }
    });
};
export const clearEditImageList = () => ({
    type: FORM_ACTIONS.CLEAR_EDIT_IMAGE_LIST,
    payload: {
        editedImageList: []
    }
});
export const updateImageNote = () => async (dispatch, getState) => {
    const { Forms: { editedImageList } } = getState();
    const capturedImages = await values(imageStore);
    const images = capturedImages.filter(x => editedImageList.indexOf(x.ImageId) > -1);
    images.forEach(async (image) => {
        if (image.RowId) {
            dispatch({
                type: FORM_ACTIONS.UPDATE_IMAGE_NOTE_PENDING,
            });
            await axios.put(APIEndpoints.EditImageNote, buildFormData(image)).then((res) => {
                dispatch({
                    type: FORM_ACTIONS.UPDATE_IMAGE_NOTE_SUCCESS,
                    meta: { message: 'Edited Image Note successfully' }
                });
                del(image.ImageId, imageStore).then(() => set(res.data.ImageId, res.data, imageStore));
            }).catch((err) => {
                if (err.message === errorMsg) {
                    const offlineObject = {
                        url: err.config.url,
                        body: image,
                        requestType: 'PUT'
                    };
                    set(`Images_${image.ImageId}`, offlineObject, offlineStore)
                        .then(() => {
                            dispatch({
                                type: FORM_ACTIONS.UPDATE_IMAGE_NOTE_OFFLINE_SUCCESS,
                                meta: { messageType: MESSAGE_TYPE.INFO, message: 'Image note stored in OfflineStore', exception: err }
                            });
                        })
                        .catch((offlineErr) => {
                            dispatch({
                                type: FORM_ACTIONS.UPDATE_IMAGE_NOTE_OFFLINE_FAILURE,
                                meta: { err: offlineErr }
                            });
                            del(image.imageId, imageStore);
                        });
                } else {
                    dispatch({
                        type: FORM_ACTIONS.UPDATE_IMAGE_NOTE_FAILURE,
                        meta: { err }
                    });
                    del(image.ImageId, imageStore);
                }
            });
        }
    });
};
export const submitNewFormOnlineOrOffline = (uniqueFormId, formObject, newTrfId, userName) => async (dispatch, getState) => {
    dispatch({
        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWFORMDATA_PENDING,
    });
    const { Forms: { updatedFormDataObj, formDataSRA } } = getState();
    const newFormObject = MatchSRA(newTrfId) && !formObject?.InDraftState ? _.merge(formObject, formDataSRA) : _.merge(formObject, updatedFormDataObj);
    newFormObject.F4ModifiedBy = userName?.name;
    newFormObject.F51DateLastModified = newFormObject.F5DateTested;
    if (newFormObject?.InDraftState && newFormObject?.draftTrackingData) {
        dispatch(saveDraft(newFormObject.draftTrackingData, uniqueFormId));
        delete newFormObject.draftTrackingData;
    }
    axios.post(`${APIEndpoints.SubmitFormEndpoint}/${newTrfId}`, trimFormData(deleteProperty(newFormObject, 'RowId'))).then(() => {
        // HERE I SHOULD ALSO get the form object from the returned data, as it contains RowId from the database and fetch it here in the redux store
        dispatch({
            type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWFORMDATA_SUCCESS,
            meta: { message: 'form data saved successfully' }
        });
        dispatch(formSubmitSucceeded(newFormObject, newTrfId));
    }).catch((err) => {
        if (err.message === errorMsg) {
            const offlineObject = {
                url: err.config.url,
                body: trimFormData(deleteProperty(newFormObject, 'RowId')),
                requestType: 'POST'
            };
            set(`Forms_${uniqueFormId}`, offlineObject, offlineStore)
                .then(() => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWFORMDATA_OFFLINE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                    });
                    dispatch(formSubmitSucceeded(newFormObject, newTrfId));
                })
                .catch((offlineErr) => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWFORMDATA_FAILURE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: offlineErr.message, exception: offlineErr }
                    });
                });
        } else {
            dispatch({
                type: FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWFORMDATA_FAILURE,
                meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
            });
        }
    });
};

export const submitExistingFormOnlineOrOffline = (uniqueFormId, formObject, newTrfId, userName) => async (dispatch, getState) => {
    dispatch({
        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITEXISTINGFORMDATA_PENDING,
    });
    const { Forms: { selectedTag } } = getState();
    const newFormObject = { ...formObject };
    newFormObject.F4ModifiedBy = userName?.name;
    newFormObject.F51DateLastModified = formatDate(selectedTag.currentDateTime);
    if (newFormObject?.draftTrackingData) {
        dispatch(updateDraft(newFormObject.draftTrackingData, uniqueFormId));
        delete newFormObject.draftTrackingData;
    }
    axios.put(APIEndpoints.SubmitFormEndpoint.concat(`/${newTrfId}`), trimFormData(newFormObject)).then(() => {
        dispatch({
            type: FORM_ACTIONS.FORM_ACTIONS_SUBMITEXISTINGFORMDATA_SUCCESS,
            meta: { message: 'form data saved successfully' }
        });
        dispatch(formSubmitSucceeded(newFormObject, null));
    }).catch((err) => {
        if (err.message === errorMsg) {
            const offlineObject = {
                url: err.config.url,
                body: trimFormData(newFormObject),
                requestType: 'PUT'
            };
            set(`Forms_${uniqueFormId}`, offlineObject, offlineStore)
                .then(() => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITEXISTINGFORMDATA_OFFLINE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                    });
                    dispatch(setCurrentView('tagselectionmoscreen'));
                })
                .catch((offlineErr) => {
                    dispatch({
                        type: FORM_ACTIONS.FORM_ACTIONS_SUBMITEXISTINGFORMDATA_FAILURE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: offlineErr.message, exception: offlineErr }
                    });
                });
        } else {
            dispatch({
                type: FORM_ACTIONS.FORM_ACTIONS_SUBMITEXISTINGFORMDATA_FAILURE,
                meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
            });
        }
    });
};

const processFormData = (res, selectedTag, currentTrfId) => {
    const { data } = res;
    const fetchFormData = typeof data === 'string'
        ? null
        : data.find((item) => (item.F89Mo === selectedTag.HdWorkOrder
            || item.F20Mo === selectedTag.HdWorkOrder)
            && (item.F86Mi === selectedTag.Mi
                || item.F17Mi === selectedTag.Mi));
    const resultFormData = fetchFormData ?? formDataInitialState;
    const aftTestPoint = ['P7-20', 'P7-21', 'M6-10', 'P6-26'].includes(currentTrfId)
        ? Number(resultFormData?.F675NumberOfTestPoints)
        : 1;
    const altTestPoint = ['P7-20', 'P7-21', 'M6-10', 'P6-26'].includes(currentTrfId)
        ? Number(resultFormData?.F721NumberOfTestPoints)
        : 1;
    const flowAlarmTestPoint = currentTrfId === 'M6-10'
        ? Number(resultFormData?.F816NumberOfTestPoints)
        : 1;

    return {
        tableLoading: false,
        formData: resultFormData,
        altTestPoint,
        aftTestPoint,
        flowAlarmTestPoint
    };
};

const processOfflineFormData = (data, currentTrfId) => {
    const fetchFormData = data?.body;
    const resultFormData = fetchFormData ?? formDataInitialState;
    const aftTestPoint = ['P7-20', 'P7-21', 'M6-10', 'P6-26'].includes(currentTrfId)
        ? Number(resultFormData?.F675NumberOfTestPoints)
        : 1;
    const altTestPoint = ['P7-20', 'P7-21', 'M6-10', 'P6-26'].includes(currentTrfId)
        ? Number(resultFormData?.F721NumberOfTestPoints)
        : 1;
    const flowAlarmTestPoint = currentTrfId === 'M6-10'
        ? Number(resultFormData?.F816NumberOfTestPoints)
        : 1;

    return {
        tableLoading: false,
        formData: resultFormData,
        aftTestPoint,
        altTestPoint,
        flowAlarmTestPoint
    };
};

export const GetITFForm = ({ uniqueFormName, HdLocation, currentTrfId }) => async (dispatch, getState) => {
    const { AppData: { isMRATOnline }, Forms: { selectedTag } } = getState();
    const filterData = { TrfId: currentTrfId, HdLocation };
    return new Promise((resolve, reject) => {
        dispatch({
            type: FORM_ACTIONS.GET_ITF_FORM_PENDING,
            payload: {
                tableLoading: true,
                deleteExistingImageList: [],
                deleteNewImageList: [],
                newImageList: []
            }
        });
        dispatch(getAllMOImages());
        dispatch(getTotalRecordsSavedOffline());
        if (isMRATOnline) {
            dispatch(getAllDPSData());
            dispatch(getAllTRFFormImage());
            dispatch(getTestMakeModelData());
            axios.post(APIEndpoints.GetITFForm, filterData)
                .then((res) => {
                    const result = processFormData(res, selectedTag, currentTrfId);
                    dispatch({
                        type: FORM_ACTIONS.GET_ITF_FORM_SUCCESS,
                        payload: result
                    });
                    resolve(result.formData);
                })
                .catch((err) => {
                    dispatch({
                        type: FORM_ACTIONS.GET_ITF_FORM_FAILURE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                    });
                    reject(err);
                });
        } else {
            get(`Forms_${uniqueFormName}`, offlineStore).then((data) => {
                const result = processOfflineFormData(data, currentTrfId);
                dispatch({
                    type: FORM_ACTIONS.GET_ITF_FORM_OFFLINE_SUCCESS,
                    payload: result
                });
                resolve(result.formData);
            })
                .catch((err) => {
                    dispatch({
                        type: FORM_ACTIONS.GET_ITF_FORM_FAILURE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                    });
                    reject(err);
                });
        }
    });
};

export const resetFormData = () => (dispatch) => {
    dispatch({
        type: FORM_ACTIONS.RESET_FORM_DATA,
        payload: {
            formData: formDataInitialState,
            deleteExistingImageList: [],
            deleteNewImageList: [],
            newImageList: []
        }

    });
    dispatch(getTestStatusFilteredData());
};

export const setSelectedTRF = (selectedTrfId) => (dispatch, getState) => {
    const { Forms: { selectedTag } } = getState();
    dispatch({
        type: FORM_ACTIONS.SET_SELECTED_TRF,
        payload: {
            selectedTrfId,
            selectedTag: { ...selectedTag, currentDateTime: new Date() }
        }
    });
};

export const clearSelectedTRF = () => (dispatch) => {
    dispatch({
        type: FORM_ACTIONS.CLEAR_SELECTED_TRF,
        payload: {
            selectedTrfId: null
        }
    });
};

export const deleteExistingImages = (rowId) => (dispatch, getState) => {
    let { Forms: { deleteExistingImageList } } = getState();
    deleteExistingImageList = [...deleteExistingImageList, rowId];
    dispatch({
        type: FORM_ACTIONS.DELETE_IMAGE,
        payload: {
            deleteExistingImageList
        }
    });
};

export const deleteNewImages = (imageId) => (dispatch, getState) => {
    let { Forms: { deleteNewImageList } } = getState();
    deleteNewImageList = [...deleteNewImageList, imageId];
    dispatch({
        type: FORM_ACTIONS.DELETE_IMAGE,
        payload: {
            deleteNewImageList
        }
    });
};

export const newImages = (ImageId) => (dispatch, getState) => {
    let { Forms: { newImageList } } = getState();
    newImageList = [...newImageList, ImageId];
    dispatch({
        type: FORM_ACTIONS.CAPTURE_IMAGE,
        payload: {
            newImageList
        }
    });
};

export const getAllMOImages = () => async (dispatch, getState) => {
    const { AppData: { isMRATOnline }, Forms: { selectedTag } } = getState();
    dispatch({
        type: FORM_ACTIONS.GETIMAGES_PENDING
    });
    if (isMRATOnline) {
        const delAllExistingImages = await values(imageStore);
        delAllExistingImages.forEach((img) => del(img.ImageId, imageStore));
        const filterData = { MO: selectedTag.HdWorkOrder, Mi: selectedTag.Mi, HdLocation: selectedTag.HdLocation };
        await axios.post(APIEndpoints.GetImages, filterData)
            .then((res) => {
                res.data.map((data) => set(data.ImageId, data, imageStore));
                dispatch({
                    type: FORM_ACTIONS.GETIMAGES_SUCCESS
                });
            }).catch((err) => dispatch({
                type: FORM_ACTIONS.GETIMAGES_FAILURE,
                meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
            }));
    } else {
        clear(imageStore);
        const offlineStoreData = await values(offlineStore);
        for await (const data of offlineStoreData) {
            if (data.url.includes('SubmitImages')) {
                await set(data.body.ImageId, data.body, imageStore);
            }
        }
    }
};

export const UpdateFormDataObj = (formData) => async (dispatch) => {
    dispatch({
        type: FORM_ACTIONS.UPDATE_FORMDATE_OBJECT,
        payload: {
            updatedFormDataObj: formData
        }
    });
};

const createSaveTestPointRangePrevVal = (type, key) => (testPoint) => (dispatch) => {
    dispatch({
        type,
        payload: {
            [key]: testPoint
        }
    });
};

export const saveAltTestPointRangePrevVal = createSaveTestPointRangePrevVal(FORM_ACTIONS.UPDATE_ASLEFT_TESTPOINTRANGE, 'altTestPoint');
export const saveAftTestPointRangePrevVal = createSaveTestPointRangePrevVal(FORM_ACTIONS.UPDATE_ASFOUND_TESTPOINTRANGE, 'aftTestPoint');
export const saveFlowAlarmTestPointRangePrevVal = createSaveTestPointRangePrevVal(FORM_ACTIONS.UPDATE_FLOWALARM_TESTPOINTRANGE, 'flowAlarmTestPoint');

export const resetTestPointVal = () => (dispatch) => {
    dispatch({
        type: FORM_ACTIONS.UPDATE_TESTPOINTRANGE,
        payload: {
            aftTestPoint: null,
            altTestPoint: null,
            flowAlarmTestPoint: null
        }
    });
};

export const saveSpinnerVisibleData = (spinnerValue) => (dispatch) => {
    dispatch({
        type: FORM_ACTIONS.UPDATE_SPINNER_VISIBLE,
        payload: {
            spinnerVisible: spinnerValue
        }
    });
};

export const getMultiTRFDetails = () => async (dispatch, getState) => {
    const res = await axios.get(APIEndpoints.ADMIN_GetMultiTRFDetails);
    const multiTRFDetailsData = res.data.map(x => ({ TrfmasterId: x.TrfmasterId, Trftag: x.Trftag, Trftitle: x.Trftitle, Status: x.Status }));
    const activeMultiTRFDetailsData = multiTRFDetailsData.filter((y) => y.Status === true);
    const multiTRFDetails = activeMultiTRFDetailsData.sort((a, b) => a.TrfmasterId?.localeCompare(b.TrfmasterId, undefined, {
        numeric: true,
        sensitivity: 'base'
    }));
    dispatch({
        type: FORM_ACTIONS.FORM_ACTIONS_GET_MULTI_TRF_DETAILS_DATA_SUCCESS,
        payload: {
            multiTRFDetails
        }
    });
};

export const saveDraft = (data, uniqueFormId) => (dispatch) => {
    axios.post(`${APIEndpoints.SaveDraftTrackingDetails}`, data)
        .then(() => {
            dispatch({
                type: FORM_ACTIONS.FORM_ACTIONS_SAVE_DRAFT_ONLINE_SUCCESS,
                meta: { message: 'draft form data saved successfully' }
            });
        }).catch((err) => {
            if (err.message === errorMsg) {
                const offlineObject = {
                    url: err.config.url,
                    body: data,
                    requestType: 'POST'
                };
                set(`Draft_${uniqueFormId}`, offlineObject, offlineStore)
                    .then(() => {
                        dispatch({
                            type: FORM_ACTIONS.FORM_ACTIONS_SAVE_DRAFT_OFFLINE_SUCCESS,
                            meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                        });
                    })
                    .catch((offlineErr) => {
                        dispatch({
                            type: FORM_ACTIONS.FORM_ACTIONS_SAVE_DRAFT_OFFLINE_FAILURE,
                            meta: { messageType: MESSAGE_TYPE.ERROR, message: offlineErr.message, exception: offlineErr }
                        });
                    });
            } else {
                dispatch({
                    type: FORM_ACTIONS.FORM_ACTIONS_SAVE_DRAFT_ONLINE_FAILURE,
                    meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                });
            }
        });
};

export const updateDraft = (data, uniqueFormId) => (dispatch) => {
    axios.put(`${APIEndpoints.UpdateDraftTrackingDetails}`, data)
        .then(() => {
            dispatch({
                type: FORM_ACTIONS.FORM_ACTIONS_UPDATE_DRAFT_ONLINE_SUCCESS,
                meta: { message: 'draft form data updated successfully' }
            });
        }).catch((err) => {
            if (err.message === errorMsg) {
                const offlineObject = {
                    url: err.config.url,
                    body: data,
                    requestType: 'PUT'
                };
                set(`Draft_${uniqueFormId}`, offlineObject, offlineStore)
                    .then(() => {
                        dispatch({
                            type: FORM_ACTIONS.FORM_ACTIONS_UPDATE_DRAFT_OFFLINE_SUCCESS,
                            meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                        });
                    })
                    .catch((offlineErr) => {
                        dispatch({
                            type: FORM_ACTIONS.FORM_ACTIONS_UPDATE_DRAFT_OFFLINE_FAILURE,
                            meta: { messageType: MESSAGE_TYPE.ERROR, message: offlineErr.message, exception: offlineErr }
                        });
                    });
            } else {
                dispatch({
                    type: FORM_ACTIONS.FORM_ACTIONS_UPDATE_DRAFT_ONLINE_FAILURE,
                    meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                });
            }
        });
};
