import { labelWidth } from '../P710/P710.data';

export const hmiPlaceholderName = 'Select HMI Switch Status';
export const phyPlaceholderName = 'Select Physical Status';
export const HMISectionDataP8141 = (formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F100HmiswitchStatusPriorToTestLabel',
            label: 'HMI Switch Status Prior to Test',
            xs: 4,
            isLabel: true,
            fontWeight: '600',
            fontSize: '9pt',
            padding: '18px 0 0 0'
        },
        {
            key: 'F100HmiswitchStatusPriorToTest',
            value: formData.F100HmiswitchStatusPriorToTest,
            isDropdown: true,
            placeholder: hmiPlaceholderName,
            options: [
                { name: hmiPlaceholderName, value: '' },
                { name: 'Activated', value: 'Activated' },
                { name: 'Deactivated', value: 'Deactivated' }],
            width: '100%',
            labelWidth: '0%',
            xs: 2,
            isRequired: true,
            inputTextAlign: 'center',
            marginBottom: '15px',
            marginTop: '15px'
        },
        {
            key: 'F450PhysicalSwitchStatusPriorToTestLabel',
            label: 'Physical Switch Status Prior to Test',
            xs: 4,
            isLabel: true,
            fontWeight: '600',
            labelWidth,
            fontSize: '9pt',
            textAlign: 'right',
            padding: '18px 5px 0 0'
        },
        {
            key: 'F450PhysicalSwitchStatusPriorToTest',
            value: formData.F450PhysicalSwitchStatusPriorToTest,
            isDropdown: true,
            placeholder: phyPlaceholderName,
            options: [
                { name: phyPlaceholderName, value: '' },
                { name: 'Activated', value: 'Activated' },
                { name: 'Deactivated', value: 'Deactivated' }],
            width: '100%',
            labelWidth: '0%',
            xs: 2,
            inputTextAlign: 'center',
            marginBottom: '15px',
            marginTop: '15px',
        }]
    }]
});
