// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
// const ua = window.navigator.userAgent;
// const msie = ua.indexOf('MSIE ');
// const msie11 = ua.indexOf('Trident/');
// const msedge = ua.indexOf('Edge/');
// const firefox = ua.indexOf('Firefox');
// const isIE = msie > 0 || msie11 > 0;
// const isEdge = msedge > 0;
// const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENTID,
        clientSecret: process.env.REACT_APP_CLIENTSECRET,
        authority: 'https://login.microsoftonline.com/ea80952e-a476-42d4-aaf4-5457852b0f7e',
        redirectUri: process.env.REACT_APP_BASE_URL,
        postLogoutRedirectUri: null,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ['user.read']
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
    getGroupsAssociatedWithUser: 'https://graph.microsoft.com/v1.0/users/{id}/checkMemberGroups'
};

// API Authentication Parameters
export const apiAuthParameters = {
    scopes: [
        process.env.REACT_APP_API_SCOPE
    ]
};
