import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const FoundResultAsFoundResultFormula = (formData) => {
    if (
        FieldHasValue(formData.F300SwitchSetpoint)
        && FieldHasValue(formData.F181AftTestEquipmentIndication)
        && FieldHasValue(formData.F182AftHmireading)
        && FieldHasValue(formData.F101AftTolerance)) {
        if (ValidateNumberField(formData.F183AftDeviation)
            <= ValidateNumberField(formData.F101AftTolerance) && (formData.F182AftHmireading === 'ALARM')) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
