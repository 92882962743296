import { AsLeftResultFormulaM63andM64 } from './M63.formulas';
import { notRequiredVal } from '../../../DetectorLenseSection/DetectorLense.data';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';

export const AsLeftTestTableSectionDataM63 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'As-LeftTest',
                label: 'As-Left Test (in field repair attempt)',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 5px 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F413AltConfirmTestTorch',
                    label: 'Confirm that test torch has been shined into the detector',
                    value: formData.F376CleanDetectorLensesIfVisiblyDirty !== notRequiredVal ? formData.F413AltConfirmTestTorch : '',
                    isRequired: formData.F376CleanDetectorLensesIfVisiblyDirty !== notRequiredVal,
                    isDisabled: (formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal),
                    readOnly: (formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal),
                    xs: 6,
                    labelWidth: '57.5%',
                    width: (formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal) ? '41%' : '42.5%',
                    isDropdown: formData.F376CleanDetectorLensesIfVisiblyDirty !== notRequiredVal,
                    placeholder: dropdownPlaceholder,
                    options: [{ name: dropdownPlaceholder, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    marginLeft: '8px',
                    marginRight: '0.5rem',
                    inputTextAlign: 'center',
                    height: '1.96rem',
                    borderBottom: '0',
                    paddingPlaceholder: '4px',
                    arrowMarginTop: '4px'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F378AltHmistatus1',
                    label: 'HMI Status',
                    value: formData.F376CleanDetectorLensesIfVisiblyDirty !== notRequiredVal ? formData.F378AltHmistatus1 : '',
                    isRequired: formData.F376CleanDetectorLensesIfVisiblyDirty !== notRequiredVal,
                    isDisabled: (formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal),
                    readOnly: (formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal),
                    isDropdown: formData.F376CleanDetectorLensesIfVisiblyDirty !== notRequiredVal,
                    placeholder: 'Select HMI Status',
                    options: [{ name: 'Select HMI Status', value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'FAULT', value: 'FAULT' }],
                    height: (formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal) ? '1.3rem' : '1.5rem',
                    labelWidth: '57.5%',
                    width: (formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal) ? '41%' : '42.5%',
                    marginLeft: '8px',
                    marginRight: '0.5rem',
                    inputTextAlign: 'center',
                    xs: 6,
                    borderBottom: '0'
                },
                {
                    key: 'nodata',
                    isLabel: true,
                    xs: 1
                },
                {
                    key: 'F103AltResult',
                    label: 'As-Left Result',
                    value: formData.F376CleanDetectorLensesIfVisiblyDirty !== notRequiredVal ? AsLeftResultFormulaM63andM64(formData) : '',
                    isDisabled: (formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal),
                    readOnly: true,
                    xs: 5,
                    width: '55%',
                    labelWidth: '45%',
                    isCalculatedNew: true,
                    textAlign: 'center',
                    height: '1.1rem',
                    borderRight: '0',
                    marginRight: '8px',
                    direction: 'rtl',
                    inputMarginRight: '4px',
                    isLabelBold: true,
                    fontWeight: '700'
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F414AltConfirmCctvvideoOnHmi',
                    label: 'Confirm that CCTV video image appeared on HMI (or dedicated screen)',
                    value: formData.F376CleanDetectorLensesIfVisiblyDirty !== notRequiredVal ? formData.F414AltConfirmCctvvideoOnHmi : '',
                    isDisabled: (formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal),
                    isDropdown: formData.F376CleanDetectorLensesIfVisiblyDirty !== notRequiredVal,
                    readOnly: (formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal),
                    xs: 6,
                    labelWidth: '57.5%',
                    width: (formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal) ? '41%' : '42.5%',
                    marginLeft: '8px',
                    marginRight: '0.5rem',
                    placeholder: dropdownPlaceholder,
                    options: [{ name: dropdownPlaceholder, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    inputTextAlign: 'center',
                    marginBottom: '4px',
                    borderBottom: '0',
                    height: '2rem',
                    paddingPlaceholder: '5px',
                    arrowMarginTop: '5px'
                }]
        }
    ]
});
