export const keyName = [
    {
        'P7-20': ['AltVibrationAmplitude',
            'AltVibrationFrequency',
            'AltSensorOutput',
            'AltSystemReading',
            'AltHmiReading']
    },
    {
        'P6-26': ['AltVibrationAmplitude',
            'AltVibrationFrequency',
            'AltSensorOutput',
            'AltSystemReading',
            'AltHmiReading']
    },
    {
        'P7-21': ['AltSimulatedGap',
            'AltExpectedOutput',
            'AltMeasuredReading',
            'AltHmiReading',
            'AltSystemReading']
    }, {
        'M6-10': [
            'AltSmokeConfirmation',
            'AltHmiStatus',
            'AltRecordTransitTime'
        ]
    }
];
