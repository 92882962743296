import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { FormDropdown } from 'elements/Dropdown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import { Colors } from 'helpers/Colors';
import { useDispatch } from 'react-redux';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useMsal, useAccount } from '@azure/msal-react';
import { isMobile as isTablet } from 'react-device-detect';
import { DropDownElement, ArrowElement } from './index.styled';
import { setCurrentView, saveUsersGroupDetails } from '../Home/Home.actions';
import { getUserRole } from './UserRole';

const UserRoleSelector = (props) => {
    const dispatch = useDispatch();
    const appInsights = useAppInsightsContext();
    const { accounts } = useMsal();
    const history = useHistory();
    const account = useAccount(accounts[0] || {});

    const [appInsightUserDetails, setAppInsightUserDetails] = useState({ userName: '', groupName: '', userRole: '', userEmailId: '' });
    const [userRoleId, setUserRoleId] = useState('');
    const arrowColor = !userRoleId ? Colors?.grey : Colors?.green;
    const handleChange = (value) => {
        setUserRoleId(value.userRoleId);
        trackAIUserDetails(value.userRoleId);
    };

    const trackAIUserDetails = (value) => {
        const userGroupInfo = props.userGroups.find(group => group.Id === value);
        setAppInsightUserDetails(() => {
            appInsightUserDetails.userName = account.name;
            appInsightUserDetails.groupName = userGroupInfo.Name;
            appInsightUserDetails.userRole = getUserRole(userGroupInfo.RoleId);
            appInsightUserDetails.userEmailId = account.username;
            return appInsightUserDetails;
        });
    };

    const checkAdminRole = (id) => {
        const role = getUserRole(id);
        return role.includes('Admin');
    };

    const onNextClick = () => {
        const roleId = props.userGroups.filter(group => group.Id === userRoleId)[0].RoleId;
        dispatch(saveUsersGroupDetails([userRoleId])).then(() => {
            if (checkAdminRole(roleId)) {
                dispatch(setCurrentView('admin'));
                history.push('/admin');
            } else {
                dispatch(setCurrentView('home'));
            }
        });
        appInsights.trackEvent({ name: 'user_Details', properties: { appInsightUserDetails } });
    };

    const validRoleOptions = () => !isTablet ? props.userGroups.map(validGroup => ({
        name: validGroup.Name,
        value: validGroup.Id
    }))
        : props.userGroups.filter(data => !getUserRole(data.RoleId).includes('Admin'))
            .map(validGroup => ({
                name: validGroup.Name,
                value: validGroup.Id
            }));

    return (
        <SiteWrapper
            headerText={
                (
                    <div style={{ textAlign: 'center' }}>
                        Support User Role Selection
                    </div>
                )
            }
        >
            <div className="container">
                <Grid container>
                    <Grid container item xs={12}>
                        <Grid item xs={12} style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', marginTop: '5%' }}>
                                <DropDownElement>
                                    <FormDropdown
                                        width="38rem"
                                        name="userRoleId"
                                        value={userRoleId}
                                        handleChange={handleChange}
                                        options={validRoleOptions()}
                                        className="BigSize"
                                        noDefaultOption
                                        marginLeft="0"
                                        menuMarginLeft="0"
                                        placeholder="Select a role"
                                    />
                                </DropDownElement>
                                <ArrowElement>
                                    <IconButton component="span" onClick={onNextClick} disabled={!userRoleId}>
                                        <ArrowForwardIosIcon
                                            id="ActionButtonArrow"
                                            style={{
                                                fontSize: '4.5rem',
                                                fill: arrowColor,
                                                color: Colors.green,
                                                width: '55px',
                                                height: '57px'
                                            }}
                                        />
                                    </IconButton>
                                </ArrowElement>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </SiteWrapper>
    );
};

export default UserRoleSelector;

UserRoleSelector.propTypes = {
    userGroups: PropTypes.array
};
