import styled from 'styled-components';

export const HeaderButton = styled.button`
    width: ${props => props.buttonWidth ? props.buttonWidth.toString().concat('px !important') : '105px'};
    height: ${props => props.buttonHeight ? props.buttonHeight.toString().concat('px !important') : '80px'};
    :hover {
        background-color: #335B03;
        border-color: transparent
    }
`;
