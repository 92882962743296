export const sraPlaceHolder = 'Select Yes or No';

export const SraDeviceSection = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'NodataP81',
            xs: 6,
            isLabel: true
        },
        {
            label: 'YES/NO',
            isLabel: true,
            xs: 2,
            fontWeight: 700,
            labelWidth: '94%',
            textAlign: 'center'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'F326istheSraontheIcssalarmAndEventLogChronicleLabel',
            label: '1. Is the SRA on the ICSS Alarm & Event Log/ Chronicle?',
            labelWidth: '100%',
            xs: 6,
            fontWeight: 600,
            fontSize: '9pt',
            padding: '5px 0 0 0',
            isLabel: true
        },
        {
            key: 'F326istheSraontheIcssalarmAndEventLogChronicle',
            value: formData.F326istheSraontheIcssalarmAndEventLogChronicle,
            isRequired: true,
            isDropdown: true,
            placeholder: sraPlaceHolder,
            options: [{ name: sraPlaceHolder, value: '' }, { name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }],
            xs: 2,
            labelWidth: '0',
            inputTextAlign: 'center',
            width: '95%'
        }]
    },
    {
        key: 3,
        fields: [{
            key: 'F327DoesTheIcssAlarmTagOrDescriptioncontainTheTextSraLabel',
            label: '2. Does the ICSS alarm tag or description contain the text "SRA"?',
            labelWidth: '100%',
            xs: 6,
            fontWeight: 600,
            fontSize: '9pt',
            padding: '5px 0 0 0',
            isLabel: true
        },
        {
            key: 'F327DoesTheIcssAlarmTagOrDescriptioncontainTheTextSra',
            value: formData.F327DoesTheIcssAlarmTagOrDescriptioncontainTheTextSra,
            isRequired: true,
            isDropdown: true,
            placeholder: sraPlaceHolder,
            options: [{ name: sraPlaceHolder, value: '' }, { name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }],
            xs: 2,
            borderTop: '0',
            width: '95%',
            inputTextAlign: 'center',
            labelWidth: '0'
        }]
    },
    {
        key: 4,
        fields: [{
            key: 'F328IsTheIcssAlarmPriorityAsPerAlarmResponseManualLabel',
            label: '3. Is the ICSS alarm priority as per Alarm Response Manual?',
            labelWidth: '100%',
            xs: 6,
            fontWeight: 600,
            fontSize: '9pt',
            padding: '5px 0 0 0',
            isLabel: true
        },
        {
            key: 'F328IsTheIcssAlarmPriorityAsPerAlarmResponseManual',
            value: formData.F328IsTheIcssAlarmPriorityAsPerAlarmResponseManual,
            isRequired: true,
            isDropdown: true,
            placeholder: sraPlaceHolder,
            options: [{ name: sraPlaceHolder, value: '' }, { name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }],
            xs: 2,
            borderTop: '0',
            labelWidth: '0',
            inputTextAlign: 'center',
            width: '95%',
        }]
    },
    {
        key: 5,
        fields: [{
            key: 'F329IsTheSraClearlyIdentifiedonTheHmiLabel',
            label: '4. Is the SRA clearly identified on the HMI? e.g. labelled as LoP, SRA, P8',
            labelWidth: '100%',
            xs: 6,
            fontWeight: 600,
            fontSize: '9pt',
            padding: '5px 0 0 0',
            isLabel: true
        },
        {
            key: 'F329IsTheSraClearlyIdentifiedonTheHmi',
            value: formData.F329IsTheSraClearlyIdentifiedonTheHmi,
            isRequired: true,
            isDropdown: true,
            placeholder: sraPlaceHolder,
            options: [{ name: sraPlaceHolder, value: '' }, { name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }],
            xs: 2,
            borderTop: '0',
            labelWidth: '0',
            inputTextAlign: 'center',
            width: '95%',
        }]
    },
    {
        key: 6,
        fields: [{
            key: 'F330DoesTheOperatorHaveKnowledgeOfTheResponseRequiredLabel',
            label: '5. Does the Operator have knowledge of the response required?',
            labelWidth: '100%',
            xs: 6,
            fontWeight: 600,
            fontSize: '9pt',
            padding: '5px 0 0 0',
            isLabel: true
        },
        {
            key: 'F330DoesTheOperatorHaveKnowledgeOfTheResponseRequired',
            value: formData.F330DoesTheOperatorHaveKnowledgeOfTheResponseRequired,
            isRequired: true,
            isDropdown: true,
            placeholder: sraPlaceHolder,
            options: [{ name: sraPlaceHolder, value: '' }, { name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }],
            xs: 2,
            borderTop: '0',
            labelWidth: '0',
            inputTextAlign: 'center',
            width: '95%'
        }]
    },
    {
        key: 7,
        fields: [{
            key: 'F331IsTheArmReadilyAvialableToTheOperatorLabel',
            label: '6. Is the ARM readily available to the Operator?',
            labelWidth: '100%',
            xs: 6,
            fontWeight: 600,
            fontSize: '9pt',
            padding: '5px 0 0 0',
            isLabel: true
        },
        {
            key: 'F331IsTheArmReadilyAvialableToTheOperator',
            value: formData.F331IsTheArmReadilyAvialableToTheOperator,
            isRequired: true,
            isDropdown: true,
            placeholder: sraPlaceHolder,
            options: [{ name: sraPlaceHolder, value: '' }, { name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }],
            xs: 2,
            borderTop: '0',
            labelWidth: '0',
            inputTextAlign: 'center',
            width: '95%'
        }]
    },
    {
        key: 8,
        fields: [{
            key: 'F332IsTheResponseInTheArmAdequateLabel',
            label: '7. Is the response in the ARM adequate?',
            labelWidth: '100%',
            xs: 6,
            fontWeight: 600,
            fontSize: '9pt',
            padding: '5px 0 0 0',
            isLabel: true
        },
        {
            key: 'F332IsTheResponseInTheArmAdequate',
            value: formData.F332IsTheResponseInTheArmAdequate,
            isRequired: true,
            isDropdown: true,
            placeholder: sraPlaceHolder,
            options: [{ name: sraPlaceHolder, value: '' }, { name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }],
            xs: 2,
            borderTop: '0',
            labelWidth: '0',
            inputTextAlign: 'center',
            width: '95%'
        }]
    },
    {
        key: 9,
        fields: [{
            key: 'F333IsTimeToRespondLessThan10minutesLabel',
            label: '8. Is time to respond less than 10 minutes? ',
            labelWidth: '100%',
            xs: 6,
            fontWeight: 600,
            fontSize: '9pt',
            padding: '5px 0 0 0',
            isLabel: true
        },
        {
            key: 'F333IsTimeToRespondLessThan10minutes',
            value: formData.F333IsTimeToRespondLessThan10minutes,
            isRequired: true,
            isDropdown: true,
            placeholder: sraPlaceHolder,
            options: [{ name: sraPlaceHolder, value: '' }, { name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }],
            xs: 2,
            borderTop: '0',
            labelWidth: '0',
            inputTextAlign: 'center',
            width: '95%'
        }]
    }]
});

export const SelectSraDeviceSection = (selectedTag, formName, formData) => {
    let filterViewData = { rows: [] };
    if (formName === 'P8-1') {
        filterViewData = SraDeviceSection(selectedTag, formData);
    }
    return filterViewData;
};
