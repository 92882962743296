 import { testEquipmentInitialState } from './TestEquipment.data';
 import { TEST_ACTIONS } from './TestEquipment.constants';

export default function TestEquipmentReducer (state = testEquipmentInitialState, action) {
    switch (action.type) {
        case TEST_ACTIONS.GET_TEST_RECORDS_COUNT:
        case TEST_ACTIONS.RESET_TEST_TOTAL_COUNT:
        case TEST_ACTIONS.GET_SEARCH_TEST_RECORDS_PENDING:
        case TEST_ACTIONS.GET_SEARCH_TEST_RECORDS_FAILURE:
        case TEST_ACTIONS.GET_SEARCH_TEST_RECORDS_SUCCESS:
        case TEST_ACTIONS.GET_SORT_TEST_RECORDS_PENDING:
        case TEST_ACTIONS.GET_SORT_TEST_RECORDS_FAILURE:
        case TEST_ACTIONS.GET_SORT_TEST_RECORDS_SUCCESS:
        case TEST_ACTIONS.ADDTESTEQUIPMENT_SET_INITIALDATA:
        case TEST_ACTIONS.ADDTESTEQUIPMENT_RESET_DATA:
        case TEST_ACTIONS.EDITTESTEQUIPMENT_SET_EXISTINGDATA:
        case TEST_ACTIONS.EDITTESTEQUIPMENT_RESET_DATA:
        case TEST_ACTIONS.ADD_TESTEQUIPMENTRECORD_PENDING:
        case TEST_ACTIONS.ADD_TESTEQUIPMENTRECORD_SUCCESS:
        case TEST_ACTIONS.ADD_TESTEQUIPMENTRECORD_FAILURE:
        case TEST_ACTIONS.EDIT_TESTEQUIPMENTRECORD_PENDING:
        case TEST_ACTIONS.EDIT_TESTEQUIPMENTRECORD_SUCCESS:
        case TEST_ACTIONS.EDIT_TESTEQUIPMENTRECORD_FAILURE:
        case TEST_ACTIONS.UPDATETESTEQUIPMENT_UNSAVED_CHANGES_LABEL:
        case TEST_ACTIONS.DISPLAY_DEVICETYPE_SORTORDER:
        case TEST_ACTIONS.DISPLAY_STATUS_SORTORDER:
        case TEST_ACTIONS.GET_TEST_MAKE_MODEL_DATA_SUCCESS:
        case TEST_ACTIONS.GET_TEST_MAKE_MODEL_DATA_FAILURE:
        return { ...state, ...action.payload };
        case TEST_ACTIONS.ADDTESTEQUIPMENT_UPDATE_DATA:
            return {
                ...state,
                addTestEquipmentData: {
                    ...state.addTestEquipmentData,
                    ...action.payload
                }
            };
        case TEST_ACTIONS.EDITTESTEQUIPMENT_UPDATE_DATA:
            return {
                ...state,
                editTestEquipmentData: {
                    ...state.editTestEquipmentData,
                    ...action.payload
                }
            };
        case TEST_ACTIONS.EDIT_TESTEQP_SET_EXISTINGDATA:
        case TEST_ACTIONS.EDIT_TEST_RECORD_PENDING:
        case TEST_ACTIONS.EDIT_TEST_RECORD_SUCCESS:
        case TEST_ACTIONS.EDIT_TEST_RESET_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
