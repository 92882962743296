export const APIEndpoints = {
    HOME_GetBPRegionAssets: `${process.env.REACT_APP_API_BASE_URL}/api/HomePage/GetBPRegionAssets`,
    HOME_GetAllBarrierTypes: `${process.env.REACT_APP_API_BASE_URL}/api/HomePage/GetAllBarrierTypes`,
    HOME_GetAllWorkTypes: `${process.env.REACT_APP_API_BASE_URL}/api/HomePage/GetAllWorkTypes`,
    HOME_GetTrfMasterLists: `${process.env.REACT_APP_API_BASE_URL}/api/HomePage/GetTrfMasterLists`,
    GetDPSSourceMappings: `${process.env.REACT_APP_API_BASE_URL}/api/HomePage/GetDPSSourceMappings`,
    DPS_AddEditDps: `${process.env.REACT_APP_API_BASE_URL}/api/HomePage/AddEditDps/`,
    DPS_GetAssetDPSList: `${process.env.REACT_APP_API_BASE_URL}/api/homepage/GetAssetDPSList/`,
    GetAssetTestMakeModel: `${process.env.REACT_APP_API_BASE_URL}/api/Offline/GetAssetTestMakeModel`,
    GetMratGroupMappings: `${process.env.REACT_APP_API_BASE_URL}/api/Offline/GetMratGroupMappings`,
    GetInformation: `${process.env.REACT_APP_API_BASE_URL}/api/Offline/GetInformation`,
    GetVmSearchMo: `${process.env.REACT_APP_API_BASE_URL}/api/Offline/GetVmSearchMo`,
    GetTestMakeModel: `${process.env.REACT_APP_API_BASE_URL}/api/Offline/GetTestMakeModel`,
    GetVwSearchTag: `${process.env.REACT_APP_API_BASE_URL}/api/Offline/GetVwSearchTag`,
    GetVwSearchTagBi: `${process.env.REACT_APP_API_BASE_URL}/api/Offline/GetVwSearchTagBi`,
    GetITFForm: `${process.env.REACT_APP_API_BASE_URL}/api/Offline/GetITFForm`,
    SubmitFormEndpoint: `${process.env.REACT_APP_API_BASE_URL}/api/offline/submitForms`,
    SaveTestEq: `${process.env.REACT_APP_API_BASE_URL}/api/offline/SaveTestEq`,
    GetImages: `${process.env.REACT_APP_API_BASE_URL}/api/offline/GetImages`,
    SubmitImages: `${process.env.REACT_APP_API_BASE_URL}/api/offline/SubmitImages`,
    DeleteImages: `${process.env.REACT_APP_API_BASE_URL}/api/offline/DeleteImages`,
    SubmitTRFImages: `${process.env.REACT_APP_API_BASE_URL}/api/offline/SaveTRFImage`,
    SubmitExistingTRFImages: `${process.env.REACT_APP_API_BASE_URL}/api/offline/UpdateTRFImage`,
    EditImageNote: `${process.env.REACT_APP_API_BASE_URL}/api/offline/EditImages`,
    GetTRFImages: `${process.env.REACT_APP_API_BASE_URL}/api/offline/GetTRFImages`,
    GetTRFImagesForPrinting: `${process.env.REACT_APP_API_BASE_URL}/api/offline/GetTRFImagesForPrinting`,
    GetSavedTRFPrintScreenDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Offline/GetHistoricalPrintScreenDetails`,
    GetMratSecurityGroupById: `${process.env.REACT_APP_API_BASE_URL}/api/HomePage/GetMratSecurityGroupById`,
    GetMratSecurityGroupInfo: `${process.env.REACT_APP_API_BASE_URL}/api/HomePage/GetMratSecurityGroupInfo`,
    GetAllRegionDisclaimer: `${process.env.REACT_APP_API_BASE_URL}/api/HomePage/GetAllRegionDisclaimer`,
    ADMIN_GetTrfMasterLists: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetTrfMasterListsAdmin`,
    ADMIN_SubmitTrfID: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/SubmitTRFID`,
    ADMIN_UpdateTrfID: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateTRFID`,
    ADMIN_GetDpsMappingDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetDpsMappingDetails`,
    ADMIN_AddDpsMappingRecord: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddDpsMappingRecord`,
    ADMIN_UpdateDpsMappingRecord: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateDpsMappingRecord`,
    ADMIN_GetColumnNames: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetColumnNames`,
    ADMIN_GetTableNames: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetTableNames`,
    ADMIN_GetInputFieldTypes: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetInputFieldTypes`,
    ADMIN_GetUserInputTypes: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetUserInputTypes`,
    ADMIN_GetApplicableRegions: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetApplicableRegions`,
    ADMIN_GetMratSecurityGroups: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetMRATSecurityGroupAdmin`,
    ADMIN_GetAdGroupType: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetAdGroupTypeAdmin`,
    ADMIN_GetRole: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetRoleAdmin`,
    ADMIN_SubmitSecurityGroupRecord: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/SubmitSecurityGroupRecord`,
    ADMIN_UpdateMRATSecurityGroupRecord: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateMRATSecurityGroupRecord`,
    ADMIN_AddGroupMappingRecord: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddGroupMappingRecord`,
    ADMIN_UpdateGroupMappingRecord: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateGroupMappingRecord`,
    ADMIN_GetAssetImageDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetAssetImageDetails`,
    ADMIN_AddInputFieldTypeDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddInputFieldTypeRecord`,
    ADMIN_UpdateInputFieldTypeDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateInputFieldTypeRecord`,
    ADMIN_AddRole: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddRole`,
    ADMIN_UpdateRole: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateRole`,
    ADMIN_SubmitUserInputTypeRecord: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddUserInputTypeRecord`,
    ADMIN_UpdateUserInputTypeRecord: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateUserInputTypeRecord`,
    ADMIN_AddAdGroupType: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddAdGroupType`,
    ADMIN_UpdateAdGroupType: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateAdGroupType`,
    ADMIN_GetBpRegionDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetBpRegionDetails`,
    ADMIN_AddBpRegionDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddBpRegionDetails`,
    ADMIN_UpdateBpRegionDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateBpRegionDetails`,
    ADMIN_GetDpsFields: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetDpsField`,
    ADMIN_AddDpsfield: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddDpsfield`,
    ADMIN_UpdateDpsfield: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateDpsfield`,
    ADMIN_GetDPSDataSources: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetDPSDataSources`,
    ADMIN_AddDPSDataSources: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddDPSDataSources`,
    ADMIN_UpdateDPSDataSources: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateDPSDataSources`,
    ADMIN_GetDPSsourceMapping: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetDPSsourceMapping`,
    ADMIN_AddDpsSourceMapping: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddDPSsourceMapping`,
    ADMIN_UpdateDpsSourceMapping: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateDPSsourceMapping`,
    ADMIN_GetCorrectiveDPSMapping: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetCorrectiveDPSMapping`,
    ADMIN_AddCorrectiveDPSMapping: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddCorrectiveDPSMapping`,
    ADMIN_UpdateCorrectiveDPSMapping: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateCorrectiveDPSMapping`,
    InfoDocuments: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetInformationData`,
    ADMIN_AddInformation: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddInformation`,
    ADMIN_UpdateInformation: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateInformation`,
    ADMIN_GetMultiTRFDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetMultiTrfDetails`,
    ADMIN_AddMultiTRFDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddMultiTrfDetails`,
    ADMIN_UpdateMultiTRFDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateMultiTrfDetails`,
    UpdateDraftTrackingDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Offline/UpdateDraftTrackingDetails`,
    GetDraftTrackingDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Offline/GetDraftTrackingDetails`,
    SaveDraftTrackingDetails: `${process.env.REACT_APP_API_BASE_URL}/api/Offline/SaveDraftTrackingDetails`,
    DPSUpload: `${process.env.REACT_APP_API_BASE_URL}/api/HomePage/DPSUpload`,
    ADMIN_GetDPSUploadlog: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetDPSUploadlog`,
    ADMIN_AddDPSUploadlog: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddDPSUploadlog`,
    ADMIN_GetAdfPipelineParameter: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetAdfPipelineParameter`,
    ADMIN_AddAdfPipelineParameter: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddAdfPipelineParameter`,
    ADMIN_UpdateAdfPipelineParameter: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateAdfPipelineParameter`,
    ADMIN_GetProdMultiroleLoginExclusion: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/GetProdMultiroleLoginExclusion`,
    ADMIN_AddProdMultiroleLoginExclusion: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/AddProdMultiroleLoginExclusion`,
    ADMIN_UpdateProdMultiroleLoginExclusion: `${process.env.REACT_APP_API_BASE_URL}/api/Admin/UpdateProdMultiroleLoginExclusion`,

};

export const nonCachableEndpoints = {
    DPS_GetAssetDPSList: APIEndpoints.DPS_GetAssetDPSList
};

export const NetwotkFirstEndpoints = {
    HOME_GetAllBarrierTypes: APIEndpoints.HOME_GetAllBarrierTypes,
    HOME_GetAllWorkTypes: APIEndpoints.HOME_GetAllWorkTypes,
    HOME_GetTrfMasterLists: APIEndpoints.HOME_GetTrfMasterLists,
    GetVmSearchMo: APIEndpoints.GetVmSearchMo
};
