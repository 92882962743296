import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const isSelfPortableTest = (formData) => formData.F430TestMethodTypeUsed === 'SELF/PORTABLE TEST';
export const AFTSoundPressureFormula = (formData) => {
    if (ValidateNumberField(formData.F416AftSelectTestGain) === 1) {
        return '59';
    }
    if (ValidateNumberField(formData.F416AftSelectTestGain) === 2) {
        return '64';
    }
    if (ValidateNumberField(formData.F416AftSelectTestGain) === 3) {
        return '69';
    }
    if (ValidateNumberField(formData.F416AftSelectTestGain) === 4) {
        return '74';
    }
    if (ValidateNumberField(formData.F416AftSelectTestGain) === 5) {
        return '79';
    }
    if (ValidateNumberField(formData.F416AftSelectTestGain) === 6) {
        return '84';
    }
    if (ValidateNumberField(formData.F416AftSelectTestGain) === 7) {
        return '89';
    }
    if (ValidateNumberField(formData.F416AftSelectTestGain) === 8) {
        return '94';
    }
    if (ValidateNumberField(formData.F416AftSelectTestGain) === 9) {
        return '99';
    }
    return '';
};

export const AsFoundResultM65Formula = (formData) => {
    if (FieldHasValue(formData.F820TestToolActivationAft) && (formData.F820TestToolActivationAft === 'NO')) {
            return 'FAIL';
    }
    if (isSelfPortableTest(formData) && FieldHasValue(formData.F372AftHmistatus1) && FieldHasValue(formData.F820TestToolActivationAft)) {
        if (formData.F820TestToolActivationAft === 'YES' && formData.F372AftHmistatus1 === 'ALARM') {
            return 'PASS';
        }
        return 'FAIL';
    }
    if (FieldHasValue(formData.F372AftHmistatus1) && FieldHasValue(formData.F418AftSoundPressureLevel2)
        && FieldHasValue(formData.F417AftSoundPressureLevel1) && FieldHasValue(formData.F31FailTolerance)) {
        if (Math.abs(ValidateNumberField(formData.F417AftSoundPressureLevel1) - ValidateNumberField(formData.F418AftSoundPressureLevel2))
            <= ValidateNumberField(formData.F31FailTolerance) && FieldHasValue(formData.F372AftHmistatus1 === 'ALARM')) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
