import { DEFAULT_WORK_TYPE } from './Home.constants';

export const homeInitialState = {
    previousScreen: '',
    currentView: 'splash',
    regionDetails: [],
    bpRegionName: '',
    regionName: '',
    assetNameList: [],
    selectedAssetName: '',
    selectedGroupName: '',
    workTypeList: [],
    selectedWorkType: DEFAULT_WORK_TYPE.toLowerCase(),
    allBarrierTypeList: [],
    barrierTypeList: [],
    selectedBarrierType: '',
    tags: [],
    tagsBi: [],
    syncInvalidationStatus: 0,
    isOfflineDataPresent: false,
    syncButtonStatus: false,
    selectedAssetAliasName: '',
    bpRegionNameAlias: '',
    isDataLoaded: false,
    adGroupType: null,
    draftStatus: false,
    draftDuration: '',
    draftNotice: '',
};
