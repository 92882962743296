import { ValidateField } from 'helpers/Validation';

export const TestResultSectionDataP75 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F82HmiReadingAt',
                label: 'HMI Reading after Test',
                value: ValidateField(formData.F82HmiReadingAt),
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 4,
                labelWidth: '50%',
                width: '50%',
                direction: 'rtl',
                textAlign: 'center'
            },
            {
                key: 'Nodata',
                isLabel: true,
                xs: 1
            },
            {
                key: 'ProcessTXReadingAtLabel',
                label: 'Process TX Reading after Test',
                labelWidth: '97%',
                xs: 3,
                height: '1.2rem',
                padding: '5px 0 0 0',
                isLabel: 'true',
                textAlign: 'end',
                fontSize: '9pt',
                fontWeight: '600'
            },
            {
                key: 'F83ProcessTxReadingAt',
                value: ValidateField(formData.F83ProcessTxReadingAt),
                isRequired: true,
                labelWidth: '0',
                xs: 4,
                height: '1.2rem',
                textAlign: 'center',
                width: '100%',
                type: 'number',
                maxLength: '10',
                marginLeft: '0.8px'
            }]
        }]
});
