export const TestResultSectionDataP61 = (selectedTag, formData, formName) => {
    let hmistatusAfterTest;
    let processGaugeAT;
    if (formName === 'P6-1') {
        hmistatusAfterTest = 'HMI Reading after Test';
        processGaugeAT = 'Process Gauge Reading after Test';
    } else if (formName === 'P8-7.1') {
        hmistatusAfterTest = 'HMI Status After Test';
        processGaugeAT = 'Process Gauge Reading After Test';
    }
    return {
        rows: [
            {
                key: 5,
                fields: [
                    {
                        key: 'F82HmiReadingAt',
                        label: hmistatusAfterTest,
                        value: formData.F82HmiReadingAt,
                        isRequired: true,
                        type: 'number',
                        maxLength: '10',
                        xs: 4,
                        labelWidth: '50%',
                        width: '50%',
                        direction: 'rtl',
                        textAlign: 'center'
                    },
                    {
                        key: 'Nodata',
                        isLabel: true,
                        xs: 1,
                    },
                    {
                        key: 'F83ProcessGaugeReadingAtLabel',
                        label: processGaugeAT,
                        labelWidth: '97%',
                        xs: 3,
                        height: '1.2rem',
                        padding: '5px 0 0 0',
                        isLabel: 'true',
                        textAlign: 'end',
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F83ProcessGaugeReadingAt',
                        value: formData.F83ProcessGaugeReadingAt,
                        type: 'number',
                        maxLength: '10',
                        isRequired: true,
                        labelWidth: '0',
                        xs: 4,
                        height: '1.2rem',
                        textAlign: 'center',
                        width: '100%',
                        marginLeft: '0.8px'
                    }]
            }]
    };
};
