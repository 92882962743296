import { ValidateField, FieldHasValue, ExponentialToDecimal, ValidateNumberField } from 'helpers/Validation';

export const AsLeftF215AftDeviation1Formula = (formData) => FieldHasValue(formData.F502AltReferenceCalibrationGas)
    && FieldHasValue(formData.F357AnalyzerReading)
    ? ExponentialToDecimal(Math.abs(ValidateField(formData.F357AnalyzerReading) - ValidateField(formData.F502AltReferenceCalibrationGas))) : '';

export const AsLeftResultResultFormula = (formData) => {
    if (FieldHasValue(formData.F237AltDeviation1)
        && FieldHasValue(formData.F101AftTolerance)) {
        if (ValidateNumberField(formData.F237AltDeviation1) > ValidateNumberField(formData.F101AftTolerance)) {
            return 'Fail';
        }
        return 'Pass';
    }
    return '';
};

export const CalibrationPoint1Formula = (formData) => 'Reference for Calibration Gas - '.concat(ValidateField(formData.F118InstrumentEngineeringUnits));
