import { ValidateNumberField, FieldHasValue } from 'helpers/Validation';

export const AsLeftResultFormula = (formData) => {
    if (
        FieldHasValue(formData.F434DetectorvaluewithTestGasAppliedALT)
        && FieldHasValue(formData.F426AltAppliedTestGasConcentration)
        && FieldHasValue(formData.F378AltHmistatus1)
        && FieldHasValue(formData.F31FailTolerance)) {
        if (Math.abs(ValidateNumberField(formData.F426AltAppliedTestGasConcentration) - ValidateNumberField(formData.F434DetectorvaluewithTestGasAppliedALT))
            <= ValidateNumberField(formData.F31FailTolerance) && formData.F378AltHmistatus1 === 'ALARM') {
                return 'PASS';
            }
        return 'FAIL';
    }
    return '';
};
