export const adGroupHeaderData = [
    {
        field: 'AdGroupTypeId',
        headerName: 'AD Group Type ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Code',
        headerName: 'Code (AD Group Type)',
        width: '170',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'Description',
        headerName: 'Description',
        width: '220',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '85',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '140',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '140',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const adGroupInitialState = {
    AdGroupTypeId: '',
    Code: '',
    Status: 'Inactive',
    Description: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const adGroupSearchState = {
    AdGroupTypeId: '',
    Code: '',
    Status: '',
    Description: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const adGroupApiEndpoint = {
    read: 'ADMIN_GetAdGroupType'
};
