import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormInput } from 'elements/Inputs/FormInput/';
import { FormInputViewOnly } from 'elements/Inputs/FormInput/FormInputViewOnly';
import { selectReCalibrationData } from './ReCalibration.data';
import { StyledLabel } from 'elements/Label/Label';
import { InputAutogrow } from 'elements/Inputs/FormInput/InputAutogrow/InputAutogrow';

const ReCalibrationSection = (props) => {
    const { selectedTag, formName, changeHandler, formData, isFormReadOnly, userRole } = props;
    const formInput = (field) => field.isAutoGrow
        ? (
            <InputAutogrow
                id={field.key}
                name={field.key}
                value={field.value}
                maxLength={field.maxLength}
                handleChange={changeHandler}
                inputWidth={field.inputWidth}
                width={field.labelWidth}
                label={field.label}
                marginBottom={field.marginBottom}
                marginLeft={field.marginLeft}
                direction={field.direction}
                marginRight={field.marginRight}
                padding={field.padding}
                isCalculatedNew={field.isCalculatedNew}
                inputMarginTop={field.inputMarginTop}
                readOnly={isFormReadOnly}
                isDisabled={field.readOnly}
                isRequired={field.isRequired}
                textAlign={field.textAlign}
                marginTop={field.marginTop}
            />
        ) : (
            <FormInput
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                color={field.color}
                isRequired={field.isRequired}
                isCalculated={field.isCalculated}
                isCalculatedNew={field.isCalculatedNew}
                textAlignLabel={field.textAlignLabel}
                textAlign={field.textAlign}
                handleChange={changeHandler}
                type={field.type}
                width={field.width}
                labelWidth={field.labelWidth}
                padding={field.padding}
                readOnly={field.readOnly || isFormReadOnly}
                maxLength={field.maxLength}
                direction={field.direction}
                marginBottom={field.marginBottom}
                marginRight={field.marginRight}
                marginLeft={field.marginLeft}
                borderTop={field.borderTop}
                borderBottom={field.borderBottom}
                borderLeft={field.borderLeft}
                borderRight={field.borderRight}
                height={field.height}
                marginTop={field.marginTop}
                userRole={userRole}
                hasDPSError={field.hasDPSError}
                hasHmiError={field.hasHmiError}
                hasInstError={field.hasInstError}
                hasDPSNotNullError={field.hasDPSNotNullError}
                isDisabled={field.isDisabled}
                inputWidth={field.inputWidth}
            />
        );
    const formLabel = (field) => field.isLabel
        ? (
            <StyledLabel
                id={field.key}
                fontWeight={field.fontWeight}
                fontSize={field.fontSize}
                textAlign={field.textAlign}
                labelWidth={field.labelWidth}
                padding={field.padding}
                direction={field.direction}
            >
                {field.label}
            </StyledLabel>
        ) : formInput(field);
    return (
        <div>
            {
                selectReCalibrationData(selectedTag, formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ padding: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isInfo ? (
                                    <FormInputViewOnly
                                        id={field.key}
                                        name={field.key}
                                        label={field.label}
                                        value={field.value}
                                        fontStyle={field.fontStyle}
                                        width={field.width}
                                        height={field.height}
                                        wordBreak={field.wordBreak}
                                        labelWidth={field.labelWidth}
                                        noWrap={field.noWrap}
                                        field={field.padding}
                                        padding={field.padding}
                                        marginBottom={field.marginBottom}
                                        textAlign={field.textAlign}
                                        marginTop={field.marginTop}
                                        labelFontStyle={field.labelFontStyle}
                                        valueMarginTop={field.valueMarginTop}
                                        marginLeft={field.marginLeft}
                                    />
                                ) : formLabel(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </div>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    dpsData: Forms.dpsData,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReCalibrationSection);

ReCalibrationSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
