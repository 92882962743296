import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';

export const TestResultSectionDataM612 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'DetectorReinstatement',
            label: 'Detector reinstatement',
            isLabel: true,
            xs: 12,
            fontWeight: '700',
        }]
    },
    {
        key: 2,
        fields: [
            {
                key: 'F382HmistatusAfterTestLabel',
                label: 'HMI Status after test',
                xs: 4,
                labelWidth: '100%',
                padding: '0',
                isLabel: true,
                fontSize: '9pt',
                fontWeight: '600'
            },
            {
                key: 'F382HmistatusAfterTest',
                value: formData.F382HmistatusAfterTest,
                isRequired: true,
                isDropDown: true,
                placeholder: Placeholder.HMI_STATUS_PLACEHOLDER,
                options: [{ name: Placeholder.HMI_STATUS_PLACEHOLDER, value: '' },
                { name: 'NORMAL', value: 'NORMAL' },
                { name: 'ALARM', value: 'ALARM' },
                { name: 'FAULT', value: 'FAULT' }],
                inputTextAlign: 'center',
                xs: 2,
                borderBottom: '0',
                labelWidth: '0',
                width: '100%',
                textAlign: 'left'
            }
        ]
    },
    {
        key: 3,
        fields: [
            {
                key: 'F409LocalIndicationLabel',
                label: 'Local Indication',
                xs: 4,
                labelWidth: '100%',
                padding: '0',
                isLabel: true,
                fontSize: '9pt',
                fontWeight: '600'
            },
            {
                key: 'F409LocalIndication',
                value: formData.F409LocalIndication,
                isRequired: true,
                isDropDown: true,
                placeholder: 'Select Local Indication',
                options: [
                    { name: 'Select Local Indication', value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'FAULT', value: 'FAULT' }],
                xs: 2,
                inputTextAlign: 'center',
                labelWidth: '0',
                width: '100%',
                direction: 'rtl'
            },
            {
                key: 'F85TestResultLabel',
                label: 'Test Result',
                xs: 2,
                labelWidth,
                padding: paddingLabel,
                isLabel: true,
                fontSize: '9pt',
                textAlign: 'right',
                fontWeight: '600'
            },
            {
                key: 'F85TestResult',
                value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                isRequired: true,
                isDropDown: true,
                placeholder: 'Select Test Result',
                options: TestResultDropdownOptions(formData),
                xs: 4,
                labelWidth: '0',
                width: '100%',
            }
        ]
    }
    ]

});
