import React, { useState } from 'react';
import DraftAlertDialog from 'components/DraftDialog/DraftAlertDialog';
import PropTypes from 'prop-types';

export const DraftAlert = ({ dialogMessage, dialogOpenState, handleAlertFunction, handleClose }) => {
    const warningMessage = 'When you save the TRF as a DRAFT the following features will be removed:';
    const [noticeAlert, setNoticeAlert] = useState(false);
    const [warningAlert, setWarningAlert] = useState(true);
    const handleWarningAlert = () => {
        setWarningAlert(false);
        setNoticeAlert(true);
    };
    return (
        <>
            <DraftAlertDialog
                id="alertDialog-Draft"
                dialogMessage={warningMessage}
                dialogOpenState={warningAlert}
                handleClose={() => handleClose() && setWarningAlert(false)}
                cancelButtonText="No"
                confirmButtonText="Yes"
                handleAlertFunction={() => handleWarningAlert()}
            />
            <DraftAlertDialog
                id="alertDialog-Notice"
                dialogMessage="Enter a reason for saving as draft"
                displayNotice
                dialogOpenState={dialogOpenState && noticeAlert}
                handleClose={handleClose}
                cancelButtonText="Cancel"
                confirmButtonText="Save Draft"
                handleAlertFunction={(notice) => handleAlertFunction(notice)}
            />
        </>
    );
};

DraftAlert.propTypes = {
    dialogMessage: PropTypes.string,
    dialogOpenState: PropTypes.bool,
    handleAlertFunction: PropTypes.func,
    handleClose: PropTypes.func
};
