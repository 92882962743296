export const selectTemperatureSensorP891 = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F573TemperatureSensorTypeLabel',
                    label: 'Temperature Sensor Type',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '17px 0 0 0'
                },
                {
                    key: 'F573TemperatureSensorType',
                    value: formData.F573TemperatureSensorType,
                    isDropdown: true,
                    placeholder: 'Select from list',
                    options: [{ name: 'Select from list', value: '' },
                    { name: 'RTD', value: 'RTD' },
                    { name: 'Thermocouple', value: 'Thermocouple' }],
                    inputTextAlign: 'center',
                    isRequired: true,
                    labelWidth: '0',
                    width: '100%',
                    xs: 2,
                    marginTop: '14px'
                }
            ]
        }
    ]
});

export const selectTemperatureSensor = (selectedTag, formName, formData) => {
    let filterViewData;
    if (['P8-9.1', 'P7-19'].includes(formName)) {
        filterViewData = selectTemperatureSensorP891(selectedTag, formName, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
