import { AsLeftResultFormula } from './M611.formula';
import { checkTGCGreaterThanSetpointHH, checkTGCLessThanSetpointLL } from 'helpers/Validation';
import { paddingLabel, tgcHighLabel, tgcLowLabel } from '../../../AsFoundTestTableSection/Data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const isEnabledcondition = (formDataValue, disableField) => disableField ? '' : formDataValue;
export const AsLeftTestTableSectionDataM611M614M615M61 = (selectedTag, formData, formName) => {
    const disableField = ((formData.F102AftResult === 'PASS' && formData.F425ReCalibrationRequired === 'NO') || formData.F619AsLeftTestRequired === 'NO');
    let altTestGasConcentration;
    if (formName === 'M6-11' || formName === 'M6-1' || formName === 'M6-14') {
        altTestGasConcentration = tgcHighLabel;
    } else if (formName === 'M6-15') {
        altTestGasConcentration = tgcLowLabel;
    }
    let checkTCGFunction;
    if (formName === 'M6-11' || formName === 'M6-1' || formName === 'M6-14') {
        checkTCGFunction = checkTGCGreaterThanSetpointHH(formData?.F240HighHighSetpoint, formData?.F426AltAppliedTestGasConcentration);
    } else if (formName === 'M6-15') {
        checkTCGFunction = checkTGCLessThanSetpointLL(formData?.F241LowLowSetpoint, formData?.F426AltAppliedTestGasConcentration);
    }

    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'AsleftTest',
                label: 'As-Left Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F432AltZeroreadingvalueLabel',
                    label: 'Reading Without Test Gas',
                    xs: 4,
                    labelWidth,
                    padding: paddingLabel,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F432AltZeroreadingvalue',
                    value: isEnabledcondition(formData.F432AltZeroreadingvalue, disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    height: '1.28rem',
                    borderBottom: '0'
                }]
        },
        {
            key: 3,
            fields: [{
                key: 'F426AltAppliedTestGasConcentrationLabel',
                label: 'Applied Test Gas Concentration',
                xs: 4,
                labelWidth,
                padding: paddingLabel,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F426AltAppliedTestGasConcentration',
                value: isEnabledcondition(formData.F426AltAppliedTestGasConcentration, disableField),
                isDisabled: disableField,
                readOnly: disableField,
                isRequired: !(disableField),
                xs: 2,
                type: 'number',
                maxLength: '10',
                labelWidth: '0%',
                width: '100%',
                textAlign: 'center',
                height: '1.3rem',
                borderBottom: '0',
                hasAltTestGasConcentrationError: !checkTCGFunction,
                testGasConcentrationMessage: altTestGasConcentration
            },
            {
                key: 'AltTestGasConcentration',
                label: altTestGasConcentration,
                isLabel: true,
                padding: '5px 0 5px 4px',
                xs: 6,
                color: '#ea7c34',
                fontSize: '8.5pt',
                fontStyle: 'italic',
                fontWeight: '600'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F434DetectorvaluewithTestGasAppliedALTLabel',
                label: 'Detector Value With Test Gas Applied',
                xs: 4,
                labelWidth,
                padding: paddingLabel,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F434DetectorvaluewithTestGasAppliedALT',
                value: isEnabledcondition(formData.F434DetectorvaluewithTestGasAppliedALT, disableField),
                isDisabled: disableField,
                readOnly: disableField,
                type: 'number',
                maxLength: '10',
                isRequired: !(disableField),
                xs: 2,
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                labelWidth: '0%',
                width: '100%',
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F378AltHmistatus1Label',
                label: 'HMI Status',
                xs: 4,
                labelWidth,
                padding: paddingLabel,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F378AltHmistatus1',
                value: isEnabledcondition(formData.F378AltHmistatus1, disableField),
                isRequired: !(disableField),
                isDisabled: disableField,
                isDropdown: !(disableField),
                readOnly: disableField,
                placeholder: 'Select HMI Status',
                options: [{ name: 'Select HMI Status', value: '' },
                { name: 'NORMAL', value: 'NORMAL' },
                { name: 'ALARM', value: 'ALARM' },
                { name: 'FAULT', value: 'FAULT' }],
                xs: 2,
                labelWidth: '0%',
                width: '100%',
                marginBottom: '0.25rem',
                inputTextAlign: 'center',
                borderBottom: '0',
                height: (formData.F376CleanDetectorLensesIfVisiblyDirty === 'NOT REQUIRED' && formData.F377AlignmentRequired === 'NO') ? '1.2rem' : '1.5rem',
            },
            {
                key: 'F103AltResultLabel',
                label: 'As-Left Result',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                labelWidth
            },
            {
                key: 'F103AltResult',
                value: isEnabledcondition(AsLeftResultFormula(formData), disableField),
                isDisabled: disableField,
                readOnly: true,
                isLabelBold: true,
                isCalculatedNew: true,
                height: (disableField) ? '1.5rem' : '1.25rem',
                xs: 2,
                labelWidth: '0%',
                width: '100%',
                textAlignLabel: 'end',
                borderBottom: '0',
                borderRight: '0',
                textAlign: 'center',
                inputMarginRight: '4px',
                marginBottom: '4px',
            }]
        }]
    };
};
