import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AddButton } from 'elements/CustomHeaderButtons/HeaderButtons';
import DataTable from 'components/DataTable/DataTable';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import SearchBox from '../../../../elements/SearchBox/SearchBox';
import { updatedTabledefination } from './DPS.constants';
import { MRAT_USER_ROLE } from '../../Home/Home.constants';
import {
    searchDPS, setDataForEditDPS, reSetDPSTotalCount,
    setDisplayStatusSortOrder, setFunctionalLocationSortOrder, getDpsSourceMapping, getCorrectiveDpsMapping,
    getDpsFieldMaxLength
} from './DPS.actions';
import { setCurrentView } from '../../Home/Home.actions';

const AddButtonDPS = ({ id, setCurrentAppView }) => {
    const dpsbuttonHandler = () => {
        setCurrentAppView('addDpsForm');
    };
    return (
        <AddButton
            id={id}
            buttonHandler={dpsbuttonHandler}
        />
    );
};

const DPSPage = (props) => {
    const { triggerSearch, initialiseEditDPSdata } = props;
    const { dpsDataAll, dpsTableData, isTableLoading, totalCount, userRole, selectedBarrierType, isMRATOnline,
        dpsSearchString, setCurrentAppView, setDisplayStatusSortAscOrder, setFunctionalLocationSortAscOrder,
        loadDpsLockedFieldDetails, loadDpsFieldMaxlength, loadCorrectiveDpsMapping } = props;
    const [searchString, handleSearchString] = useState(dpsSearchString);
    const tableData = searchString.length === 0 ? dpsDataAll : dpsTableData;
    const getParsedTableData = () => tableData.map((item) => ({
        ...item,
        FormLocationDescription: item.LocationDescription?.slice(0, 30),
        FormIcssSoftTag: item.IcssSoftTag?.slice(0, 30),
    }));
    const handleSearch = (searchValue) => {
        handleSearchString(searchValue);
        triggerSearch(searchValue);
    };

    const handleArrowButton = (rowData) => {
        initialiseEditDPSdata(rowData);
        setCurrentAppView('editDpsForm');
    };

    const sortButtonHandler = (columnName) => {
        if (columnName === 'SapFloc') {
            setFunctionalLocationSortAscOrder(columnName);
        } else {
            setDisplayStatusSortAscOrder(columnName);
        }
    };
    const isViewer = MRAT_USER_ROLE.View === userRole;
    const isEngineer = MRAT_USER_ROLE.Engineer === userRole;
    const selectedBarrierTypeIsM6 = selectedBarrierType === ('M6 F&G').toLowerCase();
    const showAddButton = isMRATOnline && !isViewer && (isEngineer || selectedBarrierTypeIsM6);
    useEffect(() => {
        loadDpsLockedFieldDetails();
        loadDpsFieldMaxlength();
        loadCorrectiveDpsMapping();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <SiteWrapper
            headerText="Device Performance Standards Database"
            ButtonSection={showAddButton && <AddButtonDPS id="addButtonDPS" setCurrentAppView={setCurrentAppView} />}
            showTotalCount
            totalCount={totalCount}
            goBack="home"
        >
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <SearchBox
                            value={dpsSearchString}
                            handleSearch={handleSearch}
                            margin="5px"
                            width="400px"
                        />
                        <DataTable
                            id="DPSDataTable"
                            tableDefinition={updatedTabledefination}
                            tableData={getParsedTableData()}
                            isLoading={isTableLoading}
                            sortButtonHandler={sortButtonHandler}
                            actionButtonHandler={(dataRow) => handleArrowButton(dataRow)}
                            showArrowButton
                        />
                    </div>
                </div>
            </div>
        </SiteWrapper>
    );
};

const mapStateToProps = ({ AppData, DPS, Home }) => ({
    dpsDataAll: DPS.dpsDataAll,
    dpsSearchString: DPS.dpsSearchString,
    dpsTableData: DPS.dpsTableData,
    isTableLoading: DPS.tableLoading || false,
    totalCount: DPS.totalCount,
    selectedBarrierType: Home.selectedBarrierType,
    userRole: Home.userRole,
    isMRATOnline: AppData.isMRATOnline
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    setCurrentAppView: (view) => dispatch(setCurrentView(view)),
    triggerSearch: (value) => dispatch(searchDPS(value)),
    initialiseEditDPSdata: (rowData) => dispatch(setDataForEditDPS(rowData)),
    resetDPSTotalCount: () => { dispatch(reSetDPSTotalCount()); },
    setDisplayStatusSortAscOrder: (columnName) => { dispatch(setDisplayStatusSortOrder(columnName)); },
    setFunctionalLocationSortAscOrder: (columnName) => { dispatch(setFunctionalLocationSortOrder(columnName)); },
    loadDpsLockedFieldDetails: () => dispatch(getDpsSourceMapping()),
    loadDpsFieldMaxlength: () => dispatch(getDpsFieldMaxLength()),
    loadCorrectiveDpsMapping: () => dispatch(getCorrectiveDpsMapping())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DPSPage);

AddButtonDPS.propTypes = {
    id: PropTypes.string,
    setCurrentAppView: PropTypes.func,
};

DPSPage.propTypes = {
    dpsDataAll: PropTypes.array.isRequired,
    dpsSearchString: PropTypes.string,
    dpsTableData: PropTypes.array.isRequired,
    initialiseEditDPSdata: PropTypes.func.isRequired,
    isMRATOnline: PropTypes.bool,
    isTableLoading: PropTypes.bool,
    loadCorrectiveDpsMapping: PropTypes.func,
    loadDpsFieldMaxlength: PropTypes.func,
    loadDpsLockedFieldDetails: PropTypes.func,
    selectedBarrierType: PropTypes.string,
    setCurrentAppView: PropTypes.func,
    setDisplayStatusSortAscOrder: PropTypes.func,
    setFunctionalLocationSortAscOrder: PropTypes.func,
    totalCount: PropTypes.number,
    triggerSearch: PropTypes.func.isRequired,
    userRole: PropTypes.string
};
