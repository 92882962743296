export const VisualInspectSectionDataPP617 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'VISUALINSPECTION',
                label: 'VISUAL INSPECTION',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                width: '28%',
                padding: '15px 0 0 0'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F179VisuallyInspection',
                label: `Visually inspect instrument tagging, if the position transmitter is in good condition, state of instrument 
                wiring connection/ termination, general instrument condition, and for signs general deterioration and leaking`,
                value: formData.F179VisuallyInspection,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'Diagnostics',
                label: 'DIAGNOSTICS',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '0'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F120Diagnostics',
                label: 'Retrieve Diagnostic Message Information:',
                value: formData.F120Diagnostics,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]
});
