import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';
import { CheckBoxWrapper } from './CheckBox.styled';

const CheckBox = (props) => {
    const { id, name, handleChange, isChecked, visibility, label, labelTop, color, isCheckedHandler, isDisabled, isCheckedTypeFunc, historicalPrintScreen } = props;
    return (
        <CheckBoxWrapper visibility={visibility} color={color} label={label} historicalPrintScreen={historicalPrintScreen}>
            <FormControlLabel
                control={(
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxOutlinedIcon fontSize="small" />}
                        name={name}
                        checked={isCheckedTypeFunc ? isCheckedHandler(name) && !isDisabled : isChecked ?? false}
                        onChange={(e) => handleChange({ [name]: e.target.checked })}
                        disabled={isDisabled}
                    />
                )}
                label={label}
                id={id}
                labelPlacement={labelTop ? 'top' : 'end'}
            />
        </CheckBoxWrapper>
    );
};

export default CheckBox;

CheckBox.propTypes = {
    color: PropTypes.string,
    handleChange: PropTypes.func,
    historicalPrintScreen: PropTypes.string,
    id: PropTypes.string,
    isChecked: PropTypes.any,
    isCheckedHandler: PropTypes.any,
    isCheckedTypeFunc: PropTypes.any,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
    labelTop: PropTypes.any,
    name: PropTypes.string,
    visibility: PropTypes.string
};
