import { paddingAF } from '../../../AsLeftTestTableSection/Data/M71/M71.data';
import { AsFoundF215AftDeviation1Formula, AsFoundResultFormula } from './P724.formula';
import { CalibrationPoint1Formula } from '../../../AsLeftTestTableSection/Data/P724/P724.formula';

export const AsFoundTestTableSectionDataP724 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'As-FoundTest',
            label: 'As-Found Test',
            isLabel: true,
            xs: 12,
            fontWeight: 700,
            padding: '5px 0 0 8px'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'CalibrationPoint',
            label: 'Calibration Point',
            isLabel: true,
            xs: 3,
            fontWeight: 700,
            labelWidth: '100%',
            padding: '5px 0 5px 8px',
            fontSize: '9pt'
        },
        {
            key: 'ReferenceCalibrationGas',
            label: 'Reference Calibration Gas',
            isLabel: true,
            xs: 2,
            fontWeight: 700,
            labelWidth: '100%',
            textAlign: 'center',
            padding: paddingAF,
            fontSize: '9pt'
        },
        {
            key: 'AnalyzerReading',
            label: 'Analyzer Reading',
            isLabel: true,
            xs: 2,
            fontWeight: 700,
            textAlign: 'center',
            labelWidth: '100%',
            padding: paddingAF,
            fontSize: '9pt'
        },
        {
            key: 'HMIReading',
            label: 'HMI Reading',
            isLabel: true,
            xs: 2,
            fontWeight: 700,
            labelWidth: '100%',
            textAlign: 'center',
            padding: paddingAF,
            fontSize: '9pt'
        },
        {
            key: 'Deviation',
            label: 'Deviation',
            isLabel: true,
            xs: 3,
            fontWeight: 700,
            textAlign: 'center',
            padding: paddingAF,
            fontSize: '9pt'
        }]
    },
    {
        key: 3,
        fields: [{
            key: 'F121AftCalibrationPoint1',
            value: CalibrationPoint1Formula(formData),
            xs: 3,
            width: '100%',
            labelWidth: '0',
            readOnly: true,
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            borderBottom: '0',
            marginLeft: '4px'
        },
        {
            key: 'F501AftReferenceCalibrationGas',
            value: formData.F501AftReferenceCalibrationGas,
            isRequired: true,
            xs: 2,
            type: 'number',
            maxLength: '10',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderRight: '0',
            borderBottom: '0'
        },
        {
            key: 'F356AftAnalyzerReading',
            value: formData.F356AftAnalyzerReading,
            isRequired: true,
            xs: 2,
            type: 'number',
            maxLength: '10',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderRight: '0',
            borderBottom: '0'
        },
        {
            key: 'F106AftHmireading1',
            value: formData.F106AftHmireading1,
            isRequired: true,
            type: 'number',
            maxLength: '10',
            xs: 2,
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderRight: '0',
            borderBottom: '0'
        },
        {
            key: 'F215AftDeviation1',
            value: AsFoundF215AftDeviation1Formula(formData),
            xs: 3,
            textAlign: 'center',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            inputMarginRight: '4px',
            readOnly: true,
        }]
    },
    {
        key: 4,
        fields: [{
            key: 'F101AftToleranceLable',
            xs: 3,
            value: 'Tolerance (EU)',
            width: '100%',
            labelWidth: '0',
            readOnly: true,
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            borderBottom: '0',
            marginBottom: '4px',
            marginLeft: '4px',
            isLabelBold: true
        },
        {
            key: 'F101AftTolerance',
            value: selectedTag.DateTested ? formData?.F101AftTolerance : formData?.F101AftTolerance ?? selectedTag?.ToleranceInInstrumentEu,
            textAlignLabel: 'end',
            isRequired: true,
            maxLength: '10',
            xs: 2,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderRight: '0',
            borderBottom: '0',
            marginBottom: '4px'
        },
        {
            key: 'F102AftResultLabel',
            value: 'As-Found Pass/Fail Result',
            xs: 4,
            readOnly: true,
            width: '100%',
            labelWidth: '0',
            textAlign: 'end',
            height: '1.2rem',
            borderRight: '0',
            marginBottom: '4px',
            isLabelBold: true,
            borderBottom: '0'
        },
        {
            key: 'F102AftResult',
            value: AsFoundResultFormula(formData),
            readOnly: true,
            textAlignLabel: 'end',
            isCalculatedNew: true,
            xs: 3,
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            marginBottom: '4px',
            inputMarginRight: '4px',
            fontWeight: '700'
        }]
    }]
});
