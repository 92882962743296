import { AsLeftResultFormulaM71 } from './M71.formulas';
import { conditionalValueSelector } from 'helpers/Validation';
import { isAsLeftTestRequired } from '../../AsLeftTestTableSection.helpers';

export const paddingAF = '5px 0 5px 0';
export const AsLeftTestTableSectionDataM71 = (formData) => {
    const altDisabled = isAsLeftTestRequired(formData?.F102AftResult, formData.F619AsLeftTestRequired);
    return {
        rows: [
            {
                key: 11,
                fields: [{
                    key: 'TagNumber3',
                    label: 'Tag Number',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: '15px 0 5px 6px',
                    fontSize: '9pt'
                },
                {
                    key: 'Description3',
                    label: 'Description',
                    isLabel: true,
                    xs: 3,
                    fontWeight: '700',
                    labelWidth: '100%',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'Value3',
                    label: 'Value',
                    isLabel: true,
                    xs: 1,
                    fontWeight: '700',
                    labelWidth: '100%',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'TagNumber4',
                    label: 'Tag Number',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    marginTop: '15px',
                    padding: '15px 0 5px 15px',
                    fontSize: '9pt'
                },
                {
                    key: 'Description4',
                    label: 'Description',
                    isLabel: true,
                    xs: 3,
                    fontWeight: '700',
                    labelWidth: '100%',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'Value4',
                    label: 'Value',
                    isLabel: true,
                    xs: 1,
                    fontWeight: '700',
                    labelWidth: '100%',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                }]
            },
            {
                key: 12,
                fields: [{
                    key: 'F710AltTst1TagNumber',
                    value: conditionalValueSelector(altDisabled, '', formData.F710AltTst1TagNumber),
                    xs: 2,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    inputMarginLeft: '4px',
                    maxLength: '30',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    isMultiLine: true
                },
                {
                    key: 'F711AltTst1Desc',
                    value: conditionalValueSelector(altDisabled, '', formData.F711AltTst1Desc),
                    xs: 3,
                    maxLength: '45',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    isMultiLine: true,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                },
                {
                    key: 'F712AltTst1Val',
                    value: conditionalValueSelector(altDisabled, '', formData.F712AltTst1Val),
                    xs: 1,
                    maxLength: '15',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    isMultiLine: true,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderLeft: '0',
                },
                {
                    key: 'F719AltTst2TagNumber',
                    value: conditionalValueSelector(altDisabled, '', formData.F719AltTst2TagNumber),
                    maxLength: '30',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 2,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    isMultiLine: true,
                    borderRight: '0',
                    borderBottom: '0',
                    inputMarginLeft: '15px',
                },
                {
                    key: 'F720AltTst2Desc',
                    value: conditionalValueSelector(altDisabled, '', formData.F720AltTst2Desc),
                    maxLength: '45',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 3,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    isMultiLine: true
                },
                {
                    key: 'F721AltTst2Val',
                    value: conditionalValueSelector(altDisabled, '', formData.F721AltTst2Val),
                    xs: 1,
                    maxLength: '15',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    isMultiLine: true
                }]
            },
            {
                key: 13,
                fields: [{
                    key: 'F713AltTst1TagNumber',
                    value: conditionalValueSelector(altDisabled, '', formData.F713AltTst1TagNumber),
                    xs: 2,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    inputMarginLeft: '4px',
                    maxLength: '30',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    isMultiLine: true
                },
                {
                    key: 'F714AltTst1Desc',
                    value: conditionalValueSelector(altDisabled, '', formData.F714AltTst1Desc),
                    xs: 3,
                    maxLength: '45',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    isMultiLine: true
                },
                {
                    key: 'F715AltTst1Val',
                    value: conditionalValueSelector(altDisabled, '', formData.F715AltTst1Val),
                    xs: 1,
                    maxLength: '15',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    inputWidth: '100%',
                    isMultiLine: true,
                    labelWidth: '0',
                    height: '1.2rem',
                    borderLeft: '0',
                    borderBottom: '0',
                },
                {
                    key: 'F722AltTst2TagNumber',
                    value: conditionalValueSelector(altDisabled, '', formData.F722AltTst2TagNumber),
                    maxLength: '30',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 2,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    marginLeft: '8px',
                    inputMarginLeft: '15px',
                    isMultiLine: true
                },
                {
                    key: 'F723AltTst2Desc',
                    value: conditionalValueSelector(altDisabled, '', formData.F723AltTst2Desc),
                    maxLength: '45',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 3,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    isMultiLine: true
                },
                {
                    key: 'F724AltTst2Val',
                    value: conditionalValueSelector(altDisabled, '', formData.F724AltTst2Val),
                    xs: 1,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    maxLength: '15',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    isMultiLine: true
                }]
            },
            {
                key: 14,
                fields: [{
                    key: 'F716AltTst1TagNumber',
                    value: conditionalValueSelector(altDisabled, '', formData.F716AltTst1TagNumber),
                    xs: 2,
                    inputWidth: '100%',
                    labelWidth: '0',
                    maxLength: '30',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    inputMarginLeft: '4px',
                    isMultiLine: true
                },
                {
                    key: 'F717AltTst1Desc',
                    value: conditionalValueSelector(altDisabled, '', formData.F717AltTst1Desc),
                    xs: 3,
                    maxLength: '45',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    isMultiLine: true
                },
                {
                    key: 'F718AltTst1Val',
                    value: conditionalValueSelector(altDisabled, '', formData.F718AltTst1Val),
                    xs: 1,
                    maxLength: '15',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    inputWidth: '100%',
                    labelWidth: '0',
                    borderLeft: '0',
                    height: '1.2rem',
                    isMultiLine: true
                },
                {
                    key: 'F725AltTst2TagNumber',
                    value: conditionalValueSelector(altDisabled, '', formData.F725AltTst2TagNumber),
                    maxLength: '30',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 2,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    marginLeft: '8px',
                    isMultiLine: true,
                    inputMarginLeft: '15px',
                },
                {
                    key: 'F726AltTst2Desc',
                    value: conditionalValueSelector(altDisabled, '', formData.F726AltTst2Desc),
                    maxLength: '45',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 3,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    isMultiLine: true
                },
                {
                    key: 'F727AltTst2Val',
                    value: conditionalValueSelector(altDisabled, '', formData.F727AltTst2Val),
                    xs: 1,
                    inputWidth: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    maxLength: '15',
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    isMultiLine: true
                }]
            },
            {
                key: 15,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: true,
                        xs: 8,
                    },
                    {
                        key: 'As-LeftResultLabel',
                        value: 'As-Left Result',
                        xs: 2,
                        width: '100%',
                        readOnly: true,
                        labelWidth: '0',
                        isLabelNew: true,
                        height: '1.2rem',
                        borderRight: '0',
                        borderLeft: '0',
                        borderTop: '0',
                        marginLeft: '4px',
                        borderBottom: '0',
                        marginBottom: '4px',
                        textAlign: 'right'
                    },
                    {
                        key: 'F103AltResult',
                        value: conditionalValueSelector(altDisabled, '', AsLeftResultFormulaM71(formData)),
                        isDisabled: altDisabled,
                        xs: 2,
                        isCalculatedNew: true,
                        readOnly: true,
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        borderTop: '0',
                        height: '1.2rem',
                        borderBottom: '0',
                        marginBottom: '4px',
                        inputMarginRight: '4px',
                        borderRight: '0'
                    }]
            }
        ]
    };
};
