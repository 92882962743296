import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormInput, FormInputMultiline } from 'elements/Inputs/FormInput/';
import { StyledLabel } from 'elements/Label/Label';
import { selectVisualInspectionTemplateForForm } from './VisualInspectSection.data';
import { StyleDropdown } from '../AsFoundTestTableSection/AsFoundTestSection.styled';
import { FormDropdown } from 'elements/Inputs/FormInput/Dropdown/Dropdown';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

const VisualInspectSection = (props) => {
    const { selectedTag, formName, changeHandler, formData, isFormReadOnly, userRole } = props;
    const classes = useStyles();
    const multiDrop = (field) => field.isMultiline ? (
        <FormInputMultiline
            rows={3}
            id={field.key}
            name={field.key}
            label={field.label}
            value={field.value}
            isRequired={field.isRequired}
            handleChange={changeHandler}
            width={field.width}
            inputWidth={field.inputWidth}
            marginLeft={field.marginLeft}
            readOnly={field.readOnly || isFormReadOnly}
            alignItems={field.alignItems}
            padding={field.padding}
            marginTop={field.marginTop}
            marginBottom={field.marginBottom}
            marginRight={field.marginRight}
            isDisabled={field.isDisabled}
            borderRight={field.borderRight}
            borderTop={field.borderTop}
        />
    )
        : (
            <>
                <FormInput
                    id={field.key}
                    name={field.key}
                    label={field.label}
                    value={field.value}
                    color={field.color}
                    isRequired={field.isRequired}
                    handleChange={changeHandler}
                    type={field.type}
                    labelWidth={field.labelWidth}
                    width={field.width}
                    marginBottom={field.marginBottom}
                    marginLeft={field.marginLeft}
                    marginRight={field.marginRight}
                    readOnly={field.readOnly || isFormReadOnly}
                    maxLength={field.maxLength}
                    isCalculatedNew={field.isCalculatedNew}
                    direction={field.direction}
                    marginTop={field.marginTop}
                    borderLeft={field.borderLeft}
                    textAlign={field.textAlign}
                    hasDPSError={field.hasDPSError}
                    borderTop={field.borderTop}
                    borderBottom={field.borderBottom}
                    borderRight={field.borderRight}
                    userRole={userRole}
                    isDisabled={field.isDisabled}
                />
            </>
        );
        const formDropdownInput = (field) => field.isDropdown ? (
            <StyleDropdown
                formName={formName}
            >
                <FormDropdown
                    id={field.key}
                    name={field.key}
                    label={field.label}
                    value={field.value}
                    placeholder={field.placeholder}
                    handleChange={changeHandler}
                    options={field.options}
                    isRequired={field.isRequired}
                    width={field.width}
                    inputTextAlign={field.inputTextAlign}
                    labelWidth={field.labelWidth}
                    direction={field.direction}
                    readOnly={field.readOnly || isFormReadOnly}
                    isDisabled={field.isDisabled}
                    marginBottom={field.marginBottom}
                    marginLeft={field.marginLeft}
                    marginRight={field.marginRight}
                    borderBottom={field.borderBottom}
                    height={field.height}
                    marginTop={field.marginTop}
                    borderTop={field.borderTop}
                    paddingPlaceholder={field.paddingPlaceholder}
                    arrowMarginTop={field.arrowMarginTop}
                    fontSize={field.fontSize}
                    borderRight={field.borderRight}
                    rootMarginRight={field.rootMarginRight}
                    noDefaultOption={field.noDefaultOption}
                    inputMarginRight={field.inputMarginRight}
                    borderLeft={field.borderLeft}
                    subMenu={field.subMenu}
                    defaultValue={field.defaultValue}
                    padding={field.padding}
                />
            </StyleDropdown>
        )
            : multiDrop(field);
    return (
        <Grid container spacing={1} className={classes.root}>
            {
                selectVisualInspectionTemplateForForm(selectedTag, formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ paddingBottom: '0', paddingTop: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isLabel
                                    ? (
                                        <StyledLabel
                                            id={field.key}
                                            fontWeight={field.fontWeight}
                                            color={field.color}
                                            marginTop={field.marginTop}
                                            top={field.top}
                                            labelWidth={field.labelWidth}
                                            fontSize={field.fontSize}
                                            marginLeft={field.marginLeft}
                                            padding={field.padding}
                                            textAlign={field.textAlign}
                                        >
                                            {field.label}
                                        </StyledLabel>
                                    )
                                    : formDropdownInput(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisualInspectSection);

VisualInspectSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string
};
