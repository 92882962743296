import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import React from 'react';
import InformationPage from '../../../../components/InformationPage/InformationPage';

const Info = () => (
        <SiteWrapper
            headerText="Information"
            goBack="home"
        >
            <InformationPage />
        </SiteWrapper>
    );

export default Info;
