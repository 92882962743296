import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import RefreshIcon from 'icons/RefreshButton/Refresh';
import { useMsal } from '@azure/msal-react';
import { formatDate } from 'helpers/DateFormatter';
import { TestEquipmentForm } from './TestEquipmentForm';
import { resetDataForEditTestEquipment, updateDataForEditTestEquipment, PutEditTestEquipmentData } from './TestEquipmentForm.actions';
import { IsEditMandatory } from './mandatoryFieldsForAddEdit';
import { setCurrentView } from '../../Home/Home.actions';

const RefreshFormComponent = ({ buttonHandler }) => <RefreshIcon buttonHandler={buttonHandler} rotate={-90} tooltip="Reset Form" />;
RefreshFormComponent.propTypes = {
    buttonHandler: PropTypes.func.isRequired
};

const EditTestEquipmentForm = (props) => {
    const { setCurrentAppView, resetData, updateFormData, editTestEquipmentData, isMRATOnline,
        putEditTestEquipmentData, bpRegionNameAlias, selectedAssetAliasName, unSavedChangesTE } = props;
    const FooterButtonVisible = unSavedChangesTE && IsEditMandatory(isMRATOnline, editTestEquipmentData);
    const [disableEditTESubmit, setDisableEditTESubmit] = useState(false);
    const showRefreshButton = isMRATOnline;
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const UpdateFormDataHandler = (obj) => {
        const objWithPropertiesToBeUpdated = obj;
        objWithPropertiesToBeUpdated.CreatedBy = editTestEquipmentData.CreatedBy;
        objWithPropertiesToBeUpdated.CreatedDate = editTestEquipmentData.CreatedDate;
        updateFormData(objWithPropertiesToBeUpdated);
    };
    const SubmitButtonHandler = async () => {
        const newFormedObject = editTestEquipmentData;
        setDisableEditTESubmit(true);
        newFormedObject.ModifiedBy = userName?.name;
        newFormedObject.LastModifiedDate = formatDate(new Date());
        await putEditTestEquipmentData(newFormedObject);
        setCurrentAppView('testequipment');
    };
    const readOnly = !isMRATOnline;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => resetData(), []);
    return (
        <SiteWrapper
            headerText={
                (
                    <span style={{ textAlign: 'center' }}>
                        Edit Test Equipment
                    </span>
                )
            }
            ButtonSection={showRefreshButton && <RefreshFormComponent buttonHandler={() => resetData()} />}
            footerButtonHandler={SubmitButtonHandler}
            footerButtonText="Submit"
            FooterButtonVisible={FooterButtonVisible}
            goBack="testequipment"
            isDisabled={disableEditTESubmit}
            displayUnsavedChangesPopUp
        >
            <TestEquipmentForm
                formId="EditTestEquipmentForm"
                isEditTestEquipment
                formValues={editTestEquipmentData}
                formHandler={(obj) => UpdateFormDataHandler(obj)}
                bpRegionNameAlias={bpRegionNameAlias}
                readOnly={readOnly}
                selectedAssetAliasName={selectedAssetAliasName}
            />
        </SiteWrapper>
    );
};

const mapStateToProps = ({ AppData, TestEquipment, Home, Forms }) => ({
    editTestEquipmentData: TestEquipment.editTestEquipmentData,
    isMRATOnline: AppData.isMRATOnline,
    trfMasterList: Home.trfMasterList,
    selectedTag: Forms.selectedTag,
    selectedAssetAliasName: Home.selectedAssetAliasName,
    bpRegionNameAlias: Home.bpRegionNameAlias,
    unSavedChangesTE: TestEquipment.unSavedChangesTE
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    setCurrentAppView: (view) => dispatch(setCurrentView(view)),
    resetData: () => dispatch(resetDataForEditTestEquipment()),
    updateFormData: (obj) => dispatch(updateDataForEditTestEquipment(obj)),
    putEditTestEquipmentData: (obj) => dispatch(PutEditTestEquipmentData(obj)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditTestEquipmentForm);

EditTestEquipmentForm.propTypes = {
    bpRegionNameAlias: PropTypes.string,
    editTestEquipmentData: PropTypes.object.isRequired,
    isMRATOnline: PropTypes.bool.isRequired,
    putEditTestEquipmentData: PropTypes.func.isRequired,
    resetData: PropTypes.func,
    selectedAssetAliasName: PropTypes.string,
    setCurrentAppView: PropTypes.func,
    unSavedChangesTE: PropTypes.bool,
    updateFormData: PropTypes.func
};
