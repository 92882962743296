import styled from 'styled-components';

export const BodyWrapper = styled.div`
margin-left: 2rem;
margin-right: 2rem;
width: 100%;
height: ${props => {
        if (props.headerText === 'Asset and Barrier Type') {
            return 'calc(100vh - 185px)';
        }
        return props.height || 'calc(100vh - 150px)';
    }};
overflow-x: hidden;
overflow-y: auto;
background-color: white;
margin: auto;
color: rgb(51, 51, 51);
`;
