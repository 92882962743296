import { FieldHasValue, ValidateNumberField, IsNonText } from 'helpers/Validation';

export const FoundResultF61AsFoundResultFormula = (formData) => {
    if (FieldHasValue(formData.F42GaugeReading1)
        && FieldHasValue(formData.F43GaugeReading2)
        && FieldHasValue(formData.F44GaugeReading3)
        && FieldHasValue(formData.F45TxReading1)
        && FieldHasValue(formData.F46TxReading2)
        && FieldHasValue(formData.F47TxReading3)
        && FieldHasValue(formData.F48HmiReading1)
        && FieldHasValue(formData.F49HmiReading2)
        && FieldHasValue(formData.F50HmiReading3) && IsNonText(formData.F26InstrumentUrv) && FieldHasValue(formData.F60ToleranceEu)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F54DeviationEu1), ValidateNumberField(formData.F55DeviationEu2),
        ValidateNumberField(formData.F56DeviationEu3)]) <= ValidateNumberField(formData.F60ToleranceEu)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const FoundResultF281AftCalibrationNeededFormula = (formData) => {
    if (formData.F61AsFoundResult && FieldHasValue(formData.F26InstrumentUrv)) {
        if (ValidateNumberField(formData.F26InstrumentUrv) > ValidateNumberField(formData.F25InstrumentLrv)) {
            return Math.max.apply(Math, [ValidateNumberField(formData.F54DeviationEu1),
            ValidateNumberField(formData.F55DeviationEu2), ValidateNumberField(formData.F56DeviationEu3)])
                / (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) <= 0.02 ? 'NO' : 'YES';
        }
        return '';
    }
    return '';
};
