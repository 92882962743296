export const VisualInspectSectionDataP721 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect proximity probe, probe/extension cables, proximity transducer and junction boxes for signs
                 of damage, corrosion, water/oil ingress, loose connections and security of mounting. Inspect condition of cabling, 
                 glanding and earthing.`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F120Diagnostics',
                label: 'Retrieve any diagnostic messages',
                value: formData.F120Diagnostics,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                marginTop: '15px',
                alignItems: 'inherit'
            }]
        }
    ]
});
