import { ValidateField } from 'helpers/Validation';

export const isDisabledFieldInAsLeftTest = (formData) => (ValidateField(formData.F619AsLeftTestRequired === 'NO' || formData.F61AsFoundResult === 'PASS'));
export const FailureModeSectionDataP716 = (formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F619AsLeftTestRequired',
            label: 'As-Left Test Required?',
            value: (formData.F61AsFoundResult !== 'PASS') ? formData.F619AsLeftTestRequired : '',
            isDisabled: formData.F61AsFoundResult === 'PASS',
            isRequired: false,
            isDropdown: (formData.F61AsFoundResult !== 'PASS'),
            readOnly: formData.F61AsFoundResult === 'PASS',
            placeholder: 'Select YES or NO',
            options: [{ name: 'Select YES or NO', value: '' },
            { name: 'YES', value: 'YES' },
            { name: 'NO', value: 'NO' }],
            width: '56.8%',
            labelWidth: '42%',
            inputTextAlign: 'center',
            xs: 6,
            marginTop: '15px',
            marginLeft: '4px'
        },
        {
            key: 'F759AsLeftTestPstMethod',
            label: 'As-Left Test PST Method',
            value: (formData.F619AsLeftTestRequired !== 'NO' && formData.F61AsFoundResult !== 'PASS') ? formData.F759AsLeftTestPstMethod : '',
            isDisabled: (formData.F619AsLeftTestRequired === 'NO' || formData.F61AsFoundResult === 'PASS'),
            isRequired: (formData.F619AsLeftTestRequired !== 'NO' && formData.F61AsFoundResult !== 'PASS'),
            isDropdown: (formData.F619AsLeftTestRequired !== 'NO' && formData.F61AsFoundResult !== 'PASS'),
            readOnly: (formData.F619AsLeftTestRequired === 'NO' || formData.F61AsFoundResult === 'PASS'),
            placeholder: 'Select PST Method',
            options: [{ name: 'Select PST Method', value: '' },
            { name: 'Solenoid Valve', value: 'Solenoid Valve' },
            { name: 'Smart Positioner', value: 'Smart Positioner' },
            { name: 'Both - valve & positioner', value: 'Both - valve & positioner' }],
            xs: 6,
            marginRight: '0.5rem',
            inputTextAlign: 'center',
            width: formData.F61AsFoundResult === 'PASS' ? '52.7%' : '53.7%',
            labelWidth: '44.9%',
            marginTop: '15px',
            textAlignLabel: 'end',
            textAlign: 'end'
        }]
    }]
});
