import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { conditionalValueSelector, ValidateField } from 'helpers/Validation';
import { AsLeftTestTableP716Formula } from '../P716/P716.formulas';
import { disableAsLeftTestFields } from '../P66/P66.data';

export const AsLeftTestTableSectionDataS71 = (formData) => {
    const isAltSolenoidSectionDisabled = !formData.F759AsLeftTestPstMethod
        || disableAsLeftTestFields(formData) || (formData.F759AsLeftTestPstMethod === 'Smart Positioner');
    const isAltSmartSectionDisabled = !formData.F759AsLeftTestPstMethod
        || disableAsLeftTestFields(formData) || (formData.F759AsLeftTestPstMethod === 'Solenoid Valve');
    return {
        rows: [
            {
                key: 1,
                fields: [
                    {
                        key: 'AsFoundTestLabel',
                        label: 'As-Left Test',
                        isLabel: true,
                        xs: 12,
                        fontWeight: '700',
                        padding: '5px 0 0 8px'
                    }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'NodataS71',
                        xs: 4,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'TestviaSolenoidValve',
                        label: 'Test via Solenoid Valve',
                        isLabel: true,
                        xs: 2,
                        fontWeight: '700',
                        padding: paddingLabel,
                        textAlign: 'center',
                    },
                    {
                        key: 'NodataS71',
                        xs: 3,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'TestviaSmartPositioner',
                        label: 'Test via Smart Positioner',
                        isLabel: true,
                        xs: 3,
                        fontWeight: '700',
                        padding: paddingLabel,
                        textAlign: 'right',
                        labelWidth: '97%'
                    }]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F752AltValveMeasuredPartialTravelAmountLabel',
                        label: 'Measured Partial Travel Amount (%)',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: '5px 0 0 8px',
                    },
                    {
                        key: 'F752AltValveMeasuredPartialTravelAmount',
                        value: conditionalValueSelector(isAltSolenoidSectionDisabled, '', ValidateField(formData.F752AltValveMeasuredPartialTravelAmount)),
                        isRequired: !isAltSolenoidSectionDisabled,
                        isDisabled: isAltSolenoidSectionDisabled,
                        readOnly: isAltSolenoidSectionDisabled,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        width: '100%',
                        labelWidth: '0%',
                        height: '1.2rem',
                        textAlign: 'center',
                    },
                    {
                        key: 'F756AltSmartMeasuredPartialTravelAmountLabel',
                        label: 'Measured Partial Travel Amount (%)',
                        xs: 4,
                        isLabel: true,
                        labelWidth: '98%',
                        textAlign: 'right',
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F756AltSmartMeasuredPartialTravelAmount',
                        value: conditionalValueSelector(isAltSmartSectionDisabled, '', ValidateField(formData.F756AltSmartMeasuredPartialTravelAmount)),
                        isRequired: !isAltSmartSectionDisabled,
                        isDisabled: isAltSmartSectionDisabled,
                        readOnly: isAltSmartSectionDisabled,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        width: '100%',
                        labelWidth: '0%',
                        height: '1.2rem',
                        textAlign: 'center',
                        inputMarginRight: '4px',
                        borderRight: '0',
                    }
                ]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F753AltValveTravelTimeToTargetPositionLabel',
                        label: 'Travel Time to Target Position (sec)',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: '4px 0 0 8px',
                    },
                    {
                        key: 'F753AltValveTravelTimeToTargetPosition',
                        value: conditionalValueSelector(isAltSolenoidSectionDisabled, '', ValidateField(formData.F753AltValveTravelTimeToTargetPosition)),
                        isDisabled: isAltSolenoidSectionDisabled,
                        readOnly: isAltSolenoidSectionDisabled,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        width: '100%',
                        labelWidth: '0%',
                        height: '1.2rem',
                        textAlign: 'center',
                        borderTop: '0'
                    },
                    {
                        key: 'F757AltSmartTravelTimeToTargetPositionLabel',
                        label: 'Travel Time to Target Position (sec)',
                        xs: 4,
                        isLabel: true,
                        labelWidth: '98%',
                        textAlign: 'right',
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F757AltSmartTravelTimeToTargetPosition',
                        value: conditionalValueSelector(isAltSmartSectionDisabled, '', ValidateField(formData.F757AltSmartTravelTimeToTargetPosition)),
                        isDisabled: isAltSmartSectionDisabled,
                        readOnly: isAltSmartSectionDisabled,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        width: '100%',
                        labelWidth: '0%',
                        height: '1.2rem',
                        textAlign: 'center',
                        borderTop: '0',
                        inputMarginRight: '4px',
                        borderRight: '0'
                    }
                ]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F754AltValvePartialStrokeTestResultLabel',
                        label: 'Partial Stroke Test Result',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: '4px 0 0 8px',
                    },
                    {
                        key: 'F754AltValvePartialStrokeTestResult',
                        value: conditionalValueSelector(isAltSolenoidSectionDisabled, '', ValidateField(formData.F754AltValvePartialStrokeTestResult)),
                        isDisabled: isAltSolenoidSectionDisabled,
                        isRequired: !isAltSolenoidSectionDisabled,
                        readOnly: isAltSolenoidSectionDisabled,
                        isDropdown: !isAltSolenoidSectionDisabled,
                        placeholder: Placeholder.SELECT_TEST_RESULT,
                        options: [{ name: Placeholder.SELECT_TEST_RESULT, value: '' },
                        { name: 'Successful', value: 'Successful' },
                        { name: 'Abnormal', value: 'Abnormal' },
                        { name: 'Aborted', value: 'Aborted' }],
                        xs: 2,
                        borderTop: '0',
                        inputTextAlign: 'center',
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        height: conditionalValueSelector((isAltSolenoidSectionDisabled), '', '1.5rem')
                    },
                    {
                        key: 'F758AltSmartPartialStrokeTestResultLabel',
                        label: 'Partial Stroke Test Result',
                        xs: 4,
                        isLabel: true,
                        labelWidth,
                        textAlign: 'right',
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F758AltSmartPartialStrokeTestResult',
                        value: conditionalValueSelector(isAltSmartSectionDisabled, '', ValidateField(formData.F758AltSmartPartialStrokeTestResult)),
                        isDisabled: isAltSmartSectionDisabled,
                        isRequired: !isAltSmartSectionDisabled,
                        readOnly: isAltSmartSectionDisabled,
                        isDropdown: !isAltSmartSectionDisabled,
                        placeholder: Placeholder.SELECT_TEST_RESULT,
                        options: [{ name: Placeholder.SELECT_TEST_RESULT, value: '' },
                        { name: 'Successful', value: 'Successful' },
                        { name: 'Abnormal', value: 'Abnormal' },
                        { name: 'Aborted', value: 'Aborted' }],
                        xs: 2,
                        borderTop: '0',
                        inputTextAlign: 'center',
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        borderRight: '0',
                        borderBottom: '0',
                        rootMarginRight: '4px',
                        inputMarginRight: '4px',
                        height: '1.5rem'
                    }
                ]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 6,
                    },
                    {
                        key: 'F81AsLeftResultLabel',
                        label: 'As-Left Result',
                        xs: 4,
                        isLabel: true,
                        labelWidth,
                        textAlign: 'right',
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F81AsLeftResult',
                        value: conditionalValueSelector(disableAsLeftTestFields(formData), '', AsLeftTestTableP716Formula(formData)),
                        isCalculatedNew: !disableAsLeftTestFields(formData),
                        isDisabled: disableAsLeftTestFields(formData),
                        readOnly: true,
                        xs: 2,
                        width: '100%',
                        labelWidth: '0%',
                        height: '1.2rem',
                        textAlign: 'center',
                        inputMarginRight: '4px',
                        marginBottom: '4px',
                        borderRight: '0',
                        borderBottom: '0',
                    }
                ]
            }
        ]
    };
};
