export const VisualInspectSectionDataP724 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'VISUALINSPECTION',
            label: 'VISUAL INSPECTION',
            isLabel: true,
            xs: 12,
            fontWeight: '700',
            padding: '15px 0 0 0'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'F193VisuallyInspect',
            label: `Visually inspect instrument tagging, seals, tubing, sample lines, state of instrument wiring connection/terminations, 
                general instrument condition and signs of deterioration as well as for signs of process fluid weeping/leaking`,
            value: formData.F193VisuallyInspect,
            isRequired: true,
            isMultiline: true,
            xs: 12,
            width: '50%',
            inputWidth: '50%',
            alignItems: 'inherit'
        }]
    },
    {
        key: 3,
        fields: [{
            key: 'Diagnostics',
            label: 'DIAGNOSTICS',
            isLabel: true,
            xs: 12,
            fontWeight: '700',
            padding: '0'
        }]
    },
    {
        key: 4,
        fields: [
            {
                key: 'F120Diagnostics',
                label: 'Retrieve Diagnostic Message Information:',
                value: formData.F120Diagnostics,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
    }]
});
