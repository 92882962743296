import React from 'react';
import { connect } from 'react-redux';
import Form from '../../FormsTemplate';
import { FormNames } from '../../Forms.constants';

import UserInfoSection from '../../Sections/UserInfoSection/UserInfoSection';
import SraDeviceSection from '../../Sections/SraDeviceSection/SraDeviceSection';
import TestResultSection from '../../Sections/TestResultSection/TestResultSection';
import NoteSection from '../../Sections/NoteSection/NoteSection';

const P81Form = (props) => (
    <Form
        formName={FormNames.P81}
    >
        <UserInfoSection />
        <SraDeviceSection />
        <TestResultSection />
        <NoteSection />
    </Form>
);

const mapStateToProps = ({ Forms }) => ({
    selectedTag: Forms.selectedTag,
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(P81Form);
