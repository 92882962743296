import { sraPlaceHolder } from '../SraDeviceSection/SraDeviceSection.data';
import { paddingLabel } from '../HMISection/Data/C11/C11.data';
import { labelWidth } from '../HMISection/Data/P710/P710.data';
import { Placeholder } from '../../Sections.constants';

export const MagneticFloatCheckData = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'MAGNETICFLOATCHECK',
                label: 'MAGNETIC FLOAT CHECK',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '15px 0 0 0'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F247MfcHighestPositiveGaussReadingLabel',
                    label: 'Highest Positive Gauss Reading',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F247MfcHighestPositiveGaussReading',
                    value: formData.F247MfcHighestPositiveGaussReading,
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                },
                {
                    key: 'F248MfcFloatOrientedCorrectlyLabel',
                    label: 'Is float oriented correctly?',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F248MfcFloatOrientedCorrectly',
                    value: formData.F248MfcFloatOrientedCorrectly,
                    isDropdown: true,
                    placeholder: sraPlaceHolder,
                    options: [{ name: sraPlaceHolder, value: '' },
                    { name: 'Yes', value: 'Yes' },
                    { name: 'No', value: 'No' }],
                    inputTextAlign: 'center',
                    xs: 2,
                    width: '100%',
                    height: '1.57rem',
                    labelWidth: '0%',
                    textAlign: 'center',
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F249MfcHighestNegativeGaussReadingLabel',
                    label: 'Highest Negative Gauss Reading',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F249MfcHighestNegativeGaussReading',
                    value: formData.F249MfcHighestNegativeGaussReading,
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    borderTop: '0',
                    height: '1.2rem',
                    textAlign: 'center',
                },
                {
                    key: 'F250MfcRecordedStrengthLessThan48GaussLabel',
                    label: 'Is recorded strength less than 48 Gauss?',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F250MfcRecordedStrengthLessThan48Gauss',
                    value: formData.F250MfcRecordedStrengthLessThan48Gauss,
                    isDropdown: true,
                    placeholder: sraPlaceHolder,
                    options: [{ name: sraPlaceHolder, value: '' },
                    { name: 'Yes', value: 'Yes' },
                    { name: 'No', value: 'No' }],
                    inputTextAlign: 'center',
                    xs: 2,
                    borderTop: '0',
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                }
            ]
        }
    ]
});
export const SolenoidOrPositionSection = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F973SisEsdSolenoidPositionLabel',
                    label: 'If more than one SIS or ESD solenoid or position is fitted, confirm correct activation',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '11px 0 0 0',
                },
                {
                    key: 'F973SisEsdSolenoidPosition',
                    value: formData.F973SisEsdSolenoidPosition,
                    isDropdown: true,
                    placeholder: Placeholder.SELECT_YES_OR_NO,
                    options: [{ name: Placeholder.SELECT_YES_OR_NO, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    xs: 2,
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    marginTop: '15px'
                }]
        }
    ]
});

export const selectMagneticFloatCheckData = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'P7-8') {
        filterViewData = MagneticFloatCheckData(selectedTag, formName, formData);
    } else if (['S7-1'].includes(formName) && formData.F956TrfTypeSelection === 'Full') {
        filterViewData = SolenoidOrPositionSection(formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
