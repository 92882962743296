import { formatDate } from 'helpers/DateFormatter';

export const submitP8Forms = async (p8FormsObj) => {
    // rebuild the data object because there may be some formulas not built
    const newFormDataObjectP81 = p8FormsObj.newFormedObjectP81;
    const newFormDataSRA = p8FormsObj.formDataSRA;
    if (p8FormsObj.formDataSRA?.RowId && p8FormsObj.selectedTag?.InDraftState) {
        newFormDataObjectP81.F4ModifiedBy = p8FormsObj.userName?.name;
        newFormDataObjectP81.F51DateLastModified = formatDate(p8FormsObj.selectedTag.currentDateTime);
        newFormDataSRA.F4ModifiedBy = p8FormsObj.userName?.name;
        newFormDataSRA.F51DateLastModified = formatDate(p8FormsObj.selectedTag.currentDateTime);
        p8FormsObj.saveNewFormOnlineOrOffline(`${p8FormsObj.uniqueFormName}_P81`, newFormDataObjectP81, 'P8-1', p8FormsObj.userName);
        p8FormsObj.saveExistingFormOnlineOrOffline(`${p8FormsObj.uniqueFormName}_SRA`, newFormDataSRA, p8FormsObj.newTrfId, p8FormsObj.userName);
    } else if (p8FormsObj.formDataSRA.RowId && p8FormsObj.formDataObj.RowId) {
        newFormDataObjectP81.F4ModifiedBy = p8FormsObj.userName?.name;
        newFormDataObjectP81.F51DateLastModified = formatDate(p8FormsObj.selectedTag.currentDateTime);
        newFormDataSRA.F4ModifiedBy = p8FormsObj.userName?.name;
        newFormDataSRA.F51DateLastModified = formatDate(p8FormsObj.selectedTag.currentDateTime);
        p8FormsObj.saveExistingFormOnlineOrOffline(`${p8FormsObj.uniqueFormName}_P81`, newFormDataObjectP81, 'P8-1', p8FormsObj.userName);
        p8FormsObj.saveExistingFormOnlineOrOffline(`${p8FormsObj.uniqueFormName}_SRA`, newFormDataSRA, p8FormsObj.newTrfId, p8FormsObj.userName);
    } else {
        newFormDataObjectP81.F4ModifiedBy = p8FormsObj.userName?.name;
        newFormDataObjectP81.F51DateLastModified = newFormDataObjectP81.F5DateTested;
        newFormDataSRA.F4ModifiedBy = p8FormsObj.userName?.name;
        newFormDataSRA.F51DateLastModified = p8FormsObj.formDataSRA.F5DateTested;
        p8FormsObj.saveNewFormOnlineOrOffline(`${p8FormsObj.uniqueFormName}_P81`, newFormDataObjectP81, 'P8-1', p8FormsObj.userName);
        p8FormsObj.saveNewFormOnlineOrOffline(`${p8FormsObj.uniqueFormName}_SRA`, newFormDataSRA, p8FormsObj.newTrfId, p8FormsObj.userName);
    }
};
