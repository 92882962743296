import DataGridComponent from 'components/DataGrid/DataGridComponent';
import React, { useState } from 'react';
import {
    rolesHeaderData, rolesApiEndpoint,
    rolesSearchState,
    rolesInitialState
} from './Roles.data';
import RolesForm from './RolesForm';

const addTitle = 'Add New Record for Roles';
const editTitle = 'Edit Record for Roles';

const Roles = () => {
    const apiEndpoints = rolesApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(rolesInitialState);
    const [modalTitle, setModalTitle] = useState(addTitle);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'RoleId',
        rowNumber: ''
    });
    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitle(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };
    const handleAddRecord = () => {
        setEditRowData(rolesInitialState);
        setModalTitle(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRefreshDatagrid(prev => !prev);
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
    };

    return (
        <>
            <DataGridComponent
                headerData={rolesHeaderData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                initialData={rolesSearchState}
                rowDetails={rowDetails}
                refreshDatagrid={refreshDatagrid}
            />

            {showModal
                && (
                    <RolesForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                    />
                )}
        </>
    );
};

export default Roles;
