import styled from 'styled-components';

export const StyledLabelForFilter = styled.div`
    margin-bottom: 5px;
    font-weight: 400;
    margin-left: 0;
    font-size: 8pt;
    width: 98%;
    text-align: center;
`;

export const SelectWrapper = styled.div`
    font-size : 11px;
`;
