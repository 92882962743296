import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import InputControls from '../../FormHelpers/InputControls';
import { selectToleranceInstrumentEUComment } from './ToleranceInstrumentEUComment.data';

const useStyles = formName => makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

const ToleranceInstrumentEUCommentSection = (props) => {
    const { selectedTag, formData, formName } = props;
    const classes = useStyles(formName)();
    return (
        <Grid container spacing={1} className={classes.root} style={{ marginTop: '5px' }}>
            {
                selectToleranceInstrumentEUComment(selectedTag, formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ paddingBottom: '0', paddingTop: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key} style={{ textAlign: 'end' }}>
                                <InputControls field={field} />
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    dpsData: Forms.dpsData,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ToleranceInstrumentEUCommentSection);

ToleranceInstrumentEUCommentSection.propTypes = {
    formData: PropTypes.object,
    formName: PropTypes.string,
    selectedTag: PropTypes.object
};
