import { COMMON_CONSTANTS } from './common.constants';

const commonState = {
    isMRATOnline: navigator.onLine,
    lastOnlineTime: ''
};

const commonReducer = (state = commonState, action) => {
    switch (action.type) {
        case COMMON_CONSTANTS.MRAT_ONLINEOFFLINESTATUSUPDATE:
        case COMMON_CONSTANTS.MRAT_SETLASTONLINETIME:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default commonReducer;
