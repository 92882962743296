import styled from 'styled-components';

export const AutocompleteWrapper = styled.div`
.MuiAutocomplete-input {
    height: 23px;
    padding: 1px 35px 2px 10px !important;
    font-size: 11px;
}
.MuiAutocomplete-inputRoot {
    padding: 0 !important;;
    height: 27px;
}
.MuiAutocomplete-listbox {
    margin: 0;
    font-size: 11px;
    overflow-x: hidden;
    word-wrap: break-word;
    max-height: 140px;
}
.MuiAutocomplete-noOptions {
    font-size: 11px;
    padding: 10px;
}
.MuiAutocomplete-li {
    max-height: 12px;
    height: 12px;
    font-size: 11px;
    overflow-x: hidden;
    word-wrap: break-word;
}
`;
