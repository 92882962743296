import React from 'react';
import { connect } from 'react-redux';
import Form from '../../FormsTemplate';
import { FormNames } from '../../Forms.constants';

import UserInfoSection from '../../Sections/UserInfoSection/UserInfoSection';
import ManufacturerModelSection from '../../Sections/ManufacturerModelSection/ManufacturerModelSection';
import HMISection from '../../Sections/HMISection/HMISection';
import VisualInspectSection from '../../Sections/VisualInspectSection/VisualInspectSection';
import ValveSizeSection from '../../Sections/ValveSizeSection/ValveSizeSection';
import FailureModeSection from '../../Sections/FailureModeSection/FailureModeSection';
import NoteSection from '../../Sections/NoteSection/NoteSection';
import BorderedBox from '../../BorderedBox';
import ToleranceInstrumentEUComment from '../../Sections/ToleranceInstrumentEUComment/ToleranceInstrumentEUComment';
import FireSuppressionSection from '../../Sections/FireSuppressionSection/FireSuppressionSection';
import FoundResultSection from '../../Sections/FoundResultSection/FoundResultSection';
import AsFoundSolenoidLimitSection from '../../Sections/AFT_SolenoidLimitSection/AsFoundSolenoidLimitSection';
import AsLeftSolenoidLimitSection from '../../Sections/ALT_SolenoidLimitSection/AsLeftSolenoidLimitSection';
import AsFoundIPTransmitterSection from '../../Sections/AsFoundIP_TransmitterSection/AsFoundIP_TransmitterSection';
import AsLeftIPTransmitterSection from '../../Sections/AsLeftIP_TransmitterSection/AsLeftIP_TransmitterSection';

const P731Form = (props) => (
    <Form
        formName={FormNames.P731}
    >
        <UserInfoSection />
        <ManufacturerModelSection />
        <HMISection />
        <VisualInspectSection />
        <BorderedBox>
            <FireSuppressionSection />
        </BorderedBox>
        <BorderedBox>
            <AsFoundSolenoidLimitSection />
            <AsFoundIPTransmitterSection />
            <FoundResultSection />
        </BorderedBox>
        <ToleranceInstrumentEUComment />
        <FailureModeSection />
        <BorderedBox>
            <AsLeftSolenoidLimitSection />
            <AsLeftIPTransmitterSection />
        </BorderedBox>
        <ValveSizeSection />
        <NoteSection />
    </Form>
);

const mapStateToProps = ({ Forms }) => ({
    selectedTag: Forms.selectedTag,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(P731Form);
