export const VisualInspectSectionDataM67 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect the condition of the device:
                a.	Record any details of the As Found condition for the Tx and Rx Optics (Flashing / clicking / clean / dirty etc.)
                b.	Inspect the mounting plate & cabling for mechanical damage, corrosion and general deterioration
                Note any other abnormal conditions in the Fault Log and raise a corrective MO if the fault cannot easily be rectified`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]

});
