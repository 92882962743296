import { conditionalValueSelector, ValidateField } from 'helpers/Validation';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

const disableHasEngApproved = formData => formData.F962HistoricalValveTimingUsed === 'NO';
const valueHasEngApproved = formData => disableHasEngApproved(formData) ? '' : 'NO';

export const ValveSizeTravelSectionDataS71 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F344ValveTravelTimeRequirementLabel',
                    label: 'Valve Travel Time Requirement (sec)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '20px 0 0 0',
                },
                {
                    key: 'F344ValveTravelTimeRequirement',
                    value: selectedTag.DateTested ? formData?.F344ValveTravelTimeRequirement : formData?.F344ValveTravelTimeRequirement ?? selectedTag?.DesignElementResponseTime,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    isRequired: formData.F956TrfTypeSelection === 'Full',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.25rem',
                    textAlign: 'center',
                    borderBottom: '0',
                    marginTop: '15px',
                },
                {
                    key: 'F957ValveFailureModeLabel',
                    label: 'Valve Failure Mode',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '20px 0 0 0',
                },
                {
                    key: 'F957ValveFailureMode',
                    value: conditionalValueSelector(formData.F957ValveFailureMode, formData.F957ValveFailureMode, 'FC'),
                    isDropdown: true,
                    isRequired: true,
                    options: [{ name: 'FC', value: 'FC' },
                    { name: 'FO', value: 'FO' }],
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    inputTextAlign: 'center',
                    borderBottom: '0',
                    noDefaultOption: true,
                    marginTop: '15px',
                }
            ]
        },
        {
            key: 2,
            fields: [{
                key: 'F958PreviousTwoTestClosureTimes1Label',
                label: 'Previous two test closure times (sec)',
                xs: 2,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '3px 0 0 0'
            },
            {
                key: 'F958PreviousTwoTestClosureTimes1',
                value: formData.F958PreviousTwoTestClosureTimes1,
                isRequired: formData.F956TrfTypeSelection === 'Full',
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0%',
                height: '1.2rem',
                textAlign: 'center',
                borderRight: '0',
                marginLeft: '0.9rem'
            }, {
                key: 'F959PreviousTwoTestClosureTimes2',
                value: formData.F959PreviousTwoTestClosureTimes2,
                isRequired: formData.F956TrfTypeSelection === 'Full',
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0%',
                height: '1.2rem',
                textAlign: 'center',
            },
            {
                key: 'F960PreviousTwoTestOpeningTimes1Label',
                label: 'Previous two test opening times (sec)',
                isLabel: true,
                labelWidth: '100%',
                textAlign: 'right',
                fontWeight: '600',
                fontSize: '9pt',
                padding: '3px 0 0 0',
                xs: 2,
                marginLeft: '0.6rem'
            },
            {
                key: 'F960PreviousTwoTestOpeningTimes1',
                value: formData.F960PreviousTwoTestOpeningTimes1,
                isRequired: formData.F956TrfTypeSelection === 'Full',
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0%',
                height: '1.2rem',
                textAlign: 'center',
                borderRight: '0',
                marginLeft: '0.9rem'
            },
            {
                key: 'F961PreviousTwoTestOpeningTimes2',
                value: formData.F961PreviousTwoTestOpeningTimes2,
                isRequired: formData.F956TrfTypeSelection === 'Full',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                height: '1.2rem',
                textAlign: 'center',
                fontSize: '16pt',
                type: 'number',
                maxLength: '10',
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F962HistoricalValveTimingUsedLabel',
                label: 'Historical Valve Timing Used?',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                marginTop: '10px',
            },
            {
                key: 'F962HistoricalValveTimingUsed',
                value: conditionalValueSelector(formData.F962HistoricalValveTimingUsed, formData.F962HistoricalValveTimingUsed, 'NO'),
                isDropdown: true,
                isRequired: true,
                options: [{ name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                noDefaultOption: true,
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                marginTop: '10px',
                height: '1.68rem',
            },
            {
                key: 'F963DateHistoricalValveTimingUsedLabel',
                label: 'Date of Historical Valve Timing (if used)',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                marginTop: '10px',
            },
            {
                key: 'F963DateHistoricalValveTimingUsed',
                value: formData.F963DateHistoricalValveTimingUsed,
                isRequired: ValidateField(formData.F962HistoricalValveTimingUsed === 'YES'),
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlign: 'center',
                height: '1.7rem',
                isDatefield: true,
                marginLeft: '0px',
                marginRight: '0px',
                padding: '10 0 0 0',
                fontSize: '18px',
                marginBottom: '0px',
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F964HasIandCEngApprovedLabel',
                label: 'Has I&C eng approved this value?',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '4px 0 0 0',
            },
            {
                key: 'F964HasIandCEngApproved',
                value: conditionalValueSelector(formData.F964HasIandCEngApproved && !disableHasEngApproved(formData), formData.F964HasIandCEngApproved, valueHasEngApproved(formData)),
                isRequired: !disableHasEngApproved(formData),
                isDisabled: disableHasEngApproved(formData),
                readOnly: disableHasEngApproved(formData),
                isDropdown: !disableHasEngApproved(formData),
                height: conditionalValueSelector(disableHasEngApproved(formData), '1.25rem', '1.5rem'),
                options: [{ name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                noDefaultOption: true,
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                borderTop: '0'
            }]
        }
    ]
});
