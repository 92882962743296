import {
    FailureModeLowPassFailResult,
    FailureModeHighPassFailResult,
    isFailModeHighCurrentDisabled,
    isFailModeLowCurrentDisabled,
    isFailureModeHighCurrentRequired,
    isFailureModeLowCurrentRequired,
    isBadPvorFaultOnHmiHcRequired,
    isBadPvorFaultOnHmiLcRequired
} from '../../FailureModeSection.formulas';
import { sraPlaceHolder } from '../../../SraDeviceSection/SraDeviceSection.data';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { FailureMode } from 'journeys/portal/Forms/Sections.constants';

export const FailureModeSectionDataP712 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'remark',
                label: FailureMode.FAILURE_MODE_LABEL,
                isLabel: true,
                fontWeight: '600',
                labelWidth: '100%',
                padding: '15px 20px 5px 0',
                fontSize: '8.5pt',
                color: '#ea7c34'
            },
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F36FailureModeLcLabel',
                    label: 'Failure Mode Low Current (mA)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F36FailureModeLc',
                    value: selectedTag.DateTested ? formData?.F36FailureModeLc : formData?.F36FailureModeLc ?? selectedTag?.FailureModeLowCurrentMA,
                    isRequired: isFailModeLowCurrentDisabled(formData) ? false : isFailureModeLowCurrentRequired(selectedTag, formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    isDisabled: isFailModeLowCurrentDisabled(formData),
                    readOnly: isFailModeLowCurrentDisabled(formData),
                },
                {
                    key: 'F33FailureModeHcLabel',
                    label: 'Failure Mode High Current (mA)',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F33FailureModeHc',
                    value: selectedTag.DateTested ? formData?.F33FailureModeHc : formData?.F33FailureModeHc ?? selectedTag?.FailureModeHighCurrentMA,
                    xs: 2,
                    isRequired: isFailModeHighCurrentDisabled(formData) ? false : isFailureModeHighCurrentRequired(selectedTag, formData),
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    isDisabled: isFailModeHighCurrentDisabled(formData),
                    readOnly: isFailModeHighCurrentDisabled(formData)
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F315TxshowBadPvorFaultOnHmiLcLabel',
                    label: 'Does TX show "Bad PV" or "Fault" on HMI?',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F315TxshowBadPvorFaultOnHmiLc',
                    value: formData.F315TxshowBadPvorFaultOnHmiLc,
                    isRequired: isFailModeLowCurrentDisabled(formData) ? false : isBadPvorFaultOnHmiLcRequired(selectedTag, formData),
                    isDropdown: isFailModeLowCurrentDisabled(formData) ? false : isBadPvorFaultOnHmiLcRequired(selectedTag, formData),
                    placeholder: sraPlaceHolder,
                    options: [{ name: sraPlaceHolder, value: '' },
                    { name: 'Yes', value: 'Yes' },
                    { name: 'No', value: 'No' }],
                    xs: 2,
                    borderTop: '0',
                    inputTextAlign: 'center',
                    width: '100%',
                    height: isFailModeLowCurrentDisabled(formData) ? '1.25rem' : '1.5rem',
                    labelWidth: '0%',
                    textAlign: 'center',
                    isDisabled: isFailModeLowCurrentDisabled(formData),
                    readOnly: isFailModeLowCurrentDisabled(formData),
                },
                {
                    key: 'F316TxshowBadPvorFaultOnHmiHcLabel',
                    label: 'Does TX show "Bad PV" or "Fault" on HMI?',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F316TxshowBadPvorFaultOnHmiHc',
                    value: formData.F316TxshowBadPvorFaultOnHmiHc,
                    isRequired: isFailModeHighCurrentDisabled(formData) ? false : isBadPvorFaultOnHmiHcRequired(selectedTag, formData),
                    isDropdown: isFailModeHighCurrentDisabled(formData) ? false : isBadPvorFaultOnHmiHcRequired(selectedTag, formData),
                    placeholder: sraPlaceHolder,
                    options: [{ name: sraPlaceHolder, value: '' },
                    { name: 'Yes', value: 'Yes' },
                    { name: 'No', value: 'No' }],
                    xs: 2,
                    borderTop: '0',
                    inputTextAlign: 'center',
                    width: '100%',
                    height: isFailModeHighCurrentDisabled(formData) ? '1.25rem' : '1.5rem',
                    labelWidth: '0%',
                    textAlign: 'center',
                    isDisabled: isFailModeHighCurrentDisabled(formData),
                    readOnly: isFailModeHighCurrentDisabled(formData),
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F38FormulaCalculatedPf2Label',
                    label: 'Failure Mode Low Pass/Fail Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F38FormulaCalculatedPf2',
                    value: FailureModeLowPassFailResult(formData),
                    isCalculatedNew: true,
                    readOnly: true,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    borderTop: '0',
                    height: '1.2rem',
                    textAlign: 'center',
                    isDisabled: isFailModeLowCurrentDisabled(formData)
                },
                {
                    key: 'F35FormulaCalculatedPf1Label',
                    label: 'Failure Mode High Pass/Fail Result',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F35FormulaCalculatedPf1',
                    value: FailureModeHighPassFailResult(formData),
                    isCalculatedNew: true,
                    readOnly: true,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    borderTop: '0',
                    textAlign: 'center',
                    isDisabled: isFailModeHighCurrentDisabled(formData)
                }
            ]
        },
        {
            key: 5,
            fields: [
                {
                    key: 'F304IsDampingValueTheSameAsfactorydefaultvalueLabel',
                    label: 'Is damping value the same as factory default value?',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F304IsDampingValueTheSameAsfactorydefaultvalue',
                    value: formData.F304IsDampingValueTheSameAsfactorydefaultvalue,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: sraPlaceHolder,
                    options: [{ name: sraPlaceHolder, value: '' },
                    { name: 'Yes', value: 'Yes' },
                    { name: 'No', value: 'No' }],
                    xs: 2,
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0%',
                    borderTop: isFailModeLowCurrentDisabled(formData) ? '' : '0',
                    textAlign: 'center',
                }
            ]
        },
    ]
});
