import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { StyledLabel } from 'elements/Label/Label';
import { FormDropdown } from 'elements/Inputs/FormInput/Dropdown/Dropdown';
import { FormInput, FormInputMultiline } from 'elements/Inputs/FormInput';
import { InputAutogrow } from 'elements/Inputs/FormInput/InputAutogrow/InputAutogrow';
import { selectHMITemplateForForm } from './HMISection.data';
import { StyleHMINewDropdown } from './HMISection.styled';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

const HMISection = (props) => {
    const { selectedTag, formName, changeHandler, formData, isFormReadOnly, userRole } = props;
    const classes = useStyles();
    const formDropInput = (field) => field.isDropdown ? (
        <StyleHMINewDropdown
            formName={formName}
        >
            <FormDropdown
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                placeholder={field.placeholder}
                handleChange={changeHandler}
                options={field.options}
                isRequired={field.isRequired}
                marginLeft={field.marginLeft}
                width={field.width}
                labelWidth={field.labelWidth}
                readOnly={isFormReadOnly}
                inputTextAlign={field.inputTextAlign}
                paddingRightPlaceholder={field.paddingRightPlaceholder}
                marginTop={field.marginTop}
                marginBottom={field.marginBottom}
                direction={field.direction}
                marginRight={field.marginRight}
                borderBottom={field.borderBottom}
                height={field.height}
            />
        </StyleHMINewDropdown>
    )
        : formLabel(field);

    const formMultilineInput = (field) => field.isMultiline
        ? (
            <FormInputMultiline
                rows={3}
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                type={field.type}
                isRequired={field.isRequired}
                isCalculated={field.isCalculated}
                isCalculatedNew={field.isCalculatedNew}
                handleChange={changeHandler}
                width={field.width}
                inputWidth={field.inputWidth}
                readOnly={isFormReadOnly}
                direction={field.direction}
                marginRight={field.marginRight}
                marginLeft={field.marginLeft}
                alignItems={field.alignItems}
            />
        )
        : formDropInput(field);

    const formLabel = (field) => field.isLabel
        ? (
            <StyledLabel
                id={field.key}
                fontWeight={field.fontWeight}
                fontSize={field.fontSize}
                textAlign={field.textAlign}
                labelWidth={field.labelWidth}
                padding={field.padding}
                direction={field.direction}
                color={field.color}
            >
                {field.label}
            </StyledLabel>
        ) : (
            <FormInput
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                color={field.color}
                isRequired={field.isRequired}
                isCalculated={field.isCalculated}
                isCalculatedNew={field.isCalculatedNew}
                textAlignLabel={field.textAlignLabel}
                textAlign={field.textAlign}
                handleChange={changeHandler}
                type={field.type}
                width={field.width}
                labelWidth={field.labelWidth}
                padding={field.padding}
                readOnly={field.readOnly || isFormReadOnly}
                maxLength={field.maxLength}
                direction={field.direction}
                marginBottom={field.marginBottom}
                marginRight={field.marginRight}
                marginLeft={field.marginLeft}
                borderTop={field.borderTop}
                borderBottom={field.borderBottom}
                borderLeft={field.borderLeft}
                borderRight={field.borderRight}
                height={field.height}
                marginTop={field.marginTop}
                userRole={userRole}
                hasDPSError={field.hasDPSError}
                hasHmiError={field.hasHmiError}
                hasInstError={field.hasInstError}
                hasDPSNotNullError={field.hasDPSNotNullError}
                isDisabled={field.isDisabled}
                inputWidth={field.inputWidth}
            />
        );
    return (
        <Grid container spacing={1} className={classes.root}>
            {
                selectHMITemplateForForm(selectedTag, formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ paddingBottom: '0', paddingTop: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isAutoGrow
                                    ? (
                                        <InputAutogrow
                                            id={field.key}
                                            name={field.key}
                                            value={field.value}
                                            maxLength={field.maxLength}
                                            handleChange={changeHandler}
                                            inputWidth={field.inputWidth}
                                            width={field.labelWidth}
                                            label={field.label}
                                            marginBottom={field.marginBottom}
                                            marginLeft={field.marginLeft}
                                            direction={field.direction}
                                            marginRight={field.marginRight}
                                            padding={field.padding}
                                            isCalculatedNew={field.isCalculatedNew}
                                            inputMarginTop={field.inputMarginTop}
                                            readOnly={isFormReadOnly}
                                            isDisabled={field.readOnly}
                                            isRequired={field.isRequired}
                                            textAlign={field.textAlign}
                                            marginTop={field.marginTop}
                                        />
                                    )
                                    : formMultilineInput(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    dpsData: Forms.dpsData,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HMISection);

HMISection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
