import React from 'react';
import { connect } from 'react-redux';
import Form from '../../FormsTemplate';
import { FormNames } from '../../Forms.constants';
import PropTypes from 'prop-types';

import UserInfoSection from '../../Sections/UserInfoSection/UserInfoSection';
import ManufacturerModelSection from '../../Sections/ManufacturerModelSection/ManufacturerModelSection';
import HMISection from '../../Sections/HMISection/HMISection';
import VisualInspectSection from '../../Sections/VisualInspectSection/VisualInspectSection';
import ValveSizeTravelSection from '../../Sections/ValveSizeTravelSection/ValveSizeTravelSection';
import AsFoundTestTableSection from '../../Sections/AsFoundTestTableSection/AsFoundTestTableSection';
import AsLeftTestTableSection from '../../Sections/AsLeftTestTableSection/AsLeftTestTableSection';
import NoteSection from '../../Sections/NoteSection/NoteSection';
import BorderedBox from '../../BorderedBox';
import FailureModeSection from '../../Sections/FailureModeSection/FailureModeSection';
import AttentionLabel from '../../Sections/AttentionLabel/AttentionLabel';
import AsFoundSolenoidLimitSection from '../../Sections/AFT_SolenoidLimitSection/AsFoundSolenoidLimitSection';
import AsLeftSolenoidLimitSection from '../../Sections/ALT_SolenoidLimitSection/AsLeftSolenoidLimitSection';
import ValveSizeSection from '../../Sections/ValveSizeSection/ValveSizeSection';
import FullPartialStrokeTest from '../../Sections/FullPartialStrokeTest/FullPartialStrokeTest';
import AsFoundPstMethodSection from '../../Sections/AsFound_PST_Method/AsFound_PST_Method';
import AsLeftTestRequiredSection from '../../Sections/AsLeftTestRequired/AsLeftTestRequired';
import MagneticFloatCheckSection from '../../Sections/MagneticFloatCheck/MagneticFloatCheck';

const S71Form = ({ formData }) => {
    const bordereConditionFull = (formData?.F956TrfTypeSelection === 'Full');
    const bordereConditionPartial = (formData?.F956TrfTypeSelection === 'Partial');
    return (
        <Form
            formName={FormNames.S71}
        >
            <AttentionLabel />
            <UserInfoSection />
            <FullPartialStrokeTest />
            <ManufacturerModelSection />
            <HMISection />
            <VisualInspectSection />
            {bordereConditionFull && <ValveSizeTravelSection />}
            {bordereConditionFull && (
                <BorderedBox>
                    <AsFoundSolenoidLimitSection />
                </BorderedBox>
            )}
            {bordereConditionFull && <AsLeftTestRequiredSection />}
            {bordereConditionFull && (
                <BorderedBox>
                    <AsLeftSolenoidLimitSection />
                </BorderedBox>
            )}
            {bordereConditionFull && <MagneticFloatCheckSection />}
            {bordereConditionPartial && <AsFoundPstMethodSection />}
            {bordereConditionPartial && (
                <BorderedBox>
                    <AsFoundTestTableSection />
                </BorderedBox>
            )}
            {bordereConditionPartial && <FailureModeSection />}
            {bordereConditionPartial && (
                <BorderedBox>
                    <AsLeftTestTableSection />
                </BorderedBox>
            )}
            <ValveSizeSection />
            <NoteSection />
        </Form>
    );
};
const mapStateToProps = ({ Forms }) => ({
    formData: Forms.updatedFormDataObj,
});
const mapDispatchToProps = dispatch => ({
    dispatch,
});

S71Form.propTypes = {
    formData: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(S71Form);
