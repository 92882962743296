import { ValidateField, FieldHasValue, ExponentialToDecimal, ValidateNumberField } from 'helpers/Validation';

export const AsFoundF215AftDeviation1Formula = (formData) => FieldHasValue(formData.F501AftReferenceCalibrationGas)
    && FieldHasValue(formData.F356AftAnalyzerReading)
    ? ExponentialToDecimal(Math.abs(ValidateField(formData.F356AftAnalyzerReading) - ValidateField(formData.F501AftReferenceCalibrationGas))) : '';

export const AsFoundResultFormula = (formData) => {
    if (FieldHasValue(formData.F215AftDeviation1)
        && FieldHasValue(formData.F101AftTolerance)) {
        if (ValidateNumberField(formData.F215AftDeviation1) > ValidateNumberField(formData.F101AftTolerance)) {
            return 'Fail';
        }
        return 'Pass';
    }
    return '';
};
