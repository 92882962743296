import { ValveSizeStrokeTestResultFormula } from './C12.formulas';
import { placeholderName } from '../../../AsFoundTestTableSection/Data/P610/P610.data';
import { valvePlaceHolder } from '../P610/P610.data';
import { valveSizeValue } from '../../../HMISection/Data/P725/P725.data';
import { failedComponentsOptions } from '../P716/P716.data';
import { FailedComponent } from '../../../../Sections.constants';

export const ValveSizeSectionDataC12 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'NodataC12',
                isLabel: true,
                xs: 12,
                padding: '20px 0 0 0'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F340ValveSize',
                    label: 'Valve Size (inch)',
                    value: valveSizeValue(selectedTag, formData),
                    isRequired: true,
                    xs: 6,
                    width: '33.7%',
                    labelWidth: '42.4%',
                    height: '1.2rem',
                    type: 'number',
                    maxLength: '10',
                    textAlign: 'center',
                    borderBottom: '0'
                },
                {
                    key: 'F344ValveTravelTimeRequirement',
                    label: 'Valve Travel Time Requirement (sec)',
                    value: selectedTag.DateTested ? formData?.F344ValveTravelTimeRequirement : formData?.F344ValveTravelTimeRequirement ?? selectedTag?.DesignElementResponseTime,
                    xs: 6,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    marginRight: '0.5rem',
                    width: '38.5%',
                    labelWidth: '75%',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderBottom: '0',
                    direction: 'rtl',
                },
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F341ValveFullStrokeLocalPosition',
                    label: 'Valve Full Stroke Local Position',
                    value: formData.F341ValveFullStrokeLocalPosition,
                    isRequired: true,
                    xs: 6,
                    isDropDown: true,
                    placeholder: placeholderName,
                    options: [
                        { name: placeholderName, value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                    width: '39.9%',
                    labelWidth: '42.4%',
                    height: '1.4rem',
                    inputTextAlign: 'center',
                    borderBottom: '0'
                },
                {
                    key: 'F345ValveFullStrokeHmipositionIndication',
                    label: 'Valve Full Stroke HMI Position Indication',
                    value: formData.F345ValveFullStrokeHmipositionIndication,
                    isDropDown: true,
                    placeholder: placeholderName,
                    options: [
                        { name: placeholderName, value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                    marginRight: '0.5rem',
                    xs: 6,
                    width: '37.9%',
                    labelWidth: '75%',
                    height: '1.4rem',
                    inputTextAlign: 'center',
                    borderBottom: '0',
                    direction: 'rtl',
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F342ValveFullStrokeTravelTime',
                    label: 'Valve Full Stroke Travel Time (sec)',
                    value: formData.F342ValveFullStrokeTravelTime,
                    isRequired: true,
                    xs: 6,
                    width: '33.7%',
                    labelWidth: '42.4%',
                    type: 'number',
                    maxLength: '10',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderBottom: '0'
                },
                {
                    key: 'F346StrokeTestResult',
                    label: 'Stroke Test Result',
                    value: ValveSizeStrokeTestResultFormula(formData),
                    isCalculatedNew: true,
                    xs: 6,
                    readOnly: true,
                    marginRight: '0.5rem',
                    width: '38.5%',
                    labelWidth: '75%',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderBottom: '0',
                    direction: 'rtl',
                },
            ]
        },
        {
            key: 5,
            fields: [
                {
                    key: 'F343PhysicalValvePositionAfterTest',
                    label: 'Physical Valve Position After Test',
                    value: formData.F343PhysicalValvePositionAfterTest,
                    isDropDown: true,
                    isRequired: true,
                    placeholder: valvePlaceHolder,
                    options: [
                        { name: valvePlaceHolder, value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                    padding: '0',
                    xs: 6,
                    width: '39.9%',
                    labelWidth: '42.4%',
                    inputTextAlign: 'center',
                    height: '1.5rem'
                },
                {
                    key: 'F347HmivalvePositionAfterTest',
                    label: 'HMI Valve Position After Test',
                    value: formData.F347HmivalvePositionAfterTest,
                    xs: 6,
                    isDropDown: true,
                    placeholder: valvePlaceHolder,
                    options: [
                        { name: valvePlaceHolder, value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                    marginRight: '0.5rem',
                    width: '37.9%',
                    labelWidth: '75%',
                    height: '1.5rem',
                    inputTextAlign: 'center',
                    borderBottom: '0',
                    direction: 'rtl',
                },
            ]
        },
        {
            key: 6,
            fields: [
                {
                    key: 'Nodata6',
                    xs: 6,
                    isLabel: true,
                    padding: '0'
                },
                {
                    key: 'F85TestResultLabel',
                    label: 'Test Result',
                    xs: 2,
                    isLabel: true,
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '5px 0 0 0'
                },
                {
                    key: 'F85TestResult',
                    value: formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: [{ name: 'Select Test Result', value: '' },
                    { name: 'Pass', value: 'Pass' },
                    { name: 'Fail', value: 'Fail' }],
                    xs: 4,
                    labelWidth: '0',
                    width: '100%',
                    borderBottom: (formData.F85TestResult === 'Fail') ? '0' : ''
                }
            ]
        },
        {
            key: 7,
            fields: [
                {
                    key: 'NodataC12',
                    xs: 6,
                    isLabel: true,
                    padding: '0'
                },
                {
                    key: 'F348FailedComponentsLabel',
                    label: 'Failed Components',
                    xs: 2,
                    fontWeight: '600',
                    isLabel: true,
                    padding: '5px 0 0 0',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                },
                {
                    key: 'F348FailedComponents',
                    value: formData.F85TestResult === 'Fail' ? formData.F348FailedComponents : '',
                    placeholder: FailedComponent.SEARCH_FAILED_COMPONENTS,
                    isRequired: formData.F85TestResult === 'Fail',
                    isDisabled: formData.F85TestResult !== 'Fail',
                    readOnly: formData.F85TestResult !== 'Fail',
                    isFailedDropDown: formData.F85TestResult === 'Fail',
                    options: failedComponentsOptions,
                    xs: 2,
                    fontSize: '8.4pt',
                    labelWidth: '0',
                    width: '100%',
                    borderTop: '0',
                    paddingLeft: '1px'
                },
                {
                    key: 'F349FailedComponents1',
                    value: formData.F85TestResult === 'Fail' ? formData.F349FailedComponents1 : '',
                    placeholder: FailedComponent.SEARCH_FAILED_COMPONENTS,
                    isDisabled: formData.F85TestResult !== 'Fail',
                    readOnly: formData.F85TestResult !== 'Fail',
                    isFailedDropDown: formData.F85TestResult === 'Fail',
                    options: failedComponentsOptions,
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    fontSize: '8.4pt',
                    borderTop: '0',
                    borderLeft: '0',
                    paddingLeft: '1px'
                },
            ]
        },
        {
            key: 8,
            fields: [
                {
                    key: 'Nodata7',
                    label: '',
                    xs: 8,
                    isLabel: true
                },
                {
                key: 'remark',
                label: 'Remark: Record manufacturer and model of failed components on the TRF.',
                isLabel: true,
                xs: 4,
                fontWeight: '600',
                labelWidth: '100%',
                fontSize: '8.5pt',
                color: '#ea7c34'
            }]
        },
    ]

});
