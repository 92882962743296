export const placeholderName = 'Select ON or OFF';
export const HMISectionDataM616 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F653StatusNotificationAppliancePtLabel',
            label: 'Status of Notification Appliance Prior to Test',
            xs: 4,
            isLabel: true,
            fontWeight: '600',
            fontSize: '9pt',
            padding: '18px 0 0 0'
        },
        {
            key: 'F653StatusNotificationAppliancePt',
            value: formData.F653StatusNotificationAppliancePt,
            isDropdown: true,
            isRequired: true,
            placeholder: placeholderName,
            options: [
                { name: placeholderName, value: '' },
                { name: 'ON', value: 'ON' },
                { name: 'OFF', value: 'OFF' }],
            labelWidth: '0%',
            width: '100%',
            marginTop: '15px',
            height: '1.7rem',
            inputTextAlign: 'center',
            paddingRightPlaceholder: '15px',
            xs: 2
        }]
    }]
});
