export const WIDTH = {
    DESKTOP_LARGE: 1440,
    DESKTOP_MIN: 992,
    TABLET_WIDTH: 769,
    MOBILE_MAX: 460
};

export const minWidth = 'min-width: ';
export const maxWidth = 'max-width: ';

export const MOBILE_GRID_GUTTER = 21;

const mrat = {
    colors: {

        greyLight: '#EAEAEA',
        white: '#FFFFFF',
        blackFont: '#333333',
        bluePrimary: '#00667F',
        greyDark: '#707070',
        black: '#000000',
        blueLight: '#81A2B0', // for footer icons
        blueGreen: '#027c66',
        disableBlueGreen: '#a4c9c2',
        tableBlue: '#00667f'

    },
    icon: {
        sizes: {
            smaller: '0.55rem',
            small: '0.75rem',
            mediumSmall: '0.95rem',
            medium: '1.125rem',
            large: '1.5rem',
            super: '2.5rem',
            extraLarge: '3.5rem'
        },
        borderRadius: {
            circle: { medium: '1.5em' }
        }
    },
    fonts: {
        primary: 'Arial-regular',
        secondary: 'Arial-regular',
        sizes: {
            h32: '2rem',
            h24: '1.5rem',
            h21: '1.3125rem',
            h20: '1.25rem',
            h19: '1.2rem',
            body16: '1rem',
            body14: '0.875rem',
            body12: '0.75rem',
            body10: '0.675rem',
            body8: '0.535rem'
        }
    },
    media: {
        mobile: minWidth.concat(WIDTH.MOBILE_MAX, 'px'),
        mobileMax: maxWidth.concat(WIDTH.MOBILE_MAX, 'px'),
        tablet: minWidth.concat(WIDTH.TABLET_WIDTH, 'px'),
        tabletMax: maxWidth.concat(WIDTH.TABLET_WIDTH, 'px'),
        desktop: minWidth.concat(WIDTH.DESKTOP_MIN, 'px'),
        desktopMax: maxWidth.concat(WIDTH.DESKTOP_MIN, 'px'),
        desktopLarge: minWidth.concat(WIDTH.DESKTOP_LARGE, 'px')
    }
};

const theme = mrat;

export default theme;
