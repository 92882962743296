import { signalToTriggerIsErrorCheck } from '../../../SignalToTrigger/SignalToTrigger.data';

export const AuxiliaryProcessSectionDataP714 = (formData, formName) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AuxiliaryProcessReadings',
                label: 'Auxiliary Process Readings',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 5px 3px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F816ProcessTmpReading',
                label: 'Process Temperature Reading of Flow Tx',
                value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F816ProcessTmpReading : '',
                xs: 6,
                maxLength: '20',
                width: '32.5%',
                textAlign: 'center',
                labelWidth: '67.5%',
                marginLeft: '2px',
                borderBottom: '0',
                readOnly: signalToTriggerIsErrorCheck(formName, formData),
                isDisabled: signalToTriggerIsErrorCheck(formName, formData),
            },
            {
                key: 'F817ProcessTmpReadingIndependentLabel',
                label: 'Process Temperature Reading of Independent Tx',
                xs: 4,
                isLabel: 'true',
                labelWidth: '98%',
                textAlign: 'end',
                fontSize: '9pt',
                fontWeight: '600',
                padding: '4px 0 0 0',
            },
            {
                key: 'F817ProcessTmpReadingIndependent',
                value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F817ProcessTmpReadingIndependent : '',
                xs: 2,
                maxLength: '20',
                labelWidth: '0',
                width: '100%',
                textAlign: 'center',
                marginLeft: '2px',
                borderBottom: '0',
                borderRight: '0',
                readOnly: signalToTriggerIsErrorCheck(formName, formData),
                isDisabled: signalToTriggerIsErrorCheck(formName, formData),
            }
            ]
        },
        {
            key: 3,
            fields: [{
                key: 'F818ProcessPressReading',
                label: 'Process Pressure Reading of Flow Tx',
                value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F818ProcessPressReading : '',
                maxLength: '20',
                xs: 6,
                textAlign: 'center',
                width: '32.5%',
                labelWidth: '67.5%',
                marginLeft: '2px',
                fontSize: '9pt',
                fontWeight: '600',
                readOnly: signalToTriggerIsErrorCheck(formName, formData),
                isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                borderBottom: '0',
            },
            {
                key: 'F819ProcessPressReadingIndependentLabel',
                label: 'Process Pressure Reading of Independent Tx',
                xs: 4,
                isLabel: 'true',
                labelWidth: '98%',
                textAlign: 'end',
                fontSize: '9pt',
                fontWeight: '600',
                padding: '4px 0 0 0',
            },
            {
                key: 'F819ProcessPressReadingIndependent',
                value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F819ProcessPressReadingIndependent : '',
                maxLength: '20',
                xs: 2,
                labelWidth: '0',
                width: '100%',
                textAlign: 'center',
                marginLeft: '2px',
                borderRight: '0',
                readOnly: signalToTriggerIsErrorCheck(formName, formData),
                isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                borderBottom: '0',
            }]
        }
    ]

});
