import { FieldHasValue } from 'helpers/Validation';
import { getAltDeleteIconStatus } from '../../AsLeftTestTableSection.helpers';

export const AsLeftTestDeviationFormula = (formData) => {
    if (formData.F722TransducerOutputSignal === 'Voltage') {
        return 'mV';
    }
    if (formData.F722TransducerOutputSignal === 'Current') {
        return 'µA';
    }
    return '';
};

export const AsLeftResultMandatoryCheck = (formData, altTestPoint) => {
    let flag = false;
    if (!getAltDeleteIconStatus()) {
        for (let index = 2; index < Number(altTestPoint) + 2; index += 1) {
            if (!FieldHasValue(formData[`AltVibrationFrequency${index}`]) || !FieldHasValue(formData[`AltDeviation${index}`])) {
                flag = false;
                break;
            }
            flag = true;
        }
    }
    return flag;
};

export const AltOutputOffsetFormula = (formData) => {
    if (formData.F722TransducerOutputSignal === 'Voltage') {
        return 'Output Offset Voltage (V DC)';
    }
    if (formData.F722TransducerOutputSignal === 'Current') {
        return 'Output Offset Current (mA DC)';
    }
    return 'Output Offset';
};

export const setAltTestPoint = (formData, altTestPoint) => {
    if (formData.F102AftResult !== 'PASS') {
        return formData?.F721NumberOfTestPoints ?? altTestPoint;
    }
    return '';
};
