export const TestResultSectionDataP851 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F82HmiReadingAt',
                    label: 'HMI Reading after Test',
                    value: formData.F82HmiReadingAt,
                    isRequired: true,
                    xs: 4,
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '50%',
                    width: '50%',
                    textAlignLabel: 'left',
                    height: '1.2rem',
                    textAlign: 'center'
                },
                {
                    key: 'Nodata',
                    xs: 1,
                    isLabel: true
                },
                {
                    key: 'F83ProcessGaugeReadingAtLabel',
                    label: 'Process Gauge Reading after Test',
                    labelWidth: '97.3%',
                    xs: 3,
                    padding: '5px 0 0 0',
                    isLabel: 'true',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F83ProcessGaugeReadingAt',
                    value: formData.F83ProcessGaugeReadingAt,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    xs: 4,
                    height: '1.2rem',
                    marginTop: '2px',
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    borderBottom: '0',
                    marginLeft: '0.8px'
                }]
        }]
});
