import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { parseDate } from 'helpers/DateFormatter';
import { AddButton } from 'elements/CustomHeaderButtons/HeaderButtons';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import SearchBox from 'elements/SearchBox/SearchBox';
import DataTable from 'components/DataTable/DataTable';
import { redTableCellStyle } from 'components/DataTable/DataTable.styled';
import { updatedTabledefination } from './TestEquipment.constants';
import { searchTestEquipment, reSetTestTotalCount, setDeviceTypeSortOrder, setStatusSortOrder } from './TestEquipment.actions';
import { setDataForEditTestEquipment } from './AddEditTestEquipmentForm/TestEquipmentForm.actions';
import { setCurrentView } from '../Home/Home.actions';

const TestEquipmentPage = (props) => {
  const { initializeTestEquipmentData } = props;
  const { setCurrentAppView, isMRATOnline, testEquipmentDataAll, testEquipmentTableData, triggerSearch, isTableLoading,
    totalCount, searchStringForTestEq, setStatusSortAscOrder, setDeviceTypeSortAscOrder, previousScreen } = props;
  const [searchString, handleSearchString] = useState(searchStringForTestEq);
  const tableData = searchString.length === 0 ? testEquipmentDataAll : testEquipmentTableData;

  const testEquipmentButtonHandler = () => {
    setCurrentAppView('addTestEquipmentform');
  };

  const now = new Date();
  const { redTableCell } = redTableCellStyle();

  const editButtonHandler = (rowdata) => {
    initializeTestEquipmentData(rowdata);
    setCurrentAppView('editTestEquipmentform');
  };
  const getParsedTableData = () => tableData.map((item) => ({
    ...item,
    CalDueDate: parseDate(`${item.CalDueDate}`),
    IsCellRedClass: (item.CalDueDate && new Date(item.CalDueDate).setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0))
      ? redTableCell : null,
    FormDeviceType: item.DeviceType?.slice(0, 20),
    FormManufacturer: item.TestEqManufacturer?.slice(0, 20),
    FormModel: item.TestEqModel?.slice(0, 20),
  }));

  const handleSearch = (searchValue) => {
    handleSearchString(searchValue);
    triggerSearch(searchValue);
  };

  const sortButtonHandler = (columnName) => {
    if (columnName === 'FormDeviceType') {
      setDeviceTypeSortAscOrder(columnName);
    } else {
      setStatusSortAscOrder(columnName);
    }
  };

  return (
    <SiteWrapper
      headerText="Test Equipment Inventory"
      ButtonSection={isMRATOnline && <AddButton id="addButtonOfTest" buttonHandler={testEquipmentButtonHandler} />}
      showTotalCount
      totalCount={totalCount}
      goBack={previousScreen}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <SearchBox
              value={searchStringForTestEq}
              handleSearch={handleSearch}
              margin="5px"
              width="400px"
            />
            <DataTable
              id="TestEquipmentDataTable"
              tableDefinition={updatedTabledefination}
              tableData={getParsedTableData()}
              showArrowButton
              isLoading={isTableLoading}
              sortButtonHandler={sortButtonHandler}
              actionButtonHandler={(dataRow) => editButtonHandler(dataRow)}
            />
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};

const mapStateToProps = ({ AppData, TestEquipment, Home }) => ({
  previousScreen: Home.previousScreen,
  testEquipmentDataAll: TestEquipment.testEquipmentDataAll,
  testEquipmentTableData: TestEquipment.testEquipmentTableData,
  isTableLoading: TestEquipment.tableLoading || false,
  isMRATOnline: AppData.isMRATOnline,
  totalCount: TestEquipment.totalCount,
  searchStringForTestEq: TestEquipment.searchStringForTestEq,
  sortDeviceTypeAscOrder: TestEquipment.sortDeviceTypeAscOrder,
  sortStatusAscOrder: TestEquipment.sortStatusAscOrder,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setCurrentAppView: (view) => dispatch(setCurrentView(view)),
  triggerSearch: (value) => dispatch(searchTestEquipment(value)),
  initializeTestEquipmentData: (rowdata) => dispatch(setDataForEditTestEquipment(rowdata)),
  resetTestTotalCount: () => { dispatch(reSetTestTotalCount()); },
  setDeviceTypeSortAscOrder: (columnName) => { dispatch(setDeviceTypeSortOrder(columnName)); },
  setStatusSortAscOrder: (columnName) => { dispatch(setStatusSortOrder(columnName)); }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestEquipmentPage);

TestEquipmentPage.propTypes = {
  initializeTestEquipmentData: PropTypes.func.isRequired,
  isMRATOnline: PropTypes.bool,
  isTableLoading: PropTypes.bool,
  previousScreen: PropTypes.string,
  searchStringForTestEq: PropTypes.string,
  setCurrentAppView: PropTypes.func,
  setDeviceTypeSortAscOrder: PropTypes.func,
  setStatusSortAscOrder: PropTypes.func,
  testEquipmentDataAll: PropTypes.array.isRequired,
  testEquipmentTableData: PropTypes.array.isRequired,
  totalCount: PropTypes.number,
  triggerSearch: PropTypes.func.isRequired
};
