export const prodMultiroleLoginExclusionHeaderData = [
    {
        field: 'RowId',
        headerName: 'Row ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'UserId',
        headerName: 'User ID',
        width: '200',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'Active',
        headerName: 'Status',
        width: '85',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Justification',
        headerName: 'Justification',
        width: '220',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '140',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedBy',
        headerName: 'Last Modified By',
        width: '140',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comment',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const prodMultiroleLoginExclusionInitialState = {
    RowId: '',
    UserId: '',
    Active: 'Inactive',
    Justification: '',
    CreatedBy: '',
    CreatedDate: '',
    LastModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const prodMultiroleLoginExclusionSearchState = {
    RowId: '',
    UserId: '',
    Active: '',
    Justification: '',
    CreatedBy: '',
    CreatedDate: '',
    LastModifiedBy: '',
    LastModifiedDate: '',
    Comments: ''
};

export const prodMultiroleLoginExclusionApiEndpoint = {
    read: 'ADMIN_GetProdMultiroleLoginExclusion'
};
