export const FailureModeSectionDataP733P620 = (formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'remark',
            label: `Remark: Heater trips may be executed in electrical switchboard cubicle or electrical control panel (ECP).
             Heaters with thyristor stages are usually tripped from ECP.`,
            isLabel: true,
            xs: 12,
            fontWeight: '600',
            labelWidth: '100%',
            padding: '15px 20px 5px 0',
            fontSize: '8.5pt',
            color: '#ea7c34'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'F439ContactorFailState',
            label: 'Test Mode',
            value: formData.F439ContactorFailState,
            isDropdown: true,
            placeholder: 'Select Test mode',
            options: [{ name: 'Select Test mode', value: '' },
            { name: 'Live: Duty/Operating Heater Trip', value: 'Live: Duty/Operating Heater Trip' },
            { name: 'Offline: Standby/Isolated Heater Test', value: 'Offline: Standby/Isolated Heater Test' }],
            isRequired: true,
            xs: 6,
            width: '48.5%',
            labelWidth: '50%',
            marginRight: '0.5rem',
            marginBottom: '15px',
            inputTextAlign: 'center',
        },
        {
            key: 'F599TripExecutionVia',
            label: 'Trip Execution Via',
            value: formData.F599TripExecutionVia,
            isDropdown: true,
            isRequired: true,
            placeholder: 'Select Trip Execution',
            options: [{ name: 'Select Trip Execution', value: '' },
            { name: 'Electrical Control Panel', value: 'Electrical Control Panel' },
            { name: 'Electrical Switchboard Cubicle', value: 'Electrical Switchboard Cubicle' }],
            xs: 6,
            labelWidth: '39%',
            marginRight: '0.5rem',
            direction: 'rtl',
            width: '61%',
            marginBottom: '15px',
            inputTextAlign: 'center',
        }]
    }]
});
