import styled from 'styled-components';

export const CheckBoxWrapper = styled.div`
.MuiIconButton-colorSecondary {
    color:  ${props => props.color ? props.color : '#498205'};
}
.MuiCheckbox-colorSecondary.Mui-checked {
    color:  ${props => props.color ? props.color : '#498205'};
}
.MuiCheckbox-colorSecondary.Mui-disabled {
    color: ${props => props.color ? props.color : '#498205'};
}
.MuiFormControlLabel-label.Mui-disabled {
    color: ${props => props.color ? props.color : '#498205'};
}
.MuiTypography-body1 {
    font-size: 10pt;
    margin-top: ${props => props.historicalPrintScreen ? '5px' : ''};
    line-height: ${props => props.label === 'Print Select All' ? '0' : '1.6'};
    padding-top: ${props => props.label === 'Print Select All' ? '8px' : '0px'};
}
.MuiFormControlLabel-labelPlacementTop {
    margin-left: 6px;
}
text-align: center;
padding-bottom: 0;
color:  ${props => props.color ? props.color : '#498205'};
visibility: ${props => props.visibility ? props.visibility : 'visible'}
`;
