import DataGridComponent from 'components/DataGrid/DataGridComponent';
import React, { useState } from 'react';
import {
    adGroupHeaderData, adGroupApiEndpoint,
    adGroupSearchState,
    adGroupInitialState
} from './AdGroupType.data';
import AdGroupTypeForm from './AdGroupTypeForm';

const addTitle = 'Add New Record for AD Group Type';
const editTitle = 'Edit Record for AD Group Type';

const AdGroupType = () => {
    const apiEndpoints = adGroupApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(adGroupInitialState);
    const [modalTitle, setModalTitle] = useState(addTitle);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'AdGroupTypeId',
        rowNumber: ''
    });
    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitle(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };
    const handleAddRecord = () => {
        setEditRowData(adGroupInitialState);
        setModalTitle(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRefreshDatagrid(prev => !prev);
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
    };

    return (
        <>
            <DataGridComponent
                headerData={adGroupHeaderData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                initialData={adGroupSearchState}
                rowDetails={rowDetails}
                refreshDatagrid={refreshDatagrid}
            />

            {showModal
                && (
                    <AdGroupTypeForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                    />
                )}
        </>
    );
};

export default AdGroupType;
