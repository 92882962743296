export const placeholderName = 'Select Physical Position';
export const HMISectionDataM71 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F661DischargeValvePositionPtLabel',
            label: 'Discharge Valve Position Prior to Test',
            xs: 4,
            isLabel: true,
            fontWeight: '600',
            fontSize: '9pt',
            padding: '18px 0 0 0'
        },
        {
            key: 'F661DischargeValvePositionPt',
            value: formData.F661DischargeValvePositionPt,
            isDropdown: true,
            isRequired: true,
            placeholder: placeholderName,
            options: [
                { name: placeholderName, value: '' },
                { name: 'Open', value: 'Open' },
                { name: 'Closed', value: 'Closed' }],
            labelWidth: '0%',
            width: '100%',
            marginTop: '15px',
            height: '1.7rem',
            inputTextAlign: 'center',
            paddingRightPlaceholder: '15px',
            xs: 2
        }]
    }]
});
