import {
    HmiInst1FormulaP63,
    HmiInst2FormulaP63,
    HmiInst3FormulaP63,
    AsLeftTestTableDeviationEu1Formula,
    AsLeftTestTableDeviationEu2Formula,
    AsLeftTestTableDeviationEu3Formula,
    F103AltResultFormula
} from '../P63/P63.formula';
import { paddingAF } from '../M71/M71.data';
import { isEnabledcondition } from '../M611/M611.data';

export const asLeftTestTableSectionDataP74 = (selectedTag, formData) => {
    const disableField = formData.F62SensorTrimNeed === 'NO';
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsLeftTest',
                    label: 'As-Left Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'HMI-Inst',
                    label: '% - Inst.',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: '5px 0 5px 8px',
                    fontSize: '9pt'
                },
                {
                    key: 'TestDeviceIndication',
                    label: 'Test Device Indication',
                    isLabel: true,
                    xs: 2,
                    textAlign: 'center',
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'TX Reading',
                    label: 'TX Reading',
                    isLabel: true,
                    xs: 2,
                    textAlign: 'center',
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'HMI Reading',
                    label: 'HMI Reading',
                    isLabel: true,
                    xs: 2,
                    textAlign: 'center',
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'mA Reading',
                    label: 'mA Reading',
                    isLabel: true,
                    xs: 2,
                    textAlign: 'center',
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'Deviation (EU)',
                    label: 'Deviation (EU)',
                    isLabel: true,
                    xs: 2,
                    textAlign: 'center',
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: paddingAF,
                    fontSize: '9pt'
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'F225AltHmiInst1',
                    value: isEnabledcondition(HmiInst1FormulaP63(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F323AltRtdSimulatorReading1',
                    value: isEnabledcondition(formData.F323AltRtdSimulatorReading1, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F231AltTxreading1',
                    xs: 2,
                    value: isEnabledcondition(formData.F231AltTxreading1, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F109AltHmireading1',
                    value: isEnabledcondition(formData.F109AltHmireading1, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F234AltMAreading1',
                    value: isEnabledcondition(formData.F234AltMAreading1, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0'
                },
                {
                    key: 'F237AltDeviation1',
                    value: isEnabledcondition(AsLeftTestTableDeviationEu1Formula(formData), disableField),
                    isDisabled: disableField,
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px'
                }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F226AltHmiInst2',
                    value: isEnabledcondition(HmiInst2FormulaP63(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F324AltRtdSimulatorReading2',
                    value: isEnabledcondition(formData.F324AltRtdSimulatorReading2, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F232AltTxreading2',
                    value: isEnabledcondition(formData.F232AltTxreading2, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F113AltHmireading2',
                    value: isEnabledcondition(formData.F113AltHmireading2, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F235AltMAreading2',
                    value: isEnabledcondition(formData.F235AltMAreading2, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0'
                },
                {
                    key: 'F238AltDeviation2',
                    value: isEnabledcondition(AsLeftTestTableDeviationEu2Formula(formData), disableField),
                    isDisabled: disableField,
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px'
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F227AltHmiInst3',
                    value: isEnabledcondition(HmiInst3FormulaP63(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F325AltRtdSimulatorReading3',
                    value: isEnabledcondition(formData.F325AltRtdSimulatorReading3, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    borderRight: '0',
                    textAlign: 'center',
                    height: '1.2rem'
                },
                {
                    key: 'F233AltTxreading3',
                    value: isEnabledcondition(formData.F233AltTxreading3, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0'
                },
                {
                    key: 'F114AltHmireading3',
                    value: isEnabledcondition(formData.F114AltHmireading3, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0'
                },
                {
                    key: 'F236AltMAreading3',
                    value: isEnabledcondition(formData.F236AltMAreading3, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0'
                },
                {
                    key: 'F239AltDeviation3',
                    value: isEnabledcondition(AsLeftTestTableDeviationEu3Formula(formData), disableField),
                    isDisabled: disableField,
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    inputMarginRight: '4px'
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'NoData2',
                    xs: 4,
                    isLabel: true
                },
                {
                    key: 'F103AltResultLabel',
                    value: 'As-Left Result',
                    readOnly: true,
                    xs: 2,
                    isLabelBold: true,
                    textAlign: 'end',
                    labelWidth: '0',
                    width: '100%',
                    height: '1.1rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    borderTop: '0',
                    marginBottom: '4px'
                },
                {
                    key: 'F103AltResult',
                    value: F103AltResultFormula(formData),
                    readOnly: true,
                    isCalculatedNew: true,
                    isDisabled: disableField,
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    height: '1.1rem',
                    borderTop: disableField ? '' : '0',
                    inputMarginRight: '-2px',
                    textAlign: 'center',
                    borderBottom: '0',
                    marginBottom: '4px'
                }]
            }]
    };
};
