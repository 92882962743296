import axios from 'axios';
import { APIEndpoints } from 'helpers/APILists';
import DataGridComponent from 'components/DataGrid/DataGridComponent';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import _ from 'lodash';
import React, { useState } from 'react';
import { trackErrors } from '../helper';
import {
    dpsSourceMappingData, dpsSourceMappingApiEndpoint,
    searchDataInitialState, dpsSourceMappingInitialState
} from './DpsSourceMapping.data';

import DpsSourceMappingForm from './DpsSourceMappingForm';

const addTitle = 'Add New Record for DPS Source Mapping';
const editTitle = 'Edit Record for DPS Source Mapping';

const DpsSourceMapping = () => {
    const apiEndpoints = dpsSourceMappingApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(dpsSourceMappingInitialState);
    const [modalTitle, setModalTitile] = useState(addTitle);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'DpssourceMappingId',
        rowNumber: ''
    });
    const [dropdownsData, setDpsSourceMappingData] = useState({
        bpRegionOptions: [],
        dpsFieldOptions: [],
        dpsSourceOptions: []
    });

    const parseData = (data) => new Promise((resolve, reject) => {
        (async () => {
            try {
                const bpRegionDetails = await axios.get(APIEndpoints.ADMIN_GetBpRegionDetails);
                const dpsFieldsId = await axios.get(APIEndpoints.ADMIN_GetDpsFields);
                const dpsDataSources = await axios.get(APIEndpoints.ADMIN_GetDPSDataSources);
                const bpRegionAlias = await axios.get(APIEndpoints.GetMratGroupMappings);
                const result = data?.map((res) => ({
                    ...res,
                    Region: bpRegionDetails.data.find(x => x?.RegionId === res?.RegionId)?.BpRegion1,
                    Dpsfield: dpsFieldsId.data.find(y => y?.DpsfieldId === res?.DpsfieldId)?.DpsfieldColumnName,
                    Dpssource: dpsDataSources.data.find(y => y?.DpssourceId === res?.DpssourceId)?.DpssourceName,
                    Status: res.Status ? 'Active' : 'Inactive',
                    IsCalculated: res.IsCalculated ? 'Yes' : 'No',
                    IsReadOnly: res.IsReadOnly ? 'Yes' : 'No',
                    BpRegionAlias: bpRegionDetails.data.find(x => x?.RegionId === res?.RegionId)?.BpRegionAlias,
                }));
                setDpsSourceMappingData(prev => ({
                    ...prev,
                    bpRegionOptions: _.sortBy(bpRegionDetails.data.map(x => ({
                        label: x.BpRegion1,
                        value: x.RegionId
                    })), 'label'),
                    dpsFieldOptions: _.sortBy(dpsFieldsId.data.filter(y => y.Status === true).map(x => ({
                        label: x.DpsfieldColumnName,
                        value: x.DpsfieldId
                    })), 'label'),
                    dpsSourceOptions: _.sortBy(dpsDataSources.data.filter(y => y.Status === true).map(x => ({
                        label: x.DpssourceName,
                        value: x.DpssourceId
                    })), 'label'),
                    bpRegionAliasOptions: _.sortBy(bpRegionAlias.data.map(x => ({
                        label: x.BpRegionNameAlias,
                        value: x.RegionId
                    })), 'label'),
                }));
                resolve(result);
            } catch (err) {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
                reject();
            }
        })();
    });

    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitile(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };
    const handleAddRecord = () => {
        setEditRowData(dpsSourceMappingInitialState);
        setModalTitile(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
        setRefreshDatagrid(prev => !prev);
    };

    return (
        <>
            <DataGridComponent
                headerData={dpsSourceMappingData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                initialData={searchDataInitialState}
                rowDetails={rowDetails}
                dataParser={parseData}
                refreshDatagrid={refreshDatagrid}
            />

            {showModal
                && (
                    <DpsSourceMappingForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                        dropdownsData={dropdownsData}
                    />
                )}
        </>
    );
};

export default DpsSourceMapping;
