import { FieldHasValue, ValidateNumberField, IsNonText } from 'helpers/Validation';

export const TestResultF81AsLeftResultFormula = (formData) => {
    if (FieldHasValue(formData.F66TestModule2Reading1)
        && FieldHasValue(formData.F67TestModule2Reading2)
        && FieldHasValue(formData.F68TestModule2Reading3)
        && FieldHasValue(formData.F69Tx2Reading1)
        && FieldHasValue(formData.F70Tx2Reading2)
        && FieldHasValue(formData.F71Tx2Reading3)
        && FieldHasValue(formData.F72Hmi2Reading1)
        && FieldHasValue(formData.F73Hmi2Reading2)
        && FieldHasValue(formData.F74Hmi2Reading3)
        && IsNonText(formData.F26InstrumentUrv)
        && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F78Deviation2Eu1),
        ValidateNumberField(formData.F79Deviation2Eu2),
        ValidateNumberField(formData.F80Deviation2Eu3)]) <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
