import {
    AFTCalibration2Points3Formula,
    AFTCalibration2Points2Formula,
    AFTCalibration2Points1Formula,
} from '../../../AsFoundTestTableSection/Data/P710/P710.formulas';
import {
    AsLeftTableF78Deviation2Eu1Formula,
    AsLeftTableF79Deviation2Eu2Formula,
    AsLeftTableF80Deviation2Eu3Formula,
    AsLeftResultFormula
} from './P710.formulas';
import { paddingAF } from '../M71/M71.data';

const disableField = (formData) => formData.F102AftResult === 'PASS' && formData.F160AftSensorTrimNeeded === 'NO';
const hmiInstEngVal1 = (formData) => disableField(formData) ? '' : AFTCalibration2Points3Formula(formData);
const hmiInstEngVal2 = (formData) => disableField(formData) ? '' : AFTCalibration2Points2Formula(formData);
const hmiInstEngVal3 = (formData) => disableField(formData) ? '' : AFTCalibration2Points1Formula(formData);
const borserTopDisable = (formData) => disableField(formData) ? '' : '0';

export const AsLeftTestTableSectionDataP710andP68 = (selectedTag, formData) => ({
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsleftTest',
                    label: 'As-Left Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'HMIINST',
                    label: '% - HMI - Inst.',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: '5px 0 5px 8px',
                    fontSize: '9pt'
                },
                {
                    key: 'GaugeReading',
                    label: 'Gauge Reading',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'TransmitterReading',
                    label: 'Transmitter Reading',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    textAlign: 'center',
                    labelWidth: '100%',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'HMIReading',
                    label: 'HMI Reading',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'mAReading',
                    label: 'mA Reading',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'Deviation',
                    label: 'Deviation (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt',
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'F63Hmi2Inst1',
                    value: hmiInstEngVal1(formData),
                    isDisabled: disableField(formData),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F139AltGaugeReading1',
                    value: disableField(formData) ? '' : formData.F139AltGaugeReading1,
                    isRequired: !disableField(formData),
                    isDisabled: disableField(formData),
                    readOnly: disableField(formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F161AltTransmitterReading1',
                    value: disableField(formData) ? '' : formData.F161AltTransmitterReading1,
                    isRequired: !disableField(formData),
                    isDisabled: disableField(formData),
                    readOnly: disableField(formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F109AltHmireading1',
                    value: disableField(formData) ? '' : formData.F109AltHmireading1,
                    isRequired: !disableField(formData),
                    isDisabled: disableField(formData),
                    readOnly: disableField(formData),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F164AltMeasuredReading1',
                    value: disableField(formData) ? '' : formData.F164AltMeasuredReading1,
                    isRequired: !disableField(formData),
                    isDisabled: disableField(formData),
                    readOnly: disableField(formData),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                },
                {
                    key: 'F78Deviation2Eu1',
                    value: disableField(formData) ? '' : AsLeftTableF78Deviation2Eu1Formula(formData),
                    isDisabled: disableField(formData),
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F64Hmi2Inst2',
                    value: hmiInstEngVal2(formData),
                    isDisabled: disableField(formData),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F140AltGaugeReading2',
                    value: disableField(formData) ? '' : formData.F140AltGaugeReading2,
                    isRequired: !disableField(formData),
                    isDisabled: disableField(formData),
                    readOnly: disableField(formData),
                    maxLength: '10',
                    xs: 2,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F162AltTransmitterReading2',
                    value: disableField(formData) ? '' : formData.F162AltTransmitterReading2,
                    isRequired: !disableField(formData),
                    isDisabled: disableField(formData),
                    readOnly: disableField(formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F113AltHmireading2',
                    value: disableField(formData) ? '' : formData.F113AltHmireading2,
                    isRequired: !disableField(formData),
                    isDisabled: disableField(formData),
                    readOnly: disableField(formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F165AltMeasuredReading2',
                    value: disableField(formData) ? '' : formData.F165AltMeasuredReading2,
                    isRequired: !disableField(formData),
                    isDisabled: disableField(formData),
                    readOnly: disableField(formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                },
                {
                    key: 'F79Deviation2Eu2',
                    value: disableField(formData) ? '' : AsLeftTableF79Deviation2Eu2Formula(formData),
                    isDisabled: disableField(formData),
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px'
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F65Hmi2Inst3',
                    value: hmiInstEngVal3(formData),
                    isDisabled: disableField(formData),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F141AltGaugeReading3',
                    value: disableField(formData) ? '' : formData.F141AltGaugeReading3,
                    isRequired: !disableField(formData),
                    isDisabled: disableField(formData),
                    readOnly: disableField(formData),
                    maxLength: '10',
                    xs: 2,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F163AltTransmitterReading3',
                    value: disableField(formData) ? '' : formData.F163AltTransmitterReading3,
                    isRequired: !disableField(formData),
                    isDisabled: disableField(formData),
                    readOnly: disableField(formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F114AltHmireading3',
                    value: disableField(formData) ? '' : formData.F114AltHmireading3,
                    isRequired: !disableField(formData),
                    isDisabled: disableField(formData),
                    readOnly: disableField(formData),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F166AltMeasuredReading3',
                    value: disableField(formData) ? '' : formData.F166AltMeasuredReading3,
                    isRequired: !disableField(formData),
                    isDisabled: disableField(formData),
                    readOnly: disableField(formData),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F80Deviation2Eu3',
                    value: disableField(formData) ? '' : AsLeftTableF80Deviation2Eu3Formula(formData),
                    isDisabled: disableField(formData),
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    inputMarginRight: '4px',
                }]
            },
            {
                key: 6,
                fields: [{
                    key: 'NoData2',
                    xs: 4,
                    isLabel: true,
                    padding: '5px 25px 18.5px 0'
                },
                {
                    key: 'F103AltResultLabel',
                    value: 'As-Left Result',
                    readOnly: true,
                    xs: 2,
                    isLabelBold: true,
                    textAlign: 'end',
                    labelWidth: '0',
                    width: '100%',
                    height: '1.1rem',
                    borderRight: '0',
                    borderTop: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                },
                {
                    key: 'F103AltResult',
                    value: AsLeftResultFormula(formData),
                    readOnly: true,
                    isCalculatedNew: true,
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    height: '1.1rem',
                    isDisabled: disableField(formData),
                    borderTop: borserTopDisable(formData),
                    inputMarginRight: '-2px',
                    borderBottom: '0',
                    marginBottom: '4px',
                    textAlign: 'center'
                }]
            }]
    });
