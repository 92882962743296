import { ValidateField } from 'helpers/Validation';
import {
    AsFoundTestTableF39HmiInst1Formula,
    AsFoundTestTableF40HmiInst2Formula,
    AsFoundTestTableF41HmiInst3Formula,
    AsFoundTestTableF56DeviationEu3Formula,
    AsFoundTestTableF55DeviationEu2Formula,
    AsFoundTestTableF54DeviationEu1Formula,
    FoundResultF61AsFoundResultFormula
} from './P71.formulas';
import { FoundResultF62SensorTrimNeedFormula } from '../../../FoundResultSection/Data/Formulas';

export const AsFoundTestTableSectionData = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'AsFoundTest',
            label: 'As Found Test',
            isLabel: true,
            fontWeight: '700',
            padding: '8px 0 0 8px'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'F57ReturnSignal',
            label: 'Return Signal',
            value: ValidateField(formData.F57ReturnSignal),
            isRequired: true,
            xs: 6,
            type: 'number',
            maxLength: '10',
            width: '32.4%',
            textAlign: 'center',
            labelWidth: '31.8%',
            marginLeft: '8px'
        },
        {
            key: 'Nodata1',
            isLabel: true,
            xs: 1,
        },
        {
            key: 'ThresholdValueLabel',
            label: 'Threshold Value As Found(V)',
            xs: 3,
            isLabel: 'true',
            labelWidth: '98%',
            textAlign: 'end',
            fontSize: '9pt',
            fontWeight: '600',
            padding: '4px 0 0 0',
            marginRight: '0.5rem'
        },
        {
            key: 'F58ThresholdAsFound',
            value: ValidateField(formData.F58ThresholdAsFound),
            isRequired: true,
            xs: 2,
            type: 'number',
            maxLength: '10',
            labelWidth: '0',
            width: '97%',
            textAlign: 'center',
            borderRight: '0',
            inputMarginRight: '4px'
        }
        ]
    },
    {
        key: 3,
        fields: [{
            key: 'F59ThresholdAsLeft',
            value: ValidateField(formData.F59ThresholdAsLeft),
            label: 'Threshold Value As Left(V)',
            isRequired: true,
            type: 'number',
            maxLength: '10',
            xs: 6,
            textAlign: 'center',
            width: '32.4%',
            labelWidth: '31.8%',
            fontSize: '9pt',
            fontWeight: '600',
            marginLeft: '8px',
            borderTop: '0'
        },
        {
            key: 'Nodata2',
            isLabel: true,
            xs: 2,
        },
        {
            key: 'Nodata3',
            isLabel: true,
            xs: 2,
        },
        {
            key: 'Nodata4',
            isLabel: true,
            xs: 2,
        }]
    },
    {
        key: 4,
        fields: [{
            key: 'HMIINST',
            label: '% HMI INST',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            padding: '5px 0 5px 8px',
            fontSize: '9pt'
        },
        {
            key: 'GaugeReading',
            label: 'Gauge Reading',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        },
        {
            key: 'TXReading',
            label: 'TX Reading',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        },
        {
            key: 'HMIReading',
            label: 'HMI Reading',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        },
        {
            key: 'mAReading',
            label: 'mA Reading',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        },
        {
            key: 'Deviation',
            label: 'Deviation(EU)',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        }]
    },
    {
        key: 5,
        fields: [{
            key: 'F39HmiInst1',
            xs: 2,
            value: AsFoundTestTableF39HmiInst1Formula(formData).length > 255
                ? AsFoundTestTableF39HmiInst1Formula(formData).slice(0, 255) : AsFoundTestTableF39HmiInst1Formula(formData),
            width: '100%',
            labelWidth: '0',
            readOnly: true,
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            borderBottom: '0',
            marginLeft: '4px'
        },
        {
            key: 'F42GaugeReading1',
            value: formData.F42GaugeReading1,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderBottom: '0',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F45TxReading1',
            value: formData.F45TxReading1,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderLeft: '0',
            borderRight: '0',
            borderBottom: '0',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F48HmiReading1',
            value: formData.F48HmiReading1,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderRight: '0',
            borderBottom: '0',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F51MAReading1',
            value: formData.F51MAReading1,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F54DeviationEu1',
            value: AsFoundTestTableF54DeviationEu1Formula(formData),
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            inputMarginRight: '4px',
            readOnly: true,
            xs: 2,
            textAlign: 'center'
        }]
    },
    {
        key: 6,
        fields: [{
            key: 'F40HmiInst2',
            xs: 2,
            value: AsFoundTestTableF40HmiInst2Formula(formData).length > 255
                ? AsFoundTestTableF40HmiInst2Formula(formData).slice(0, 255) : AsFoundTestTableF40HmiInst2Formula(formData),
            width: '100%',
            labelWidth: '0',
            readOnly: true,
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            borderBottom: '0',
            marginLeft: '4px'
        },
        {
            key: 'F43GaugeReading2',
            value: formData.F43GaugeReading2,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderBottom: '0',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F46TxReading2',
            value: formData.F46TxReading2,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderLeft: '0',
            borderRight: '0',
            borderBottom: '0',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F49HmiReading2',
            value: formData.F49HmiReading2,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderRight: '0',
            borderBottom: '0',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F52MAReading2',
            value: formData.F52MAReading2,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F55DeviationEu2',
            value: AsFoundTestTableF55DeviationEu2Formula(formData),
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            inputMarginRight: '4px',
            readOnly: true,
            xs: 2,
            textAlign: 'center'
        }]
    },
    {
        key: 7,
        fields: [{
            key: 'F41HmiInst3',
            xs: 2,
            value: AsFoundTestTableF41HmiInst3Formula(formData).length > 255
                ? AsFoundTestTableF41HmiInst3Formula(formData).slice(0, 255) : AsFoundTestTableF41HmiInst3Formula(formData),
            width: '100%',
            labelWidth: '0',
            readOnly: true,
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            marginLeft: '4px'
        },
        {
            key: 'F44GaugeReading3',
            value: formData.F44GaugeReading3,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F47TxReading3',
            value: formData.F47TxReading3,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F50HmiReading3',
            value: formData.F50HmiReading3,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F53MAReading3',
            value: formData.F53MAReading3,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F56DeviationEu3',
            value: AsFoundTestTableF56DeviationEu3Formula(formData),
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            inputMarginRight: '4px',
            readOnly: true,
            xs: 2,
            textAlign: 'center'
        }]
    },
    {
        key: 8,
        fields: [{
            key: 'F60ToleranceEuLabel',
            value: 'Tolerance (EU)',
            xs: 2,
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            borderRight: '0',
            borderBottom: '0',
            marginLeft: '0.26rem',
            borderLeft: '0',
            isLabelNew: true,
            marginBottom: '4px',
            marginRight: '0',
            borderTop: '0'
        },
        {
            key: 'F60ToleranceEu',
            value: selectedTag.DateTested ? formData?.F60ToleranceEu : formData?.F60ToleranceEu ?? selectedTag?.ToleranceInInstrumentEu,
            type: 'number',
            xs: 2,
            labelWidth: '0',
            width: '100%',
            maxLength: '10',
            isRequired: true,
            textAlign: 'center',
            height: '1.1rem',
            borderBottom: '0',
            borderTop: '0'
        },
        {
            key: 'F61AsFoundResultLabel',
            value: 'As Found Result',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            xs: 2,
            borderRight: '0',
            borderLeft: '0',
            borderBottom: '0',
            isLabelBold: true,
            textAlign: 'end',
            borderTop: '0'
        },
        {
            key: 'F61AsFoundResult',
            value: FoundResultF61AsFoundResultFormula(formData),
            readOnly: true,
            isCalculatedNew: true,
            xs: 2,
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.1rem',
            borderRight: '0',
            borderBottom: '0',
            borderTop: '0'
        },
        {
            key: 'F62SensorTrimNeedLabel',
            value: 'Sensor Trim Needed?',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            xs: 2,
            borderBottom: '0',
            borderRight: '0',
            isLabelBold: true,
            textAlign: 'end',
            borderTop: '0'
        },
        {
            key: 'F62SensorTrimNeed',
            value: FoundResultF62SensorTrimNeedFormula(formData),
            readOnly: true,
            isCalculatedNew: true,
            xs: 2,
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.1rem',
            inputMarginRight: '4px',
            borderBottom: '0',
            borderRight: '0',
            borderTop: '0'
        }]
    }]
});
