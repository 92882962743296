export const TestResultSectionDataP724 = (selectedTag, formData) => ({
    rows: [
    {
        key: 1,
        fields: [{
            key: 'HMIReadingafterTest',
            label: 'HMI Reading After the Test',
            isLabel: true,
            xs: 3,
            fontWeight: '600',
            marginLeft: '5px',
            fontSize: '9pt',
            padding: '5px 0 0 0',
            textAlign: 'left'
        },
        {
            key: 'F82HmiReadingAt',
            value: formData.F82HmiReadingAt,
            isRequired: true,
            type: 'number',
            maxLength: '10',
            xs: 2,
            textAlign: 'center',
            height: '1.2rem',
            labelWidth: '0',
            width: '100%'
        },
        {
            key: 'F267ProcessTxreadingAfterTestLabel',
            label: 'Process TX Reading After the Test',
            xs: 3,
            fontSize: '9pt',
            fontWeight: '600',
            padding: '5px 0 0 0',
            labelWidth: '97%',
            textAlign: 'end',
            isLabel: true
        },
        {
            key: 'F267ProcessTxreadingAfterTest',
            value: formData.F267ProcessTxreadingAfterTest,
            isRequired: true,
            xs: 4,
            textAlign: 'center',
            type: 'number',
            maxLength: '10',
            height: '1.2rem',
            labelWidth: '0',
            width: '100%',
            marginLeft: '0.8px'
        }]
    }]
});
