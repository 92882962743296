import { labelWidth } from '../HMISection/Data/P710/P710.data';
import { ReCalibrationDataM615, paddingValue } from './Data/M615/M615.data';

const ReCalibrationDataM61M611M614 = (selectedTag, formData) => {
    const selectedTagDateTested = (formDataValue, defaultValue) => selectedTag.DateTested ? formDataValue : formDataValue ?? defaultValue;
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'F30ReCalibrationToleranceLabel',
                label: 'Re-Calibration Tolerance',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingValue
            },
            {
                key: 'F30ReCalibrationTolerance',
                value: selectedTagDateTested(formData?.F30ReCalibrationTolerance, selectedTag?.TsprReCalibrationTolerance),
                xs: 2,
                type: 'number',
                maxLength: '10',
                isRequired: true,
                width: '100%',
                labelWidth: '0%',
                height: '1.2rem',
                textAlign: 'center',
                marginTop: '15px'
            },
            {
                key: 'F301ReCalibrationToleranceEULabel',
                label: 'Re-Calibration Tolerance EU',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                labelWidth,
                padding: paddingValue
            },
            {
                key: 'F301ReCalibrationToleranceEU',
                value: selectedTagDateTested(formData?.F301ReCalibrationToleranceEU, selectedTag?.ReCalibrationToleranceEU),
                xs: 2,
                isRequired: true,
                direction: 'rtl',
                inputWidth: '100%',
                labelWidth: '0%',
                marginRight: '0',
                textAlign: 'center',
                maxLength: '20',
                isAutoGrow: true,
                marginTop: '15px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F31FailToleranceLabel',
                label: 'Fail Tolerance',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt'
            },
            {
                key: 'F31FailTolerance',
                value: selectedTagDateTested(formData?.F31FailTolerance, selectedTag?.FailTolerance),
                xs: 2,
                type: 'number',
                maxLength: '10',
                isRequired: true,
                width: '100%',
                labelWidth: '0%',
                height: '1.25rem',
                textAlign: 'center'
            },
            {
                key: 'F311FailToleranceEULabel',
                label: 'Fail Tolerance EU',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                labelWidth
            },
            {
                key: 'F311FailToleranceEU',
                value: selectedTagDateTested(formData?.F311FailToleranceEU, selectedTag?.FailToleranceEU),
                xs: 2,
                isRequired: true,
                direction: 'rtl',
                inputWidth: '100%',
                labelWidth: '0%',
                marginRight: '0',
                textAlign: 'center',
                maxLength: '20',
                isAutoGrow: true
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F32HighSetpointLabel',
                label: 'High Setpoint',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt'
            },
            {
                key: 'F32HighSetpoint',
                value: selectedTagDateTested(formData?.F32HighSetpoint, selectedTag?.SetPointHigh),
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0%',
                height: '1.25rem',
                textAlign: 'center'
            }]
        },
        formData?.F796HighSetpointComment ?? selectedTag?.HighSetpointComment ? {
            key: 5,
            fields: [{
                key: 'F796HighSetpointCommentLabel',
                label: 'High Setpoint Comment',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt'
            },
            {
                key: 'F796HighSetpointComment',
                value: selectedTagDateTested(formData?.F796HighSetpointComment, selectedTag?.HighSetpointComment),
                textAlign: 'left',
                fontStyle: 'italic',
                isInfo: true,
                wordBreak: false,
                labelFontStyle: 'italic',
                width: '100%',
                labelWidth: '0%',
                xs: 2,
                labelTextAlign: 'left',
                marginTop: '5px',
                noWrap: true
            }]
        } : {
            key: 5,
            fields: []
        },
        {
            key: 4,
            fields: [{
                key: 'F240HighHighSetpointLabel',
                label: 'High High Setpoint',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt'
            },
            {
                key: 'F240HighHighSetpoint',
                value: selectedTagDateTested(formData?.F240HighHighSetpoint, selectedTag?.HighHighSetpoint),
                xs: 2,
                type: 'number',
                maxLength: '10',
                isRequired: true,
                width: '100%',
                labelWidth: '0%',
                height: '1.25rem',
                borderTop: formData?.F796HighSetpointComment ?? selectedTag?.HighSetpointComment ? '' : '0',
                textAlign: 'center'
            }]
        },
        formData?.F448HighHighSetpointComment ?? selectedTag?.HighHighSetpointComment ? {
            key: 5,
            fields: [{
                key: 'F448HighHighSetpointCommentLabel',
                label: 'High High Setpoint Comment',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt'
            },
            {
                key: 'F448HighHighSetpointComment',
                value: selectedTagDateTested(formData?.F448HighHighSetpointComment, selectedTag?.HighHighSetpointComment),
                textAlign: 'left',
                fontStyle: 'italic',
                isInfo: true,
                wordBreak: false,
                labelFontStyle: 'italic',
                width: '100%',
                labelWidth: '0%',
                xs: 2,
                labelTextAlign: 'left',
                marginTop: '5px',
                noWrap: true
            }]
        } : {
            key: 5,
            fields: []
        }
        ]
    };
};
export const selectReCalibrationData = (selectedTag, formName, formData) => {
    let filterViewData;
    if (['M6-11', 'M6-14', 'M6-1'].includes(formName)) {
        filterViewData = ReCalibrationDataM61M611M614(selectedTag, formData);
    } else if (formName === 'M6-15') {
        filterViewData = ReCalibrationDataM615(selectedTag, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
