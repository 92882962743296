import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { AccountButton } from 'elements/CustomHeaderButtons/HeaderButtons';
import { entries } from 'idb-keyval';
import offlineStore from 'utils/OfflineDB';
import { syncMRATData } from 'components/MRATSync/MRATSync';
import { updateMRATSyncInvalidationStatus, checkOfflineDataPresent, setCurrentView, disableMRATSyncButton } from 'journeys/portal/Home/Home.actions';
import { msalConfig } from '../../../../../authConfig';
import { UserSection, DropDownPanel, DropDownItem } from './UserDropdown.styled';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';

export const msalInstance = new PublicClientApplication(msalConfig);

const UserDropdown = (props) => {
    const { displayFormStatus, updateInvalidationStatus, checkOfflineFormDataPresent, disableSyncButton, setCurrentAppView } = props;
    const [isOpen, toggleDropdown] = useState(false);
    const [logOutAlert, handleLogOutAlert] = useState(false);
    const accounts = msalInstance.getAllAccounts();
    const handleLogOut = async () => {
        handleLogOutAlert(false);
        const storesOfflineValues = await entries(offlineStore);
        storesOfflineValues.length !== 0 && await syncMRATData({
            storesOfflineValues, updateInvalidationStatus, checkOfflineFormDataPresent, setCurrentAppView, disableSyncButton
        });
        const logoutRequest = {
            account: accounts[0]
        };
        if (logoutRequest.account.username.toLowerCase().includes('bp')) {
            msalInstance.logoutRedirect();
        }
        msalInstance.logoutRedirect(logoutRequest);
    };

    const headerLogOutButtonHandler = () => {
        handleLogOutAlert(true);
    };

    return (
        <div>
            <ClickAwayListener onClickAway={() => toggleDropdown(false)}>

                <UserSection>
                    <AccountButton
                        id="headerAccountButton"
                        buttonHandler={() => toggleDropdown(!isOpen)}
                    />
                    {isOpen && (
                        <DropDownPanel>
                            <DropDownItem id="userLogoutButton" onClick={() => displayFormStatus ? headerLogOutButtonHandler() : handleLogOut()}>
                                Log Out
                            </DropDownItem>
                        </DropDownPanel>
                    )}

                </UserSection>
            </ClickAwayListener>
            {logOutAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={logOutAlert}
                    handleClose={() => handleLogOutAlert(false)}
                    handleAlertFunction={() => handleLogOut()}
                />
            )}
        </div>

    );
};
const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    setCurrentAppView: (view) => dispatch(setCurrentView(view)),
    updateInvalidationStatus: (localState) => dispatch(updateMRATSyncInvalidationStatus(localState)),
    checkOfflineFormDataPresent: () => dispatch(checkOfflineDataPresent()),
    disableSyncButton: (status) => dispatch(disableMRATSyncButton(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown);

UserDropdown.propTypes = {
    checkOfflineFormDataPresent: PropTypes.func,
    disableSyncButton: PropTypes.func,
    displayFormStatus: PropTypes.bool,
    setCurrentAppView: PropTypes.func,
    updateInvalidationStatus: PropTypes.func
};
