import { isSelfPortableTest } from '../AsFoundTestTableSection/Data/M65/M65.formula';
import { labelWidth } from '../HMISection/Data/P710/P710.data';
import { paddingValue } from '../ReCalibrationSection/Data/M615/M615.data';

export const FailToleranceAlarmSection = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F31FailToleranceLabel',
                    label: 'Fail Tolerance',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '18px 0 0 5px'
                },
                {
                    key: 'F31FailTolerance',
                    value: selectedTag?.DateTested ? formData?.F31FailTolerance : formData?.F31FailTolerance ?? selectedTag?.FailTolerance,
                    isRequired: !isSelfPortableTest(formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    marginTop: '15px',
                    marginLeft: '5px'
                },
                {
                    key: 'F311FailToleranceEULabel',
                    label: 'Fail Tolerance EU',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingValue,
                    labelWidth
                },
                {
                    key: 'F311FailToleranceEU',
                    value: selectedTag?.DateTested ? formData?.F311FailToleranceEU : formData?.F311FailToleranceEU ?? selectedTag?.FailToleranceEU,
                    xs: 2,
                    isRequired: true,
                    direction: 'rtl',
                    inputWidth: '100%',
                    labelWidth: '0%',
                    marginRight: '0',
                    textAlign: 'center',
                    maxLength: '20',
                    isAutoGrow: true,
                    marginTop: '15px'
                }
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F415AlarmTimeDelayLabel',
                    label: 'Alarm time delay',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '0 0 0 5px'
                },
                {
                    key: 'F415AlarmTimeDelay',
                    value: formData?.F415AlarmTimeDelay,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    marginLeft: '5px'
                }
            ]
        }
    ]
});

export const SelectFailToleranceAlarmSection = (selectedTag, formName, formData) => {
    let filterViewData = { rows: [] };
    if (formName === 'M6-5') {
        filterViewData = FailToleranceAlarmSection(selectedTag, formData);
    }
    return filterViewData;
};
