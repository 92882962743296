import { paddingLabel } from '../C11/C11.data';
import { labelWidth } from '../P710/P710.data';
import { valveSizeValue } from '../P725/P725.data';

export const HMISectionDataP726 = (selectedTag, formData) => ({
    rows: [
        {
            key: 2,
            fields: [{
                key: 'F600ProcessFluidLabel',
                label: 'Process Fluid',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '18px 0 0 0'
            },
            {
                key: 'F600ProcessFluid',
                value: formData.F600ProcessFluid,
                isRequired: true,
                isDropdown: true,
                placeholder: 'Select Process Fluid',
                options: [
                    { name: 'Select Process Fluid', value: '' },
                    { name: 'Gas', value: 'Gas' },
                    { name: 'Liquid', value: 'Liquid' }],
                inputTextAlign: 'center',
                labelWidth: '0%',
                width: '100%',
                xs: 2,
                height: '1.5rem',
                borderBottom: '0',
                textAlign: 'center',
                marginTop: '15px',
            },
            {
                key: 'F601MaxTolerableLeakageRateLabel',
                label: 'Maximum Tolerable Leakage Rate',
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: '18px 5px 0 0'
            },
            {
                key: 'F601MaxTolerableLeakageRate',
                value: selectedTag.DateTested ? formData?.F601MaxTolerableLeakageRate : formData?.F601MaxTolerableLeakageRate ?? selectedTag?.TargetLeakageRate,
                xs: 2,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                labelWidth: '0%',
                width: '100%',
                height: '1.25rem',
                textAlignLabel: 'end',
                borderBottom: '0',
                textAlign: 'center',
                marginTop: '15px',
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F602ValveTypeLabel',
                label: 'Valve Type',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel
            },
            {
                key: 'F602ValveType',
                value: formData.F602ValveType,
                isDropdown: true,
                placeholder: 'Select Valve Type',
                options: [
                    { name: 'Select Valve Type', value: '' },
                    { name: 'Ball', value: 'Ball' },
                    { name: 'Gate', value: 'Gate' },
                    { name: 'Plug', value: 'Plug' },
                    { name: 'Relief', value: 'Relief' }],
                inputTextAlign: 'center',
                height: '1.6rem',
                xs: 2,
                labelWidth: '0%',
                width: '100%',
                textAlign: 'center',
            },
            {
                key: 'F603LeakageRateEngUnitLabel',
                label: 'Leakage Rate Engineering Unit (EU)',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel
            },
            {
                key: 'F603LeakageRateEngUnit',
                value: selectedTag.DateTested ? formData?.F603LeakageRateEngUnit : formData?.F603LeakageRateEngUnit ?? selectedTag?.TargetLeakageRateEu,
                xs: 2,
                isRequired: true,
                maxLength: '17',
                labelWidth: '0%',
                width: '100%',
                height: '1.24rem',
                textAlignLabel: 'end',
                textAlign: 'center',
            }]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F340ValveSizeLabel',
                    label: 'Valve Size (inch)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F340ValveSize',
                    value: valveSizeValue(selectedTag, formData),
                    isRequired: true,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    borderTop: '0',
                    textAlign: 'center',
                    type: 'number',
                    maxLength: '10',
                },
                {
                    key: 'F795LeakageClassLabel',
                    label: 'Leakage Class',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel
                },
                {
                    key: 'F795LeakageClass',
                    value: selectedTag.DateTested ? formData?.F795LeakageClass : formData?.F795LeakageClass ?? selectedTag?.TargetLeakageClass,
                    xs: 2,
                    borderTop: '0',
                    maxLength: '20',
                    labelWidth: '0%',
                    width: '100%',
                    height: '1.2rem',
                    textAlignLabel: 'end',
                    textAlign: 'center',
                }
            ]
        }]
});
