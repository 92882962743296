import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import { useMsal } from '@azure/msal-react';
import { formatDate } from 'helpers/DateFormatter';
import RefreshIcon from '../../../../icons/RefreshButton/Refresh';
import { DpsForm } from './DPSForm';
import { dpsFormState } from '../DPS/DPS.data';
import { setDataForAddDPS, resetDataForAddDPS, updateDataForAddDPS, PostAddDPSData } from '../DPS/DPS.actions';
import { MRAT_USER_ROLE } from '../../Home/Home.constants';
import { setCurrentView } from '../../Home/Home.actions';
import { getDescriptionByTrfId } from '../DPS/DPS.helpers';

const RefreshFormComponent = ({ buttonHandler }) => <RefreshIcon buttonHandler={buttonHandler} rotate={-90} tooltip="Reset Form" />;
RefreshFormComponent.propTypes = {
    buttonHandler: PropTypes.func.isRequired
};

const AddDpsForm = (props) => {
    const { setInitialData, resetData, updateFormData, addDPSData, trfMasterList, bpRegionNameAlias, selectedAssetAliasName, postAddDPSData, isMRATOnline, selectedBarrierType,
        userRole, SapFloc, sapFlocExist, TrfId, dpsLockedFields, correctiveDPSMapping, dpsMaxLengthMapper } = props;
    const showRefreshButton = isMRATOnline;
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const FooterButtonVisible = isMRATOnline && ((MRAT_USER_ROLE.Engineer === userRole)
        || selectedBarrierType === ('M6 F&G').toLowerCase())
        && SapFloc && SapFloc.length > 0 && TrfId && TrfId.length > 0;
    useEffect(() => {
        setInitialData(dpsFormState(bpRegionNameAlias.toUpperCase(), selectedAssetAliasName.toUpperCase()));
        return () => resetData(dpsFormState(bpRegionNameAlias.toUpperCase(), selectedAssetAliasName.toUpperCase()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAssetAliasName, bpRegionNameAlias]);
    const [disableAddDPSSubmit, setDisableAddDPSSubmit] = useState(false);
    const SubmitButtonHandler = async () => {
        setDisableAddDPSSubmit(true);
        setTimeout(async () => {
            await postAddDPSData();
            setDisableAddDPSSubmit(false);
        }, 500);
    };

    const UpdateFormDataHandler = (obj) => {
        const objWithPropertiesToBeUpdated = obj;
        if (obj.TrfId !== undefined) {
            const selectedTrfId = trfMasterList.filter(x => x.ApplicableRegions && obj.TrfId === x.TrfId)[0];
            const selectedCorrectiveTrfId = correctiveDPSMapping?.find(x => obj.TrfId === x.Trfid && x.Status);
            if (selectedTrfId) {
                objWithPropertiesToBeUpdated.TrfDesc = selectedTrfId.TrfShortDesc;
                objWithPropertiesToBeUpdated.CorrectiveTrfId = selectedCorrectiveTrfId ? selectedCorrectiveTrfId?.CorrectiveTrfid : '';
                objWithPropertiesToBeUpdated.CorrectiveTrfDesc = objWithPropertiesToBeUpdated.CorrectiveTrfId
                    ? trfMasterList.find(x => x.ApplicableRegions && obj.CorrectiveTrfId === x.TrfId).TrfShortDesc : '';
            } else {
                objWithPropertiesToBeUpdated.TrfDesc = '';
            }
        }
        if (obj.CorrectiveTrfId) {
            objWithPropertiesToBeUpdated.CorrectiveTrfDesc = getDescriptionByTrfId(obj.CorrectiveTrfId, trfMasterList);
        }

        if (obj.InspectionIrfId) {
            objWithPropertiesToBeUpdated.InspectionIrfDescription = getDescriptionByTrfId(obj.InspectionIrfId, trfMasterList);
        }
        objWithPropertiesToBeUpdated.CreatedBy = userName?.name;
        objWithPropertiesToBeUpdated.CreatedDate = formatDate(new Date());
        updateFormData(objWithPropertiesToBeUpdated);
    };
    return (
        <SiteWrapper
            headerText="Add New Device Performance Standard"
            ButtonSection={
                showRefreshButton && <RefreshFormComponent buttonHandler={() => resetData(dpsFormState(bpRegionNameAlias.toUpperCase(), selectedAssetAliasName.toUpperCase()))} />
            }
            footerButtonHandler={SubmitButtonHandler}
            footerButtonText="Submit"
            FooterButtonVisible={FooterButtonVisible}
            goBack="dps"
            isDisabled={disableAddDPSSubmit}
            displayUnsavedChangesPopUp
        >
            {addDPSData
                ? (
                    <DpsForm
                        formId="AddDpsForm"
                        formValues={addDPSData}
                        formHandler={(obj) => UpdateFormDataHandler(obj)}
                        trfMasterList={trfMasterList}
                        sapFlocExist={sapFlocExist}
                        dpsLockedFields={dpsLockedFields}
                        dpsMaxLengthMapper={dpsMaxLengthMapper}
                    />
                )
                : null}
        </SiteWrapper>
    );
};

const mapStateToProps = ({ AppData, DPS, Home }) => ({
    addDPSData: DPS.addDPSData,
    dpsDataAll: DPS.dpsDataAll,
    correctiveDPSMapping: DPS.correctiveDPSMapping,
    trfMasterList: Home.trfMasterList,
    selectedAssetAliasName: Home.selectedAssetAliasName,
    isMRATOnline: AppData.isMRATOnline,
    bpRegionNameAlias: Home.bpRegionNameAlias,
    selectedBarrierType: Home.selectedBarrierType,
    userRole: Home.userRole,
    SapFloc: DPS.addDPSData?.SapFloc,
    sapFlocExist: DPS.sapFlocExist,
    TrfId: DPS.addDPSData?.TrfId,
    dpsLockedFields: DPS.dpsLockedFields,
    dpsMaxLengthMapper: DPS.dpsMaxLengthMapper
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    setCurrentAppView: (view) => dispatch(setCurrentView(view)),
    setInitialData: (initialState) => dispatch(setDataForAddDPS(initialState)),
    resetData: (initialState) => dispatch(resetDataForAddDPS(initialState)),
    updateFormData: (obj) => dispatch(updateDataForAddDPS(obj)),
    postAddDPSData: () => dispatch(PostAddDPSData()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDpsForm);

AddDpsForm.propTypes = {
    addDPSData: PropTypes.object.isRequired,
    bpRegionNameAlias: PropTypes.string,
    correctiveDPSMapping: PropTypes.array,
    dpsLockedFields: PropTypes.array,
    dpsMaxLengthMapper: PropTypes.object,
    isMRATOnline: PropTypes.bool,
    postAddDPSData: PropTypes.func.isRequired,
    resetData: PropTypes.func,
    SapFloc: PropTypes.string,
    sapFlocExist: PropTypes.bool,
    selectedAssetAliasName: PropTypes.string,
    selectedBarrierType: PropTypes.string,
    setInitialData: PropTypes.func,
    TrfId: PropTypes.string,
    trfMasterList: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    updateFormData: PropTypes.func,
    userRole: PropTypes.string
};
