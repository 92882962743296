import styled from 'styled-components';

export const DropDownElement = styled.div`
padding: 10px 0 0 0;
.Dropdown-option {
    min-height: 10px;
    padding: 4.8px 10px;
}
.Dropdown-menu {
    max-height: 300px;
}
.BigSize .Dropdown-control {
    height: 3rem;
    padding: 10px;
}
.Dropdown-arrow {
    padding: 7px;
}
`;

export const ArrowElement = styled.div`
.MuiIconButton-root{
    padding: 10px 0;
}
`;
