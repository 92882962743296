import { FieldHasValue, ValidateField, ValidateNumberField, ExponentialToDecimal } from 'helpers/Validation';

export const isF115HmiEUHasValue = (formData) => FieldHasValue(formData.F115HmiengineeringUnits) ? ' ' : '';
export const isF28InstEUHasValue = (formData) => FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '';

export const AsFoundTestTableF39HmiInst1Formula = (formData) => (
    FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F25InstrumentLrv))
    ? '0% - '.concat(ValidateField(formData.F21HmiLrv))
        .concat(isF115HmiEUHasValue(formData))
        .concat(ValidateField(formData.F115HmiengineeringUnits))
        .concat(' - ')
        .concat(ValidateField(formData.F25InstrumentLrv))
        .concat(isF28InstEUHasValue(formData))
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '0%';

export const AsFoundTestTableF40HmiInst2Formula = (formData) => {
    if (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F25InstrumentLrv) && FieldHasValue(formData.F26InstrumentUrv)) {
        if (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F22HmiUrv)) {
            return '50% - '.concat(ExponentialToDecimal(ValidateNumberField(formData.F21HmiLrv)
                + (ValidateNumberField(formData.F22HmiUrv) - ValidateNumberField(formData.F21HmiLrv)) * (0.5)))
                .concat(isF115HmiEUHasValue(formData))
                .concat(ValidateField(formData.F115HmiengineeringUnits))
                .concat(' - ')
                .concat(ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)
                    + (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) * (0.5)))
                .concat(isF28InstEUHasValue(formData))
                .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        }
        return '50%';
    }
    return '50%';
};

export const AsFoundTestTableF41HmiInst3Formula = (formData) => (
    (FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F26InstrumentUrv))
        ? '100% - '.concat(ValidateField(formData.F22HmiUrv))
            .concat(isF115HmiEUHasValue(formData))
            .concat(ValidateField(formData.F115HmiengineeringUnits))
            .concat(' - ')
            .concat(ValidateField(formData.F26InstrumentUrv))
            .concat(isF28InstEUHasValue(formData))
            .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '100%');

export const AsFoundTestTableF54DeviationEu1Formula = (formData) => FieldHasValue(formData.F45TxReading1) && FieldHasValue(formData.F665AftSimulatedGap1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F45TxReading1) - ValidateNumberField(formData.F665AftSimulatedGap1))) : '';
export const AsFoundTestTableF55DeviationEu2Formula = (formData) => FieldHasValue(formData.F46TxReading2) && FieldHasValue(formData.AftSimulatedGap2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F46TxReading2) - ValidateNumberField(formData.AftSimulatedGap2))) : '';
export const AsFoundTestTableF56DeviationEu3Formula = (formData) => FieldHasValue(formData.F47TxReading3) && FieldHasValue(formData.AftSimulatedGap3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F47TxReading3) - ValidateNumberField(formData.AftSimulatedGap3))) : '';
