import { ValidateField, conditionalValueSelector } from 'helpers/Validation';
import { FoundResultF81AsLeftResultFormula } from '../P726/P726.formulas';
import { paddingValue, placeholderUnit, paddingValueTR } from '../../../AsFoundTestTableSection/Data';
import { isAsLeftTestRequired } from '../../AsLeftTestTableSection.helpers';

export const AsLeftTestTableSectionDataP725 = (selectedTag, formData) => {
    const altDisabled = isAsLeftTestRequired(formData.F102AftResult, formData.F619AsLeftTestRequired);
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'AsLeftTest',
                label: 'As-Left Test',
                isLabel: true,
                fontWeight: '700',
                padding: '8px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F636AltPbuVolume',
                label: 'PBU Volume (m3)',
                value: conditionalValueSelector(altDisabled, '', formData.F636AltPbuVolume),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                maxLength: '10',
                xs: 6,
                labelWidth: '63%',
                width: '64%',
                height: '1.26rem',
                marginLeft: '7px',
                marginRight: '0.5rem',
                textAlign: 'center',
                marginTop: '5px',
                marginBottom: '10px'
            },
            {
                key: 'F638AltTestDuration',
                label: 'Test Duration (min)',
                value: conditionalValueSelector(altDisabled,
                    '', formData.F638AltTestDuration),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                xs: 6,
                labelWidth: '48.7%',
                width: '49%',
                height: '1.26rem',
                marginRight: '0.5rem',
                inputMarginRight: '4px',
                direction: 'rtl',
                textAlign: 'center',
                marginTop: '5px',
                borderRight: '0',
                type: 'number',
                maxLength: '10',
                marginBottom: '10px'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'AltSimulationPoints',
                isLabel: true,
                fontWeight: '700',
                xs: 3,
                padding: paddingValue,
                fontSize: '9pt'
            },
            {
                key: 'Initial',
                label: 'Initial',
                isLabel: true,
                fontWeight: '700',
                xs: 3,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'End',
                label: 'End',
                isLabel: true,
                fontWeight: '700',
                xs: 3,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'Unit of MeasurementNo3',
                label: 'Unit of Measurement',
                isLabel: true,
                fontWeight: '700',
                xs: 3,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'PressureMeasurement',
                xs: 3,
                label: 'Pressure Measurement',
                fontWeight: '600',
                labelWidth: '100%',
                textAlign: 'left',
                fontSize: '9pt',
                marginLeft: '7px',
                isLabel: true,
                height: '1.2rem',
                padding: paddingValueTR,
            },
            {
                key: 'F641AltInitial1',
                value: conditionalValueSelector(altDisabled,
                    '', ValidateField(formData.F641AltInitial1)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: (altDisabled) ? '1.5rem' : '1.3rem',
                borderBottom: '0',
                borderRight: '0',
                xs: 3,
                maxLength: '10',
            },
            {
                key: 'F645AltEnd1',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F645AltEnd1)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: conditionalValueSelector(altDisabled,
                    '1.5rem', '1.3rem'),
                borderBottom: '0',
                xs: 3,
                maxLength: '10',
            },
            {
                key: 'F621AltUnitOfMeasurement',
                value: conditionalValueSelector(altDisabled,
                    '', ValidateField(formData.F621AltUnitOfMeasurement)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                isDropdown: !(altDisabled),
                placeholder: placeholderUnit,
                options: [
                    { name: placeholderUnit, value: '' },
                    { name: 'bar', value: 'bar' },
                    { name: 'kPa', value: 'kPa' },
                    { name: 'psi', value: 'psi' }],
                inputTextAlign: 'center',
                type: 'number',
                width: '99%',
                labelWidth: '0',
                height: '1.5rem',
                borderBottom: '0',
                borderRight: '0',
                borderLeft: '0',
                textAlign: 'center',
                xs: 3,
                maxLength: '10',
                inputMarginRight: '4px'
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'TemperatureMeasurement',
                xs: 3,
                label: 'Temperature Measurement',
                fontWeight: '600',
                labelWidth: '100%',
                textAlign: 'left',
                fontSize: '9pt',
                marginLeft: '7px',
                isLabel: true,
                padding: paddingValueTR,
            },
            {
                key: 'F642AltInitial2',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F642AltInitial2)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: conditionalValueSelector(altDisabled,
                    '1.6rem', '1.28rem'),
                borderRight: '0',
                xs: 3,
                maxLength: '10'
            },
            {
                key: 'F646AltEnd2',
                value: conditionalValueSelector(altDisabled, '', ValidateField(formData.F646AltEnd2)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: conditionalValueSelector(altDisabled, '1.6rem', '1.28rem'),
                xs: 3,
                maxLength: '10'
            },
            {
                key: 'F648AltUnitOfMeasurement1',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F648AltUnitOfMeasurement1)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                isDropdown: !(altDisabled),
                placeholder: placeholderUnit,
                options: [
                    { name: placeholderUnit, value: '' },
                    { name: '°C', value: '°C' },
                    { name: '°F', value: '°F' }],
                inputTextAlign: 'center',
                type: 'number',
                width: '99%',
                labelWidth: '0',
                height: '1.65rem',
                borderRight: '0',
                borderLeft: '0',
                textAlign: 'center',
                xs: 3,
                maxLength: '10',
                inputMarginRight: '4px',
            }]
        },
        {
            key: 6,
            fields: [{
                key: 'Alt_CalculatedValveLeakageRate',
                value: 'Calculated Valve Leakage Rate (EU)',
                xs: 3,
                width: '100%',
                readOnly: true,
                height: '1.1rem',
                labelWidth: '0',
                marginLeft: '4px',
                borderLeft: '0',
                borderBottom: '0',
                fontSize: '9pt',
                fontWeight: '600',
                isLabelNew: true,
                borderTop: '0',
                borderRight: '0',
                marginBottom: '4px',
            },
            {
                key: 'F634AltCalculatedValveLeakageRate',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F634AltCalculatedValveLeakageRate)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                isRequired: !(altDisabled),
                xs: 3,
                width: '100%',
                textAlign: 'center',
                labelWidth: '0',
                height: conditionalValueSelector(altDisabled, '1.22rem', '1.1rem'),
                borderBottom: '0',
                borderRight: '0',
                borderTop: '0',
                marginBottom: '4px',
                type: 'number',
                maxLength: '10',
            },
            {
                key: 'AsLeftResultLabel',
                value: 'As-Left Result',
                xs: 3,
                textAlign: 'end',
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                height: '1.1rem',
                marginBottom: '4px',
                borderRight: conditionalValueSelector(altDisabled, '0', ''),
                borderBottom: '0',
                borderLeft: conditionalValueSelector(altDisabled, '0', ''),
                borderTop: '0',
                isLabelBold: true,
            },
            {
                key: 'F81AsLeftResult',
                value: conditionalValueSelector(altDisabled, '', FoundResultF81AsLeftResultFormula(formData)),
                isCalculatedNew: !(altDisabled),
                isDisabled: altDisabled,
                readOnly: true,
                xs: 3,
                labelWidth: '0',
                textAlign: 'center',
                width: '100%',
                borderBottom: '0',
                borderTop: conditionalValueSelector(altDisabled, '', '0'),
                height: '1.1rem',
                marginBottom: '4px',
                borderRight: '0',
                borderLeft: conditionalValueSelector(altDisabled, '', '0'),
                inputMarginRight: '4px'
            }]
        }
        ]
    };
};
