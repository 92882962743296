export const savedTRFPrintScreenInitialState = {
    savedTRFDataAll: [],
    searchedTRFData: [],
    totalCount: 0,
    MoNumberValue: '',
    MoStatusValue: '',
    tagFlocFilter: [],
    tagFirstTestDoneByFilter: [],
    tagTestRecordIdFilter: [],
    tagTestRecordTestResultFilter: [],
    tagTestRecordAsFoundResultFilter: [],
    TagFirstDateTestedValue: null,
    DeckHullBldgValue: '',
    QuadValue: '',
    LevelValue: '',
    RoomValue: '',
    Location2Value: '',
    selectedSavedTRFScreenFilters: {
        MoNumber: '',
        MoFunctionalLocation: '',
        TagFunctionalLocation: [],
        TagFirstDateTested: '',
        TagFirstTestDoneBy: [],
        TagTestRecordId: [],
        DeckHullBldg: '',
        Quad: '',
        Level: '',
        Room: '',
        Location2: ''
    },
    sortMONumberAscOrder: true,
    sortTagFunctionalLocationAscOrder: true,
    sortTagDateTestedAscOrder: true,
    tableLoading: false
};
