import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { selectFlowChangeSectionData } from './FlowChangeCheckSection.data';
import { FormInput } from 'elements/Inputs/FormInput';
import { StyledLabel } from 'elements/Label/Label';
import { StyleLabelFlowChangeCheck } from './FlowChangeCheckSection.styled';

const FlowChangeCheckSection = (props) => {
    const { selectedTag, changeHandler, formData, formName, userRole, isFormReadOnly } = props;
    const styleInput = (field) => field.isLabel ? (
        <StyleLabelFlowChangeCheck
        marginTop={field.marginTop}
        >
            <StyledLabel
                id={field.key}
                label={field.label}
                color={field.color}
                fontWeight={field.fontWeight}
                fontSize={field.fontSize}
                textAlign={field.textAlign}
                fontStyle={field.fontStyle}
                labelWidth={field.labelWidth}
                marginLeft={field.marginLeft}
                padding={field.padding}

            >
                {field.label}
            </StyledLabel>
        </StyleLabelFlowChangeCheck>
    )
        : (
            <FormInput
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                isCalculated={field.isCalculated}
                isCalculatedNew={field.isCalculatedNew}
                isRequired={field.isRequired}
                handleChange={changeHandler}
                type={field.type}
                width={field.width}
                padding={field.padding}
                labelWidth={field.labelWidth}
                textAlign={field.textAlign}
                readOnly={field.readOnly || isFormReadOnly}
                maxLength={field.maxLength}
                borderRight={field.borderRight}
                borderLeft={field.borderLeft}
                height={field.height}
                inputMarginRight={field.inputMarginRight}
                userRole={userRole}
                isDisabled={field.isDisabled}
                fontWeight={field.fontWeight}
                marginLeft={field.marginLeft}
                borderBottom={field.borderBottom}
                direction={field.direction}
                marginRight={field.marginRight}
                isLabelBold={field.isLabelBold}
                fontSize={field.fontSize}
                inputFontSize={field.inputFontSize}
                marginBottom={field.marginBottom}
                marginTop={field.marginTop}
            />
        );
    return (
        <div>
            {
                selectFlowChangeSectionData(selectedTag, formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {styleInput(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </div>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FlowChangeCheckSection);

FlowChangeCheckSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
