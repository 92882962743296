export const FailureModeSectionDataP726 = (formName, formData) => {
    let leftTestRequiredWidth;
    let inPutWidth;
    let disableAltDropdown = formData.F102AftResult === 'PASS';
    if (formName === 'M7-1') {
        leftTestRequiredWidth = '60%';
        inPutWidth = '40%';
    } else if (['M6-11', 'M6-14', 'M6-15', 'M6-1'].includes(formName)) {
        leftTestRequiredWidth = '100%';
        inPutWidth = '50%';
        disableAltDropdown = formData.F102AftResult === 'PASS' && formData.F425ReCalibrationRequired === 'NO';
    } else {
        leftTestRequiredWidth = '63%';
        inPutWidth = '64%';
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F619AsLeftTestRequired',
                    label: 'As-Left Test Required?',
                    value: !disableAltDropdown ? formData.F619AsLeftTestRequired : '',
                    isDisabled: disableAltDropdown,
                    isRequired: false,
                    isDropdown: !disableAltDropdown,
                    readOnly: disableAltDropdown,
                    placeholder: 'Select YES or NO',
                    options: [{ name: 'Select YES or NO', value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    inputTextAlign: 'center',
                    labelWidth: leftTestRequiredWidth,
                    width: inPutWidth,
                    xs: 6,
                    height: disableAltDropdown ? '1.26rem' : '1.55rem',
                    marginTop: '15px',
                    marginRight: formName === 'M7-1' ? '4px' : '0',
                    textAlign: 'left',
                }]
            }]
    };
};
