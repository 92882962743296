import { FieldHasValue } from 'helpers/Validation';

export const AltLowAlarmTestResultFormula = (formData) => {
    if (FieldHasValue(formData.F829AltLowAlarmTest) && FieldHasValue(formData.F378AltHmistatus1)) {
        if (formData.F829AltLowAlarmTest === 'PERFORM' && formData.F378AltHmistatus1 === 'LOW ALARM') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const AltHighAlarmTestResultFormula = (formData) => {
    if (FieldHasValue(formData.F831AltHighAlarmTest) && FieldHasValue(formData.F379AltHmistatus2)) {
        if (formData.F831AltHighAlarmTest === 'PERFORM' && formData.F379AltHmistatus2 === 'HIGH ALARM') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const AltBeamblockTestResultFormula = (formData) => {
    if (FieldHasValue(formData.F833AltBeamBlockTest) && FieldHasValue(formData.F834AltHmiStatus)) {
        if (formData.F833AltBeamBlockTest === 'PERFORM' && formData.F834AltHmiStatus === 'FAULT') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const AsLeftResultFormula = (formData) => {
    const fields = [
        { test: 'F830AltLowAlarmTestResult', test2: 'F832AltHighAlarmTestResult', discard: 'F833AltBeamBlockTest' },
        { test: 'F832AltHighAlarmTestResult', test2: 'F835AltBeamBlockTestResult', discard: 'F829AltLowAlarmTest' },
        { test: 'F830AltLowAlarmTestResult', test2: 'F835AltBeamBlockTestResult', discard: 'F831AltHighAlarmTest' },
        { test: 'F830AltLowAlarmTestResult', discard: 'F831AltHighAlarmTest', discard2: 'F833AltBeamBlockTest' },
        { test: 'F832AltHighAlarmTestResult', discard: 'F829AltLowAlarmTest', discard2: 'F833AltBeamBlockTest' },
        { test: 'F830AltLowAlarmTestResult', test2: 'F832AltHighAlarmTestResult', test3: 'F835AltBeamBlockTestResult' }
    ];

    for (const field of fields) {
        const { test, test2, test3, discard, discard2 } = field;
        if (
            (FieldHasValue(formData[test]) && formData[discard] === 'DISCARD' && formData[discard2] === 'DISCARD')
            || (FieldHasValue(formData[test]) && FieldHasValue(formData[test2]) && formData[discard] === 'DISCARD')
            || (FieldHasValue(formData[test]) && FieldHasValue(formData[test2]) && FieldHasValue(formData[test3]))
        ) {
            if (formData[test] === 'FAIL' || formData[test2] === 'FAIL' || formData[test3] === 'FAIL') {
                return 'FAIL';
            }
            return 'PASS';
        }
    }

    return '';
};
