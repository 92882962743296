import { FieldHasValue, ValidateField } from 'helpers/Validation';

export const AsLeftTestTableP716Formula = (formData) => {
    if (FieldHasValue(formData.F754AltValvePartialStrokeTestResult) && FieldHasValue(formData.F758AltSmartPartialStrokeTestResult)) {
        if (ValidateField(formData.F758AltSmartPartialStrokeTestResult === 'Successful') && ValidateField(formData.F754AltValvePartialStrokeTestResult === 'Successful')) {
            return 'PASS';
        }
        return 'FAIL';
    }
    if ((FieldHasValue(formData.F754AltValvePartialStrokeTestResult) || FieldHasValue(formData.F758AltSmartPartialStrokeTestResult))
        && (ValidateField(formData.F759AsLeftTestPstMethod === 'Solenoid Valve') || ValidateField(formData.F759AsLeftTestPstMethod === 'Smart Positioner'))) {
        if ((formData.F754AltValvePartialStrokeTestResult === 'Successful') || (formData.F758AltSmartPartialStrokeTestResult === 'Successful')) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
