import { hmiPlaceholderName, phyPlaceholderName } from '../../../HMISection/Data/P8141/P8141.data';

export const TestResultSectionDataP8141 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F143HmiswitchStatusAfterTest',
            label: 'HMI Switch Status After Test',
            value: formData.F143HmiswitchStatusAfterTest,
            isDropDown: true,
            placeholder: hmiPlaceholderName,
            options: [
                { name: hmiPlaceholderName, value: '' },
                { name: 'Activated', value: 'Activated' },
                { name: 'Deactivated', value: 'Deactivated' }],
            direction: 'rtl',
            xs: 6,
            width: '49%',
            isRequired: true,
            labelWidth: '51.9%',
            inputTextAlign: 'center'
        },
        {
            key: 'F457PhysicalSwitchReadingAfterTest',
            label: 'Physical Switch Status After Test',
            value: formData.F457PhysicalSwitchReadingAfterTest,
            isDropDown: true,
            placeholder: phyPlaceholderName,
            options: [
                { name: phyPlaceholderName, value: '' },
                { name: 'Activated', value: 'Activated' },
                { name: 'Deactivated', value: 'Deactivated' }],
            labelWidth: '39%',
            marginRight: '0.5rem',
            width: '61%',
            xs: 6,
            marginLeft: '0.8px',
            borderBottom: '0'
        }]
    }]
});
