import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStylesDrawer = makeStyles(() => ({
    root: {
        display: 'flex',
    },
    drawer: {
        flexDirection: 'column',
        position: 'absolute',
        height: 'inherit',
        overflow: 'hidden',
        width: '14%',
        float: 'left',
    },
    drawerPaper: {
        width: '99%',
        position: 'absolute',
        height: '99.6%',

    },
    drawerPaperAnchorDockedLeft: {
        borderRight: '2px solid rgba(73, 130, 5, 1)',
    },
    primary: {
        fontSize: '11px',
    },
    tooltip: {
        fontSize: '10.5px',
        backgroundColor: 'white',
        border: '1px solid black',
        color: 'rgba(0, 0, 0, 0.87)',
        margin: 0
    }

}));

export const AdminWrapper = styled.div`
    height: inherit;
    overflow: hidden;
    float: right;
    width: 86%;
`;
