export const FailureModeSectionDataP611 = (formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F439ContactorFailState',
            label: 'Test mode',
            value: formData.F439ContactorFailState,
            isDropdown: true,
            placeholder: 'Select Test mode',
            options: [{ name: 'Select Test mode', value: '' },
                { name: 'Live: running motor trip', value: 'Live: running motor trip' },
                { name: 'Offline: stopped motor test', value: 'Offline: stopped motor test' }],
            isRequired: true,
            xs: 6,
            width: '48.5%',
            labelWidth: '50%',
            marginRight: '0.5rem',
            height: '1.1rem',
            marginTop: '15px',
            marginBottom: '15px',
            inputTextAlign: 'center',
        }]
    }]
});
