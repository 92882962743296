import { asFoundResultFormula } from '../../../AsFoundIP_TransmitterSection/AsFoundIP_TransmitterSection.formulas';

export const FoundResultSectionDataP6142 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'Nodata',
                    isLabel: 'true',
                    xs: 4,
                },
                {
                    key: 'F101AftToleranceLabel',
                    value: 'Tolerance (%)',
                    xs: 2,
                    width: '96%',
                    readOnly: true,
                    labelWidth: '0',
                    height: '1.1rem',
                    borderRight: '0',
                    borderBottom: '0',
                    isLabelNew: true,
                    marginBottom: '4px',
                    marginTop: '20px',
                    textAlign: 'right',
                    borderTop: '0',
                    borderLeft: '0',
                },
                {
                    key: 'F101AftTolerance',
                    value: selectedTag.DateTested ? formData?.F101AftTolerance : formData?.F101AftTolerance ?? selectedTag?.ToleranceInInstrumentEu,
                    isRequired: true,
                    type: 'number',
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    maxLength: '10',
                    textAlign: 'center',
                    height: '1.1rem',
                    borderBottom: '0',
                    marginTop: '20px'
                },
                {
                    key: 'F61AsFoundResultLabel',
                    value: 'As-Found Result',
                    width: '96%',
                    readOnly: true,
                    labelWidth: '0',
                    height: '1.1rem',
                    xs: 2,
                    borderRight: '0',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderTop: '0',
                    isLabelBold: true,
                    textAlign: 'right',
                    marginTop: '20px',
                    marginBottom: '4px',
                },
                {
                    key: 'F61AsFoundResult',
                    value: asFoundResultFormula(formData),
                    readOnly: true,
                    isCalculatedNew: true,
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                    height: '1.1rem',
                    borderRight: '0',
                    borderBottom: '0',
                    inputMarginRight: '4px',
                    marginTop: '20px',
                    marginBottom: '4px',
                }]
        }]
});
