import { ExponentialToDecimal, FieldHasValue, ValidateField, ValidateNumberField } from 'helpers/Validation';
import { round } from 'lodash';

export const AsFoundTestTableF39HmiInst1Formula = (formData) => (FieldHasValue(formData.F21HmiLrv) ? '0% - '.concat(ValidateField(formData.F21HmiLrv))
    .concat(' ')
    .concat(ValidateField(formData.F24HmiEngineeringUnit))
    .concat(' - ')
    .concat(ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)))
    .concat(' ')
    .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '0%');

export const AsFoundTestTableF40HmiInst2Formula = (formData) => {
    let Hmi2Inst2;
    if (FieldHasValue(formData.F21HmiLrv) || FieldHasValue(formData.F22HmiUrv)) {
        Hmi2Inst2 = '50% - '.concat(round(((ExponentialToDecimal(Math.sqrt(((Math.pow((ValidateNumberField(formData.F21HmiLrv)), 2))
        + (Math.pow((ValidateNumberField(formData.F22HmiUrv)), 2))) / 2)))), 2))
        .concat(' ')
        .concat(ValidateField(formData.F24HmiEngineeringUnit))
        .concat(' - ')
        .concat(ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv) + (ValidateNumberField(formData.F26InstrumentUrv)
            - ValidateNumberField(formData.F25InstrumentLrv)) * (0.5)))
        .concat(' ')
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        return Hmi2Inst2.length > 255 ? Hmi2Inst2.slice(0, 255) : Hmi2Inst2;
    }
    return '50%';
};

export const AsFoundTestTableF41HmiInst3Formula = (formData) => FieldHasValue(formData.F22HmiUrv) ? '100% - '.concat(ValidateField(formData.F22HmiUrv))
    .concat(' ')
    .concat(ValidateField(formData.F24HmiEngineeringUnit))
    .concat(' - ')
    .concat(ValidateField(formData.F26InstrumentUrv))
    .concat(' ')
    .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '100%';
