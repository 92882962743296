import { FieldHasValue, ValidateNumberField, ExponentialToDecimal, IsNonText } from 'helpers/Validation';

export const AsLeftTestTableF237AltDeviation1Formula = (formData) => {
    if (FieldHasValue(formData.F487AltLocalTransmitterTxreading1)
        && FieldHasValue(formData.F109AltHmireading1)
        && FieldHasValue(formData.F164AltMeasuredReading1)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F109AltHmireading1)
            - ValidateNumberField(formData.F487AltLocalTransmitterTxreading1)));
    }
    return '';
};

export const AsLeftTestTableF238AltDeviation2Formula = (formData) => {
    if (FieldHasValue(formData.F488AltLocalTransmitterTxreading2)
        && FieldHasValue(formData.F113AltHmireading2)
        && FieldHasValue(formData.F165AltMeasuredReading2)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F113AltHmireading2)
            - ValidateNumberField(formData.F488AltLocalTransmitterTxreading2)));
    }
    return '';
};

export const AsLeftTestTableF239AltDeviation3Formula = (formData) => {
    if (FieldHasValue(formData.F489AltLocalTransmitterTxreading3)
        && FieldHasValue(formData.F114AltHmireading3)
        && FieldHasValue(formData.F166AltMeasuredReading3)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F114AltHmireading3)
            - ValidateNumberField(formData.F489AltLocalTransmitterTxreading3)));
    }
    return '';
};

export const F81AsLeftResultFormula = (formData) => {
    if (formData.F472SignalToTriggerLevelPriortoTest === 'Error') {
        return 'FAIL';
    }
    if (FieldHasValue(formData.F101AftTolerance)
        && IsNonText(formData.F237AltDeviation1)
        && IsNonText(formData.F238AltDeviation2)
        && IsNonText(formData.F239AltDeviation3)) {
        return Math.max.apply(Math, [ValidateNumberField(formData.F237AltDeviation1),
        ValidateNumberField(formData.F238AltDeviation2), ValidateNumberField(formData.F239AltDeviation3)])
            > ValidateNumberField(formData.F101AftTolerance)
            ? 'FAIL' : 'PASS';
    }
    return '';
};
