import { commonConstants, valveToLouverDamper } from 'journeys/portal/Forms/Sections.constants';
import { placeholderPerformDiscard, disableTestSectionAft, disableConditionAft, calculateResult } from '../../AFT_SolenoidLimitSection/AsFoundSolenoidLimitSection.data';
import { calculateDeviation } from '../AsFoundIP_TransmitterSection.formulas';
import { conditionalValueSelector } from 'helpers/Validation';
import { asFoundIPTransmitterTestResultData } from './P6142TestResult.data';
import { paddingvalue } from '../../AsFoundTestTableSection/Data/C11/C11.data';

export const asFoundIPTransmitterSectionData = (selectedTag, formName, formData) => {
    const disableTest2Key = formData.F850AftTest2IpPositioner;
    const disableTest3Key = formData.F870AftTest3PositionTransmitter;
    return {
        rows: [
            {
                key: 1,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 4,
                        marginBottom: '3.5px'
                    },
                    {
                        key: 'F850AftTest2IpPositionerLabel',
                        value: 'Test 2 - I/P Positioner',
                        xs: 2,
                        isLabelBold: true,
                        height: '1.28rem',
                        textAlign: 'center',
                        borderRight: '0',
                        borderBottom: '0',
                        borderLeft: '0',
                        borderTop: '0',
                        width: '100%',
                        readOnly: true,
                        labelWidth: '0',
                        marginTop: '10px',
                    },

                    {
                        key: 'F850AftTest2IpPositioner',
                        value: conditionalValueSelector(calculateResult(formData.F837IplelementIppositioner), commonConstants.Perform, disableTest2Key),
                        isDropdown: !calculateResult(formData.F837IplelementIppositioner),
                        readOnly: calculateResult(formData.F837IplelementIppositioner),
                        isRequired: true,
                        placeholder: placeholderPerformDiscard,
                        options: [
                            { name: placeholderPerformDiscard, value: '' },
                            { name: commonConstants.Perform, value: commonConstants.Perform },
                            { name: commonConstants.Discard, value: commonConstants.Discard }],
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        xs: 2,
                        inputTextAlign: 'center',
                        marginTop: '10px',
                        height: conditionalValueSelector(calculateResult(formData.F837IplelementIppositioner), '1.25rem', '1.5rem')
                    },
                    {
                        key: 'F870AftTest3PositionTransmitterLabel',
                        value: 'Test 3 - Position Transmitter',
                        xs: 2,
                        isLabelBold: true,
                        fontWeight: '700',
                        width: '100%',
                        readOnly: true,
                        labelWidth: '0',
                        textAlign: 'center',
                        padding: '14px 0 0 0',
                        borderRight: '0',
                        borderBottom: '0',
                        borderLeft: '0',
                        borderTop: '0',
                        marginTop: '10px',
                        height: '1.28rem',
                    },
                    {
                        key: 'F870AftTest3PositionTransmitter',
                        value: conditionalValueSelector(calculateResult(formData.F838IplelementPositionTransmitter), commonConstants.Perform, disableTest3Key),
                        isDropdown: !calculateResult(formData.F838IplelementPositionTransmitter),
                        readOnly: calculateResult(formData.F838IplelementPositionTransmitter),
                        isRequired: true,
                        placeholder: placeholderPerformDiscard,
                        options: [
                            { name: placeholderPerformDiscard, value: '' },
                            { name: commonConstants.Perform, value: commonConstants.Perform },
                            { name: commonConstants.Discard, value: commonConstants.Discard }],
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        xs: 2,
                        marginTop: '10px',
                        borderRight: '0',
                        inputTextAlign: 'center',
                        height: conditionalValueSelector(calculateResult(formData.F838IplelementPositionTransmitter), '1.25rem', '1.5rem')
                    }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'ValvePositionCommand',
                        label: `${valveToLouverDamper(formName)} Position Command (%)`,
                        xs: 3,
                        labelWidth: '100%',
                        textAlign: 'center',
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        padding: paddingvalue
                    },
                    {
                        key: 'ValveLocalPosition',
                        label: `${valveToLouverDamper(formName)} Local Position (%)`,
                        xs: 2,
                        labelWidth: '100%',
                        textAlign: 'center',
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        padding: paddingvalue
                    },
                    {
                        key: 'Deviation1',
                        label: 'Deviation (%)',
                        xs: 2,
                        labelWidth: '100%',
                        textAlign: 'center',
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        padding: paddingvalue
                    },
                    {
                        key: 'HMIPositionIndication',
                        label: 'HMI Position Indication (%)',
                        xs: 2,
                        labelWidth: '100%',
                        textAlign: 'center',
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        padding: paddingvalue
                    },
                    {
                        key: 'Deviation2',
                        label: 'Deviation (%)',
                        xs: 2,
                        labelWidth: '100%',
                        textAlign: 'center',
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        padding: paddingvalue
                    }]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1
                    },
                    {
                        key: 'ValvePositionCommand',
                        value: 0,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                    },
                    {
                        key: 'F851AftValveLocalPosition1',
                        value: disableConditionAft(disableTest2Key, formData.F851AftValveLocalPosition1),
                        isRequired: !disableTestSectionAft(disableTest2Key),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        readOnly: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        borderRight: '0',
                        borderLeft: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F860AftTest2Deviation1',
                        value: calculateDeviation(formData.F851AftValveLocalPosition1, formData.ValvePositionCommand),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F871AftHmiPositionIndication1',
                        value: disableConditionAft(disableTest3Key, formData.F871AftHmiPositionIndication1),
                        isRequired: !disableTestSectionAft(disableTest3Key),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        readOnly: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        borderRight: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F880AftTest3Deviation1',
                        value: calculateDeviation(formData.F871AftHmiPositionIndication1, formData.ValvePositionCommand),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'ValvePositionCommand1',
                        value: 25,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                    },
                    {
                        key: 'F852AftValveLocalPosition2',
                        value: disableConditionAft(disableTest2Key, formData.F852AftValveLocalPosition2),
                        isRequired: !disableTestSectionAft(disableTest2Key),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        readOnly: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F861AftTest2Deviation2',
                        value: calculateDeviation(formData.F852AftValveLocalPosition2, formData.ValvePositionCommand1),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        borderBottom: '0',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F872AftHmiPositionIndication2',
                        value: disableConditionAft(disableTest3Key, formData.F872AftHmiPositionIndication2),
                        isRequired: !disableTestSectionAft(disableTest3Key),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        readOnly: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        borderRight: '0',
                        textAlign: 'center'
                    },
                    {
                        key: 'F881AftTest3Deviation2',
                        value: calculateDeviation(formData.F872AftHmiPositionIndication2, formData.ValvePositionCommand1),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'ValvePositionCommand2',
                        value: 50,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F853AftValveLocalPosition3',
                        value: disableConditionAft(disableTest2Key, formData.F853AftValveLocalPosition3),
                        isRequired: !disableTestSectionAft(disableTest2Key),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        readOnly: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        borderBottom: '0',
                        type: 'number',
                        maxLength: '10',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F862AftTest2Deviation3',
                        value: calculateDeviation(formData.F853AftValveLocalPosition3, formData.ValvePositionCommand2),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F873AftHmiPositionIndication3',
                        value: disableConditionAft(disableTest3Key, formData.F873AftHmiPositionIndication3),
                        isRequired: !disableTestSectionAft(disableTest3Key),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        readOnly: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F882AftTest3Deviation3',
                        value: calculateDeviation(formData.F873AftHmiPositionIndication3, formData.ValvePositionCommand2),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'ValvePositionCommand3',
                        value: 75,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                    },
                    {
                        key: 'F854AftValveLocalPosition4',
                        value: disableConditionAft(disableTest2Key, formData.F854AftValveLocalPosition4),
                        isRequired: !disableTestSectionAft(disableTest2Key),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        readOnly: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F863AftTest2Deviation4',
                        value: calculateDeviation(formData.F854AftValveLocalPosition4, formData.ValvePositionCommand3),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F874AftHmiPositionIndication4',
                        value: disableConditionAft(disableTest3Key, formData.F874AftHmiPositionIndication4),
                        isRequired: !disableTestSectionAft(disableTest3Key),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        readOnly: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F883AftTest3Deviation4',
                        value: calculateDeviation(formData.F874AftHmiPositionIndication4, formData.ValvePositionCommand3),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 7,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'ValvePositionCommand4',
                        value: 100,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F855AftValveLocalPosition5',
                        value: disableConditionAft(disableTest2Key, formData.F855AftValveLocalPosition5),
                        isRequired: !disableTestSectionAft(disableTest2Key),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        readOnly: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F864AftTest2Deviation5',
                        value: calculateDeviation(formData.F855AftValveLocalPosition5, formData.ValvePositionCommand4),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F875AftHmiPositionIndication5',
                        value: disableConditionAft(disableTest3Key, formData.F875AftHmiPositionIndication5),
                        isRequired: !disableTestSectionAft(disableTest3Key),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        readOnly: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F884AftTest3Deviation5',
                        value: calculateDeviation(formData.F875AftHmiPositionIndication5, formData.ValvePositionCommand4),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 8,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'ValvePositionCommand5',
                        value: 75,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F856AftValveLocalPosition6',
                        value: disableConditionAft(disableTest2Key, formData.F856AftValveLocalPosition6),
                        isRequired: !disableTestSectionAft(disableTest2Key),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        readOnly: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F865AftTest2Deviation6',
                        value: calculateDeviation(formData.F856AftValveLocalPosition6, formData.ValvePositionCommand5),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        borderBottom: '0',
                        type: 'number',
                        readOnly: true,
                        maxLength: '10',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F876AftHmiPositionIndication6',
                        value: disableConditionAft(disableTest3Key, formData.F876AftHmiPositionIndication6),
                        isRequired: !disableTestSectionAft(disableTest3Key),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        readOnly: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F885AftTest3Deviation6',
                        value: calculateDeviation(formData.F876AftHmiPositionIndication6, formData.ValvePositionCommand5),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 9,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'ValvePositionCommand6',
                        value: 50,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        height: '1.2rem',
                        borderBottom: '0',
                        type: 'number',
                        maxLength: '10',
                        isLabelNew: true,
                        textAlign: 'center',
                    },
                    {
                        key: 'F857AftValveLocalPosition7',
                        value: disableConditionAft(disableTest2Key, formData.F857AftValveLocalPosition7),
                        isRequired: !disableTestSectionAft(disableTest2Key),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        readOnly: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F866AftTest2Deviation7',
                        value: calculateDeviation(formData.F857AftValveLocalPosition7, formData.ValvePositionCommand6),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        borderBottom: '0',
                        type: 'number',
                        readOnly: true,
                        maxLength: '10',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F877AftHmiPositionIndication7',
                        value: disableConditionAft(disableTest3Key, formData.F877AftHmiPositionIndication7),
                        isRequired: !disableTestSectionAft(disableTest3Key),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        readOnly: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F886AftTest3Deviation7',
                        value: calculateDeviation(formData.F877AftHmiPositionIndication7, formData.ValvePositionCommand6),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 10,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'ValvePositionCommand7',
                        value: 25,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F858AftValveLocalPosition8',
                        value: disableConditionAft(disableTest2Key, formData.F858AftValveLocalPosition8),
                        isRequired: !disableTestSectionAft(disableTest2Key),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        readOnly: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F867AftTest2Deviation8',
                        value: calculateDeviation(formData.F858AftValveLocalPosition8, formData.ValvePositionCommand7),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F878AftHmiPositionIndication8',
                        value: disableConditionAft(disableTest3Key, formData.F878AftHmiPositionIndication8),
                        isRequired: !disableTestSectionAft(disableTest3Key),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        readOnly: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F887AftTest3Deviation8',
                        value: calculateDeviation(formData.F878AftHmiPositionIndication8, formData.ValvePositionCommand7),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 11,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'ValvePositionCommand8',
                        value: 0,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        isLabelNew: true,
                        textAlign: 'center',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F859AftValveLocalPosition9',
                        value: disableConditionAft(disableTest2Key, formData.F859AftValveLocalPosition9),
                        isRequired: !disableTestSectionAft(disableTest2Key),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        readOnly: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F868AftTest2Deviation9',
                        value: calculateDeviation(formData.F859AftValveLocalPosition9, formData.ValvePositionCommand8),
                        isDisabled: disableTestSectionAft(disableTest2Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        readOnly: true,
                        maxLength: '10',
                        height: '1.2rem',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F879AftHmiPositionIndication9',
                        value: disableConditionAft(disableTest3Key, formData.F879AftHmiPositionIndication9),
                        isRequired: !disableTestSectionAft(disableTest3Key),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        readOnly: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F888AftTest3Deviation9',
                        value: calculateDeviation(formData.F879AftHmiPositionIndication9, formData.ValvePositionCommand8),
                        isDisabled: disableTestSectionAft(disableTest3Key),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            asFoundIPTransmitterTestResultData(formData),
        ]
    };
};
