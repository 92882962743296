import { paddingAF } from '../M71/M71.data';
import { AsLeftF215AftDeviation1Formula, AsLeftResultResultFormula, CalibrationPoint1Formula } from './P724.formula';

export const AsLeftTestTableSectionDataP724 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'As-LeftTest',
            label: 'As-Left Test',
            isLabel: true,
            xs: 12,
            fontWeight: 700,
            padding: '5px 0 0 8px'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'CalibrationPoint',
            label: 'Calibration Point',
            isLabel: true,
            xs: 3,
            fontWeight: 700,
            labelWidth: '100%',
            padding: '5px 0 5px 8px',
            fontSize: '9pt'
        },
        {
            key: 'ReferenceCalibrationGas',
            label: 'Reference Calibration Gas',
            isLabel: true,
            xs: 2,
            fontWeight: 700,
            labelWidth: '100%',
            textAlign: 'center',
            padding: paddingAF,
            fontSize: '9pt'
        },
        {
            key: 'AnalyzerReading',
            label: 'Analyzer Reading',
            isLabel: true,
            xs: 2,
            fontWeight: 700,
            textAlign: 'center',
            labelWidth: '100%',
            padding: paddingAF,
            fontSize: '9pt'
        },
        {
            key: 'HMIReading',
            label: 'HMI Reading',
            isLabel: true,
            xs: 2,
            fontWeight: 700,
            labelWidth: '100%',
            textAlign: 'center',
            padding: paddingAF,
            fontSize: '9pt'
        },
        {
            key: 'Deviation',
            label: 'Deviation',
            isLabel: true,
            xs: 3,
            fontWeight: 700,
            textAlign: 'center',
            padding: paddingAF,
            fontSize: '9pt'
        }]
    },
    {
        key: 3,
        fields: [{
            key: 'F136AltCalibrationPoint1',
            value: formData.F102AftResult !== 'Pass' ? CalibrationPoint1Formula(formData) : '',
            isDisabled: formData.F102AftResult === 'Pass',
            xs: 3,
            width: '100%',
            labelWidth: '0',
            readOnly: true,
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            marginLeft: '4px'
        },
        {
            key: 'F502AltReferenceCalibrationGas',
            value: formData.F102AftResult !== 'Pass' ? formData.F502AltReferenceCalibrationGas : '',
            isRequired: formData.F102AftResult !== 'Pass',
            isDisabled: formData.F102AftResult === 'Pass',
            readOnly: formData.F102AftResult === 'Pass',
            xs: 2,
            type: 'number',
            maxLength: '10',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderRight: '0'
        },
        {
            key: 'F357AnalyzerReading',
            value: formData.F102AftResult !== 'Pass' ? formData.F357AnalyzerReading : '',
            isRequired: formData.F102AftResult !== 'Pass',
            isDisabled: formData.F102AftResult === 'Pass',
            readOnly: formData.F102AftResult === 'Pass',
            xs: 2,
            type: 'number',
            maxLength: '10',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderRight: '0'
        },
        {
            key: 'F109AltHmireading1',
            value: formData.F102AftResult !== 'Pass' ? formData.F109AltHmireading1 : '',
            isRequired: formData.F102AftResult !== 'Pass',
            isDisabled: formData.F102AftResult === 'Pass',
            readOnly: formData.F102AftResult === 'Pass',
            type: 'number',
            maxLength: '10',
            xs: 2,
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderRight: '0'
        },
        {
            key: 'F237AltDeviation1',
            value: formData.F102AftResult !== 'Pass' ? AsLeftF215AftDeviation1Formula(formData) : '',
            isDisabled: formData.F102AftResult === 'Pass',
            xs: 3,
            textAlign: 'center',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            inputMarginRight: '4px',
            readOnly: true,
        }]
    },
    {
        key: 4,
        fields: [{
            key: 'NOdata',
            isLabel: true,
            xs: 5
        },
        {
            key: 'F103AltResultLabel',
            value: 'As-Left Pass/Fail Result',
            xs: 4,
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            textAlign: 'end',
            height: '1.2rem',
            marginBottom: '4px',
            isLabelBold: true,
            borderBottom: '0',
            borderLeft: '0',
            borderRight: '0',
            borderTop: '0'
        },
        {
            key: 'F103AltResult',
            value: formData.F102AftResult !== 'Pass' ? AsLeftResultResultFormula(formData) : '',
            isDisabled: formData.F102AftResult === 'Pass',
            readOnly: true,
            textAlignLabel: 'end',
            isCalculatedNew: true,
            xs: 3,
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            marginBottom: '4px',
            inputMarginRight: '4px',
            fontWeight: '700',
            borderTop: '0'
        }]
    }]
});
