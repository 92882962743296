import { formsInitialState } from './Forms.data';
import { FORM_ACTIONS } from './Forms.constants';

export default function FormsReducer(state = formsInitialState, action) {
    switch (action.type) {
        case FORM_ACTIONS.CLEAR_SELECTED_TRF:
        case FORM_ACTIONS.GET_ITF_FORM_PENDING:
        case FORM_ACTIONS.GET_ITF_FORM_SUCCESS:
        case FORM_ACTIONS.GET_ITF_FORM_FAILURE:
        case FORM_ACTIONS.GET_OFFLINE_FORM_COUNT:
        case FORM_ACTIONS.GET_ITF_FORM_OFFLINE_SUCCESS:
        case FORM_ACTIONS.SET_FORMTAG_SELECTED:
        case FORM_ACTIONS.GET_DPSRECORD_PENDING:
        case FORM_ACTIONS.GET_DPSRECORD_SUCCESS:
        case FORM_ACTIONS.RESET_FORM_DATA:
        case FORM_ACTIONS.SET_SELECTED_TRF:
        case FORM_ACTIONS.DELETE_IMAGE:
        case FORM_ACTIONS.CAPTURE_IMAGE:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWFORMDATA_PENDING:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWFORMDATA_SUCCESS:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWFORMDATA_FAILURE:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMITNEWFORMDATA_OFFLINE:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMIT_ASSIGNED_TRFID_PENDING:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMIT_ASSIGNED_TRFID_SUCCESS:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMIT_ASSIGNED_TRFID_FAILURE:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMIT_ASSIGNED_TRFID_OFFLINE:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMITEXISTINGFORMDATA_PENDING:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMITEXISTINGFORMDATA_SUCCESS:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMITEXISTINGFORMDATA_FAILURE:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMITEXISTINGFORMDATA_OFFLINE:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_SUCCESS:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_OFFLINE:
        case FORM_ACTIONS.FORM_ACTIONS_SUBMITTRFIMAGE_FAILURE:
        case FORM_ACTIONS.GET_TRF_FORM_IMAGE:
        case FORM_ACTIONS.SET_P81_FORMDATA:
        case FORM_ACTIONS.SET_SRA_FORMDATA:
        case FORM_ACTIONS.CLEAR_SRA_FORMDATA:
        case FORM_ACTIONS.UPDATE_IMAGE_NOTE_PENDING:
        case FORM_ACTIONS.UPDATE_IMAGE_NOTE_SUCCESS:
        case FORM_ACTIONS.UPDATE_IMAGE_NOTE_FAILURE:
        case FORM_ACTIONS.UPDATE_IMAGE_NOTE_OFFLINE_SUCCESS:
        case FORM_ACTIONS.UPDATE_IMAGE_NOTE_OFFLINE_FAILURE:
        case FORM_ACTIONS.SET_IS_EDIT_IMAGE:
        case FORM_ACTIONS.UPDATE_FORMDATE_OBJECT:
        case FORM_ACTIONS.EDIT_IMAGE_NOTE:
        case FORM_ACTIONS.CLEAR_EDIT_IMAGE_LIST:
        case FORM_ACTIONS.UPDATE_ASFOUND_TESTPOINTRANGE:
        case FORM_ACTIONS.UPDATE_ASLEFT_TESTPOINTRANGE:
        case FORM_ACTIONS.UPDATE_TESTPOINTRANGE:
        case FORM_ACTIONS.UPDATE_FLOWALARM_TESTPOINTRANGE:
        case FORM_ACTIONS.UPDATE_SPINNER_VISIBLE:
        case FORM_ACTIONS.FORM_ACTIONS_GET_MULTI_TRF_DETAILS_DATA_SUCCESS:
        case FORM_ACTIONS.FORM_ACTIONS_SAVE_DRAFT_OFFLINE_FAILURE:
        case FORM_ACTIONS.FORM_ACTIONS_SAVE_DRAFT_OFFLINE_SUCCESS:
        case FORM_ACTIONS.FORM_ACTIONS_SAVE_DRAFT_ONLINE_SUCCESS:
        case FORM_ACTIONS.FORM_ACTIONS_SAVE_DRAFT_ONLINE_FAILURE:
        case FORM_ACTIONS.FORM_ACTIONS_UPDATE_DRAFT_OFFLINE_FAILURE:
        case FORM_ACTIONS.FORM_ACTIONS_UPDATE_DRAFT_OFFLINE_SUCCESS:
        case FORM_ACTIONS.FORM_ACTIONS_UPDATE_DRAFT_ONLINE_SUCCESS:
        case FORM_ACTIONS.FORM_ACTIONS_UPDATE_DRAFT_ONLINE_FAILURE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
