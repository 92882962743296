import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { conditionalValueSelector } from 'helpers/Validation';

export const actualOrSimulatedLevel = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F980UnitsUsedLabel',
                label: 'Units used for readings:',
                labelWidth,
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '20px 0 0 0',
            },
            {
                key: 'F980UnitsUsed',
                value: formData.F980UnitsUsed ? formData.F980UnitsUsed : 'Instrument',
                options: [{ name: 'Instrument', value: 'Instrument' },
                { name: 'HMI', value: 'HMI' }],
                isDropdown: true,
                isRequired: true,
                labelWidth: '0%',
                width: '100%',
                inputTextAlign: 'center',
                noDefaultOption: true,
                textAlign: 'center',
                marginTop: '18px',
                borderBottom: '0',
                xs: 2
            },
            {
                key: 'Nodata',
                isLabel: true,
                xs: 6,
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F988ActualSimualtedLevelLabel',
                    label: 'Actual or Simulated Level?',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F988ActualSimualtedLevel',
                    value: conditionalValueSelector(formData.F988ActualSimualtedLevel, formData.F988ActualSimualtedLevel, 'Simulated'),
                    isDropdown: true,
                    isRequired: true,
                    options: [{ name: 'Actual', value: 'Actual' },
                    { name: 'Simulated', value: 'Simulated' }],
                    xs: 2,
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    noDefaultOption: true,
                },
                ...(formData.F988ActualSimualtedLevel === 'Simulated'
                    ? [
                        {
                            key: 'noData',
                            isLabel: true,
                            xs: 1,
                        },
                        {
                            key: 'F989SimulatedCurrentInjectionLabel',
                            label: 'Simulated Using Current (mA) Injection?',
                            xs: 3,
                            isLabel: true,
                            labelWidth,
                            fontWeight: '600',
                            fontSize: '9pt',
                            textAlign: 'right',
                        },
                        {
                            key: 'F989SimulatedCurrentInjection',
                            value: conditionalValueSelector(formData.F989SimulatedCurrentInjection, formData.F989SimulatedCurrentInjection, 'YES'),
                            isDropdown: true,
                            isRequired: true,
                            options: [{ name: 'YES', value: 'YES' },
                            { name: 'NO', value: 'NO' }],
                            xs: 2,
                            inputTextAlign: 'center',
                            width: '100%',
                            labelWidth: '0%',
                            textAlign: 'center',
                            noDefaultOption: true,
                        }
                    ] : [])
            ]
        },
    ]
});
