import { TestPointRange } from 'helpers/Validation';
import {
    AsFoundTestDeviationFormula, AsFoundResultMandatoryCheck, AsFoundTestDeviation1Formula,
    AsFoundResultFormula, ToleranceLabelFormula, OutputOffsetFormula
} from './P720.formulas';
import { SystemReadingFormulaValueCheck } from '../P721/P721.formulas';
import { getAftDeleteIconStatus } from '../../AsFoundTestTableSection.helpers';

export const paddingValue = '0 0 0px 8px';
export const placeholderUnit = 'Select Unit';
export const AsFoundTestTableSectionDataP720 = (selectedTag, formData, aftTestPoint) => {
    const rows = [];
    rows.push({
        key: 1,
        fields: [{
            key: 'AsFoundTest',
            label: 'As-Found Test',
            isLabel: true,
            xs: 3,
            fontWeight: '700',
            padding: '8px 0 0 8px',
            borderTop: '0',
        },
        {
            key: 'F675NumberOfTestPoints',
            label: 'Number of Test Points',
            value: formData?.F675NumberOfTestPoints,
            isBlur: true,
            type: 'number',
            maxLength: '10',
            xs: 3,
            labelWidth: '80.2%',
            width: '64%',
            height: '1.26rem',
            isRequired: true,
            marginRight: '0.5rem',
            textAlign: 'center',
            marginTop: '4px',
            borderTop: '0',
            marginBottom: '10px',
            direction: 'rtl',
            readOnly: getAftDeleteIconStatus(),
            hasTestPointError: !TestPointRange(formData.F675NumberOfTestPoints, 10)
        },
        {
            key: 'F676TransducerOutputSignal',
            label: 'Sensor Output Signal',
            value: formData.F676TransducerOutputSignal,
            xs: 3,
            isDropdown: true,
            isRequired: true,
            placeholder: placeholderUnit,
            options: [
                { name: placeholderUnit, value: '' },
                { name: 'Voltage', value: 'Voltage' },
                { name: 'Current', value: 'Current' }],
            inputTextAlign: 'center',
            labelWidth: '52%',
            width: '49.1%',
            height: '1.5rem',
            borderTop: '0',
            marginTop: '4px',
            marginRight: '0.28rem',
            direction: 'rtl',
        },
        {
            key: 'F774AftOutputOffset',
            label: OutputOffsetFormula(formData),
            value: formData.F774AftOutputOffset,
            type: 'number',
            maxLength: '10',
            xs: 3,
            labelWidth: '67%',
            borderRight: '0',
            width: '50%',
            height: '1.26rem',
            marginRight: '0.2rem',
            textAlign: 'center',
            marginTop: '4px',
            borderTop: '0',
            marginBottom: '10px',
            direction: 'rtl',
            inputMarginRight: '4px'
        }]
    },
        {
            key: 2,
            fields: [{
                key: 'remark',
                label: 'Remark: \'Deviation\' is calculated between the simulated \'Vibration Amplitude\' and the \'HMI Reading\'.',
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '100%',
                padding: '0px 1px 10px 9px',
                fontSize: '8.5pt',
                color: '#ea7c34'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'AftDelete',
                    label: '',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 1,
                    textAlign: 'center',
                    padding: paddingValue,
                    fontSize: '9pt'
                },
                {
                    key: 'VibrationAmplitude',
                    label: 'Vibration Amplitude',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    padding: paddingValue,
                    fontSize: '9pt'
                },
                {
                    key: 'VibrationFrequency',
                    label: 'Vibration Frequency',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 1,
                    textAlign: 'center',
                    fontSize: '9pt',
                    padding: paddingValue,
                },
                {
                    key: 'SensorOutput',
                    label: 'Sensor Output',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt',
                    padding: paddingValue,
                },
                {
                    key: 'SystemReading',
                    label: 'System 1 Reading',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt',
                    padding: paddingValue,
                },
                {
                    key: 'HMIReading',
                    label: 'HMI Reading',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt',
                    padding: paddingValue,
                },
                {
                    key: 'Deviation',
                    label: 'Deviation',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt',
                    padding: paddingValue,
                }]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'AftDeleteLabel',
                    value: getAftDeleteIconStatus() ? 'Delete' : '',
                    textAlign: 'center',
                    fontColor: 'red',
                    isDeleteLabel: getAftDeleteIconStatus(),
                    width: '100%',
                    labelWidth: '0',
                    height: '1.28rem',
                    xs: 1,
                    borderLeft: '0',
                    borderRight: '0',
                    borderBottom: '0',
                    readOnly: true,
                    marginLeft: '4px',
                },
                {
                    key: 'F776AftVibrationAmplitude',
                    value: formData.F24HmiEngineeringUnit,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.4rem',
                    xs: 2,
                    borderRight: '0',
                    borderBottom: '0',
                    isCalculatedNew: true,
                    readOnly: true,
                },
                {
                    key: 'F777AftVibrationFrequency',
                    xs: 1,
                    value: formData.F777AftVibrationFrequency,
                    isDropdown: true,
                    isRequired: true,
                    borderBottom: '0',
                    placeholder: placeholderUnit,
                    options: [
                        { name: placeholderUnit, value: '' },
                        { name: 'Hz', value: 'Hz' },
                        { name: 'CPM', value: 'CPM' }],
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.65rem'
                },
                {
                    key: 'F778AFTSensorOutput1',
                    value: AsFoundTestDeviationFormula(formData),
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.4rem',
                    xs: 1,
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    isCalculatedNew: true,
                    readOnly: true,
                },
                {
                    key: 'F779AftSensorOutput2',
                    xs: 1,
                    value: formData.F779AftSensorOutput2,
                    isDropdown: true,
                    isRequired: SystemReadingFormulaValueCheck(formData, 'AftSensorOutput', aftTestPoint),
                    placeholder: placeholderUnit,
                    options: [
                        { name: placeholderUnit, value: '' },
                        { name: 'AC Peak', value: 'AC Peak' },
                        { name: 'AC P-P', value: 'AC P-P' },
                        { name: 'AC RMS', value: 'AC RMS' }],
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.65rem',
                    borderRight: '0',
                    borderBottom: '0',
                },
                {
                    key: 'F691AftSystemReading1',
                    value: formData.F691AftSystemReading1,
                    isDropdown: true,
                    isRequired: SystemReadingFormulaValueCheck(formData, 'AftSystemReading', aftTestPoint),
                    subMenu: true,
                    borderBottom: '0',
                    placeholder: placeholderUnit,
                    options: [
                        {
                            type: 'group',
                            label: '',
                            i: [{ name: placeholderUnit, value: '' }
                            ]
                        },
                        {
                            type: 'group',
                            label: 'Displacement',
                            i: [
                                { name: 'mm', value: 'mm' },
                                { name: 'µm', value: 'µm' },
                                { name: 'inch', value: 'inch' },
                                { name: 'mil', value: 'mil' }
                            ]
                        },
                        {
                            type: 'group',
                            label: 'Velocity',
                            i: [
                                { name: 'mm/s', value: 'mm/s' },
                                { name: 'µm/s', value: 'µm/s' },
                                { name: 'inch/s', value: 'inch/s' },
                                { name: 'mil/s', value: 'mil/s' }
                            ]
                        },
                        {
                            type: 'group',
                            label: 'Acceleration',
                            i: [
                                { name: 'mm/s2', value: 'mm/s2' },
                                { name: 'µm/s2', value: 'µm/s2' },
                                { name: 'inch/s2', value: 'inch/s2' },
                                { name: 'mil/s2', value: 'mil/s2' },
                                { name: 'g', value: 'g' }
                            ]
                        },
                    ],
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.65rem',
                    textAlign: 'center',
                    xs: 1,
                },
                {
                    key: 'F780AftSystemReading',
                    xs: 1,
                    value: formData.F780AftSystemReading,
                    isDropdown: true,
                    isRequired: SystemReadingFormulaValueCheck(formData, 'AftSystemReading', aftTestPoint),
                    placeholder: placeholderUnit,
                    options: [
                        { name: placeholderUnit, value: '' },
                        { name: 'Peak', value: 'Peak' },
                        { name: 'P-P', value: 'P-P' },
                        { name: 'RMS', value: 'RMS' }],
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.65rem',
                    borderLeft: '0',
                    borderBottom: '0',
                },
                {
                    key: 'F50HMIReading3',
                    value: formData.F24HmiEngineeringUnit,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    borderLeft: '0',
                    borderRight: '0',
                    borderBottom: '0',
                    height: '1.4rem',
                    xs: 2,
                    isCalculatedNew: true,
                    readOnly: true,
                },
                {
                    key: 'F215AftDeviation1',
                    value: formData.F24HmiEngineeringUnit,
                    isCalculatedNew: true,
                    readOnly: true,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.4rem',
                    xs: 2,
                    maxLength: '10',
                    borderRight: '0',
                    borderBottom: '0',
                    inputMarginRight: '4px',
                }]
        });
    if (TestPointRange(aftTestPoint ?? formData?.F675NumberOfTestPoints, 10)) {
        for (let index = 2; index < Number(aftTestPoint ?? formData?.F675NumberOfTestPoints) + 2; index += 1) {
            rows.push(
                {
                    key: 4 + index,
                    fields: [{
                        key: index,
                        xs: 1,
                        borderTop: '0',
                        borderBottom: '0',
                        borderLeft: '0',
                        borderRight: '0',
                        readOnly: true,
                        isDelete: getAftDeleteIconStatus()
                    },
                    {
                        key: `AftVibrationAmplitude${index}`,
                        value: formData[`AftVibrationAmplitude${index}`],
                        type: 'number',
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.23rem',
                        borderRight: '0',
                        xs: 2,
                        maxLength: '10',
                        borderBottom: '0',
                        isRequired: true,
                        isSimulatedGapRequired: true
                    },
                    {
                        key: `AftVibrationFrequency${index}`,
                        value: formData[`AftVibrationFrequency${index}`],
                        type: 'number',
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.23rem',
                        xs: 1,
                        maxLength: '10',
                        borderBottom: '0',
                        isRequired: true
                    },
                    {
                        key: `AftSensorOutput${index}`,
                        value: formData[`AftSensorOutput${index}`],
                        type: 'number',
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.23rem',
                        xs: 2,
                        maxLength: '10',
                        borderBottom: '0',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: `AftSystemReading${index}`,
                        value: formData[`AftSystemReading${index}`],
                        type: 'number',
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.23rem',
                        xs: 2,
                        maxLength: '10',
                        borderBottom: '0',
                    },
                    {
                        key: `AftHmiReading${index}`,
                        value: formData[`AftHmiReading${index}`],
                        type: 'number',
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.23rem',
                        xs: 2,
                        maxLength: '10',
                        borderRight: '0',
                        borderBottom: '0',
                        borderLeft: '0',
                        isRequired: true
                    },
                    {
                        key: `AftDeviation${index}`,
                        value: AsFoundTestDeviation1Formula(formData[`AftVibrationAmplitude${index}`],
                            formData[`AftHmiReading${index}`]),
                        type: 'number',
                        readOnly: true,
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.23rem',
                        xs: 2,
                        maxLength: '10',
                        borderBottom: '0',
                        borderRight: '0',
                        inputMarginRight: '4px',
                        isDeviationRequired: true
                    }]
                });
        }
    }
    rows.push({
        key: 17,
        fields: [{
            key: 'F101AftToleranceLabel',
            value: ToleranceLabelFormula(formData),
            xs: 3,
            width: '99%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            borderRight: '0',
            borderBottom: '0',
            marginLeft: '0.26rem',
            borderLeft: '0',
            isLabelNew: true,
            marginBottom: '4px',
            marginRight: '0'
        },
        {
            key: 'F101AftTolerance',
            value: selectedTag.DateTested ? formData?.F101AftTolerance : formData?.F101AftTolerance ?? selectedTag?.ToleranceInInstrumentEu,
            xs: 1,
            width: '100%',
            labelWidth: '0',
            type: 'number',
            maxLength: '10',
            isRequired: true,
            textAlign: 'center',
            height: '1.1rem',
            borderBottom: '0',
            marginBottom: '4px'
        },
        {
            key: 'noDataAft',
            value: '',
            xs: 4,
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            borderBottom: '0',
            borderLeft: '0',
            borderRight: '0',
        },
        {
            key: 'AsFoundResultLabel',
            value: 'As-Found Result',
            xs: 2,
            textAlign: 'end',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            marginBottom: '4px',
            borderBottom: '0',
            borderLeft: '0',
            borderRight: '0',
            isLabelBold: true,
        },
        {
            key: 'F102AftResult',
            value: AsFoundResultMandatoryCheck(formData, aftTestPoint)
                ? AsFoundResultFormula(formData, aftTestPoint) : '',
            isCalculatedNew: true,
            readOnly: true,
            xs: 2,
            labelWidth: '0',
            textAlign: 'center',
            width: '100%',
            borderBottom: '0',
            height: '1.1rem',
            borderRight: '0',
            inputMarginRight: '4px'
        }]
    });
    return { rows };
};
