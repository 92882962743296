import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { Placeholder } from 'journeys/portal/Forms/Sections.constants';

export const TestResultSectionDataM69 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'DetectorReinstatement',
                label: 'Detector reinstatement',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 0'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F404JumperWireRemovedandShortCircuitEliminatedLabel',
                    label: 'Confirm that jumper wire removed and short circuit eliminated.',
                    xs: 4,
                    labelWidth: '98%',
                    padding: '0',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F404JumperWireRemovedandShortCircuitEliminated',
                    value: formData.F404JumperWireRemovedandShortCircuitEliminated,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select YES or NO',
                    options: [{ name: 'Select YES or NO', value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    width: '100%',
                    labelWidth: '0',
                    height: '2.5rem',
                    paddingPlaceholder: '10px',
                    arrowMarginTop: '10px',
                    textAlign: 'left'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F382HmistatusAfterTestLabel',
                    label: 'HMI Status after test',
                    xs: 4,
                    labelWidth: '98%',
                    padding: '0',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F382HmistatusAfterTest',
                    isDropDown: true,
                    value: formData.F382HmistatusAfterTest,
                    isRequired: true,
                    placeholder: Placeholder.HMI_STATUS_PLACEHOLDER,
                    options: [{ name: Placeholder.HMI_STATUS_PLACEHOLDER, value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'LOOP FAULT', value: 'LOOP FAULT' }],
                    inputTextAlign: 'center',
                    xs: 2,
                    marginBottom: '15px',
                    width: '100%',
                    labelWidth: '0',
                    direction: 'rtl'
                },
                {
                    key: 'F85TestResultLabel',
                    label: 'Test Result',
                    xs: 2,
                    labelWidth,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F85TestResult',
                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    direction: 'rtl',
                    options: TestResultDropdownOptions(formData),
                    marginRight: '0.5rem',
                    width: '100%',
                    labelWidth: '0%',
                    marginBottom: '15px',
                    xs: 4
                }
            ]
        }
    ]

});
