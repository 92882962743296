import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { StyledLabel } from 'elements/Label/Label';
import PropTypes from 'prop-types';
import { FormInput } from 'elements/Inputs/FormInput/';
import { selectZeroFlowConditions } from './ZeroFlowCondition.data';
import { FormDropdown } from 'elements/Inputs/FormInput/Dropdown/Dropdown';
import { StyleDropdown } from '../AsFoundTestTableSection/AsFoundTestSection.styled';

const ZeroFlowConditions = (props) => {
    const { selectedTag, formName, changeHandler, formData, isFormReadOnly, userRole } = props;
    const filterViewData = selectZeroFlowConditions(selectedTag, formName, formData);
    const styleInput = (field) => field.isLabel ? (
        <StyledLabel
            id={field.key}
            label={field.label}
            color={field.color}
            fontWeight={field.fontWeight}
            fontSize={field.fontSize}
            textAlign={field.textAlign}
            fontStyle={field.fontStyle}
            labelWidth={field.labelWidth}
            marginLeft={field.marginLeft}
            padding={field.padding}
        >
            {field.label}
        </StyledLabel>
    )
        : (
            <FormInput
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                isCalculated={field.isCalculated}
                isCalculatedNew={field.isCalculatedNew}
                isRequired={field.isRequired}
                handleChange={changeHandler}
                type={field.type}
                width={field.width}
                padding={field.padding}
                labelWidth={field.labelWidth}
                textAlign={field.textAlign}
                readOnly={field.readOnly || isFormReadOnly}
                maxLength={field.maxLength}
                borderRight={field.borderRight}
                borderLeft={field.borderLeft}
                height={field.height}
                inputMarginRight={field.inputMarginRight}
                userRole={userRole}
                isDisabled={field.isDisabled}
                fontWeight={field.fontWeight}
                marginLeft={field.marginLeft}
                borderBottom={field.borderBottom}
                direction={field.direction}
                marginRight={field.marginRight}
                isLabelBold={field.isLabelBold}
                fontSize={field.fontSize}
                inputFontSize={field.inputFontSize}
                marginBottom={field.marginBottom}
                marginTop={field.marginTop}
            />
        );
    return (
        <Grid container spacing={1}>
            {
                filterViewData.rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ padding: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isDropdown
                                    ? (
                                        <StyleDropdown
                                            formName={formName}
                                        >
                                            <FormDropdown
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...field}
                                                id={field.key}
                                                name={field.key}
                                                handleChange={changeHandler}
                                                readOnly={isFormReadOnly || field.readOnly}
                                            />
                                        </StyleDropdown>
                                    )
                                    : styleInput(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    dpsData: Forms.dpsData,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ZeroFlowConditions);

ZeroFlowConditions.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
