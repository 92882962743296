export const VisualInspectSectionDataP728 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'VISUALINSPECTIONLabel',
                label: 'VISUAL INSPECTION',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                width: '28%',
                padding: '15px 0 0 0'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F119VisualInspection',
                label: `Visually inspect instrument tagging, instrument mounting conditions, state of instrument wiring connection/terminations,
                for general instrument condition and general signs deterioration as well as for signs of process fluid weeping/leaking.`,
                value: formData.F119VisualInspection,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]

});
