import React from 'react';
import { SVGButton } from 'elements/';
import PropTypes from 'prop-types';

const BackButton = (props) => (
  <SVGButton id="backButton" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 15 15" onClick={props.onClick}>
    <g id="Group_161905" data-name="Group 161905" transform="translate(-0.383 1.365)">
      <g id="Ellipse_3180" data-name="Ellipse 3180" transform="translate(0.383 -1.365)" fill="none" stroke="white" strokeWidth="1">
        <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
        <circle cx="7.5" cy="7.5" r="7" fill="none" />
      </g>
      <path
        id="select"
        d="M0,4.055,4.071,0,7.989,4.055"
        transform="translate(4.979 10.521) rotate(-90)"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </SVGButton>
);

export default BackButton;

BackButton.propTypes = {
  onClick: PropTypes.func
};
