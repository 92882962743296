import axios from 'axios';
import { APIEndpoints } from 'helpers/APILists';
import { TEST_ACTIONS } from './TestEquipment.constants';
import { searchTEFunction, sortFunction } from '../SubSections/DPS/DPS.helpers';
import { MESSAGE_TYPE } from '../../common/common.constants';

export const setStatusSortOrder = (columnName) => (dispatch, getState) => {
    const { TestEquipment: { sortStatusAscOrder } } = getState();
    dispatch({
        type: 'DISPLAY_STATUS_SORTORDER',
        payload: {
            sortColumn: columnName,
            sortStatusAscOrder: !sortStatusAscOrder,
        }
    });
    dispatch(sortTestEquipment(columnName, !sortStatusAscOrder));
};

export const setDeviceTypeSortOrder = (columnName) => (dispatch, getState) => {
    const { TestEquipment: { sortDeviceTypeAscOrder } } = getState();
    dispatch({
        type: 'DISPLAY_DEVICETYPE_SORTORDER',
        payload: {
            sortColumn: 'DeviceType',
            sortDeviceTypeAscOrder: !sortDeviceTypeAscOrder,
        }
    });
    dispatch(sortTestEquipment('DeviceType', !sortDeviceTypeAscOrder));
};

export const getTestMakeModelData = () => async (dispatch, getState) => {
    const { Home: { selectedAssetAliasName, bpRegionNameAlias }, TestEquipment: { searchStringForTestEq } } = getState();
    const filterData = { Asset: selectedAssetAliasName, Region: bpRegionNameAlias };
    await axios.post(APIEndpoints.GetAssetTestMakeModel, filterData)
        .then((res) => {
            dispatch({
                type: TEST_ACTIONS.GET_TEST_MAKE_MODEL_DATA_SUCCESS,
                payload: {
                    testEquipmentDataAll: res.data
                }
            });
            if (!searchStringForTestEq) {
                const totalCount = res.data.length;
                dispatch({
                    type: TEST_ACTIONS.GET_TEST_RECORDS_COUNT,
                    payload: {
                        totalCount
                    }
                });
            }
        }).catch((err) => {
            dispatch({
                type: TEST_ACTIONS.GET_TEST_MAKE_MODEL_DATA_FAILURE,
                payload: {
                    tableLoading: false
                },
                meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
            });
        });
};

export const reSetTestTotalCount = () => async (dispatch) => {
    dispatch({
        type: TEST_ACTIONS.RESET_TEST_TOTAL_COUNT,
        payload: {
            totalCount: 0
        }
    });
};

export const searchTestEquipment = (searchString) => async (dispatch, getState) => {
    await dispatch(getTestMakeModelData());
    dispatch({
        type: TEST_ACTIONS.GET_SEARCH_TEST_RECORDS_PENDING,
        payload: {
            tableLoading: true,
            searchStringForTestEq: searchString
        }
    });
    const { TestEquipment: { testEquipmentDataAll }, TestEquipment: { sortColumn, sortDeviceTypeAscOrder, sortStatusAscOrder } } = getState();
    searchTEFunction(searchString, testEquipmentDataAll)
        .then(result => {
            const resultCount = result.length;
            dispatch({
                type: TEST_ACTIONS.GET_SEARCH_TEST_RECORDS_SUCCESS,
                payload: {
                    tableLoading: false,
                    testEquipmentTableData: result,
                    totalCount: resultCount
                }
            });
        }
        ).then(() => sortColumn && (sortColumn === 'DeviceType' ? dispatch(sortTestEquipment(sortColumn,
            sortDeviceTypeAscOrder)) : dispatch(sortTestEquipment(sortColumn, sortStatusAscOrder))))
        .catch(error => {
            dispatch({
                type: TEST_ACTIONS.GET_SEARCH_TEST_RECORDS_FAILURE,
                payload: {
                    tableLoading: false
                },
                meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
            });
        }
        );
};

export const sortTestEquipment = (colForSorting, sortAscOrder) => (dispatch, getState) => {
    const { TestEquipment: { testEquipmentDataAll }, TestEquipment: { searchStringForTestEq, testEquipmentTableData } } = getState();
    dispatch({
        type: TEST_ACTIONS.GET_SORT_TEST_RECORDS_PENDING,
        payload: {
            tableLoading: true
        }
    });
    if (searchStringForTestEq) {
        sortFunction(testEquipmentTableData, colForSorting, sortAscOrder)
            .then(sortedData => {
                dispatch({
                    type: TEST_ACTIONS.GET_SORT_TEST_RECORDS_SUCCESS,
                    payload: {
                        tableLoading: false,
                        testEquipmentTableData: sortedData
                    }
                });
            }
            )
            .catch(error => {
                dispatch(sortTestRecordFailure(error));
            }
            );
    } else {
        sortFunction(testEquipmentDataAll, colForSorting, sortAscOrder)
            .then(sortedData => {
                dispatch({
                    type: TEST_ACTIONS.GET_SORT_TEST_RECORDS_SUCCESS,
                    payload: {
                        tableLoading: false,
                        testEquipmentDataAll: sortedData
                    }
                });
            }
            )
            .catch(error => {
                dispatch(sortTestRecordFailure(error));
            }
            );
    }
};

const sortTestRecordFailure = (error) => (dispatch) => {
    dispatch({
        type: TEST_ACTIONS.GET_SORT_TEST_RECORDS_FAILURE,
        payload: {
            tableLoading: false,
        },
        meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
    });
};
