let deleteIcons = false;
export const getFlowAlarmDeleteIconStatus = () => deleteIcons;

export const showFlowAlarmDeleteIcons = (testPoint, previousVal) => {
    deleteIcons = previousVal !== testPoint;
    return deleteIcons;
};

export const hideFlowAlarmDeleteIcons = () => {
    deleteIcons = false;
    return deleteIcons;
};
