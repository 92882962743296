import styled from 'styled-components';
import theme from 'theme';

export const CentralContainer = styled.div`
    /* height: 100vh; */
    background-color: ${theme.colors.greyLight};
    position: relative;
    padding-bottom: 3rem;
`;

export const CentralWrapper = styled.div`
    padding: 1rem;
`;
