import { AsLeftTableF81AsLeftResultFormula, disableALTValveFullStrokeTravelTime } from './P610.formulas';
import { valvePlaceHolder } from '../../../ValveSizeSection/Data/P610/P610.data';
import { abnormalWarning, valveMovementPlaceHolder } from '../../../AsFoundTestTableSection/Data';

export const AsLeftTestTableSectionDataP610 = (selectedTag, formData) => {
    const altDisabled = (formData?.F61AsFoundResult === 'PASS' || formData.F619AsLeftTestRequired === 'NO');
    const disableALTValveFullStroke = altDisabled || disableALTValveFullStrokeTravelTime(formData);
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsLeftTest',
                    label: 'As-Left Test',
                    isLabel: true,
                    fontWeight: '700',
                    padding: '5px 0 5px 8px',
                    xs: 12,
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'attention',
                    label: `Attention: The following simple corrections may be performed by technician as part of this PM work order —
                repeat stroking valve against stiction, cleaning vent lines and ports, flow regulator adjustments, limit switch adjustments.`,
                    width: '0',
                    marginLeft: '8px',
                    color: '#ea7c34',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderTop: '0',
                    borderRight: '0',
                    labelWidth: '100%',
                    inputMarginRight: '6px',
                    fontSize: '8.5pt'
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'followingcomplicated',
                    label: `The following complicated corrections should be performed by technician as part of follow-up corrective work order —
                component replacements (e.g. solenoid, QEV), valve and actuator overhaul, lubricating and greasing valve or actuator.`,
                    width: '0',
                    marginLeft: '8px',
                    labelWidth: '100%',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderTop: '0',
                    borderRight: '0',
                    fontSize: '8.5pt',
                    inputMarginRight: '6px',
                    color: '#ea7c34',
                    marginBottom: '5px'
                }]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F350ValveFullStrokeLocalPosition2',
                        label: 'Valve Full Stroke Local Position',
                        value: !altDisabled ? formData.F350ValveFullStrokeLocalPosition2 : '',
                        isRequired: !altDisabled,
                        isDisabled: altDisabled,
                        readOnly: altDisabled,
                        isDropdown: !altDisabled,
                        placeholder: valvePlaceHolder,
                        options: [
                            { name: valvePlaceHolder, value: '' },
                            { name: 'Open', value: 'Open' },
                            { name: 'Closed', value: 'Closed' },
                            { name: 'Intermediate', value: 'Intermediate' }],
                        xs: 5,
                        width: (altDisabled) ? '39.6%' : '',
                        labelWidth: '49.6%',
                        marginLeft: '8px',
                        textAlign: 'center',
                        borderBottom: (disableALTValveFullStrokeTravelTime(formData) && !altDisabled) ? '' : '0',
                        rootMarginRight: '0px',
                        inputTextAlign: 'center',
                        height: '1.5rem'
                    },
                    {
                        key: 'NodataP610',
                        xs: 1,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'F352ValveFullStrokeHmipositionIndication2',
                        label: 'Valve Full Stroke HMI Position Indication',
                        value: !altDisabled ? formData.F352ValveFullStrokeHmipositionIndication2 : '',
                        isDisabled: altDisabled,
                        readOnly: altDisabled,
                        isDropdown: !altDisabled,
                        placeholder: valvePlaceHolder,
                        options: [
                            { name: valvePlaceHolder, value: '' },
                            { name: 'Open', value: 'Open' },
                            { name: 'Closed', value: 'Closed' },
                            { name: 'Intermediate', value: 'Intermediate' }],
                        xs: 6,
                        marginRight: '0.5rem',
                        direction: 'rtl',
                        width: altDisabled ? '32%' : '',
                        labelWidth: '65%',
                        textAlign: 'center',
                        borderRight: '0',
                        inputMarginRight: '4px',
                        rootMarginRight: '3.9px',
                        inputTextAlign: 'center'
                    }]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F354AltValveFullStrokeMovement',
                        label: 'Valve Full Stroke Movement',
                        value: !disableALTValveFullStroke ? formData.F354AltValveFullStrokeMovement : '',
                        isRequired: !disableALTValveFullStroke,
                        isDisabled: disableALTValveFullStroke,
                        readOnly: disableALTValveFullStroke,
                        isDropdown: !disableALTValveFullStroke,
                        placeholder: valveMovementPlaceHolder,
                        options: [
                            { name: valveMovementPlaceHolder, value: '' },
                            { name: 'Smooth', value: 'Smooth' },
                            { name: 'Abnormal', value: 'Abnormal' }],
                        xs: 5,
                        width: disableALTValveFullStroke ? '39.7%' : '39.6%',
                        labelWidth: '49.6%',
                        marginLeft: '8px',
                        textAlign: 'center',
                        borderBottom: '0',
                        rootMarginRight: '0px',
                        inputTextAlign: 'center',
                        height: '1.5rem'
                    },
                    {
                        key: 'abnormalWarning',
                        label: formData?.F354AltValveFullStrokeMovement === 'Abnormal'
                            ? abnormalWarning : '',
                        isLabel: true,
                        xs: 7,
                        color: '#ea7c34',
                        fontSize: '8.5pt',
                        fontWeight: '600',
                        padding: '0'
                    }]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'F351ValveFullStrokeTravelTime2',
                        label: 'Valve Full Stroke Travel Time (sec)',
                        value: !(disableALTValveFullStroke) ? formData.F351ValveFullStrokeTravelTime2 : '',
                        isRequired: !(disableALTValveFullStroke),
                        isDisabled: disableALTValveFullStroke,
                        readOnly: disableALTValveFullStroke,
                        xs: 5,
                        type: 'number',
                        maxLength: '10',
                        width: '39.7%',
                        labelWidth: '49.6%',
                        marginLeft: '8px',
                        height: '1.2rem',
                        borderBottom: '0',
                        marginBottom: '4px',
                        textAlign: 'center'
                    },
                    {
                        key: 'NodataP610',
                        xs: 2,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'F81AsLeftResult',
                        label: 'As-Left Result',
                        value: !altDisabled ? AsLeftTableF81AsLeftResultFormula(formData) : '',
                        isDisabled: altDisabled,
                        isCalculatedNew: true,
                        xs: 5,
                        readOnly: true,
                        marginRight: '0.5rem',
                        direction: 'rtl',
                        height: '1.2rem',
                        width: '40%',
                        labelWidth: '60%',
                        textAlign: 'center',
                        borderRight: '0',
                        borderBottom: '0',
                        marginBottom: '4px',
                        inputMarginRight: '4px'
                    }]
            }
        ]
    };
};
