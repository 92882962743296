import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormInput } from 'elements/Inputs/FormInput';
import { InputAutogrow } from 'elements/Inputs/FormInput/InputAutogrow/InputAutogrow';
import { selectManufacturerModelTemplateForForm } from './ManufacturerModelSection.data';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

const ManufacturerModelSection = (props) => {
    const { selectedTag, changeHandler, formData, formName, isFormReadOnly, userRole } = props;
    const classes = useStyles();
    const filterViewData = selectManufacturerModelTemplateForForm(selectedTag, formName, formData);
    return (
        <Grid container spacing={1} className={classes.root}>
            {
                filterViewData.rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ paddingTop: '0', paddingBottom: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isAutoGrow
                                    ? (
                                        <InputAutogrow
                                            id={field.key}
                                            name={field.key}
                                            value={field.value}
                                            maxLength={field.maxLength}
                                            handleChange={changeHandler}
                                            inputWidth={field.inputWidth}
                                            width={field.labelWidth}
                                            label={field.label}
                                            marginBottom={field.marginBottom}
                                            marginLeft={field.marginLeft}
                                            direction={field.direction}
                                            marginRight={field.marginRight}
                                            padding={field.padding}
                                            readOnly={isFormReadOnly}
                                        />
                                    )
                                    : (
                                        <FormInput
                                            id={field.key}
                                            name={field.key}
                                            label={field.label}
                                            value={field.value}
                                            handleChange={changeHandler}
                                            marginLeft={field.marginLeft}
                                            width={field.width}
                                            labelWidth={field.labelWidth}
                                            direction={field.direction}
                                            marginRight={field.marginRight}
                                            borderBottom={field.borderBottom}
                                            readOnly={isFormReadOnly}
                                            marginBottom={field.marginBottom}
                                            marginTop={field.marginTop}
                                            height={field.height}
                                            borderTop={field.borderTop}
                                            userRole={userRole}
                                            maxLength={field.maxLength}
                                        />
                                    )}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    dpsData: Forms.dpsData,
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManufacturerModelSection);

ManufacturerModelSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
