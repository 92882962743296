import { paddingAF } from '../../../AsLeftTestTableSection/Data/P77/P77.data';
import { AsFoundResultFormulaM71 } from './M71.formulas';

export const AsFoundTestTableSectionDataM71 = (formData) => ({
    rows: [
        {
            key: 10,
            fields: [{
                key: 'remark',
                label: `Remark: Below fields may be used for recording various process parameter values or equipment
                 performance indicators throughout the test process (e.g., water discharge pressures, valve travel times).`,
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '99%',
                padding: '15px 1px 10px 7px',
                fontSize: '8.5pt',
                color: '#ea7c34'
            }]
        },
        {
            key: 11,
            fields: [{
                key: 'TagNumber1',
                label: 'Tag Number',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                padding: '15px 0 5px 6px',
                fontSize: '9pt'
            },
            {
                key: 'Description1',
                label: 'Description',
                isLabel: true,
                xs: 3,
                fontWeight: '700',
                labelWidth: '100%',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Value1',
                label: 'Value',
                isLabel: true,
                xs: 1,
                fontWeight: '700',
                labelWidth: '100%',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'TagNumber2',
                label: 'Tag Number',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                marginTop: '15px',
                padding: '15px 0 5px 15px',
                fontSize: '9pt'
            },
            {
                key: 'Description2',
                label: 'Description',
                isLabel: true,
                xs: 3,
                fontWeight: '700',
                labelWidth: '100%',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Value2',
                label: 'Value',
                isLabel: true,
                xs: 1,
                fontWeight: '700',
                labelWidth: '100%',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            }]
        },
        {
            key: 12,
            fields: [{
                key: 'F678AftTst1TagNumber',
                value: formData.F678AftTst1TagNumber,
                xs: 2,
                inputWidth: '100%',
                labelWidth: '0',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                inputMarginLeft: '4px',
                maxLength: '30',
                isMultiLine: true
            },
            {
                key: 'F679AftTst1Desc',
                value: formData.F679AftTst1Desc,
                xs: 3,
                maxLength: '45',
                inputWidth: '100%',
                labelWidth: '0',
                borderBottom: '0',
                isMultiLine: true
            },
            {
                key: 'F680AftTst1Val',
                value: formData.F680AftTst1Val,
                xs: 1,
                maxLength: '15',
                inputWidth: '100%',
                labelWidth: '0',
                borderBottom: '0',
                borderLeft: '0',
                textAlign: 'center',
                isMultiLine: true
            },
            {
                key: 'F687AftTst2TagNumber',
                value: formData.F687AftTst2TagNumber,
                maxLength: '30',
                xs: 2,
                inputWidth: '95%',
                labelWidth: '0',
                borderRight: '0',
                borderBottom: '0',
                marginLeft: '8px',
                isMultiLine: true,
                inputMarginLeft: '15px'
            },
            {
                key: 'F688AftTst2Desc',
                value: formData.F688AftTst2Desc,
                maxLength: '45',
                xs: 3,
                inputWidth: '100%',
                labelWidth: '0',
                borderBottom: '0',
                borderRight: '0',
                isMultiLine: true
            },
            {
                key: 'F689AftTst2Val',
                value: formData.F689AftTst2Val,
                xs: 1,
                maxLength: '15',
                inputWidth: '100%',
                labelWidth: '0',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                isMultiLine: true
            }]
        },
        {
            key: 13,
            fields: [{
                key: 'F681AftTst1TagNumber',
                value: formData.F681AftTst1TagNumber,
                xs: 2,
                inputWidth: '100%',
                labelWidth: '0',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                inputMarginLeft: '4px',
                maxLength: '30',
                isMultiLine: true
            },
            {
                key: 'F682AftTst1Desc',
                value: formData.F682AftTst1Desc,
                xs: 3,
                maxLength: '45',
                inputWidth: '100%',
                labelWidth: '0',
                borderBottom: '0',
                isMultiLine: true
            },
            {
                key: 'F683AftTst1Val',
                value: formData.F683AftTst1Val,
                xs: 1,
                isMultiLine: true,
                maxLength: '15',
                inputWidth: '100%',
                labelWidth: '0',
                borderLeft: '0',
                borderBottom: '0'
            },
            {
                key: 'F690AftTst2TagNumber',
                value: formData.F690AftTst2TagNumber,
                maxLength: '30',
                xs: 2,
                isMultiLine: true,
                inputWidth: '95%',
                labelWidth: '0',
                borderRight: '0',
                borderBottom: '0',
                marginLeft: '8px',
                inputMarginLeft: '15px'
            },
            {
                key: 'F691AftTst2Desc',
                value: formData.F691AftTst2Desc,
                maxLength: '45',
                xs: 3,
                isMultiLine: true,
                inputWidth: '100%',
                labelWidth: '0',
                borderBottom: '0',
                borderRight: '0'
            },
            {
                key: 'F692AftTst2Val',
                value: formData.F692AftTst2Val,
                xs: 1,
                inputWidth: '100%',
                labelWidth: '0',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                maxLength: '15',
                isMultiLine: true
            }]
        },
        {
            key: 14,
            fields: [{
                key: 'F684AftTst1TagNumber',
                value: formData.F684AftTst1TagNumber,
                xs: 2,
                inputWidth: '100%',
                labelWidth: '0',
                maxLength: '30',
                borderRight: '0',
                isMultiLine: true,
                borderLeft: '0',
                inputMarginLeft: '4px'
            },
            {
                key: 'F685AftTst1Desc',
                value: formData.F685AftTst1Desc,
                xs: 3,
                maxLength: '45',
                inputWidth: '100%',
                labelWidth: '0',
                isMultiLine: true,
            },
            {
                key: 'F686AftTst1Val',
                value: formData.F686AftTst1Val,
                xs: 1,
                maxLength: '15',
                inputWidth: '100%',
                isMultiLine: true,
                labelWidth: '0',
                borderLeft: '0',
            },
            {
                key: 'F693AftTst2TagNumber',
                value: formData.F693AftTst2TagNumber,
                maxLength: '30',
                xs: 2,
                inputWidth: '95%',
                labelWidth: '0',
                isMultiLine: true,
                borderRight: '0',
                marginLeft: '8px',
                inputMarginLeft: '15px'
            },
            {
                key: 'F694AftTst2Desc',
                value: formData.F694AftTst2Desc,
                maxLength: '45',
                xs: 3,
                inputWidth: '100%',
                labelWidth: '0',
                isMultiLine: true,
                borderRight: '0'
            },
            {
                key: 'F695AftTst2Val',
                value: formData.F695AftTst2Val,
                xs: 1,
                inputWidth: '100%',
                labelWidth: '0',
                borderRight: '0',
                isMultiLine: true,
                inputMarginRight: '4px',
                maxLength: '15'
            }]
        },
        {
            key: 15,
            fields: [
            {
                key: 'Nodata',
                isLabel: true,
                xs: 8,
            },
            {
                key: 'As-FoundResultLabel',
                value: 'As-Found Result',
                xs: 2,
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                isLabelNew: true,
                height: '1.2rem',
                borderLeft: '0',
                borderTop: '0',
                borderBottom: '0',
                marginBottom: '4px',
                textAlign: 'right',
                borderRight: '0',
            },
            {
                key: 'F102AftResult',
                value: AsFoundResultFormulaM71(formData),
                xs: 2,
                isCalculatedNew: true,
                readOnly: true,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                borderTop: '0',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                marginBottom: '4px',
                inputMarginRight: '4px',
            },
            ]
        }
    ]
});
