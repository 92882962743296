import { ValidateField, conditionalValueSelector } from 'helpers/Validation';
import { FoundResultF81AsLeftResultFormula } from './P726.formulas';
import { paddingValue, placeholderUnit, paddingValueTR } from '../../../AsFoundTestTableSection/Data';
import { isAsLeftTestRequired } from '../../AsLeftTestTableSection.helpers';

export const AsLeftTestTableSectionDataP726 = (selectedTag, formData) => {
    const altDisabled = isAsLeftTestRequired(formData.F102AftResult, formData.F619AsLeftTestRequired);
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'AsLeftTest',
                label: 'As-Left Test',
                isLabel: true,
                fontWeight: '700',
                padding: '8px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F620AltDifferentialPressureAcrossValve',
                label: 'Differential Pressure Across Valve',
                value: conditionalValueSelector(altDisabled, '',
                    formData.F620AltDifferentialPressureAcrossValve),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                maxLength: '10',
                xs: 6,
                labelWidth: '63%',
                width: '64%',
                height: '1.26rem',
                marginLeft: '7px',
                marginRight: '0.5rem',
                textAlign: 'center',
                marginTop: '5px',
            },
            {
                key: 'F621AltUnitOfMeasurement',
                label: 'Unit of Measurement',
                value: conditionalValueSelector(altDisabled,
                    '', formData.F621AltUnitOfMeasurement),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                isDropdown: !(altDisabled),
                placeholder: placeholderUnit,
                options: [
                    { name: placeholderUnit, value: '' },
                    { name: 'bar', value: 'bar' },
                    { name: 'kPa', value: 'kPa' },
                    { name: 'psi', value: 'psi' }],
                inputTextAlign: 'center',
                xs: 6,
                labelWidth: '65%',
                width: conditionalValueSelector(altDisabled, '32.8%', '33.1%'),
                height: conditionalValueSelector(altDisabled,
                    '1.26rem', '1.6rem'),
                marginRight: conditionalValueSelector(altDisabled,
                    '0.23rem', '0.5rem'),
                direction: 'rtl',
                marginTop: '5px',
                borderRight: '0',
                textAlignLabel: conditionalValueSelector(altDisabled, 'end', ''),
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'MeasurementPoints',
                label: 'Measurement Points',
                isLabel: true,
                fontWeight: '700',
                xs: 3,
                textAlign: 'center',
                labelWidth: '100%',
                padding: '5px 0 0 0'
            },
            {
                key: 'Alt_AcousticEmission_Signal_Readings',
                label: 'Acoustic Emission Signal Readings (dB)',
                isLabel: true,
                fontWeight: '700',
                xs: 9,
                textAlign: 'center',
                fontSize: '9pt',
                labelWidth: '100%',
                padding: '5px 0 0 0'
            },
            ]
        },
        {
            key: 4,
            fields: [{
                key: 'AltSimulationPoints',
                isLabel: true,
                fontWeight: '700',
                xs: 3,
                padding: paddingValue,
                fontSize: '9pt'
            },
            {
                key: 'Alt_No1',
                label: 'No. 1',
                isLabel: true,
                fontWeight: '700',
                xs: 3,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'Alt_No2',
                label: 'No. 2',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'Alt_No3',
                label: 'No. 3',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'Alt_No4',
                label: 'No. 4',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'ValveCenter',
                xs: 3,
                label: 'Valve Center',
                fontWeight: '600',
                labelWidth: '100%',
                textAlign: 'left',
                fontSize: '9pt',
                marginLeft: '7px',
                isLabel: true,
                padding: paddingValueTR,
            },
            {
                key: 'F622AltValveCenterNo1',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F622AltValveCenterNo1)),
                isRequired: !(altDisabled),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                xs: 3,
                maxLength: '10',
            },
            {
                key: 'F623AltValveCenterNo2',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F623AltValveCenterNo2)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                xs: 2,
                maxLength: '10',
                borderLeft: '0',
            },
            {
                key: 'F624AltValveCenterNo3',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F624AltValveCenterNo3)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                borderLeft: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F625AltValveCenterNo4',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F625AltValveCenterNo4)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                xs: 2,
                textAlign: 'center'
            }]
        },
        {
            key: 6,
            fields: [{
                key: 'ValveInlet',
                xs: 3,
                label: 'Valve Inlet',
                fontWeight: '600',
                labelWidth: '100%',
                textAlign: 'left',
                fontSize: '9pt',
                marginLeft: '7px',
                isLabel: true,
                padding: paddingValueTR,
            },
            {
                key: 'F626AltValveInletNo1',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F626AltValveInletNo1)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                xs: 3,
                maxLength: '10'
            },
            {
                key: 'F627AltValveInletNo2',
                value: conditionalValueSelector(altDisabled,
                    '', ValidateField(formData.F627AltValveInletNo2)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                xs: 2,
                maxLength: '10',
                borderLeft: '0',
            },
            {
                key: 'F628AltValveInletNo3',
                value: conditionalValueSelector(altDisabled,
                    '', ValidateField(formData.F628AltValveInletNo3)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                borderLeft: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F629AltValveInletNo4',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F629AltValveInletNo4)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                xs: 2,
                textAlign: 'center'
            }]
        },
        {
            key: 7,
            fields: [{
                key: 'ValveOutlet',
                xs: 3,
                label: 'Valve Outlet',
                fontWeight: '600',
                labelWidth: '100%',
                textAlign: 'left',
                fontSize: '9pt',
                marginLeft: '7px',
                isLabel: true,
                padding: paddingValueTR,
            },
            {
                key: 'F630AltValveOutletNo1',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F630AltValveOutletNo1)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                textAlign: 'center',
                xs: 3,
                maxLength: '10'
            },
            {
                key: 'F631AltValveOutletNo2',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F631AltValveOutletNo2)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                textAlign: 'center',
                xs: 2,
                maxLength: '10',
                borderLeft: '0',
            },
            {
                key: 'F632AltValveOutletNo3',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F632AltValveOutletNo3)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F633AltValveOutletNo4',
                value: conditionalValueSelector(altDisabled, '',
                    ValidateField(formData.F633AltValveOutletNo4)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                inputMarginRight: '4px',
                xs: 2,
                textAlign: 'center'
            }]
        },
        {
            key: 8,
            fields: [{
                key: 'Alt_CalculatedValveLeakageRate',
                value: 'Calculated Valve Leakage Rate (EU)',
                xs: 3,
                width: '100%',
                readOnly: true,
                height: '1.1rem',
                labelWidth: '0',
                marginLeft: '4px',
                borderLeft: '0',
                borderBottom: '0',
                fontSize: '9pt',
                fontWeight: '600',
                isLabelNew: true,
                borderTop: '0',
                borderRight: '0',
                marginBottom: '4px',
            },
            {
                key: 'F634AltCalculatedValveLeakageRate',
                value: conditionalValueSelector(altDisabled,
                    '', ValidateField(formData.F634AltCalculatedValveLeakageRate)),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                isRequired: !(altDisabled),
                xs: 3,
                width: '100%',
                textAlign: 'center',
                labelWidth: '0',
                height: conditionalValueSelector(altDisabled, '1.22rem', '1.1rem'),
                borderBottom: '0',
                borderTop: '0',
                marginBottom: '4px',
                type: 'number',
                maxLength: '10',
            },
            {
                key: 'NoData2',
                xs: 2,
                isLabel: true
            },
            {
                key: 'AsLeftResultLabel',
                value: 'As-Left Result',
                xs: 2,
                textAlign: 'end',
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                height: '1.1rem',
                marginBottom: '4px',
                borderRight: '0',
                borderBottom: '0',
                borderLeft: '0',
                borderTop: '0',
                isLabelBold: true,
            },
            {
                key: 'F81AsLeftResult',
                value: conditionalValueSelector(altDisabled, '',
                    FoundResultF81AsLeftResultFormula(formData)),
                isCalculatedNew: !(altDisabled),
                isDisabled: altDisabled,
                readOnly: true,
                xs: 2,
                labelWidth: '0',
                textAlign: 'center',
                width: '100%',
                borderBottom: '0',
                borderTop: conditionalValueSelector(altDisabled, '', '0'),
                height: '1.1rem',
                marginBottom: '4px',
                borderRight: '0',
                borderLeft: conditionalValueSelector(altDisabled, '0', ''),
                inputMarginRight: '4px'
            }]
        }
        ]
    };
};
