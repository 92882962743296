import styled from 'styled-components';

export const StyleDropdown = styled.div`
.Dropdown-placeholder.is-selected {
    width: 100%;
    overflow: hidden;
}
.Dropdown-menu {
    text-align: center;
}`;
