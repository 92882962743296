import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const paddingValue = '18px 0 0 0';

export const ReCalibrationDataM615 = (selectedTag, formData) => {
    const selectedTagDateTested = (formDataValue, defaultValue) => selectedTag.DateTested ? formDataValue : formDataValue ?? defaultValue;
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'F30ReCalibrationToleranceLabel',
                label: 'Re-Calibration Tolerance',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingValue
            },
            {
                key: 'F30ReCalibrationTolerance',
                value: selectedTagDateTested(formData?.F30ReCalibrationTolerance, selectedTag?.TsprReCalibrationTolerance),
                xs: 2,
                type: 'number',
                maxLength: '10',
                isRequired: true,
                width: '100%',
                labelWidth: '0%',
                height: '1.2rem',
                textAlign: 'center',
                marginTop: '15px'
            },
            {
                key: 'F301ReCalibrationToleranceEULabel',
                label: 'Re-Calibration Tolerance EU',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingValue,
                labelWidth
            },
            {
                key: 'F301ReCalibrationToleranceEU',
                value: selectedTagDateTested(formData?.F301ReCalibrationToleranceEU, selectedTag?.ReCalibrationToleranceEU),
                xs: 2,
                isRequired: true,
                direction: 'rtl',
                inputWidth: '100%',
                labelWidth: '0%',
                marginRight: '0',
                textAlign: 'center',
                maxLength: '20',
                isAutoGrow: true,
                marginTop: '15px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F31FailToleranceLabel',
                label: 'Fail Tolerance',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt'
            },
            {
                key: 'F31FailTolerance',
                value: selectedTagDateTested(formData?.F31FailTolerance, selectedTag?.FailTolerance),
                xs: 2,
                type: 'number',
                maxLength: '10',
                isRequired: true,
                width: '100%',
                labelWidth: '0%',
                height: '1.25rem',
                textAlign: 'center'
            },
            {
                key: 'F311FailToleranceEULabel',
                label: 'Fail Tolerance EU',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                labelWidth
            },
            {
                key: 'F311FailToleranceEU',
                value: selectedTagDateTested(formData?.F311FailToleranceEU, selectedTag?.FailToleranceEU),
                xs: 2,
                isRequired: true,
                direction: 'rtl',
                inputWidth: '100%',
                labelWidth: '0%',
                marginRight: '0',
                textAlign: 'center',
                maxLength: '20',
                isAutoGrow: true
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F33LowSetpointlabel',
                label: 'Low Setpoint',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt'
            },
            {
                key: 'F33LowSetpoint',
                value: selectedTagDateTested(formData?.F33LowSetpoint, selectedTag?.LowSetPoint),
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0%',
                height: '1.25rem',
                textAlign: 'center'
            }]
        },
        formData?.F797LowSetpointComment ?? selectedTag?.LowSetpointComment ? {
            key: 5,
            fields: [{
                key: 'F797LowSetpointCommentLabel',
                label: 'Low Setpoint Comment',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt'
            },
            {
                key: 'F797LowSetpointComment',
                value: selectedTagDateTested(formData?.F797LowSetpointComment, selectedTag?.LowSetpointComment),
                textAlign: 'left',
                fontStyle: 'italic',
                isInfo: true,
                wordBreak: false,
                labelFontStyle: 'italic',
                width: '100%',
                labelWidth: '0%',
                xs: 2,
                labelTextAlign: 'left',
                marginTop: '5px',
                noWrap: true
            }]
        } : {
            key: 5,
            fields: []
        },
        {
            key: 4,
            fields: [{
                key: 'F241LowLowSetpointLabel',
                label: 'Low Low Setpoint',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt'
            },
            {
                key: 'F241LowLowSetpoint',
                value: selectedTagDateTested(formData?.F241LowLowSetpoint, selectedTag?.LowLowSetpoint),
                xs: 2,
                type: 'number',
                maxLength: '10',
                isRequired: true,
                width: '100%',
                labelWidth: '0%',
                height: '1.25rem',
                borderTop: formData?.F797LowSetpointComment ?? selectedTag?.LowSetpointComment ? '' : '0',
                textAlign: 'center',
            }]
        },
        formData?.F449LowLowSetpointComment ?? selectedTag?.LowLowSetpointComment ? {
            key: 5,
            fields: [{
                key: 'F449LowLowSetpointCommentLabel',
                label: 'Low Low Setpoint Comment',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt'
            },
            {
                key: 'F449LowLowSetpointComment',
                value: selectedTagDateTested(formData?.F449LowLowSetpointComment, selectedTag?.LowLowSetpointComment),
                textAlign: 'left',
                fontStyle: 'italic',
                isInfo: true,
                wordBreak: false,
                labelFontStyle: 'italic',
                width: '100%',
                labelWidth: '0%',
                xs: 2,
                labelTextAlign: 'left',
                marginTop: '5px',
                noWrap: true
            }]
        } : {
            key: 5,
            fields: []
        }
        ]
    };
};
