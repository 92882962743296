import { ExponentialToDecimal, ValidateNumberField, FieldHasValue, IsNonText } from 'helpers/Validation';

export const AFTF54DeviationEu1Formula = (formData) => FieldHasValue(formData.F48HmiReading1) && FieldHasValue(formData.F356AftAnalyzerReading)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F48HmiReading1) - ValidateNumberField(formData.F356AftAnalyzerReading))) : '';

export const AsFoundAftResultFormula = (formData) => {
    if (FieldHasValue(formData.F356AftAnalyzerReading)
        && FieldHasValue(formData.F48HmiReading1)) {
        if (IsNonText(formData.F26InstrumentUrv)
        && FieldHasValue(formData.F101AftTolerance)) {
            if (ValidateNumberField(formData.F54DeviationEu1) <= ValidateNumberField(formData.F101AftTolerance)) {
                return 'PASS';
            }
            return 'FAIL';
        }
        return '';
    }
    return '';
};
