import { FoundResultF102AftResultFormula } from '../P726/P726.formulas';
import { paddingValueTR } from '../P711/P711.data';

export const paddingValue = '0 0 0px 8px';
export const placeholderUnit = 'Select Unit';
export const AsFoundTestTableSectionDataP725 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'AsFoundTest',
            label: 'As-Found Test',
            isLabel: true,
            fontWeight: '700',
            padding: '8px 0 0 8px'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'F635AftPbuVolume',
            label: 'PBU Volume (m3)',
            value: formData.F635AftPbuVolume,
            type: 'number',
            maxLength: '10',
            xs: 6,
            labelWidth: '63%',
            width: '64%',
            height: '1.26rem',
            marginLeft: '7px',
            marginRight: '0.5rem',
            textAlign: 'center',
            marginTop: '5px',
            marginBottom: '10px'
        },
        {
            key: 'F637AftTestDuration',
            label: 'Test Duration (min)',
            value: formData.F637AftTestDuration,
            xs: 6,
            labelWidth: '48.7%',
            width: '49%',
            height: '1.26rem',
            marginRight: '0.5rem',
            inputMarginRight: '4px',
            textAlign: 'center',
            direction: 'rtl',
            marginTop: '5px',
            borderRight: '0',
            type: 'number',
            maxLength: '10',
            marginBottom: '10px'
        }]
    },
    {
        key: 3,
        fields: [{
            key: 'SimulationPoints',
            isLabel: true,
            fontWeight: '700',
            xs: 3,
            padding: paddingValue,
            fontSize: '9pt'
        },
        {
            key: 'Initial',
            label: 'Initial',
            isLabel: true,
            fontWeight: '700',
            xs: 3,
            textAlign: 'center',
            fontSize: '9pt',
            padding: paddingValue,
        },
        {
            key: 'End',
            label: 'End',
            isLabel: true,
            fontWeight: '700',
            xs: 3,
            textAlign: 'center',
            fontSize: '9pt',
            padding: paddingValue,
        },
        {
            key: 'Unit of MeasurementNo3',
            label: 'Unit of Measurement',
            isLabel: true,
            fontWeight: '700',
            xs: 3,
            textAlign: 'center',
            fontSize: '9pt',
            padding: paddingValue,
        }]
    },
    {
        key: 4,
        fields: [{
            key: 'PressureMeasurement',
            xs: 3,
            label: 'Pressure Measurement',
            fontWeight: '600',
            labelWidth: '100%',
            textAlign: 'left',
            fontSize: '9pt',
            marginLeft: '7px',
            isLabel: true,
            height: '1.2rem',
            padding: paddingValueTR,
        },
        {
            key: 'F639AftInitial1',
            value: formData.F639AftInitial1,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.3rem',
            borderBottom: '0',
            borderRight: '0',
            xs: 3,
            maxLength: '10',
        },
        {
            key: 'F643AftEnd1',
            value: formData.F643AftEnd1,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.3rem',
            borderBottom: '0',
            xs: 3,
            maxLength: '10',
        },
        {
            key: 'F605AftUnitOfMeasurement',
            value: formData.F605AftUnitOfMeasurement,
            isDropdown: true,
            placeholder: placeholderUnit,
            options: [
                { name: placeholderUnit, value: '' },
                { name: 'bar', value: 'bar' },
                { name: 'kPa', value: 'kPa' },
                { name: 'psi', value: 'psi' }],
            inputTextAlign: 'center',
            type: 'number',
            width: '99%',
            labelWidth: '0',
            height: '1.5rem',
            borderBottom: '0',
            borderRight: '0',
            borderLeft: '0',
            textAlign: 'center',
            xs: 3,
            maxLength: '10',
        }]
    },
    {
        key: 5,
        fields: [{
            key: 'TemperatureMeasurement',
            xs: 3,
            label: 'Temperature Measurement',
            fontWeight: '600',
            labelWidth: '100%',
            textAlign: 'left',
            fontSize: '9pt',
            marginLeft: '7px',
            isLabel: true,
            padding: paddingValueTR,
        },
        {
            key: 'F640AftInitial2',
            value: formData.F640AftInitial2,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.27rem',
            borderRight: '0',
            xs: 3,
            maxLength: '10'
        },
        {
            key: 'F644AftEnd2',
            value: formData.F644AftEnd2,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.27rem',
            xs: 3,
            maxLength: '10'
        },
        {
            key: 'F647AftUnitOfMeasurement1',
            value: formData.F647AftUnitOfMeasurement1,
            isDropdown: true,
            placeholder: placeholderUnit,
            options: [
                { name: placeholderUnit, value: '' },
                { name: '°C', value: '°C' },
                { name: '°F', value: '°F' }],
            inputTextAlign: 'center',
            type: 'number',
            width: '99%',
            labelWidth: '0',
            height: '1.65rem',
            borderRight: '0',
            borderLeft: '0',
            textAlign: 'center',
            xs: 3,
            maxLength: '10'
        }]
    },
    {
        key: 6,
        fields: [{
            key: 'CalculatedValveLeakageRate',
            value: 'Calculated Valve Leakage Rate (EU)',
            xs: 3,
            width: '100%',
            readOnly: true,
            height: '1.1rem',
            labelWidth: '0',
            marginLeft: '4px',
            borderLeft: '0',
            borderBottom: '0',
            fontSize: '9pt',
            fontWeight: '600',
            isLabelNew: true,
            borderTop: '0',
            borderRight: '0',
            marginBottom: '4px',
        },
        {
            key: 'F618AftCalculatedValveLeakageRate',
            value: formData.F618AftCalculatedValveLeakageRate,
            xs: 3,
            width: '100%',
            textAlign: 'center',
            labelWidth: '0',
            height: '1.1rem',
            borderBottom: '0',
            borderRight: '0',
            borderTop: '0',
            marginBottom: '4px',
            type: 'number',
            maxLength: '10',
            isRequired: true
        },
        {
            key: 'AsFoundResultLabel',
            value: 'As-Found Result',
            xs: 3,
            textAlign: 'end',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            marginBottom: '4px',
            borderBottom: '0',
            borderTop: '0',
            isLabelBold: true,
        },
        {
            key: 'F102AftResult',
            value: FoundResultF102AftResultFormula(formData),
            isCalculatedNew: true,
            readOnly: true,
            xs: 3,
            labelWidth: '0',
            textAlign: 'center',
            width: '100%',
            borderBottom: '0',
            borderTop: '0',
            borderLeft: '0',
            height: '1.1rem',
            borderRight: '0',
            marginBottom: '4px',
            inputMarginRight: '4px'
        }]
    }]
});
