import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';
import { FailedComponent } from '../../../../Sections.constants';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';
import { conditionalValueSelector } from 'helpers/Validation';
import { failedComponentsOptions } from '../P716/P716.data';

const marginTopCondition = (formData) => formData.F956TrfTypeSelection === 'Full' || formData.F956TrfTypeSelection === 'Partial';

export const ValveGreasedSectionDataS71 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F974RegulatorPressureAsLeftLabel',
                    label: 'Regulator Pressure As-Left (bar)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: conditionalValueSelector(!(marginTopCondition(formData)), '20px 0 0 0', '23px 0 0 0')
                },
                {
                    key: 'F974RegulatorPressureAsLeft',
                    value: formData.F974RegulatorPressureAsLeft,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    isRequired: true,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.1rem',
                    textAlign: 'center',
                    marginTop: conditionalValueSelector(!(marginTopCondition(formData)), '15px', '20px'),
                },
                {
                    key: 'F975ValveGreasedLabel',
                    label: 'Valve Greased?',
                    xs: 2,
                    fontWeight: '600',
                    isLabel: true,
                    padding: conditionalValueSelector(!(marginTopCondition(formData)), '20px 0 0 0', '23px 0 0 0'),
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt'
                },
                {
                    key: 'F975ValveGreased',
                    value: formData.F975ValveGreased,
                    isDropDown: true,
                    placeholder: dropdownPlaceholder,
                    options: [{ name: dropdownPlaceholder, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    xs: 4,
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                    marginTop: conditionalValueSelector(!(marginTopCondition(formData)), '15px', '20px'),
                },
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'Nodata6',
                    label: '',
                    xs: 6,
                    isLabel: true
                },
                {
                    key: 'F85TestResultLabel',
                    label: 'Test Result',
                    xs: 2,
                    isLabel: true,
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '5px 0 0 0'
                },
                {
                    key: 'F85TestResult',
                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    xs: 4,
                    labelWidth: '0',
                    width: '100%',
                    borderTop: '0',
                    borderBottom: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification') ? '0' : ''
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'Nodata7',
                    label: '',
                    xs: 6,
                    isLabel: true
                },
                {
                    key: 'FailedComponents',
                    label: 'Failed Components',
                    xs: 2,
                    fontWeight: '600',
                    isLabel: true,
                    padding: '5px 0 0 0',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                },
                {
                    key: 'F348FailedComponents',
                    label: '',
                    value: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification') ? formData.F348FailedComponents : '',
                    placeholder: FailedComponent.SEARCH_FAILED_COMPONENTS,
                    isRequired: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification'),
                    isDisabled: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    readOnly: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    isFailedDropDown: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification'),
                    options: failedComponentsOptions,
                    xs: 2,
                    fontSize: '8.4pt',
                    labelWidth: '0',
                    width: '100%',
                    borderTop: '0',
                    paddingLeft: '1px'
                },
                {
                    key: 'F349FailedComponents1',
                    value: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification') ? formData.F349FailedComponents1 : '',
                    placeholder: FailedComponent.SEARCH_FAILED_COMPONENTS,
                    isDisabled: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    readOnly: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    isFailedDropDown: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification'),
                    options: failedComponentsOptions,
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    fontSize: '8.4pt',
                    borderTop: '0',
                    borderLeft: '0',
                    paddingLeft: '1px'
                }
            ]
        }
    ]

});
