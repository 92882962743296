import { Setpoint } from 'journeys/portal/Forms/Sections.constants';
import {
    setpointNote, highHighSetpointValue, highSetpointValue, lowSetpointValue, lowLowSetpointValue, highHighSetpointComment, lowSetpointComment,
    lowLowSetpointComment, highSetPointComment, highHighSetpointCommentValue, highSetpointCommentValue, lowLowSetpointCommentValue, lowSetpointCommentValue,
    borderBottomHHDisable, borderBottomDisable, borderBottomLDisable
} from '../C11/C11.data';
import { getValueToApplyUnit, getHmiUnit, getInstrumentUnit } from '../../../AsFoundTestTableSection/Data/S72/S72.formula';
import { setpointValueApplyFormula } from './S72.formula';
import { FieldHasValue } from 'helpers/Validation';

export const labelWidth = '96%';
export const paddingLabel = '0 0 0 3px';
const paddingLabelComment = '5px 0 0 3px';
const height = '1.8rem';
const isHmiReadingRequired = (setpoint) => FieldHasValue(setpoint);

export const SetpointSectionDataS72 = (selectedTag, formName, formData) => {
    const hmiUnit = getHmiUnit(formData);
    const instrumentUnit = getInstrumentUnit(formData);
    const setPointunit = formName === 'S7-3' ? instrumentUnit : hmiUnit;
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'remark',
                    label: setpointNote,
                    isLabel: true,
                    xs: 12,
                    fontWeight: '600',
                    labelWidth: '99%',
                    padding: '5px 1px 10px 3px',
                    fontSize: '8.5pt',
                    color: '#ea7c34',
                    marginTop: '0'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F240HighHighSetpointLabel',
                        label: `High High Setpoint ${setPointunit}`,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F240HighHighSetpoint',
                        value: highHighSetpointValue(selectedTag, formData),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        height,
                        textAlign: 'center',
                        borderBottom: borderBottomHHDisable(selectedTag, formData)
                    },
                    {
                        key: 'F983ValueToApply1Label',
                        label: `${Setpoint.VALUE_TO_APPLY} ${getValueToApplyUnit(formData, formName, true)}`,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel
                    },
                    {
                        key: 'F983ValueToApply1',
                        value: setpointValueApplyFormula(formName, formData, formData.F240HighHighSetpoint),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        height,
                        textAlign: 'center',
                        isCalculatedNew: true,
                        readOnly: true,
                        borderBottom: borderBottomHHDisable(selectedTag, formData)
                    },
                    {
                        key: 'F51HmiReading1Label',
                        label: `${Setpoint.HMI_READING} ${setPointunit}`,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel
                    },
                    {
                        key: 'F51HmiReading1',
                        value: formData.F51HmiReading1,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        height,
                        textAlign: 'center',
                        borderRight: '0',
                        isRequired: isHmiReadingRequired(formData.F983ValueToApply1),
                        borderBottom: borderBottomHHDisable(selectedTag, formData)
                    }]
            },
            highHighSetpointComment(selectedTag, formData) ? {
                key: 3,
                fields: [
                    {
                        key: 'F448HighHighSetpointCommentLabel',
                        label: 'High High Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        fontStyle: 'italic',
                        padding: paddingLabelComment,
                        isLabel: 'true',
                        labelWidth,
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F448HighHighSetpointComment',
                        value: highHighSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.4rem',
                        padding: paddingLabelComment,
                        labelWidth: '0%',
                        noWrap: true
                    }]
            } : {
                key: 3,
                fields: []
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F32HighSetpointLabel',
                        label: `High Setpoint ${setPointunit}`,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F32HighSetpoint',
                        value: highSetpointValue(selectedTag, formData),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        height,
                        textAlign: 'center',
                        borderBottom: borderBottomDisable(selectedTag, formData)
                    },
                    {
                        key: 'F984ValueToApply2Label',
                        label: `${Setpoint.VALUE_TO_APPLY} ${getValueToApplyUnit(formData, formName, true)}`,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel
                    },
                    {
                        key: 'F984ValueToApply2',
                        value: setpointValueApplyFormula(formName, formData, formData.F32HighSetpoint),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        height,
                        textAlign: 'center',
                        isCalculatedNew: true,
                        readOnly: true,
                        borderBottom: borderBottomDisable(selectedTag, formData)
                    },
                    {
                        key: 'F53HmiReading3Label',
                        label: `${Setpoint.HMI_READING} ${setPointunit}`,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel
                    },
                    {
                        key: 'F53HmiReading3',
                        value: formData.F53HmiReading3,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        height,
                        textAlign: 'center',
                        borderRight: '0',
                        isRequired: isHmiReadingRequired(formData.F984ValueToApply2),
                        borderBottom: borderBottomDisable(selectedTag, formData)
                    }]
            },
            highSetPointComment(selectedTag, formData) ? {
                key: 5,
                fields: [
                    {
                        key: 'F796HighSetpointCommentLabel',
                        label: 'High Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        fontStyle: 'italic',
                        padding: paddingLabelComment,
                        isLabel: 'true',
                        labelWidth,
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F796HighSetpointComment',
                        value: highSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.4rem',
                        padding: paddingLabelComment,
                        labelWidth: '0%',
                        noWrap: true
                    }]
            } : {
                key: 5,
                fields: []
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'F33LowSetpointLabel',
                        label: `Low Setpoint ${setPointunit}`,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F33LowSetpoint',
                        value: lowSetpointValue(selectedTag, formData),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        height,
                        textAlign: 'center',
                        borderBottom: borderBottomLDisable(selectedTag, formData)
                    },
                    {
                        key: 'F985ValueToApply3Label',
                        label: `${Setpoint.VALUE_TO_APPLY} ${getValueToApplyUnit(formData, formName, true)}`,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel
                    },
                    {
                        key: 'F985ValueToApply3',
                        value: setpointValueApplyFormula(formName, formData, formData.F33LowSetpoint),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        height,
                        textAlign: 'center',
                        isCalculatedNew: true,
                        readOnly: true,
                        borderBottom: borderBottomLDisable(selectedTag, formData)
                    },
                    {
                        key: 'F54HmiReading4Label',
                        label: `${Setpoint.HMI_READING} ${setPointunit}`,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel
                    },
                    {
                        key: 'F54HmiReading4',
                        value: formData.F54HmiReading4,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        height,
                        textAlign: 'center',
                        borderRight: '0',
                        isRequired: isHmiReadingRequired(formData.F985ValueToApply3),
                        borderBottom: borderBottomLDisable(selectedTag, formData)
                    }]
            },
            lowSetpointComment(selectedTag, formData) ? {
                key: 7,
                fields: [
                    {
                        key: 'F797LowSetpointCommentLabel',
                        label: 'Low Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        fontStyle: 'italic',
                        padding: paddingLabelComment,
                        isLabel: 'true',
                        labelWidth,
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F797LowSetpointComment',
                        value: lowSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.4rem',
                        padding: paddingLabelComment,
                        labelWidth: '0%',
                        noWrap: true
                    }]
            } : {
                key: 7,
                fields: []
            },
            {
                key: 8,
                fields: [
                    {
                        key: 'F241LowLowSetpointLabel',
                        label: `Low Low Setpoint ${setPointunit}`,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F241LowLowSetpoint',
                        value: lowLowSetpointValue(selectedTag, formData),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        height,
                        textAlign: 'center',
                        borderTop: !lowSetpointComment(selectedTag, formData),
                        borderBottom: lowLowSetpointComment(selectedTag, formData) ? '' : 0,
                        marginBottom: lowLowSetpointComment(selectedTag, formData) ? '' : '4px',
                    },
                    {
                        key: 'F986ValueToApply4Label',
                        label: `${Setpoint.VALUE_TO_APPLY} ${getValueToApplyUnit(formData, formName, true)}`,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel
                    },
                    {
                        key: 'F986ValueToApply4',
                        value: setpointValueApplyFormula(formName, formData, formData.F241LowLowSetpoint),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        height,
                        textAlign: 'center',
                        isCalculatedNew: true,
                        readOnly: true,
                        borderTop: !lowSetpointComment(selectedTag, formData),
                        borderBottom: lowLowSetpointComment(selectedTag, formData) ? '' : 0,
                        marginBottom: lowLowSetpointComment(selectedTag, formData) ? '' : '4px',
                    },
                    {
                        key: 'F52HmiReading2Label',
                        label: `${Setpoint.HMI_READING} ${setPointunit}`,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel
                    },
                    {
                        key: 'F52HmiReading2',
                        value: formData.F52HmiReading2,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        height,
                        textAlign: 'center',
                        borderRight: '0',
                        isRequired: isHmiReadingRequired(formData.F986ValueToApply4),
                        borderTop: !lowSetpointComment(selectedTag, formData),
                        borderBottom: lowLowSetpointComment(selectedTag, formData) ? '' : 0,
                        marginBottom: lowLowSetpointComment(selectedTag, formData) ? '' : '4px',
                    }]
            },
            lowLowSetpointComment(selectedTag, formData) ? {
                key: 9,
                fields: [
                    {
                        key: 'F449LowLowSetpointCommentLabel',
                        label: 'Low Low Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        fontStyle: 'italic',
                        padding: '5px 0 3px 3px',
                        isLabel: 'true',
                        labelWidth,
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F449LowLowSetpointComment',
                        value: lowLowSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.4rem',
                        padding: '5px 0 3px 3px',
                        labelWidth: '0%',
                        noWrap: true
                    }]
            } : {
                key: 9,
                fields: []
            }
        ]
    };
};
