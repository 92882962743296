import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { conditionalValueSelector, ValidateField } from 'helpers/Validation';
import { AsFoundTestTableP716Formula } from '../P716/P716.formulas';

export const AsFoundTestTableSectionDataS71 = (formData) => {
    const isAftSolenoidSectionDisabled = !formData.F494Pstsetting
        || (formData.F494Pstsetting === 'Smart Positioner');
    const isAftSmartSectionDisabled = !formData.F494Pstsetting
        || (formData.F494Pstsetting === 'Solenoid Valve');
    return {
        rows: [
            {
                key: 1,
                fields: [
                    {
                        key: 'AsFoundTestLabel',
                        label: 'As-Found Test',
                        isLabel: true,
                        xs: 12,
                        fontWeight: '700',
                        padding: '5px 0 0 8px'
                    }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'NodataS71',
                        xs: 4,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'TestviaSolenoidValve',
                        label: 'Test via Solenoid Valve',
                        isLabel: true,
                        xs: 2,
                        fontWeight: '700',
                        padding: paddingLabel,
                        textAlign: 'center',
                    },
                    {
                        key: 'NodataS71',
                        xs: 3,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'TestviaSmartPositioner',
                        label: 'Test via Smart Positioner',
                        isLabel: true,
                        xs: 3,
                        fontWeight: '700',
                        padding: paddingLabel,
                        textAlign: 'right',
                        labelWidth: '97%'
                    }]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F744AftValveMeasuredPartialTravelAmountLabel',
                        label: 'Measured Partial Travel Amount (%)',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: '5px 0 0 8px',
                    },
                    {
                        key: 'F744AftValveMeasuredPartialTravelAmount',
                        value: conditionalValueSelector(isAftSolenoidSectionDisabled, '', ValidateField(formData.F744AftValveMeasuredPartialTravelAmount)),
                        isRequired: !isAftSolenoidSectionDisabled,
                        isDisabled: isAftSolenoidSectionDisabled,
                        readOnly: isAftSolenoidSectionDisabled,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        width: '100%',
                        labelWidth: '0%',
                        height: '1.2rem',
                        textAlign: 'center'
                    },
                    {
                        key: 'F748AftSmartMeasuredPartialTravelAmountLabel',
                        label: 'Measured Partial Travel Amount (%)',
                        xs: 4,
                        isLabel: true,
                        labelWidth: '98%',
                        textAlign: 'right',
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F748AftSmartMeasuredPartialTravelAmount',
                        xs: 2,
                        value: conditionalValueSelector(isAftSmartSectionDisabled, '', ValidateField(formData.F748AftSmartMeasuredPartialTravelAmount)),
                        isRequired: !isAftSmartSectionDisabled,
                        isDisabled: isAftSmartSectionDisabled,
                        readOnly: isAftSmartSectionDisabled,
                        type: 'number',
                        maxLength: '10',
                        width: '100%',
                        labelWidth: '0%',
                        height: '1.2rem',
                        textAlign: 'center',
                        inputMarginRight: '4px',
                        borderRight: '0'
                    }
                ]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F745AftValveTravelTimeToTargetPositionLabel',
                        label: 'Travel Time to Target Position (sec)',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: '4px 0 0 8px',
                    },
                    {
                        key: 'F745AftValveTravelTimeToTargetPosition',
                        value: conditionalValueSelector(isAftSolenoidSectionDisabled, '', ValidateField(formData.F745AftValveTravelTimeToTargetPosition)),
                        isDisabled: isAftSolenoidSectionDisabled,
                        readOnly: isAftSolenoidSectionDisabled,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        width: '100%',
                        labelWidth: '0%',
                        height: '1.2rem',
                        textAlign: 'center',
                        borderTop: '0',
                    },
                    {
                        key: 'F749AftSmartTravelTimeToTargetPositionLabel',
                        label: 'Travel Time to Target Position (sec)',
                        xs: 4,
                        isLabel: true,
                        labelWidth: '98%',
                        textAlign: 'right',
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F749AftSmartTravelTimeToTargetPosition',
                        value: conditionalValueSelector(isAftSmartSectionDisabled, '', ValidateField(formData.F749AftSmartTravelTimeToTargetPosition)),
                        isDisabled: isAftSmartSectionDisabled,
                        readOnly: isAftSmartSectionDisabled,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        width: '100%',
                        labelWidth: '0%',
                        height: '1.2rem',
                        textAlign: 'center',
                        borderTop: '0',
                        inputMarginRight: '4px',
                        borderRight: '0',
                    }
                ]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F746AftValvePartialStrokeTestResultLabel',
                        label: 'Partial Stroke Test Result',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: '4px 0 0 8px',
                    },
                    {
                        key: 'F746AftValvePartialStrokeTestResult',
                        value: conditionalValueSelector(isAftSolenoidSectionDisabled, '', ValidateField(formData.F746AftValvePartialStrokeTestResult)),
                        isDisabled: isAftSolenoidSectionDisabled,
                        isRequired: !isAftSolenoidSectionDisabled,
                        readOnly: isAftSolenoidSectionDisabled,
                        isDropdown: !isAftSolenoidSectionDisabled,
                        placeholder: Placeholder.SELECT_TEST_RESULT,
                        options: [{ name: Placeholder.SELECT_TEST_RESULT, value: '' },
                        { name: 'Successful', value: 'Successful' },
                        { name: 'Abnormal', value: 'Abnormal' },
                        { name: 'Aborted', value: 'Aborted' }],
                        xs: 2,
                        borderTop: '0',
                        inputTextAlign: 'center',
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        height: conditionalValueSelector((isAftSolenoidSectionDisabled), '1.24rem', '1.5rem')
                    },
                    {
                        key: 'F750AftSmartPartialStrokeTestResultLabel',
                        label: 'Partial Stroke Test Result',
                        xs: 4,
                        isLabel: true,
                        labelWidth,
                        textAlign: 'right',
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F750AftSmartPartialStrokeTestResult',
                        value: conditionalValueSelector(isAftSmartSectionDisabled, '', ValidateField(formData.F750AftSmartPartialStrokeTestResult)),
                        isDisabled: isAftSmartSectionDisabled,
                        isRequired: !isAftSmartSectionDisabled,
                        readOnly: isAftSmartSectionDisabled,
                        isDropdown: !isAftSmartSectionDisabled,
                        placeholder: Placeholder.SELECT_TEST_RESULT,
                        options: [{ name: Placeholder.SELECT_TEST_RESULT, value: '' },
                        { name: 'Successful', value: 'Successful' },
                        { name: 'Abnormal', value: 'Abnormal' },
                        { name: 'Aborted', value: 'Aborted' }],
                        xs: 2,
                        borderTop: '0',
                        inputTextAlign: 'center',
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        borderRight: '0',
                        borderBottom: '0',
                        rootMarginRight: '4px',
                        inputMarginRight: '4px',
                        height: '1.5rem'
                    }
                ]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 6,
                    },
                    {
                        key: 'F102AftResultLabel',
                        label: 'As-Found Result',
                        xs: 4,
                        isLabel: true,
                        labelWidth,
                        textAlign: 'right',
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F102AftResult',
                        value: AsFoundTestTableP716Formula(formData),
                        isCalculatedNew: true,
                        readOnly: true,
                        xs: 2,
                        width: '100%',
                        labelWidth: '0%',
                        height: '1.2rem',
                        textAlign: 'center',
                        inputMarginRight: '4px',
                        marginBottom: '4px',
                        borderRight: '0',
                        borderBottom: '0'
                    }
                ]
            }
        ]
    };
};
