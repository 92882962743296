import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const FoundResultAsFoundResultFormula = (formData) => {
    if (FieldHasValue(formData.F215AftDeviation1)
        && FieldHasValue(formData.F216AftDeviation2)
        && FieldHasValue(formData.F217AftDeviation3)
        && FieldHasValue(formData.F101AftTolerance)
        ) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F215AftDeviation1), ValidateNumberField(formData.F216AftDeviation2),
        ValidateNumberField(formData.F217AftDeviation3)]) <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
