import { ValidateField } from 'helpers/Validation';
import { AsFoundTestTableDeviationFormula, FoundResultF102AftResultFormula } from './P8111.formula';

export const AsFoundTestTableSectionDataP8111 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'AsFoundTest',
            label: 'As-Found Test',
            isLabel: true,
            fontWeight: '700',
            padding: '8px 0 0 8px'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'ReferenceTestPoint',
            label: 'Reference Test Point (EU)',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        },
        {
            key: 'Analyzer Reading',
            label: 'Analyzer Reading (EU)',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        },
        {
            key: 'HMI Reading',
            label: 'HMI Reading (HMI EU)',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        },
        {
            key: 'mAReading',
            label: 'mA Reading',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        },
        {
            key: 'PressureValue',
            label: 'Pressure Value',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        },
        {
            key: 'Deviation',
            label: 'Deviation (EU)',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        }]
    },
    {
        key: 3,
        fields: [{
            key: 'F653AftReferenceTestPoint',
            xs: 2,
            value: ValidateField(formData.F653AftReferenceTestPoint),
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            isRequired: true,
            type: 'number',
            maxLength: '10',
            textAlign: 'center',
            marginLeft: '4px'
        },
        {
            key: 'F356AftAnalyzerReading',
            value: ValidateField(formData.F356AftAnalyzerReading),
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            xs: 2,
            maxLength: '10',
        },
        {
            key: 'F106AftHmireading1',
            value: ValidateField(formData.F106AftHmireading1),
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F51MAReading1',
            value: ValidateField(formData.F51MAReading1),
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            textAlign: 'center',
            xs: 2,
            type: 'number',
            maxLength: '10'
        },
        {
            key: 'F655AftPressureValue',
            value: ValidateField(formData.F655AftPressureValue),
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '30'
        },
        {
            key: 'F215AftDeviation1',
            value: AsFoundTestTableDeviationFormula(formData),
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            inputMarginRight: '4px',
            readOnly: true,
            xs: 2,
            textAlign: 'center'
        }]
    },
    {
        key: 4,
        fields: [{
            key: 'ToleranceEULabel',
            value: 'Tolerance (EU)',
            xs: 2,
            width: '100%',
            readOnly: true,
            height: '1.1rem',
            labelWidth: '0',
            marginLeft: '4px',
            borderLeft: '0',
            borderBottom: '0',
            fontSize: '9pt',
            fontWeight: '600',
            isLabelNew: true,
            borderTop: '0',
            borderRight: '0',
            marginBottom: '4px',
        },
        {
            key: 'F101AftTolerance',
            value: selectedTag.DateTested ? formData?.F101AftTolerance : formData?.F101AftTolerance ?? selectedTag?.ToleranceInInstrumentEu,
            isRequired: true,
            xs: 2,
            width: '100%',
            textAlign: 'center',
            labelWidth: '0',
            height: '1.1rem',
            borderBottom: '0',
            borderTop: '0',
            marginBottom: '4px',
            type: 'number',
            maxLength: '10',
        },
        {
            key: 'NoData1',
            xs: 4,
            isLabel: true
        },
        {
            key: 'AsFoundResultLabel',
            value: 'As-Found Result',
            xs: 2,
            textAlign: 'end',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            marginBottom: '4px',
            borderRight: '0',
            borderLeft: '0',
            borderBottom: '0',
            borderTop: '0',
            isLabelBold: true,
        },
        {
            key: 'F102AftResult',
            value: FoundResultF102AftResultFormula(formData),
            isCalculatedNew: true,
            readOnly: true,
            xs: 2,
            labelWidth: '0',
            textAlign: 'center',
            width: '100%',
            borderBottom: '0',
            borderTop: '0',
            height: '1.1rem',
            marginBottom: '4px',
            borderRight: '0',
            inputMarginRight: '4px'
        }
        ]
    }
    ]
});
