import { ValidateField } from 'helpers/Validation';
import {
    AsFoundTestTableF217AftDeviation3Formula,
    AsFoundTestTableF216AftDeviation2Formula,
    AsFoundTestTableF215AftDeviation1Formula,
    FoundResultF102AftResultFormula,
    FoundResultF62SensorTrimNeedFormula
} from './P728.formulas';
import {
    AFTCalibration2Points1Formula,
    AFTCalibration2Points2Formula,
    AFTCalibration2Points3Formula
} from '../P710/P710.formulas';

export const AsFoundTestTableSectionDataP728 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'AsFoundTest',
            label: 'As-Found Test',
            isLabel: true,
            fontWeight: '700',
            padding: '8px 0 0 8px'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'CalibrationPoints',
            label: 'Calibration Points',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            padding: '5px 0 5px 8px',
            fontSize: '9pt'
        },
        {
            key: 'Local/TransmitterTXReading',
            label: 'Local/Transmitter (TX) Reading',
            isLabel: true,
            fontWeight: '700',
            xs: 3,
            textAlign: 'center',
            fontSize: '9pt'
        },
        {
            key: 'HMIReading',
            label: 'HMI Reading',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        },
        {
            key: 'MeasuredmAReading',
            label: 'Measured mA Reading',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt'
        },
        {
            key: 'Calculated Deviation',
            label: 'Calculated Deviation',
            isLabel: true,
            fontWeight: '700',
            xs: 3,
            textAlign: 'center',
            fontSize: '9pt'
        }]
    },
    {
        key: 3,
        fields: [{
            key: 'F121AftCalibrationPoint1',
            xs: 2,
            value: AFTCalibration2Points1Formula(formData),
            width: '100%',
            labelWidth: '0',
            readOnly: true,
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            borderBottom: '0',
            marginLeft: '4px'
        },
        {
            key: 'F481AftLocalTransmitterTxreading1',
            value: ValidateField(formData.F481AftLocalTransmitterTxreading1),
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            xs: 3,
            maxLength: '10',
        },
        {
            key: 'F106AftHmireading1',
            value: ValidateField(formData.F106AftHmireading1),
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderRight: '0',
            borderBottom: '0',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F253AftMeasuredReading1',
            value: ValidateField(formData.F253AftMeasuredReading1),
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F215AftDeviation1',
            value: AsFoundTestTableF215AftDeviation1Formula(formData),
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            inputMarginRight: '4px',
            readOnly: true,
            xs: 3,
            textAlign: 'center'
        }]
    },
    {
        key: 4,
        fields: [{
            key: 'F122AftCalibrationPoint2',
            xs: 2,
            value: AFTCalibration2Points2Formula(formData),
            width: '100%',
            labelWidth: '0',
            readOnly: true,
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            borderBottom: '0',
            marginLeft: '4px'
        },
        {
            key: 'F482AftLocalTransmitterTxreading2',
            value: ValidateField(formData.F482AftLocalTransmitterTxreading2),
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            xs: 3,
            maxLength: '10'
        },
        {
            key: 'F107AftHmireading2',
            value: ValidateField(formData.F107AftHmireading2),
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderRight: '0',
            borderBottom: '0',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F254AftMeasuredReading2',
            value: ValidateField(formData.F254AftMeasuredReading2),
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F216AftDeviation2',
            value: AsFoundTestTableF216AftDeviation2Formula(formData),
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            inputMarginRight: '4px',
            readOnly: true,
            xs: 3,
            textAlign: 'center'
        }]
    },
    {
        key: 5,
        fields: [{
            key: 'F123AftCalibrationPoint3',
            xs: 2,
            value: AFTCalibration2Points3Formula(formData),
            width: '100%',
            labelWidth: '0',
            readOnly: true,
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            marginLeft: '4px'
        },
        {
            key: 'F483AftLocalTransmitterTxreading3',
            value: ValidateField(formData.F483AftLocalTransmitterTxreading3),
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            textAlign: 'center',
            borderRight: '0',
            xs: 3,
            maxLength: '10'
        },
        {
            key: 'F108AftHmireading3',
            value: ValidateField(formData.F108AftHmireading3),
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F255AftMeasuredReading3',
            value: ValidateField(formData.F255AftMeasuredReading3),
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F217AftDeviation3',
            value: AsFoundTestTableF217AftDeviation3Formula(formData),
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            inputMarginRight: '4px',
            readOnly: true,
            xs: 3,
            textAlign: 'center'
        }]
    },
    {
        key: 7,
        fields: [{
            key: 'ToleranceEULabel',
            value: 'Tolerance (EU)',
            xs: 2,
            width: '100%',
            readOnly: true,
            height: '1.1rem',
            labelWidth: '0',
            marginLeft: '4px',
            borderLeft: '0',
            borderBottom: '0',
            fontSize: '9pt',
            fontWeight: '600',
            isLabelNew: true,
            borderTop: '0',
            borderRight: '0',
            marginBottom: '8px',
        },
        {
            key: 'F101AftTolerance',
            value: selectedTag.DateTested ? formData?.F101AftTolerance : formData?.F101AftTolerance ?? selectedTag?.ToleranceInInstrumentEu,
            isRequired: true,
            xs: 1,
            width: '100%',
            textAlign: 'center',
            labelWidth: '0',
            height: '1.1rem',
            borderBottom: '0',
            borderRight: '0',
            borderTop: '0',
            marginBottom: '8px',
            type: 'number',
            maxLength: '10',
        },
        {
            key: 'AsFoundResultLabel',
            value: 'As-Found Result',
            xs: 2,
            textAlign: 'end',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            marginBottom: '8px',
            borderRight: '0',
            borderBottom: '0',
            borderTop: '0',
            isLabelBold: true,
        },
        {
            key: 'F102AftResult',
            value: FoundResultF102AftResultFormula(formData),
            isCalculatedNew: true,
            readOnly: true,
            xs: 2,
            labelWidth: '0',
            textAlign: 'center',
            width: '100%',
            borderBottom: '0',
            borderTop: '0',
            borderRight: '0',
            height: '1.1rem',
            marginBottom: '8px',
        },
        {
            key: 'SensorTrimNeedLabel',
            value: 'Sensor Trim Needed?',
            xs: 2,
            textAlign: 'end',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            marginBottom: '8px',
            borderRight: '0',
            borderBottom: '0',
            borderTop: '0',
            isLabelBold: true,
        },
        {
            key: 'F62SensorTrimNeed',
            value: FoundResultF62SensorTrimNeedFormula(formData),
            readOnly: true,
            isCalculatedNew: true,
            xs: 3,
            labelWidth: '0%',
            width: '100%',
            textAlign: 'center',
            borderRight: '0',
            borderBottom: '0',
            borderTop: '0',
            inputMarginRight: '4px',
            height: '1.1rem',
            marginBottom: '8px',
        },
        ]
    }
    ]
});
