import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { Button, CircularProgress } from '@material-ui/core';
import informationStore from 'utils/informationDB';
import { get } from 'idb-keyval';
import { InformationPageWrapper, PDFSelector, PDFItem, PDFViewer, PageViewer, PDFButton } from './InformationPage.styled';
import { getAllInfoDocuments, setCurrentView } from '../../journeys/portal/Home/Home.actions';

const InformationPage = (props) => {
    const { isMRATOnline, getInfoDocuments } = props;
    const [allPDFs, setAllPDFs] = useState([]);
    const [pdf, setPdf] = useState();
    const [pages, setPages] = useState(null);
    const [scale, setScale] = useState(0.9);
    const [displayZoomButton, setDisplayZoomButton] = useState(true);
    function onDocumentLoadSuccess({ numPages }) {
        setPages(numPages);
    }
    useEffect(() => {
        const fetchDocs = async () => {
            isMRATOnline && await getInfoDocuments();
            const data = await get('InformationPdf', informationStore);
            setAllPDFs(data);
        };
        fetchDocs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const setCurrentPDF = async (currPDFData, currIndex) => {
        const pdfData = `data:application/pdf;base64,${currPDFData.Pdffile}`;
        setPdf(pdfData);
        setScale(0.9);
    };
    return (
        <>
            {allPDFs.length > 0
                ? (
                    <>
                        <PDFSelector>
                            {allPDFs.map((singlePdfData, index) => (
                                <div key={`pdf-${singlePdfData.Pdfname}`}>
                                    <PDFItem key={`pdf-${singlePdfData.Pdfname}`} id={`pdf-${index}`} onClick={() => setCurrentPDF(singlePdfData)}>{singlePdfData.Pdfname}</PDFItem>
                                    <Tooltip title="View item details">
                                        <IconButton aria-label="arrow" onClick={() => setCurrentPDF(singlePdfData)}>
                                            <KeyboardArrowRightIcon style={{ color: '#498205' }} id={`arrow-${index}`} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ))}
                        </PDFSelector>
                        <InformationPageWrapper>
                            {pdf && (
                                <PDFViewer>
                                    {displayZoomButton && (
                                        <PDFButton>
                                            <Button onClick={() => { setScale(prevState => prevState + 0.1); }}><ZoomInIcon /></Button>
                                            {scale > 0.9 && <Button onClick={() => { setScale(prevState => prevState > 0.9 ? prevState - 0.1 : 0.9); }}><ZoomOutIcon /></Button>}
                                        </PDFButton>
                                    )}
                                    <Document
                                        file={pdf}
                                        onLoadSuccess={(numPages) => {
                                            onDocumentLoadSuccess(numPages);
                                            setDisplayZoomButton(true);
                                        }}
                                        error="Invalid or corrupted PDF file"
                                        onLoadError={() => setDisplayZoomButton(false)}
                                    >
                                        {[...Array(pages)].map((x, i) => i + 1).map((page, i) => (
                                            <PageViewer key={page}>
                                                <Page scale={scale} className="page" pageNumber={page} />
                                                <br />
                                            </PageViewer>
                                        ))}
                                    </Document>
                                </PDFViewer>
                            )}
                        </InformationPageWrapper>
                    </>
                ) : <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />}
        </>
    );
};

const mapStateToProps = ({ AppData, Home }) => ({
    selectedBarrierType: Home.selectedBarrierType,
    userRole: Home.userRole,
    isMRATOnline: AppData.isMRATOnline
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    setCurrentAppView: (view) => dispatch(setCurrentView(view)),
    getInfoDocuments: () => dispatch(getAllInfoDocuments()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InformationPage);

InformationPage.propTypes = {
    getInfoDocuments: PropTypes.func,
    isMRATOnline: PropTypes.bool
};
