import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormInput } from 'elements/Inputs/FormInput/';
import { FormInputViewOnly } from 'elements/Inputs/FormInput/FormInputViewOnly';
import { StyledLabel } from 'elements/Label/Label';
import { FormDropdown } from 'elements/Inputs/FormInput/Dropdown/Dropdown';
import { StyleDropdown } from '../AsFoundTestTableSection/AsFoundTestSection.styled';
import { selectAsLeftFireTestSectionData } from './AsLeftFireTestSection.data';
import { StyleInput } from '../AFT_FireTestSection/AsFoundFireTestSection.styled';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

const AsLeftFireTestSection = (props) => {
    const { selectedTag, changeHandler, formData, formName, isFormReadOnly, userRole } = props;
    const classes = useStyles();
    const formDropdownInput = (field) => field.isDropdown ? (
        <StyleDropdown
            formName={formName}
        >
            <FormDropdown
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                placeholder={field.placeholder}
                handleChange={changeHandler}
                options={field.options}
                isRequired={field.isRequired}
                width={field.width}
                inputTextAlign={field.inputTextAlign}
                labelWidth={field.labelWidth}
                direction={field.direction}
                readOnly={field.readOnly || isFormReadOnly}
                isDisabled={field.isDisabled}
                marginBottom={field.marginBottom}
                marginLeft={field.marginLeft}
                marginRight={field.marginRight}
                borderBottom={field.borderBottom}
                height={field.height}
                marginTop={field.marginTop}
                borderTop={field.borderTop}
                paddingPlaceholder={field.paddingPlaceholder}
                arrowMarginTop={field.arrowMarginTop}
                fontSize={field.fontSize}
                borderRight={field.borderRight}
                rootMarginRight={field.rootMarginRight}
                noDefaultOption={field.noDefaultOption}
                inputMarginRight={field.inputMarginRight}
                borderLeft={field.borderLeft}
            />
        </StyleDropdown>
    )
        : (
            <StyleInput
                formName={formName}
                isDisabled={field.isDisabled}
            >
                <FormInput
                    id={field.key}
                    name={field.key}
                    label={field.label}
                    value={field.value}
                    isCalculated={field.isCalculated}
                    isCalculatedNew={field.isCalculatedNew}
                    direction={field.direction}
                    isRequired={field.isRequired}
                    handleChange={changeHandler}
                    type={field.type}
                    width={field.width}
                    padding={field.padding}
                    labelWidth={field.labelWidth}
                    marginLeft={field.marginLeft}
                    textAlign={field.textAlign}
                    readOnly={field.readOnly || isFormReadOnly}
                    maxLength={field.maxLength}
                    marginRight={field.marginRight}
                    borderRight={field.borderRight}
                    height={field.height}
                    marginBottom={field.marginBottom}
                    borderBottom={field.borderBottom}
                    borderTop={field.borderTop}
                    borderLeft={field.borderLeft}
                    inputMarginRight={field.inputMarginRight}
                    marginTop={field.marginTop}
                    userRole={userRole}
                    isLabelNew={field.isLabelNew}
                    isLabelBold={field.isLabelBold}
                    fontWeight={field.fontWeight}
                    isDisabled={field.isDisabled}
                    hasDPSError={field.hasDPSError}
                    textAlignLabel={field.textAlignLabel}
                />
            </StyleInput>
        );
    const formInputView = (field) => field.isInfo ? (
        <FormInputViewOnly
            id={field.key}
            name={field.key}
            label={field.label}
            value={field.value}
            fontStyle={field.fontStyle}
            labelFontStyle={field.labelFontStyle}
            width={field.width}
            height={field.height}
            wordBreak={field.wordBreak}
            labelWidth={field.labelWidth}
            noWrap={field.noWrap}
            field={field.padding}
            padding={field.padding}
            marginBottom={field.marginBottom}
            marginTop={field.marginTop}
            valueMarginTop={field.valueMarginTop}
            textAlign={field.textAlign}
            labelTextAlign={field.labelTextAlign}
        />
    )
        : formDropdownInput(field);
    return (
        <Grid container spacing={1} className={classes.root}>
            {
                selectAsLeftFireTestSectionData(selectedTag, formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ padding: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isLabel
                                    ? (
                                        <StyledLabel
                                            id={field.key}
                                            label={field.label}
                                            color={field.color}
                                            fontWeight={field.fontWeight}
                                            fontSize={field.fontSize}
                                            textAlign={field.textAlign}
                                            fontStyle={field.fontStyle}
                                            labelWidth={field.labelWidth}
                                            marginLeft={field.marginLeft}
                                            padding={field.padding}
                                        >
                                            {field.label}
                                        </StyledLabel>
                                    )
                                    : formInputView(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AsLeftFireTestSection);

AsLeftFireTestSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
