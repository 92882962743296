export const VisualInspectSectionDataM68 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F119VisualInspection',
                label: `Check condition of the detector; ensure there are no signs of damage. 
                If damage is visible continue with function test so that an ‘as found’ condition can be achieved`,
                value: formData.F119VisualInspection,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]
});
