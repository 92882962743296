export const VisualInspectSectionDataP711 = (selectedTag, formName, formData) => {
    let visualInspectSectionLabel;
    let diagnosticMessageLabel;
    let requiredDiagnostic;
    if (['P7-11', 'P8-13.1'].includes(formName)) {
        visualInspectSectionLabel = `Visually inspect instrument tagging, general instrument condition, security of mounting, instrument cabling
        and wiring, integrity of small bore tubing, for signs of deterioration, corrosion, and process fluid leaks.
        For instruments with remote diaphragm seals, check integrity of capillary tubes. Capillaries must be securely supported and clamped.`;
        diagnosticMessageLabel = 'Retrieve any diagnostic messages';
        requiredDiagnostic = false;
    } else if (['P7-14', 'P8-12.1', 'P6-31'].includes(formName)) {
        visualInspectSectionLabel = `Visually inspect loop components for signs of physical damage, water ingress, overheating and corrosion, 
        trace heating operation. Check cabling, glanding, compression fittings and shrouding for damage, security and general condition. Check 
        for signs of leakage around flowmeter pipe flanges. Check all earth grounding for damage and security. If the device is clamp-on type, 
        then visually observe the integrity of coupling to piping surface. Do not apply any coupling fluid before completing the As-Found test.`;
        diagnosticMessageLabel = 'Retrieve any diagnostic message';
        requiredDiagnostic = true;
    } else if (['P6-25'].includes(formName)) {
        visualInspectSectionLabel = `Visually inspect the scanner for signs of damage and corrosion. Verify security of scanner mounting.
                Check cables, glands and terminations for integrity. Verify tag identification is present and legible. Inspect purge air lines for signs of mechnical damage or leak.`;
        diagnosticMessageLabel = 'Retrieve diagnostic messages';
    } else {
        visualInspectSectionLabel = `Visually inspect loop components for signs of physical damage, water ingress, overheating and corrosion,
         trace heating operation. Check cabling, glanding, compression fittings and shrouding for damage, security and general condition.
          Check for signs of leakage around flowmeter pipe flanges. Check all earth grounding for damage and security.`;
        diagnosticMessageLabel = 'Retrieve any diagnostic message';
        requiredDiagnostic = true;
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F193VisuallyInspect',
                    label: visualInspectSectionLabel,
                    value: formData.F193VisuallyInspect,
                    isRequired: true,
                    isMultiline: true,
                    xs: 12,
                    width: '50%',
                    inputWidth: '50%',
                    alignItems: 'inherit',
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F194DiagnosticMessages',
                        label: diagnosticMessageLabel,
                        value: formData.F194DiagnosticMessages,
                        isMultiline: true,
                        isRequired: requiredDiagnostic,
                        xs: 12,
                        marginTop: '15px',
                        width: '50%',
                        inputWidth: '50%',
                        alignItems: 'inherit',
                    }]
            }
        ]
    };
};
