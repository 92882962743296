import React from 'react';
import { AbsoluteCenterDiv, FlashingLabel } from './SplashScreen.styled';

export const SplashScreen = () => (
    <AbsoluteCenterDiv>
        <img src="/MRAT_Logo_110x110.png" alt="MRAT" />
        <FlashingLabel className="loading">
            BP MRAT loading
            <span>.</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
        </FlashingLabel>
    </AbsoluteCenterDiv>
);
