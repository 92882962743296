import React from 'react';
import PropTypes from 'prop-types';

export default function BorderedBox(props) {
    const { formData, changeHandler, formName, dpsData, isFormReadOnly, withoutmargin, withoutBorder,
        updateFormData, altTestPoint, aftTestPoint, flowAlarmTestPoint } = props;
    const borderBox = '2px solid black';
    const noBorderLR = withoutBorder ? { border: borderBox, borderLeft: '0', borderRight: '0', marginTop: '15px' }
        : { border: borderBox, marginTop: '15px' };
    return (
        <div id="BorderedBox" style={withoutmargin ? { border: borderBox } : noBorderLR}>
            {React.Children.map(props.children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        formData,
                        changeHandler,
                        formName,
                        dpsData,
                        isFormReadOnly,
                        updateFormData,
                        aftTestPoint,
                        altTestPoint,
                        flowAlarmTestPoint
                    });
                }
                return child;
            })}
        </div>
    );
}

BorderedBox.propTypes = {
    aftTestPoint: PropTypes.number,
    altTestPoint: PropTypes.number,
    changeHandler: PropTypes.func,
    children: PropTypes.any,
    dpsData: PropTypes.object,
    flowAlarmTestPoint: PropTypes.number,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    updateFormData: PropTypes.func,
    withoutBorder: PropTypes.bool,
    withoutmargin: PropTypes.bool,
};
