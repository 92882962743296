import { AsLeftTestTableDeviationEu2Formula } from './P67.formulas';
import { AsLeftResultFormula } from '../../../TestResultSection/Data/P67/P67.formulas';

export const AsLeftTestTableSectionDataP67P841P8101 = (selectedTag, formName, formData) => {
    let altIndicationLabel;
    if (formName === 'P6-18') {
        altIndicationLabel = 'Switch Output';
    } else {
        altIndicationLabel = 'HMI Indication';
    }
    return {
        rows: [
            {
                key: 1,
                fields: [
                    {
                        key: 'ASLeftTest',
                        label: 'As-Left Test',
                        isLabel: true,
                        xs: 4,
                        fontWeight: '700',
                        labelWidth: '100%',
                        padding: '7px 0 5px 6px',
                        fontSize: '9pt'
                    },
                    {
                        key: 'Nodata',
                        isLabel: true,
                        xs: 1
                    },
                    {
                        key: 'F184AltTestEquipmentIndicationLabel',
                        label: 'Test Equipment Reading',
                        isLabel: true,
                        xs: 2,
                        fontWeight: '600',
                        textAlign: 'center',
                        labelWidth: '100%',
                        padding: '7px 0 5px 3px',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F185AltHmireadingLabel',
                        label: altIndicationLabel,
                        isLabel: true,
                        xs: 3,
                        fontWeight: '600',
                        labelWidth: '100%',
                        textAlign: 'center',
                        padding: '7px 0 5px 0',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F186AltDeviationLabel',
                        label: 'Deviation (EU)',
                        isLabel: true,
                        fontWeight: '600',
                        xs: 2,
                        textAlign: 'center',
                        labelWidth: '100%',
                        padding: '7px 0 5px 0',
                        fontSize: '9pt'
                    }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'Nodata1',
                        xs: 5,
                        isLabel: true,
                    },
                    {
                        key: 'F184AltTestEquipmentIndication',
                        value: formData.F102AftResult !== 'PASS' ? formData.F184AltTestEquipmentIndication : '',
                        isRequired: formData.F102AftResult !== 'PASS',
                        isDisabled: formData.F102AftResult === 'PASS',
                        readOnly: formData.F102AftResult === 'PASS',
                        type: 'number',
                        xs: 2,
                        width: '100%',
                        maxLength: '10',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.13rem',
                        borderRight: '0',
                    },
                    {
                        key: 'F185AltHmireading',
                        value: formData.F102AftResult !== 'PASS' ? formData.F185AltHmireading : '',
                        isRequired: formData.F102AftResult !== 'PASS',
                        isDisabled: formData.F102AftResult === 'PASS',
                        readOnly: formData.F102AftResult === 'PASS',
                        isDropdown: formData.F102AftResult !== 'PASS',
                        placeholder: 'Select ALARM or NORMAL',
                        options: [{ name: 'Select ALARM or NORMAL', value: '' },
                        { name: 'ALARM', value: 'ALARM' },
                        { name: 'NORMAL', value: 'NORMAL' }],
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: formData.F102AftResult === 'PASS' ? '1.13rem' : '1.5rem'
                    },
                    {
                        key: 'F186AltDeviation',
                        value: formData.F102AftResult !== 'PASS' ? AsLeftTestTableDeviationEu2Formula(formData) : '',
                        isDisabled: formData.F102AftResult === 'PASS',
                        xs: 2,
                        labelWidth: '0',
                        width: '96.2%',
                        textAlign: 'center',
                        height: '1.13rem',
                        borderLeft: '0',
                        borderRight: '0',
                        readOnly: true,
                        inputMarginRight: '4px'
                    }]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'Nodata2',
                        isLabel: true,
                        xs: 7
                    },
                    {
                        key: 'F103AltResult',
                        label: 'As-Left Result',
                        value: formData.F102AftResult !== 'PASS' ? AsLeftResultFormula(formData) : '',
                        isDisabled: formData.F102AftResult === 'PASS',
                        readOnly: true,
                        isCalculatedNew: true,
                        xs: 5,
                        labelWidth: '58.3%',
                        fontWeight: '700',
                        direction: 'rtl',
                        width: '38.4%',
                        inputMarginRight: '4px',
                        marginRight: '0.5rem',
                        borderBottom: '0',
                        borderRight: '0',
                        borderTop: '0',
                        height: '1.16rem',
                        textAlign: 'center',
                        marginBottom: '4px'
                    }
                ]
            }
        ]
    };
};
