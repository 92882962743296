import styled from 'styled-components';

export const SearchFieldWrapper = styled.div`
.MuiOutlinedInput-root {
    border-radius: 0;
    background-color:white;
    height: 24px;
    width:  ${props => props.width ? props.width : '40px'};
}
.MuiOutlinedInput-root:hover fieldset {
    border-color: #498205;
}
.MuiOutlinedInput-root:focus fieldset {
    border-color: #498205;
}
.MuiOutlinedInput-root:focus-within fieldset {
    border-color: #498205;
}
.MuiOutlinedInput-notchedOutline {
    border-color: #498205;
    border-width: 2px;
}
.MuiInputBase-input.Mui-disabled {
    background-color: #498205;
    padding: 0;
    width: 18px;
    height: 24px;
}

.MuiOutlinedInput-input {
    padding-left: 8px;
    padding-right: 8px;
}
.MuiInputBase-input {
    height: 1.1876em;
    font-size: 11px;
}
`;
