import { placeholderName } from '../../../HMISection/Data/M616/M616.data';
import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';

export const TestResultSectionDataM616 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F660StatusNotificationApplianceAt',
            label: 'Status of Notification Appliance After Test',
            value: formData.F660StatusNotificationApplianceAt,
            isRequired: true,
            isDropDown: true,
            placeholder: placeholderName,
            options: [{ name: placeholderName, value: '' },
            { name: 'ON', value: 'ON' },
            { name: 'OFF', value: 'OFF' }],
            direction: 'rtl',
            xs: 6,
            width: '49%',
            labelWidth: '51.9%',
            inputTextAlign: 'center',
        },
        {
            key: 'F85TestResultLabel',
            label: 'Test Result',
            xs: 3,
            labelWidth: '96%',
            padding: '5px 0 0 0',
            fontWeight: '600',
            fontSize: '9pt',
            textAlign: 'end',
            isLabel: 'true'
        },
        {
            key: 'F85TestResult',
            value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
            isRequired: true,
            isDropDown: true,
            placeholder: 'Select Test Result',
            options: TestResultDropdownOptions(formData),
            xs: 3,
            labelWidth: '0',
            width: '100%'
        }]
    },
]
});
