import { FieldHasValue, ValidateNumberField, IsANumber } from 'helpers/Validation';

export const FoundResultF81AsLeftResultFormula = (formData) => {
    if (FieldHasValue(formData.F601MaxTolerableLeakageRate) && IsANumber(formData.F601MaxTolerableLeakageRate)
        && FieldHasValue(formData.F634AltCalculatedValveLeakageRate)) {
        if (ValidateNumberField(formData.F634AltCalculatedValveLeakageRate) <= ValidateNumberField(formData.F601MaxTolerableLeakageRate)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
