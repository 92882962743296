export const VisualInspectSectionDataM64 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F410DetectorMounting',
                label: `Ensure that detector mounting arrangements and enclosure are secure and undamaged. 
                Confirm that the detector is aligned correctly and that the field of view is correct. 
                The direction of the detectors field of view has to be checked against the Fire and gas layout 
                drawings to ensure that the detector is in correct alignment. Check condition of detector window. 
                If visibly dirty continue with function test as this is the ‘as found’ condition.`,
                value: formData.F410DetectorMounting,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]

});
