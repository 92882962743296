import { EngineeringUnit } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../C11/C11.data';
import { labelWidth } from '../P710/P710.data';

export const HMISectionDataP66 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F100HmireadingPriorToTestLabel',
                label: 'HMI Reading Prior to Test',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F100HmireadingPriorToTest',
                value: formData.F100HmireadingPriorToTest,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                textAlign: 'center',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                height: '1.1rem'
            },
            {
                key: 'F115HmiengineeringUnitsLabel',
                label: EngineeringUnit.HMI_ENGINEERING_UNIT_EU,
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F115HmiengineeringUnits',
                value: selectedTag.DateTested ? formData?.F115HmiengineeringUnits
                    : formData?.F115HmiengineeringUnits ?? selectedTag?.TsprHmiEngineeringUnits,
                xs: 2,
                textAlignLabel: 'end',
                inputWidth: '100%',
                labelWidth: '0%',
                borderTop: '0',
                marginRight: '0',
                textAlign: 'center',
                isRequired: true,
                maxLength: '14',
                direction: 'rtl',
                isAutoGrow: true,
            }]
        }
    ]
});
