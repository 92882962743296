import { checkURVGreaterThanLRV } from 'helpers/Validation';
import { EngineeringUnit } from 'journeys/portal/Forms/Sections.constants';
import { HMISectionCulatedRangeFormula } from './P78.formulas';
import { paddingLabel } from '../C11/C11.data';
import { labelWidth } from '../P710/P710.data';

export const HMISectionDataP78P617P712 = (selectedTag, formName, formData) => {
    let hmiReadingLabel;
    let processReadingLabel;
    if (formName === 'P7-24') {
        hmiReadingLabel = 'HMI Value Reading Prior to Test';
        processReadingLabel = 'Process Value Reading Prior to Test';
    } else {
        hmiReadingLabel = 'HMI Reading Prior to Test';
        processReadingLabel = 'Process TX Reading Prior to Test';
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F100HmireadingPriorToTestLabel',
                    label: hmiReadingLabel,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F100HmireadingPriorToTest',
                    value: formData.F100HmireadingPriorToTest,
                    xs: 2,
                    borderBottom: '0',
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center'
                },
                {
                    key: 'F144ProcessTxreadingPriorToTestLabel',
                    label: processReadingLabel,
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,

                },
                {
                    key: 'F144ProcessTxreadingPriorToTest',
                    value: formData.F144ProcessTxreadingPriorToTest,
                    isRequired: true,
                    xs: 2,
                    borderBottom: '0',
                    type: 'number',
                    maxLength: '10',
                    height: '1.2rem',
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    textAlign: 'center'

                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'F21HmiLrvLabel',
                    label: 'HMI LRV',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F21HmiLrv',
                    value: selectedTag.DateTested ? formData?.F21HmiLrv : formData?.F21HmiLrv ?? selectedTag?.TsprHmiLrv,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    height: '1.2rem',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    hasHmiError: !checkURVGreaterThanLRV(formData.F21HmiLrv, formData.F22HmiUrv)
                },
                {
                    key: 'F22HmiUrvLabel',
                    label: 'HMI URV',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F22HmiUrv',
                    value: selectedTag.DateTested ? formData?.F22HmiUrv : formData?.F22HmiUrv ?? selectedTag?.TsprHmiUrv,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    isRequired: true,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderBottom: '0',
                    hasHmiError: !checkURVGreaterThanLRV(formData.F21HmiLrv, formData.F22HmiUrv),
                }]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F105HmicalculatedRangeLabel',
                        label: 'HMI Calculated Range',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F105HmicalculatedRange',
                        value: HMISectionCulatedRangeFormula(formData.F21HmiLrv, formData.F22HmiUrv, formData.F115HmiengineeringUnits),
                        xs: 2,
                        isCalculatedNew: true,
                        readOnly: true,
                        inputWidth: '100%',
                        labelWidth: '0%',
                        borderTop: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                        isAutoGrow: true,
                        marginRight: '0',
                    },
                    {
                        key: 'F115HmiengineeringUnitsLabel',
                        label: EngineeringUnit.HMI_ENGINEERING_UNIT_EU,
                        xs: 4,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel
                    },
                    {
                        key: 'F115HmiengineeringUnits',
                        maxLength: '14',
                        isRequired: true,
                        value: selectedTag.DateTested ? formData?.F115HmiengineeringUnits : formData?.F115HmiengineeringUnits ?? selectedTag?.TsprHmiEngineeringUnits,
                        xs: 2,
                        inputWidth: '100%',
                        labelWidth: '0%',
                        textAlignLabel: 'end',
                        borderTop: '0',
                        borderBottom: '0',
                        marginRight: '0',
                        textAlign: 'center',
                        isAutoGrow: true,
                        direction: 'rtl',
                    }]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F116InstrumentCalibratedLrvLabel',
                        label: 'Instrument Calibrated LRV',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F116InstrumentCalibratedLrv',
                        value: selectedTag.DateTested ? formData?.F116InstrumentCalibratedLrv : formData?.F116InstrumentCalibratedLrv ?? selectedTag?.TsprInstrumentCalibratedLrv,
                        xs: 2,
                        isRequired: true,
                        type: 'number',
                        maxLength: '10',
                        width: '100%',
                        labelWidth: '0%',
                        height: '1.3rem',
                        textAlign: 'center',
                        borderTop: '0',
                        borderBottom: '0',
                        hasInstError: !checkURVGreaterThanLRV(formData.F116InstrumentCalibratedLrv, formData.F117InstrumentCalibratedUrv)
                    },
                    {
                        key: 'F117InstrumentCalibratedUrvLabel',
                        label: 'Instrument Calibrated URV',
                        xs: 4,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel
                    },
                    {
                        key: 'F117InstrumentCalibratedUrv',
                        type: 'number',
                        maxLength: '10',
                        xs: 2,
                        value: selectedTag.DateTested ? formData?.F117InstrumentCalibratedUrv : formData?.F117InstrumentCalibratedUrv ?? selectedTag?.InstrumentCalibratedUrv,
                        isRequired: true,
                        width: '100%',
                        labelWidth: '0%',
                        textAlignLabel: 'end',
                        height: '1.2rem',
                        textAlign: 'center',
                        borderBottom: '0',
                        hasInstError: !checkURVGreaterThanLRV(formData.F116InstrumentCalibratedLrv, formData.F117InstrumentCalibratedUrv)
                    }]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F27InstrumentCalibratedRangeLabel',
                        label: 'Instrument Calibrated Range',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel
                    },
                    {
                        key: 'F27InstrumentCalibratedRange',
                        value: HMISectionCulatedRangeFormula(formData.F116InstrumentCalibratedLrv, formData.F117InstrumentCalibratedUrv, formData.F118InstrumentEngineeringUnits),
                        xs: 2,
                        readOnly: true,
                        isCalculatedNew: true,
                        inputWidth: '100%',
                        labelWidth: '0%',
                        borderTop: '0',
                        textAlign: 'center',
                        isAutoGrow: 'true',
                        marginRight: '0',
                    },
                    {
                        key: 'F118InstrumentEngineeringUnitsLabel',
                        label: EngineeringUnit.INSTRUMENT_ENGINEERING_UNIT_EU,
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        labelWidth,
                        padding: paddingLabel
                    },
                    {
                        key: 'F118InstrumentEngineeringUnits',
                        maxLength: '17',
                        isRequired: true,
                        value: selectedTag.DateTested ? formData?.F118InstrumentEngineeringUnits
                            : formData?.F118InstrumentEngineeringUnits ?? selectedTag?.TsprInstrumentEngineeringUnits,
                        xs: 2,
                        textAlignLabel: 'end',
                        inputWidth: '100%',
                        labelWidth: '0%',
                        marginRight: '0',
                        textAlign: 'center',
                        borderTop: '0',
                        isAutoGrow: true,
                        direction: 'rtl',
                    }
                ]
            }
        ]
    };
};
