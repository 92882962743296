import React from 'react';
import PropTypes from 'prop-types';
import CaptureImage from './CaptureImage/CaptureImage';
import ImageGallery from './ImageGallery/ImageGallery';
import { CameraPageWrapper } from './Camera.styled';

const Camera = (props) => {
    const { selectedTag, formData, capturedImages, getImages, readOnly } = props;
    return (
        <CameraPageWrapper id="cam_page_wrapper">
            <CaptureImage id="capture_image" selectedTag={selectedTag} formData={formData} getImages={getImages} readOnly={readOnly} />
            <ImageGallery id="image_gallery" capturedImages={capturedImages} getImages={getImages} readOnly={readOnly} />
        </CameraPageWrapper>
    );
};

export default Camera;

Camera.propTypes = {
    capturedImages: PropTypes.array,
    formData: PropTypes.object,
    getImages: PropTypes.func,
    readOnly: PropTypes.bool,
    selectedTag: PropTypes.object
};
