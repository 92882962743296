import { ValidateField, conditionalValueSelector } from 'helpers/Validation';
import {
    AsLeftTestTableF237AltDeviation1Formula,
    AsLeftTestTableF238AltDeviation2Formula,
    AsLeftTestTableF239AltDeviation3Formula,
    F81AsLeftResultFormula
} from './P728.formulas';
import {
    AFTCalibration2Points3Formula,
    AFTCalibration2Points2Formula,
    AFTCalibration2Points1Formula,
} from '../../../AsFoundTestTableSection/Data/P710/P710.formulas';
import { paddingAF } from '../M71/M71.data';
import { disableAsLeft } from '../../AsLeftTestTableSection.helpers';

export const AsLeftTestTableSectionDataP728 = (selectedTag, formData) => {
    const altDisabled = disableAsLeft(formData.F102AftResult);
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsLeftTest',
                    label: 'As-Left Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'CalibrationPoints',
                    label: 'Calibration Points',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: '5px 0 5px 8px',
                    fontSize: '9pt'
                },
                {
                    key: 'Local/TransmitterTXReading',
                    label: 'Local/Transmitter (TX) Reading',
                    isLabel: true,
                    xs: 3,
                    textAlign: 'center',
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'HMI Reading',
                    label: 'HMI Reading',
                    isLabel: true,
                    xs: 2,
                    textAlign: 'center',
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'MeasuredmAReading',
                    label: 'Measured mA Reading',
                    isLabel: true,
                    xs: 2,
                    textAlign: 'center',
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'Calculated Deviation',
                    label: 'Calculated Deviation',
                    isLabel: true,
                    xs: 3,
                    textAlign: 'center',
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: paddingAF,
                    fontSize: '9pt'
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'F136AltCalibrationPoint1',
                    value: conditionalValueSelector(altDisabled, '',
                        AFTCalibration2Points1Formula(formData)),
                    isDisabled: altDisabled,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F487AltLocalTransmitterTxreading1',
                    xs: 3,
                    value: conditionalValueSelector(altDisabled, '',
                        ValidateField(formData.F487AltLocalTransmitterTxreading1)),
                    isRequired: (!altDisabled),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F109AltHmireading1',
                    value: conditionalValueSelector(altDisabled,
                        '', ValidateField(formData.F109AltHmireading1)),
                    isRequired: (!altDisabled),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F164AltMeasuredReading1',
                    value: conditionalValueSelector(altDisabled, '',
                        ValidateField(formData.F164AltMeasuredReading1)),
                    isRequired: (!altDisabled),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0'
                },
                {
                    key: 'F237AltDeviation1',
                    value: conditionalValueSelector(altDisabled, '',
                        AsLeftTestTableF237AltDeviation1Formula(formData)),
                    isDisabled: altDisabled,
                    readOnly: true,
                    xs: 3,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px'
                }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F137AltCalibrationPoint2',
                    value: conditionalValueSelector(altDisabled, '',
                        AFTCalibration2Points2Formula(formData)),
                    isDisabled: altDisabled,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F488AltLocalTransmitterTxreading2',
                    value: conditionalValueSelector(altDisabled,
                        '', ValidateField(formData.F488AltLocalTransmitterTxreading2)),
                    isRequired: (!altDisabled),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 3,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F113AltHmireading2',
                    value: conditionalValueSelector(altDisabled, '',
                        ValidateField(formData.F113AltHmireading2)),
                    isRequired: (!altDisabled),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F165AltMeasuredReading2',
                    value: conditionalValueSelector(altDisabled,
                        '', ValidateField(formData.F165AltMeasuredReading2)),
                    isRequired: (!altDisabled),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0'
                },
                {
                    key: 'F238AltDeviation2',
                    value: conditionalValueSelector(altDisabled, '',
                        AsLeftTestTableF238AltDeviation2Formula(formData)),
                    isDisabled: altDisabled,
                    readOnly: true,
                    xs: 3,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px'
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F138AltCalibrationPoint3',
                    value: conditionalValueSelector(altDisabled, '',
                        AFTCalibration2Points3Formula(formData)),
                    isDisabled: altDisabled,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F489AltLocalTransmitterTxreading3',
                    value: conditionalValueSelector(altDisabled, '',
                        ValidateField(formData.F489AltLocalTransmitterTxreading3)),
                    isRequired: (!altDisabled),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 3,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0'
                },
                {
                    key: 'F114AltHmireading3',
                    value: conditionalValueSelector(altDisabled, '',
                        ValidateField(formData.F114AltHmireading3)),
                    isRequired: (!altDisabled),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0'
                },
                {
                    key: 'F166AltMeasuredReading3',
                    value: conditionalValueSelector(altDisabled, '',
                        ValidateField(formData.F166AltMeasuredReading3)),
                    isRequired: (!altDisabled),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0'
                },
                {
                    key: 'F239AltDeviation3',
                    value: conditionalValueSelector(altDisabled, '',
                        AsLeftTestTableF239AltDeviation3Formula(formData)),
                    isDisabled: altDisabled,
                    readOnly: true,
                    xs: 3,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    inputMarginRight: '4px'
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'NoData2',
                    xs: 3,
                    isLabel: true
                },
                {
                    key: 'F81AsLeftResultLabel',
                    value: 'As-Left Result',
                    readOnly: true,
                    xs: 2,
                    isLabelBold: true,
                    textAlign: 'end',
                    labelWidth: '0',
                    width: '97%',
                    height: '1.1rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    borderTop: '0',
                    marginBottom: '4px'
                },
                {
                    key: 'F81AsLeftResult',
                    value: conditionalValueSelector(altDisabled, '', F81AsLeftResultFormula(formData)),
                    readOnly: true,
                    isCalculatedNew: true,
                    isDisabled: altDisabled,
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    height: '1.2rem',
                    borderTop: conditionalValueSelector(altDisabled, '', '0'),
                    textAlign: 'center',
                    borderBottom: '0',
                    marginBottom: '4px',
                    inputMarginRight: '-2px'
                }
                ]
            }]
    };
};
