import styled from 'styled-components';

export const GradientLine = styled.div`
    background: linear-gradient(to right, yellowgreen,green,yellowgreen,yellow);
    margin-top: 4px;
    height: 4px
`;

export const HeaderRibbon = styled.div`
    background-color: #498205 ;
    height: 35px;
`;

export const PrintIconStyle = styled.span`
    .MuiIconButton-root {
        padding: 0
    }
`;

export const HeaderText = styled.div`
margin: auto;
font-size: ${props => props.titleFontSize || '19pt'};
font-family: Arial;
line-height: 1.2;
`;
export const HeaderButton = styled.button`
    width: ${props => props.buttonWidth ? props.buttonWidth.toString().concat('px !important') : '105px'};
    height: ${props => props.buttonHeight ? props.buttonHeight.toString().concat('px !important') : '80px'};
    :hover {
        background-color: #335B03;
        border-color: transparent
    }
`;

export const HeaderContainer = styled.div`
background-color: #498205 ;
color: white;
display: flex;
justify-content: space-between;
opacity: 1;
    img {
        margin: 0 1% 0 1%;
        width: 93px;
        height: 80px;
        object-fit: cover;
    }
    div {
        display: flex;
        font-family: Arial, sans-serif;
        @media only screen and (max-width: 960px) {
            font-size: ${props => {
        if (props.formName === 'P7-12') {
            return '14.4pt';
        }
        if (props.formName === 'P7-6') {
            return '15.9pt';
        }
        if (props.formName === 'P7-3') {
            return '13.9pt';
        }
        if (props.formName === 'C1-2' || props.formName === 'P7-2') {
            return '14.8pt';
        }
        if (props.formName === 'P6-3' || props.formName === 'P6-3.2') {
            return '15.4pt';
        }
        return props.titleFontSize || '19pt;';
    }};
        }
        font-size: ${props => props.titleFontSize || '19pt'};
        line-height: 1.2;
        align-items: center;
        div {
            margin-right: 5px;
            margin-left: 5px;
            .MuiIconButton-root {
                padding: 0;
            }
        }
    }
    div.buttonCollection > button {    
        font-weight: 600;
        font-size: 9pt;
        width: 90px;
        height: 64px;
        border: 2px solid #FFFFFF !important;
        background-color: #498205;
        border-radius: 20px;
        color: white;
        cursor: pointer;
        margin-right:20px;
        &:hover {
            border-color: transparent !important; 
        }
        &:disabled{
            cursor: auto;
            border-color: darkgrey !important;
            background-color: ${props => props.isDPSDisabled ? '#498205' : '#cccccc'};
            color: ${props => props.isDPSDisabled ? 'darkgrey' : '#666666'};
        }
    }
`;
export const TotalRecords = styled.div`
font-size: 12pt;
width:  ${props => props.width ? props.width : '30px'};
text-align: center;
`;
