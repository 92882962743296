import { ALTSoundPressureFormula, AsLeftResultM65Formula } from './M65.formula';
import { notRequiredVal } from '../../../DetectorLenseSection/DetectorLense.data';
import { testLabel } from '../../../AsFoundTestTableSection/Data/M65/M65.data';
import { isSelfPortableTest } from '../../../AsFoundTestTableSection/Data/M65/M65.formula';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';

export const AsLeftTestTableSectionDataM65 = (selectedTag, formData) => {
    const isLenseCleaningRequired = formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal;
    const isPortableTestOrLenseCleaningRequired = (isSelfPortableTest(formData) || (isLenseCleaningRequired));
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsleftTest',
                    label: 'As-Left Test (in field repair attempt)',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F821TestToolActivationAltLabel',
                        label: 'Confirm that test tool has been activated in the proximity of the detector',
                        xs: 4,
                        labelWidth: '98%',
                        padding: '0 0 0 8px',
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F821TestToolActivationAlt',
                        value: isLenseCleaningRequired ? '' : formData.F821TestToolActivationAlt,
                        isRequired: !isLenseCleaningRequired,
                        isDisabled: (isLenseCleaningRequired),
                        readOnly: (isLenseCleaningRequired),
                        isDropdown: !isLenseCleaningRequired,
                        placeholder: 'Select YES or NO',
                        options: [{ name: 'Select YES or NO', value: '' },
                        { name: 'YES', value: 'YES' }, { name: 'NO', value: 'NO' }],
                        labelWidth: '0',
                        width: '100%',
                        height: (isLenseCleaningRequired) ? '1.2rem' : '1.5rem',
                        inputTextAlign: 'center',
                        xs: 2,
                        marginTop: '5px'
                    }]
            },
            {
                key: 3,
                fields: [{
                    key: 'Gaintesting',
                    label: 'Gain testing',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    fontSize: '9pt',
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F420AltSelectTestGainLabel',
                        label: testLabel,
                        xs: 4,
                        labelWidth: '98%',
                        padding: '0 0 0 8px',
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F420AltSelectTestGain',
                        value: isPortableTestOrLenseCleaningRequired ? '' : formData.F420AltSelectTestGain,
                        isRequired: !isPortableTestOrLenseCleaningRequired,
                        isDisabled: isPortableTestOrLenseCleaningRequired,
                        readOnly: isPortableTestOrLenseCleaningRequired,
                        isDropdown: !isPortableTestOrLenseCleaningRequired,
                        placeholder: testLabel,
                        options: [{ name: testLabel, value: '' },
                        { name: '1', value: '1' }, { name: '2', value: '2' }, { name: '3', value: '3' },
                        { name: '4', value: '4' }, { name: '5', value: '5' }, { name: '6', value: '6' },
                        { name: '7', value: '7' }, { name: '8', value: '8' }, { name: '9', value: '9' }],
                        labelWidth: '0',
                        height: isPortableTestOrLenseCleaningRequired ? '1.2rem' : '1.5rem',
                        width: '100%',
                        inputTextAlign: 'center',
                        xs: 2
                    }]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F421AltSoundPressureLevel1Label',
                        label: 'Applied sound pressure level, dB',
                        xs: 4,
                        labelWidth: '98%',
                        padding: '0 0 0 8px',
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F421AltSoundPressureLevel1',
                        value: isPortableTestOrLenseCleaningRequired ? '' : ALTSoundPressureFormula(formData),
                        isDisabled: isPortableTestOrLenseCleaningRequired,
                        xs: 2,
                        readOnly: true,
                        isCalculatedNew: true,
                        borderTop: '0',
                        labelWidth: '0',
                        width: '100%',
                        textAlign: 'center',
                        height: '1rem'
                    }]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'nodata',
                        isLabel: true,
                        xs: 12
                    }
                ]
            },
            {
                key: 7,
                fields: [
                    {
                        key: 'F378AltHmistatus1Label',
                        label: 'HMI Status',
                        xs: 4,
                        labelWidth: '98%',
                        padding: '0 0 0 8px',
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F378AltHmistatus1',
                        value: isLenseCleaningRequired ? '' : formData.F378AltHmistatus1,
                        isRequired: !isLenseCleaningRequired,
                        isDisabled: (isLenseCleaningRequired),
                        readOnly: (isLenseCleaningRequired),
                        isDropdown: !isLenseCleaningRequired,
                        placeholder: 'Select HMI Status',
                        options: [{ name: 'Select HMI Status', value: '' },
                        { name: 'NORMAL', value: 'NORMAL' },
                        { name: 'ALARM', value: 'ALARM' },
                        { name: 'LOOP FAULT', value: 'LOOP FAULT' }],
                        labelWidth: '0',
                        height: (isLenseCleaningRequired) ? '1.2rem' : '1.5rem',
                        width: '100%',
                        inputTextAlign: 'center',
                        xs: 2,
                    }]
            },
            {
                key: 8,
                fields: [
                    {
                        key: 'F422AltSoundPressureLevel2Label',
                        label: 'Measured sound pressure level, dB',
                        xs: 4,
                        labelWidth: '98%',
                        padding: '0 0 0 8px',
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F422AltSoundPressureLevel2',
                        value: isPortableTestOrLenseCleaningRequired ? '' : formData.F422AltSoundPressureLevel2,
                        xs: 2,
                        isRequired: !isPortableTestOrLenseCleaningRequired,
                        isDisabled: isPortableTestOrLenseCleaningRequired,
                        readOnly: isPortableTestOrLenseCleaningRequired,
                        maxLength: '10',
                        labelWidth: '0',
                        width: '100%',
                        borderTop: '0',
                        type: 'number',
                        textAlign: 'center',
                        height: isPortableTestOrLenseCleaningRequired ? '1.21rem' : '1.23rem',
                    },
                    {
                        key: 'F103AltResultLabel',
                        label: 'As-Left Result',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '700',
                        labelWidth,
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F103AltResult',
                        value: isLenseCleaningRequired ? '' : AsLeftResultM65Formula(formData),
                        isDisabled: (isLenseCleaningRequired),
                        readOnly: true,
                        width: '100%',
                        labelWidth: '0',
                        isCalculatedNew: true,
                        xs: 2,
                        textAlign: 'center',
                        height: '1.1rem',
                        borderRight: '0',
                        direction: 'rtl',
                        isLabelBold: true,
                        inputMarginRight: '4px',
                        fontWeight: '700'
                    }]
            },
            {
                key: 9,
                fields: [
                    {
                        key: 'F423AltTimeDelayLabel',
                        label: 'Time delay, sec',
                        xs: 4,
                        labelWidth: '98%',
                        padding: '0 0 0 8px',
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F423AltTimeDelay',
                        value: isLenseCleaningRequired ? '' : formData.F423AltTimeDelay,
                        isDisabled: (isLenseCleaningRequired),
                        readOnly: (isLenseCleaningRequired),
                        xs: 2,
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        width: '100%',
                        marginBottom: '4px',
                        textAlign: 'center',
                        height: '1.1rem',
                        borderTop: isSelfPortableTest(formData) ? '' : '0',
                        borderBottom: '0'
                    }]
            },
        ]
    };
};
