export const DpsDataSourceHeaderData = [
    {
        field: 'DpssourceId',
        headerName: 'DPS Source ID',
        width: '100',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'DpssourceName',
        headerName: 'DPS Source Name',
        width: '150',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '130',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '140',
        hideInTable: false,
        isDisabled: false,
        type: 'date'
    },
    {
        field: 'ModifiedBy',
        headerName: 'Modified By',
        width: '110',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '140',
        hideInTable: false,
        isDisabled: false,
        type: 'date'
    },
    {
        field: 'Comment',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const DpsDataSourceInitialState = {
    DpssourceId: 0,
    DpssourceName: '',
    Status: 'Inactive',
    Comment: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: ''
};

export const DpsDataSourceSearchState = {
    DpssourceId: '',
    DpssourceName: '',
    Status: '',
    Comment: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: ''
};

export const dpsDataSourceApiEndpoint = {
    read: 'ADMIN_GetDPSDataSources'
};
