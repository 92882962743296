export const DpsUploadHeaderData = [
    {
        field: 'RowId',
        headerName: 'Row ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'FileName',
        headerName: 'File Name',
        width: '250',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'SheetName',
        headerName: 'Sheet Name',
        width: '150',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'RecordCount',
        headerName: 'Record Count',
        width: '120',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'InsertedRecordCount',
        headerName: 'Inserted Record Count',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'UpdatedRecordCount',
        headerName: 'Updated Record Count',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'DuplicateRecordCount',
        headerName: 'Duplicate Record Count',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'UserNameForUpload',
        headerName: 'User Name For Upload',
        width: '180',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'UploadStatus',
        headerName: 'Upload Status',
        width: '130',
        hideInTable: false,
        isDisabled: false,
        type: 'date'
    },
    {
        field: 'UploadedBy',
        headerName: 'Uploaded By',
        width: '140',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'UploadedDate',
        headerName: 'Uploaded Date',
        width: '140',
        hideInTable: false,
        isDisabled: false,
        type: 'date'
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const DpsUploadInitialState = {
    RowId: 0,
    FileName: '',
    SheetName: '',
    RecordCount: '',
    UserNameForUpload: '',
    UploadStatus: '',
    UploadedBy: '',
    UploadedDate: '',
    Comments: '',
    InsertedRecordCount: '',
    UpdatedRecordCount: '',
    DuplicateRecordCount: ''
};

export const DpsUploadSearchState = {
    RowId: '',
    FileName: '',
    SheetName: '',
    RecordCount: '',
    UserNameForUpload: '',
    UploadStatus: '',
    UploadedBy: '',
    UploadedDate: '',
    Comments: '',
    InsertedRecordCount: '',
    UpdatedRecordCount: '',
    DuplicateRecordCount: ''
};
export const dpsUploadApiEndpoint = {
    read: 'ADMIN_GetDPSUploadlog'
};
