import { ValidateField, conditionalValueSelector } from 'helpers/Validation';
import { AsLeftTestTableP716Formula } from './P716.formulas';
import { isDisabledFieldInAsLeftTest } from '../../../FailureModeSection/Data/P716/P716.data';
import { paddingLabel, placeholderSelectYes, placeholderStrokeTestResult } from '../../../AsFoundTestTableSection/Data/P716/P716.data';
import { isAsLeftTestRequired } from '../../AsLeftTestTableSection.helpers';

export const AsLeftTestTableSectionDataP716 = (selectedTag, formData) => {
    const altDisabled = isAsLeftTestRequired(formData.F61AsFoundResult, formData.F619AsLeftTestRequired);
    const solenoidValveLabel = 'Solenoid Valve';
    const isAltSolenoidSectionDisabled = !formData.F759AsLeftTestPstMethod
        || isDisabledFieldInAsLeftTest(formData)
        || (formData.F759AsLeftTestPstMethod === 'Smart Positioner');
    const isSolenoidTestingDisabled = isAltSolenoidSectionDisabled
        || formData?.F751AltValveInitiatedPartialStrokeTest !== 'YES';
    const isAltSmartSectionDisabled = !formData.F759AsLeftTestPstMethod
        || isDisabledFieldInAsLeftTest(formData)
        || (formData.F759AsLeftTestPstMethod === solenoidValveLabel);
    const isSmartTestingDisabled = isAltSmartSectionDisabled
        || formData?.F755AltSmartInitiatedPartialStrokeTest !== 'YES';
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'ASLEFTTEST',
                    label: 'As-Left Test',
                    isLabel: true,
                    fontWeight: '700',
                    width: '28%',
                    padding: paddingLabel,
                    xs: 12,
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'attention',
                    label: `Attention: The following simple corrections may be performed by technician as part of this
                 PM work order — repeat stroking valve against stiction,
                 cleaning vent lines and ports, flow regulator adjustments, limit switch adjustments.
               `,
                    width: '0',
                    marginLeft: '8px',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderTop: '0',
                    borderRight: '0',
                    labelWidth: '100%',
                    inputMarginRight: '6px',
                    fontSize: '8.5pt',
                    color: '#ea7c34'
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'followingcomplicated',
                    label: ` The following complicated corrections should be performed by technician as part
                 of follow-up corrective work order — component replacements
                 (e.g. solenoid, QEV), valve and actuator overhaul, lubricating and greasing valve or actuator.`,
                    width: '0',
                    marginLeft: '8px',
                    labelWidth: '100%',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderTop: '0',
                    borderRight: '0',
                    fontSize: '8.5pt',
                    inputMarginRight: '6px',
                    color: '#ea7c34',
                    marginBottom: '5px'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'NodataP716',
                        xs: 3,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'TestviaSolenoidValve',
                        label: 'Test via Solenoid Valve',
                        isLabel: true,
                        xs: 2,
                        fontWeight: '700',
                        padding: paddingLabel,
                        textAlign: 'center',
                        marginLeft: '21px'
                    },
                    {
                        key: 'NodataP716',
                        xs: 4,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'TestviaSmartPositioner',
                        label: 'Test via Smart Positioner',
                        isLabel: true,
                        xs: 2,
                        fontWeight: '700',
                        padding: paddingLabel,
                        textAlign: 'center',
                        marginLeft: '22px'
                    }]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F751AltValveInitiatedPartialStrokeTest',
                        label: 'Initiated Partial Stroke Test',
                        value: conditionalValueSelector(isAltSolenoidSectionDisabled, '', ValidateField(formData.F751AltValveInitiatedPartialStrokeTest)),
                        isRequired: !isAltSolenoidSectionDisabled,
                        isDisabled: isAltSolenoidSectionDisabled,
                        readOnly: isAltSolenoidSectionDisabled,
                        isDropdown: !isAltSolenoidSectionDisabled,
                        placeholder: placeholderSelectYes,
                        options: [{ name: placeholderSelectYes, value: '' },
                        { name: 'YES', value: 'YES' }],
                        xs: 6,
                        width: conditionalValueSelector(isAltSolenoidSectionDisabled, '55.1%', '56.4%'),
                        labelWidth: '43%',
                        borderBottom: conditionalValueSelector(isSolenoidTestingDisabled, '', '0'),
                        marginLeft: '7px',
                        textAlign: 'center',
                        inputTextAlign: 'center',
                        height: conditionalValueSelector((formData.F759AsLeftTestPstMethod === 'Smart Positioner'), '1.1rem', '1.5rem')
                    },
                    {
                        key: 'F755AltSmartInitiatedPartialStrokeTest',
                        label: 'Initiated Partial Stroke Test',
                        value: conditionalValueSelector(isAltSmartSectionDisabled, '', ValidateField(formData.F755AltSmartInitiatedPartialStrokeTest)),
                        isRequired: !isAltSmartSectionDisabled,
                        isDisabled: isAltSmartSectionDisabled,
                        readOnly: isAltSmartSectionDisabled,
                        isDropdown: !isAltSmartSectionDisabled,
                        placeholder: placeholderSelectYes,
                        options: [{ name: placeholderSelectYes, value: '' },
                        { name: 'YES', value: 'YES' }],
                        xs: 6,
                        width: conditionalValueSelector(isAltSmartSectionDisabled, '52.7%', '53.4%'),
                        labelWidth: '43.5%',
                        borderBottom: conditionalValueSelector(isSmartTestingDisabled, '', '0'),
                        marginLeft: '7.8px',
                        textAlign: 'center',
                        borderRight: '0',
                        marginRight: '0.47rem',
                        direction: 'rtl',
                        inputTextAlign: 'center',
                        height: conditionalValueSelector((formData.F759AsLeftTestPstMethod === solenoidValveLabel), '1.1rem', '1.5rem')
                    }
                ]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F752AltValveMeasuredPartialTravelAmount',
                        label: 'Measured Partial Travel Amount (%)',
                        value: conditionalValueSelector(isSolenoidTestingDisabled, '', ValidateField(formData.F752AltValveMeasuredPartialTravelAmount)),
                        isRequired: !isSolenoidTestingDisabled,
                        isDisabled: isSolenoidTestingDisabled,
                        readOnly: isSolenoidTestingDisabled,
                        xs: 6,
                        width: '55.2%',
                        labelWidth: '43.3%',
                        type: 'number',
                        maxLength: '10',
                        marginLeft: '6px',
                        textAlign: 'center',
                        height: '1.1rem',
                    },
                    {
                        key: 'F756AltSmartMeasuredPartialTravelAmount',
                        label: 'Measured Partial Travel Amount (%)',
                        value: conditionalValueSelector(isSmartTestingDisabled, '', ValidateField(formData.F756AltSmartMeasuredPartialTravelAmount)),
                        isRequired: !isSmartTestingDisabled,
                        isDisabled: isSmartTestingDisabled,
                        readOnly: isSmartTestingDisabled,
                        xs: 6,
                        borderRight: '0',
                        inputMarginRight: '4px',
                        marginRight: '0.5rem',
                        width: '55%',
                        labelWidth: '47%',
                        textAlign: 'center',
                        textAlignLabel: 'end',
                        height: '1.1rem',
                        marginLeft: '0',
                        type: 'number',
                        maxLength: '10',
                    }]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F753AltValveTravelTimeToTargetPosition',
                        label: 'Travel Time to Target Position (sec)',
                        value: conditionalValueSelector(isSolenoidTestingDisabled, '', ValidateField(formData.F753AltValveTravelTimeToTargetPosition)),
                        isDisabled: isSolenoidTestingDisabled,
                        readOnly: isSolenoidTestingDisabled,
                        xs: 6,
                        width: '55.2%',
                        labelWidth: '43.3%',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        borderTop: '0',
                        marginLeft: '6px',
                        textAlign: 'center',
                        height: '1.1rem',
                    },
                    {
                        key: 'F757AltSmartTravelTimeToTargetPosition',
                        label: 'Travel Time to Target Position (sec)',
                        value: conditionalValueSelector(isSmartTestingDisabled, '', ValidateField(formData.F757AltSmartTravelTimeToTargetPosition)),
                        isDisabled: isSmartTestingDisabled,
                        readOnly: isSmartTestingDisabled,
                        xs: 6,
                        borderRight: '0',
                        borderBottom: '0',
                        borderTop: '0',
                        inputMarginRight: '4px',
                        marginRight: '0.5rem',
                        width: '55%',
                        labelWidth: '47%',
                        direction: 'rtl',
                        textAlign: 'center',
                        height: '1.1rem',
                        marginLeft: '0',
                        type: 'number',
                        maxLength: '10',
                    }]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'F754AltValvePartialStrokeTestResult',
                        label: 'Partial Stroke Test Result',
                        value: conditionalValueSelector(isSolenoidTestingDisabled, '', ValidateField(formData.F754AltValvePartialStrokeTestResult)),
                        isRequired: !isSolenoidTestingDisabled,
                        isDisabled: isSolenoidTestingDisabled,
                        readOnly: isSolenoidTestingDisabled,
                        isDropdown: !isSolenoidTestingDisabled,
                        placeholder: placeholderStrokeTestResult,
                        options: [{ name: placeholderStrokeTestResult, value: '' },
                        { name: 'Successful', value: 'Successful' },
                        { name: 'Abnormal', value: 'Abnormal' },
                        { name: 'Aborted', value: 'Aborted' }],
                        xs: 6,
                        labelWidth: '43%',
                        marginLeft: '7px',
                        textAlign: 'center',
                        inputTextAlign: 'center',
                        width: conditionalValueSelector(isSolenoidTestingDisabled, '55.1%', '56.4%'),
                    },
                    {
                        key: 'F758AltSmartPartialStrokeTestResult',
                        label: 'Partial Stroke Test Result',
                        value: conditionalValueSelector(isSmartTestingDisabled, '', ValidateField(formData.F758AltSmartPartialStrokeTestResult)),
                        isRequired: !isSmartTestingDisabled,
                        isDisabled: isSmartTestingDisabled,
                        readOnly: isSmartTestingDisabled,
                        isDropdown: !isSmartTestingDisabled,
                        placeholder: placeholderStrokeTestResult,
                        options: [{ name: placeholderStrokeTestResult, value: '' },
                        { name: 'Successful', value: 'Successful' },
                        { name: 'Abnormal', value: 'Abnormal' },
                        { name: 'Aborted', value: 'Aborted' }],
                        xs: 6,
                        width: conditionalValueSelector(isSmartTestingDisabled, '52.4%', '53.4%'),
                        labelWidth: '43.5%',
                        marginLeft: '7.8px',
                        textAlign: 'center',
                        borderRight: '0',
                        marginRight: '0.47rem',
                        direction: 'rtl',
                        inputTextAlign: 'center',
                        height: conditionalValueSelector((formData.F759AsLeftTestPstMethod === solenoidValveLabel), '1.24rem', '1.5rem')
                    }]
            },
            {
                key: 7,
                fields: [
                    {
                        key: 'ALTNodataP716',
                        xs: 6,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'F81AsLeftResult',
                        label: 'As-Left Result',
                        value: conditionalValueSelector((altDisabled), '', AsLeftTestTableP716Formula(formData)),
                        isCalculatedNew: !(altDisabled),
                        isDisabled: altDisabled,
                        readOnly: true,
                        xs: 6,
                        fontWeight: '700',
                        borderRight: '0',
                        inputMarginRight: '4px',
                        marginRight: '0.5rem',
                        width: '55%',
                        labelWidth: '47%',
                        direction: 'rtl',
                        textAlign: 'center',
                        height: '1.1rem',
                        marginLeft: '0',
                        isLabelBold: true,
                        borderTop: conditionalValueSelector(formData.F755AltSmartInitiatedPartialStrokeTest === 'YES', '0', ''),
                        marginBottom: '4px',
                        borderBottom: '0'
                    }]
            }
        ]
    };
};
