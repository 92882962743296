import { conditionalValueSelector } from 'helpers/Validation';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { AftLowAlarmTestResultFormula, AftHighAlarmTestResultFormula, AftBeamblockTestResultFormula, AsFoundResultFormula } from './M67.formula';
import { AlarmTestResult } from 'journeys/portal/Forms/Sections.constants';

export const hmiStatusPlaceholder = 'Select HMI Status';
export const hmiStatusLabel = 'HMI Status';
export const AsFoundTestTableSectionDataM67 = (selectedTag, formData) => {
    const lowAlarmDiscardCheck = formData.F822AftLowAlarmTest === AlarmTestResult.DISCARD;
    const highAlarmDiscardCheck = formData.F824AftHighAlarmTest === AlarmTestResult.DISCARD;
    const beamBlockDiscardCheck = formData.F826AftBeamBlockTest === AlarmTestResult.DISCARD;
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'As-FoundTest',
                    label: 'As-Found Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F822AftLowAlarmTest',
                        label: 'Low Alarm Test',
                        value: conditionalValueSelector(highAlarmDiscardCheck, AlarmTestResult.PERFORM, formData.F822AftLowAlarmTest),
                        isRequired: true,
                        isDropdown: !highAlarmDiscardCheck,
                        readOnly: highAlarmDiscardCheck,
                        placeholder: 'Select Low Alarm Test',
                        options: [{ name: 'Select Low Alarm Test', value: '' },
                        { name: 'DISCARD', value: 'DISCARD' },
                        { name: AlarmTestResult.PERFORM, value: AlarmTestResult.PERFORM }],
                        labelWidth: '51.2%',
                        marginLeft: '0.5rem',
                        inputTextAlign: 'center',
                        xs: 5,
                        textAlign: 'center',
                        width: conditionalValueSelector(highAlarmDiscardCheck, '47.2%', '48.8%')
                    }
                ]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F374AftAppliedLowAlarmTestFilter1',
                        label: 'Applied Low Alarm Test Filter Type',
                        value: conditionalValueSelector(lowAlarmDiscardCheck, '', formData.F374AftAppliedLowAlarmTestFilter1),
                        xs: 5,
                        isDisabled: lowAlarmDiscardCheck,
                        readOnly: lowAlarmDiscardCheck,
                        padding: '0 0 0 8px',
                        labelWidth: '51.2%',
                        width: '47.2%',
                        marginLeft: '0.5rem',
                        textAlign: 'center',
                        height: '1.2rem',
                        borderBottom: '0',
                        borderTop: '0'
                    }
                ]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F372AftHmistatus1',
                        label: hmiStatusLabel,
                        value: conditionalValueSelector(lowAlarmDiscardCheck, '', formData.F372AftHmistatus1),
                        isDropdown: !lowAlarmDiscardCheck,
                        isRequired: !lowAlarmDiscardCheck,
                        isDisabled: lowAlarmDiscardCheck,
                        readOnly: lowAlarmDiscardCheck,
                        placeholder: hmiStatusPlaceholder,
                        options: [{ name: hmiStatusPlaceholder, value: '' },
                        { name: 'NORMAL', value: 'NORMAL' },
                        { name: 'LOW ALARM', value: 'LOW ALARM' },
                        { name: 'FAULT', value: 'FAULT' }],
                        labelWidth: '51.2%',
                        marginLeft: '0.5rem',
                        inputTextAlign: 'center',
                        xs: 5,
                        marginBottom: '15px',
                        width: conditionalValueSelector(lowAlarmDiscardCheck, '47%', '48.8%'),
                        height: conditionalValueSelector(lowAlarmDiscardCheck, '1.1rem', '1.5rem'),
                    },
                    {
                        key: 'F823AftLowAlarmTestResult',
                        value: conditionalValueSelector(lowAlarmDiscardCheck, '',
                            AftLowAlarmTestResultFormula(formData)),
                        isDisabled: lowAlarmDiscardCheck,
                        readOnly: true,
                        isCalculatedNew: true,
                        width: '100%',
                        labelWidth: '0',
                        xs: 2,
                        textAlign: 'center',
                        height: '1.1rem',
                        direction: 'rtl',
                        borderLeft: '0'
                    }
                ]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F824AftHighAlarmTest',
                        label: 'High Alarm Test',
                        value: conditionalValueSelector(lowAlarmDiscardCheck, AlarmTestResult.PERFORM,
                            formData.F824AftHighAlarmTest),
                        isRequired: true,
                        isDropdown: !lowAlarmDiscardCheck,
                        placeholder: 'Select High Alarm Test',
                        options: [{ name: 'Select High Alarm Test', value: '' },
                        { name: 'DISCARD', value: 'DISCARD' },
                        { name: AlarmTestResult.PERFORM, value: AlarmTestResult.PERFORM }],
                        labelWidth: '51.2%',
                        marginLeft: '0.5rem',
                        inputTextAlign: 'center',
                        xs: 5,
                        readOnly: lowAlarmDiscardCheck,
                        textAlign: 'center',
                        width: conditionalValueSelector(lowAlarmDiscardCheck, '47.2%', '48.8%')
                    }
                ]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'F375AftAppliedHighAlarmTestFilter2',
                        label: 'Applied High Alarm Test Filter Type',
                        value: conditionalValueSelector(highAlarmDiscardCheck, '',
                            formData.F375AftAppliedHighAlarmTestFilter2),
                        xs: 5,
                        padding: '0 0 0 8px',
                        labelWidth: '51.2%',
                        width: '47.2%',
                        marginLeft: '0.5rem',
                        textAlign: 'center',
                        height: '1.2rem',
                        borderBottom: '0',
                        borderTop: '0',
                        isDisabled: highAlarmDiscardCheck,
                        readOnly: highAlarmDiscardCheck,
                    }
                ]
            },
            {
                key: 7,
                fields: [
                    {
                        key: 'F373AftHmistatus2',
                        label: hmiStatusLabel,
                        value: conditionalValueSelector(highAlarmDiscardCheck, '', formData.F373AftHmistatus2),
                        isRequired: !highAlarmDiscardCheck,
                        isDropdown: !highAlarmDiscardCheck,
                        placeholder: hmiStatusPlaceholder,
                        options: [{ name: hmiStatusPlaceholder, value: '' },
                        { name: 'NORMAL', value: 'NORMAL' },
                        { name: 'HIGH ALARM', value: 'HIGH ALARM' },
                        { name: 'FAULT', value: 'FAULT' }],
                        labelWidth: '51.2%',
                        marginLeft: '0.5rem',
                        inputTextAlign: 'center',
                        xs: 5,
                        marginBottom: '15px',
                        readOnly: highAlarmDiscardCheck,
                        isDisabled: highAlarmDiscardCheck,
                        width: conditionalValueSelector(highAlarmDiscardCheck, '47%', '48.8%'),
                        height: conditionalValueSelector(highAlarmDiscardCheck, '1.1rem', '1.5rem'),
                    },
                    {
                        key: 'F825AftHighAlarmTestResult',
                        value: conditionalValueSelector(highAlarmDiscardCheck, '',
                            AftHighAlarmTestResultFormula(formData)),
                        readOnly: true,
                        isDisabled: highAlarmDiscardCheck,
                        width: '100%',
                        labelWidth: '0',
                        isCalculatedNew: true,
                        xs: 2,
                        textAlign: 'center',
                        height: '1.12rem',
                        direction: 'rtl',
                        borderLeft: '0'
                    }
                ]
            },
            {
                key: 8,
                fields: [
                    {
                        key: 'F826AftBeamBlockTest',
                        label: 'Beam Block Test',
                        value: formData.F826AftBeamBlockTest,
                        isRequired: true,
                        isDropdown: true,
                        placeholder: 'Select Beam Block Test',
                        options: [{ name: 'Select Beam Block Test', value: '' },
                        { name: 'DISCARD', value: 'DISCARD' },
                        { name: AlarmTestResult.PERFORM, value: AlarmTestResult.PERFORM }],
                        labelWidth: '51.2%',
                        marginLeft: '0.5rem',
                        inputTextAlign: 'center',
                        xs: 5,
                        borderBottom: conditionalValueSelector(beamBlockDiscardCheck, '', '0'),
                    }]
            },
            {
                key: 9,
                fields: [{
                    key: 'F827AftHmiStatus',
                    label: hmiStatusLabel,
                    value: conditionalValueSelector((beamBlockDiscardCheck), '', formData.F827AftHmiStatus),
                    isRequired: !(beamBlockDiscardCheck),
                    isDropdown: !(beamBlockDiscardCheck),
                    placeholder: hmiStatusPlaceholder,
                    options: [{ name: hmiStatusPlaceholder, value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'LOW ALARM', value: 'LOW ALARM' },
                    { name: 'FAULT', value: 'FAULT' }],
                    xs: 5,
                    inputTextAlign: 'center',
                    labelWidth: '51.2%',
                    marginLeft: '0.5rem',
                    marginBottom: '0.26rem',
                    borderBottom: '0',
                    isDisabled: beamBlockDiscardCheck,
                    readOnly: beamBlockDiscardCheck,
                    width: conditionalValueSelector(beamBlockDiscardCheck, '47%', '48.8%'),
                    height: conditionalValueSelector(beamBlockDiscardCheck, '1.25rem', '1.5rem'),
                },
                {
                    key: 'F828AftBeamBlockTestResult',
                    value: conditionalValueSelector((beamBlockDiscardCheck),
                        '', AftBeamblockTestResultFormula(formData)),
                    readOnly: true,
                    width: '100%',
                    labelWidth: '0',
                    isCalculatedNew: true,
                    xs: 2,
                    textAlign: 'center',
                    height: '1.25rem',
                    direction: 'rtl',
                    borderLeft: '0',
                    borderBottom: '0',
                    isDisabled: beamBlockDiscardCheck,
                },
                {
                    key: 'F102AftResultLabel',
                    label: 'As-Found Result',
                    xs: 3,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F102AftResult',
                    value: AsFoundResultFormula(formData),
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0',
                    isCalculatedNew: true,
                    xs: 2,
                    textAlign: 'center',
                    height: '1.25rem',
                    borderRight: '0',
                    borderBottom: '0',
                    marginRight: '0.5rem',
                    direction: 'rtl',
                    marginBottom: '4px',
                    inputMarginRight: '4px',
                    isLabelBold: true,
                    fontWeight: '700'
                }
                ]
            },
        ]
    };
};
