export const VisualInspectSectionDataP63andP632 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect instrument tagging, instrument mounting/manifold conditions, the state of instrument wiring 
                connection/terminations, the general instrument condition, the thermowell vent port, for excessive strain on the 
                cable for a remote mount transmitter, for general signs deterioration as well as for signs of process fluid weeping/leaking`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]

});
