import { AsFoundResultFormula } from './M612.formulas';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const AsFoundTestTableSectionDataM612 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'As-Found Test',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F407AftTestMethodAppliedLabel',
                    label: 'Test method applied',
                    xs: 4,
                    labelWidth: '100%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F407AftTestMethodApplied',
                    value: formData.F407AftTestMethodApplied,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'Select YES or NO',
                    options: [{ name: 'Select YES or NO', value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    xs: 2,
                    inputTextAlign: 'center',
                    labelWidth: '0',
                    width: '100%',
                    borderBottom: '0'
                }]
        },
        {
            key: 3,
            fields: [{
                key: 'F372AftHmistatus1Label',
                label: 'HMI Status',
                xs: 4,
                labelWidth: '100%',
                padding: '0 0 0 8px',
                isLabel: true,
                fontSize: '9pt',
                fontWeight: '600'
            },
            {
                key: 'F372AftHmistatus1',
                value: formData.F372AftHmistatus1,
                isRequired: true,
                isDropdown: true,
                placeholder: 'Select HMI Status',
                options: [{ name: 'Select HMI Status', value: '' },
                { name: 'NORMAL', value: 'NORMAL' },
                { name: 'ALARM', value: 'ALARM' },
                { name: 'FAULT', value: 'FAULT' }],
                xs: 2,
                inputTextAlign: 'center',
                labelWidth: '0',
                width: '100%',
                borderBottom: '0'
            },
            {
                key: 'F102AftResultLabel',
                label: 'As-Found Result',
                xs: 4,
                isLabel: true,
                fontWeight: '700',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F102AftResult',
                value: AsFoundResultFormula(formData),
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                isCalculatedNew: true,
                xs: 2,
                textAlign: 'center',
                height: '1.24rem',
                borderRight: '0',
                borderBottom: '0',
                direction: 'rtl',
                marginBottom: '4px',
                inputMarginRight: '4px',
                isLabelBold: true,
                fontWeight: '700'
            }
            ]
        },
    ]
});
