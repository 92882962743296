import React from 'react';
import { connect } from 'react-redux';
import { DatePicker } from 'elements/';
import PropTypes from 'prop-types';
import SearchBox from 'elements/SearchBox/SearchBox';
import { DateSelection, DatePickerStyle, DateFilter, AllDateButton, DateButton } from './DateSection.styled';
import { setDatePicker, searchMODescription } from '../MOSelection.actions';
import { Grid } from '@material-ui/core';

const DateSection = (props) => {
    const { startDate, endDate, setMODatePicker, searchCriteria, triggerSearch } = props;

    const handleSearch = async (searchValue, name) => {
        await triggerSearch(searchValue, name);
    };

    const handleSelection = (event) => {
        const keyName = Object.keys(event);
        if (keyName[0] === 'startDate') {
            setMODatePicker('Custom', event.startDate, endDate);
        } else if (keyName[0] === 'finishDate') {
            setMODatePicker('Custom', startDate, event.finishDate);
        }
    };

    const dateFilterHandler = (e) => {
        setMODatePicker(e.target.name);
    };

    return (
        <DateSelection>
            <Grid container>
                <Grid item md={7} lg={8}>
                    <DatePickerStyle>
                        <div>
                            <DatePicker
                                name="startDate"
                                label="Start Date:"
                                value={startDate}
                                fontSize="11pt"
                                width="200px"
                                onChange={(event) => handleSelection({ startDate: event })}
                            />
                        </div>
                        <div>
                            <DatePicker
                                name="finishDate"
                                label="Finish Date:"
                                width="200px"
                                value={endDate}
                                fontSize="11pt"
                                onChange={(event) => handleSelection({ finishDate: event })}
                            />
                        </div>
                    </DatePickerStyle>
                    <Grid container style={{ marginTop: '28px' }}>
                        <Grid item sm={2} lg={2} xl={1}>
                            <SearchBox
                                value={searchCriteria.moNumber}
                                handleSearch={handleSearch}
                                name="moNumber"
                                height="27px"
                                placeholder="MO Number"
                                fullWidth
                                fontSize="7.5pt"
                                padding="0px 5px"
                                borderColor="#498205"
                            />
                        </Grid>

                        <Grid item sm={6} lg={5} xl={3}>
                            <SearchBox
                                value={searchCriteria.moDescription}
                                name="moDescription"
                                handleSearch={handleSearch}
                                fullWidth
                                height="27px"
                                placeholder="MO Description"
                                fontSize="7.5pt"
                                padding="0px 5px"
                                borderColor="#498205"
                            />
                        </Grid>

                        <Grid item sm={4} lg={3} xl={2}>
                            <SearchBox
                                value={searchCriteria.functionalLocation}
                                handleSearch={handleSearch}
                                name="functionalLocation"
                                placeholder="Functional Location"
                                height="27px"
                                fullWidth
                                fontSize="7.5pt"
                                padding="0px 5px"
                                borderColor="#498205"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={5} lg={4}>
                    <DateFilter>
                        <AllDateButton>
                            <button name="All" type="submit" onClick={dateFilterHandler}>
                                ALL
                            </button>
                        </AllDateButton>
                        <DateButton>
                            <button name="Today" type="submit" onClick={dateFilterHandler}>
                                Today
                            </button>
                            <button name="Today - 7 Days" type="submit" onClick={dateFilterHandler}>
                                Today - 7 Days
                            </button>
                            <button name="Today - 30 Days" type="submit" onClick={dateFilterHandler}>
                                Today - 30 Days
                            </button>
                        </DateButton>
                        <DateButton>
                            <button name="Tomorrow" type="submit" onClick={dateFilterHandler}>
                                Tomorrow
                            </button>
                            <button name="Today + 7 Days" type="submit" onClick={dateFilterHandler}>
                                Today + 7 Days
                            </button>
                            <button name="Today + 30 Days" type="submit" onClick={dateFilterHandler}>
                                Today + 30 Days
                            </button>
                        </DateButton>
                    </DateFilter>
                </Grid>
            </Grid>
        </DateSelection>

    );
};

const mapStateToProps = ({ MOSelection }) => ({
    startDate: MOSelection.startDate,
    endDate: MOSelection.endDate,
    moDataAll: MOSelection.moDataAll,
    searchCriteria: MOSelection.searchCriteria,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    setMODatePicker: (type, customStartDate, customEndDate) => dispatch(setDatePicker(type, customStartDate, customEndDate)),
    triggerSearch: (obj, name) => dispatch(searchMODescription(obj, name)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DateSection);

DateSection.propTypes = {
    endDate: PropTypes.any,
    searchCriteria: PropTypes.object,
    setMODatePicker: PropTypes.func,
    startDate: PropTypes.any,
    triggerSearch: PropTypes.func
};
