import React, { useState } from 'react';
import 'elements/React-Dropdown/style.css';
import PropTypes from 'prop-types';
import { StyledLabel } from './Dropdown/Dropdown.styled';
import Select from 'react-select';
import { bgColorWithData } from './Inputs.styled';

export const SearchableDropdown = (props) => {
    const { id, label, name, handleChange, value, options = [],
        isRequired, labelWidth, width, marginRight, readOnly, readOnlyDps, fontFamily,
        marginLeft, marginBottom, marginTop, direction, textAlign, borderTop = true,
        fontWeight, borderBottom = true, alignItems, selectMarginLeft, height, menuOnTop, topPaddingNoOptions,
        topPadding, flexDirection, testEqDisplay, labelFontSize, labelColor, borderLeft, placeholder, paddingLeft } = props;
    const [inputVal, setInputVal] = useState('');
    const valObj = value ? { label: value, value } : '';
    const borderStyle = '2px solid grey !important';
    const backgroundColor = () => {
        if (isRequired && value) {
            return bgColorWithData;
        }
        if (isRequired) {
            return 'yellow';
        }
        return 'white';
    };
    const customStyles = {
        container: styles => ({
            ...styles,
            width,
            marginLeft: selectMarginLeft || '',
            paddingTop: topPadding || '0',
        }),
        control: styles => ({
            ...styles,
            borderTop: (borderTop && !readOnly) ? borderStyle : '0',
            borderLeft: (borderLeft === '0' || readOnly) ? '0' : borderStyle,
            borderRight: readOnly ? '0' : borderStyle,
            borderBottom: (borderBottom && !readOnly) ? borderStyle : '0',
            boxShadow: '0 !important',
            fontSize: '9pt',
            height: height || '1.7rem',
            paddingTop: '0',
            minHeight: '1rem',
            borderRadius: '0',
            outline: 'none',
            backgroundColor: backgroundColor()
        }),
        valueContainer: (styles, state) => ({
            ...styles,
            paddingLeft: paddingLeft ?? '5px',
          }),
        singleValue: styles => ({
            ...styles,
            color: 'black',
        }),
        option: (styles, state) => ({
            ...styles,
            fontSize: '9pt',
            backgroundColor: state.isSelected && '#498205',
            '&:hover': {
                backgroundColor: '#DCDCDC'
            },
            paddingLeft: paddingLeft ?? '5px'
        }),
        noOptionsMessage: (styles, state) => ({
            ...styles,
            fontSize: '9pt',
            paddingTop: topPaddingNoOptions ?? '',
            height: height || '1.7rem',
        }),
        menu: styles => ({
            ...styles,
            border: borderStyle,
            borderRadius: '0',
            maxHeight: '185px',
            overflow: 'hidden',
            backgroundColor: backgroundColor(),
            marginTop: '0',
            marginBottom: '0',
        }),
        menuList: styles => ({
            ...styles,
            maxHeight: '185px',
            paddingTop: '0',
            paddingBottom: '0',
        }),
        input: styles => ({
            ...styles,
            paddingTop: '0px',
            paddingBottom: '1px',
        }),
        clearIndicator: styles => ({
            ...styles,
            padding: '0',
            color: 'black'
        }),
        dropdownIndicator: styles => ({
            ...styles,
            padding: '0',
            display: readOnly ? 'none' : 'block',
            color: 'black'
        }),
        indicatorSeparator: styles => ({
            ...styles,
            display: 'none',
        })

    };
    return (
        <div style={{ display: testEqDisplay, alignItems: alignItems || 'center', flexDirection: flexDirection || '', marginTop, marginBottom }}>
            <StyledLabel
                textAlign={textAlign}
                labelWidth={labelWidth}
                marginLeft={marginLeft}
                marginRight={marginRight}
                direction={direction}
                fontWeight={fontWeight}
                labelColor={labelColor}
                labelFontSize={labelFontSize}
                fontFamily={fontFamily}
            >
                {label}
            </StyledLabel>
            <Select
                id={id}
                value={valObj}
                inputValue={inputVal}
                isDisabled={readOnly || readOnlyDps}
                onChange={(e, trigger) => {
                    trigger.action === 'clear' && setInputVal('');
                    handleChange({ [name]: e?.value });
                }}
                isClearable={valObj.value || inputVal}
                placeholder={placeholder ?? ''}
                styles={customStyles}
                options={[...options.map(x => ({ label: x.name, value: x.value }))]}
                onInputChange={(e) => setInputVal(e)}
                menuPlacement={menuOnTop ? 'top' : 'auto'}
            />
        </div>
    );
};

SearchableDropdown.propTypes = {
    alignItems: PropTypes.string,
    borderBottom: PropTypes.bool,
    borderLeft: PropTypes.string,
    borderTop: PropTypes.bool,
    direction: PropTypes.string,
    flexDirection: PropTypes.string,
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.string,
    handleChange: PropTypes.func,
    height: PropTypes.string,
    id: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    labelColor: PropTypes.string,
    labelFontSize: PropTypes.string,
    labelWidth: PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    marginTop: PropTypes.string,
    menuOnTop: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.array,
    paddingLeft: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    readOnlyDps: PropTypes.bool,
    selectMarginLeft: PropTypes.string,
    testEqDisplay: PropTypes.string,
    textAlign: PropTypes.string,
    topPadding: PropTypes.string,
    topPaddingNoOptions: PropTypes.string,
    value: PropTypes.any,
    width: PropTypes.string,
};
