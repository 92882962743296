import { checkURVGreaterThanLRV } from 'helpers/Validation';
import { HMISectionCulatedRangeFormula } from '../P78/P78.formulas';
import { EngineeringUnit } from 'journeys/portal/Forms/Sections.constants';

export const paddingLabel = '4px 5px 0 0';
const labelWidth = '98%';
export const HMISectionDataC11C13C132C14 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F100HmireadingPriortoTestLabel',
                label: 'HMI Reading Prior to Test',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel
            },
            {
                key: 'F100HmireadingPriortoTest',
                value: formData.F100HmireadingPriortoTest,
                xs: 2,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0%',
                height: '1.2rem',
                textAlign: 'center',
                borderBottom: '0'
            },
            {
                key: 'F20ProcessGaugeReadingPtLabel',
                label: 'Process Gauge Reading Prior to Test',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
                labelWidth,
            },
            {
                key: 'F20ProcessGaugeReadingPt',
                value: formData.F20ProcessGaugeReadingPt,
                xs: 2,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                height: '1.2rem',
                labelWidth: '0%',
                width: '100%',
                textAlignLabel: 'end',
                textAlign: 'center',
                borderBottom: '0'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F21HmiLrvLabel',
                label: 'HMI LRV',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel
            },
            {
                key: 'F21HmiLrv',
                value: selectedTag.DateTested ? formData?.F21HmiLrv : formData?.F21HmiLrv ?? selectedTag?.TsprHmiLrv,
                type: 'number',
                maxLength: '10',
                isRequired: true,
                height: '1.2rem',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlign: 'center',
                borderBottom: '0',
                hasHmiError: !checkURVGreaterThanLRV(formData.F21HmiLrv, formData.F22HmiUrv)
            },
            {
                key: 'F22HmiUrvLabel',
                label: 'HMI URV',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
                labelWidth,
            },
            {
                key: 'F22HmiUrv',
                value: selectedTag.DateTested ? formData?.F22HmiUrv : formData?.F22HmiUrv ?? selectedTag?.TsprHmiUrv,
                xs: 2,
                type: 'number',
                maxLength: '10',
                isRequired: true,
                labelWidth: '0%',
                width: '100%',
                textAlignLabel: 'end',
                height: '1.2rem',
                textAlign: 'center',
                borderBottom: '0',
                hasHmiError: !checkURVGreaterThanLRV(formData.F21HmiLrv, formData.F22HmiUrv)
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F105HmicalculatedRangeLabel',
                label: 'HMI Calculated Range',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel
            },
            {
                key: 'F105HmicalculatedRange',
                value: HMISectionCulatedRangeFormula(formData.F21HmiLrv, formData.F22HmiUrv, formData.F115HmiengineeringUnits),
                xs: 2,
                isCalculatedNew: true,
                readOnly: true,
                inputWidth: '100%',
                labelWidth: '0%',
                borderTop: '0',
                textAlign: 'center',
                borderBottom: '0',
                isAutoGrow: true,
                marginRight: '0',
            },
            {
                key: 'F115HmiengineeringUnitsLabel',
                label: EngineeringUnit.HMI_ENGINEERING_UNIT_EU,
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                labelWidth,
                textAlign: 'right',
                padding: paddingLabel
            },
            {
                key: 'F115HmiengineeringUnits',
                value: selectedTag.DateTested ? formData?.F115HmiengineeringUnits : formData?.F115HmiengineeringUnits ?? selectedTag?.TsprHmiEngineeringUnits,
                isRequired: true,
                xs: 2,
                inputWidth: '100%',
                labelWidth: '0%',
                textAlignLabel: 'end',
                textAlign: 'center',
                maxLength: '14',
                direction: 'rtl',
                isAutoGrow: true,
                marginRight: '0'
            }]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F25InstrumentLrvLabel',
                    label: 'Instrument LRV',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F25InstrumentLrv',
                    value: selectedTag.DateTested ? formData?.F25InstrumentLrv : formData?.F25InstrumentLrv ?? selectedTag?.TsprInstrumentCalibratedLrv,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.3rem',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderTop: '0',
                    hasInstError: !checkURVGreaterThanLRV(formData.F25InstrumentLrv, formData.F26InstrumentUrv)
                },
                {
                    key: 'F26InstrumentUrvLabel',
                    label: 'Instrument URV',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                    labelWidth,
                },
                {
                    key: 'F26InstrumentUrv',
                    value: selectedTag.DateTested ? formData?.F26InstrumentUrv : formData?.F26InstrumentUrv ?? selectedTag?.InstrumentCalibratedUrv,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderBottom: '0',
                    hasInstError: !checkURVGreaterThanLRV(formData.F25InstrumentLrv, formData.F26InstrumentUrv)
                }]
        },
        {
            key: 5,
            fields: [
                {
                    key: 'F27InstrumentCalibratedRangeLabel',
                    label: 'Instrument Calibrated Range',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F27InstrumentCalibratedRange',
                    value: HMISectionCulatedRangeFormula(formData.F25InstrumentLrv, formData.F26InstrumentUrv, formData.F28InstrumentEngineeringUnit),
                    xs: 2,
                    readOnly: true,
                    isCalculatedNew: true,
                    inputWidth: '100%',
                    labelWidth: '0%',
                    borderTop: '0',
                    textAlign: 'center',
                    isAutoGrow: 'true',
                    marginRight: '0',
                },
                {
                    key: 'F28InstrumentEngineeringUnitLabel',
                    label: EngineeringUnit.INSTRUMENT_ENGINEERING_UNIT_EU,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                    labelWidth,
                },
                {
                    key: 'F28InstrumentEngineeringUnit',
                    value: selectedTag.DateTested ? formData?.F28InstrumentEngineeringUnit : formData?.F28InstrumentEngineeringUnit ?? selectedTag?.TsprInstrumentEngineeringUnits,
                    xs: 2,
                    textAlignLabel: 'end',
                    isRequired: true,
                    inputWidth: '100%',
                    labelWidth: '0%',
                    borderTop: '0',
                    textAlign: 'center',
                    maxLength: '17',
                    direction: 'rtl',
                    isAutoGrow: true,
                    marginRight: '0'
                }]
        }
    ]
});
