import { paddingAF } from '../../../AsLeftTestTableSection/Data/M71/M71.data';
import { MinSFFormula, MaxSFFormula, SFResultFormula } from './P721.formulas';

export const FoundTestTableSectionDataP721 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'ScaleFactor',
                label: 'Scale Factor Verification',
                isLabel: true,
                xs: 12,
                fontWeight: 700,
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'SpecifiedSF',
                label: 'Specified SF',
                isLabel: true,
                xs: 2,
                fontWeight: 700,
                labelWidth: '100%',
                padding: '5px 0 5px 8px',
                fontSize: '9pt',
                textAlign: 'center',
            },
            {
                key: 'Unit',
                label: 'Unit',
                isLabel: true,
                xs: 2,
                fontWeight: 700,
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'SFTolerance',
                label: 'SF Tolerance (%)',
                isLabel: true,
                xs: 2,
                fontWeight: 700,
                textAlign: 'center',
                labelWidth: '100%',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'MinSF',
                label: 'Min. SF',
                isLabel: true,
                xs: 2,
                fontWeight: 700,
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'MaxSF',
                label: 'Max. SF',
                isLabel: true,
                xs: 2,
                fontWeight: 700,
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'CalculatedSF',
                label: 'Calculated SF',
                isLabel: true,
                xs: 1,
                fontWeight: 700,
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'SFResult',
                label: 'SF Result',
                isLabel: true,
                xs: 1,
                fontWeight: 700,
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F743SpecifiedSf',
                value: formData.F743SpecifiedSf,
                xs: 2,
                width: '100%',
                labelWidth: '0',
                isRequired: true,
                textAlign: 'center',
                height: '1.2rem',
                type: 'number',
                maxLength: '10',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px',
                marginBottom: '4px',
            },
            {
                key: 'F744Unit',
                value: formData.F744Unit,
                isRequired: true,
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                marginBottom: '4px',
                maxLength: '10'
            },
            {
                key: 'F745SfTolerance',
                value: formData.F745SfTolerance,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                marginBottom: '4px',
            },
            {
                key: 'F746MinSf',
                value: MinSFFormula(formData),
                isCalculatedNew: true,
                readOnly: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                marginBottom: '4px',
            },
            {
                key: 'F747MaxSf',
                value: MaxSFFormula(formData),
                isCalculatedNew: true,
                readOnly: true,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                marginBottom: '4px',
            },
            {
                key: 'F748CalculatedSf',
                value: formData.F748CalculatedSf,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 1,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                marginBottom: '4px',
            },
            {
                key: 'F749SfResult',
                value: SFResultFormula(formData),
                isCalculatedNew: true,
                xs: 1,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
                marginBottom: '4px',
            }]
        }
    ]
});
