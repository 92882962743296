import { FoundResultFormula } from './P8141.formula';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';

export const AsFoundTestTableSectionDataP8141 = (formData) => ({
        rows: [{
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F779AftMovedTargetPositionActivateSwitch',
                label: 'Moved Target\'s Position to Activate Switch',
                value: formData.F779AftMovedTargetPositionActivateSwitch,
                xs: 6,
                isRequired: true,
                width: '48.5%',
                labelWidth: '51.5%',
                isDropdown: true,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                textAlign: 'center',
                inputTextAlign: 'center',
                borderBottom: '0',
            },
            {
                key: 'F780AftMovedTargetPositionDeactivateSwitch',
                label: 'Moved Target\'s Position to Deactivate Switch',
                value: formData.F780AftMovedTargetPositionDeactivateSwitch,
                isRequired: true,
                isDropdown: true,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                xs: 6,
                width: '48.5%',
                labelWidth: '57.5%',
                marginRight: '0.5rem',
                direction: 'rtl',
                rootMarginRight: '4px',
                textAlign: 'center',
                inputTextAlign: 'center',
                borderRight: '0',
                borderBottom: '0'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F781AftConfirmSwitchActivatesCorrectly',
                label: 'Confirm Switch Activates Correctly',
                value: formData.F781AftConfirmSwitchActivatesCorrectly,
                xs: 6,
                isRequired: true,
                width: '48.5%',
                labelWidth: '51.5%',
                isDropdown: true,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                textAlign: 'center',
                inputTextAlign: 'center'
            },
            {
                key: 'F782AftConfirmSwitchDeactivatesCorrectly',
                label: 'Confirm Switch Deactivates Correctly',
                value: formData.F782AftConfirmSwitchDeactivatesCorrectly,
                isRequired: true,
                isDropdown: true,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                xs: 6,
                width: '48.5%',
                labelWidth: '57.5%',
                marginRight: '0.5rem',
                direction: 'rtl',
                rootMarginRight: '4px',
                textAlign: 'center',
                inputTextAlign: 'center',
                borderRight: '0',
                borderBottom: '0'
            }]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'NoData1',
                    xs: 6,
                    isLabel: true,
                    height: '1.24rem',
                },
                {
                    key: 'F102AftResult',
                    label: 'As-Found Result',
                    isCalculatedNew: true,
                    xs: 6,
                    value: FoundResultFormula(formData),
                    readOnly: true,
                    labelWidth: '65.5%',
                    width: '54.2%',
                    marginRight: '0.5rem',
                    textAlign: 'center',
                    direction: 'rtl',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    height: '1.24rem',
                    borderBottom: '0',
                    marginBottom: '4px'
                }]
        }]
    });
