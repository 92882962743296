import { AutomaticActivationFireSuppression, FunctionTestType } from 'journeys/portal/Forms/Sections.constants';
import { calcHeight, Test1Result, Test2Result } from './M71.formulas';
import { conditionalValueSelector } from 'helpers/Validation';

export const dropdownPlaceHolder2 = 'Select YES or NO or N/A';
export const dropdownPlaceHolder3 = 'Select Discard or Perform';
export const dropdownPlaceholder = 'Select YES or NO';
export const AsFoundFireTestSectionDataM71 = (formData) => {
    const automaticDiscardCheck = formData.F664AftTst1AutomaticActivationFireSuppression === AutomaticActivationFireSuppression.DISCARD;
    const manualDiscardCheck = formData.F665AftTst2ManualActivationFireSuppression === AutomaticActivationFireSuppression.DISCARD;
    const dryTestCheck = formData.F663FunctionTestType === FunctionTestType.DRY_TEST;
    const borderRightCheck = manualDiscardCheck ? '' : '0';
    const borderBottomCheck = dryTestCheck ? '' : '0';
    const automaticDisableCheck = dryTestCheck || automaticDiscardCheck;
    const manualDisableCheck = dryTestCheck || manualDiscardCheck;
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'remark',
                label: `Remark: Most fire suppression systems have provisions for both automatic and manual operation of
         extinguishant discharge valves. Select either or both tests as per the maintenance requirements.`,
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '100%',
                padding: '15px 1px 5px 7px',
                fontSize: '8.5pt',
                color: '#ea7c34'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F664AftTst1AutomaticActivationFireSuppression',
                label: 'Test 1 - Automatic Activation of Fire Suppression',
                value: conditionalValueSelector(manualDiscardCheck,
                    'Perform', formData.F664AftTst1AutomaticActivationFireSuppression),
                xs: 6,
                isRequired: true,
                readOnly: manualDiscardCheck,
                labelWidth: conditionalValueSelector(manualDiscardCheck,
                    '59.4%', '64.5%'),
                width: conditionalValueSelector(manualDiscardCheck,
                    '39.5%', '43%'),
                height: conditionalValueSelector(manualDiscardCheck, '1.24rem', '1.5rem'),
                isDropdown: !manualDiscardCheck,
                placeholder: dropdownPlaceHolder3,
                options: [{ name: dropdownPlaceHolder3, value: '' },
                { name: AutomaticActivationFireSuppression.DISCARD, value: AutomaticActivationFireSuppression.DISCARD },
                { name: 'Perform', value: 'Perform' }],
                marginLeft: '8px',
                textAlign: 'center',
                inputTextAlign: 'center',
                borderBottom: conditionalValueSelector(automaticDiscardCheck, '', '0'),
            },
            {
                key: 'F665AftTst2ManualActivationFireSuppression',
                label: 'Test 2 - Manual Activation of Fire Suppression',
                value: conditionalValueSelector(automaticDiscardCheck,
                    'Perform', formData.F665AftTst2ManualActivationFireSuppression),
                isRequired: true,
                isDropdown: !automaticDiscardCheck,
                placeholder: dropdownPlaceHolder3,
                options: [{ name: dropdownPlaceHolder3, value: '' },
                { name: AutomaticActivationFireSuppression.DISCARD, value: AutomaticActivationFireSuppression.DISCARD },
                { name: 'Perform', value: 'Perform' }],
                xs: 6,
                labelWidth: conditionalValueSelector(automaticDiscardCheck, '66.5%', '64.5%'),
                marginRight: '0.5rem',
                direction: 'rtl',
                inputMarginRight: '4px',
                width: conditionalValueSelector(automaticDiscardCheck, '41.5%', '43.5%'),
                height: conditionalValueSelector(automaticDiscardCheck, '1.24rem', '1.5rem'),
                textAlign: 'center',
                inputTextAlign: 'center',
                borderRight: '0',
                borderBottom: borderRightCheck,
                readOnly: automaticDiscardCheck
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F666AftTst1ActivatedFireSuppressionSystem',
                label: 'Activated Fire Suppression System',
                value: conditionalValueSelector(automaticDiscardCheck, '',
                    formData.F666AftTst1ActivatedFireSuppressionSystem),
                xs: 6,
                isDropdown: !automaticDiscardCheck,
                isRequired: !automaticDiscardCheck,
                isDisabled: automaticDiscardCheck,
                readOnly: automaticDiscardCheck,
                labelWidth: conditionalValueSelector(automaticDiscardCheck,
                    '65.5%', '64.5%'),
                width: conditionalValueSelector(automaticDiscardCheck,
                    '40.9%', '43%'),
                inputMarginRight: '4px',
                height: conditionalValueSelector(automaticDiscardCheck,
                    '1.24rem', '1.5rem'),
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                inputTextAlign: 'center',
                borderBottom: '0',
            },
            {
                key: 'F667AftTst2ActivatedFireSuppressionSystem',
                label: 'Activated Fire Suppression System',
                value: conditionalValueSelector(manualDiscardCheck, '',
                    formData.F667AftTst2ActivatedFireSuppressionSystem),
                isDropdown: !manualDiscardCheck,
                isRequired: !manualDiscardCheck,
                isDisabled: manualDiscardCheck,
                readOnly: manualDiscardCheck,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                xs: 6,
                height: conditionalValueSelector(manualDiscardCheck,
                    '1.24rem', '1.5rem'),
                labelWidth: conditionalValueSelector(manualDiscardCheck,
                    '66.5%', '64.5%'),
                width: conditionalValueSelector(manualDiscardCheck,
                    '41.5%', '43.5%'),
                marginRight: conditionalValueSelector(manualDiscardCheck,
                    '4px', '0.5rem'),
                inputMarginRight: '4px',
                direction: 'rtl',
                inputTextAlign: 'center',
                borderBottom: '0',
                borderRight: borderRightCheck
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F668AftTst1ConfirmDischargeValveOperatesCorrectly',
                label: 'Confirm Discharge Valve Operates Correctly',
                value: conditionalValueSelector(automaticDiscardCheck, '', formData.F668AftTst1ConfirmDischargeValveOperatesCorrectly),
                xs: 6,
                isDropdown: !automaticDiscardCheck,
                isRequired: !automaticDiscardCheck,
                isDisabled: automaticDiscardCheck,
                readOnly: automaticDiscardCheck,
                labelWidth: conditionalValueSelector(automaticDiscardCheck,
                    '65.5%', '64.5%'),
                width: conditionalValueSelector(automaticDiscardCheck,
                    '40.9%', '43%'),
                height: calcHeight(automaticDiscardCheck, dryTestCheck),
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                inputTextAlign: 'center',
                inputMarginRight: '4px',
                borderBottom: borderBottomCheck,
            },
            {
                key: 'F669AftTst2ConfirmDischargeValveOperatesCorrectly',
                label: 'Confirm Discharge Valve Operates Correctly',
                value: conditionalValueSelector(manualDiscardCheck, '', formData.F669AftTst2ConfirmDischargeValveOperatesCorrectly),
                isDropdown: !manualDiscardCheck,
                isRequired: !manualDiscardCheck,
                isDisabled: manualDiscardCheck,
                readOnly: manualDiscardCheck,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                xs: 6,
                height: calcHeight(manualDiscardCheck, dryTestCheck),
                labelWidth: conditionalValueSelector(manualDiscardCheck, '66.5%', '64.5%'),
                width: conditionalValueSelector(manualDiscardCheck, '41.5%', '43.5%'),
                marginRight: conditionalValueSelector(manualDiscardCheck, '4px', '0.5rem'),
                inputMarginRight: '4px',
                direction: 'rtl',
                inputTextAlign: 'center',
                borderBottom: borderBottomCheck,
                borderRight: borderRightCheck
            }]
        },
        {
            key: 6,
            fields: [{
                key: 'F670AftTst1ConfirmExtinguishantReleasedProtectedArea',
                label: 'Confirm Extinguishant Released to Protected Area',
                value: automaticDisableCheck
                    ? '' : formData.F670AftTst1ConfirmExtinguishantReleasedProtectedArea,
                xs: 6,
                isDisabled: automaticDisableCheck,
                isRequired: !(automaticDisableCheck),
                readOnly: automaticDisableCheck,
                isDropdown: !(automaticDisableCheck),
                labelWidth: conditionalValueSelector(automaticDisableCheck,
                    '65.5%', '64.5%'),
                width: conditionalValueSelector(automaticDisableCheck,
                    '40.9%', '43%'),
                height: conditionalValueSelector(automaticDisableCheck, '1.24rem', '1.5rem'),
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                inputMarginRight: '4px',
                inputTextAlign: 'center',
                borderBottom: '0',
            },
            {
                key: 'F671AftTst2ConfirmExtinguishantReleasedProtectedArea',
                label: 'Confirm Extinguishant Released to Protected Area',
                value: conditionalValueSelector(manualDisableCheck, '',
                    formData.F671AftTst2ConfirmExtinguishantReleasedProtectedArea),
                isDisabled: manualDisableCheck,
                readOnly: manualDisableCheck,
                isRequired: !(manualDisableCheck),
                isDropdown: !(manualDisableCheck),
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                xs: 6,
                height: conditionalValueSelector(manualDisableCheck, '1.24rem', '1.5rem'),
                labelWidth: conditionalValueSelector(manualDisableCheck, '66.5%', '64.5%'),
                width: conditionalValueSelector(manualDisableCheck, '41.5%', '43.5%'),
                marginRight: conditionalValueSelector(manualDisableCheck, '4px', '0.5rem'),
                inputMarginRight: '4px',
                direction: 'rtl',
                inputTextAlign: 'center',
                borderBottom: '0',
                borderRight: manualDiscardCheck || borderBottomCheck
            }]
        },
        {
            key: 7,
            fields: [{
                key: 'F672AftTst1ConfirmCorrectHmiIndication',
                label: 'Confirm Correct HMI Indication',
                value: conditionalValueSelector(automaticDiscardCheck, '', formData.F672AftTst1ConfirmCorrectHmiIndication),
                xs: 6,
                isDropdown: !automaticDiscardCheck,
                isRequired: !automaticDiscardCheck,
                isDisabled: automaticDiscardCheck,
                readOnly: automaticDiscardCheck,
                labelWidth: conditionalValueSelector(automaticDiscardCheck, '65.5%', '64.5%'),
                width: conditionalValueSelector(automaticDiscardCheck, '40.9%', '43%'),
                height: conditionalValueSelector(automaticDiscardCheck, '1.24rem', '1.5rem'),
                placeholder: dropdownPlaceHolder2,
                options: [{ name: dropdownPlaceHolder2, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' },
                { name: 'N/A', value: 'N/A' }],
                marginLeft: '8px',
                inputTextAlign: 'center',
                borderBottom: '0',
                inputMarginRight: '4px',
            },
            {
                key: 'F673AftTst2ConfirmCorrectHmiIndication',
                label: 'Confirm Correct HMI Indication',
                value: conditionalValueSelector(manualDiscardCheck, '', formData.F673AftTst2ConfirmCorrectHmiIndication),
                isDropdown: !manualDiscardCheck,
                isRequired: !manualDiscardCheck,
                isDisabled: manualDiscardCheck,
                readOnly: manualDiscardCheck,
                placeholder: dropdownPlaceHolder2,
                options: [{ name: dropdownPlaceHolder2, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' },
                { name: 'N/A', value: 'N/A' }],
                xs: 6,
                height: conditionalValueSelector(manualDiscardCheck, '1.24rem', '1.5rem'),
                labelWidth: conditionalValueSelector(manualDiscardCheck,
                    '66.5%', '64.5%'),
                width: conditionalValueSelector(manualDiscardCheck,
                    '41.5%', '43.5%'),
                marginRight: conditionalValueSelector(manualDiscardCheck,
                    '4px', '0.5rem'),
                inputMarginRight: '4px',
                direction: 'rtl',
                inputTextAlign: 'center',
                borderBottom: '0',
                borderRight: borderRightCheck
            }]
        },
        {
            key: 8,
            fields: [{
                key: 'F674AftTst1ConfirmLampsFGMatrixPanel',
                label: 'Confirm Lamps on F&G Matrix Panel',
                value: conditionalValueSelector(automaticDiscardCheck,
                    '', formData.F674AftTst1ConfirmLampsFGMatrixPanel),
                xs: 6,
                isDropdown: !automaticDiscardCheck,
                isRequired: !automaticDiscardCheck,
                isDisabled: automaticDiscardCheck,
                readOnly: automaticDiscardCheck,
                labelWidth: conditionalValueSelector(automaticDiscardCheck,
                    '65.5%', '64.5%'),
                width: conditionalValueSelector(automaticDiscardCheck,
                    '40.9%', '43%'),
                height: conditionalValueSelector(automaticDiscardCheck, '1.24rem', '1.5rem'),
                placeholder: dropdownPlaceHolder2,
                options: [{ name: dropdownPlaceHolder2, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' },
                { name: 'N/A', value: 'N/A' }],
                marginLeft: '8px',
                inputMarginRight: '4px',
                inputTextAlign: 'center',
                borderBottom: '0',
            },
            {
                key: 'F675AftTst2ConfirmLampsFGMatrixPanel',
                label: 'Confirm Lamps on F&G Matrix Panel',
                value: conditionalValueSelector(manualDiscardCheck, '', formData.F675AftTst2ConfirmLampsFGMatrixPanel),
                isDropdown: !manualDiscardCheck,
                isRequired: !manualDiscardCheck,
                isDisabled: manualDiscardCheck,
                readOnly: manualDiscardCheck,
                placeholder: dropdownPlaceHolder2,
                options: [{ name: dropdownPlaceHolder2, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' },
                { name: 'N/A', value: 'N/A' }],
                xs: 6,
                height: conditionalValueSelector(manualDiscardCheck, '1.24rem', '1.5rem'),
                labelWidth: conditionalValueSelector(manualDiscardCheck, '66.5%', '64.5%'),
                width: conditionalValueSelector(manualDiscardCheck, '41.5%', '43.5%'),
                marginRight: conditionalValueSelector(manualDiscardCheck, '4px', '0.5rem'),
                inputMarginRight: '4px',
                direction: 'rtl',
                inputTextAlign: 'center',
                borderBottom: '0',
                borderRight: borderRightCheck
            }]
        },
        {
            key: 9,
            fields: [{
                key: 'F676AftTst1Result',
                label: 'Test 1 Result',
                value: automaticDiscardCheck ? '' : Test1Result(formData),
                xs: 6,
                isDisabled: automaticDiscardCheck,
                readOnly: true,
                isCalculatedNew: true,
                labelWidth: conditionalValueSelector(automaticDiscardCheck, '65.5%', '60.8%'),
                width: conditionalValueSelector(automaticDiscardCheck, '40.9%', '39.5%'),
                textAlign: 'center',
                height: '1.24rem',
                marginLeft: '8px',
                inputMarginRight: '4px',
                marginRight: conditionalValueSelector(automaticDiscardCheck, '0', '10px')
            },
            {
                key: 'F677AftTst2Result',
                label: 'Test 2 Result',
                isCalculatedNew: true,
                value: manualDiscardCheck ? '' : Test2Result(formData),
                xs: 6,
                isDisabled: manualDiscardCheck,
                readOnly: true,
                labelWidth: conditionalValueSelector(manualDiscardCheck, '66.5%', '65.5%'),
                width: conditionalValueSelector(manualDiscardCheck, '41.5%', '42.5%'),
                marginRight: conditionalValueSelector(manualDiscardCheck, '4px', '0.5rem'),
                textAlign: 'center',
                direction: 'rtl',
                borderRight: borderRightCheck,
                inputMarginRight: '4px',
                height: '1.24rem',
            }]
        }]
    };
};
