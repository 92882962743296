export const VisualInspectSectionDataC11C13C132C14 = (selectedTag, formData) => ({

    rows: [

        {
            key: 1,
            fields: [{
                key: 'F29VisuallyInspectInstrumentTaggingNote',
                label: `Visually inspect instrument tagging, general condition, signs of deterioration, tubing/fittings, 
                potential leak points, cable/gland integrity, electrical terminations, manifold assembly, etc.`,
                value: formData.F29VisuallyInspectInstrumentTaggingNote,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit',
                marginTop: '15px',
            }]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F32RetrieveDiagnosticMsg',
                    label: 'Retrieve any diagnostic messages',
                    value: formData.F32RetrieveDiagnosticMsg,
                    isMultiline: true,
                    xs: 12,
                    marginTop: '15px',
                    width: '50%',
                    inputWidth: '50%',
                    alignItems: 'inherit',
                }]
        }
    ]
});
