import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const TestResultSectionDataP66 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F143HmireadingAfterTestLabel',
                    label: 'HMI Reading After Test',
                    xs: 4,
                    labelWidth: '100%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F143HmireadingAfterTest',
                    value: formData.F143HmireadingAfterTest,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    inputMarginRight: '4px',
                    fontWeight: '700',
                },
                {
                    key: 'F85TestResultLabel',
                    label: 'Test Result',
                    xs: 3,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                },
                {
                    key: 'F85TestResult',
                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 3,
                }]
        },
    ]
});
