export const VisualInspectSectionDataP611 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F193VisuallyInspect',
            label: `Visually inspect field cabling, junction boxes, MCC cubicle for security of mounting, mechanical damage, corrosion and
             general deterioration. Check status indication lamps, buttons, switch positions, meter readings on MCC cubicle for any abnormalities.`,
            value: formData.F193VisuallyInspect,
            isRequired: true,
            isMultiline: true,
            xs: 12,
            width: '50%',
            inputWidth: '50%',
            alignItems: 'inherit',
            marginTop: '15px'
        }]
    }]
});
