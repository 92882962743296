import { AsLeftResultFormulaP615 } from './P615.formula';

export const AsLeftTestTableSectionDataP615 = (selectedTag, formData) => {
    const altDisabled = (formData?.F61AsFoundResult === 'PASS' || formData.F619AsLeftTestRequired === 'NO');
    return {
    rows: [{
        key: 1,
        fields: [{
            key: 'As-LeftTest',
            label: 'As-Left Test',
            isLabel: true,
            xs: 12,
            fontWeight: '700',
            padding: '5px 0 5px 8px'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'attention',
            label: `Attention: The following simple corrections may be performed by technician 
                        as part of this PM work order — repeat tripping solenoid valve against stiction, 
                        cleaning vent lines and ports.`,
            width: '0',
            marginLeft: '8px',
            color: '#ea7c34',
            borderBottom: '0',
            borderLeft: '0',
            borderTop: '0',
            borderRight: '0',
            labelWidth: '100%',
            inputMarginRight: '6px',
            fontSize: '8.5pt'
        },
        ]
    },
    {
        key: 3,
        fields: [{
            key: 'attention2',
            label: `The following complicated corrections should be performed by technician 
                        as part of follow-up corrective work order — solenoid valve replacements 
                        or overhaul for internal inspection.`,
            width: '0',
            marginLeft: '8px',
            color: '#ea7c34',
            borderBottom: '0',
            borderLeft: '0',
            borderTop: '0',
            borderRight: '0',
            labelWidth: '100%',
            inputMarginRight: '6px',
            fontSize: '8.5pt',
            marginBottom: '10px'
        },
        ]
    },
    {
        key: 4,
        fields: [{
            key: 'F575AltSolenoidValveActionUponTrip',
            label: 'Solenoid Valve Action Upon Trip',
            value: altDisabled ? '' : formData.F575AltSolenoidValveActionUponTrip,
            isRequired: !(altDisabled),
            isDisabled: altDisabled,
            readOnly: altDisabled,
            xs: 6,
            labelWidth: '51%',
            width: formData.F61AsFoundResult !== 'PASS' ? '49%' : '47.5%',
            isDropdown: !(altDisabled),
            placeholder: 'Select Valve Action',
            options: [{ name: 'Select Valve Action', value: '' },
            { name: 'Quick', value: 'Quick' },
            { name: 'Sticking', value: 'Sticking' }],
            marginLeft: '8px',
            inputTextAlign: 'center',
            borderBottom: '0'
        }]
    },
    {
        key: 5,
        fields: [{
            key: 'F576AltSolenoidValvePositionAfterTrip',
            label: 'Solenoid Valve Position After Trip',
            value: altDisabled ? '' : formData.F576AltSolenoidValvePositionAfterTrip,
            isDropdown: !(altDisabled),
            isRequired: !(altDisabled),
            isDisabled: altDisabled,
            readOnly: altDisabled,
            xs: 6,
            labelWidth: '51%',
            width: formData.F61AsFoundResult !== 'PASS' ? '49%' : '47.5%',
            height: !(altDisabled) ? '' : '1.26rem',
            marginLeft: '8px',
            placeholder: 'Select Open or Closed',
            options: [{ name: 'Select Open or Closed', value: '' },
            { name: 'Open', value: 'Open' },
            { name: 'Closed', value: 'Closed' }],
            inputTextAlign: 'center',
            marginBottom: '4px',
            borderBottom: '0',
        },
        {
            key: 'F81AsLeftResult',
            label: 'As-Left Result',
            value: altDisabled ? '' : AsLeftResultFormulaP615(formData),
            isDisabled: altDisabled,
            readOnly: altDisabled,
            isCalculatedNew: true,
            textAlign: 'center',
            height: '1.26rem',
            borderRight: '0',
            marginRight: '8px',
            direction: 'rtl',
            inputMarginRight: '4px',
            isLabelBold: true,
            fontWeight: '700',
            xs: 6,
            width: '50.2%',
            labelWidth: '49.8%',
            borderBottom: '0',
        }]
    }]
};
};
