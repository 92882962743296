import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';

export const trackErrors = (meta) => {
    if (navigator.onLine && meta?.messageType === MESSAGE_TYPE.ERROR) {
        window.appInsights?.trackException({
            error: meta?.message,
            exception: meta?.exception,
            severityLevel: SeverityLevel.Error,
            properties: { ...meta?.exception }
        });
    }
};

// to take care of alphanumeric and case insensitive sorting
export const customSort = (data) => data.sort((a, b) => a.toString().toLowerCase().localeCompare(b.toString().toLowerCase(), undefined, {
    numeric: true,
    sensitivity: 'base'
}));
