import { InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { DropdownWrapper, useStyles } from './DropdownInput.styled';
import { backgroundColor } from '../AdminScreen.helper';

const DropdownInput = ({ name, title, value, options, handleInputChange, required, handleBlur, errorMsg, disabled }) => {
    const classes = useStyles();
    const bgColor = backgroundColor(value, required);

    return (
        <DropdownWrapper>
            <div style={{ display: 'flex' }}>
                <InputLabel style={{ fontSize: '12px', color: 'black', paddingLeft: '3px', paddingBottom: '0', fontWeight: '600' }}>
                    {title}
                </InputLabel>
                {errorMsg && <span style={{ fontSize: '9px', color: 'rgb(255, 0, 0)', fontWeight: 'bold', right: '10%' }}>{errorMsg}</span>}
            </div>
            <Select
                variant="outlined"
                classes={{ root: classes.select }}
                style={{ width: '250px', marginTop: '4px', marginBottom: '12px', backgroundColor: bgColor, }}
                value={value}
                name={name}
                onChange={handleInputChange}
                onBlur={handleBlur}
                disabled={disabled}
                error={Boolean(errorMsg)}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    getContentAnchorEl: null,
                    classes: {
                        paper: classes.menuPaper
                    }
                }}
            >
                {typeof options[0] === 'string'
                    ? (options?.map((val, index) => <MenuItem key={val} classes={{ root: classes.menuRoot }} value={val}>{val}</MenuItem>))
                    : (options?.map((val, index) => <MenuItem key={val.value} classes={{ root: classes.menuRoot }} value={val.value}>{val.label}</MenuItem>))}
            </Select>
        </DropdownWrapper>
    );
};

export default DropdownInput;

DropdownInput.propTypes = {
    disabled: PropTypes.bool,
    errorMsg: PropTypes.string,
    handleBlur: PropTypes.func,
    handleInputChange: PropTypes.func,
    name: PropTypes.string,
    options: PropTypes.array,
    required: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.any
};
