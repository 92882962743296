import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';
import { performTest } from '../AsFoundIP_TransmitterSection/AsFoundIP_TransmitterSection.formulas';
import { commonConstants } from '../../Sections.constants';

export const disableAsLeftSection = (formData) => (formData.F619AsLeftTestRequired === commonConstants.No || formData.F61AsFoundResult === commonConstants.Pass_Result);

export const test2Result = (formData) => {
    if (FieldHasValue(formData.F901AltValveLocalPosition1)
        && FieldHasValue(formData.F902AltValveLocalPosition2)
        && FieldHasValue(formData.F903AltValveLocalPosition3)
        && FieldHasValue(formData.F904AltValveLocalPosition4)
        && FieldHasValue(formData.F905AltValveLocalPosition5)
        && FieldHasValue(formData.F906AltValveLocalPosition6)
        && FieldHasValue(formData.F907AltValveLocalPosition7)
        && FieldHasValue(formData.F908AltValveLocalPosition8)
        && FieldHasValue(formData.F909AltValveLocalPosition9) && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F910AltTest2Deviation1), ValidateNumberField(formData.F911AltTest2Deviation2),
        ValidateNumberField(formData.F912AltTest2Deviation3), ValidateNumberField(formData.F913AltTest2Deviation4),
        ValidateNumberField(formData.F914AltTest2Deviation5), ValidateNumberField(formData.F915AltTest2Deviation6),
        ValidateNumberField(formData.F916AltTest2Deviation7), ValidateNumberField(formData.F917AltTest2Deviation8),
        ValidateNumberField(formData.F918AltTest2Deviation9)]) <= ValidateNumberField(formData.F101AftTolerance)) {
            return commonConstants.Pass_Result;
        }
        return commonConstants.Fail_Result;
    }
    return '';
};

export const test3Result = (formData) => {
    if (FieldHasValue(formData.F921AltHmiPositionIndication1)
        && FieldHasValue(formData.F922AltHmiPositionIndication2)
        && FieldHasValue(formData.F923AltHmiPositionIndication3)
        && FieldHasValue(formData.F924AltHmiPositionIndication4)
        && FieldHasValue(formData.F925AltHmiPositionIndication5)
        && FieldHasValue(formData.F926AltHmiPositionIndication6)
        && FieldHasValue(formData.F927AltHmiPositionIndication7)
        && FieldHasValue(formData.F928AltHmiPositionIndication8)
        && FieldHasValue(formData.F929AltHmiPositionIndication9) && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F930AltTest3Deviation1), ValidateNumberField(formData.F931AltTest3Deviation2),
        ValidateNumberField(formData.F932AltTest3Deviation3), ValidateNumberField(formData.F933AltTest3Deviation4),
        ValidateNumberField(formData.F934AltTest3Deviation5), ValidateNumberField(formData.F935AltTest3Deviation6),
        ValidateNumberField(formData.F936AltTest3Deviation7), ValidateNumberField(formData.F937AltTest3Deviation8),
        ValidateNumberField(formData.F938AltTest3Deviation9)]) <= ValidateNumberField(formData.F101AftTolerance)) {
            return commonConstants.Pass_Result;
        }
        return commonConstants.Fail_Result;
    }
    return '';
};

export const asLeftTest1 = (formData) => {
    if (formData.F836IplelementSolenoid === commonConstants.No || !performTest(formData.F890AltTest1Solenoid)) {
        return true;
    }
    if (formData.F836IplelementSolenoid === commonConstants.Yes && performTest(formData.F890AltTest1Solenoid)) {
        return (formData.F894AltTest1Result === commonConstants.Pass_Result);
    }
    return false;
};
export const asLeftTest2 = (formData) => {
    if (formData.F837IplelementIppositioner === commonConstants.No || !performTest(formData.F900AltTest2IpPositioner)) {
        return true;
    }
    if (formData.F837IplelementIppositioner === commonConstants.Yes && performTest(formData.F900AltTest2IpPositioner)) {
        return (formData.F919AltTest2Result === commonConstants.Pass_Result);
    }
    return false;
};
export const asLeftTest3 = (formData) => {
    if (formData.F838IplelementPositionTransmitter === commonConstants.No || !performTest(formData.F920AltTest3PositionTransmitter)) {
        return true;
    }
    if (formData.F838IplelementPositionTransmitter === commonConstants.Yes && performTest(formData.F920AltTest3PositionTransmitter)) {
        return (formData.F939AltTest3Result === commonConstants.Pass_Result);
    }
    return false;
};
export const asLeftTest4 = (formData) => {
    if (formData.F839IplelementLimitSwitch === commonConstants.No || !performTest(formData.F895AltTest4LimitSwitch)) {
        return true;
    }
    if (formData.F839IplelementLimitSwitch === commonConstants.Yes && performTest(formData.F895AltTest4LimitSwitch)) {
        return (formData.F899AltTest4Result === commonConstants.Pass_Result);
    }
    return false;
};

export const asLeftTestResultFormula = (formData) => {
    if (((performTest(formData.F890AltTest1Solenoid) && FieldHasValue(formData.F894AltTest1Result)) || !performTest(formData.F890AltTest1Solenoid))
        && ((performTest(formData.F900AltTest2IpPositioner) && FieldHasValue(formData.F919AltTest2Result)) || !performTest(formData.F900AltTest2IpPositioner))
        && ((performTest(formData.F920AltTest3PositionTransmitter) && FieldHasValue(formData.F939AltTest3Result)) || !performTest(formData.F920AltTest3PositionTransmitter))
        && ((performTest(formData.F895AltTest4LimitSwitch) && FieldHasValue(formData.F899AltTest4Result)) || !performTest(formData.F895AltTest4LimitSwitch))
        && FieldHasValue(formData.F836IplelementSolenoid) && FieldHasValue(formData.F837IplelementIppositioner) && FieldHasValue(formData.F838IplelementPositionTransmitter) && FieldHasValue(formData.F839IplelementLimitSwitch)) {
        if (asLeftTest1(formData) && asLeftTest2(formData) && asLeftTest3(formData) && asLeftTest4(formData)) {
            return commonConstants.Pass_Result;
        }
        return commonConstants.Fail_Result;
    }
    return '';
};
