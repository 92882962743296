import React from 'react';
import Dropdown from 'react-dropdown';
import 'elements/React-Dropdown/style.css';
import PropTypes from 'prop-types';
import { StyledLabel, SelectWrapper } from './Dropdown.styled';

export const FormDropdown = (props) => {
    const { id, label, name, handleChange, value, options = [], isRequired,
        textAlign, marginLeft, readOnly, width, flexDisplay, marginTop,
        noDefaultOption, paddingLeft, fontWeight, fontSize, placeholder,
        menuMarginLeft, formName, marginBottom, height, padding, arrowPadding, fontColor, isLocked, bgColor } = props;
    const elementId = id || `id-${name}`;
    let { className } = props;
    className = className || '';

    // Here the best pattern is to use useEffect, but it does not work right now with use effect,
    // so I am just checking before if it contains an empty value and adding it at the beginning
    if (!noDefaultOption && options && options[0] && options[0].value !== '') {
        options.unshift({ name: ' ', value: '' });
    }

    return (
        <SelectWrapper
            id={`${elementId}-wrapper`}
            width={width}
            style={flexDisplay}
            fontSize={fontSize}
            marginLeft={marginLeft}
            menuMarginLeft={menuMarginLeft}
            marginTop={marginTop}
            height={height}
            padding={padding}
            arrowPadding={arrowPadding}
            marginBottom={marginBottom}
            bgColor={bgColor}
        >
            <StyledLabel
                paddingLeft={paddingLeft}
                fontWeight={fontWeight}
                fontSize={fontSize}
                textAlign={textAlign}
                marginLeft={marginLeft}
                marginBottom={marginBottom}
                fontColor={fontColor}
            >
                {label}
            </StyledLabel>
            <Dropdown
                formName={formName}
                id={elementId}
                name={name}
                value={value}
                options={[...options.map(x => ({ label: x.name, value: x.value }))]}
                onChange={(e) => handleChange({ [name]: e.value })}
                disabled={readOnly}
                style={{
                    textAlign: 'left',
                    width
                }}
                className={`${className ?? ''} ${isRequired ? 'isRequired' : ''} ${isRequired && value ? 'isRequiredValue' : ''} ${readOnly && formName ? 'readOnly' : ''} ${isLocked ? 'isLocked' : ''}`}
                width={width}
                placeholder={placeholder}
            />
        </SelectWrapper>
    );
};

FormDropdown.propTypes = {
    arrowPadding: PropTypes.string,
    bgColor: PropTypes.string,
    className: PropTypes.string,
    flexDisplay: PropTypes.string,
    fontColor: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    formName: PropTypes.string,
    handleChange: PropTypes.func,
    height: PropTypes.string,
    id: PropTypes.string,
    isLocked: PropTypes.bool,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    marginTop: PropTypes.string,
    menuMarginLeft: PropTypes.string,
    name: PropTypes.string,
    noDefaultOption: PropTypes.bool,
    options: PropTypes.array,
    padding: PropTypes.string,
    paddingLeft: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    textAlign: PropTypes.string,
    value: PropTypes.any,
    width: PropTypes.string,
};
