import { FieldHasValue, ValidateField, ValidateNumberField, ExponentialToDecimal, IsNonText } from 'helpers/Validation';

export const AsLeftTableF63Hmi2Inst3Formula = (formData) => {
    let Hmi2Inst1;
    if (FieldHasValue(formData.F22HmiUrv)) {
        Hmi2Inst1 = '0% - '.concat(ValidateField(formData.F21HmiLrv))
            .concat(FieldHasValue(formData.F24HmiEngineeringUnit) ? ' ' : '')
            .concat(ValidateField(formData.F24HmiEngineeringUnit))
            .concat(' - ')
            .concat(ValidateField(formData.F25InstrumentLrv))
            .concat(FieldHasValue(formData.F118InstrumentEngineeringUnits) ? ' ' : '')
            .concat(ValidateField(formData.F118InstrumentEngineeringUnits));
        return Hmi2Inst1.length > 255 ? Hmi2Inst1.slice(0, 255) : Hmi2Inst1;
    }
    return '0%';
};

export const AsLeftTableF65Hmi2Inst1Formula = (formData) => {
    let Hmi2Inst3;
    if (FieldHasValue(formData.F22HmiUrv)) {
        Hmi2Inst3 = '100% - '.concat(ValidateField(formData.F22HmiUrv))
            .concat((FieldHasValue(formData.F24HmiEngineeringUnit)) ? ' ' : '')
            .concat(ValidateField(formData.F24HmiEngineeringUnit))
            .concat(' - ')
            .concat(ValidateField(formData.F117InstrumentCalibratedUrv))
            .concat((FieldHasValue(formData.F118InstrumentEngineeringUnits)) ? ' ' : '')
            .concat(ValidateField(formData.F118InstrumentEngineeringUnits));
        return Hmi2Inst3.length > 255 ? Hmi2Inst3.slice(0, 255) : Hmi2Inst3;
    }
    return '100%';
};
export const AsLeftTableF78Deviation2Eu1Formula = (formData) => FieldHasValue(formData.F69Tx2Reading1) && FieldHasValue(formData.F66TestModule2Reading1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F69Tx2Reading1) - ValidateNumberField(formData.F66TestModule2Reading1))) : '';
export const AsLeftTableF79Deviation2Eu2Formula = (formData) => FieldHasValue(formData.F70Tx2Reading2) && FieldHasValue(formData.F67TestModule2Reading2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F70Tx2Reading2) - ValidateNumberField(formData.F67TestModule2Reading2))) : '';
export const AsLeftTableF80Deviation2Eu3Formula = (formData) => FieldHasValue(formData.F71Tx2Reading3) && FieldHasValue(formData.F68TestModule2Reading3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F71Tx2Reading3) - ValidateNumberField(formData.F68TestModule2Reading3))) : '';

export const TestResultF81AsLeftResultFormula = (formData) => {
    if (FieldHasValue(formData.F66TestModule2Reading1)
        && FieldHasValue(formData.F67TestModule2Reading2)
        && FieldHasValue(formData.F68TestModule2Reading3)
        && FieldHasValue(formData.F69Tx2Reading1)
        && FieldHasValue(formData.F70Tx2Reading2)
        && FieldHasValue(formData.F71Tx2Reading3)
        && FieldHasValue(formData.F72Hmi2Reading1)
        && FieldHasValue(formData.F73Hmi2Reading2)
        && FieldHasValue(formData.F74Hmi2Reading3)
        && IsNonText(formData.F117InstrumentCalibratedUrv)
        && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F78Deviation2Eu1),
        ValidateNumberField(formData.F79Deviation2Eu2),
        ValidateNumberField(formData.F80Deviation2Eu3)]) <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
