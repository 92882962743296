import { ExponentialToDecimal, FieldHasValue, ValidateField, ValidateNumberField } from 'helpers/Validation';
import { isF24HmiEUHasValue } from '../P61/P61.formulas';
import { isF28InstEUHasValue } from '../P65/P65.formulas';

export const AsFoundTestTableF39HmiInst1Formula = (formData) => FieldHasValue(formData.F22HmiUrv) ? '100% - '.concat(ValidateField(formData.F22HmiUrv))
    .concat(isF24HmiEUHasValue(formData))
    .concat(ValidateField(formData.F24HmiEngineeringUnit))
    .concat(' - ')
    .concat(ValidateField(formData.F26InstrumentUrv))
    .concat(isF28InstEUHasValue(formData))
    .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '100%';

export const AsFoundTestTableF40HmiInst2Formula = (formData) => (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F22HmiUrv))
    ? '50% - '.concat(ExponentialToDecimal(ValidateNumberField(formData.F21HmiLrv)
        + (ValidateNumberField(formData.F22HmiUrv) - ValidateNumberField(formData.F21HmiLrv)) * (0.5)))
        .concat(isF24HmiEUHasValue(formData))
        .concat(ValidateField(formData.F24HmiEngineeringUnit))
        .concat(' - ')
        .concat(ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)
            + (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) * (0.5)))
        .concat(isF28InstEUHasValue(formData))
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '50%';

export const AsFoundTestTableF41HmiInst3Formula = (formData) => (FieldHasValue(formData.F21HmiLrv) ? '0% - '.concat(ValidateField(formData.F21HmiLrv))
    .concat(isF24HmiEUHasValue(formData))
    .concat(ValidateField(formData.F24HmiEngineeringUnit))
    .concat(' - ')
    .concat(ValidateNumberField(formData.F25InstrumentLrv))
    .concat(isF28InstEUHasValue(formData))
    .concat(ValidateField(formData.F28InstrumentEngineeringUnit))
    : '0%');

export const AsFoundTestTableF54DeviationEu1Formula = (formData) => FieldHasValue(formData.F45TxReading1) && FieldHasValue(formData.F42GaugeReading1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F45TxReading1) - ValidateNumberField(formData.F42GaugeReading1))) : '';
export const AsFoundTestTableF55DeviationEu2Formula = (formData) => FieldHasValue(formData.F46TxReading2) && FieldHasValue(formData.F43GaugeReading2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F46TxReading2) - ValidateNumberField(formData.F43GaugeReading2))) : '';
export const AsFoundTestTableF56DeviationEu3Formula = (formData) => FieldHasValue(formData.F47TxReading3) && FieldHasValue(formData.F44GaugeReading3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F47TxReading3) - ValidateNumberField(formData.F44GaugeReading3))) : '';
