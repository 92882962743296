import { FieldHasValue, ValidateField } from 'helpers/Validation';

export const AsFoundTestTableP716Formula = (formData) => {
    if (FieldHasValue(formData.F746AftValvePartialStrokeTestResult) && FieldHasValue(formData.F750AftSmartPartialStrokeTestResult)) {
        if (ValidateField(formData.F750AftSmartPartialStrokeTestResult === 'Successful') && ValidateField(formData.F746AftValvePartialStrokeTestResult === 'Successful')) {
            return 'PASS';
        }
        return 'FAIL';
    }

    if ((FieldHasValue(formData.F746AftValvePartialStrokeTestResult) || FieldHasValue(formData.F750AftSmartPartialStrokeTestResult))
        && (ValidateField(formData.F494Pstsetting === 'Solenoid Valve') || ValidateField(formData.F494Pstsetting === 'Smart Positioner'))) {
        if ((formData.F746AftValvePartialStrokeTestResult === 'Successful') || (formData.F750AftSmartPartialStrokeTestResult === 'Successful')) {
            return 'PASS';
        }
        return 'FAIL';
    }

    return '';
};
