import { tagSelectionMoScreenInitialState } from './TagSelectionMOScreen.data';
import { TAGS_ACTIONS } from './TagSelectionMOScreen.constants';

export default function TagSelectionMOScreenReducer(state = tagSelectionMoScreenInitialState, action) {
    switch (action.type) {
        case TAGS_ACTIONS.GET_TAGRECORDS_PENDING:
        case TAGS_ACTIONS.GET_TAGRECORDS_SUCCESS:
        case TAGS_ACTIONS.SET_TAGRECORD_ROW:
        case TAGS_ACTIONS.LOAD_FILTERS:
        case TAGS_ACTIONS.SET_CHECKBOX_FILTERS:
        case TAGS_ACTIONS.SET_FILTERS:
        case TAGS_ACTIONS.SET_TESTSTATUS_FILTERS:
        case TAGS_ACTIONS.GET_SEARCHTAGRECORDS_FAILURE:
        case TAGS_ACTIONS.GET_SEARCHTAGRECORDS_PENDING:
        case TAGS_ACTIONS.GET_SEARCHTAGRECORDS_SUCCESS:
        case TAGS_ACTIONS.GET_SORTTAGRECORDS_PENDING:
        case TAGS_ACTIONS.GET_SORTTAGRECORDS_SUCCESS:
        case TAGS_ACTIONS.GET_SORTTAGRECORDS_FAILURE:
        case TAGS_ACTIONS.GET_SELECTEDROWTAGS:
        case TAGS_ACTIONS.DESELECT_FILTERS:
        case TAGS_ACTIONS.GET_TESTSTATUS_SELECTEDROWTAGS:
        case TAGS_ACTIONS.HDLOCATION_SORTORDER:
        case TAGS_ACTIONS.GET_TAGRECORDS_TOTALCOUNT:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
