import { Placeholder, commonConstants, Result } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { InspectionResultDropdownOptions, InspectionResultValue } from './M42.formulas';

export const TestResultSectionDataM42 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F1017CrticalFaultLabel',
                    label: 'Critical Fault Found ',
                    xs: 2,
                    labelWidth: '100%',
                    padding: '0',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F1017CrticalFault',
                    value: formData.F1017CrticalFault,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: Placeholder.SELECT_YES_OR_NO,
                    options: [
                        { name: Placeholder.SELECT_YES_OR_NO, value: '' },
                        { name: commonConstants.YES, value: commonConstants.YES },
                        { name: commonConstants.NO, value: commonConstants.NO }],
                    xs: 4,
                    inputTextAlign: 'center',
                    labelWidth: '0',
                    width: '100%',
                    direction: 'rtl',
                    textAlign: 'center',
                    marginLeft: '16px'
                },
                {
                    key: 'F102AftResultLabel',
                    label: 'As-Found Result',
                    padding: '5px 25px 0 0',
                    xs: 2,
                    labelWidth: '98%',
                    paddingLabel,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right'
                },
                {
                    key: 'F102AftResult',
                    value: formData.F102AftResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select PASS or FAIL',
                    options: [
                        { name: 'Select PASS or FAIL', value: '' },
                        { name: Result.RESULT_PASS, value: Result.RESULT_PASS },
                        { name: Result.RESULT_FAIL, value: Result.RESULT_FAIL }
                    ],
                    xs: 4,
                    labelWidth: '0',
                    width: '100%',
                    marginLeft: '13px',
                    borderBottom: '0'
                }
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'nodata',
                    isLabel: true,
                    xs: 6
                },
                {
                    key: 'F85TestResultLabel',
                    label: 'Inspection Result',
                    xs: 2,
                    labelWidth: '100%',
                    paddingLabel,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right'
                },
                {
                    key: 'F85TestResult',
                    value: InspectionResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: Placeholder.SELECT,
                    options: InspectionResultDropdownOptions(formData),
                    xs: 4,
                    labelWidth: '0',
                    width: '100%',
                    marginLeft: '13px',
                }
            ]
        }
    ]

});
