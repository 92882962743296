/* eslint-disable no-console */
import _ from 'lodash';
import { loginRequest, graphConfig } from '../authConfig';
import { msalInstance } from './MsalAuthWrapper/MsalAuthWrapper';

export async function callMsGraph() {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        msalInstance.loginRedirect();
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers
    };
    return fetch(graphConfig.graphMeEndpoint, options)
        .then(apiresponse => apiresponse.json())
        .catch(error => console.error(error));
}

const validateAndSanitizeIds = (ids) => ids.filter(id => typeof id === 'string')
    .filter(id => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(String(id)));

export async function getUserGroups(groupIds) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        msalInstance.loginRedirect();
    }

    const tokenResponse = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account
    });

    let headers = new Headers();
    headers = {
        ...headers,
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        'Content-Type': 'application/json'
    };

    const checkMemberGroupsURL = graphConfig.getGroupsAssociatedWithUser.replace('{id}', account.username);

    const sanitizedGroupIds = validateAndSanitizeIds(groupIds);
    const chunkedGroupIds = _.chunk(sanitizedGroupIds, process.env.REACT_APP_MS_GRAPH_API_CHECK_MEMBER_GROUPS_LIMIT ?? 20);
    const chunkPromises = [];
    chunkedGroupIds.forEach((chunkGroupIds) => {
        const options = {
            method: 'POST',
            headers,
            body: JSON.stringify({ groupIds: chunkGroupIds })
        };
        chunkPromises.push(fetch(checkMemberGroupsURL, options));
    });

    const responsePromises = await Promise.all(chunkPromises);

    const jsonPromises = [];
    responsePromises.forEach((response) => {
        if (response.ok) {
            jsonPromises.push(response.json());
        } else {
            throw response.statusText;
        }
    });

    const dataPromises = await Promise.all(jsonPromises);

    let validGroupIds = [];

    dataPromises.forEach((data) => {
        validGroupIds = _.concat(validGroupIds, data.value);
    });

    return validGroupIds;
}
