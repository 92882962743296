import { conditionalValueSelector } from 'helpers/Validation';

export const VisualInspectSectionDataP6142 = (formName, formData) => {
    const visualInspectSectionLabel = `Visually inspect process pipe work; flanges, small-bore pipe work, 
    compression fittings and manifolds and ensure they are secure, undamaged and free of leaks and loss of containment, 
        as far as possible where applicable without removing insulation, visually inspect the  valve body, yoke and actuator for 
            any signs of physical damage and corrosion`;
    const visualInspectSectionLabeP6232 = `Visually inspect louver or damper and ancillary elements for evidence of damage,
    corrosion and contamination. Check small-bore tubing and compression fittings for correct installation and working condition.
        Check cables and glands for damage. Ensure device identification is in place, correct and legible.`;
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F119VisualInspection',
                    label: conditionalValueSelector(['P6-23.2'].includes(formName), visualInspectSectionLabeP6232, visualInspectSectionLabel),
                    value: formData.F119VisualInspection,
                    isRequired: true,
                    isMultiline: true,
                    xs: 12,
                    width: '50%',
                    inputWidth: '50%',
                    alignItems: 'inherit'
                }]
            }
        ]
    };
};
