import { ExponentialToDecimal, FieldHasValue, ValidateField, ValidateNumberField } from 'helpers/Validation';

export const AsFoundTestTableF39FormulaInst1Formula = (formData) => FieldHasValue(formData.F26InstrumentUrv) ? '0% - '
    .concat(ValidateField(formData.F25InstrumentLrv))
    .concat(' ')
    .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '0%';

export const AsFoundTestTableF40FormulaInst2Formula = (formData) => (FieldHasValue(formData.F26InstrumentUrv)
    ? '50% - '.concat(ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)
        + (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) * (0.5)))
        .concat(' ')
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '50%');

export const AsFoundTestTableF41FormulaInst3Formula = (formData) => (FieldHasValue(formData.F26InstrumentUrv) ? '100% - '
    .concat(ValidateField(formData.F26InstrumentUrv))
    .concat(' ')
    .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '100%');
