import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormInput } from 'elements/Inputs/FormInput/';
import { StyledLabel } from 'elements/Label/Label';
import { selectFoundResultTemplateForForm } from './FoundResultSection.data';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

const FoundResultSection = (props) => {
    const { selectedTag, changeHandler, formData, formName, isFormReadOnly, userRole } = props;
    const classes = useStyles();
    return (
        <Grid container spacing={1} className={classes.root}>
            {
                selectFoundResultTemplateForForm(selectedTag, formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ padding: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {
                                    field.isLabel
                                        ? (
                                            <StyledLabel
                                                id={field.key}
                                                fontWeight={field.fontWeight}
                                                textAlign={field.textAlign}
                                                labelWidth={field.labelWidth}
                                                padding={field.padding}
                                                fontSize={field.fontSize}
                                            >
                                                {field.label}
                                            </StyledLabel>
                                        )
                                        : (
                                            <FormInput
                                                id={field.key}
                                                name={field.key}
                                                label={field.label}
                                                value={field.value}
                                                isRequired={field.isRequired}
                                                isCalculated={field.isCalculated}
                                                isCalculatedNew={field.isCalculatedNew}
                                                handleChange={changeHandler}
                                                direction={field.direction}
                                                readOnly={field.readOnly || isFormReadOnly}
                                                type={field.type}
                                                width={field.width}
                                                labelWidth={field.labelWidth}
                                                padding={field.padding}
                                                marginRight={field.marginRight}
                                                marginLeft={field.marginLeft}
                                                maxLength={field.maxLength}
                                                marginTop={field.marginTop}
                                                borderRight={field.borderRight}
                                                borderLeft={field.borderLeft}
                                                borderBottom={field.borderBottom}
                                                borderTop={field.borderTop}
                                                height={field.height}
                                                marginBottom={field.marginBottom}
                                                inputMarginRight={field.inputMarginRight}
                                                textAlign={field.textAlign}
                                                isLabelNew={field.isLabelNew}
                                                isLabelBold={field.isLabelBold}
                                                userRole={userRole}
                                                fontWeight={field.fontWeight}
                                                hasDPSError={field.hasDPSError}
                                                isDisabled={field.isDisabled}
                                            />
                                        )
                                }
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FoundResultSection);

FoundResultSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
