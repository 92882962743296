export const trfHeaderData = [
    {
        field: 'RowId',
        headerName: 'Row ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'TrfId',
        headerName: 'TRF ID',
        width: '85',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'MratTrfTableName',
        headerName: 'TRF Database Table Name',
        width: '150',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'TrfLongDesc',
        headerName: 'TRF Long Desc',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'TrfShortDesc',
        headerName: 'TRF Short Desc',
        width: '200',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'TrfShortDescReplaceWhenActive',
        headerName: 'TRF Short Desc Replace When Active',
        width: '230',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'ApplicableRegions',
        headerName: 'Applicable Regions',
        width: '90',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'WorkType',
        headerName: 'Work Type',
        width: '105',
        isDropdown: true,
        dropdownValues: ['Preventative', 'Corrective'],
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'TrfType',
        headerName: 'TRF Type',
        width: '105',
        isDropdown: true,
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'TrfCount',
        headerName: 'TRF Count',
        width: '105',
        isDropdown: true,
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Status',
        headerName: 'TRF List Status',
        width: '130',
        hideInTable: false,
        isDisabled: false,
        isDropdown: true,
        dropdownValues: ['Active', 'Inactive'],
        defaultValue: 'Active'
    },
    {
        field: 'DpsListStatus',
        headerName: 'DPS List Status',
        width: '135',
        hideInTable: false,
        isDisabled: false,
        isDropdown: true,
        dropdownValues: ['Active', 'Inactive'],
        defaultValue: 'Active'
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '110',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const trfMasterListInitialState = {
    RowId: '',
    TrfId: '',
    MratTrfTableName: '',
    TrfLongDesc: '',
    TrfShortDesc: '',
    TrfShortDescReplaceWhenActive: '',
    Status: 'Inactive',
    ApplicableRegions: 'ALL',
    WorkType: '',
    TrfType: 'N/A',
    TrfCount: 0,
    DpsListStatus: 'Inactive',
    Comments: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: ''
};

export const searchDataInitialState = {
    RowId: '',
    TrfId: '',
    MratTrfTableName: '',
    TrfLongDesc: '',
    TrfShortDesc: '',
    TrfShortDescReplaceWhenActive: '',
    Status: '',
    ApplicableRegions: '',
    WorkType: '',
    TrfType: '',
    TrfCount: '',
    DpsListStatus: '',
    Comments: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: ''
};

export const trfMasterListApiEndpoint = {
    read: 'ADMIN_GetTrfMasterLists',
    create: 'ADMIN_SubmitTrfID',
    update: 'ADMIN_UpdateTrfID'
};
