import { labelWidth } from '../P710/P710.data';

export const HMISectionDataM68 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F100HmireadingPriorToTestLabel',
                    label: 'HMI Reading Prior to Test',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F100HmireadingPriorToTest',
                    value: formData.F100HmireadingPriorToTest,
                    isDropdown: true,
                    isRequired: true,
                    placeholder: 'Select HMI Status',
                    options: [{ name: 'Select HMI Status', value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'FAULT', value: 'FAULT' }],
                    labelWidth: '0%',
                    width: '100%',
                    height: '1.7rem',
                    inputTextAlign: 'center',
                    paddingRightPlaceholder: '15px',
                    xs: 2
                },
                {
                    key: 'F435DcsControlTagLabel',
                    label: 'DCS Control Tag/Address',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: 0
                },
                {
                    key: 'F435DcsControlTag',
                    value: selectedTag.DateTested ? formData?.F435DcsControlTag : formData?.F435DcsControlTag ?? selectedTag?.DcsControlTag,
                    xs: 2,
                    direction: 'rtl',
                    inputWidth: '100%',
                    labelWidth: '0%',
                    marginRight: '0',
                    textAlign: 'center',
                    maxLength: '40',
                    isAutoGrow: true,
                    marginTop: '0',
                },
            ]
        },
        {
            key: 2,
            fields: [{
                key: 'F371DetectorLocalStatusPriorToTestLabel',
                label: 'Detector Local Status Prior to Test',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F371DetectorLocalStatusPriorToTest',
                value: formData.F371DetectorLocalStatusPriorToTest,
                isDropdown: true,
                placeholder: 'Select Detector Local Status',
                options: [{ name: 'Select Detector Local Status', value: '' },
                { name: 'NORMAL', value: 'NORMAL' },
                { name: 'ALARM', value: 'ALARM' },
                { name: 'FAULT', value: 'FAULT' }],
                labelWidth: '0%',
                width: '100%',
                inputTextAlign: 'center',
                borderTop: '0',
                paddingRightPlaceholder: '15px',
                xs: 2,
                height: '1.7rem',
                marginBottom: '15px'
            }]
        }
    ]

});
