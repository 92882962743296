export const TestResultSectionDataP720 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F143HmireadingAfterTest',
                label: 'HMI Vibration Reading After Test (HMI EU)',
                value: formData.F143HmireadingAfterTest,
                isRequired: true,
                xs: 4,
                type: 'number',
                direction: 'rtl',
                marginRight: '0.5rem',
                maxLength: '10',
                labelWidth: '50%',
                width: '50%',
                height: '1.62rem',
                textAlign: 'center'
            },
            {
                key: 'Nodata',
                isLabel: true,
                xs: 1
            },
            {
                key: 'F752GapVoltageAfterTestLabel',
                label: 'Gap Voltage After Test (V DC)',
                labelWidth: '98%',
                xs: 3,
                padding: '5px 0 0 0',
                isLabel: true,
                textAlign: 'end',
                fontSize: '9pt',
                fontWeight: '600'
            },
            {
                key: 'F752GapVoltageAfterTest',
                value: (formData.F774SensorType === 'Velometer' || formData.F774SensorType === 'Accelerometer') ? '' : formData.F752GapVoltageAfterTest,
                type: 'number',
                maxLength: '10',
                isRequired: !(formData.F774SensorType === 'Velometer' || formData.F774SensorType === 'Accelerometer') ? true : '',
                labelWidth: '0',
                xs: 4,
                height: '1.63rem',
                textAlign: 'center',
                width: '100%',
                isDisabled: (formData.F774SensorType === 'Velometer' || formData.F774SensorType === 'Accelerometer'),
                readOnly: (formData.F774SensorType === 'Velometer' || formData.F774SensorType === 'Accelerometer'),
                marginLeft: '0.8px'
            }]
        }]
});
