import React from 'react';
import { connect } from 'react-redux';
import Form from '../../FormsTemplate';
import { FormNames } from '../../Forms.constants';
import ToleranceInstrumentEUCommentSection from '../../Sections/ToleranceInstrumentEUComment/ToleranceInstrumentEUComment';
import UserInfoSection from '../../Sections/UserInfoSection/UserInfoSection';
import ManufacturerModelSection from '../../Sections/ManufacturerModelSection/ManufacturerModelSection';
import DeviceTypesSection from '../../Sections/DeviceTypesSection/DeviceTypesSection';
import HMISection from '../../Sections/HMISection/HMISection';
import VisualInspectSection from '../../Sections/VisualInspectSection/VisualInspectSection';
import TestResultSection from '../../Sections/TestResultSection/TestResultSection';
import FoundResultSection from '../../Sections/FoundResultSection/FoundResultSection';
import AsFoundTestTableSection from '../../Sections/AsFoundTestTableSection/AsFoundTestTableSection';
import AsLeftTestTableSection from '../../Sections/AsLeftTestTableSection/AsLeftTestTableSection';
import NoteSection from '../../Sections/NoteSection/NoteSection';
import BorderedBox from '../../BorderedBox';
import SetpointSection from '../../Sections/SetpointSection/SetpointSection';
import TestResultComponent from '../../Sections/TestResultComponent/TestResultComponent';

const P871Form = (props) => (
    <Form
        formName={FormNames.P871}
    >
        <UserInfoSection />
        <ManufacturerModelSection />
        <DeviceTypesSection />
        <HMISection />
        <VisualInspectSection />
        <BorderedBox>
            <AsFoundTestTableSection />
            <FoundResultSection />
        </BorderedBox>
        <ToleranceInstrumentEUCommentSection />
        <BorderedBox>
            <AsLeftTestTableSection />
        </BorderedBox>
        <BorderedBox>
            <SetpointSection />
        </BorderedBox>
        <TestResultSection />
        <TestResultComponent />
        <NoteSection />
    </Form>
);

const mapStateToProps = ({ Forms }) => ({
    selectedTag: Forms.selectedTag,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(P871Form);
