import React, { useMemo, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClearIcon from '@material-ui/icons/Clear';
import { StyledLabel, DatePickerForPrint } from 'elements/';
import { MultiSelect } from 'react-multi-select-component';
import 'elements/React-Dropdown/style.css';
import { StyledLabelForFilter, SelectWrapper } from 'elements/AutoCompleteFilter/AutocompleteFilter.styled';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ClearFiltersIcon from 'icons/ClearFilterButton/ClearFilter';
import SearchBox from 'elements/SearchBox/SearchBox';
import { AutocompleteFilter } from 'elements/AutoCompleteFilter/AutoCompleteFilter';
import {
    clearFilters, setMoNumberValue, setMoStatusValue, setTagFirstDateTestedValue, setDeckHullBldgValue,
    setQuadValue, setLevelValue, setRoomValue, setLocation2Value, searchFilteredValues
} from './SavedTRFPrintScreen.actions';
import { DatePickerStyle, TRFFilterWrapper } from './SavedTRFPrintScreen.styled';

const ArrowRenderer = ({ expanded }) => expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
const ClearIconComponent = () => <ClearIcon style={{ width: '13px', height: '13px', marginTop: '6px', color: '#000099' }} />;

const SavedTRFPrintScreenFilter = (props) => {
    const { MoNumberValue, MoStatusValue, TagFirstDateTestedValue, setMoNumber, setMoStatus, setTagDate, search, clearTRFFilters } = props;
    const { selectedBarrierType, searchedTRFData, setDeckHullBldg, setQuad, setLevel, setRoom, setLocation2, QuadValue,
        LevelValue, RoomValue, Location2Value, DeckHullBldgValue, tagFlocFilter, tagFirstTestDoneByFilter, tagTestRecordIdFilter, tagTestRecordTestResultFilter, tagTestRecordAsFoundResultFilter } = props;
    const FormOpenClosed = ['Open', 'Closed'];
    const allItemsAreSelected = 'All items are selected.';
    const clearSearch = 'Clear Search';
    const clearSelected = 'Clear Selected';
    const noOptions = 'No options';
    const selectAll = 'Select All';
    const selectAllFiltered = 'Select All (Filtered)';
    const marginbottom = selectedBarrierType === 'm6 f&g' ? '-5px' : '5px';
    const makeDataObject = (arr) => {
        const res = [];
        for (const item of arr) {
            const obj = { label: item?.toUpperCase(), value: item?.toLowerCase() };
            res.push(obj);
        }
        return res;
    };

    const useCreateOptions = (filter, key) => useMemo(() => makeDataObject(
        [...new Set(filter.map((x) => {
            if (key === 'TestResult' || key === 'AsFoundResult') {
                return x[key]?.toUpperCase();
            }
            return x[key];
        }))]
    ).sort((a, b) => (a.value < b.value ? -1 : 1)), [filter, key]);

    const [selectedFloc, setSelectedFloc] = useState([]);
    const [selectedFirstTest, setSelectedFirstTest] = useState([]);
    const [selectedTrfId, setSelectedTrfId] = useState([]);
    const [selectedTestResult, setSelectedTestResult] = useState([]);
    const [selectedAsFoundResult, setSelectedAsFoundResult] = useState([]);
    const paddingright = selectedBarrierType === 'm6 f&g' ? '80px' : '100px';
    const paddingleft = selectedBarrierType === 'm6 f&g' ? '80px' : '100px';
    const handleSearch = (searchValue) => {
        setMoNumber(searchValue);
        search({ MoNumber: searchValue });
    };
    return (
        <TRFFilterWrapper id="TRFFilterWrapper">
            <Grid container style={{ paddingRight: paddingright, paddingTop: '5px' }}>
                <Grid item container xs={12} style={{ paddingLeft: paddingleft }}>
                    <Grid xs={2} item style={{ paddingTop: '0', marginBottom: '-17px' }}>
                        <StyledLabel fontSize="8pt">
                            Clear Filters
                        </StyledLabel>
                        <IconButton
                            aria-label="delete"
                            onClick={() => {
                                clearTRFFilters();
                                setSelectedFloc([]);
                                setSelectedFirstTest([]);
                                setSelectedTrfId([]);
                            }}
                        >
                            <ClearFiltersIcon id="clearFilter" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <StyledLabelForFilter>MO Number</StyledLabelForFilter>
                        <SearchBox
                            value={MoNumberValue}
                            isClear={MoNumberValue === ''}
                            width="100%"
                            fullwidth
                            handleSearch={handleSearch}
                            borderColor="#498205"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <AutocompleteFilter
                            label="MO Status"
                            id="MO Status"
                            value={MoStatusValue}
                            options={FormOpenClosed}
                            handleChange={(event, newValue) => {
                                setMoStatus(newValue);
                                search({ MoFunctionalLocation: newValue });
                            }}
                            handleInputChange={() => {
                                setMoStatus('');
                                search({ MoFunctionalLocation: '' });
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} style={{ marginTop: '2px', marginLeft: '2px' }}>
                        <SelectWrapper>
                            <StyledLabelForFilter>Tag FLOC</StyledLabelForFilter>
                            <MultiSelect
                                options={useCreateOptions(tagFlocFilter, 'TagFunctionalLocation')}
                                value={selectedFloc}
                                onChange={(value) => {
                                    setSelectedFloc(value);
                                    search({ TagFunctionalLocation: value });
                                }}
                                overrideStrings={{
                                    allItemsAreSelected,
                                    clearSearch,
                                    clearSelected,
                                    noOptions,
                                    search: 'Search',
                                    selectAll,
                                    selectAllFiltered,
                                    selectSomeItems: ' ',
                                    create: 'Create',
                                }}
                                ArrowRenderer={ArrowRenderer}
                                ClearIcon={<ClearIconComponent />}
                                ClearSelectedIcon={<ClearIconComponent />}
                                labelledBy="Select"
                            />
                        </SelectWrapper>
                    </Grid>
                </Grid>
                <Grid item container xs={12} style={{ paddingLeft: paddingleft, marginBottom: marginbottom }}>
                    <Grid xs={2} item />
                    <Grid item xs={2}>
                        <StyledLabelForFilter>Tag First Date Tested</StyledLabelForFilter>
                        <DatePickerStyle>
                            <DatePickerForPrint
                                name="Tag First Date Tested"
                                width="98%"
                                height="2rem"
                                value={TagFirstDateTestedValue}
                                onChange={(newValue) => {
                                    setTagDate(newValue);
                                    search({ TagFirstDateTested: newValue });
                                }}
                                handleInputChange={() => {
                                    setTagDate(null);
                                    search({ TagFirstDateTested: null });
                                }}
                            />
                        </DatePickerStyle>
                    </Grid>
                    <Grid item xs={2}>
                        <SelectWrapper>
                            <StyledLabelForFilter>Tag First Test Done By</StyledLabelForFilter>
                            <MultiSelect
                                options={useCreateOptions(tagFirstTestDoneByFilter, 'TagFirstTestDoneBy')}
                                value={selectedFirstTest}
                                onChange={(value) => {
                                    setSelectedFirstTest(value);
                                    search({ TagFirstTestDoneBy: value });
                                }}
                                overrideStrings={{
                                    allItemsAreSelected,
                                    clearSearch,
                                    clearSelected,
                                    noOptions,
                                    search: 'Search',
                                    selectAll,
                                    selectAllFiltered,
                                    selectSomeItems: ' ',
                                    create: 'Create',
                                }}
                                ArrowRenderer={ArrowRenderer}
                                ClearIcon={<ClearIconComponent />}
                                ClearSelectedIcon={<ClearIconComponent />}
                                labelledBy="Select"
                            />
                        </SelectWrapper>
                    </Grid>
                    <Grid xs={2} item>
                        <SelectWrapper>
                            <StyledLabelForFilter>Tag TRF ID</StyledLabelForFilter>
                            <MultiSelect
                                options={useCreateOptions(tagTestRecordIdFilter, 'TagTestRecordId')}
                                value={selectedTrfId}
                                onChange={(value) => {
                                    setSelectedTrfId(value);
                                    search({ TagTestRecordId: value });
                                }}
                                overrideStrings={{
                                    allItemsAreSelected,
                                    clearSearch,
                                    clearSelected,
                                    noOptions,
                                    search: 'Search',
                                    selectAll,
                                    selectAllFiltered,
                                    selectSomeItems: ' ',
                                    create: 'Create',
                                }}
                                ArrowRenderer={ArrowRenderer}
                                ClearIcon={<ClearIconComponent />}
                                ClearSelectedIcon={<ClearIconComponent />}
                                labelledBy="Select"
                            />
                        </SelectWrapper>
                    </Grid>
                    <Grid xs={2} item>
                        <SelectWrapper>
                            <StyledLabelForFilter>As-Found Result</StyledLabelForFilter>
                            <MultiSelect
                                options={useCreateOptions(tagTestRecordAsFoundResultFilter, 'AsFoundResult')}
                                value={selectedAsFoundResult}
                                onChange={(value) => {
                                    setSelectedAsFoundResult(value);
                                    search({ AsFoundResult: value });
                                }}
                                overrideStrings={{
                                    allItemsAreSelected,
                                    clearSearch,
                                    clearSelected,
                                    noOptions,
                                    search: 'Search',
                                    selectAll,
                                    selectAllFiltered,
                                    selectSomeItems: ' ',
                                    create: 'Create',
                                }}
                                ArrowRenderer={ArrowRenderer}
                                ClearIcon={<ClearIconComponent />}
                                ClearSelectedIcon={<ClearIconComponent />}
                                labelledBy="Select"
                            />
                        </SelectWrapper>
                    </Grid>
                    <Grid xs={2} item>
                        <SelectWrapper>
                            <StyledLabelForFilter>Test Result</StyledLabelForFilter>
                            <MultiSelect
                                options={useCreateOptions(tagTestRecordTestResultFilter, 'TestResult')}
                                value={selectedTestResult}
                                onChange={(value) => {
                                    setSelectedTestResult(value);
                                    search({ TestResult: value });
                                }}
                                overrideStrings={{
                                    allItemsAreSelected,
                                    clearSearch,
                                    clearSelected,
                                    noOptions,
                                    search: 'Search',
                                    selectAll,
                                    selectAllFiltered,
                                    selectSomeItems: ' ',
                                    create: 'Create',
                                }}
                                ArrowRenderer={ArrowRenderer}
                                ClearIcon={<ClearIconComponent />}
                                ClearSelectedIcon={<ClearIconComponent />}
                                labelledBy="Select"
                            />
                        </SelectWrapper>
                    </Grid>
                </Grid>
                {(selectedBarrierType === ('M6 F&G').toLowerCase()) && (
                    <Grid item container xs={12} style={{ paddingLeft: paddingleft, marginBottom: '2px' }}>
                        <Grid xs={2} item>
                            <h6 style={{ fontSize: '9px', fontWeight: 'normal', width: '60%' }}>For M6 Barrier Types Only from DPS table</h6>
                        </Grid>
                        <Grid xs={2} item>
                            <AutocompleteFilter
                                label="Deck/Hull/Bldg"
                                id="Deck/Hull/Bldg"
                                widthForAutocomplete="98%"
                                value={DeckHullBldgValue}
                                options={[...new Set(searchedTRFData.map((x) => x.DeckHullBldg).filter(y => y !== null && y !== ''))].sort((a, b) => a < b ? -1 : 1)}
                                handleChange={(event, value) => {
                                    setDeckHullBldg(value);
                                    search({ DeckHullBldg: value });
                                }}
                                handleInputChange={() => {
                                    setDeckHullBldg('');
                                    search({ DeckHullBldg: '' });
                                }}
                            />
                        </Grid>
                        <Grid xs={2} item>
                            <AutocompleteFilter
                                label="Quad"
                                id="Quad"
                                widthForAutocomplete="98%"
                                value={QuadValue}
                                options={[...new Set(searchedTRFData.map((x) => x.Quad).filter(y => y !== null && y !== ''))].sort((a, b) => a < b ? -1 : 1)}
                                handleChange={(event, value) => {
                                    setQuad(value);
                                    search({ Quad: value });
                                }}
                                handleInputChange={() => {
                                    setQuad('');
                                    search({ Quad: '' });
                                }}
                            />
                        </Grid>
                        <Grid xs={2} item>
                            <AutocompleteFilter
                                label="Level"
                                id="Level"
                                widthForAutocomplete="98%"
                                value={LevelValue}
                                options={[...new Set(searchedTRFData.map((x) => x.Level).filter(y => y !== null && y !== ''))].sort((a, b) => a < b ? -1 : 1)}
                                handleChange={(event, value) => {
                                    setLevel(value);
                                    search({ Level: value });
                                }}
                                handleInputChange={() => {
                                    setLevel('');
                                    search({ Level: '' });
                                }}
                            />
                        </Grid>
                        <Grid xs={2} item>
                            <AutocompleteFilter
                                label="Room"
                                id="Room"
                                widthForAutocomplete="98%"
                                value={RoomValue}
                                options={[...new Set(searchedTRFData.map((x) => x.Room).filter(y => y !== null && y !== ''))].sort((a, b) => a < b ? -1 : 1)}
                                handleChange={(event, value) => {
                                    setRoom(value);
                                    search({ Room: value });
                                }}
                                handleInputChange={() => {
                                    setRoom('');
                                    search({ Room: '' });
                                }}
                            />
                        </Grid>
                        <Grid xs={2} item>
                            <AutocompleteFilter
                                label="Location"
                                id="Location"
                                widthForAutocomplete="98%"
                                value={Location2Value}
                                options={[...new Set(searchedTRFData.map((x) => x.Location2).filter(y => y !== null && y !== ''))].sort((a, b) => a < b ? -1 : 1)}
                                handleChange={(event, value) => {
                                    setLocation2(value);
                                    search({ Location2: value });
                                }}
                                handleInputChange={() => {
                                    setLocation2('');
                                    search({ Location2: '' });
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </TRFFilterWrapper>
    );
};

const mapStateToProps = ({ TagSelectionMOScreen, Home, SavedTRF }) => ({
    DeckHullBldgValue: SavedTRF.DeckHullBldgValue,
    QuadValue: SavedTRF.QuadValue,
    LevelValue: SavedTRF.LevelValue,
    Location2Value: SavedTRF.Location2Value,
    MoNumberValue: SavedTRF.MoNumberValue,
    MoStatusValue: SavedTRF.MoStatusValue,
    RoomValue: SavedTRF.RoomValue,
    savedTRFDataAll: SavedTRF.savedTRFDataAll,
    selectedBarrierType: Home.selectedBarrierType,
    selectedSavedTRFScreenFilters: SavedTRF.selectedSavedTRFScreenFilters,
    searchedTRFData: SavedTRF.searchedTRFData,
    TagFirstDateTestedValue: SavedTRF.TagFirstDateTestedValue,
    tagFirstTestDoneByFilter: SavedTRF.tagFirstTestDoneByFilter,
    TagFirstTestDoneByValue: SavedTRF.TagFirstTestDoneByValue,
    tagFlocFilter: SavedTRF.tagFlocFilter,
    TagFunctionalLocationValue: SavedTRF.TagFunctionalLocationValue,
    tagTestRecordAsFoundResultFilter: SavedTRF.tagTestRecordAsFoundResultFilter,
    tagTestRecordIdFilter: SavedTRF.tagTestRecordIdFilter,
    tagTestRecordTestResultFilter: SavedTRF.tagTestRecordTestResultFilter,
    TagTestRecordIdValue: SavedTRF.TagTestRecordIdValue,
});
const mapDispatchToProps = dispatch => ({
    dispatch,
    clearTRFFilters: () => dispatch(clearFilters()),
    setDeckHullBldg: (val) => dispatch(setDeckHullBldgValue(val)),
    setLevel: (val) => dispatch(setLevelValue(val)),
    setLocation2: (val) => dispatch(setLocation2Value(val)),
    setQuad: (val) => dispatch(setQuadValue(val)),
    setMoNumber: (val) => dispatch(setMoNumberValue(val)),
    setMoStatus: (val) => dispatch(setMoStatusValue(val)),
    setRoom: (val) => dispatch(setRoomValue(val)),
    setTagDate: (val) => dispatch(setTagFirstDateTestedValue(val)),
    search: (val) => dispatch(searchFilteredValues(val))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SavedTRFPrintScreenFilter);

ArrowRenderer.propTypes = {
    expanded: PropTypes.bool
};

SavedTRFPrintScreenFilter.propTypes = {
    clearTRFFilters: PropTypes.func,
    DeckHullBldgValue: PropTypes.string,
    LevelValue: PropTypes.string,
    Location2Value: PropTypes.string,
    MoNumberValue: PropTypes.string,
    MoStatusValue: PropTypes.string,
    QuadValue: PropTypes.string,
    RoomValue: PropTypes.string,
    search: PropTypes.func,
    searchedTRFData: PropTypes.array,
    selectedBarrierType: PropTypes.string,
    setDeckHullBldg: PropTypes.func,
    setLevel: PropTypes.func,
    setLocation2: PropTypes.func,
    setMoNumber: PropTypes.func,
    setMoStatus: PropTypes.func,
    setQuad: PropTypes.func,
    setRoom: PropTypes.func,
    setTagDate: PropTypes.func,
    TagFirstDateTestedValue: PropTypes.instanceOf(Date),
    tagFirstTestDoneByFilter: PropTypes.array,
    tagFlocFilter: PropTypes.array,
    tagTestRecordAsFoundResultFilter: PropTypes.array,
    tagTestRecordIdFilter: PropTypes.array,
    tagTestRecordTestResultFilter: PropTypes.array
};
