export const LowFlowAlarmTest = (selectedTag, formName, formData) => ({
    rows: [{
            key: 1,
            fields: [{
                key: 'LowFlowAlarmTest',
                label: 'Low Flow Alarm Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'remark',
                label: 'Remark: This is an optional test to validate that low flow rate of process fluid sample through the analyzer activates fault alarm.',
                fontWeight: '600',
                isLabel: true,
                labelWidth: '100%',
                width: '0',
                fontSize: '8.5pt',
                color: '#ea7c34',
                marginLeft: '4px',
                padding: '5px 25px 5px 4px'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F660InternalSensorFlow',
                label: 'Internal Sensor Flow',
                value: formData.F660InternalSensorFlow,
                xs: 4,
                padding: '0',
                maxLength: '30',
                labelWidth: '50%',
                width: '50%',
                marginLeft: '8px',
                textAlign: 'center',
                borderBottom: '0',
                height: '1.2rem',
                marginBottom: '4px'
            },
            {
                key: 'F659AnalyzerReading3',
                label: 'Analyzer Reading',
                value: formData.F659AnalyzerReading3,
                xs: 4,
                maxLength: '30',
                labelWidth: '50%',
                width: '50%',
                marginRight: '0.5rem',
                textAlign: 'center',
                direction: 'rtl',
                borderBottom: '0',
                height: '1.2rem',
                marginBottom: '4px'
            },
            {
                key: 'F50HmiReading3',
                label: 'HMI Reading',
                value: formData.F50HmiReading3,
                maxLength: '30',
                xs: 4,
                labelWidth: '50%',
                width: '50%',
                marginRight: '0.5rem',
                textAlign: 'center',
                borderRight: '0',
                direction: 'rtl',
                borderBottom: '0',
                height: '1.2rem',
                marginBottom: '4px',
                inputMarginRight: '4px'
            }]
        },
    ]
});
