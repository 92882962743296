import styled from 'styled-components';

export const TextInputWrapper = styled.div`
.MuiFormControl-marginDense {
    margin-top: 4px;

.MuiOutlinedInput-multiline{
    padding: 0px;
}

.MuiOutlinedInput-inputMarginDense {
    padding-top: ${props => props.multiline ? '3.5px' : '7px'};
    padding-bottom: ${props => props.multiline ? '3.5px' : '7px'};
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
`;
