import { TxAsFoundLabel } from 'journeys/portal/Forms/Sections.constants';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

const paddingLabel = '4px 5px 0 0';
export const IsLevelTrf = formData => formData.F956TrfTypeSelection === 'Level';

export const TxAsFoundDataS74 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F977TxAsFoundLabel',
                label: TxAsFoundLabel.Tx_As_Found,
                xs: IsLevelTrf(formData) ? 2 : 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
                marginTop: '15px'
            },
            {
                key: 'F977TxAsFound',
                value: formData.F977TxAsFound,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                height: '1.2rem',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlign: 'center',
                marginTop: '15px'
            },
            {
                key: 'F978AftAlternativeTxLabel',
                label: TxAsFoundLabel.Alternative_Tx,
                xs: IsLevelTrf(formData) ? 2 : 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
                marginTop: '15px'
            },
            {
                key: 'F978AftAlternativeTx',
                value: formData.F978AftAlternativeTx,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlignLabel: 'end',
                height: '1.2rem',
                textAlign: 'center',
                marginTop: '15px'
            },
            ...(IsLevelTrf(formData)
                ? [
                    {
                        key: 'F994AftSightGlassLabel',
                        label: TxAsFoundLabel.Sight_Glass,
                        xs: 2,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel,
                        marginTop: '15px'
                    },
                    {
                        key: 'F994AftSightGlass',
                        value: formData.F994AftSightGlass,
                        type: 'number',
                        maxLength: '10',
                        xs: 2,
                        width: '100%',
                        labelWidth: '0%',
                        textAlignLabel: 'end',
                        height: '1.2rem',
                        textAlign: 'center',
                        marginTop: '15px',
                    },
                ]
                : []),
            ]
        },
        ...(IsLevelTrf(formData)
            ? [{
                key: 2,
                fields: [{
                    key: 'RaiseandLowerLabel',
                    label: 'Raise and Lower Vessel Contents (if operationally possible)',
                    xs: 12,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                    marginTop: '15px'
                },
                ]
            },
            {
                key: 3,
                fields: [{
                    key: 'F992RaisedLevelLabel',
                    label: 'Raised Level',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                    marginTop: '15px'
                },
                {
                    key: 'F992RaisedLevel',
                    value: formData.F992RaisedLevel,
                    type: 'number',
                    maxLength: '10',
                    height: '1.2rem',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    marginTop: '15px'
                },
                {
                    key: 'F990AftAlternativeTx1Label',
                    label: TxAsFoundLabel.Alternative_Tx,
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                    marginTop: '15px'
                },
                {
                    key: 'F990AftAlternativeTx1',
                    value: formData.F990AftAlternativeTx1,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    textAlign: 'center',
                    marginTop: '15px'
                },
                {
                    key: 'F995AftSightGlass1Label',
                    label: TxAsFoundLabel.Sight_Glass,
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                    marginTop: '15px'
                },
                {
                    key: 'F995AftSightGlass1',
                    value: formData.F995AftSightGlass1,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    textAlign: 'center',
                    marginTop: '15px'
                }
                ]
            },
            {
                key: 4,
                fields: [{
                    key: 'F993LoweredLevelLabel',
                    label: 'Lowered Level',
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F993LoweredLevel',
                    value: formData.F993LoweredLevel,
                    type: 'number',
                    maxLength: '10',
                    height: '1.2rem',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderTop: '0'
                },
                {
                    key: 'F991AftAlternativeTx2Label',
                    label: TxAsFoundLabel.Alternative_Tx,
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel
                },
                {
                    key: 'F991AftAlternativeTx2',
                    value: formData.F991AftAlternativeTx2,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderTop: '0'
                },
                {
                    key: 'F996AftSightGlass2Label',
                    label: TxAsFoundLabel.Sight_Glass,
                    xs: 2,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel
                },
                {
                    key: 'F996AftSightGlass2',
                    value: formData.F996AftSightGlass2,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderTop: '0'
                }
                ]
            }]
            : []),
    ]

});
