export const TestResultSectionDataP62 = (selectedTag, formData) => ({
    rows: [
        {
            key: 5,
            fields: [{
                key: 'F82HmiReadingAt',
                label: 'HMI Reading after Test',
                value: formData.F82HmiReadingAt,
                isRequired: true,
                xs: 4,
                type: 'number',
                direction: 'rtl',
                marginRight: '0.5rem',
                maxLength: '10',
                labelWidth: '50%',
                width: '50%',
                height: '1.2rem',
                textAlign: 'center'
            },
            {
                key: 'Nodata',
                isLabel: true,
                xs: 1
            },
            {
                key: 'F83ProcessGaugeReadingAtLabel',
                label: 'Process Gauge Reading after Test',
                labelWidth: '97%',
                xs: 3,
                padding: '5px 0 0 0',
                isLabel: 'true',
                textAlign: 'end',
                fontSize: '9pt',
                fontWeight: '600'
            },
            {
                key: 'F83ProcessGaugeReadingAt',
                value: formData.F83ProcessGaugeReadingAt,
                type: 'number',
                maxLength: '10',
                isRequired: true,
                labelWidth: '0',
                xs: 4,
                height: '1.2rem',
                textAlign: 'center',
                width: '100%',
                marginLeft: '0.8px'
            }]
        }]
});
