import styled from 'styled-components';

export const FooterContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    opacity: 1;
    height: ${props => props.widerFooter ? props.widerFooter : '4rem'};
    background-color: white;
`;

export const SubmitButton = styled.div`
    right: 25%;
    top: ${props => props.openCameraButtonVisible ? '30px' : '10px'};
    float: right;
    position: absolute;
    cursor: pointer;
    button {
        border-color: transparent;
        background-color: ${props => props.formOffline ? 'rgb(255, 140, 0)' : 'rgb(73, 130, 5)'};
        color: #FFFFFF;
        border-radius: 20px;
        width: 8rem;
        height: 28px;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        &:hover{
            background-color: ${props => props.formOffline ? 'rgb(255, 121, 0)' : 'rgb(65, 117, 4)'};
        }
    }
    button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}
`;

export const OpenCameraButton = styled(SubmitButton)`
    left: 18rem;
`;

export const CloseCameraButton = styled(SubmitButton)`
    left: 30rem;
`;

export const CaptureScreenShotButton = styled(SubmitButton)`
    left: 27.5rem;
`;

export const Version = styled.div`
font-size: 1rem;
margin: 0 6px 0 0;
color: #A6A6A6;
position: absolute;
bottom: 0;
right: 0;
`;

export const FooterContent = styled.div`
font-size: 11pt;
margin: 0 6px 0 6px;
line-height: 20px;
color: #A6A6A6;
position: absolute;
bottom: 0;
left: 0;
`;
export const TrfDropdown = styled.div`
line-height: 15px;
`;
export const GradientLine = styled.div`
    background: linear-gradient(to right, yellowgreen,green,yellowgreen,yellow);
    height: 4px
`;
export const SelectTrfText = styled.div`
font-size: 14px; 
color: black; 
font-weight: bold; 
text-align: left;
margin-left: 90px;
margin-top: 4px;
margin-bottom: 5px;
`;
export const DropDownStyle = styled.div`
display: flex;
`;
export const SelectDropdown = styled.div`
font-size: 12px;
position: fixed;
.Dropdown-option {
    min-height: 10px;
    padding: 6px 10px;
}
`;
export const SelectArrow = styled.div`
margin-left: 238px;
.MuiIconButton-root {
    padding: 0px 1px 2px 5px;
}
`;
