import { FieldHasValue, ValidateNumberField, IsNonText } from 'helpers/Validation';

export const FoundResultF102AftResultFormula = (formData) => {
    if (FieldHasValue(formData.F320AftRtdSimulatorReading1)
        && FieldHasValue(formData.F321AftRtdSimulatorReading2)
        && FieldHasValue(formData.F322AftRtdSimulatorReading3)
        && FieldHasValue(formData.F45TxReading1)
        && FieldHasValue(formData.F46TxReading2)
        && FieldHasValue(formData.F47TxReading3)
        && FieldHasValue(formData.F48HmiReading1)
        && FieldHasValue(formData.F49HmiReading2)
        && FieldHasValue(formData.F50HmiReading3) && IsNonText(formData.F117InstrumentCalibratedUrv) && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F54DeviationEu1), ValidateNumberField(formData.F55DeviationEu2), ValidateNumberField(formData.F56DeviationEu3)])
            <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const FoundResultF62SensorTrimNeedFormula = (formData) => {
    if (formData.F102AftResult && FieldHasValue(formData.F117InstrumentCalibratedUrv)) {
        if (ValidateNumberField(formData.F117InstrumentCalibratedUrv) > ValidateNumberField(formData.F116InstrumentCalibratedLrv)) {
            return Math.max.apply(Math, [ValidateNumberField(formData.F54DeviationEu1),
            ValidateNumberField(formData.F55DeviationEu2), ValidateNumberField(formData.F56DeviationEu3)])
                / (ValidateNumberField(formData.F117InstrumentCalibratedUrv) - ValidateNumberField(formData.F116InstrumentCalibratedLrv)) <= 0.02 ? 'NO' : 'YES';
        }
        return '';
    }
    return '';
};
