import React from 'react';

const AdminHome = () => (
    <div>
        <center style={{ paddingTop: '50px', color: '#498205' }}>
            <h2>Admin Home</h2>
        </center>
    </div>
);

export default AdminHome;
