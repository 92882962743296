import { ValidateField } from 'helpers/Validation';

export const TestResultSectionDataP74 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F82HmiReadingAt',
                    label: 'HMI Reading after Test',
                    value: ValidateField(formData.F82HmiReadingAt),
                    isRequired: true,
                    xs: 4,
                    labelWidth: '55%',
                    type: 'number',
                    maxLength: '10',
                    width: '45%',
                    direction: 'rtl',
                    textAlign: 'center',
                    marginTop: '15px'
                },
                {
                    key: 'noData',
                    isLabel: true,
                    xs: 1
                },
                {
                    key: 'ProcessTXReadingatLabel',
                    label: 'Process TX Reading After Test',
                    xs: 3,
                    isLabel: true,
                    fontSize: '9pt',
                    fontWeight: '600',
                    labelWidth: '97.3%',
                    textAlign: 'end',
                    padding: '19px 0 0 0'
                },
                {
                    key: 'F267ProcessTxreadingAfterTest',
                    value: ValidateField(formData.F267ProcessTxreadingAfterTest),
                    isRequired: true,
                    xs: 4,
                    labelWidth: '0',
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    marginTop: '15px',
                    height: '1.2rem',
                    marginLeft: '0.8px'
                }]
        }]
});
