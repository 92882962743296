import axios from 'axios';
import { APIEndpoints } from 'helpers/APILists';
import _ from 'lodash';
import { checkNoFilters, searchFunction } from './SavedTRFPrintScreenHelper';
import { TRF_ACTIONS } from './SavedTRFPrintScreen.constants';
import { sortFunction } from '../SubSections/DPS/DPS.helpers';
import { MESSAGE_TYPE } from '../../common/common.constants';

export const getAllSavedTRFData = () => async (dispatch, getState) => {
    const { Home: { selectedBarrierType, regionName, selectedAssetName, selectedWorkType } } = getState();
    dispatch({
        type: TRF_ACTIONS.GET_TRFRECORDS_PENDING,
        payload: {
            tableLoading: true,
            totalCount: 0
        }
    });
    const filterData = {
        Region: regionName,
        Asset: selectedAssetName,
        BarrierType: selectedBarrierType,
        WorkType: selectedWorkType
    };
    await axios.post(APIEndpoints.GetSavedTRFPrintScreenDetails, filterData)
        .then((res) => {
            const updatedData = res.data.map(item => {
                if (item.AsFoundResult === null) {
                    return { ...item, AsFoundResult: '' };
                }
                return item;
            });
            const savedTRFData = _.sortBy(updatedData, ['MoNumber']);
            const totalCount = savedTRFData.length;

            dispatch({
                type: TRF_ACTIONS.GET_TRFRECORDS_SUCCESS,
                payload: {
                    tableLoading: false,
                    searchedTRFData: savedTRFData,
                    savedTRFDataAll: savedTRFData,
                    tagFlocFilter: savedTRFData,
                    tagFirstTestDoneByFilter: savedTRFData,
                    tagTestRecordIdFilter: savedTRFData,
                    tagTestRecordTestResultFilter: savedTRFData,
                    tagTestRecordAsFoundResultFilter: savedTRFData,
                    MoNumberValue: '',
                    MoStatusValue: '',
                    TagFunctionalLocationValue: '',
                    TagFirstDateTestedValue: null,
                    TagFirstTestDoneByValue: '',
                    TagTestRecordIdValue: '',
                    DeckHullBldgValue: '',
                    QuadValue: '',
                    LevelValue: '',
                    RoomValue: '',
                    Location2Value: '',
                    selectedSavedTRFScreenFilters: {
                        MoNumber: '',
                        MoFunctionalLocation: '',
                        TagFunctionalLocation: [],
                        TagFirstDateTested: '',
                        TagFirstTestDoneBy: [],
                        TagTestRecordId: [],
                        DeckHullBldg: '',
                        Quad: '',
                        Level: '',
                        Room: '',
                        Location2: ''
                    },
                    totalCount
                }
            });
        })
        .catch(error => {
            dispatch({
                type: TRF_ACTIONS.GET_TRFRECORDS_FAILURE,
                payload: {
                    tableLoading: false,
                },
                meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
            });
        }
        );
};

export const clearFilters = () => async (dispatch, getState) => {
    const { SavedTRF: { savedTRFDataAll } } = getState();
    const totalCount = savedTRFDataAll.length;
    await dispatch({
        type: TRF_ACTIONS.CLEAR_FILTERS,
        payload: {
            tableLoading: false,
            MoNumberValue: '',
            MoStatusValue: '',
            TagFunctionalLocationValue: '',
            TagFirstDateTestedValue: null,
            TagFirstTestDoneByValue: '',
            TagTestRecordIdValue: '',
            DeckHullBldgValue: '',
            QuadValue: '',
            LevelValue: '',
            RoomValue: '',
            Location2Value: '',
            searchedTRFData: savedTRFDataAll,
            tagFlocFilter: savedTRFDataAll,
            tagFirstTestDoneByFilter: savedTRFDataAll,
            tagTestRecordIdFilter: savedTRFDataAll,
            tagTestRecordTestResultFilter: savedTRFDataAll,
            tagTestRecordAsFoundResultFilter: savedTRFDataAll,
            selectedSavedTRFScreenFilters: {
                MoNumber: '',
                MoFunctionalLocation: '',
                TagFunctionalLocation: [],
                TagFirstDateTested: '',
                TagFirstTestDoneBy: [],
                TagTestRecordId: [],
                DeckHullBldg: '',
                Quad: '',
                Level: '',
                Room: '',
                Location2: ''
            },
            totalCount
        }
    });
};
export const setMoNumberValue = (moNumber) => (dispatch) => {
    dispatch({
        type: TRF_ACTIONS.SET_FILTER_VALUE,
        payload: {
            MoNumberValue: moNumber
        }
    });
};

export const setMoStatusValue = (moStatus) => (dispatch) => {
    dispatch({
        type: TRF_ACTIONS.SET_FILTER_VALUE,
        payload: {
            MoStatusValue: moStatus
        }
    });
};
export const setTagFirstDateTestedValue = (TagDate) => (dispatch) => {
    dispatch({
        type: TRF_ACTIONS.SET_FILTER_VALUE,
        payload: {
            TagFirstDateTestedValue: TagDate
        }
    });
};

export const setDeckHullBldgValue = (deckHullBldg) => (dispatch) => {
    dispatch({
        type: TRF_ACTIONS.SET_FILTER_VALUE,
        payload: {
            DeckHullBldgValue: deckHullBldg
        }
    });
};

export const setQuadValue = (quad) => (dispatch) => {
    dispatch({
        type: TRF_ACTIONS.SET_FILTER_VALUE,
        payload: {
            QuadValue: quad
        }
    });
};

export const setLevelValue = (level) => (dispatch) => {
    dispatch({
        type: TRF_ACTIONS.SET_FILTER_VALUE,
        payload: {
            LevelValue: level
        }
    });
};

export const setRoomValue = (room) => (dispatch) => {
    dispatch({
        type: TRF_ACTIONS.SET_FILTER_VALUE,
        payload: {
            RoomValue: room
        }
    });
};

export const setLocation2Value = (location) => (dispatch) => {
    dispatch({
        type: TRF_ACTIONS.SET_FILTER_VALUE,
        payload: {
            Location2Value: location
        }
    });
};

export const searchFilteredValues = (selectedFilter) => async (dispatch, getState) => {
    const { SavedTRF: { selectedSavedTRFScreenFilters, savedTRFDataAll } } = getState();
    const filters = { ...selectedSavedTRFScreenFilters, ...selectedFilter };
    let searchedData = savedTRFDataAll;
    if (checkNoFilters(filters)) {
        dispatch(clearFilters());
    } else {
        for (const [key, value] of Object.entries(filters)) {
            if (value !== '') {
                searchedData = searchFunction(key, value, searchedData);
            }
        }
        if (Object.values(selectedFilter)[0]?.length !== 0) {
            const filterKey = Object.keys(selectedFilter)[0];
            switch (filterKey) {
                case 'TagFunctionalLocation':
                    dispatch({
                        type: TRF_ACTIONS.GET_SEARCHTRFRECORDS_SUCCESS,
                        payload: {
                            tableLoading: false,
                            searchedTRFData: searchedData,
                            selectedSavedTRFScreenFilters: filters,
                            tagFirstTestDoneByFilter: searchedData,
                            tagTestRecordIdFilter: searchedData,
                            tagTestRecordTestResultFilter: searchedData,
                            tagTestRecordAsFoundResultFilter: searchedData,
                            totalCount: searchedData.length
                        }
                    });
                    break;
                case 'TagFirstTestDoneBy':
                    dispatch({
                        type: TRF_ACTIONS.GET_SEARCHTRFRECORDS_SUCCESS,
                        payload: {
                            tableLoading: false,
                            tagFlocFilter: searchedData,
                            tagTestRecordIdFilter: searchedData,
                            tagTestRecordTestResultFilter: searchedData,
                            searchedTRFData: searchedData,
                            selectedSavedTRFScreenFilters: filters,
                            tagTestRecordAsFoundResultFilter: searchedData,
                            totalCount: searchedData.length
                        }
                    });
                    break;
                case 'TagTestRecordId':
                    dispatch({
                        type: TRF_ACTIONS.GET_SEARCHTRFRECORDS_SUCCESS,
                        payload: {
                            tableLoading: false,
                            tagFlocFilter: searchedData,
                            tagFirstTestDoneByFilter: searchedData,
                            searchedTRFData: searchedData,
                            tagTestRecordAsFoundResultFilter: searchedData,
                            tagTestRecordTestResultFilter: searchedData,
                            selectedSavedTRFScreenFilters: filters,
                            totalCount: searchedData.length
                        }
                    });
                    break;
                case 'AsFoundResult':
                    dispatch({
                        type: TRF_ACTIONS.GET_SEARCHTRFRECORDS_SUCCESS,
                        payload: {
                            tableLoading: false,
                            tagFlocFilter: searchedData,
                            tagFirstTestDoneByFilter: searchedData,
                            searchedTRFData: searchedData,
                            selectedSavedTRFScreenFilters: filters,
                            totalCount: searchedData.length,
                            tagTestRecordIdFilter: searchedData,
                            tagTestRecordTestResultFilter: searchedData
                        }
                    });
                    break;
                case 'TestResult':
                    dispatch({
                        type: TRF_ACTIONS.GET_SEARCHTRFRECORDS_SUCCESS,
                        payload: {
                            tableLoading: false,
                            tagFlocFilter: searchedData,
                            tagFirstTestDoneByFilter: searchedData,
                            searchedTRFData: searchedData,
                            selectedSavedTRFScreenFilters: filters,
                            tagTestRecordAsFoundResultFilter: searchedData,
                            totalCount: searchedData.length,
                            tagTestRecordIdFilter: searchedData
                        }
                    });
                    break;
                default:
                    dispatch({
                        type: TRF_ACTIONS.GET_SEARCHTRFRECORDS_SUCCESS,
                        payload: {
                            tableLoading: false,
                            tagFirstTestDoneByFilter: searchedData,
                            tagFlocFilter: searchedData,
                            tagTestRecordIdFilter: searchedData,
                            tagTestRecordTestResultFilter: searchedData,
                            tagTestRecordAsFoundResultFilter: searchedData,
                            searchedTRFData: searchedData,
                            selectedSavedTRFScreenFilters: filters,
                            totalCount: searchedData.length
                        }
                    });
                    break;
            }
        } else {
            dispatch({
                type: TRF_ACTIONS.GET_SEARCHTRFRECORDS_SUCCESS,
                payload: {
                    tableLoading: false,
                    tagFirstTestDoneByFilter: searchedData,
                    tagFlocFilter: searchedData,
                    tagTestRecordIdFilter: searchedData,
                    tagTestRecordTestResultFilter: searchedData,
                    tagTestRecordAsFoundResultFilter: searchedData,
                    searchedTRFData: searchedData,
                    selectedSavedTRFScreenFilters: filters,
                    totalCount: searchedData.length
                }
            });
        }
    }
};

export const setMONumberSortOrder = (columnName) => (dispatch, getState) => {
    const { SavedTRF: { sortMONumberAscOrder } } = getState();
    dispatch({
        type: TRF_ACTIONS.MO_NUMBER_SORTORDER,
        payload: {
            sortMONumberAscOrder: !sortMONumberAscOrder,
            sortColumn: columnName,
            tableLoading: true
        }
    });
    dispatch(sortPrintScreenData(columnName, !sortMONumberAscOrder));
};

export const setTagFunctionalLocationSortOrder = (columnName) => (dispatch, getState) => {
    const { SavedTRF: { sortTagFunctionalLocationAscOrder } } = getState();
    dispatch({
        type: TRF_ACTIONS.TAG_FUNCTIONAL_LOCATION_SORTORDER,
        payload: {
            sortTagFunctionalLocationAscOrder: !sortTagFunctionalLocationAscOrder,
            sortColumn: columnName,
            tableLoading: true
        }
    });
    dispatch(sortPrintScreenData(columnName, !sortTagFunctionalLocationAscOrder));
};

export const setTagDateTestedSortOrder = (columnName) => (dispatch, getState) => {
    const { SavedTRF: { sortTagDateTestedAscOrder } } = getState();
    dispatch({
        type: TRF_ACTIONS.TAG_DATE_TESTED_SORTORDER,
        payload: {
            sortTagDateTestedAscOrder: !sortTagDateTestedAscOrder,
            sortColumn: columnName,
            tableLoading: true
        }
    });
    dispatch(sortPrintScreenData(columnName, !sortTagDateTestedAscOrder));
};

export const sortPrintScreenData = (colForSorting, sortAscOrder) => (dispatch, getState) => {
    const { SavedTRF: { searchedTRFData } } = getState();
    dispatch({
        type: TRF_ACTIONS.GET_SORTTAGRECORDS_PENDING,
        payload: {
            tableLoading: true
        }
    });
    sortFunction(searchedTRFData, colForSorting, sortAscOrder)
        .then(sortedData => {
            dispatch({
                type: TRF_ACTIONS.GET_SORTTAGRECORDS_SUCCESS,
                payload: {
                    tableLoading: false,
                    searchedTRFData: sortedData
                }
            });
        }
        )
        .catch(error => {
            dispatch({
                type: TRF_ACTIONS.GET_SORTTAGRECORDS_FAILURE,
                payload: {
                    tableLoading: false,
                },
                meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
            });
        }
        );
};
