import axios from 'axios';
import DataGridComponent from 'components/DataGrid/DataGridComponent';
import React, { useEffect, useState } from 'react';
import {
    multiTrfDetailsHeaderData, multiTrfDetailsApiEndpoint,
    multiTrfDetailsSearchState,
    multiTrfDetailsInitialState
} from './MultiTrfDetails.data';
import MultiTrfDetailsForm from './MultiTrfDetailsForm';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import { trackErrors } from '../helper';
import { APIEndpoints } from 'helpers/APILists';

const addTitle = 'Add New Record for Multi TRF';
const editTitle = 'Edit Record for Multi TRF';

const MultiTrfDetails = () => {
    const apiEndpoints = multiTrfDetailsApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(multiTrfDetailsInitialState);
    const [modalTitle, setModalTitle] = useState(addTitle);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'RowId',
        rowNumber: ''
    });
    const [dropdownsData, setDropdownsData] = useState({
        trfIdOptions: []
    });

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetTrfMasterLists)
            .then((res) => {
                setDropdownsData(prev => ({
                    ...prev,
                    trfIdOptions: res.data.filter(y => y.TrfType === 'Multi' && y.Status === 'Active').sort((a, b) => a.TrfId.localeCompare(b.TrfId, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })).map(x => x.TrfId),
                }));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitle(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };
    const parseData = (data) => new Promise((resolve, reject) => {
        (async () => {
            try {
                const result = data.map(item => ({
                    ...item,
                    Status: item?.Status ? 'Active' : 'Inactive',
                }));
            resolve(result);
            } catch (err) {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
                reject();
            }
        })();
    });
    const handleAddRecord = () => {
        setEditRowData(multiTrfDetailsInitialState);
        setModalTitle(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRefreshDatagrid(prev => !prev);
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
    };

    return (
        <>
            <DataGridComponent
                headerData={multiTrfDetailsHeaderData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                initialData={multiTrfDetailsSearchState}
                rowDetails={rowDetails}
                refreshDatagrid={refreshDatagrid}
                dataParser={parseData}
            />

            {showModal
                && (
                    <MultiTrfDetailsForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                        dropdownsData={dropdownsData}
                    />
                )}
        </>
    );
};

export default MultiTrfDetails;
