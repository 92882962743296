import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormInput } from 'elements/Inputs/FormInput/';
import { StyledLabel } from 'elements/Label/Label';
import { FormDropdown } from 'elements/Inputs/FormInput/Dropdown/Dropdown';
import { StyleDropdown } from '../AsFoundTestTableSection/AsFoundTestSection.styled';
import { asFoundPstMethodSectionData } from './AsFound_PST_Method.data';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

const AsFoundPstMethodSection = (props) => {
    const { changeHandler, formData, formName, isFormReadOnly, userRole } = props;
    const classes = useStyles();
    const formDropdownInput = (field) => field.isDropdown ? (
        <StyleDropdown
            formName={formName}
        >
            <FormDropdown
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                placeholder={field.placeholder}
                handleChange={changeHandler}
                options={field.options}
                isRequired={field.isRequired}
                width={field.width}
                inputTextAlign={field.inputTextAlign}
                labelWidth={field.labelWidth}
                readOnly={field.readOnly || isFormReadOnly}
                isDisabled={field.isDisabled}
                height={field.height}
                marginTop={field.marginTop}
                noDefaultOption={field.noDefaultOption}
                inputMarginRight={field.inputMarginRight}
                borderBottom={field.borderBottom}
            />
        </StyleDropdown>
    )
        : (
            <FormInput
            id={field.key}
            name={field.key}
            label={field.label}
            value={field.value}
            isRequired={field.isRequired}
            isCalculated={field.isCalculated}
            isCalculatedNew={field.isCalculatedNew}
            handleChange={changeHandler}
            readOnly={field.readOnly || isFormReadOnly}
            labelWidth={field.labelWidth}
            width={field.width}
            marginLeft={field.marginLeft}
            marginRight={field.marginRight}
            direction={field.direction}
            padding={field.padding}
            type={field.type}
            maxLength={field.maxLength}
            textAlign={field.textAlign}
            userRole={userRole}
            isDisabled={field.isDisabled}
            height={field.height}
            marginTop={field.marginTop}
            />
        );
    return (
        <Grid container spacing={1} className={classes.root}>
            {
                asFoundPstMethodSectionData(formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ paddingLeft: '4px', paddingRight: '4px', paddingTop: '0px', paddingBottom: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isLabel
                                    ? (
                                        <StyledLabel
                                            id={field.key}
                                            label={field.label}
                                            color={field.color}
                                            fontWeight={field.fontWeight}
                                            fontSize={field.fontSize}
                                            textAlign={field.textAlign}
                                            fontStyle={field.fontStyle}
                                            labelWidth={field.labelWidth}
                                            marginLeft={field.marginLeft}
                                            padding={field.padding}
                                        >
                                            {field.label}
                                        </StyledLabel>
                                    )
                                    : formDropdownInput(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AsFoundPstMethodSection);

AsFoundPstMethodSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    userRole: PropTypes.string,
};
