const ImageIdGenerator = (today) => {
    let timestamp = today.getFullYear().toString().substr(2, 2);
    timestamp += (today.getMonth() < 9 ? '0' : '');
    timestamp += today.getMonth() + 1;
    timestamp += ((today.getDate() < 10) ? '0' : '') + today.getDate().toString();
    timestamp += ((today.getHours() < 10) ? '0' : '')
    + today.getHours().toString() + ((today.getMinutes() < 10) ? '0' : '')
    + today.getMinutes().toString() + ((today.getSeconds() < 10) ? '0' : '')
    + today.getSeconds().toString();
    if (today.getMilliseconds() < 10) {
        timestamp += '00';
    } else if (today.getMilliseconds() < 100) {
        timestamp += '0';
    } else {
        timestamp += '';
    }
    timestamp += today.getMilliseconds().toString();
    return timestamp;
};

export default ImageIdGenerator;
