import { ExponentialToDecimal, FieldHasValue, ValidateNumberField } from 'helpers/Validation';
import { getAftDeleteIconStatus } from '../../AsFoundTestTableSection.helpers';

export const AsFoundTestDeviationFormula = (formData) => {
    if (formData.F676TransducerOutputSignal === 'Voltage') {
        return 'V';
    }
    if (formData.F676TransducerOutputSignal === 'Current') {
        return 'mA';
    }
    return '';
};

export const AsFoundTestDeviation1Formula = (ExpectedOutput, MeasuredOutput) => FieldHasValue(ExpectedOutput) && FieldHasValue(MeasuredOutput)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(ExpectedOutput) - ValidateNumberField(MeasuredOutput))) : '';

export const AsFoundResultMandatoryCheck = (formData, aftTestPoint) => {
    let flag = false;
    if (!getAftDeleteIconStatus()) {
        for (let index = 2; index < Number(aftTestPoint) + 2; index += 1) {
            if (!FieldHasValue(formData[`AftSimulatedGap${index}`]) || !FieldHasValue(formData[`AftDeviation${index}`])) {
                flag = false;
                break;
            }
            flag = true;
        }
    }
    return flag;
};

export const AsFoundResultFormula = (formData, aftTestPoint) => {
    const deviationValue = [];
    for (let index = 2; index < Number(aftTestPoint) + 2; index += 1) {
        deviationValue.push(Number(formData[`AftDeviation${index}`]));
    }
    if (FieldHasValue(formData.F101AftTolerance) && deviationValue.length > 0) {
        if (Math.max(...deviationValue) <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const AsFoundTestHmiReadingFormula = (formData) => {
    if (FieldHasValue(formData.F24HmiEngineeringUnit)) {
        return (formData.F24HmiEngineeringUnit);
    }
    return '';
};

export const ToleranceLabelFormula = (formData) => {
    if (formData.F676TransducerOutputSignal === 'Voltage') {
        return 'Tolerance (V)';
    }
    if (formData.F676TransducerOutputSignal === 'Current') {
        return 'Tolerance (mA)';
    }
    return 'Tolerance';
};

export const SystemReadingFormulaValueCheck = (formData, inputValue, aftTestPoint) => {
    let flag = false;
    for (let index = 2; index < Number(aftTestPoint) + 2; index += 1) {
        if (FieldHasValue(formData[`${inputValue}${index}`])) {
            flag = true;
            break;
        }
    }
    return flag;
};
