import axios from 'axios';
import { APIEndpoints } from 'helpers/APILists';
import { isEqual } from 'lodash';
import { TEST_ACTIONS } from '../TestEquipment.constants';
import { searchTestEquipment, sortTestEquipment, getTestMakeModelData } from '../TestEquipment.actions';
import { MESSAGE_TYPE } from '../../../common/common.constants';

export const setDataForAddTestEquipment = (initialState) => (dispatch) => {
    dispatch({
        type: TEST_ACTIONS.ADDTESTEQUIPMENT_SET_INITIALDATA,
        payload: {
            addTestEquipmentData: initialState
        }
    });
};

const trimTEIData = (teiData) => {
    const teiDataObject = teiData;
    for (const key in teiDataObject) {
        if (typeof teiDataObject[key] === 'string' && key !== 'CalDueDate') {
            teiDataObject[key] = teiDataObject[key]?.trim();
        }
    }
    return teiDataObject;
};

const displayTestEquipmentUnSavedChangesLabel = () => (dispatch, getState) => {
    const { TestEquipment: { editTestEquipmentData }, TestEquipment: { testEquipmentDataAll } } = getState();
    let originalData = testEquipmentDataAll.find(x => x.Rowid === editTestEquipmentData.Rowid);
    originalData = { ...originalData, CalDueDate: new Date(originalData.CalDueDate).setHours(0, 0, 0, 0), Notes: originalData.Notes ?? '' };
    const modifiedData = { ...editTestEquipmentData, CalDueDate: new Date(editTestEquipmentData.CalDueDate).setHours(0, 0, 0, 0), Notes: editTestEquipmentData.Notes ?? '' };
    delete modifiedData.IsCellRedClass;
    delete modifiedData.FormDeviceType;
    delete modifiedData.FormManufacturer;
    delete modifiedData.FormModel;
    const labelState = isEqual(originalData, modifiedData);
    dispatch({
        type: TEST_ACTIONS.UPDATETESTEQUIPMENT_UNSAVED_CHANGES_LABEL,
        payload: {
            unSavedChangesTE: !labelState
        }
    });
};

export const resetDataForAddTestEquipment = (initialState) => (dispatch) => {
    dispatch({
        type: TEST_ACTIONS.ADDTESTEQUIPMENT_RESET_DATA,
        payload: {
            addTestEquipmentData: initialState
        }
    });
};

export const updateDataForAddTestEquipment = (changeObj) => (dispatch) => {
    dispatch({
        type: TEST_ACTIONS.ADDTESTEQUIPMENT_UPDATE_DATA,
        payload: {
            ...changeObj
        }
    });
};

export const setDataForEditTestEquipment = (existingState) => (dispatch) => {
    dispatch({
        type: TEST_ACTIONS.EDITTESTEQUIPMENT_SET_EXISTINGDATA,
        payload: {
            editTestEquipmentData: existingState,
        }
    });
};

export const resetDataForEditTestEquipment = () => (dispatch, getState) => {
    const { TestEquipment: { editTestEquipmentData }, TestEquipment: { testEquipmentDataAll } } = getState();
    dispatch({
        type: TEST_ACTIONS.EDITTESTEQUIPMENT_RESET_DATA,
        payload: {
            editTestEquipmentData: testEquipmentDataAll.find(x => x.Rowid === editTestEquipmentData.Rowid),
            unSavedChangesTE: false
        }
    });
};

export const updateDataForEditTestEquipment = (changeObj) => (dispatch) => {
    dispatch({
        type: TEST_ACTIONS.EDITTESTEQUIPMENT_UPDATE_DATA,
        payload: {
            ...changeObj
        }
    });
    dispatch(displayTestEquipmentUnSavedChangesLabel());
};

const searchTestEqData = (dispatch, searchStringForTestEq, sortColumn, sortDeviceTypeAscOrder, sortStatusAscOrder) => {
    searchStringForTestEq && dispatch(searchTestEquipment(searchStringForTestEq));
    !searchStringForTestEq && sortColumn && (sortColumn === 'DeviceType' ? dispatch(
        sortTestEquipment(sortColumn, sortDeviceTypeAscOrder))
        : dispatch(sortTestEquipment(sortColumn, sortStatusAscOrder)));
};
export const PostAddTestEquipmentData = (obj) => async (dispatch, getState) => {
    const { TestEquipment: { addTestEquipmentData, searchStringForTestEq, sortColumn, sortDeviceTypeAscOrder, sortStatusAscOrder } } = getState();
    dispatch({
        type: TEST_ACTIONS.ADD_TESTEQUIPMENTRECORD_PENDING,
        payload: {
            tableLoading: true
        }
    });
    const modifiedAddTestEquipmentData = { ...obj, CalDueDate: new Date(addTestEquipmentData.CalDueDate).toLocaleDateString('en-US') };
    await axios.post(APIEndpoints.SaveTestEq, trimTEIData(modifiedAddTestEquipmentData))
        .then((res) => {
            dispatch({
                type: TEST_ACTIONS.ADD_TESTEQUIPMENTRECORD_SUCCESS,
                payload: {
                    tableLoading: false,
                    addTestEquipmentData: res.data
                }
            });
            dispatch(getTestMakeModelData()).then(() => {
                searchTestEqData(dispatch, searchStringForTestEq, sortColumn, sortDeviceTypeAscOrder, sortStatusAscOrder);
            });
        })
        .catch((err) => {
            dispatch({
                type: TEST_ACTIONS.ADD_TESTEQUIPMENTRECORD_FAILURE,
                payload: {
                    tableLoading: false,
                },
                meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
            });
        });
};

export const PutEditTestEquipmentData = (obj) => async (dispatch, getState) => {
    const { TestEquipment: { editTestEquipmentData, searchStringForTestEq, sortColumn, sortDeviceTypeAscOrder, sortStatusAscOrder } } = getState();
    dispatch({
        type: TEST_ACTIONS.EDIT_TESTEQUIPMENTRECORD_PENDING,
        payload: {
            tableLoading: true,
        }
    });
    const modifiedEditTestEquipmentData = { ...obj, CalDueDate: new Date(editTestEquipmentData.CalDueDate).toLocaleDateString('en-US') };
    await axios.put(APIEndpoints.SaveTestEq, trimTEIData(modifiedEditTestEquipmentData))
        .then((res) => {
            dispatch({
                type: TEST_ACTIONS.EDIT_TESTEQUIPMENTRECORD_SUCCESS,
                payload: {
                    tableLoading: false
                }
            });
            dispatch(getTestMakeModelData()).then(() => {
                searchTestEqData(dispatch, searchStringForTestEq, sortColumn, sortDeviceTypeAscOrder, sortStatusAscOrder);
            });
        })
        .catch((error) => {
            dispatch({
                type: TEST_ACTIONS.EDIT_TESTEQUIPMENTRECORD_FAILURE,
                payload: {
                    tableLoading: false,
                },
                meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
            });
        });
};
