export const VisualInspectSectionDataP732 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F193VisuallyInspect',
            label: `Visually inspect relay condition, LED indications, flyback diode, 
            security of mounting of relay on relay base, retainer clip, any loose wiring/terminations.`,
            value: formData.F193VisuallyInspect,
            isRequired: true,
            isMultiline: true,
            xs: 12,
            width: '50%',
            inputWidth: '50%',
            alignItems: 'inherit',
            marginTop: '15px'
        }]
    }]
});
