import { AsFoundTestTableF61AsFoundResultFormula, disableAFTValveFullStrokeTravelTime } from './P610.formulas';
import { abnormalWarning } from '../P727/P727.data';

export const placeholderName = 'Select Valve Position';
export const valveMovementPlaceHolder = 'Select Valve Movement';
export const AsFoundTestTableSectionDataP610 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F341ValveFullStrokeLocalPosition',
                    label: 'Valve Full Stroke Local Position',
                    value: formData.F341ValveFullStrokeLocalPosition,
                    isDropdown: true,
                    placeholder: placeholderName,
                    options: [
                        { name: placeholderName, value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                    isRequired: true,
                    labelWidth: '49.6%',
                    marginLeft: '8px',
                    textAlign: 'center',
                    borderBottom: disableAFTValveFullStrokeTravelTime(formData) ? '' : '0',
                    xs: 5,
                    rootMarginRight: '0px',
                    inputTextAlign: 'center',
                    height: '1.5rem'
                },
                {
                    key: 'NoDataP610',
                    xs: 1,
                    isLabel: true,
                    padding: '0'
                },
                {
                    key: 'F345ValveFullStrokeHmipositionIndication',
                    label: 'Valve Full Stroke HMI Position Indication',
                    value: formData.F345ValveFullStrokeHmipositionIndication,
                    isDropdown: true,
                    placeholder: placeholderName,
                    options: [
                        { name: placeholderName, value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                    xs: 6,
                    marginRight: '0.5rem',
                    direction: 'rtl',
                    labelWidth: '65%',
                    textAlign: 'center',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    rootMarginRight: '3.6px',
                    inputTextAlign: 'center'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F353AftValveFullStrokeMovement',
                    label: 'Valve Full Stroke Movement',
                    value: disableAFTValveFullStrokeTravelTime(formData) ? '' : formData.F353AftValveFullStrokeMovement,
                    isRequired: !disableAFTValveFullStrokeTravelTime(formData),
                    isDisabled: disableAFTValveFullStrokeTravelTime(formData),
                    readOnly: disableAFTValveFullStrokeTravelTime(formData),
                    isDropdown: !disableAFTValveFullStrokeTravelTime(formData),
                    placeholder: valveMovementPlaceHolder,
                    options: [
                        { name: valveMovementPlaceHolder, value: '' },
                        { name: 'Smooth', value: 'Smooth' },
                        { name: 'Abnormal', value: 'Abnormal' }],
                    xs: 5,
                    type: 'number',
                    maxLength: '10',
                    width: disableAFTValveFullStrokeTravelTime(formData) ? '39.7%' : '39.6%',
                    labelWidth: '49.6%',
                    marginLeft: '8px',
                    textAlign: 'center',
                    borderBottom: '0',
                    rootMarginRight: '0px',
                    inputTextAlign: 'center',
                    height: '1.5rem'
                },
                {
                    key: 'aftAbnormalWarning',
                    label: formData?.F353AftValveFullStrokeMovement === 'Abnormal'
                        ? abnormalWarning
                        : '',
                    isLabel: true,
                    xs: 7,
                    color: '#ea7c34',
                    fontSize: '8.5pt',
                    fontWeight: '600',
                    padding: '0'
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F342ValveFullStrokeTravelTime',
                    label: 'Valve Full Stroke Travel Time (sec)',
                    value: disableAFTValveFullStrokeTravelTime(formData) ? '' : formData.F342ValveFullStrokeTravelTime,
                    type: 'number',
                    xs: 5,
                    maxLength: '10',
                    isRequired: !disableAFTValveFullStrokeTravelTime(formData),
                    isDisabled: disableAFTValveFullStrokeTravelTime(formData),
                    readOnly: disableAFTValveFullStrokeTravelTime(formData),
                    width: '39.7%',
                    labelWidth: '49.6%',
                    marginLeft: '8px',
                    height: '1.2rem',
                    marginBottom: '4px',
                    borderBottom: '0',
                    textAlign: 'center'
                },
                {
                    key: 'NodataP610',
                    xs: 2,
                    isLabel: true,
                    padding: '0'
                },
                {
                    key: 'F61AsFoundResult',
                    label: 'As-Found Result',
                    value: AsFoundTestTableF61AsFoundResultFormula(formData),
                    xs: 5,
                    isCalculatedNew: true,
                    readOnly: true,
                    marginRight: '0.5rem',
                    direction: 'rtl',
                    height: '1.2rem',
                    width: '40%',
                    labelWidth: '60%',
                    textAlign: 'center',
                    borderRight: '0',
                    borderBottom: '0',
                    marginBottom: '4px',
                    inputMarginRight: '4px'
                }
            ]
        }
    ]
});
