import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { useStyles } from './ImageUploader.styled';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import { backgroundColor } from 'journeys/portal/Admin/AdminScreen.helper';

const ImageUploader = ({ onSubmit, required, value }) => {
    const classes = useStyles();
    const inputRef = useRef(null);
    const [showUploader, setShowUploader] = useState(false);
    const [imgData, setImgData] = useState(null);
    const [uploadError, setUploadError] = useState(false);
    const [cancelAlert, setCancelAlert] = useState(false);
    const bgColor = backgroundColor(value, required);
    const handleButtonClick = () => {
        setShowUploader(true);
    };

    const handleOnClose = () => {
        setCancelAlert(false);
        setImgData(null);
        inputRef.current.value = null;
        setUploadError(false);
        setShowUploader(false);
    };

    const handleAlert = () => {
        if (imgData) {
            setCancelAlert(true);
        } else {
            handleOnClose();
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const handleUploadImage = (e) => {
        const imageFile = e.target.files[0];
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|)$/i;
        // allow only png nad jpg file upload
        if (!allowedExtensions.exec(e.target.value)[0]) {
            setUploadError(true);
            inputRef.current.value = null;
            setImgData(null);
        } else {
            setUploadError(false);
            const reader = new FileReader();
            reader.onload = (e1) => {
                const img = document.createElement('img');
                img.onload = (event) => {
                    let { width, height } = img;
                    // to convert shorter image dimension to 200px and adjust accordingly
                    if (width > height) {
                        const ratio = width / height;
                        height = 200;
                        width = height * ratio;
                    } else {
                        const ratio = height / width;
                        width = 200;
                        height = width * ratio;
                    }
                    // Dynamically create a canvas element
                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;

                    const ctx = canvas.getContext('2d');

                    // Actual resizing
                    ctx.drawImage(img, 0, 0, width, height);

                    // Show resized image in preview element
                    const dataurl = canvas.toDataURL(imageFile.type);
                    setImgData(dataurl);
                };
                img.src = e1.target.result;
            };
            reader.readAsDataURL(imageFile);
        }
    };

    const handleDeleteImage = () => {
        setUploadError(false);
        inputRef.current.value = null;
        setImgData(null);
    };

    const handleSubmit = () => {
        onSubmit(imgData);
        handleOnClose();
    };

    return (
        <>
            <Button
                variant="outlined"
                size="small"
                style={{ textTransform: 'capitalize', fontSize: '11px', color: 'black', backgroundColor: bgColor, maxHeight: '27px' }}
                onClick={handleButtonClick}
            >
                Upload New
            </Button>
            {showUploader
                && (
                    <Dialog
                        open
                        scroll="paper"
                        maxWidth="md"
                        fullWidth
                    >
                        {cancelAlert && (
                            <AlertDialog
                                dialogMessage={AdminConstants.DIALOG_MESSAGE}
                                dialogOpenState={cancelAlert}
                                handleClose={() => handleUnsaveChanges()}
                                handleAlertFunction={handleOnClose}
                            />
                        )}
                        <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>
                            Upload Image
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: 'flex', marginTop: '15px' }}>
                                <div style={{ display: 'grid', gap: '20px', minWidth: '300px', marginRight: '10px', alignContent: 'center', justifyItems: 'center' }}>
                                    <InputLabel style={{ fontSize: '13px', color: 'black', fontWeight: '600' }}>
                                        Please select a PNG or JPEG file
                                    </InputLabel>
                                    <input
                                        ref={inputRef}
                                        type="file"
                                        accept=".png,.jpg"
                                        onChange={handleUploadImage}
                                        style={{ maxHeight: '320px', maxWidth: '180px', height: '30px', marginTop: '40px', marginBottom: '40px' }}
                                    />
                                    {uploadError && (
                                        <InputLabel style={{ fontSize: '12px', color: 'red', fontWeight: '600' }}>
                                            The selected file must be in .png or .jpg format
                                        </InputLabel>
                                    )}
                                </div>
                                <div style={{ height: '300px', padding: '20px', border: '2px dashed grey', flex: 'auto' }}>
                                    <img alt="" src={imgData} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px' }}>
                            {imgData
                                && (
                                    <Button
                                        classes={{ root: classes.button }}
                                        variant="outlined"
                                        onClick={handleSubmit}
                                    >
                                        Upload
                                    </Button>
                                )}
                            <Button
                                classes={{ root: classes.button }}
                                onClick={handleDeleteImage}
                                variant="outlined"
                            >
                                Reset
                            </Button>
                            <Button
                                classes={{ root: classes.button }}
                                onClick={handleAlert}
                                variant="outlined"
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
        </>

    );
};
export default ImageUploader;

ImageUploader.propTypes = {
    onSubmit: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.any
};
