import { commonConstants, valveToLouverDamper } from 'journeys/portal/Forms/Sections.constants';
import { calculateDeviation } from '../../AsFoundIP_TransmitterSection/AsFoundIP_TransmitterSection.formulas';
import { calculateResult, placeholderPerformDiscard } from '../../AFT_SolenoidLimitSection/AsFoundSolenoidLimitSection.data';
import { disableCondition, disableTestSection, enableDropdownCondtion } from '../../ALT_SolenoidLimitSection/AsLeftSolenoidLimitSection.data';
import { conditionalValueSelector } from 'helpers/Validation';
import { asLeftIPTransmitterTestResultData } from './P6142TestResult.data';
import { paddingvalue } from '../../AsFoundTestTableSection/Data/C11/C11.data';

export const disableLabelCondition = (disableField, value) => conditionalValueSelector(disableField, '', value);

export const asLeftIPTransmitterSectionData = (selectedTag, formName, formData) => {
    const disableTest2Key = formData.F900AltTest2IpPositioner;
    const disableTest3Key = formData.F920AltTest3PositionTransmitter;
    const testPerform1 = conditionalValueSelector(calculateResult(formData.F837IplelementIppositioner), commonConstants.Perform, disableTest2Key);
    const testPerform2 = conditionalValueSelector(calculateResult(formData.F838IplelementPositionTransmitter), commonConstants.Perform, disableTest3Key);
    const disableField = (formData.F61AsFoundResult === commonConstants.Pass_Result || formData.F619AsLeftTestRequired === commonConstants.No);

    return {
        rows: [
            {
                key: 1,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 4,
                        marginBottom: '3.5px'
                    },
                    {
                        key: 'F900AltTest2IpPositionerLabel',
                        value: disableLabelCondition(disableField, 'Test 2 - I/P Positioner'),
                        xs: 2,
                        isDisabled: disableField,
                        isLabelBold: true,
                        height: conditionalValueSelector(disableField, '1.35rem', '1.28rem'),
                        textAlign: 'center',
                        borderRight: '0',
                        borderBottom: '0',
                        borderLeft: '0',
                        borderTop: '0',
                        width: '100%',
                        readOnly: true,
                        labelWidth: '0',
                        marginTop: '10px',
                    },
                    {
                        key: 'F900AltTest2IpPositioner',
                        value: conditionalValueSelector(disableField, '', testPerform1),
                        isRequired: !disableField,
                        isDisabled: disableField,
                        isDropdown: enableDropdownCondtion(formData.F837IplelementIppositioner, disableField),
                        readOnly: calculateResult(formData.F837IplelementIppositioner) || disableField,
                        placeholder: placeholderPerformDiscard,
                        options: [
                            { name: placeholderPerformDiscard, value: '' },
                            { name: commonConstants.Perform, value: commonConstants.Perform },
                            { name: commonConstants.Discard, value: commonConstants.Discard }],
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        xs: 2,
                        inputTextAlign: 'center',
                        marginTop: '10px',
                        height: conditionalValueSelector((calculateResult(formData.F837IplelementIppositioner) || disableField), '1.25rem', '1.5rem'),
                    },
                    {
                        key: 'F920AltTest3PositionTransmitterLabel',
                        value: disableLabelCondition(disableField, 'Test 3 - Position Transmitter'),
                        isDisabled: disableField,
                        xs: 2,
                        isLabelBold: true,
                        fontWeight: '700',
                        width: '100%',
                        readOnly: true,
                        labelWidth: '0',
                        textAlign: 'center',
                        padding: '14px 0 0 0',
                        borderRight: '0',
                        borderBottom: '0',
                        borderLeft: '0',
                        borderTop: '0',
                        marginTop: '10px',
                        height: conditionalValueSelector(disableField, '1.35rem', '1.28rem'),
                    },
                    {
                        key: 'F920AltTest3PositionTransmitter',
                        value: conditionalValueSelector(disableField, '', testPerform2),
                        isRequired: !disableField,
                        isDisabled: disableField,
                        isDropdown: enableDropdownCondtion(formData.F838IplelementPositionTransmitter, disableField),
                        readOnly: calculateResult(formData.F838IplelementPositionTransmitter) || disableField,
                        placeholder: placeholderPerformDiscard,
                        options: [
                            { name: placeholderPerformDiscard, value: '' },
                            { name: commonConstants.Perform, value: commonConstants.Perform },
                            { name: commonConstants.Discard, value: commonConstants.Discard }],
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        xs: 2,
                        marginTop: '10px',
                        borderRight: '0',
                        inputTextAlign: 'center',
                        height: conditionalValueSelector((calculateResult(formData.F838IplelementPositionTransmitter) || disableField), '1.25rem', '1.5rem'),
                    }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'AltValvePositionCommand',
                        label: disableLabelCondition(disableField, `${valveToLouverDamper(formName)} Position Command (%)`),
                        isDisabled: disableField,
                        xs: 3,
                        labelWidth: '100%',
                        textAlign: 'center',
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        padding: paddingvalue
                    },
                    {
                        key: 'AltValveLocalPosition',
                        label: disableLabelCondition(disableField, `${valveToLouverDamper(formName)} Local Position (%)`),
                        isDisabled: disableField,
                        xs: 2,
                        isLabel: true,
                        labelWidth: '100%',
                        textAlign: 'center',
                        fontWeight: '700',
                        fontSize: '9pt',
                        padding: paddingvalue
                    },
                    {
                        key: 'AltDeviation1',
                        label: disableLabelCondition(disableField, 'Deviation (%)'),
                        isDisabled: disableField,
                        xs: 2,
                        labelWidth: '100%',
                        textAlign: 'center',
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        padding: paddingvalue
                    },
                    {
                        key: 'AltHMIPositionIndication',
                        label: disableLabelCondition(disableField, 'HMI Position Indication (%)'),
                        isDisabled: disableField,
                        xs: 2,
                        labelWidth: '100%',
                        textAlign: 'center',
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        padding: paddingvalue
                    },
                    {
                        key: 'AltDeviation2',
                        label: disableLabelCondition(disableField, 'Deviation (%)'),
                        isDisabled: disableField,
                        xs: 2,
                        labelWidth: '100%',
                        textAlign: 'center',
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        padding: paddingvalue
                    }]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1
                    },
                    {
                        key: 'AltValvePositionCommand',
                        value: disableLabelCondition(disableField, 0),
                        isDisabled: disableField,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                    },
                    {
                        key: 'F901AltValveLocalPosition1',
                        value: disableCondition(disableTest2Key, disableField, formData.F901AltValveLocalPosition1),
                        isRequired: !disableTestSection(disableTest2Key, disableField),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        readOnly: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        borderRight: '0',
                        borderLeft: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F910AltTest2Deviation1',
                        value: calculateDeviation(formData.F901AltValveLocalPosition1, formData.AltValvePositionCommand),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F921AltHmiPositionIndication1',
                        value: disableCondition(disableTest3Key, disableField, formData.F921AltHmiPositionIndication1),
                        isRequired: !disableTestSection(disableTest3Key, disableField),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        readOnly: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F930AltTest3Deviation1',
                        value: calculateDeviation(formData.F921AltHmiPositionIndication1, formData.AltValvePositionCommand),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'AltValvePositionCommand1',
                        value: disableLabelCondition(disableField, 25),
                        isDisabled: disableField,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                    },
                    {
                        key: 'F902AltValveLocalPosition2',
                        value: disableCondition(disableTest2Key, disableField, formData.F902AltValveLocalPosition2),
                        isRequired: !disableTestSection(disableTest2Key, disableField),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        readOnly: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F911AltTest2Deviation2',
                        value: calculateDeviation(formData.F902AltValveLocalPosition2, formData.AltValvePositionCommand1),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        borderBottom: '0',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F922AltHmiPositionIndication2',
                        value: disableCondition(disableTest3Key, disableField, formData.F922AltHmiPositionIndication2),
                        isRequired: !disableTestSection(disableTest3Key, disableField),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        readOnly: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F931AltTest3Deviation2',
                        value: calculateDeviation(formData.F922AltHmiPositionIndication2, formData.AltValvePositionCommand1),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'AltValvePositionCommand2',
                        value: disableLabelCondition(disableField, 50),
                        isDisabled: disableField,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F903AltValveLocalPosition3',
                        value: disableCondition(disableTest2Key, disableField, formData.F903AltValveLocalPosition3),
                        isRequired: !disableTestSection(disableTest2Key, disableField),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        readOnly: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        borderBottom: '0',
                        type: 'number',
                        maxLength: '10',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F912AltTest2Deviation3',
                        value: calculateDeviation(formData.F903AltValveLocalPosition3, formData.AltValvePositionCommand2),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F923AltHmiPositionIndication3',
                        value: disableCondition(disableTest3Key, disableField, formData.F923AltHmiPositionIndication3),
                        isRequired: !disableTestSection(disableTest3Key, disableField),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        readOnly: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F932AltTest3Deviation3',
                        value: calculateDeviation(formData.F923AltHmiPositionIndication3, formData.AltValvePositionCommand2),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'AltValvePositionCommand3',
                        value: disableLabelCondition(disableField, 75),
                        isDisabled: disableField,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                    },
                    {
                        key: 'F904AltValveLocalPosition4',
                        value: disableCondition(disableTest2Key, disableField, formData.F904AltValveLocalPosition4),
                        isRequired: !disableTestSection(disableTest2Key, disableField),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        readOnly: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F913AltTest2Deviation4',
                        value: calculateDeviation(formData.F904AltValveLocalPosition4, formData.AltValvePositionCommand3),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F924AltHmiPositionIndication4',
                        value: disableCondition(disableTest3Key, disableField, formData.F924AltHmiPositionIndication4),
                        isRequired: !disableTestSection(disableTest3Key, disableField),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        readOnly: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F933AltTest3Deviation4',
                        value: calculateDeviation(formData.F924AltHmiPositionIndication4, formData.AltValvePositionCommand3),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 7,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'AltValvePositionCommand4',
                        value: disableLabelCondition(disableField, 100),
                        isDisabled: disableField,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F905AltValveLocalPosition5',
                        value: disableCondition(disableTest2Key, disableField, formData.F905AltValveLocalPosition5),
                        isRequired: !disableTestSection(disableTest2Key, disableField),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        readOnly: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F914AltTest2Deviation5',
                        value: calculateDeviation(formData.F905AltValveLocalPosition5, formData.AltValvePositionCommand4),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F925AltHmiPositionIndication5',
                        value: disableCondition(disableTest3Key, disableField, formData.F925AltHmiPositionIndication5),
                        isRequired: !disableTestSection(disableTest3Key, disableField),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        readOnly: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F934AltTest3Deviation5',
                        value: calculateDeviation(formData.F925AltHmiPositionIndication5, formData.AltValvePositionCommand4),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 8,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'AltValvePositionCommand5',
                        value: disableLabelCondition(disableField, 75),
                        isDisabled: disableField,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F906AltValveLocalPosition6',
                        value: disableCondition(disableTest2Key, disableField, formData.F906AltValveLocalPosition6),
                        isRequired: !disableTestSection(disableTest2Key, disableField),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        readOnly: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F915AltTest2Deviation6',
                        value: calculateDeviation(formData.F906AltValveLocalPosition6, formData.AltValvePositionCommand5),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        borderBottom: '0',
                        type: 'number',
                        readOnly: true,
                        maxLength: '10',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F926AltHmiPositionIndication6',
                        value: disableCondition(disableTest3Key, disableField, formData.F926AltHmiPositionIndication6),
                        isRequired: !disableTestSection(disableTest3Key, disableField),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        readOnly: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F935AltTest3Deviation6',
                        value: calculateDeviation(formData.F926AltHmiPositionIndication6, formData.AltValvePositionCommand5),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 9,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'AltValvePositionCommand6',
                        value: disableLabelCondition(disableField, 50),
                        isDisabled: disableField,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        height: '1.2rem',
                        borderBottom: '0',
                        type: 'number',
                        maxLength: '10',
                        isLabelNew: true,
                        textAlign: 'center',
                    },
                    {
                        key: 'F907AltValveLocalPosition7',
                        value: disableCondition(disableTest2Key, disableField, formData.F907AltValveLocalPosition7),
                        isRequired: !disableTestSection(disableTest2Key, disableField),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        readOnly: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F916AltTest2Deviation7',
                        value: calculateDeviation(formData.F907AltValveLocalPosition7, formData.AltValvePositionCommand6),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        borderBottom: '0',
                        type: 'number',
                        readOnly: true,
                        maxLength: '10',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F927AltHmiPositionIndication7',
                        value: disableCondition(disableTest3Key, disableField, formData.F927AltHmiPositionIndication7),
                        isRequired: !disableTestSection(disableTest3Key, disableField),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        readOnly: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F936AltTest3Deviation7',
                        value: calculateDeviation(formData.F927AltHmiPositionIndication7, formData.AltValvePositionCommand6),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 10,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'AltValvePositionCommand7',
                        value: disableLabelCondition(disableField, 25),
                        isDisabled: disableField,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderBottom: '0',
                        isLabelNew: true,
                        textAlign: 'center',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F908AltValveLocalPosition8',
                        value: disableCondition(disableTest2Key, disableField, formData.F908AltValveLocalPosition8),
                        isRequired: !disableTestSection(disableTest2Key, disableField),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        readOnly: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F917AltTest2Deviation8',
                        value: calculateDeviation(formData.F908AltValveLocalPosition8, formData.AltValvePositionCommand7),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F928AltHmiPositionIndication8',
                        value: disableCondition(disableTest3Key, disableField, formData.F928AltHmiPositionIndication8),
                        isRequired: !disableTestSection(disableTest3Key, disableField),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        readOnly: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        borderBottom: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F937AltTest3Deviation8',
                        value: calculateDeviation(formData.F928AltHmiPositionIndication8, formData.AltValvePositionCommand7),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        borderBottom: '0',
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            {
                key: 11,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                    },
                    {
                        key: 'AltValvePositionCommand8',
                        value: disableLabelCondition(disableField, 0),
                        isDisabled: disableField,
                        xs: 3,
                        width: '100%',
                        labelWidth: '0',
                        readOnly: true,
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        isLabelNew: true,
                        textAlign: 'center',
                        fontSize: '9pt'
                    },
                    {
                        key: 'F909AltValveLocalPosition9',
                        value: disableCondition(disableTest2Key, disableField, formData.F909AltValveLocalPosition9),
                        isRequired: !disableTestSection(disableTest2Key, disableField),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        readOnly: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        textAlign: 'center',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: 'F918AltTest2Deviation9',
                        value: calculateDeviation(formData.F909AltValveLocalPosition9, formData.AltValvePositionCommand8),
                        isDisabled: disableTestSection(disableTest2Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        readOnly: true,
                        maxLength: '10',
                        height: '1.2rem',
                        textAlign: 'center',
                        isCalculatedNew: true,
                    },
                    {
                        key: 'F929AltHmiPositionIndication9',
                        value: disableCondition(disableTest3Key, disableField, formData.F929AltHmiPositionIndication9),
                        isRequired: !disableTestSection(disableTest3Key, disableField),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        readOnly: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        type: 'number',
                        maxLength: '10',
                        height: '1.2rem',
                        borderRight: '0',
                        textAlign: 'center',
                    },
                    {
                        key: 'F938AltTest3Deviation9',
                        value: calculateDeviation(formData.F929AltHmiPositionIndication9, formData.AltValvePositionCommand8),
                        isDisabled: disableTestSection(disableTest3Key, disableField),
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        height: '1.2rem',
                        type: 'number',
                        maxLength: '10',
                        readOnly: true,
                        textAlign: 'center',
                        isCalculatedNew: true,
                        borderRight: '0',
                    }]
            },
            asLeftIPTransmitterTestResultData(selectedTag, formData)
        ]
    };
};
