import { FieldHasValue } from 'helpers/Validation';

export const AsLeftResultFormulaM616 = (formData) => {
    if (FieldHasValue(formData.F657AltActivatedNotificationAppliances)
        && FieldHasValue(formData.F658AltConfirmAudibleAlarmsFunctionCorrectly)
        && FieldHasValue(formData.F659AltConfirmVisualAlarmsFunctionCorrectly)) {
        if (formData.F657AltActivatedNotificationAppliances === 'YES'
            && (formData.F658AltConfirmAudibleAlarmsFunctionCorrectly === 'YES'
                || formData.F659AltConfirmVisualAlarmsFunctionCorrectly === 'YES')
            && (formData.F658AltConfirmAudibleAlarmsFunctionCorrectly !== 'NO'
                && formData.F659AltConfirmVisualAlarmsFunctionCorrectly !== 'NO')) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
