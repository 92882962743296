import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import FormInput from 'elements/Inputs/FormInput/Input';
import { selectValveTravelSectionData } from './ValveTravelSection.data';

const ValveTravelSection = (props) => {
    const { selectedTag, changeHandler, dpsData, formName, formData, isFormReadOnly, userRole } = props;

    const filterViewData = selectValveTravelSectionData(selectedTag, dpsData, formName, formData);
    return (
        <div style={{ marginTop: '20px', marginLeft: '44%' }}>
            {
                filterViewData.rows.map(row => (
                    <Grid item container xs={12} key={row.key}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                <FormInput
                                    id={field.key}
                                    name={field.key}
                                    label={field.label}
                                    value={field.value}
                                    type={field.type}
                                    isRequired={field.isRequired}
                                    handleChange={changeHandler}
                                    marginLeft={field.marginLeft}
                                    width={field.width}
                                    labelWidth={field.labelWidth}
                                    isCalculatedNew={field.isCalculatedNew}
                                    direction={field.direction}
                                    borderTop={field.borderTop}
                                    borderBottom={field.borderBottom}
                                    borderLeft={field.borderLeft}
                                    borderRight={field.borderRight}
                                    marginRight={field.marginRight}
                                    readOnly={isFormReadOnly || field.readOnly}
                                    maxLength={field.maxLength}
                                    userRole={userRole}
                                    textAlign={field.textAlign}
                                    hasDPSError={field.hasDPSError}
                                />
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </div>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    dpsData: Forms.dpsData,
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ValveTravelSection);

ValveTravelSection.propTypes = {
    changeHandler: PropTypes.func,
    dpsData: PropTypes.object,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
