export const formatDate = (date) => date
    ? new Intl.DateTimeFormat('en-GB',
        {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: 'numeric',
            hourCycle: 'h12'
        }).format(date).replace(' ', '-').replace(' ', '-')
        .replace(',', '')
        .replace('am', 'AM')
        .replace('pm', 'PM')
        .replace('Sept', 'Sep') : '';

export const formatDateToUTC = (date) => date
    ? new Intl.DateTimeFormat('en-GB',
        {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: 'numeric',
            hourCycle: 'h12',
            timeZone: 'UTC'
        }).format(date).replace(' ', '-').replace(' ', '-')
        .replace(',', '')
        .replace('am', 'AM')
        .replace('pm', 'PM')
        .replace('Sept', 'Sep') : '';

export const parseDate = inputDate => {
    const date = new Date(inputDate);
    const formattedDate = date.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-').replace('Sept', 'Sep');
    const dateArray = formattedDate.split('-');
    const day = `0${dateArray[0]}`.slice(-2);
    dateArray.splice(0, 1, day);
    return dateArray.join('-');
};

export const getReferenceDate = (currentDate, ref) => new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + ref);
