import { AsFoundResultFormulaP615 } from './P615.formulas';

export const AsFoundTestTableSectionDataP615 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'As-FoundTest',
            label: 'As-Found Test',
            isLabel: true,
            xs: 12,
            fontWeight: '700',
            padding: '5px 0 8px 8px'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'F573AftSolenoidValveActionUponTrip',
            label: 'Solenoid Valve Action Upon Trip',
            value: formData.F573AftSolenoidValveActionUponTrip,
            xs: 6,
            isRequired: true,
            labelWidth: '51%',
            width: '49%',
            isDropdown: true,
            placeholder: 'Select Valve Action',
            options: [{ name: 'Select Valve Action', value: '' },
            { name: 'Quick', value: 'Quick' },
            { name: 'Sticking', value: 'Sticking' }],
            marginLeft: '8px',
            inputTextAlign: 'center',
            borderBottom: '0',
        }]
    },
    {
        key: 3,
        fields: [{
            key: 'F574AftSolenoidValvePositionAfterTrip',
            label: 'Solenoid Valve Position After Trip',
            value: formData.F574AftSolenoidValvePositionAfterTrip,
            xs: 6,
            marginLeft: '8px',
            isRequired: true,
            isDropdown: true,
            placeholder: 'Select Open or Closed',
            options: [{ name: 'Select Open or Closed', value: '' },
            { name: 'Open', value: 'Open' },
            { name: 'Closed', value: 'Closed' }],
            inputTextAlign: 'center',
            labelWidth: '51%',
            width: '49%',
            borderBottom: '0',
            marginBottom: '4px'
        },
        {
            key: 'F61AsFoundResult',
            label: 'As-Found Result',
            value: AsFoundResultFormulaP615(formData),
            readOnly: true,
            xs: 6,
            width: '50.2%',
            labelWidth: '49.8%',
            isCalculatedNew: true,
            textAlign: 'center',
            height: '1.25rem',
            borderRight: '0',
            marginRight: '8px',
            direction: 'rtl',
            inputMarginRight: '4px',
            isLabelBold: true,
            fontWeight: '700',
            borderBottom: '0',
        }]
    }]
});
