/* eslint-disable no-unused-vars */
import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import axiosInterceptor from 'utils/RequestInterceptor'; // interceptor code is included to app js
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import AdminHandler from 'journeys/portal/Admin/AdminHandler';
import theme from './theme';
import configureStore from './dev-store';
import { MsalAuthWrapper } from './utils/MsalAuthWrapper/MsalAuthWrapper';
import AppContainer from './AppContainer';
import { reactPlugin } from './AppInsights';

const App = () => {
    const { store } = configureStore();
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <MsalAuthWrapper>
                    <AppInsightsContext.Provider value={reactPlugin}>
                        <BrowserRouter>
                            <Switch>
                                <Route path="/admin" component={AdminHandler} />
                                <Route path="/*" component={AppContainer} />
                            </Switch>
                        </BrowserRouter>
                    </AppInsightsContext.Provider>
                </MsalAuthWrapper>
            </ThemeProvider>
        </Provider>
    );
};

export default App;
