import { Setpoint, Result, commonConstants, asLeftTestLabel } from 'journeys/portal/Forms/Sections.constants';
import { borderCondition, halfNumberOfFields, stepMultiplier } from '../../../AsFoundTestTableSection/Data/S72/S72.data';
import { DeviationAltFormula, expectedReadingFormula, valueToApplyFormula, AsLeftResultFormula, totalNumberOfFields, getValueToApplyUnit, getUnits } from '../../../AsFoundTestTableSection/Data/S72/S72.formula';
import { conditionalValueSelector } from 'helpers/Validation';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';

export const AsLeftTestTableSectionDataS72 = (formName, formData) => {
    const disableAsLeftSection = (formData.F102AftResult === Result.RESULT_PASS || formData.F619AsLeftTestRequired === commonConstants.NO);
    const readingUnit = disableAsLeftSection ? '' : getUnits(formData);
    const valueToApplyUnit = disableAsLeftSection ? '' : getValueToApplyUnit(formData, formName);
    const valveToApplylabel = `${Setpoint.VALUE_TO_APPLY} ${valueToApplyUnit}`;
    const expectedReadingLabel = `Expected Reading ${readingUnit}`;
    const actualReadingLabel = `Actual Tx Reading ${readingUnit}`;
    const rows = [
        {
            key: 1,
            fields: [{
                key: 'As-LeftTest',
                label: asLeftTestLabel(formName),
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'Step',
                    label: 'Step (%)',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt'
                },
                {
                    key: 'ValueToApply',
                    label: valveToApplylabel,
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt'
                },
                {
                    key: 'ExpectedReading',
                    label: expectedReadingLabel,
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt'
                },
                {
                    key: 'ActualTxReading',
                    label: actualReadingLabel,
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt'
                },
                {
                    key: 'Deviation',
                    label: 'Deviation(%)',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt'
                }]
        }
    ];

    for (let i = 0; i <= totalNumberOfFields; i += 1) {
        const value = i <= halfNumberOfFields ? i * stepMultiplier : (totalNumberOfFields - i) * stepMultiplier;
        rows.push({
            key: i + 3,
            fields: [
                {
                    key: `StepKey${i}`,
                    value: conditionalValueSelector(disableAsLeftSection, '', value),
                    isDisabled: disableAsLeftSection,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    readOnly: true,
                    type: 'number',
                    height: '1.2rem',
                    borderBottom: borderCondition(i),
                    borderLeft: '0',
                    borderRight: '0',
                    marginLeft: '4px',
                    textAlign: 'center',
                    fontSize: '9pt',
                },
                {
                    key: `AltValueToApply${i + 2}`,
                    value: conditionalValueSelector(disableAsLeftSection, '', valueToApplyFormula(formData, formName, value, formData[`AltExpectedReading${i + 2}`])),
                    isDisabled: disableAsLeftSection,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    type: 'number',
                    maxLength: '10',
                    readOnly: true,
                    borderBottom: borderCondition(i),
                    textAlign: 'center',
                    isCalculatedNew: !disableAsLeftSection,
                },
                {
                    key: `AltExpectedReading${i + 2}`,
                    value: conditionalValueSelector(disableAsLeftSection, '', expectedReadingFormula(formData, formName, value)),
                    isDisabled: disableAsLeftSection,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    type: 'number',
                    maxLength: '10',
                    readOnly: true,
                    borderBottom: borderCondition(i),
                    textAlign: 'center',
                    isCalculatedNew: !disableAsLeftSection,
                    borderRight: '0',
                    borderLeft: '0'
                },
                {
                    key: `AltTxReading${i + 2}`,
                    value: conditionalValueSelector(disableAsLeftSection, '', formData[`AltTxReading${i + 2}`]),
                    isDisabled: disableAsLeftSection,
                    readOnly: disableAsLeftSection,
                    xs: 2,
                    isRequired: !disableAsLeftSection,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    type: 'number',
                    maxLength: '10',
                    borderBottom: borderCondition(i),
                    textAlign: 'center',
                    borderRight: '0',
                },
                {
                    key: `AltDeviation${i + 2}(%)`,
                    value: conditionalValueSelector(disableAsLeftSection, '', DeviationAltFormula(formName, formData, formData[`AltExpectedReading${i + 2}`], formData[`AltTxReading${i + 2}`])),
                    isDisabled: disableAsLeftSection,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    type: 'number',
                    maxLength: '10',
                    readOnly: true,
                    borderBottom: borderCondition(i),
                    textAlign: 'center',
                    isCalculatedNew: !disableAsLeftSection,
                }
            ]
        });
    }
    rows.push({
        key: 12,
        fields: [
            {
                key: 'Nodata',
                isLabel: true,
                xs: 6,
            },
            {
                key: 'F103AltResultLabel',
                label: 'As-Left Result',
                xs: 2,
                isLabel: true,
                fontWeight: '700',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F103AltResult',
                value: conditionalValueSelector(disableAsLeftSection, '', AsLeftResultFormula(formName, formData)),
                isDisabled: disableAsLeftSection,
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                height: '1.25rem',
                readOnly: true,
                borderBottom: '0',
                textAlign: 'center',
                isCalculatedNew: !disableAsLeftSection,
                borderTop: '0',
                marginBottom: '4px'
            }]
    });
    return { rows };
};
