import { ExponentialToDecimal, FieldHasValue, ValidateField, ValidateNumberField } from 'helpers/Validation';

export const AsLeftTableF63Formula2Inst1Formula = (formData) => {
    let Hmi2Inst1;
    if (FieldHasValue(formData.F26InstrumentUrv)) {
        Hmi2Inst1 = '0% - '.concat(ValidateField(formData.F25InstrumentLrv))
            .concat(' ')
            .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        return Hmi2Inst1.length > 255 ? Hmi2Inst1.slice(0, 255) : Hmi2Inst1;
    }
    return '0%';
};

export const AsLeftTableF64Formula2Inst2Formula = (formData) => {
    let Hmi2Inst2;
    if (FieldHasValue(formData.F26InstrumentUrv)) {
        Hmi2Inst2 = '50% - '.concat(ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)
            + (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) * (0.5)))
            .concat(' ')
            .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        return Hmi2Inst2.length > 255 ? Hmi2Inst2.slice(0, 255) : Hmi2Inst2;
    }
    return '50%';
};

export const AsLeftTableF65Formula2Inst3Formula = (formData) => {
    let Hmi2Inst3;
    if (FieldHasValue(formData.F26InstrumentUrv)) {
        Hmi2Inst3 = '100% - '
            .concat(ValidateField(formData.F26InstrumentUrv))
            .concat(' ')
            .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        return Hmi2Inst3.length > 255 ? Hmi2Inst3.slice(0, 255) : Hmi2Inst3;
    }
    return '100%';
};

export const AsLeftTableF78Deviation2Eu1Formula = (formData) => {
    if (FieldHasValue(formData.F66Test2ModuleReading1)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F69Tx2Reading1) - ValidateNumberField(formData.F66Test2ModuleReading1)));
    }
    return '';
};

export const AsLeftTableF79Deviation2Eu2Formula = (formData) => {
    if (FieldHasValue(formData.F67Test2ModuleReading2)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F70Tx2Reading2) - ValidateNumberField(formData.F67Test2ModuleReading2)));
    }
    return '';
};

export const AsLeftTableF80Deviation2Eu3Formula = (formData) => {
    if (FieldHasValue(formData.F68Test2ModuleReading3)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F71Tx2Reading3) - ValidateNumberField(formData.F68Test2ModuleReading3)));
    }
    return '';
};
