import React from 'react';
import { connect } from 'react-redux';
import Form from '../../FormsTemplate';
import { FormNames } from '../../Forms.constants';

import UserInfoSection from '../../Sections/UserInfoSection/UserInfoSection';
import ManufacturerModelSection from '../../Sections/ManufacturerModelSection/ManufacturerModelSection';
import HMISection from '../../Sections/HMISection/HMISection';
import VisualInspectSection from '../../Sections/VisualInspectSection/VisualInspectSection';
import ValveTravelSection from '../../Sections/ValveTravelSection/ValveTravelSection';
import AsFoundTestTableSection from '../../Sections/AsFoundTestTableSection/AsFoundTestTableSection';
import AsLeftTestTableSection from '../../Sections/AsLeftTestTableSection/AsLeftTestTableSection';
import ValveSizeSection from '../../Sections/ValveSizeSection/ValveSizeSection';
import NoteSection from '../../Sections/NoteSection/NoteSection';
import BorderedBox from '../../BorderedBox';

const M613Form = (props) => (
    <Form
        formName={FormNames.M613}
    >
        <UserInfoSection />
        <ManufacturerModelSection />
        <HMISection />
        <VisualInspectSection />
        <ValveTravelSection />
        <BorderedBox>
            <AsFoundTestTableSection />
        </BorderedBox>
        <BorderedBox>
            <AsLeftTestTableSection />
        </BorderedBox>
        <ValveSizeSection />
        <NoteSection />
    </Form>
);

const mapStateToProps = ({ Forms }) => ({
    selectedTag: Forms.selectedTag,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(M613Form);
