import { FieldHasValue, ValidateNumberField, ExponentialToDecimal, CustomRound } from 'helpers/Validation';
import { expectedReadingMultiplier1, expectedReadingMultiplier2, expectedReadingMultiplier3, valueToApplyConstant1, valueToApplyConstant2 } from '../../../AsFoundTestTableSection/Data/S72/S72.formula';

export const setpointValueApplyFormulaS72 = (formData, setPoint) => {
    if (!FieldHasValue(setPoint)) {
        return '';
    }
    const hmiLrv = ValidateNumberField(formData.F21HmiLrv);
    const hmiUrv = ValidateNumberField(formData.F22HmiUrv);
    const instrumentLrv = ValidateNumberField(formData.F25InstrumentLrv);
    const instrumentUrv = ValidateNumberField(formData.F26InstrumentUrv);
    const setPointValue = ValidateNumberField(setPoint);
    if (FieldHasValue(formData.F979SqrtExtraction === 'YES' && FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F25InstrumentLrv) && FieldHasValue(formData.F26InstrumentUrv) && FieldHasValue(setPoint))) {
        return ExponentialToDecimal(Math.pow((setPointValue - hmiLrv) / (hmiUrv - hmiLrv), 2) * (instrumentUrv - instrumentLrv) + instrumentLrv);
    }

    if (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F25InstrumentLrv) && FieldHasValue(formData.F26InstrumentUrv) && FieldHasValue(setPoint)) {
        return ExponentialToDecimal((((setPointValue - hmiLrv) / (hmiUrv - hmiLrv)) * (instrumentUrv - instrumentLrv)) + instrumentLrv);
    }
    return '';
};

export const setpointValueApplyFormulaS73 = (formData, value) => {
    if (!FieldHasValue(value)) {
        return '';
    }
    if (formData.F987UsePtConversion === 'NO') {
        return value;
    }
    if (FieldHasValue(value)) {
        const expectedReadingValue = ValidateNumberField(value);
        const part1 = 1 + expectedReadingValue * expectedReadingMultiplier1;
        const part2 = Math.pow(expectedReadingValue, 2) * expectedReadingMultiplier2;
        const part3 = expectedReadingValue < 0 ? expectedReadingMultiplier3 * (expectedReadingValue - 100) * Math.pow(expectedReadingValue, 3) : 0;
        const result = 100 * (part1 + part2 + part3);
        return Math.round(result * 10) / 10;
    }
    return '';
};

export const setpointValueApplyFormulaS74 = (formData, setPoint) => {
    if (!FieldHasValue(setPoint)) {
        return '';
    }
    if (FieldHasValue(formData.F988ActualSimualtedLevel) === 'Simulated' && FieldHasValue(formData.F989SimulatedCurrentInjection) === 'YES'
        && FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F25InstrumentLrv) && FieldHasValue(formData.F26InstrumentUrv) && FieldHasValue(setPoint)) {
        return ExponentialToDecimal(CustomRound(((setPoint - formData.F21HmiLrv)
            / (formData.F22HmiUrv - formData.F21HmiLrv)) * valueToApplyConstant1) + valueToApplyConstant2);
    }
    return setPoint;
};

export const setpointValueApplyFormula = (formName, formData, value) => {
    if (formName === 'S7-3') {
        return setpointValueApplyFormulaS73(formData, value);
    }
    if (formName === 'S7-2') {
        return setpointValueApplyFormulaS72(formData, value);
    }
    return setpointValueApplyFormulaS74(formData, value);
};
