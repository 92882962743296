import { FieldHasValue, ValidateField, ValidateNumberField, ExponentialToDecimal } from 'helpers/Validation';

export const isF28InstEUHasValue = (formData) => FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '';

export const AsFoundTestTableHmiInst1Formula = (formData) => (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F25InstrumentLrv))
    ? '0% - '.concat(ValidateField(formData.F21HmiLrv))
        .concat('% - ')
        .concat(ValidateField(formData.F25InstrumentLrv))
        .concat(isF28InstEUHasValue(formData))
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '0%';

export const AsFoundTestTableHmiInst2Formula = (formData) => (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F22HmiUrv)
    && FieldHasValue(formData.F25InstrumentLrv) && FieldHasValue(formData.F26InstrumentUrv))
    ? '50% - '.concat(ExponentialToDecimal(ValidateNumberField(formData.F21HmiLrv)
        + (ValidateNumberField(formData.F22HmiUrv) - ValidateNumberField(formData.F21HmiLrv)) * 0.5))
        .concat('% - ')
        .concat(ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)
            + (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) * (0.5)))
        .concat(isF28InstEUHasValue(formData))
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '50%';

export const AsFoundTestTableHmiInst3Formula = (formData) => (FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F26InstrumentUrv))
    ? '100% - '.concat(ValidateField(formData.F22HmiUrv))
        .concat('% - ')
        .concat(ValidateField(formData.F26InstrumentUrv))
        .concat(isF28InstEUHasValue(formData))
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '100%';

export const AsFoundTestTableDeviationEu1Formula = (formData) => FieldHasValue(formData.F206AftIndependentReading1) && FieldHasValue(formData.F209AftTxreading1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F209AftTxreading1) - ValidateNumberField(formData.F206AftIndependentReading1))) : '';

export const AsFoundTestTableDeviationEu2Formula = (formData) => FieldHasValue(formData.F207AftIndependentReading2) && FieldHasValue(formData.F210AftTxreading2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F210AftTxreading2) - ValidateNumberField(formData.F207AftIndependentReading2))) : '';

export const AsFoundTestTableDeviationEu3Formula = (formData) => FieldHasValue(formData.F208AftIndependentReading3) && FieldHasValue(formData.F211AftTxreading3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F211AftTxreading3) - ValidateNumberField(formData.F208AftIndependentReading3))) : '';
