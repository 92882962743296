import { LeftResultAsLeftResultFormulaM68 } from './M68.formulas';
import { labelWidth } from '../HMISection/Data/P710/P710.data';

export const paddingValAFWS = '5px 0 5px 8px';
export const AsLeftTestWorkshopData = (selectedTag, formName, formData) => {
    const disableAsLeftTestWorkshop = formData.F383IsThereHazardousAreaRestriction === 'NO' || formData.F396AftResult2 === 'PASS';
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'ASLEFTTEST2',
                    label: 'As-Left Test - Workshop',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: paddingValAFWS
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F389AltConfirmThatExternalHeatAppliedWorkshopLabel',
                        label: 'Confirm that external heat has been applied to the detector in the workshop (any heat source such as hot air gun or magnet)',
                        xs: 4,
                        labelWidth: '98%',
                        padding: '0 0 0 8px',
                        isLabel: true,
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F389AltConfirmThatExternalHeatAppliedWorkshop',
                        value: disableAsLeftTestWorkshop ? '' : formData.F389AltConfirmThatExternalHeatAppliedWorkshop,
                        isRequired: !disableAsLeftTestWorkshop,
                        isDisabled: disableAsLeftTestWorkshop,
                        readOnly: disableAsLeftTestWorkshop,
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        isDropdown: !disableAsLeftTestWorkshop,
                        height: '3.24rem',
                        inputTextAlign: 'center',
                        placeholder: 'Select YES or NO',
                        options: [{ name: 'Select YES or NO', value: '' },
                        { name: 'YES', value: 'YES' },
                        { name: 'NO', value: 'NO' }],
                        paddingPlaceholder: '13px',
                        arrowMarginTop: '13px'
                    },
                ]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F390AltMeasureDetectorMaVOutputWithMultimeterLabel',
                        label: 'Measure detector mA/V output with multimeter (m/A)',
                        xs: 4,
                        labelWidth: '98%',
                        padding: paddingValAFWS,
                        isLabel: true,
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F390AltMeasureDetectorMaVOutputWithMultimeter',
                        value: disableAsLeftTestWorkshop ? '' : formData.F390AltMeasureDetectorMaVOutputWithMultimeter,
                        isRequired: !disableAsLeftTestWorkshop,
                        isDisabled: disableAsLeftTestWorkshop,
                        readOnly: disableAsLeftTestWorkshop,
                        xs: 2,
                        borderBottom: '0',
                        borderTop: '0',
                        width: '100%',
                        maxLength: '10',
                        type: 'number',
                        labelWidth: '0',
                        padding: '0 0 0 8px',
                        height: '1.65rem',
                        textAlign: 'center',
                    },
                ]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F391AltRecordConfirmedFireMaVOutputLevelLabel',
                        label: 'Record "confirmed fire" mA/V output level from detector datasheet or manual (m/A)',
                        xs: 4,
                        labelWidth: '98%',
                        padding: paddingValAFWS,
                        isLabel: true,
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F391AltRecordConfirmedFireMaVOutputLevel',
                        value: disableAsLeftTestWorkshop ? '' : formData.F391AltRecordConfirmedFireMaVOutputLevel,
                        isRequired: !disableAsLeftTestWorkshop,
                        isDisabled: disableAsLeftTestWorkshop,
                        readOnly: disableAsLeftTestWorkshop,
                        xs: 2,
                        borderBottom: '0',
                        width: '100%',
                        maxLength: '10',
                        type: 'number',
                        labelWidth: '0',
                        height: '2.24rem',
                        textAlign: 'center',
                        marginBottom: '4px'
                    },
                    {
                        key: 'F397AltResult2Label',
                        label: 'As-Left Workshop Result',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '700',
                        labelWidth,
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: '25px 5px 0 0',
                    },
                    {
                        key: 'F397AltResult2',
                        value: disableAsLeftTestWorkshop ? '' : LeftResultAsLeftResultFormulaM68(formData),
                        isDisabled: disableAsLeftTestWorkshop,
                        isCalculatedNew: true,
                        xs: 2,
                        readOnly: true,
                        fontWeight: '700',
                        borderBottom: '0',
                        borderRight: '0',
                        inputMarginRight: '4px',
                        width: '100%',
                        height: '1.16rem',
                        labelWidth: '0',
                        direction: 'rtl',
                        textAlign: 'center',
                        marginTop: '1.08rem',
                    },
                ]
            }
        ]
    };
};
export const selectAsLeftTestWorkshopSectionData = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'M6-8') {
        filterViewData = AsLeftTestWorkshopData(selectedTag, formName, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
