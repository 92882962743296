export const TestResultSectionDataC11C13C14 = (selectedTag, formData, formName) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F82HmiReadingAt',
                    label: 'HMI Reading After Test',
                    value: formData.F82HmiReadingAt,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    xs: 4,
                    labelWidth: '50%',
                    width: '50%',
                    direction: 'rtl',
                    textAlign: 'center'
                },
                {
                    key: 'Nodata',
                    isLabel: true,
                    xs: 1,
                },
                {
                    key: 'F83ProcessGaugeReadingAtLabel',
                    label: 'Process Gauge Reading After Test',
                    labelWidth: '97%',
                    xs: 3,
                    height: '1.2rem',
                    padding: '5px 0 0 0',
                    isLabel: 'true',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F83ProcessGaugeReadingAt',
                    value: formData.F83ProcessGaugeReadingAt,
                    type: 'number',
                    maxLength: '10',
                    isRequired: true,
                    labelWidth: '0',
                    xs: 4,
                    height: '1.2rem',
                    textAlign: 'center',
                    width: '100%'
                }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'Nodata',
                    isLabel: true,
                    xs: 6
                },
                {
                    key: 'F85TestResultLabel',
                    label: 'Test Result',
                    xs: 2,
                    marginRight: '0.5rem',
                    labelWidth: '96%',
                    padding: '5px 0 0 0',
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'end',
                    isLabel: 'true',
                },
                {
                    key: 'F85TestResult',
                    value: formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: [{ name: 'Select Test Result', value: '' },
                    { name: 'Pass', value: 'Pass' },
                    { name: 'Fail', value: 'Fail' }],
                    xs: 4,
                    labelWidth: '0',
                    width: '100%',
                    borderTop: '0'
                }]
        }]
});
