import { FieldHasValue, ValidateNumberField, IsANumber } from 'helpers/Validation';

export const FoundResultF102AftResultFormula = (formData) => {
    if (FieldHasValue(formData.F601MaxTolerableLeakageRate) && IsANumber(formData.F601MaxTolerableLeakageRate)
        && FieldHasValue(formData.F618AftCalculatedValveLeakageRate)) {
        if (ValidateNumberField(formData.F618AftCalculatedValveLeakageRate) <= ValidateNumberField(formData.F601MaxTolerableLeakageRate)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
