import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { disableAsLeftTestRequired } from '../../../AsLeftTestTableSection/Data/P66/P66.data';
import { conditionalValueSelector } from 'helpers/Validation';

export const FailureModeSectionDataP66 = (formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F619AsLeftTestRequiredLabel',
            label: 'As-Left Test Required?',
            xs: 4,
            labelWidth,
            padding: '0 0 0 8px',
            isLabel: true,
            fontWeight: '600',
            fontSize: '9pt',
            marginTop: '15px'
        },
        {
            key: 'F619AsLeftTestRequired',
            value: conditionalValueSelector(disableAsLeftTestRequired(formData), '', formData.F619AsLeftTestRequired),
            isDisabled: disableAsLeftTestRequired(formData),
            readOnly: disableAsLeftTestRequired(formData),
            isDropdown: !disableAsLeftTestRequired(formData),
            placeholder: Placeholder.SELECT_YES_OR_NO,
            options: [{ name: Placeholder.SELECT_YES_OR_NO, value: '' },
            { name: 'YES', value: 'YES' },
            { name: 'NO', value: 'NO' }],
            labelWidth: '0%',
            width: '100%',
            inputTextAlign: 'center',
            xs: 2,
            marginTop: '15px'
        }]
    }]
});
