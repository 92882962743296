import { FoundResultF102AftResultFormula } from './P726.formulas';
import { paddingValue, placeholderUnit } from '../P725/P725.data';
import { paddingValueTR } from '../P711/P711.data';

export const AsFoundTestTableSectionDataP726 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'AsFoundTest',
            label: 'As-Found Test',
            isLabel: true,
            fontWeight: '700',
            padding: '8px 0 0 8px'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'F604AftDifferentialPressureAcrossValve',
            label: 'Differential Pressure Across Valve',
            value: selectedTag.DateTested ? formData?.F604AftDifferentialPressureAcrossValve : formData?.F604AftDifferentialPressureAcrossValve ?? selectedTag?.TsprHmiUrv,
            type: 'number',
            maxLength: '10',
            xs: 6,
            labelWidth: '63%',
            width: '64%',
            height: '1.26rem',
            marginLeft: '7px',
            marginRight: '0.5rem',
            textAlign: 'center',
            marginTop: '5px',
        },
        {
            key: 'F605AftUnitOfMeasurement',
            label: 'Unit of Measurement',
            value: formData.F605AftUnitOfMeasurement,
            isDropdown: true,
            placeholder: placeholderUnit,
            options: [
                { name: placeholderUnit, value: '' },
                { name: 'bar', value: 'bar' },
                { name: 'kPa', value: 'kPa' },
                { name: 'psi', value: 'psi' }],
            inputTextAlign: 'center',
            xs: 6,
            labelWidth: '65%',
            width: '33%',
            height: '1.6rem',
            marginRight: '0.5rem',
            direction: 'rtl',
            marginTop: '5px',
            borderRight: '0',
        }]
    },
    {
        key: 3,
        fields: [{
            key: 'MeasurementPoints',
            label: 'Measurement Points',
            isLabel: true,
            fontWeight: '700',
            fontSize: '9pt',
            xs: 3,
            textAlign: 'center',
            labelWidth: '100%',
            padding: '5px 0 0 0'
        },
        {
            key: 'AcousticEmission_Signal_Readings',
            label: 'Acoustic Emission Signal Readings (dB)',
            isLabel: true,
            fontWeight: '700',
            xs: 9,
            textAlign: 'center',
            fontSize: '9pt',
            labelWidth: '100%',
            padding: '5px 0 0 0'
        },
        ]
    },
    {
        key: 4,
        fields: [{
            key: 'SimulationPoints',
            isLabel: true,
            fontWeight: '700',
            xs: 3,
            padding: paddingValue,
            fontSize: '9pt'
        },
        {
            key: 'No1',
            label: 'No. 1',
            isLabel: true,
            fontWeight: '700',
            xs: 3,
            textAlign: 'center',
            fontSize: '9pt',
            padding: paddingValue,
        },
        {
            key: 'No2',
            label: 'No. 2',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt',
            padding: paddingValue,
        },
        {
            key: 'No3',
            label: 'No. 3',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt',
            padding: paddingValue,
        },
        {
            key: 'No4',
            label: 'No. 4',
            isLabel: true,
            fontWeight: '700',
            xs: 2,
            textAlign: 'center',
            fontSize: '9pt',
            padding: paddingValue,
        }]
    },
    {
        key: 5,
        fields: [{
            key: 'ValveCenter',
            xs: 3,
            label: 'Valve Center',
            fontWeight: '600',
            labelWidth: '100%',
            textAlign: 'left',
            fontSize: '9pt',
            marginLeft: '7px',
            isLabel: true,
            padding: paddingValueTR,
        },
        {
            key: 'F606AftValveCenterNo1',
            value: formData.F606AftValveCenterNo1,
            isRequired: true,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderBottom: '0',
            xs: 3,
            maxLength: '10',
        },
        {
            key: 'F607AftValveCenterNo2',
            value: formData.F607AftValveCenterNo2,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderBottom: '0',
            xs: 2,
            maxLength: '10',
            borderLeft: '0',
        },
        {
            key: 'F608AftValveCenterNo3',
            value: formData.F608AftValveCenterNo3,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            borderLeft: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F609AftValveCenterNo4',
            value: formData.F609AftValveCenterNo4,
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            inputMarginRight: '4px',
            xs: 2,
            textAlign: 'center',
            type: 'number',
            maxLength: '10'
        }]
    },
    {
        key: 6,
        fields: [{
            key: 'ValveInlet',
            xs: 3,
            label: 'Valve Inlet',
            fontWeight: '600',
            labelWidth: '100%',
            textAlign: 'left',
            fontSize: '9pt',
            marginLeft: '7px',
            isLabel: true,
            padding: paddingValueTR,
        },
        {
            key: 'F610AftValveInletNo1',
            value: formData.F610AftValveInletNo1,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderBottom: '0',
            xs: 3,
            maxLength: '10'
        },
        {
            key: 'F611AftValveInletNo2',
            value: formData.F611AftValveInletNo2,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.2rem',
            borderBottom: '0',
            xs: 2,
            maxLength: '10',
            borderLeft: '0',
        },
        {
            key: 'F612AftValveInletNo3',
            value: formData.F612AftValveInletNo3,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            borderLeft: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F613AftValveInletNo4',
            value: formData.F613AftValveInletNo4,
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderBottom: '0',
            borderRight: '0',
            inputMarginRight: '4px',
            xs: 2,
            textAlign: 'center',
            type: 'number',
            maxLength: '10'
        }]
    },
    {
        key: 7,
        fields: [{
            key: 'ValveOutlet',
            xs: 3,
            label: 'Valve Outlet',
            fontWeight: '600',
            labelWidth: '100%',
            textAlign: 'left',
            fontSize: '9pt',
            marginLeft: '7px',
            isLabel: true,
            padding: paddingValueTR,
        },
        {
            key: 'F614AftValveOutletNo1',
            value: formData.F614AftValveOutletNo1,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            textAlign: 'center',
            xs: 3,
            maxLength: '10'
        },
        {
            key: 'F615AftValveOutletNo2',
            value: formData.F615AftValveOutletNo2,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            textAlign: 'center',
            xs: 2,
            maxLength: '10',
            borderLeft: '0',
        },
        {
            key: 'F616AftValveOutletNo3',
            value: formData.F616AftValveOutletNo3,
            type: 'number',
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            borderLeft: '0',
            textAlign: 'center',
            xs: 2,
            maxLength: '10'
        },
        {
            key: 'F617AftValveOutletNo4',
            value: formData.F617AftValveOutletNo4,
            width: '100%',
            labelWidth: '0',
            height: '1.2rem',
            borderRight: '0',
            inputMarginRight: '4px',
            xs: 2,
            textAlign: 'center',
            type: 'number',
            maxLength: '10'
        }]
    },
    {
        key: 8,
        fields: [{
            key: 'CalculatedValveLeakageRate',
            value: 'Calculated Valve Leakage Rate (EU)',
            xs: 3,
            width: '100%',
            readOnly: true,
            height: '1.1rem',
            labelWidth: '0',
            marginLeft: '4px',
            borderLeft: '0',
            borderBottom: '0',
            fontSize: '9pt',
            fontWeight: '600',
            isLabelNew: true,
            borderTop: '0',
            borderRight: '0',
            marginBottom: '4px',
        },
        {
            key: 'F618AftCalculatedValveLeakageRate',
            value: formData.F618AftCalculatedValveLeakageRate,
            xs: 3,
            width: '100%',
            textAlign: 'center',
            labelWidth: '0',
            height: '1.1rem',
            borderBottom: '0',
            borderTop: '0',
            marginBottom: '4px',
            type: 'number',
            maxLength: '10',
            isRequired: true
        },
        {
            key: 'NoData2',
            xs: 2,
            isLabel: true
        },
        {
            key: 'AsFoundResultLabel',
            value: 'As-Found Result',
            xs: 2,
            textAlign: 'end',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            marginBottom: '4px',
            borderBottom: '0',
            borderLeft: '0',
            borderTop: '0',
            borderRight: '0',
            isLabelBold: true,
        },
        {
            key: 'F102AftResult',
            value: FoundResultF102AftResultFormula(formData),
            isCalculatedNew: true,
            readOnly: true,
            xs: 2,
            labelWidth: '0',
            textAlign: 'center',
            width: '100%',
            borderBottom: '0',
            borderTop: '0',
            borderRight: '0',
            height: '1.1rem',
            inputMarginRight: '4px',
            marginBottom: '4px',
        }]
    }]
});
