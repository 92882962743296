export const HMISectionDataM31P723 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F370HmistatusPriorToTestLabel',
                label: 'HMI Status Prior to Test',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '18px 0 0 0'
            },
            {
                key: 'F370HmistatusPriorToTest',
                value: formData.F370HmistatusPriorToTest,
                isDropdown: true,
                isRequired: true,
                placeholder: 'Select HMI Status',
                options: [{ name: 'Select HMI Status', value: '' },
                { name: 'NORMAL', value: 'NORMAL' },
                { name: 'ALARM', value: 'ALARM' },
                { name: 'FAULT', value: 'FAULT' }],
                labelWidth: '0%',
                width: '100%',
                inputTextAlign: 'center',
                marginTop: '15px',
                marginBottom: '15px',
                paddingRightPlaceholder: '15px',
                xs: 2
            }]
        },
    ]

});
