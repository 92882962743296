import { paddingLabel } from '../C11/C11.data';
import { labelWidth } from '../P710/P710.data';

export const valveSizeValue = (selectedTag, formData) => selectedTag.DateTested ? formData?.F340ValveSize : formData?.F340ValveSize ?? selectedTag?.ValveSize;
export const HMISectionDataP725 = (selectedTag, formData) => ({
    rows: [
        {
            key: 2,
            fields: [{
                key: 'F600ProcessFluidLabel',
                label: 'Process Fluid',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '18px 0 0 0'
            },
            {
                key: 'F600ProcessFluid',
                value: formData.F600ProcessFluid,
                isRequired: true,
                isDropdown: true,
                placeholder: 'Select Process Fluid',
                options: [
                    { name: 'Select Process Fluid', value: '' },
                    { name: 'Gas', value: 'Gas' },
                    { name: 'Liquid', value: 'Liquid' }],
                inputTextAlign: 'center',
                labelWidth: '0%',
                width: '100%',
                xs: 2,
                height: '1.5rem',
                textAlign: 'center',
                marginTop: '15px',
            },
            {
                key: 'F601MaxTolerableLeakageRateLabel',
                label: 'Maximum Tolerable Leakage Rate',
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: '18px 5px 0 0'
            },
            {
                key: 'F601MaxTolerableLeakageRate',
                value: selectedTag.DateTested ? formData?.F601MaxTolerableLeakageRate : formData?.F601MaxTolerableLeakageRate ?? selectedTag?.TargetLeakageRate,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                labelWidth: '0%',
                width: '100%',
                height: '1.25rem',
                textAlignLabel: 'end',
                borderBottom: '0',
                textAlign: 'center',
                marginTop: '15px',
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F340ValveSizeLabel',
                label: 'Valve Size (inch)',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel
            },
            {
                key: 'F340ValveSize',
                value: valveSizeValue(selectedTag, formData),
                isRequired: true,
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                height: '1.2rem',
                borderTop: '0',
                textAlign: 'center',
                type: 'number',
                maxLength: '10',
                inputTextAlign: 'center'
            },
            {
                key: 'F603LeakageRateEngUnitLabel',
                label: 'Leakage Rate Engineering Unit (EU)',
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel
            },
            {
                key: 'F603LeakageRateEngUnit',
                value: selectedTag.DateTested ? formData?.F603LeakageRateEngUnit : formData?.F603LeakageRateEngUnit ?? selectedTag?.TargetLeakageRateEu,
                xs: 2,
                isRequired: true,
                maxLength: '17',
                labelWidth: '0%',
                width: '100%',
                height: '1.15rem',
                textAlignLabel: 'end',
                textAlign: 'center',
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'Nodata',
                isLabel: true,
                xs: 6,
            },
            {
                key: 'F795LeakageClassLabel',
                label: 'Leakage Class',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel
            },
            {
                key: 'F795LeakageClass',
                value: selectedTag.DateTested ? formData?.F795LeakageClass : formData?.F795LeakageClass ?? selectedTag?.TargetLeakageClass,
                xs: 2,
                labelWidth: '0%',
                width: '100%',
                height: '1.15rem',
                textAlignLabel: 'end',
                textAlign: 'center',
                borderTop: '0',
                maxLength: '20'
            }]
        }]
});
