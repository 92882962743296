export const correctiveDPSMappingData = [
    {
        field: 'CorrectiveDpsmappingId',
        headerName: 'Corrective DPS Mapping ID',
        width: '80',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Trf',
        headerName: 'TRF ID',
        width: '100',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'CorrectiveTrf',
        headerName: 'Corrective TRF ID',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'BusinessRule',
        headerName: 'Business Rule',
        width: '300',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '100',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '150',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const correctiveDPSMappingInitialState = {
    CorrectiveDpsmappingId: '',
    Trf: '',
    CorrectiveTrf: '',
    Trfid: '',
    CorrectiveTrfid: '',
    BusinessRule: '',
    Status: 'Inactive',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: '',
};

export const searchDataInitialState = {
    CorrectiveDpsmappingId: '',
    Trf: '',
    CorrectiveTrf: '',
    Trfid: '',
    CorrectiveTrfid: '',
    BusinessRule: '',
    Status: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comments: '',
};

export const correctiveDPSMappingApiEndpoint = {
    read: 'ADMIN_GetCorrectiveDPSMapping'
};
