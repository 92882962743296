import { FailedComponent } from '../../../../Sections.constants';
import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';

export const damperPlaceHolder = 'SELECT DAMPER POSITION';
export const DamperSectionDataM613 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F368PhysicalDamperPositionAfterTestLabel',
                    label: 'Physical Damper Position After Test',
                    xs: 4,
                    labelWidth: '100%',
                    padding: '15px 0 0 0',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F368PhysicalDamperPositionAfterTest',
                    value: formData.F368PhysicalDamperPositionAfterTest,
                    isDropDown: true,
                    isRequired: true,
                    placeholder: damperPlaceHolder,
                    options: [{ name: damperPlaceHolder, value: '' },
                    { name: 'OPEN', value: 'OPEN' },
                    { name: 'CLOSE', value: 'CLOSE' },
                    { name: 'INTERMEDIATE', value: 'INTERMEDIATE' }],
                    inputTextAlign: 'center',
                    xs: 2,
                    width: '100%',
                    marginTop: '15px',
                    labelWidth: '0',
                },
                {
                    key: 'F369HmidamperAfterTestLabel',
                    label: 'HMI Damper Position After Test',
                    xs: 2,
                    fontWeight: '600',
                    isLabel: true,
                    padding: '20px 0 0 0',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                },
                {
                    key: 'F369HmidamperAfterTest',
                    value: formData.F369HmidamperAfterTest,
                    isDropDown: true,
                    placeholder: damperPlaceHolder,
                    options: [{ name: damperPlaceHolder, value: '' },
                    { name: 'OPEN', value: 'OPEN' },
                    { name: 'CLOSE', value: 'CLOSE' },
                    { name: 'INTERMEDIATE', value: 'INTERMEDIATE' }],
                    xs: 4,
                    marginTop: '20px',
                    labelWidth: '0',
                    width: '100%'
                },
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'Nodata6',
                    xs: 6,
                    isLabel: true,
                    padding: '0'
                },
                {
                    key: 'F85TestResultLabel',
                    label: 'Test Result',
                    xs: 2,
                    isLabel: true,
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '5px 0 0 0'
                },
                {
                    key: 'F85TestResult',
                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    xs: 4,
                    labelWidth: '0',
                    width: '100%',
                    borderTop: '0'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'Nodata7',
                    xs: 6,
                    isLabel: true,
                    padding: '0'
                },
                {
                    key: 'F348FailedComponentsLabel',
                    label: 'Failed Components',
                    xs: 2,
                    fontWeight: '600',
                    isLabel: true,
                    padding: '5px 0 0 0',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                },
                {
                    key: 'F348FailedComponents',
                    value: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification') ? formData.F348FailedComponents : '',
                    placeholder: FailedComponent.SEARCH_FAILED_COMPONENTS,
                    isRequired: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification'),
                    isDisabled: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    readOnly: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    isDropDown: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification'),
                    options: [{ name: FailedComponent.SEARCH_FAILED_COMPONENTS, value: '' },
                    { name: 'Valve', value: 'Valve' },
                    { name: 'Actuator', value: 'Actuator' },
                    { name: 'Solenoid', value: 'Solenoid' },
                    { name: 'Positioner', value: 'Positioner' },
                    { name: 'Position transmitter', value: 'Positiontransmitter' },
                    { name: 'Limit switch', value: 'Limitswitch' },
                    { name: 'Pneumatic Part', value: 'Pneumaticpart' },
                    { name: 'Unknown', value: 'Unknown' }],
                    xs: 2,
                    fontSize: '8.4pt',
                    labelWidth: '0',
                    width: '100%',
                    borderTop: '0',
                    paddingLeft: '1px'
                },
                {
                    key: 'F349FailedComponents1',
                    value: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification') ? formData.F349FailedComponents1 : '',
                    placeholder: FailedComponent.SEARCH_FAILED_COMPONENTS,
                    isDisabled: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    readOnly: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    isDropDown: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification'),
                    options: [{ name: FailedComponent.SEARCH_FAILED_COMPONENTS, value: '' },
                    { name: 'Valve', value: 'Valve' },
                    { name: 'Actuator', value: 'Actuator' },
                    { name: 'Solenoid', value: 'Solenoid' },
                    { name: 'Positioner', value: 'Positioner' },
                    { name: 'Position transmitter', value: 'Positiontransmitter' },
                    { name: 'Limit switch', value: 'Limitswitch' },
                    { name: 'Pneumatic Part', value: 'Pneumaticpart' },
                    { name: 'Unknown', value: 'Unknown' }],
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    fontSize: '8.4pt',
                    borderTop: '0',
                    borderLeft: '0',
                    paddingLeft: '1px'
                }
            ]
        }
    ]

});
