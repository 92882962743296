import { Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { formatDate } from 'helpers/DateFormatter';
import { APIEndpoints } from 'helpers/APILists';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import PdfUploader from 'components/DataGrid/helpers/PdfUploader';
import PdfViewer from 'components/DataGrid/helpers/PdfViewer';
import DropdownInput from '../../elements/DropdownInput';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import { trackErrors } from '../helper';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import ModalFooterActions from '../../elements/ModalFooterActions';
import { strEquals } from '../../AdminScreen.helper';

const InformationForm = ({ isOpen, title, onClose, rowData, onRecordSubmit }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [cancelAlert, setCancelAlert] = useState(false);
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const [pdfNameError, setPdfNameError] = useState(false);
    const [nameList, setNameList] = useState([]);
    const submitButtonVisible = !_.isEqual(rowData, createRowData)
        && createRowData.Pdfname
        && createRowData.PdfdisplayOnScreen && createRowData.Status
        && ((createRowData.Status === 'Active' && createRowData.Pdffile) || createRowData.Status === 'Inactive');

    useEffect(() => {
        axios.get(APIEndpoints.InfoDocuments)
            .then((res) => {
                setNameList(res.data.map(x => ({ Id: x.Id, Pdfname: x.Pdfname })));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    const handleInputChange = ((e) => setCreateRowData((prev) => (
        {
            ...prev,
            [e.target.name]: e.target.value
        }
    )));

    const handleBlur = () => {
        setPdfNameError('');
        if (nameList.find(x => strEquals(x.Pdfname, createRowData.Pdfname)
            && x.Id !== createRowData.Id)) {
            setPdfNameError(' *(PDF Name already exists)');
        }
    };

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const resetButtonHandler = () => {
        setCreateRowData(rowData);
    };

    const handlePdfUpload = (pdf) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            setCreateRowData((prev) => ({
                ...prev,
                Pdffile: base64String
            }));
        };
        reader.readAsDataURL(pdf);
    };

    const submitButtonHandler = () => {
        createRowData.Pdfstatus = createRowData.Status === AdminConstants.ACTIVE;
        if (createRowData.Id) {
            delete createRowData.id;
            createRowData.ModifiedBy = userName?.name;
            createRowData.LastModifiedDate = formatDate(new Date());
            updateRecord(createRowData);
        } else {
            createRowData.Id = 0;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            createRowData.ModifiedBy = userName?.name;
            createRowData.LastModifiedDate = createRowData.CreatedDate;
            submitRecord(createRowData);
        }
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_AddInformation, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.Id);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateInformation, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.Id);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="Pdfname"
                        title="PDF Name"
                        value={createRowData.Pdfname}
                        required
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlur}
                        errorMsg={pdfNameError}
                        type="string"
                        maxLength="50"
                    />
                    <div>
                        <InputLabel style={{ fontSize: '12px', color: 'black', paddingLeft: '3px', paddingBottom: '4px', fontWeight: '600' }}>
                            PDF File
                        </InputLabel>
                        {createRowData.Pdffile && <PdfViewer pdfData={createRowData.Pdffile} />}
                        <PdfUploader onSubmit={handlePdfUpload} required={createRowData.Status === 'Active'} value={createRowData.Pdffile ? true : ''} />
                    </div>
                    <DropdownInput
                        name="PdfdisplayOnScreen"
                        title="PDF Display On Screen"
                        value={createRowData.PdfdisplayOnScreen}
                        options={['USER', 'ADMIN']}
                        handleInputChange={handleInputChange}
                        required
                    />
                    <DropdownInput
                        name="Status"
                        title="PDF Status"
                        value={createRowData.Status}
                        options={['Active', 'Inactive']}
                        handleInputChange={handleInputChange}
                        required
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData?.Id ? createRowData.CreatedBy : userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData?.Id ? formatDate(new Date(createRowData.CreatedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData.LastModifiedDate || formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '12px' }}>
                    <TextInput
                        name="Comments"
                        title="Comments"
                        value={createRowData.Comments}
                        handleInputChange={handleInputChange}
                        isMultiline
                        width="1080px"
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px', justifyContent: 'space-between' }}>
                <div />
                <ModalFooterActions
                    submitButtonVisible={submitButtonVisible}
                    submitButtonHandler={submitButtonHandler}
                    resetButtonHandler={resetButtonHandler}
                    cancelButtonHandler={cancelButtonHandler}
                />
            </DialogActions>
        </Dialog>

    );
};

export default InformationForm;

InformationForm.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string
};
