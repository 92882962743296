export const COMMON_CONSTANTS = {
    MRAT_ONLINEOFFLINESTATUSUPDATE: 'MRAT_ONLINEOFFLINESTATUSUPDATE',
    MRAT_SETLASTONLINETIME: 'MRAT_SETLASTONLINETIME'
};

export const MESSAGE_TYPE = {
    INFO: 'Info',
    ERROR: 'Error',
    WARNING: 'Warning'
};
