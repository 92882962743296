import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';
import { isSelfPortableTest } from '../../../AsFoundTestTableSection/Data/M65/M65.formula';

export const ALTSoundPressureFormula = (formData) => {
    if (ValidateNumberField(formData.F420AltSelectTestGain) === 1) {
        return '59';
    }
    if (ValidateNumberField(formData.F420AltSelectTestGain) === 2) {
        return '64';
    }
    if (ValidateNumberField(formData.F420AltSelectTestGain) === 3) {
        return '69';
    }
    if (ValidateNumberField(formData.F420AltSelectTestGain) === 4) {
        return '74';
    }
    if (ValidateNumberField(formData.F420AltSelectTestGain) === 5) {
        return '79';
    }
    if (ValidateNumberField(formData.F420AltSelectTestGain) === 6) {
        return '84';
    }
    if (ValidateNumberField(formData.F420AltSelectTestGain) === 7) {
        return '89';
    }
    if (ValidateNumberField(formData.F420AltSelectTestGain) === 8) {
        return '94';
    }
    if (ValidateNumberField(formData.F420AltSelectTestGain) === 9) {
        return '99';
    }
    return '';
};

export const AsLeftResultM65Formula = (formData) => {
    if (FieldHasValue(formData.F821TestToolActivationAlt) && (formData.F821TestToolActivationAlt === 'NO')) {
        return 'FAIL';
    }
    if (isSelfPortableTest(formData) && FieldHasValue(formData.F378AltHmistatus1) && FieldHasValue(formData.F821TestToolActivationAlt)) {
        if (formData.F821TestToolActivationAlt === 'YES' && formData.F378AltHmistatus1 === 'ALARM') {
            return 'PASS';
        }
        return 'FAIL';
    }
    if (FieldHasValue(formData.F378AltHmistatus1) && FieldHasValue(formData.F422AltSoundPressureLevel2)
        && FieldHasValue(formData.F421AltSoundPressureLevel1) && FieldHasValue(formData.F31FailTolerance)) {
        if (Math.abs(ValidateNumberField(formData.F421AltSoundPressureLevel1) - ValidateNumberField(formData.F422AltSoundPressureLevel2))
            <= ValidateNumberField(formData.F31FailTolerance) && FieldHasValue(formData.F378AltHmistatus1 === 'ALARM')) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
