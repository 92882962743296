export const VisualInspectSectionDataP861P69 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect instrument tagging, position transmitter in good condition, instrument wiring 
                connection/termination, instrument condition, general deterioration and no leak sign.`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F120Diagnostics',
                    label: 'Retrieve diagnostic message.',
                    value: formData.F120Diagnostics,
                    isMultiline: true,
                    xs: 12,
                    width: '50%',
                    inputWidth: '50%',
                    marginTop: '15px',
                    alignItems: 'inherit'
                }]
        }
    ]

});
