import { FieldHasValue } from 'helpers/Validation';
import { strEquals } from 'journeys/portal/Admin/AdminScreen.helper';
import { getAftDeleteIconStatus } from '../../AsFoundTestTableSection.helpers';

export const TestPassFailFormula = (smokeConfirmation, hmiStatus) => {
    if (FieldHasValue(smokeConfirmation) && FieldHasValue(hmiStatus)) {
        if (strEquals(smokeConfirmation, 'YES') && strEquals(hmiStatus, 'ALARM')) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const AsFoundResultMandatoryCheck = (formData, aftTestPoint) => {
    let flag = false;
    if (getAftDeleteIconStatus()) {
        return flag;
    }
    for (let index = 2; index < Number(aftTestPoint) + 2; index += 1) {
        if (!FieldHasValue(formData[`AftResultReading${index}`])) {
            flag = false;
            break;
        }
        flag = true;
    }
    return flag;
};

export const AsFoundResultFormula = (formData, asFoundTestPoint) => {
    const asFoundResultValue = [];
    const indexValue = 2;
    for (let index = indexValue; index < Number(asFoundTestPoint) + 2; index += 1) {
        asFoundResultValue.push((formData[`AftResultReading${index}`]));
    }
    if (asFoundResultValue.length > 0) {
        if (asFoundResultValue.includes('FAIL')) {
            return 'FAIL';
        }
        return 'PASS';
    }
    return '';
};
