import { ALTDensitySectionDataP65P831 } from './Data/P65/P65.data';

export const ALTDensitySectionData = (selectedTag, formName, formData) => {
    const disableAsLeftSection = ((formData.F102AftResult === 'PASS' && formData.F218AftIsRecalibrationNeeded === 'NO')
        || formData.F619AsLeftTestRequired === 'NO');
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsLeftTestHMIINST',
                    label: 'As-Left Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 5px 3px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'F219AltWaterDensityUsed',
                    label: 'Water Density Used',
                    value: !disableAsLeftSection ? formData.F219AltWaterDensityUsed : '',
                    isDisabled: disableAsLeftSection,
                    readOnly: disableAsLeftSection,
                    xs: 6,
                    type: 'number',
                    maxLength: '10',
                    width: '36.6%',
                    textAlign: 'center',
                    labelWidth: '21rem',
                    marginLeft: '2px',
                    borderBottom: '0',
                },
                {
                    key: 'Nodata',
                    isLabel: true,
                    xs: 2,
                },
                {
                    key: 'F222AltBackgroundCountLabel',
                    label: 'Background Count',
                    xs: 2,
                    isLabel: 'true',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '4px 0 0 0',
                },
                {
                    key: 'F222AltBackgroundCount',
                    value: !disableAsLeftSection ? formData.F222AltBackgroundCount : '',
                    isDisabled: disableAsLeftSection,
                    readOnly: disableAsLeftSection,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderRight: '0',
                    marginLeft: '2px'
                }
                ]
            },
            {
                key: 3,
                fields: [{
                    key: 'F220AltOilDensityUsed',
                    label: 'Oil Density Used',
                    value: !disableAsLeftSection ? formData.F220AltOilDensityUsed : '',
                    isDisabled: disableAsLeftSection,
                    readOnly: disableAsLeftSection,
                    type: 'number',
                    maxLength: '10',
                    xs: 6,
                    textAlign: 'center',
                    width: '36.6%',
                    labelWidth: '21rem',
                    marginLeft: '2px',
                    borderBottom: '0',
                },
                {
                    key: 'Nodata',
                    isLabel: true,
                    xs: 2,
                },
                {
                    key: 'F223AltVesselEmptyCountLabel',
                    label: 'Vessel Empty Count',
                    xs: 2,
                    isLabel: 'true',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '4px 0 0 0',
                },
                {
                    key: 'F223AltVesselEmptyCount',
                    value: !disableAsLeftSection ? formData.F223AltVesselEmptyCount : '',
                    isDisabled: disableAsLeftSection,
                    readOnly: disableAsLeftSection,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderRight: '0',
                    marginLeft: '2px'
                }
                ]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F221AltGasDensityUsed',
                        label: 'Gas Density Used',
                        value: !disableAsLeftSection ? formData.F221AltGasDensityUsed : '',
                        isDisabled: disableAsLeftSection,
                        readOnly: disableAsLeftSection,
                        type: 'number',
                        maxLength: '10',
                        xs: 6,
                        width: '36.6%',
                        textAlign: 'center',
                        labelWidth: '21rem',
                        marginLeft: '2px'
                    },
                    {
                        key: 'Nodata',
                        isLabel: true,
                        xs: 2,
                    },
                    {
                        key: 'F224AltVesselFullCountLabel',
                        label: 'Vessel Full Count',
                        xs: 2,
                        isLabel: 'true',
                        labelWidth: '96%',
                        textAlign: 'end',
                        fontSize: '9pt',
                        fontWeight: '600',
                        padding: '4px 0 0 0',
                    },
                    {
                        key: 'F224AltVesselFullCount',
                        value: !disableAsLeftSection ? formData.F224AltVesselFullCount : '',
                        isDisabled: disableAsLeftSection,
                        readOnly: disableAsLeftSection,
                        type: 'number',
                        maxLength: '10',
                        xs: 2,
                        labelWidth: '0',
                        width: '100%',
                        textAlign: 'center',
                        borderRight: '0',
                        marginLeft: '2px'
                    }
                ]
            }
        ]

    };
};

export const selectAltDensityTemplateForForm = (selectedTag, formName, formData) => {
    let filterViewData;
    if (['P6-5', 'P6-5.2', 'P8-3.1', 'P7-7'].includes(formName)) {
        filterViewData = ALTDensitySectionDataP65P831(selectedTag, formName, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
