import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';
import { FailedComponent } from '../../../../Sections.constants';

export const valvePlaceHolder = 'Select Valve Position';
export const otherPartSpecify = 'Other part (specify)';
export const failedComponentsOptions = [
    { name: 'Valve', value: 'Valve' },
    { name: 'Actuator', value: 'Actuator' },
    { name: 'Solenoid', value: 'Solenoid' },
    { name: 'I/P positioner', value: 'I/P positioner' },
    { name: 'Position transmitter', value: 'Position transmitter' },
    { name: 'Limit switch', value: 'Limit switch' },
    { name: 'Pilot valve', value: 'Pilot valve' },
    { name: 'Quick exhaust valve', value: 'Quick exhaust valve' },
    { name: 'Check valve', value: 'Check valve' },
    { name: 'Relief valve', value: 'Relief valve' },
    { name: 'Volume booster', value: 'Volume booster' },
    { name: 'Flow regulator', value: 'Flow regulator' },
    { name: 'Filter regulator', value: 'Filter regulator' },
    { name: 'Dust excluder', value: 'Dust excluder' },
    { name: otherPartSpecify, value: otherPartSpecify },
    { name: 'Unknown', value: 'Unknown' }
].sort((a, b) => {
    if (a.name === 'Unknown') return 1;
    if (b.name === 'Unknown') return -1;
    if (a.name === otherPartSpecify) return 1;
    if (b.name === otherPartSpecify) return -1;
    return a.name.localeCompare(b.name);
});
export const ValveSizeSectionData716 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F343PhysicalValvePositionAfterTest',
                    label: 'Physical Valve Position After Test',
                    value: formData.F343PhysicalValvePositionAfterTest,
                    isDropDown: true,
                    placeholder: valvePlaceHolder,
                    options: [
                        { name: valvePlaceHolder, value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                    xs: 6,
                    type: 'number',
                    maxLength: '10',
                    isRequired: true,
                    width: '56.4%',
                    labelWidth: '43.2%',
                    marginTop: '20px',
                    inputTextAlign: 'center'
                },
                {
                    key: 'F347HmivalvePositionAfterTestLabel',
                    label: 'HMI Valve Position After Test',
                    xs: 2,
                    fontWeight: '600',
                    isLabel: true,
                    padding: '20px 0 0 0',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt'
                },
                {
                    key: 'F347HmivalvePositionAfterTest',
                    value: formData.F347HmivalvePositionAfterTest,
                    isDropDown: true,
                    placeholder: valvePlaceHolder,
                    options: [
                        { name: valvePlaceHolder, value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                    xs: 4,
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '20px'
                },
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'Nodata6',
                    label: '',
                    xs: 6,
                    isLabel: true
                },
                {
                    key: 'F85TestResultLabel',
                    label: 'Test Result',
                    xs: 2,
                    isLabel: true,
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '5px 0 0 0'
                },
                {
                    key: 'F85TestResult',
                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    xs: 4,
                    labelWidth: '0',
                    width: '100%',
                    borderTop: '0',
                    borderBottom: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification') ? '0' : ''
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'Nodata7',
                    label: '',
                    xs: 6,
                    isLabel: true
                },
                {
                    key: 'FailedComponents',
                    label: 'Failed Components',
                    xs: 2,
                    fontWeight: '600',
                    isLabel: true,
                    padding: '5px 0 0 0',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                },
                {
                    key: 'F348FailedComponents',
                    label: '',
                    value: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification') ? formData.F348FailedComponents : '',
                    placeholder: FailedComponent.SEARCH_FAILED_COMPONENTS,
                    isRequired: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification'),
                    isDisabled: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    readOnly: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    isFailedDropDown: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification'),
                    options: failedComponentsOptions,
                    xs: 2,
                    fontSize: '8.4pt',
                    labelWidth: '0',
                    width: '100%',
                    borderTop: '0',
                    paddingLeft: '1px'
                },
                {
                    key: 'F349FailedComponents1',
                    value: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification') ? formData.F349FailedComponents1 : '',
                    placeholder: FailedComponent.SEARCH_FAILED_COMPONENTS,
                    isDisabled: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    readOnly: (formData.F85TestResult !== 'Fail' || formData.F85TestResult !== 'FailWithCorrectiveNotification'),
                    isFailedDropDown: (formData.F85TestResult === 'Fail' || formData.F85TestResult === 'FailWithCorrectiveNotification'),
                    options: failedComponentsOptions,
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    fontSize: '8.4pt',
                    borderTop: '0',
                    borderLeft: '0',
                    paddingLeft: '1px'
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'Nodata7',
                    label: '',
                    xs: 8,
                    isLabel: true
                },
                {
                    key: 'remark',
                    label: 'Remark: Record manufacturer and model of failed components on the TRF.',
                    isLabel: true,
                    xs: 4,
                    fontWeight: '600',
                    labelWidth: '100%',
                    fontSize: '8.5pt',
                    color: '#ea7c34'
                }]
        },
    ]
});
