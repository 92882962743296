import { TestPointRange, conditionalValueSelector } from 'helpers/Validation';
import {
    AsFoundTestDeviation1Formula
} from '../../../AsFoundTestTableSection/Data/P721/P721.formulas';
import { AsLeftResultFormula, AsLeftResultMandatoryCheck, AsLeftTestDeviationFormula, AltSystemReadingFormulaValueCheck } from './P721.formulas';
import { getAltDeleteIconStatus, disableAsLeft } from '../../AsLeftTestTableSection.helpers';
import { setAltTestPoint } from '../P720/P720.formulas';

export const paddingValue = '0 0 0px 8px';
export const placeholderUnit = 'Select Unit';
export const AsLeftTestTableSectionDataP721 = (formData, altTestPoint) => {
    const rows = [];
    const altDisabled = disableAsLeft(formData.F102AftResult);
    const hideDeleteLabel = (altDisabled) || !getAltDeleteIconStatus();
    rows.push({
        key: 1,
        fields: [{
            key: 'AsLeftTest',
            label: 'As-Left Test',
            isLabel: true,
            xs: 4,
            fontWeight: '700',
            padding: '8px 0 0 8px',
            borderTop: '0',
        },
        {
            key: 'F721NumberOfTestPoints',
            label: 'Number of Test Points',
            value: setAltTestPoint(formData, altTestPoint),
            isRequired: (!altDisabled),
            isDisabled: altDisabled,
            readOnly: altDisabled || getAltDeleteIconStatus(),
            isBlur: true,
            type: 'number',
            maxLength: '10',
            xs: 4,
            labelWidth: '71%',
            width: '64%',
            height: '1.26rem',
            marginRight: '0.5rem',
            textAlign: 'center',
            marginTop: '4px',
            borderTop: '0',
            marginBottom: '10px',
            direction: 'rtl',
            hasTestPointError: !TestPointRange(formData.F721NumberOfTestPoints, 10)
        },
        {
            key: 'F722TransducerOutputSignal',
            label: 'Transducer Output Signal',
            value: conditionalValueSelector(altDisabled, '', formData.F722TransducerOutputSignal),
            isDisabled: altDisabled,
            readOnly: altDisabled,
            isDropdown: (!altDisabled),
            xs: 4,
            isRequired: (!altDisabled),
            placeholder: placeholderUnit,
            options: [
                { name: placeholderUnit, value: '' },
                { name: 'Voltage', value: 'Voltage' },
                { name: 'Current', value: 'Current' }],
            inputTextAlign: 'center',
            labelWidth: '48%',
            width: conditionalValueSelector(altDisabled, '47.6%', '49.1%'),
            height: '1.5rem',
            borderRight: '0',
            borderTop: '0',
            marginTop: '4px',
            marginRight: '0.5rem',
            direction: 'rtl',
        }]
    },
        {
            key: 2,
            fields: [{
                key: 'AltDelete',
                label: '',
                isLabel: true,
                fontWeight: '700',
                xs: 1,
                textAlign: 'center',
                padding: paddingValue,
                fontSize: '9pt'
            },
            {
                key: 'SimulatedGap',
                label: 'Simulated Gap',
                isLabel: true,
                fontWeight: '700',
                xs: 1,
                textAlign: 'center',
                padding: paddingValue,
                fontSize: '9pt'
            },
            {
                key: 'Expected Output',
                label: 'Expected Output',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'Measured Output',
                label: 'Measured Output',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'HMI Reading',
                label: 'HMI Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'System 1 Reading',
                label: 'System 1 Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            },
            {
                key: 'Deviation',
                label: 'Deviation',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'AltDeleteLabel',
                value: conditionalValueSelector(hideDeleteLabel, '', 'Delete'),
                textAlign: 'center',
                fontColor: 'red',
                isDeleteLabel: getAltDeleteIconStatus(),
                width: '100%',
                labelWidth: '0',
                height: conditionalValueSelector(altDisabled, '1.65rem', '1.28rem'),
                xs: 1,
                borderLeft: '0',
                borderRight: '0',
                borderBottom: '0',
                readOnly: true,
                marginLeft: '4px',
                isDisabled: altDisabled
            },
            {
                key: 'F701AltSimulatedGap1',
                xs: 1,
                value: conditionalValueSelector(altDisabled, '', formData.F701AltSimulatedGap1),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                isDropdown: !altDisabled,
                isRequired: !altDisabled,
                placeholder: placeholderUnit,
                options: [
                    { name: placeholderUnit, value: '' },
                    { name: 'mil', value: 'mil' },
                    { name: 'mm', value: 'mm' },
                    { name: 'µm', value: 'µm' }],
                inputTextAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.65rem',
                borderBottom: '0',
                borderRight: '0',
            },
            {
                key: 'F711AltExpectedOutput1',
                value: conditionalValueSelector(altDisabled, '', AsLeftTestDeviationFormula(formData)),
                isDisabled: altDisabled,
                isCalculatedNew: (!altDisabled),
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: conditionalValueSelector(altDisabled, '1.65rem', '1.4rem'),
                xs: 2,
                borderBottom: '0',
                maxLength: '10',
                readOnly: true,
            },
            {
                key: 'F164AltMeasuredReading1',
                value: conditionalValueSelector(altDisabled, '', AsLeftTestDeviationFormula(formData)),
                isDisabled: altDisabled,
                isCalculatedNew: (!altDisabled),
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: conditionalValueSelector(altDisabled, '1.65rem', '1.4rem'),
                xs: 2,
                maxLength: '10',
                borderBottom: '0',
                borderRight: '0',
                borderLeft: '0',
                readOnly: true,
            },
            {
                key: 'F109AltHmireading1',
                value: conditionalValueSelector(altDisabled, '', formData.F24HmiEngineeringUnit),
                isDisabled: altDisabled,
                isCalculatedNew: (!altDisabled),
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: conditionalValueSelector(altDisabled, '1.65rem', '1.4rem'),
                xs: 2,
                maxLength: '10',
                borderBottom: '0',
                readOnly: true,
            },
            {
                key: 'F733AltSystemReading1',
                value: conditionalValueSelector(altDisabled, '', formData.F733AltSystemReading1),
                isDisabled: altDisabled,
                readOnly: altDisabled,
                isDropdown: (!altDisabled),
                isRequired: AltSystemReadingFormulaValueCheck(formData, 'AltSystemReading', altTestPoint),
                placeholder: placeholderUnit,
                options: [
                    { name: placeholderUnit, value: '' },
                    { name: 'mil', value: 'mil' },
                    { name: 'mm', value: 'mm' },
                    { name: 'µm', value: 'µm' }],
                inputTextAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.65rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                textAlign: 'center',
                xs: 2,
            },
            {
                key: 'F237AltDeviation1',
                value: conditionalValueSelector(altDisabled, '', AsLeftTestDeviationFormula(formData)),
                isDisabled: altDisabled,
                isCalculatedNew: (!altDisabled),
                readOnly: true,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: conditionalValueSelector(altDisabled, '1.65rem', '1.4rem'),
                xs: 2,
                maxLength: '10',
                borderRight: '0',
                borderBottom: '0',
                inputMarginRight: '4px',
            }]
        });
    if (TestPointRange(altTestPoint ?? formData?.F721NumberOfTestPoints, 10)) {
        for (let index = 2; index < Number(altTestPoint ?? formData?.F721NumberOfTestPoints) + 2; index += 1) {
            rows.push({
                key: 4 + index,
                fields: [{
                    key: index,
                    xs: 1,
                    borderTop: '0',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderRight: '0',
                    readOnly: true,
                    isDelete: conditionalValueSelector(altDisabled, false, getAltDeleteIconStatus())
                },
                {
                    key: `AltSimulatedGap${index}`,
                    value: conditionalValueSelector(altDisabled, '', formData[`AltSimulatedGap${index}`]),
                    isRequired: !altDisabled,
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    borderRight: '0',
                    xs: 1,
                    maxLength: '10',
                    borderBottom: '0',
                    isSimulatedGapRequired: true
                },
                {
                    key: `AltExpectedOutput${index}`,
                    value: conditionalValueSelector(altDisabled, '', formData[`AltExpectedOutput${index}`]),
                    isRequired: (!altDisabled),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderBottom: '0',
                },
                {
                    key: `AltMeasuredReading${index}`,
                    value: conditionalValueSelector(altDisabled, '', formData[`AltMeasuredReading${index}`]),
                    isRequired: !altDisabled,
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderBottom: '0',
                    borderRight: '0',
                    borderLeft: '0'
                },
                {
                    key: `AltHmiReading${index}`,
                    value: conditionalValueSelector(altDisabled, '', formData[`AltHmiReading${index}`]),
                    isRequired: (!altDisabled),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderBottom: '0',
                },
                {
                    key: `AltSystemReading${index}`,
                    value: conditionalValueSelector(altDisabled, '', formData[`AltSystemReading${index}`]),
                    isDisabled: altDisabled,
                    readOnly: altDisabled,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderRight: '0',
                    borderBottom: '0',
                    borderLeft: '0'
                },
                {
                    key: `AltDeviation${index}`,
                    value: conditionalValueSelector(altDisabled, '', AsFoundTestDeviation1Formula(formData[`AltExpectedOutput${index}`],
                        formData[`AltMeasuredReading${index}`])),
                    isDisabled: altDisabled,
                    type: 'number',
                    readOnly: true,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.23rem',
                    xs: 2,
                    maxLength: '10',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    isDeviationRequired: true
                }]
            });
        }
    }
    rows.push({
        key: 17,
        fields: [
            !altDisabled ? {
                key: 'NoData2',
                xs: 8,
                height: '0.5rem',
                value: '',
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                borderBottom: '0',
                borderLeft: '0',
                marginLeft: '4px',
                borderRight: '0',
            } : {
                key: 'NoData2',
                xs: 8,
                isLabel: true,
                height: '1.1rem',
            },
            {
                key: 'AsLeftResultLabel',
                value: 'As-Left Result',
                xs: 2,
                textAlign: 'end',
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                height: '1.1rem',
                marginBottom: '4px',
                borderTop: conditionalValueSelector(altDisabled, '0', ''),
                borderBottom: '0',
                borderLeft: '0',
                borderRight: '0',
                isLabelBold: true,
            },
            {
                key: 'F103AltResult',
                value: conditionalValueSelector((!altDisabled && AsLeftResultMandatoryCheck(formData, altTestPoint)),
                    AsLeftResultFormula(formData, altTestPoint), ''),
                isCalculatedNew: (!altDisabled),
                isDisabled: altDisabled,
                readOnly: true,
                xs: 2,
                labelWidth: '0',
                textAlign: 'center',
                width: '100%',
                borderBottom: '0',
                height: '1.1rem',
                borderRight: '0',
                marginBottom: '4px',
                inputMarginRight: '4px'
            }]
    });
    return { rows };
};
